import React, { Component } from 'react';
import {Col, Row, Modal, Button, Input, List, Pagination} from 'antd';
import api from "../api/api";
import AddOrEditGround from "../views/company/lectureManage/ground/AddOrEditGround";

/************* 模板Popover ****************/
class MyGroundModel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            visible: false,

            data:[],
            pageNum:1,
            pageSize:8,
            totalNum:9,
        }
    }

    myGroundModelList(){ //模板列表
        api.lecture.myGroundModelList(
            {
                "condition": {
                    "name": this.state.name,
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.myGroundModelList(), 200);
    }

    listClick(name,id) {
        this.setState({
            name:name,
            visible: false,
        });
        this.props.onChange(id)
    }

    onChange(ar){
        if (ar){
            this.updateData();
        }
    }

    componentDidMount() {
        this.myGroundModelList();
    }

    render() {
        if (this.props.value && this.props.value.id && this.props.value.name){
            this.props.onChange(this.props.value.id);
            this.setState({
                name: this.props.value.name,
            })
        }
        return (
            <div>
                <Row gutter={20}>
                    <Col span={18}>
                        <Input size="large"
                               placeholder="场地模板选择"
                               value={this.state.name}
                               onClick={()=>{
                                   this.setState({
                                       visible: true,
                                   })}
                               }/>
                    </Col>
                    <Col span={6}>
                        <AddOrEditGround tagType={"add"}
                                         onChange={(ar)=>this.onChange(ar)}/>
                    </Col>
                </Row>

                <Modal
                    title="场地模板"
                    visible={this.state.visible}
                    onCancel={()=>{
                        this.setState({
                            visible:false
                        })
                    }}
                    footer={null}
                >
                    <List
                        size="large"
                        dataSource={this.state.data}
                        renderItem={item =>
                            <List.Item onClick={()=>this.listClick(item.name,item.id)}>
                                <a>{item.name}</a>
                            </List.Item>}
                    />
                    <div style={{paddingTop:"25px"}}>
                        <Pagination
                            onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.state.pageSize}
                            current={this.state.pageNum}
                            total={this.state.totalNum}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default MyGroundModel;