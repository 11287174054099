import React, { Component } from 'react';
import {Form, Col, Select, Input, Modal, Tag, Icon, Divider, Button, Avatar, Tooltip, Table, Pagination} from 'antd';
import api from '../../../../api/api';
import MyLayout from "../../../common/MyLayout";
import ComFunction from '../../../../api/ComFunction';
import Dict from '../../../../config/Dict';
import Action from '../../../../actions/Action';
const { Option } = Select;
const InputGroup = Input.Group;

const { confirm } = Modal;

class VideoList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectType:"videoKeywords",
            lectureName:"",
            teacherName:"",
            status:null,
            videoKeywords:[],

            data:[],
            pageNum:1,
            pageSize:8,
            totalNum:99,
        };
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.updateData(), 200);
    }

    updateData() {
        api.video.myVideoInputList(
            {
                "condition": {
                    "lectureDateEnd": "",
                    "lectureDateStart": "",
                    "lectureName": this.state.lectureName,
                    "teacherName": this.state.teacherName,
                    "status":this.state.status,
                    "videoKeywords": this.state.videoKeywords
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status === 200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:8,
            totalNum:9,
            });
        setTimeout(()=>this.updateData(), 200,)
    }

    componentDidMount() {
        this.updateData();
    }

    cancellation(videoId){
        confirm({
            title: '你确定要注销这个视频吗?',
            content: '注销的视频不会被删除，注销后你可以选择重新上线或删除视频。',
            onOk:()=>{
                api.video.offVideo({videoId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.updateData();
                        }
                    }
                )
            }
        });
    }

    reOnline(vid,filePath){
        confirm({
            title: '你确定要重新上线这个视频吗?',
            content: '重新上线这个视频，视频将进入[审批中]的状态，管理员审批通过后，视频将重新上线。',
            onOk:()=>{
                api.video.addOrEditVideo(
                    {vid,filePath},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.updateData();
                        }
                    }
                )
            }
        });
    }

    render() {
        let history = this.props.history;

        const columns = [
            {
                title: '缩略图',
                dataIndex: 'posterPath',
                key: 'posterPath',
                render:(text,record)=>(
                    <Avatar 
                        src={ComFunction.getScareImgeUrl(record.posterPath,50,50)} 
                        shape="square"
                        size={50}
                    />
                )
            },
            {
                title: '视频名',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow",
                render:(text,record)=>( 
                    <Tooltip
                    title={record.name}
                    >
                         {record.name}
                    </Tooltip>
                    )
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render:(text,record)=>(
                    <span>{Dict.getValue('videoState',record.status)}</span>
                )
            },
            {
                title: '关键字',
                dataIndex: 'keywords',
                key: 'keywords',
                render:(text,record)=>(
                   <Tag color="cyan">
                    {record.keywords}
                </Tag>
                )
            },
            {
                title: '主讲',
                dataIndex: 'teachername',
                key: 'teachername',
                render:(text,record)=>(
                    <Tag color="purple">
                        {record.teacher?record.teacher.name +"":"无"}
                    </Tag>
                )
            },
            {
                title: '更新时间',
                dataIndex: 'updatedTime',
                key: 'updatedTime',
                className:"tableOverflow",
            }, 
            {
                title: '介绍',
                dataIndex: 'intro',
                key: 'intro',
                className:"tableOverflow",
                render:(text,record)=>( 
                    <Tooltip
                    title={record.intro}
                    placement="topLeft"
                    >
                         {record.intro}
                    </Tooltip>
                    )
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                render:(text,record)=>( 
                    <>
                    <Button
                    type='primary'
                    onClick={
                        ()=>{
                            history.push("/videoDetail"+'/'+record.vid)
                        }
                    }
                    >
                    详情
                    </Button>
                    <Divider type='vertical'/>
                    {
                        record.status != 0?
                        <>
                            <Button 
                            type='danger' 
                            onClick={()=>this.cancellation(record.vid)}>
                            下线视频
                            </Button>
                        </>:
                        <>
                            <Button 
                            type="primary" 
                            onClick={()=>this.reOnline(record.vid,record.filePath)}>
                            重新上线</Button>
                        </>           
                    }
                    </>
                    )
            },
        ];

        return(
            <MyLayout sider={"InfoManageSider"}>
                <div className="userEditTitle">视频列表</div>
                <Divider />
                <Form layout='inline'>
                    <Form.Item>
                        <Select 
                                style={{ width: 120 }}
                                defaultValue={this.state.status}
                                onChange={(v)=>{
                                    this.setState({status:v});
                                    this.research();
                            }}>
                            <Option value={null}>所有</Option>
                            <Option value={0}>下线</Option>
                            <Option value={1}>上线</Option>
                            <Option value={2}>驳回</Option>
                            <Option value={3}>审批中</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                            <Select
                                    defaultValue="videoKeywords"
                                    onChange={(value)=>this.setState({selectType:value})}>
                                <Option value="videoKeywords">关键字</Option>
                                <Option value="teacherName">讲师名</Option>
                            </Select>
                    </Form.Item>
                    <Form.Item>
                            <span style={{ width: '40%'}} >{(() => {
                                    switch (this.state.selectType) {
                                        case "teacherName":
                                            return <Input 
                                                          defaultValue={this.state.teacherName}
                                                          placeholder='请输入讲师名'
                                                          onChange={(e)=>this.setState({teacherName:e.target.value})}
                                            />;
                                        case "videoKeywords":
                                            return <Input 
                                                          defaultValue={this.state.videoKeywords}
                                                          placeholder='请输入视频关键字'
                                                          onChange={(e)=>this.setState({videoKeywords:[e.target.value]})}
                                            />;
                                        default:
                                            return null
                                    }
                                }
                            )()}</span>
                    </Form.Item>
                    <Form.Item>
                                <Button type='primary'
                                        icon="search"
                                        onClick={()=>this.research()}>
                                    搜索</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button
                                type="primary"
                                onClick={()=>history.push('/AddOrEditVideo'+'/'+'editVideo')}>
                            录入新的视频</Button>
                    </Form.Item>
                </Form>
                <Divider dashed/>
                <Table
                    pagination={false}
                    rowKey='vid'
                    dataSource={this.state.data}
                    columns={columns}
                />
                <Divider dashed/>
                <Pagination
                    onChange={(pageNum,pageSize)=>this.pageOnChange(
                        pageNum,pageSize
                    )}
                    pageSize={this.state.pageSize}
                    current={this.state.pageNum}
                    total={this.state.totalNum}
                />
            </MyLayout>
        )
    }
}
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveUserInfoToStore: (data) => dispatch(
            {
                type: Action.SAVE_USER_INFO,
                data: data,
            }),
    }
}

//export default connect(mapStateToProps,mapDispatchToProps)(VideoList);
export default VideoList;