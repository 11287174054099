
import React, { Component } from 'react';
import api from '../../../api/api';
import { Pagination, Button, Modal, Table, Row, Col, Input, Divider, Avatar } from 'antd';

class AddVideoWithSeriesModal extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            keyword:null,
            visible:false,
            data:[],
        };
        this.initPageSize = 8;

        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        this.totalNum = 1000;
    }

    pageSearchSeries(){
        api.series.pageSerchSeries(
            {
                "condition": {
                    "keyword": this.state.keyword
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data)=>{
                if(state && data.status === 200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        data:data.data
                    })
                }
            }
        )
    }


    pageOnChange(pageNum,pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(()=>this.pageSearchSeries(), 200);
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        this.totalNum = 1000;
        setTimeout(()=>this.pageSearchSeries(), 200,);
    }

    componentDidMount() {
        this.pageSearchSeries();
    }

    handleAdd=(record)=>{
        if(this.props.handleAdd)
        {
            this.props.handleAdd(record);
        }
    };

    render() {

        const columns = [
            {
                title: '封面',
                dataIndex: 'coverPath',
                key: 'coverPath',
                className:"tableOverflow",
                width:20,
                render: (text, record) => (
                    <Avatar 
                     size='large'
                     shape="square"
                     src={record.coverPath} 
                    />
                ),
            },
            {
                title: '系列名',
                dataIndex: 'title',
                key: 'title',
                className:"tableOverflow",
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                width:20,
                render: (text, record) => (
                    <Button
                     type='primary'
                     onClick={
                         ()=>{
                             this.handleAdd(record);
                             this.setState({
                                 visible:false
                             })
                         }
                     }
                    >添加</Button>
                ),
            }
        ];

        return (
            <>
                <Button type="primary"
                        style={{marginBottom:"18px"}}
                        onClick={()=>{
                            this.setState({
                                visible: true,
                            })
                        }}>
                    {this.props.title}
                </Button>
                <Modal
                    title="添加系列"
                    width={800}
                    centered={true}
                    visible={this.state.visible}
                    onCancel={()=>{
                        this.setState({
                            visible:false
                        })
                    }}
                    footer={null}
                >
                 <Row gutter={15}>
                        <Col span={7} >
                            <span>
                                <Input defaultValue={this.state.keyword}
                                       placeholder='关键字'
                                       onChange={(e)=>this.setState({keyword:e.target.value})}
                                />
                            </span>
                        </Col>
                        <Col span={3} >
                            <div>
                                <Button type='primary'
                                        onClick={()=>this.research()}>
                                    搜索</Button>
                            </div>
                        </Col>
                    </Row>
                    <Divider/>
                    <Table
                        className="components-table-demo-nested"
                        dataSource={this.state.data}
                        columns={columns}
                        rowKey='seriesId'
                        pagination={false}
                    />
                    <div style={{paddingTop:"25px"}}>
                        <Pagination
                            onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.pageSize}
                            current={this.pageNum}
                            total={this.totalNum}
                        />
                    </div>
                </Modal>
            </>
        );
    }
}

export default AddVideoWithSeriesModal;



