import AdressData from "./AdressData";
import Dict from './Dict';

let dict = {
    videoState:['下线','上线','驳回','审批中'],
    orgState:['已注销','审核通过','审核驳回','审核中'],
    student:["待审核","审批通过","驳回"],
    lectureState:['未提交审核','审核通过','审核驳回','审核中'],
    sex:['女','男'],
    yesOrNo:['是','否'],
    personNum:['20人以下','20-99人','100-499人', '500-999人','1000人以上'],
    userStatus: ['无效', '有效'],
    rate: ['非常差', '差','一般', '较好','非常好'],
    tradeStatus: ['未交易', '已支付','已退款'],
    freeWork:['必修','申请中','已免修'],
    examType:['练习题','考试试卷'],
    paperStatus:['未提交审核','审核中','通过审核','驳回审核'],
    seriesStatus:['编辑中','审核中','审核通过','驳回'],//0--编辑中， 1---提交审核， 2----审核通过， 3----驳回
    noticeType:['系统消息','组织消息','班级消息','定向消息'],
    noticeStatus:['有效','无效'],
    realNameAuth:['未认证','待审核','审核通过'],
    plateformAuth:['未认证','待审核','审核通过'],
    teacherStatus:['下线','上线','驳回','审批中'],
    certStatus:['编辑中','待审核','审核通过','已驳回'],
    //pmaxEdu: ['小学', '初中','高中','大学','其它'],
    //pjoinJobType: ['自助择业','地方安置','其它'],
    // pcategory: ['农村往届', '农村应届','城市往届','城市应届','农民工','退伍军人','下岗失业人员','新型职业农民','其它'],

    role: {
        "0":'个人用户',
        "1":'企业用户',
        "2":'老师用户',
        "3":'三方用户',
        "5":'admin'
    },
    nature:{
        "0":"国企",
        "1":"民营",
        "2":"合资",
        "3":"外商独资",
        "4":"股份制企业",
        "5":"上市公司",
        "6":"代表处",
        "7":"国家机关",
        "8":"事业单位",
        "9":"银行",
        "10":"医院",
        "11":"学校学院",
        "12":"律师事务所",
        "13":"社会团体",
        "14":"其它",
        "15":"港澳台公司"
    },
    companyClass: {
        "0": "请选择",
        "1": "互联网/电子商务",
        "2": "计算机软件IT服务(系统/数据/维护)",
        "3": "电子技术/半导体/集成电路",
        "4": "计算机硬件",
        "5": "通信/电信/网络设备",
        "6": "通信/电信运营、增值服务",
        "7": "网络游戏",
        "8": "基金/证券/期货/投资",
        "9": "保险",
        "10": "银行",
        "11": "信托/担保/拍卖/典当",
        "12": "房地产/建筑/建材/工程",
        "13": "家居/室内设计/装饰装潢",
        "14": "物业管理/商业中心",
        "15": "专业服务/咨询(财会/法律/人力资源等)",
        "16": "广告/会展/公关",
        "17": "中介服务",
        "18": "检验/检测/认证",
        "19": "外包服务",
        "20": "快速消费品（食品/饮料/烟酒/日化）",
        "21": "耐用消费品（服饰/纺织/皮革/家具/家电）",
        "22": "贸易/进出口",
        "23": "零售/批发",
        "24": "租赁服务",
        "25": "教育/培训/院校",
        "26": "礼品/玩具/工艺美术/收藏品/奢侈品",
        "27": "汽车/摩托车",
        "28": "大型设备/机电设备/重工业",
        "29": "加工制造（原料加工/模具）",
        "30": "仪器仪表及工业自动化",
        "31": "印刷/包装/造纸",
        "32": "办公用品及设备",
        "33": "医药/生物工程",
        "34": "医疗设备/器械",
        "35": "航空/航天研究与制造",
        "36": "交通/运输",
        "37": "物流/仓储",
        "38": "医疗/护理/美容/保健/卫生服务",
        "39": "酒店/餐饮",
        "40": "旅游/度假",
        "41": "媒体/出版/影视/文化传播",
        "42": "娱乐/体育/休闲",
        "43": "能源/矿产/采掘/冶炼",
        "44": "石油/石化/化工",
        "45": "电气/电力/水利",
        "46": "环保",
        "47": "政府/公共事业/非盈利机构",
        "48": "学术/科研",
        "49": "农/林/牧/渔",
        "50": "跨领域经营",
    },

    rankIndex:{
        "11":"第一学年上",
        "12":"第一学年下",
        "21":"第二学年上",
        "22":"第二学年下",
        "31":"第三学年上",
        "32":"第三学年下",
        "41":"第四学年上",
        "42":"第四学年下",
        "51":"第五学年上",
        "52":"第五学年下",
        "61":"第六学年上",
        "62":"第六学年下",
        "71":"第七学年上",
        "72":"第七学年下",
        "81":"第八学年上",
        "82":"第八学年下",
    },
    /*
    plateforms:[
        {
           value:'0000',
           name:'花椒'
        },
        {
            value:'0001',
            name:'YY'            
        },
        {
            value:'0002',
            name:'陌陌'            
        }
    ],
    */
}

export default {
    getValue:(key,value,def)=>{
        if(key == null || 
           !dict.hasOwnProperty(key))
        {
            return null;
        }

        var kobj = dict[key];
        if(kobj.hasOwnProperty(value))
        {
            return kobj[value];
        }
        else
        {
            return def;
        }
    },
    
    getPlanValues:(key,value,def)=>{
        if(key == null || 
            !dict.hasOwnProperty(key))
         {
             return null;
         }
         var kobj = dict[key];
         if(value)
         {
             let names = [];
             kobj.map(
                 (item)=>{
                     if(value.indexOf(item.value)>=0)
                     {
                         names.push(item.name);
                     }
                     return null;
                 }
             );

             return names.join(',');
         }
         else
         {
             return def;
         }
    },

    getAdressLabelValue:(pcity)=>{
        if(pcity == null)
        {
            return null;
        }

        for(let i = 0; i < AdressData.length; i++)
        {
            for(let j = 0; j < AdressData[i].children.length; j++)
            {
                let cd = AdressData[i].children[j];
                if(cd.value == pcity || cd.label == pcity)
                {
                    return cd.label;
                }
            }
        }
        return null;
    },


    getPCityFilters:(users)=>{
        let filters = [];
        for(let i=0; i< users.length; i++)
        {
            let hasIn = false;
            if(users[i].pcity)
            {
                for(let j = 0; j < filters.length; j++)
                {
                        if(users[i].pcity === filters[j].value)
                        {
                            hasIn = true;
                            break;
                        }
                }
           }
           else
           {
                hasIn = true;
           }

            if(!hasIn)
            {
                filters.push(
                    {
                        value: users[i].pcity,
                        text: Dict.getAdressLabelValue(users[i].pcity),
                    }
                )
            }
        }
        return filters;
    }
}

export {dict};
