import React, { Component } from 'react';
import {Input, message, Button, Form, Modal} from 'antd';
import connect from "react-redux/es/connect/connect";
import classNames from "classnames";
import _ from 'lodash';
import MyLayout from "../../common/MyLayout";
import api from "../../../api/api";
import '../../../assets/style/common.css';

import empty from  '../../../assets/images/empty.png';
import occupy from '../../../assets/images/occupy.png';
import select from '../../../assets/images/select.png';
import screen from '../../../assets/images/screen.png';

import DataValidate from "../../../config/DataValidate";

class SeatOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sceneData:null,
            seatsData:[],
            selectSeat:[],
            phone:""
        };
    }

    lectureDetail() {
        let sceneId = this.props.match.params.sceneId;
        api.lecture.sceneDetail({sceneId},
            (state,data)=>{
                if(state && data.status === 200) {
                    this.setState({
                        sceneData:data.data,
                    });
                    this.createRowAndCol(data.data.venue.modelId,data.data.tickets);
                    //this.code();//生成票座号（几排几列）
                }
            }
        )
    }

    createRowAndCol(modelId,tickets){   //push的1,代表可选
        api.lecture.groundDetail({modelId},
            (state,data)=>{
                if(state && data.status === 200) {
                    let rowData = [];
                    for(let i = 1; i <= data.data.rows; i++) {
                        let columnData = [];
                        let flag = 0;// 此处没有座位，不用描画
                        let tickValue = null;
                        for(let j = 1; j <= data.data.cols; j++) {
                            tickets.forEach(val => {
                                if(val.rowNo === ""+i && val.colNo === ""+j) {
                                    if (val.soldTime == null) {
                                        flag = 1 // soldTime:售卖时间不存在，此座位可选
                                    }else{
                                        flag = 2 // soldTime:售卖时间存在，此座位已卖出
                                    }
                                    //flag = true; // 有座位
                                    tickValue = val;
                                }
                            });
                            if (flag ) {

                            }
                            columnData.push({
                                tickets:tickValue,
                                seatNumber: flag //0：此处没座位 1：座位可选， 2: 已售 3: "可选座位点击后"
                            })
                        }
                        rowData[i-1] = columnData;
                    }
                    this.setState({
                        seatsData:rowData,
                    });
                }
            }
        );

    }

    renderSeat(){ // 画座位
        if (this.state.seatsData.length <= 0) {
            return ;
        }
        let columns = [];
        for(let i=0,len=this.state.seatsData.length; i<len;i++) {
            columns.push(
                <div key={1000+i} className="row">
                    {this.state.seatsData[i].map((val,index)=>{
                        return <span key = {index}
                                     onClick = {e=>this.click(i,index)}
                                     className = {classNames('seat',
                                         {'empty':val.seatNumber===1,//这个位置有座位 && 没被人买
                                             'occupy':val.seatNumber===2,//这个位置有座位 && 被人买了
                                             'select':val.seatNumber===3 //这个位置有座位 && 选中了
                                         })
                                     }>
                        </span>
                    })}
                </div>);
        }
        return columns;
    };

    renderNumber(){ //行号
        if (this.state.seatsData.length <= 0) {
            return ;
        }
        let spans = [];
        let row = 0;
        //检验每个数组的合并值
        for(let i=0,len=this.state.seatsData.length; i<len; i++) {
            if(this.getSum(this.state.seatsData[i])>0) {
                spans.push(<span key={i}>{++row}</span>);
            } else {
                spans.push(<span key={i}></span>);
            }
        }
        return spans;
    }

    getSum=(arr)=>{
        return arr.reduce(function(prev, curr, index, arr){
            return prev + curr;
        });
    };

    // 0: "这个位置没有座位"，
    // 1: "可选座位"，
    // 2: "已售座位",
    // 3: "可选座位被选后"
    click(row,column){
        let { seatsData, selectSeat } = this.state;
        if(seatsData[row][column].seatNumber===0 || seatsData[row][column].seatNumber===2) {
            return ;
        }
        let tmpRoom = _.cloneDeep(seatsData); // 深拷贝
        let tmpSeat = _.cloneDeep(selectSeat);
        if(tmpRoom[row][column].seatNumber === 1 && tmpSeat.length<4) { // 选定(最多选4张)
            tmpRoom[row][column].seatNumber = 3;
            let code = tmpRoom[row][column].tickets.rowNo+ "排" +tmpRoom[row][column].tickets.colNo+ "列";
            //tmpSeat.push(code);
            tmpSeat.push({
                code:code,
                row:row,
                column:column,
                ticketIds:tmpRoom[row][column].tickets.id
            });
        } else if(tmpRoom[row][column].seatNumber === 3) { // 取消选定
            tmpRoom[row][column].seatNumber = 1;
            let index = _.findIndex(selectSeat,{row,column});
            //let index = tmpSeat.indexOf(tmpRoom[row][column].tickets.rowNo+ "排" +tmpRoom[row][column].tickets.colNo+ "列");
            tmpSeat.splice(index,1);
        }
        this.setState({
            seatsData: tmpRoom,
            selectSeat: tmpSeat
        });
    }

    handleSubmit = e => {  //提交生成订单
        let {selectSeat} = this.state;
        e.preventDefault();
        let ticketIds = [];

        selectSeat.map((item,index)=>{
            ticketIds.push(item.ticketIds);
        });

        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (ticketIds.length>0){
                    api.lecture.lockSeats(
                        {ticketIds, ...values},
                        (state,data)=>{
                            if(state && data.status == 200) {
                                this.props.history.push('/Personal/OrderForm'+'/'+data.data.outTradeNo);
                            }
                            else if (state && data.status == 1000150) {
                                Modal.warning({
                                    title: '售票时间已经结束啦!',
                                    content: '本场售票时间为开场前半小时，请购买其它场次的票吧。',
                                });
                            }
                        })
                }else{
                    message.warning('至少要选择一张票，且单次不能超过四张。');
                }
            }
        });
    };

    componentDidMount() {
        this.lectureDetail();
    }

    render() {
        let { sceneData, seatsData, selectSeat} = this.state;
        const { getFieldDecorator } = this.props.form;
        let userInfo = this.props.userInfo;
        const formItemLayout = {
            wrapperCol: {
                sm: { span: 18},
            },
        };
        return(
            <MyLayout>
                <div className="App">
                    <div className="container">
                        <div className="seat-container">
                            <div className="seat-header">
                                <ul>
                                    <li>
                                        <img src={empty} alt="可选座位"/><span>可选座位</span>
                                    </li>
                                    <li>
                                        <img src={occupy} alt="已售座位"/><span>已售座位</span>
                                    </li>
                                    <li>
                                        <img src={select} alt="已选座位"/><span>已选座位</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="seat-main">
                                <div className="screen">
                                    <img src={screen} alt="屏幕"/>
                                    <h4>讲台中央</h4>
                                </div>
                                <div className="seats">
                                    <span className="line"></span>
                                    { this.renderSeat() }
                                    <div className="seat-number">
                                        { this.renderNumber() }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="msg-container">
                            <div className="info-header">
                                {sceneData!= null && sceneData.teacher.photoPath != null ? <img
                                    alt=''
                                    width={120}
                                    src={sceneData.teacher.photoPath}
                                />:"未上传"}
                                <div className="header-msg">
                                    <h3>{sceneData!= null ? sceneData.name:""}</h3>
                                    <p>
                                        <span>开始时间：</span>
                                        {sceneData!= null ? sceneData.startTime.substring(0,16):""}
                                    </p>
                                    <p>
                                        <span>结束时间：</span>
                                        {sceneData!= null ? sceneData.endTime.substring(0,16):""}
                                    </p>
                                    <p>
                                        <span>主讲人：</span>
                                        {sceneData!= null ? sceneData.teacher.name:""}
                                    </p>
                                    <p>
                                        <span>嘉宾：</span>
                                        {sceneData!= null ? sceneData.guests:""}
                                    </p>
                                </div>
                            </div>
                            <div className="info-main">
                                <p><span>会场名：</span>{sceneData!= null ? sceneData.venue.name:""}</p>
                                <p><span>地址：</span>{sceneData!= null ? sceneData.venue.location:""}</p>
                                <p>
                                    <span>票价(元)：</span>
                                    {sceneData!= null && sceneData.tickets[0]?
                                        "￥"+Number(sceneData.tickets[0].price/100).toFixed(2)+"/张":""
                                    }
                                </p>
                            </div>
                            {
                                userInfo && userInfo.role == 0 ?
                                <div>
                                    <div className="select-item">
                                        <span className="select-label">座位：</span>
                                            <div className="select-seat">
                                            {
                                                selectSeat.length>0 ? selectSeat.map(val=>{
                                                    return <span key={val} className="ticket">{val.code}</span>
                                                }):<span>一次最多选择四张票</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="select-item">
                                        <span className="select-label">总价：</span>
                                        <span>
                                            {sceneData!= null ?
                                                (selectSeat.length * sceneData.tickets[0].price/100).toFixed(2)
                                                :""}元</span>
                                    </div>
                                    <Form onSubmit={this.handleSubmit} {...formItemLayout}>
                                        <Form.Item hasFeedback>
                                            {getFieldDecorator('phone', {
                                                rules: [
                                                    {
                                                        validateStatus:'error',
                                                        required: true,
                                                        message: '请输入购买人手机号!'
                                                    },
                                                    {
                                                        validator: (rule, value, callback) => {
                                                            if(DataValidate.isMobile(value) ) {
                                                                callback();
                                                                return;
                                                            }
                                                            callback('error');
                                                        },
                                                        message: '电话号码格式不对',
                                                    },
                                                ],
                                            })(
                                                <Input style={{"width":260,"marginLeft": 28}}
                                                       placeholder="请输入购买人手机号"
                                                />,
                                            )}
                                        </Form.Item>
                                        <Form.Item >
                                            <Button type="danger"
                                                htmlType="submit"
                                                style={{"width":260,"marginLeft": 28}}
                                                block>
                                                确认选座
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>:
                                ""
                            }
                        </div>
                    </div>
                </div>
            </MyLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default connect(mapStateToProps,null)(Form.create()(SeatOrder));