
import React, { Component } from 'react';
import { Icon, Progress, Button, Tag, Table, Pagination, Select, Col, Row, Form, Divider, Modal, Popover } from 'antd';
import MyLayout from '../common/MyLayout';
import {connect } from 'react-redux';
import api from '../../api/api';
import RoutePath from '../../config/RoutePath';
import ExportJsonExcel from 'js-export-excel';
import TestPaperSelectModal from '../taskadmin/tasksManage/TestPaperSelectModal';
import TestPaperSearchCompnent from '../common/TestPaperSearchCompnent';

class StaticPaper extends Component {

    constructor(props) {
        super(props);
        this.state = { 

            clses:[],
            classId:null,
            judge:0,

            data:[],
            loading:false,

            selectedPaper:null,
            selectedVisible:false,
        };


        this.initPageSize = 8;
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        this.totalNum = 10000;
    }

    feacthClassList()
    {
        let orgId = this.props.match.params.orgId;
        if(orgId)
        {
            //管理员用户
            api.class.getAllClassByOrgId({orgId},
                (state,data)=>{
                    if(state && data.status == 200)
                    {
                        this.setState({clses:data.data});
                        if(data.data.length > 0)
                        {
                            this.setState({classId:data.data[0].classId});
                        }
                        this.research();
                    }
                }
            );
        }
        else
        {
            //老师用户
            api.taskadmin.getMyOcuppyClasses(
                null,
                (state,data)=>{
                    if(state && data.status == 200)
                    {
                        this.setState({clses:data.data});
                        if(data.data.length > 0)
                        {
                            this.setState({classId:data.data[0].classId});
                        }
                        this.research();
                    }
                }
            )
        }
    }

    downLoadWithData(data)
    {
       var option={};
       option.fileName = '测验记录'
       option.datas=[
        {
            sheetData:[],
            sheetName:'测验记录',
            sheetHeader:['姓名','试卷名','得分','答题百分比'],
            columnWidths: [4, 10, 4, 4]
        },
       ];
       for(let i = 0; i < data.length; i++)
       {
           option.datas[0].sheetData.push([
               data[i].name, 
               data[i].title,
               data[i].choiceScore+data[i].completionScore+data[i].shortScore,
               data[i].percent
           ])
       }

       var toExcel = new ExportJsonExcel(option); //new
       toExcel.saveExcel(); //保存
    }

    downloadExcelData(){
        this.setState({loading:true});
        api.exam.searchExamAnswer(
            {
                "condition": this.getSearchCondition(),
                "pageNum": 1,
                "pageSize": 500000,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.downLoadWithData(data.data);
                }
                this.setState({loading:false});
            }
        )
    }

    getSearchCondition()
    {
        return {
            classId:this.state.classId,
            judge:this.state.judge,
            paperId:this.state.selectedPaper?this.state.selectedPaper.paperId:null,
        }
    }

    fetachAnswerRecordList(){
        api.exam.searchExamAnswer(
            {
                "condition": this.getSearchCondition(),
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    componentDidMount()
    {
        this.feacthClassList();
    }

    pageOnChange(pageNum,pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(()=>this.fetachAnswerRecordList(), 200);
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        setTimeout(()=>this.fetachAnswerRecordList(), 200,);
    }

    render() {
        let history = this.props.history;
        let userInfo = this.props.userInfo;
        const columns = [
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '试卷名',
                dataIndex: 'title',
                key: 'title',
                className:"tableOverflow",
            }, 
            {
                title: '考试时间',
                dataIndex: 'kssj',
                key: 'kssj',
                className:"tableOverflow",
                render: (text, record) => (
                    <div>
                    <span>{record.startTime?record.startTime:'不定'}</span>
                    -
                    <span>{record.endTime?record.endTime:'不定'}</span>
                </div>
                )
            },
			/***
            {
                title: '答题状态',
                dataIndex: 'answerSumbit',
                key: 'answerSumbit',
                render: (text, record) => (
                    record.answerSumbit == 0?
                    <Tag color="#f50">未交卷</Tag>
                    :<Tag color="#108ee9">已交卷</Tag>
                )
            }, 
			***/
            {
                title: '判卷',
                dataIndex: 'judge',
                key: 'judge',
                render: (text, record) => (
                    record.judge == 0?
                    <Tag color="#f50">未判卷</Tag>
                    :<Tag color="#108ee9">已判卷</Tag>
                )
            }, 
            {
                title: '完成百分比',
                dataIndex: 'percent',
                key: 'percent',
                render: (text, record) => (
                    <Progress percent={record.percent}/>
                )
            },
            {
                title: '得分',
                dataIndex: 'score',
                key: 'score',
                render: (text, record) => (
                    <span>
                        {
                            record.choiceScore+record.completionScore+record.shortScore
                        }分
                    </span>
                )  
            },
        ];

        if(userInfo && userInfo.role === 2)
        {
            columns.push(           
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                render: (text, record) => (
                    <Button type='primary'
                        onClick={()=>history.push(
                              RoutePath.JudgePaper+"/"+record.uid+"/"+record.paperId)
                        }
                    >
                        判卷
                    </Button>
                )
            });
        }

        columns.push(
            {
                title:'详情',
                dataIndex: 'descrp',
                key: 'descrp',
                render: (text, record) => (
                  <Button
                   type="primary"
                   onClick={
                       ()=>this.props.history.push(RoutePath.FetchPersonalStatics + "/" + record.uid)
                   }
                  >
                    详情
                  </Button>
              )
          }
        );

        let paperTitle = "(未选择)";
        if(this.state.selectedPaper)
        {
            paperTitle = "("+this.state.selectedPaper.title+")";
        }

        return (
            <MyLayout>
                    <Row>
                        <Col span={22}>
                        <Form layout='inline'>
                        <Form.Item label='班级'>
                       {
                        this.state.classId?
                        <Select defaultValue={this.state.classId} onChange={(classId)=>{
                            this.setState({classId});
                            this.research();
                            }}>
                        {
                            this.state.clses.map((item,index)=>(
                                <Select.Option value={item.classId} key={index}>{item.name}</Select.Option>
                            ))
                        }
                        </Select>:null
                       }
                    </Form.Item> 
                    <Form.Item label='判卷'>
                        <Select value={this.state.judge} onChange={(judge)=>{
                            this.setState({judge});
                            this.research();
                            }}>
                            <Select.Option value={null}>不限定</Select.Option>
                            <Select.Option value={0}>未判卷</Select.Option>
                            <Select.Option value={1}>已判卷</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label={'试卷'+paperTitle} >
                            <Popover
                                content={
                                <TestPaperSearchCompnent
                                    onSelectItem={(selectedPaper)=>{
                                        this.setState({selectedPaper,selectedVisible:false});
                                        this.research();
                                    }}
                                    onClose={()=>this.setState({selectedVisible:false})}
                                />}
                                trigger='click'
                                visible={this.state.selectedVisible}
                            >
                                <Button
                                type='dashed'
                                onClick={()=>this.setState({selectedVisible:true})}
                                >选择考卷</Button>
                            </Popover>
                            <Divider type='vertical'/>
                            <Button
                                    onClick={()=>{
                                        this.setState({selectedPaper:null,selectedVisible:false});
                                        this.research();
                                    }}
                                    type='dashed'
                            >清除考卷</Button>
                    </Form.Item>
                    </Form>
                    </Col>
                        <Col span={2}>
                         <Button icon='download' 
                         disabled={this.state.loading}
                        loading={this.state.loading}
                         onClick={()=>this.downloadExcelData()}></Button>
                        </Col>
                    </Row>
                <Divider/>
                <Table
                  dataSource={this.state.data}
                  columns={columns}
                  rowKey={record => record.paperId+record.name}
                  pagination={false}
              />
              <Divider/>
                <Pagination
                            onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.pageSize}
                            current={this.pageNum}
                            total={this.totalNum}
                        />
            </MyLayout>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}

export default connect(mapStateToProps,null)(Form.create()(StaticPaper));




