
import React, { Component } from 'react';
import {Icon, Select,Modal, Table, Divider, PageHeader, Button, Tooltip,Form,Input,message} from 'antd';
import MyLayout from '../../common/MyLayout';
import api from '../../../api/api';
import ComFunction from '../../../api/ComFunction';
import Dict, { dict } from '../../../config/Dict';
import TaskModalSearch from '../../common/TaskModalSearch';
import TestPaperSearchModal from '../../common/TestPaperSearchModal';

const { confirm } = Modal;

class EItemSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            achive:{
                achiveName:"--",
                classId: "--",
            },
            data:[],
            visible:false,
        }
    }

    removeAchiveItemById(classAchiveItemId){
        confirm({
            title: "提示",
            content:"你确定要删除这个导出科目吗?",
            onOk:()=>{
                api.class.deleteAchiveItemByClassAchiveItemId({
                    classAchiveItemId
                    },
                    (state,data)=>{
                        if (state) {
                            if(data.status === 200) {
                                setTimeout(()=>this.getClassAchiveItemListById(), 200);
                                message.success('删除成功!');
                            }
                            else{
                                message.error(data.msg);
                            }
                        }
                    }
                )
            }
        });
    }

    addOrEditClassAchiveItem(values)
    {
        let cbk = (state,data)=>{
            if(state && data.status === 200) {
                this.setState({
                    visible: false,
                });
                this.getClassAchiveItemListById();
            } else {
                message.error(data.msg);
            }
        };
        api.class.addOrEditClassAchiveItem(values,cbk);
    };


    handleSumbit=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.addOrEditClassAchiveItem(values);
            }
        });
    }

    getAchiveDetail(){
        api.class.getClassAchiveDetailById(
            {
                classAchiveId:this.props.match.params.classAchiveId,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        achive:data.data,
                    })
                }
            }
        );
    };

    getClassAchiveItemListById(){
        api.class.getClassAchiveItemListById(
            {
                classAchiveId:this.props.match.params.classAchiveId,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        );
    };

    componentDidMount()
    {
        this.getAchiveDetail();
        this.getClassAchiveItemListById();
    };
    
    render() { 
        let history = this.props.history;

        const { getFieldDecorator } = this.props.form;
        getFieldDecorator('classAchiveId',{});
        getFieldDecorator('classAchiveItemId',{});


        const formItemLayout = {
            labelCol: {
                sm: { span: 4 },
            },
            wrapperCol: {
                sm: { span: 18},
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                sm: {
                    span: 8,
                    offset: 4,
                },
            },
        };

        const columns = [
            {
                title: '学年',
                dataIndex: 'rankIndex',
                key: 'rankIndex',
                className:"tableOverflow",
                render: (text, record) => <div>
                    {Dict.getValue("rankIndex",text,"未知")}
                </div>
            },
            {
                title: '课程任务名',
                dataIndex: 'taskName',
                key: 'taskName',
                className:"tableOverflow",
                render: (text, record) => (
                    <TaskModalSearch 
                       title={
                       <Tooltip
                        title={text}
                        >{text?text.substring(0,5)+'...':"请选择"}
                        </Tooltip>
                        }
                        onSelectItem={(item)=>{
                        record.mtaskId = item.mtaskId;
                        this.addOrEditClassAchiveItem(record);
                       }}
                    />
                ),
            },
            {
                title: '考试试卷名',
                dataIndex: 'examPaperTitle',
                key: 'examPaperTitle',
                className:"tableOverflow",
                render: (text, record) => (
                    <TestPaperSearchModal
                       title={
                        <Tooltip
                        title={text}
                        >{text?text.substring(0,5)+'...':"请选择"}</Tooltip>
                       }
                       onSelectItem={(item)=>{
                        record.examPaperId = item.paperId;
                        this.addOrEditClassAchiveItem(record);
                       }}
                    />

                ),
            },
            {
                title: '补考试卷名',
                dataIndex: 'rexamPaperTile',
                key: 'rexamPaperTile',
                className:"tableOverflow",
                render: (text, record) => (
                    <TestPaperSearchModal
                       title={
                        <Tooltip
                        title={text}
                        >{text?text.substring(0,5)+'...':"请选择"}</Tooltip>
                       }
                       onSelectItem={(item)=>{
                        record.rexamPaperId = item.paperId;
                        this.addOrEditClassAchiveItem(record);
                       }}
                    />
                ),
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button 
                            onClick={()=>{
                                this.props.form.setFieldsValue(record);
                                this.setState({
                                    visible: true,
                                });
                            }}
                            type='ghost'
                        ><Icon type='edit'/></Button>
                        <Divider type="vertical" />
                        <Button 
                            onClick={
                              ()=>this.removeAchiveItemById(record.classAchiveItemId)
                            }
                            type='danger'
                        >删除</Button>
                    </span>
                ),

            }
        ];
        return (<MyLayout>
                <PageHeader
                    style={{padding:"0"}}
                    title="科目配置"
                    subTitle={
                        this.state.achive.classId
                        + ":" 
                        + this.state.achive.achiveName}
                />
                <Divider/>
                <Button type="primary"
                onClick={()=>{
                            this.props.form.setFieldsValue({
                                    classAchiveId:this.props.match.params.classAchiveId,
                                    classAchiveItemId:null,
                                    rankIndex:11,
                                    });
                            this.setState({
                                visible: true,
                            })
                        }}>
                    添加学年
                </Button>
                <Divider dashed/>
                <Table
                    rowKey='classAchiveItemId'
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
                <Modal
                        title="添加/编辑学年"
                        visible={this.state.visible}
                        onCancel={()=>{
                                this.setState({
                                    visible:false
                                })
                        }}
                        okText="确认"
                        cancelText="取消"
                        footer={null}
                        forceRender={true}
                    >
                        <Form {...formItemLayout} onSubmit={this.handleSumbit}>
                            <Form.Item label='学年'>
                                {getFieldDecorator('rankIndex', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择学年!'
                                        },
                                    ],
                                })(
                                    <Select>                                
                                        <Select.Option value={11}>第一学年上</Select.Option>
                                        <Select.Option value={12}>第一学年下</Select.Option>
                                        <Select.Option value={21}>第二学年上</Select.Option>
                                        <Select.Option value={22}>第二学年下</Select.Option>
                                        <Select.Option value={31}>第三学年上</Select.Option>
                                        <Select.Option value={32}>第三学年下</Select.Option>
                                        <Select.Option value={41}>第四学年上</Select.Option>
                                        <Select.Option value={42}>第四学年下</Select.Option>
                                        <Select.Option value={51}>第五学年上</Select.Option>
                                        <Select.Option value={52}>第五学年下</Select.Option>
                                        <Select.Option value={61}>第六学年上</Select.Option>
                                        <Select.Option value={62}>第六学年下</Select.Option>
                                        <Select.Option value={71}>第七学年上</Select.Option>
                                        <Select.Option value={72}>第七学年下</Select.Option>
                                        <Select.Option value={81}>第八学年上</Select.Option>
                                        <Select.Option value={82}>第八学年下</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                            
                            <Form.Item  {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit" size="large" block>
                                    确定
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>

        </MyLayout>
        );
    }
}
 
export default Form.create()(EItemSetting);
