
import React, { Component } from 'react';
import { Progress, Table, Tag } from 'antd';
import api from '../../api/api';
import Dict from '../../config/Dict';

class MyWholeTaskRecord extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            taskData:[] // 我的任务
         };
    }

    getMyPartinTaskList() {
        let uid = this.props.uid;
        api.student.getMyPartinTaskList({uid},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({taskData:data.data,})
                }
            }
        )
    }

    componentDidMount() {
        this.getMyPartinTaskList();
    }

    render() {
        const {taskData} = this.state;
        const taskColumns = [
            {
                title: '任务名',
                dataIndex: 'taskName',
                key: 'taskName',
                className:"tableOverflow",
            },
            {
                title: '任务类型',
                dataIndex: 'taskType',
                key: 'taskType',
                render: (text, record) => (
                    record.taskType == 0?
                        <Tag color="orange">视频</Tag>
                        :<Tag color="cyan">试题</Tag>
                ),
            },
            {
                title: '发布者',
                dataIndex: 'teacherName',
                key: 'teacherName',
            },
            {
                title: '任务进度',
                dataIndex: 'percent',
                key: 'percent',
                render: (text, record) => (
                    <Progress percent={record.percent}/>
                ),
            },
            {
                title: '子项目数',
                dataIndex: 'itemNum',
                key: 'itemNum',
            },
            {
                title: '时间',
                dataIndex: 'tm',
                key: 'tm',
                render: (text, record) => (
                    <span>
                        {record.startTime?record.startTime.substring(0,10):'--'}
                        -
                        {record.endTime?record.endTime.substring(0,10):'--'}
                    </span>
                ),
            },
            {
                title: '免修状态',
                key: 'freeWork',
                dataIndex: 'freeWork',
                render: (text, record) => (
                    <Tag color={text==0?"#2db7f5":text==1?"#87d068":"#f50"}>
                        {
                            Dict.getValue('freeWork',text,'')
                        }
                    </Tag>
                ),
            }
        ];

        return (
            <div>
                    <Table
                            pagination={false}
                            dataSource={taskData}
                            columns={taskColumns}
                     />
            </div>
        );
    }
}

export default MyWholeTaskRecord;


