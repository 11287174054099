import React, { Component } from 'react';
import {Result, Button} from 'antd';
import MyLayout from "./common/MyLayout";

class PaymentFail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lectureData:null,
        };
    }

    render() {
        return(
            <div>
                <Result
                    status="error"
                    title="订单支付失败，请重新支付!"
                    extra={
                        <Button type="primary"
                                key="console"
                                onClick={()=>{
                                        window.location.href="about:blank";
                                        window.close();
                                    }
                                }>
                            知道了
                        </Button>
                    }
                />
            </div>
        )
    }
}

export default PaymentFail;