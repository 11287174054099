import React, { Component } from 'react';
import {Row, Col, List, Avatar, Divider, Button, Input, Tag, Tooltip, Select, Form} from 'antd';
import api from '../../../api/api';

import MyLayout from "../../common/MyLayout";
import FieldSelectModal from "../../../components/FieldSelectModal";
import OrgSelectModal from "../../../components/OrgSelectModal";
const { Option } = Select;
const InputGroup = Input.Group;


class famousTeacherList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orgIds:[],
            orgName:"",
            name:"",
            fid:"",
            status:null,
            selectType:"name",

            data:[],
            pageNum:1,
            pageSize:4,
            totalNum:9,
        };
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.teacherList(), 200);
    }

    teacherList(){
        api.teacher.teacherList(
            {
                "condition": {
                    "fid": this.state.fid,
                    "name": this.state.name,
                    "orgIds":this.state.orgIds,
                    "orgName": this.state.orgName,
                    "status":this.state.status
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:4,
            totalNum:9,
        });
        setTimeout(()=>this.teacherList(), 200,)
    }

    handleChange(data,type){
        switch (type) {
            case "org":
                this.setState({
                    orgIds:[data]
                });
                break;
            case "field":
                this.setState({
                    fid:data
                });
                break
        }
    }
    resetData(){
        this.props.form.resetFields();
        //setTimeout(()=>this.research(), 200,)
    }

    componentDidMount() {
        this.teacherList();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let history = this.props.history;
        return(
            <MyLayout >
                {/*
                <p className="userEditTitle">名师风云榜</p>
                */}
                <Row>
                    <Col push={6} span={18} className="groupPosition">
                        <InputGroup compact>
                            <Select defaultValue="name"
                                    size="large"
                                    onChange={(value)=>this.setState({selectType:value})}>
                                <Option value="name">讲师名</Option>
                                <Option value="org">组织名</Option>
                            </Select>
                            <span style={{ width: '30%'}} >{(() => {
                                    switch (this.state.selectType) {
                                        case "name":
                                            return <Input size="large"
                                                          defaultValue={this.state.name}
                                                          placeholder='请输入讲师名'
                                                          onChange={(e)=>this.setState({name:e.target.value})}
                                            />;
                                        case "org":
                                        // return <OrgSelectModal onChange={(data)=>this.handleChange(data,"org")} />;
                                            return <Input size="large"
                                                          defaultValue={this.state.orgName}
                                                          placeholder='请输入组织名'
                                                          onChange={(e)=>this.setState({orgName:e.target.value})}
                                            />;
                                        //case "fid":
                                            //return  <FieldSelectModal onChange={(data)=>this.handleChange(data,"field")}/>;
                                        default:
                                            return null
                                    }
                                }
                            )()}</span>
                            <div>
                                <Button type='primary'
                                        size="large"
                                        icon="search"
                                        onClick={()=>this.research()}>
                                    搜索</Button>
                            </div>
                        </InputGroup>
                    </Col>
                    <Divider/>
                    <List
                        itemLayout="vertical"
                        size="large"
                        pagination={{
                            onChange: page => {
                                this.pageOnChange(page, this.state.pageSize)
                            },
                            pageSize:this.state.pageSize,
                            current:this.state.pageNum,
                            total:this.state.totalNum
                        }}
                        dataSource={this.state.data}
                        renderItem={item => (
                            <List.Item
                                key={item.name}
                                extra={
                                    <img
                                        width={150}
                                        alt="logo"
                                        src={item.photoPath}
                                    />
                                }
                            >
                                <List.Item.Meta
                                    avatar={<Avatar src={item.photoPath} />}
                                    title={
                                        <div onClick={()=>
                                            history.push('/Personal/FamousTeacherDetail'+'/'+item.tid)}>
                                            <Row gutter={15}>
                                                <Col span={8} className="oneTextOverflow">
                                                    <a >{item.name}</a>
                                                </Col>
                                                <Col span={5}>
                                                    <div style={{color :"#adadad"}}>{item.updatedTime}</div>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                    description={
                                        <p>
                                            <Tooltip title="所属组织" >
                                                <Tag color="cyan"
                                                     onClick={()=>history.push('/Personal/OrgDetailsPersonal'+'/'+item.org.orgId)}>
                                                    {item.org.orgName}
                                                </Tag>
                                            </Tooltip>
                                            <Tooltip title=" 所属领域">
                                                <Tag color="purple">{item.field.name}</Tag>
                                            </Tooltip>
                                        </p>
                                    }
                                />
                                <div className="multipleTextOverflow">
                                    {item.achievement}
                                </div>
                            </List.Item>
                        )}
                    />
                </Row>
            </MyLayout>
        )
    }
}
export default Form.create()(famousTeacherList);