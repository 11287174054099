import React, { Component } from 'react';
import { Tooltip, Tabs, Progress, Button,Form, Table, Divider, PageHeader, Modal, Tag} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import Dict from "../../../config/Dict";
const { confirm } = Modal;
const { TabPane } = Tabs;

class MyPartinTaskList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:[],
            taskType:-1,
        };
    }

    getMyPartinTaskList() {
        api.student.getMyPartinTaskList({
            taskType:this.state.taskType
        },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    applicateFreeTask(mtaskId){
        api.student.applicateFreeTask({mtaskId},
            (state, data)=>{
                if(state && data.status==200) {
                    this.getMyPartinTaskList();
                    Modal.success({
                        title: '提示',
                        content: '免修申请已经提交,请等待审批...!',
                    });
                }
            }
        )
    }

    componentDidMount() {
        this.getMyPartinTaskList();
    }

    onTabsChange(taskType) {
        this.setState({taskType});
        setTimeout(()=>this.getMyPartinTaskList(),200);
    }

    render() {
        let history = this.props.history;

        const columns = [
            {
                title: '任务名',
                dataIndex: 'taskName',
                key: 'taskName',
                className:"tableOverflow",
                render: (text, record) => (
                    <Tooltip
                    title={text}>
                        {text}
                    </Tooltip>
                ),
            },
            {
                title: '任务类型',
                dataIndex: 'taskType',
                key: 'taskType',
                render: (text, record) => (
                    record.taskType == 0?
                    <Tag color="orange">视频</Tag>
                    :<Tag color="cyan">试题</Tag>
                ),
            },
            {
                title: '发布者',
                dataIndex: 'teacherName',
                key: 'teacherName',
            },
            {
                title: '任务进度',
                dataIndex: 'percent',
                key: 'percent',
                render: (text, record) => (
                    <Progress percent={record.percent}/>
                ),
            },
            {
                title: '子项目数',
                dataIndex: 'itemNum',
                key: 'itemNum',
            },
            {
                title: '时间',
                dataIndex: 'tm',
                key: 'tm',
                render: (text, record) => (
                    <span>
                        {
                            record.startTime?record.startTime.substring(0,10):'--'
                        }
                        -
                        {
                            record.endTime?record.endTime.substring(0,10):'--'
                        }
                    </span>
                ),
            },
            {
                title: '免修状态',
                key: 'freeWork',
                dataIndex: 'freeWork',
                render: (text, record) => (
                    <Tag color={text==0?"#2db7f5":text==1?"#87d068":"#f50"}>
                        {
                            Dict.getValue('freeWork',text,'')
                        }
                    </Tag>
                ),
            },
            {
                title: '操作',
                dataIndex: 'applicateFreeTask',
                key: 'applicateFreeTask',
                render: (text, record) => (
                    <span>
                        <Button disabled={record.freeWork==0?false:true}
                            onClick={()=>this.applicateFreeTask(record.mtaskId)}>申请免修</Button>
                        <Divider type='vertical'/>
                        <Button 
                        type='primary'
                        onClick={()=>history.push(
                        '/Personal/student/StudentTaskDetail'+'/'+record.mtaskId)}
                         >开始学习</Button>
                    </span>
                ),
            },
        ];
        return(
            <MyLayout>
                <PageHeader
                    style={{padding:"0"}}
                    title="任务列表"
                    subTitle="我参与的任务列表"
                />
                <Divider/>
                <Tabs defaultActiveKey="-1"  onChange={(key)=>this.onTabsChange(key)}>
                    <TabPane tab="不限" key="-1">
                    </TabPane>
                    <TabPane tab="视频" key="0">
                    </TabPane>
                    <TabPane tab="试卷" key="1">
                    </TabPane>
                </Tabs >
                <Table
                    rowKey='mtaskId'
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
            </MyLayout>
        )
    }
}

export default Form.create()(MyPartinTaskList);