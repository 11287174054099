import React,{ Component } from "react";
import HomeLayout from "../common/HomeLayout";
import Animation from "../common/Animation";

class Admin extends Component{

    constructor(props)
    {
        super(props);
    }

    render()
    {
        return(
            <HomeLayout >
                <div>Admin</div>
                <div>Admin.</div>
            </HomeLayout>
        )
    }

}

export default Admin;

