import React, { Component } from 'react';
import {Select, Form} from 'antd';
import api from "../../api/api";
const { Option } = Select;

class MyTeachersModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onClick(value) {
        this.props.onChange(value.uid)
    }

    render() {
        let teachersData = this.props.teachersData;
        return (
            <div>
                <Select size="large"
                        style={{ width: "100%" }}
                        defaultValue="请选择老师">
                    {
                        teachersData.map((value,index)=>(
                            <Option
                                key={index}
                                value={value.uid}
                                onClick={()=>this.onClick(value)}
                            >{value.userName}</Option>
                        ))
                    }
                </Select>
            </div>
        );
    }
}

export default Form.create()(MyTeachersModal);