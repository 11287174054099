
import React, { Component } from 'react';
import {Layout,Row,Col, Divider } from 'antd';
import { viewMode } from './NavbarSelect';
import { connect } from 'react-redux';
let {Footer} = Layout;


class CommonFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {

         };
    }
    render() {
        let dt = new Date();
        let year = dt.getFullYear();
        return (
            <Footer style={{textAlign:'center'}}>
                <Divider/>
                <Row>
                    <Col span={4}>
                        <img width={100} height={100} src={require('../../assets/images/qrcode.jpg')} />
                        <div>微信公众号</div>
                    </Col>
                    <Col span={5}>
                        <ul className='quick_link'>
                            <li>友情链接</li>
                            <li><a target="_blank" href="http://www.tiansi.com/">天巳集团</a></li>
                            <li><a target="_blank" href="http://www.dlqcxy.net/">大连汽车学院</a></li>
                            <li><a target="_blank" href="http://www.fujiazhai.com/">富家斋拍卖</a></li>
                        </ul>
                    </Col>
                    <Col span={5}>
                        <ul className='quick_link'>
                            <li>兄弟链接</li>
                            <li><a target="_blank" href="https://open.163.com/">网易公开课</a></li>
                            <li><a target="_blank" href="http://jntspx.chinahrt.com/">国家技能提升平台</a></li>
                            <li><a target="_blank" href="http://www.wenchanjimai.com/">文产寄卖</a></li>
                        </ul>
                    </Col>
                    <Col span={5}>
                        <ul className='quick_link'>
                            <li>搜索链接</li>
                            <li><a target="_blank" href="https://www.baidu.com">百度</a></li>
                            <li><a target="_blank" href="https://www.sohu.com/">搜狐</a></li>
                            <li><a target="_blank" href="http://www.sina.com/">新浪</a></li>
                        </ul>
                    </Col>
                    <Col span={5}>
                        <ul className='quick_link'>
                            <li>购物链接</li>
                            <li><a target="_blank" href="https://www.taobao.com">淘宝</a></li>
                            <li><a target="_blank" href="https://www.jd.com/">京东</a></li>
                            <li><a target="_blank" href="http://www.yhd.com/">1号店</a></li>
                        </ul>
                    </Col>
                </Row>
                <Divider/>
                {
                this.props.viewMode === 0 ?
                <Row>
                    <Col span={8}>

                        <a
                            target="_blank" 
                            href="http://beian.miit.gov.cn/">
                            ©2018-{year} 兑泽 德师平台 辽ICP备18015874号-2
                            {/* ©2018-{year} 兑泽 德师平台 辽ICP备18015874号-2 */}
                        </a>
                    </Col> 
                    <Col span={8}>  
                    <a
                        target="_blank" 
                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=21020202000432">
                    <img src={require("../../assets/images/legal_icon.png")} alt="公安备案"></img>
                    &nbsp; 辽公网安备 21020202000432号
                    </a>
                    </Col> 
                    <Col span={8}> 
                    <div>网站咨询电话:0411-62629330</div>
                    </Col> 
                </Row>:null
                }
            </Footer>
        );
    }
}

function mapStateToProps(state) {
    return {
        viewMode: state.user.viewMode,
    };
}

export default connect(mapStateToProps, null)(CommonFooter);