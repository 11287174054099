import React, { Component } from 'react';
import { Steps, Badge,Divider, Form, message, PageHeader, Tabs, Tag, Button, Typography, Descriptions, Modal, Upload, InputNumber, Result} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import QuestionList from "./QuestionList";
import Dict from "../../../config/Dict";
import AddOrEditPaperModal from "./AddOrEditPaperModal";
import { UploadOutlined, DownloadOutlined, WindowsFilled } from '@ant-design/icons';
import XLSX from 'xlsx';
import { setLoadingState } from '../../../reducers/UserInfo';
import TestPaperSelectModal from '../tasksManage/TestPaperSelectModal';
import TestPaperSearchCompnent from '../../common/TestPaperSearchCompnent';

const { TabPane } = Tabs;
const { Paragraph } = Typography;
const { confirm,warning} = Modal;

class AddOrEditQuestion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            detailData:null,

            currentStep:0,
            selectPaper:null,
            visible:false,
            copyRes:false,
        };
    }

    getPaperDetailByPaperId(){
        let paperId = this.props.match.params.paperId;
        api.exam.getPaperDetailByPaperId({paperId},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        detailData:data.data,
                    });
                }else{
                    message.error(data.msg);
                }
            }
        )
    }
   
    sumbitToExam(paperId){

        if(!this.checkError())
        {
            warning({
                title:'您还有没完善的题目，不能提交审核。',
                content:'未完善的题目用红点表示'
            })
            return;
        }

        confirm({
            title: "提示",
            content:"你确定要提交审核这张试卷吗?",
            onOk:()=>{
                api.exam.sumbitToExam({paperId},
                    (state,data)=>{
                        if (state) {
                            if(data.status == 200) {
                                setTimeout(()=>this.getPaperDetailByPaperId(), 200);
                                message.success("审核已经提交,通过后,该试卷将会生效!");
                            }
                        }
                    }
                )
            }
        });
    }

    removePaperById(paperId){
        confirm({
            title: "提示",
            content:"你确定要删除这张试卷吗?",
            onOk:()=>{
                api.exam.removePaperById({paperId},
                    (state, data)=>{
                        if(state && data.status==200) {
                            message.success("删除成功!");
                            this.props.history.push('/taskadmin/MyCreatePaperList')
                        }
                    }
                )
            }
        });
    }

    onChange(ar){
        if (ar){
            this.getPaperDetailByPaperId();
        }
    }

    componentDidMount() {
        this.getPaperDetailByPaperId();
    }

    checkError()
    {
        let paperDetail = this.state.detailData;
        if(paperDetail == null)
        {  
            return false;
        }
        let questions = paperDetail.questions;
        for (let i = 0; i < questions.length; i++) 
        {
              let dt = questions[i];
              if((dt.choices.length == 0 && (dt.examQuestion.itemType <=2 ))
                ||!dt.examQuestion.standardAnswer)
                {
                    return false;
                }
        }

        return true;
    }

    getTypeQuestion(type)
    {
        let paperDetail = this.state.detailData;
        if(paperDetail == null)
        {  
            return {
                res:[],
                scores:0,
                isOK:false,
            };
        }

        let questions = paperDetail.questions;

        let res = [];
        let scores = 0;
        let isOK = true;
        for (let i = 0; i < questions.length; i++) 
        {
            if(questions[i].examQuestion.itemType == type)
            {
                let dt = questions[i];
                scores += dt.examQuestion.itemScore;

              if(
                (dt.choices.length == 0 && (dt.examQuestion.itemType <=2 ))
                ||!dt.examQuestion.standardAnswer)
                {
                    isOK = false;
                }
                res.push(dt);
            }
        }
        
        return {
            res:res,
            scores:scores,
            isOK:isOK,
        };
    }

    isEmpty(obj){

        if(typeof obj === "undefined" )
        {
            return true;
        }

        if(typeof obj === "string")
        {
            if(obj === "")
            {
                return true;
            }
        }

        return false;
    }

    isAnswerValidateInSource(choices,stdanswer)
    {
        for(let i = 0; i < stdanswer.length; i++)
        {
            let isOK = false;
            for(let j = 0; j < choices.length; j++)
            {
                if(choices[j].tmark === stdanswer.charAt(i))
                {
                    isOK = true;
                    break;
                }
            }

            if(!isOK)
            {
                return false;
            }
        }  
        
        return true;
    }

    handleTheSheetData(data){

        let questions = [];
        let qtypes = ['单选题','多选题','不定向选择题','填空题','简答题','判断题'];

        //第一遍扫描，获取所有题目
        for(let i =0; i < data.length; i++)
        {
            let rowNum = i + 2;
            let row = data[i];
            let questionNo = 0;
            if(this.isEmpty(row['编号']))
            {
                message.error("第" + rowNum +"行没有编号");
                return;
            }
            questionNo = row['编号'];

            if(!this.isEmpty(row['类型']))
            {
                //这是一个提干
                let qtype = -1;
                for(let j = 0; j < qtypes.length; j++)
                {
                    if(row['类型'] === qtypes[j])
                    {
                        qtype = j;
                        break;
                    }
                }
                if(qtype === -1)
                {
                    //没找到，报错
                    message.error("编号"+questionNo+":" + "第" + rowNum +"行,不识别类型"+row['类型']);
                    return;
                }
                if(this.isEmpty(row['题干']))
                {
                    //任何题都有提干
                    message.error("编号"+questionNo+":" + "第" + rowNum +"行题干为空");
                    return;
                }
                if(this.isEmpty(row['标准答案']))
                {
                    //任何题都有标准答案
                    message.error("编号"+questionNo+":" + "第" + rowNum +"行标准答案为空");
                    return;
                }
                if(this.isEmpty(row['本题分值']))
                {
                    //任何题都有标准答案
                    message.error("编号"+questionNo+":" + "第" + rowNum +"行本题分值为空");
                    return;
                }

                let choices = [];
                if(qtype <= 2)
                {
                    //选择题
                    for(let j = i; j<data.length; j++)
                    {
                        let choice = data[j];
                        if(choice['编号'] === questionNo)
                        {
                            //本题选项
                            if(this.isEmpty(choice['标识']))
                            {
                                message.error("编号" + questionNo + "的选择题缺乏标识");
                                return;
                            }

                            //标号必须是A-Z
                            var AZ = /^[A-Z]$/;
                            if(!AZ.test(choice['标识']))
                            {
                                message.error("编号" + questionNo +"的选择题的标识不是A-Z");
                                return;
                            }

                            if(this.isEmpty(choice['选项']))
                            {
                                message.error("编号" + questionNo +"的选择题缺乏选项");
                                return;
                            }
                            choices.push({
                                tmark:choice['标识'].toString(),
                                content:choice['选项'].toString()
                            })
                        }
                    }

                    //选项个数检测
                    if(choices.length < 2)
                    {
                        message.error("编号" + questionNo +"少于两个选项，不构成选择题!!");
                        return;
                    }

                    if(qtype === 0)
                    {
                        //单项选择题答案必须是单个字母并且是选项之一
                        let isOK = false;
                        for(let j = 0; j < choices.length; j++)
                        {
                            if(choices[j].tmark === row['标准答案'].toString())
                            {
                                isOK = true;
                                break;
                            }
                        }

                        if(!isOK)
                        {
                            message.error("编号" + questionNo +"标准答案不在选项之中");
                            return;
                        }
                    }

                    if(qtype === 1
                      ||qtype === 2)
                    {
                        //多项选择，不定向选择题
                        if(!this.isAnswerValidateInSource(choices,row['标准答案'].toString()))
                        {
                            message.error("编号" + questionNo +"标准答案不在选项之中");
                            return;
                        }
                    }

                    
                }

                if(qtype === 5)
                {
                    //判断题，必须是T/F
                    let sta = row['标准答案'].toString();
                    if(sta === "T"
                        || sta === "F")
                    {
                        //OK
                    }
                    else
                    {
                        message.error("编号" + questionNo +"标准答案,因为是判断题，应该是T或者是F,本题不满足要求");
                        return;
                    }
                }

                questions.push({
                    userMark:questionNo.toString(),
                    itemScore:parseInt(row['本题分值']),
                    itemType:parseInt(qtype),
                    content:row['题干'].toString(),
                    standardAnswer:row['标准答案'].toString(),
                    choices:choices
                })
            }
        }

        if(questions.length > 0)
        {
            Modal.confirm({
                title:'解析结果确认',
                width:650,
                content:questions.map((item,index)=>(
                    <div key={index}>
                       <div>第{index+1}题:</div>
                       <div>题目:{item.content}</div>
                       <div>分值:{item.itemScore}</div>
                       <div>标准答案:{item.standardAnswer}</div>
                       {
                           item.choices.length>0?
                           item.choices.map((choice,idx)=>(
                                <div key={idx}>
                                    <div>{choice.tmark}:{choice.content}</div>
                                </div>
                           )):null
                       }
                       <Divider/>
                    </div>
                    )),
                okText:'确认导入',
                onOk:()=>{
                    let paperId = this.props.match.params.paperId;
                    setLoadingState(true);
                    api.exam.batchImportQuestions(
                        {
                            paperId:paperId,
                            questions:questions,  
                        },
                        (state,data)=>{
                            setLoadingState(false);
                            if(state && data.status === 200)
                            {
                                message.success("导入成功");
                                this.getPaperDetailByPaperId();
                            }
                            else
                            {
                                message.error(data.msg);
                            }
                        }
                    );
                }
            })
        }

    }

    beforeUpload = file => {
        let data = [];// 存储获取到的数据
        // 通过FileReader对象读取文件
        const fileReader =new FileReader();
        fileReader.readAsBinaryString(file);  //二进制
        fileReader.onload = event => {
         try {
                 const {result } = event.target;
                 // 以二进制流方式读取得到整份excel表格对象
                 const workbook = XLSX.read(result, {type:'binary' });
                 // 遍历每张工作表进行读取（这里默认只读取第一张表）
                 for (const sheet in workbook.Sheets) 
                 {
                    if(workbook.Sheets.hasOwnProperty(sheet)) 
                    {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data =data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        break; // 如果只取第一张表，就取消注释这行
                    }
                }
               this.handleTheSheetData(data);
        
            }catch (e) {
              // 这里可以抛出文件类型错误不正确的相关提示
              message.error('文件类型不正确')
              return false;
            }
        }
        return false;
    }

    downLoadTemplate(){
        window.open("/questions_import_template.xls","_blank");
    }


    clearExamPaperQuestions()
    {
        let paperId = this.props.match.params.paperId;
        confirm({
            title: "!!!提示，本操作不可恢复!!!",
            content:"您真的要清空所有试题吗?(已经被人答过的试题不可删除)",
            onOk:()=>{
                setLoadingState(true);
                api.exam.clearExamPaperQuestions({paperId},
                    (state, data)=>{
                        setLoadingState(false);
                        if(state && data.status === 200) {
                            message.success("清空成功!");
                            this.getPaperDetailByPaperId();
                        }
                        else
                        {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //this.addOrEditAdvertise(values);
                
                let paperIdTo = this.props.match.params.paperId;
                let paperIdFrom = this.state.selectPaper.paperId;
                let parm = {
                    paperIdFrom,
                    paperIdTo,
                    ...values
                }
                api.exam.copyFromOtherPaper(
                    parm,
                    (state,data)=>
                    {
                        let copyRes = true;
                        if(state && data.status === 200) {
                            this.getPaperDetailByPaperId();
                            copyRes = true;
                        }
                        else
                        {
                            copyRes = false;
                            message.error(data.msg);
                        }
                        this.setState({currentStep:2,copyRes});
                    }
                )
            }
        });
    };

    render() {
        const {detailData}=this.state;

        let tabq = [
            {
                type: 5,
                tab: '判断题',
            },
            {
                type: 0,
                tab: '单选题',
            },
            {
                type: 1,
                tab: '多选题',
            },
            {
                type: 2,
                tab: '不定向选择题',
            },
            {
                type: 3,
                tab: '填空题',
            },
            {
                type: 4,
                tab: '简答题',
            }
        ];
        let totalscore = 0;
        for(let i = 0; i < tabq.length; i++)
        {
            let dt = this.getTypeQuestion(tabq[i].type);
            tabq[i].questions = dt.res;
            tabq[i].scores = dt.scores;
            tabq[i].isOK = dt.isOK;
            totalscore += dt.scores;
        }

        const formItemLayout = {
            labelCol: {
                sm: { span: 8 },
            },
            wrapperCol: {
                sm: { span: 10},
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                sm: {
                    span: 10,
                    offset: 8,
                },
            },
        };

       //Integer numJudge = 0;
       //Integer numSingle = 0;
       //Integer numMulti = 0;
       //Integer numNotsure = 0;
       //Integer numComplete = 0;
       //Integer numShort = 0;
        let comedata = [
            {
                label:'判断题数',
                keyword:'numJudge',
            },
            {
                label:'单选题数',
                keyword:'numSingle',
            },
            {
                label:'多项选择题数',
                keyword:'numMulti',
            },
            {
                label:'不定向选择题数',
                keyword:'numNotsure',
            },
            {
                label:'填空题数',
                keyword:'numComplete',
            },
            {
                label:'简答题数',
                keyword:'numShort',
            },
        ]
        const { getFieldDecorator } = this.props.form;
        return(
            <MyLayout>
                <PageHeader
                    title={detailData?detailData.examPaper.title:""}
                    className="site-page-header"
                    subTitle="试卷详情"
                    tags={<Tag color="blue">
                        {Dict.getValue('paperStatus', detailData?detailData.examPaper.status:"", '')}
                        </Tag>}
                >
                    <div className="heading_content">
                        <div className="heading_img">
                            {detailData&&detailData.examPaper?
                                <img
                                    src={detailData.examPaper.coverPath}
                                    width={200}
                                    height={150}
                                    alt='未上传'
                                />:"未上传"}
                        </div>
                        <div className="heading_mes">
                            <Descriptions size="small" column={2}>
                                <Descriptions.Item label="试卷类型">
                                    <Tag color={detailData&&detailData.examPaper.examType==0?"#87d068":"#2db7f5"}>
                                        {Dict.getValue('examType',detailData?detailData.examPaper.examType:"",'')}
                                    </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label="题数/分值">
                                    {detailData?
                                        detailData.examPaper.itemNum+'/'+detailData.examPaper.totalScore:""}
                                </Descriptions.Item>
                                <Descriptions.Item label="答题人数">
                                    {detailData?detailData.examPaper.answerNum:""}
                                </Descriptions.Item>
                                <Descriptions.Item label="创建日期">
                                    {detailData?detailData.examPaper.createTime:""}
                                </Descriptions.Item>
                                <Descriptions.Item label="出题分值">
                                    {totalscore}
                                </Descriptions.Item>
                                <Descriptions.Item label="试卷导入">
                                    <Upload
                                     showUploadList={false}
                                     beforeUpload={this.beforeUpload}
                                     action={null}
                                     accept=".xls,.xlsx"
                                    >
                                        <Button type='primary'>
                                            <UploadOutlined />试卷导入
                                        </Button>
                                    </Upload>
                                    <Divider type='vertical'/>
                                    <Button 
                                    onClick={()=>this.downLoadTemplate()}
                                    type="dashed" 
                                    shape="round">
                                        <DownloadOutlined />模板下载
                                    </Button>
                                </Descriptions.Item>
                                <Descriptions.Item label='考试时间'>
                                        <div>
                                        <span>{detailData && detailData.examPaper.startTime?detailData.examPaper.startTime:'未定'}</span>
                                        -
                                        <span>{detailData && detailData.examPaper.endTime?detailData.examPaper.endTime:'未定'}</span>
                                    </div>
                                </Descriptions.Item>
                                <Descriptions.Item label='考试时长'>
                                        <span>{detailData && detailData.examPaper.duration?detailData.examPaper.duration+'分钟':'不定时'}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label='清空试卷'>
                                    <Button
                                        type='danger'
                                        onClick={
                                            ()=>
                                            this.clearExamPaperQuestions()
                                        }>
                                        清空试卷
                                    </Button>
                                </Descriptions.Item>
                                <Descriptions.Item label='拷贝试卷'>
                                        <Button type='primary'
                                         onClick={
                                             ()=>this.setState({
                                                currentStep:0,
                                                visible:true})
                                         }
                                        >从其它试卷拷贝</Button>
                                </Descriptions.Item>
                                <Descriptions.Item label='提交审核'>
                                    <Button key="3"
                                            type="primary"
                                            disabled={detailData&&(detailData.examPaper.status==1 || detailData.examPaper.status==2)?true:false}
                                            onClick={()=>this.sumbitToExam(detailData?detailData.examPaper.paperId:"")}>
                                    提交审核</Button>
                                </Descriptions.Item>
                            </Descriptions>
                            <Divider/>
                            <Paragraph>
                                {detailData?detailData.examPaper.description:""}
                            </Paragraph>
                        </div>
                    </div>
                </PageHeader>
                <Divider/>
                <Tabs tabPosition="left">
                    {
                        tabq.map((item,index)=>
                        <TabPane
                        tab={
                            <Badge
                            status={item.isOK?'success':'error'}
                            >
                                {item.tab+"("+item.questions.length+"-"+item.scores+")"}
                            </Badge>
                        } 
                        key={index}>
                            <QuestionList paperId={this.props.match.params.paperId}
                                        questionData={item.questions}
                                        questionType={item.type}
                                        onChange={(ar)=>this.onChange(ar)}/>
                        </TabPane>    
                        )
                    }
                </Tabs>
                <Modal
                    title="从其它试卷拷贝"
                    visible={this.state.visible}
                    maskClosable={false}
                    onCancel={()=>{
                        this.setState({
                            currentStep:0,
                            visible:false,
                        });
                    }}
                    footer={null}
                    width={800}
                >
                <div>
                        <Steps
                            current={this.state.currentStep}
                        >
                            <Steps.Step title="参考试卷选择" />
                            <Steps.Step title="参数选择拷贝" />
                            <Steps.Step title="拷贝结果" />
                        </Steps>
                        <Divider/>
                    {
                        this.state.currentStep === 0?
                        <TestPaperSearchCompnent
                            onSelectItem={
                                (selectPaper)=>{
                                    this.setState({
                                         currentStep:1,
                                         selectPaper:selectPaper,
                                    }); 
                                }
                            }
                        />:null
                    }
                    {
                        this.state.currentStep === 1?
                        <div>
                            {/**<div>您选择的试卷:{this.state.selectPaper?this.state.selectPaper.title:'--'}</div>**/}
                            <Form {...formItemLayout} onSubmit={this.handleSubmit} >
                            {
                                comedata.map((item,index)=>(
                                    <Form.Item label={item.label} key={index}>
                            {
                                    getFieldDecorator(item.keyword, {
                                            initialValue:0,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '必须输入!'
                                                },
                                            ],
                                        })(
                                        <InputNumber
                                            placeholder="大于0整数"
                                            style={{width:'100%'}}
                                            min={0}
                                            step={1}
                                            precision={0}
                                        />,
                                    )}
                                </Form.Item>
                                ))
                            }
                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit">
                                    开始拷贝
                                </Button>
                            </Form.Item>
                            </Form>
                        </div>
                        :null
                    }
                    {
                        this.state.currentStep === 2?
                        <>
                            <Result
                                status={this.state.copyRes?'success':'error'}
                                title={this.state.copyRes?'拷贝成功':'拷贝失败'}
                                extra={
                                    [
                                        <Button type='primary'
                                        onClick={()=>{
                                            this.setState({
                                                currentStep:0,
                                                visible:false,
                                            });
                                        }}
                                        >确定</Button>
                                    ]
                                }
                            />
                        </>:null
                    }
                    </div>
                </Modal>
            </MyLayout>
        )
    }
}
export default Form.create()(AddOrEditQuestion);