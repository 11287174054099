import React, { Component } from 'react';
import Root from './Root';
import { Modal,Spin, Alert} from 'antd';
import {connect } from 'react-redux';

class LoadingWapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>
             {
             this.props.sysReady?
            <Root/>
             :
             <div>
                 <Spin tip="加载中，请稍后..." >
                     <Alert
                         message="加载页面"
                         description="页面加载中请稍后......."
                         type="info"
                     />
                 </Spin>
             </div>
            }
             <Modal
             visible={this.props.isLoading}
             closable={false}
             centered={true}
             width={170}
             footer={null}>
                <Spin tip="处理中，请稍后..."></Spin>
             </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
      isLoading: state.user.isLoading,
      sysReady: state.user.sysReady,
    };
}

export default connect(mapStateToProps,null)(LoadingWapper);