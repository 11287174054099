import React, {Component} from 'react';
import {Row, Col, Input, List, Tag, Icon, Divider, Button, Tooltip, Form, Select, Card, Pagination} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import OrgSelectModal from "../../../components/OrgSelectModal";
import FieldSelectModal from "../../../components/FieldSelectModal";
import RoutePath from "../../../config/RoutePath";

const {Meta} = Card;

const {Option} = Select;
const InputGroup = Input.Group;

const IconText = ({type, text}) => (
    <span>
    <Icon type={type} style={{marginRight: 8}}/>
        {text}
  </span>
);

class moreLectureList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lectureName: "",
            startTime: "",
            endTime: "",
            orgIds: [],
            teahcerNames: [],
            selectType: "teacher",

            data: [],
            pageNum: 1,
            pageSize: 4,
            totalNum: 8,
        };
    }

    pageOnChange(pageNum, pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum: this.state.totalNum
        });
        setTimeout(() => this.updateData(), 200);
    }

    updateData() {
        api.lecture.lectureList(
            {
                "condition": {
                    "beforeEnd": true,
                    "endTime": this.state.endTime,
                    "lectureName": this.state.lectureName,
                    "orgIds": this.state.orgIds,
                    "startTime": this.state.startTime,
                    "teahcerNames": this.state.teahcerNames,
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data) => {

                if (state && data.status == 200) {
                    this.setState({
                        data: data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum: data.totalNum
                    })
                }
            }
        )
    }

    research() {
        this.setState({
            pageNum: 1,
            pageSize: 5,
            totalNum: 9,
        });
        setTimeout(() => this.updateData(), 200,)
    }

    resetData() {
        this.props.form.resetFields();
        //setTimeout(()=>this.research(), 200,)
    }

    componentDidMount() {
        this.updateData();
    }

    render() {
        let history = this.props.history;
        const {getFieldDecorator} = this.props.form;
        return (
            <MyLayout>
                {/*
                <p className="userEditTitle">讲座列表</p>
                */}
                <Row>
                    <Col push={6} span={18} className="groupPosition">
                        <InputGroup compact>
                            <Select defaultValue="teacher"
                                    size="large"
                                    onChange={(value) => this.setState({selectType: value})}>
                                <Option value="teacher">讲师名</Option>
                                <Option value="lecture">讲座名</Option>
                            </Select>
                            <span style={{width: '30%'}}>{(() => {
                                    switch (this.state.selectType) {
                                        case "lecture":
                                            return <Input size="large"
                                                          defaultValue={this.state.lectureName}
                                                          placeholder='请输入讲座名'
                                                          onChange={(e) => this.setState({lectureName: e.target.value})}
                                            />;
                                        case "teacher":
                                            return <Input size="large"
                                                          defaultValue={this.state.teahcerNames}
                                                          placeholder='请输入讲师名'
                                                          onChange={(e) => this.setState({teahcerNames: [e.target.value]})}
                                            />;
                                        default:
                                            return null
                                    }
                                }
                            )()}</span>
                            <div>
                                <Button type='primary'
                                        size="large"
                                        icon="search"
                                        onClick={() => this.research()}>
                                    搜索</Button>
                            </div>
                        </InputGroup>
                    </Col>
                    <Divider/>
                    <List
                        grid={{gutter: 20, column: 4}}
                        itemLayout="vertical"
                        size="large"
                        dataSource={this.state.data}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                <Card hoverable
                                      cover={
                                          <img alt="封面图片"
                                               style={{height: "170px"}}
                                               src={item.posterPath}
                                          />
                                      }
                                      onClick={() => history.push('/Personal/LectureSceneDetail' + '/' + item.lid)}
                                >
                                    <Meta title={item.name}
                                          style={{height: "100px"}}
                                          description={
                                                <div className="twoElements">
                                                    <Tooltip title={item.intro}>
                                                        {item.intro}
                                                    </Tooltip>
                                                </div>
                                          }
                                    />
                                </Card>
                            </List.Item>
                        )}
                    />
                    <Pagination
                        onChange={(pageNum, pageSize) => this.pageOnChange(
                            pageNum, this.state.pageSize
                        )}
                        pageSize={this.state.pageSize}
                        current={this.state.pageNum}
                        total={this.state.totalNum}
                    />
                </Row>
            </MyLayout>
        )
    }
}

export default Form.create()(moreLectureList);