import React, { Component } from 'react';
import {Col,Row, List, Avatar, Select, Divider, Button, Input} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import api from '../../../../api/api';
import MyLayout from "../../../common/MyLayout";
import Dict from "../../../../config/Dict";
import RoutePath from '../../../../config/RoutePath';
import Action from '../../../../actions/Action';

const { Option } = Select;
const InputGroup = Input.Group;

class MyOrgList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectType:"legalPersonName",
            legalPersonName: "",
            orgName: "",
            status:null,

            data:[],
            pageNum:1,
            pageSize:4,
            totalNum:9,
        };
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.updateData(), 200);
    }

    updateData() {
        api.org.myOrgList(
            {
                "condition": {
                    "legalPersonName": this.state.legalPersonName,
                    "orgName": this.state.orgName,
                    "status":this.state.status,
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:4,
            totalNum:9,
        });
        setTimeout(()=>this.updateData(), 200,)
    }

    componentDidMount() {
        this.updateData();
    }

    render() {
        let history = this.props.history;
        return(
            <MyLayout sider={"InfoManageSider"}>
                <div className="userEditTitle">组织列表</div>
                <Divider />
                <Row gutter={5}>
                    <Col span={3}>
                        <Select size="large" style={{ width: 120 }}
                                defaultValue={this.state.status}
                                onChange={(v)=>{
                                    this.setState({status:v});
                                    this.research();
                                }}>
                            <Option value={null}>所有</Option>
                            <Option value={0}>已注销</Option>
                            <Option value={1}>审核通过</Option>
                            <Option value={2}>审核驳回</Option>
                            <Option value={3}>审核中</Option>
                        </Select>
                    </Col>
                    <Col span={17}>
                        <InputGroup compact style={{marginLeft:"20px"}}>
                            <Select size="large"
                                    defaultValue="legalPersonName"
                                    onChange={(value)=>this.setState({selectType:value})}>
                                <Option value="legalPersonName">法人名</Option>
                                <Option value="orgName">公司名</Option>
                            </Select>
                            <span style={{ width: '40%'}} >{(() => {
                                    switch (this.state.selectType) {
                                        case "legalPersonName":
                                            return <Input size="large"
                                                          defaultValue={this.state.legalPersonName}
                                                          placeholder='请输入法人名'
                                                          onChange={(e)=>this.setState({legalPersonName:e.target.value})}
                                            />;
                                        case "orgName":
                                            return <Input size="large"
                                                          defaultValue={this.state.orgName}
                                                          placeholder='请输入公司名'
                                                          onChange={(e)=>{
                                                              this.setState({orgName:e.target.value})}}
                                            />;
                                        default:
                                            return null
                                    }
                                }
                            )()}</span>
                            <div>
                                <Button type='primary'
                                        size="large"
                                        icon="search"
                                        onClick={()=>this.research()}>
                                    搜索</Button>
                            </div>
                        </InputGroup>
                    </Col>
                    <Col span={4}>
                        <Button type="primary" size="large"
                                onClick={()=>history.push('/company/AddApplyOrg'+'/'+'addOrg')}>
                            录入新的组织</Button>
                    </Col>
                </Row>
                <Divider dashed/>
                <List itemLayout="vertical"
                    size="large"
                    pagination={{
                        onChange: page => {
                            this.pageOnChange(page, this.state.pageSize)
                        },
                        pageSize:this.state.pageSize,
                        current:this.state.pageNum,
                        total:this.state.totalNum
                    }}
                    dataSource={this.state.data}
                    renderItem={item => (
                        <List.Item
                            key={item.title}
                            extra={
                                <img
                                    width={150}
                                    alt="logo"
                                    src={item.logoFileId}
                                />
                            }
                        >
                            <List.Item.Meta
                                avatar={<Avatar src={item.logoFileId} />}
                                title={
                                    <Row>
                                        <Col span={22}>
                                            <a onClick={()=>history.push('/company/OrgDetail'+'/'+item.orgId)}>
                                                <div className="oneTextOverflow">
                                                    {item.orgName}
                                                </div>
                                            </a>
                                        </Col>
                                        <Col span={2}>
                                            <Button type="link"
                                                    icon={<DownloadOutlined />}
                                                    onClick={()=>history.push(RoutePath.OrgAdmin+'/'+item.orgId)}>
                                                公司管理
                                            </Button>
                                        </Col>
                                    </Row>

                                }
                                description={
                                    "法人："+item.legalPersonName +" "+"" +
                                    "【"+ Dict.getValue('nature',item.nature,'')+
                                    "/"+ Dict.getValue('companyClass',item.companyClass,'')+
                                    "/"+ Dict.getValue('personNum',item.personNum,'')+
                                    "】"
                                }
                            />
                            <div className="multipleTextOverflow">
                                {item.introduce}
                            </div>
                        </List.Item>
                    )}
                />
            </MyLayout>
        )
    }
}
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveUserInfoToStore: (data) => dispatch(
            {
                type: Action.SAVE_USER_INFO,
                data: data,
            }),
    }
}

//export default connect(mapStateToProps,mapDispatchToProps)(MyOrgList);
export default MyOrgList;