import React, { Component } from 'react';
import {Col, Row, Button, Descriptions, Badge, message, Modal, Form} from 'antd';
import MyLayout from "../../../common/MyLayout";
import Dict from '../../../../config/Dict';
import api from "../../../../api/api";
import connect from "react-redux/es/connect/connect";
import AddOrEditVenue from "../venue/AddOrEditVenue";

const { confirm } = Modal;

class VenueDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            venueData:null,
        };
    }

    venueDetail() {
        let venueId = this.props.match.params.venueId;
        api.lecture.venueDetail({venueId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        venueData:data.data,
                    })
                }
            }
        )
    }

    handle=(type)=>{
        let venueId = this.props.match.params.venueId;
        switch (type) {
            case "cancel":
                confirm({
                    title: '你确定要注销这会场吗?',
                    content: '注销的会场不会被删除，注销后申请者可以选择重新上线。',
                    onOk:()=>{
                        api.lecture.offVenue({venueId},
                            (state,data)=>{
                                if(state && data.status == 200) {
                                    if(this.state.role == 5){
                                        this.props.history.push('/admin/PendingVenueList')
                                    }else{
                                        this.venueDetail();
                                    }
                                }
                            }
                        )
                    },
                });
                break;
            case "online":
                let id = venueId;
                confirm({
                    title: '你确定要重新上线这个会场吗?',
                    content: '重新上线的会场将进入[审批中]的状态，管理员审批通过后，这个会场将重新上线。',
                    onOk:()=>{
                        api.lecture.addOrEditVenue(
                            {id},
                            (state,data)=>{
                                if(state && data.status == 200) {
                                    this.venueDetail();
                                } else {
                                    message.error(data.msg);
                                }
                            }
                        )
                    },
                });
                break;
            case "delete":
                confirm({
                    title: '你确定要删除这个会场吗?',
                    content: '您删除的会场将不再显示在会场列表中。',
                    onOk:()=>{
                        api.lecture.delVenue(
                            {venueId},
                            (state,data)=>{
                                if(state && data.status == 200) {
                                    this.props.history.push('/company/MyVenueList');
                                }
                            }
                        )
                    }
                });
                break;
        }
    };

    examineOrg=(status)=>{
        let venueId = this.props.match.params.venueId;
        let title = "";
        let content = "";
        if (status == 1){
            title = "你确定要[审批通过]这个会场吗?";
            content = "会场[审批通过]后才可以创建讲座等。"
        }else{
            title = "你确定要[审批驳回]这个会场?";
            content = "会场[审批驳回]后信息会返回给请求者。"
        }
        confirm({
            title: title,
            content:content,
            onOk:()=>{
                api.lecture.examineVenue({venueId,status},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.props.history.push('/admin/PendingVenueList');
                        }
                    }
                )
            }
        });
    };

    componentDidMount() {
        this.venueDetail();
    }

    render() {
        const {
            venueData= null
        } = this.state;
        return(
            <MyLayout >
                <div>
                    <Descriptions title="会场详情" bordered>
                        <Descriptions.Item key='createdTime' label="创建时间" span={2}>
                            {venueData!= null?venueData.createdTime:""}
                        </Descriptions.Item>
                        <Descriptions.Item key='updatedTime' label="更新时间" span={2}>
                            {venueData!= null && venueData.updateTime=="" ? venueData.updateTime:"无更新"}
                        </Descriptions.Item>
                        <Descriptions.Item label="会场名" span={2}>
                            {venueData!= null?venueData.name:""}
                        </Descriptions.Item>
                        <Descriptions.Item label="审核状态" >
                            {venueData!= null? <Badge
                                status="processing"
                                text={Dict.getValue('orgState',venueData.status,'')} />:null
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="会场位置" span={4}>
                            {venueData!= null?venueData.location:""}
                        </Descriptions.Item>
                        <Descriptions.Item label="使用的模板" span={2}>
                            {venueData!= null?venueData.model.name:""}
                        </Descriptions.Item>
                        <Descriptions.Item label="举办单位" span={2}>
                            {venueData!= null?venueData.org.orgName:""}
                        </Descriptions.Item>
                    </Descriptions>
                    {
                        this.props.userInfo.role != 5 ?
                        <Row style={{padding:20}}>
                            {
                                venueData!= null && venueData.status !=0 ?
                                <Col span={2}>
                                    <Button type="primary" onClick={()=>this.handle("cancel")}>注销会场</Button>
                                </Col>:
                                <div>
                                    <Col span={2}>
                                        <Button type="primary" onClick={()=>this.handle("online")}>重新上线</Button>
                                    </Col>
                                    <Col span={2}>
                                        <Button type="primary" onClick={()=>this.handle("delete")}>删除会场</Button>
                                    </Col>
                                </div>
                            }
                            <Col span={2}>
                                <AddOrEditVenue tagType={"edit"}
                                                 listData={venueData}
                                                 onChange={(ar)=>this.onChange(ar)}
                                />
                            </Col>
                        </Row>:
                        <Row style={{paddingTop:"25px"}}>
                            <Col span={2}>
                                <Button type="primary"
                                        onClick={()=>this.examineOrg(1)}
                                >
                                    通过审批</Button>
                            </Col>
                            <Col span={2}>
                                <Button type="primary"
                                        onClick={()=>this.examineOrg(2)}
                                >
                                    驳回审批</Button>
                            </Col>
                            <Col span={2}>
                                <Button type="primary" onClick={()=>this.cancellation()}>注销会场</Button>
                            </Col>
                        </Row>
                    }
                </div>
            </MyLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default connect(mapStateToProps,null)(Form.create({ name: 'VenueDetail' })(VenueDetail));
//export default VenueDetail;