import React, { Component } from 'react';
import {Descriptions, Divider, Row, Col, Statistic, PageHeader} from 'antd';
import MyLayout from "../../common/MyLayout";
import UserSettingSider from "../../common/sider/UserSettingSider";
import InfoModify from "./InfoModify";
import connect from "react-redux/es/connect/connect";
import Dict from '../../../config/Dict';
import AdressData from '../../../config/AdressData';
import emptyImg from "../../../assets/images/emptyImg.png";


class UserSetting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalShow:false,
            changeNewPwd:""
        };
    }


    newPwdFun(newPwd){
        this.setState({
            changeNewPwd:newPwd
        })
    }


    getAdressLabelValue(user)
    {
        if(user == null)
        {
            return null;
        }
        let pcity = user.pcity;
        if(pcity == null)
        {
            return null;
        }

        for(let i = 0; i < AdressData.length; i++)
        {
            for(let j = 0; j < AdressData[i].children.length; j++)
            {
                let cd = AdressData[i].children[j];
                if(cd.value == pcity || cd.label == pcity)
                {
                    return cd.label;
                }
            }
        }
        return null;
    }

    render() {
        let data = this.props.userInfo;
        return (
            <MyLayout sider={"UserSettingSider"}>
                <div>
                    <>
                        <Row gutter={16}>
                            <Col span={18}>
                                <div className="userEditTitle" >我的基本信息</div>
                            </Col>
                            <Col span={4}>
                                <div style={{marginLeft:"80px"}}>
                                    <InfoModify btnName={"修改基本信息"} />
                                </div>
                            </Col>
                        </Row>
                        <Divider dashed/>
                        <PageHeader style={{paddingTop: "0"}}>
                            <Row>
                                <Col span={3}>
                                    <img className="head_portrait"
                                         width={80}
                                         src={data.profile?data.profile:emptyImg}
                                    />
                                </Col>
                                <Col span={12}>
                                    <p className="myBaseInfo"><span>用户名：</span>{data!= null?data.name:""}</p>
                                    <p className="myBaseInfo"><span>性別：</span>{data!= null ? Dict.getValue('sex',data.sex,'未添加'):""}</p>
                                </Col>
                            </Row>
                        </PageHeader>
                    </>
                    {data&&data.role == 0 ?
                        <>
                            <Descriptions bordered>
                                <Descriptions.Item key='pnature' label="政治面貌" span={1}>
                                    {data&&data.pnature!=null ? data.pnature:"未添加"}
                                </Descriptions.Item>
                                <Descriptions.Item key='pcity' label="城市" span={1}>
                                    {this.getAdressLabelValue(data)}
                                </Descriptions.Item>
                                <Descriptions.Item key='paddr' label="详细地址" span={2}>
                                    {data&&data.paddr!=null ? data.paddr:"未添加"}
                                </Descriptions.Item>
                                <Descriptions.Item key='pmaxEdu' label="最高学历">
                                    {data&&data.pmaxEdu!=null ? data.pmaxEdu:"未添加"}
                                </Descriptions.Item>
                                <Descriptions.Item key='pmajor' label="报考专业" span={2}>
                                    {data&&data.pmajor!=null ? data.pmajor:"未添加"}
                                </Descriptions.Item>
                                <Descriptions.Item key='prewordPunish' label="奖惩情况" span={4}>
                                    {data&&data.prewordPunish!=null ? data.prewordPunish:"未添加"}
                                </Descriptions.Item>
                                <Descriptions.Item key='pworkUnit' label="工作单位" span={2}>
                                    {data&&data.pworkUnit!=null ? data.pworkUnit:"未添加"}
                                </Descriptions.Item>
                                <Descriptions.Item key='pduty' label="职位" span={2}>
                                    {data&&data.pduty!=null ? data.pduty:"未添加"}
                                </Descriptions.Item>
                                <Descriptions.Item key='pworkWeekend' label="周末是否工作" span={3}>
                                    {data&&data.pworkWeekend ? data.pworkWeekend:"未添加"}
                                </Descriptions.Item>
                                <Descriptions.Item key='pcategory' label="考生类别" span={3}>
                                    {data&&data.pcategory!=null ?  data.pcategory:"未添加"}
                                </Descriptions.Item>
                                {
                                    data&&data.pcategory == "退伍军人"?
                                        <>
                                            <Descriptions.Item key='pretireTime' label="退伍时间" span={2}>
                                                {data&&data.pretireTime!=null ? data.pretireTime:"未添加"}
                                            </Descriptions.Item>
                                            <Descriptions.Item key='pjoinJobType' label="择业方式" span={2}>
                                                {data&&data.pjoinJobType!=null ? data.pjoinJobType:"未添加"}
                                            </Descriptions.Item>
                                            <Descriptions.Item key='parmRewordPunish' label="服役期间奖惩" span={3}>
                                                {data&&data.parmRewordPunish!=null ? data.parmRewordPunish:"未添加"}
                                            </Descriptions.Item>
                                            <Descriptions.Item key='pretireArm' label="退役部队" span={3}>
                                                {data&&data.pretireArm!=null ? data.pretireArm:"未添加"}
                                            </Descriptions.Item>
                                        </>:""
                                }
                            </Descriptions>
                        </>:""
                    }
                </div>
            </MyLayout>
        );
    }
}
//export default UserSetting;
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default connect(mapStateToProps,null)(UserSetting);