import { Button, Comment, Form, Input } from 'antd'
import React from 'react'
import api from '../../../api/api'

const {TextArea} = Input
class AddComment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            commentValue: '',
            submitting: false,
            showValue: true,
        }
    }
    addNewsComment = id => {
        api.news.addNewsComment(
            {
                id,
                content: this.state.commentValue,
            },
            (state, data) => {
                if (state && data.status === 200) {
                    if(this.props.onUpdate)
                    {
                        this.props.onUpdate()
                    }
                }
            }
        )
    }
    addCommentComment = id => {
        api.news.addCommentComment({
            id,
            content: this.state.commentValue,
        },
        (state, data) => {
            if (state && data.status === 200) {
                if(this.props.onUpdate)
                {
                    this.props.onUpdate()
                }
            }
        })
    }
    
    handleSubmit = () => {
        const commonCommentId = this.props.commonCommentId
        if (!this.state.commentValue) {
            alert('请输入评论内容')
            return
        }
        this.props.commentType === '0'
            ? this.addNewsComment(commonCommentId)
            : this.addCommentComment(commonCommentId)

        this.setState({
            submitting: true,
        })

        setTimeout(() => {
            this.setState({
                submitting: false,
                commentValue: '',
                showValue: false,
            })
        }, 1000)
    }
    handleChange = e => {
        this.setState({
            commentValue: e.target.value,
        })
    }
    render() {
        return this.state.showValue ? (
            <Comment
                content={
                    <div>
                        <Form.Item>
                            <TextArea
                                rows={this.props.commentRows}
                                onChange={this.handleChange}
                                value={this.state.commentValue}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                htmlType="submit"
                                loading={this.state.submitting}
                                onClick={this.handleSubmit}
                                type="primary"
                            >
                                发表评论
                            </Button>
                        </Form.Item>
                    </div>
                }
            />
        ) : null
    }
}

export default AddComment
