var emailRegExp = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
var mobileRegExp = /^1[0-9]{10}$/;
var passWordRegExp = /^[A-Za-z\d]{6,16}$/;
var phoneRegExp = /^0\d{2,3}-\d{6,8}$/;
var idNumberRegExp = /^[A-Za-z\d][A-Za-z\d]{17}$/;
var uidRegExp = /^[A-Za-z][A-Za-z\d]{5,15}$/;
var mobileCode = /^[A-Za-z\d]{2,8}$/;
var emailCode = /^[A-Za-z\d]{2,8}$/;
var chineseName = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/;
var englishName = /^[A-Za-z][A-Za-z\s]*[A-Za-z]$/;
var certNo = /^[0-9a-zA-Z]{16}$/;
var classId = /^[0-9a-zA-Z]{6,16}$/;
var choiceRegExp = /^(?!.*(.).*\1)[a-zA-Z]*$/;
export default {
    isIdNumber:function(text)
    {
        return idNumberRegExp.test(text);
    },
    isEmail: function (text) {
        return emailRegExp.test(text);
    },
    isMobile: function (text) {
        return mobileRegExp.test(text);
    },
    isPassWord: function (text) {
        return passWordRegExp.test(text);
    },
    isPhone: function (txt) {
        return phoneRegExp.test(txt);
    },
    isUid: function(txt)
    {
        return uidRegExp.test(txt);
    },
    isMobileCode: function(txt)
    {
        return mobileCode.test(txt);
    },
    isEmailCode: function(txt)
    {
        return emailCode.test(txt);
    },
    isChineseName: function(txt)
    {
        return chineseName.test(txt);
    },
    isEnglishName: function(txt)
    {
        return englishName.test(txt);
    },
    isCertNo: function(txt)
    {
        return certNo.test(txt);
    },
    isClassId:function (txt) {
        return classId.test(txt);
    },
    isChoice:function (txt) {
        return choiceRegExp.test(txt);
    }
}

