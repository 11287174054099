import React, { Component } from 'react';
import {Col, Row, Modal, Table, Pagination, Divider, Input, Button, Form, Select} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import Dict from "../../../config/Dict";
import OrgSelectModal from "../../../components/OrgSelectModal";
import FieldSelectModal from "../../../components/FieldSelectModal";
const { confirm } = Modal;

class PendingTchList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fid: "",
            name: "",
            orgIds:[],
            orgName:"",
            status: null,

            data:[],
            pageNum:1,
            pageSize:8,
            totalNum:9,
        };
    }

    handleChange(data,type){
        switch (type) {
            case "org":
                this.setState({
                    orgIds:[data]
                });
                break;
            case "field":
                this.setState({
                    fid:data
                });
                break
        }
    }

    examineOrg(status,teacherId){
        let title = "";
        let content = "";
        if (status == 1){
            title = "你确定要[审批通过]这个讲师吗?";
            content = "讲师[审批通过]后才可以被用户所看见哦。"
        }else{
            title = "你确定要[审批驳回]这个讲师吗?";
            content = "讲师[审批驳回]后信息会返回给请求者。"
        }
        confirm({
            title: title,
            content:content,
            onOk:()=>{
                api.teacher.examineTeacher({teacherId,status},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.research();
                        }
                    }
                )
            }
        });
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.updateData(), 200);
    }

    updateData() {
        api.teacher.pendingTeacherList({
                "condition": {
                    "fid": this.state.fid,
                    "name": this.state.name,
                    "orgIds":this.state.orgIds,
                    "orgName": "",
                    status: this.state.status
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:4,
            totalNum:9,
        });
        setTimeout(()=>this.updateData(), 200,)
    }

    componentDidMount() {
        this.updateData();
    }

    render() {
        let history = this.props.history;
        const columns = [
            {
                title: '讲师姓名',
                dataIndex: 'name',
                key: 'name',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow:'ellipsis',
                            cursor:'pointer'
                        }
                    }
                },
                render:  (text, record) => (
                    <a onClick={()=>history.push('/company/TeacherDetail'+'/'+record.tid)}>{text}</a>
                )
            },
            {
                title: '更新时间',
                dataIndex: 'createdTime',
                key: 'createdTime',
                render: (text, record) => (
                    <span>
                        {record.updatedTime?record.updatedTime:record.createdTime}
                    </span>
                ),
            },
            {
                title: '所属组织',
                key: 'org',
                render: (text, record) => (
                    <span>{record.org.orgName}</span>
                ),
            },
            {
                title: '所属领域',
                key: 'field',
                render: (text, record) => (
                    <span>{record.field.name}</span>
                ),
            },
            {
                title: '审核状态',
                key: 'status',
                dataIndex: 'status',
                render: (text, record) => (
                    <span>
                        {
                            Dict.getValue('orgState',text,'')
                        }
                    </span>
                ),
            },
            {
                title: '审核事件',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <a onClick={()=>this.examineOrg(1,record.tid)}>同意</a>
                        <Divider type="vertical" />
                        <a onClick={()=>this.examineOrg(2,record.tid)}>驳回</a>
                    </span>
                ),
            },
        ];
        return(
            <MyLayout sider={"PlatformPendingSider"}>
                <p className="userEditTitle">名师审核</p>
                <Form layout='inline'>
                    <Form.Item>
                        <Input
                               defaultValue={this.state.name}
                               placeholder='请输入讲师名'
                               onChange={(e)=>this.setState({name:e.target.value})}
                        />
                    </Form.Item>
                    <Form.Item>
                        <OrgSelectModal userSetting={"allUser"} onChange={(data)=>this.handleChange(data,"org")}/>
                    </Form.Item>
                    <Form.Item>
                        <FieldSelectModal onChange={(data)=>this.handleChange(data,"field")}/>
                    </Form.Item>
                    <Form.Item>
                    <Select value={this.state.status} onChange={
                        (status)=>{
                            this.setState({status});
                            this.research();
                        }
                    }>
                        <Select.Option value={null}>不限制</Select.Option>
                        <Select.Option value={0}>已注销</Select.Option>
                        <Select.Option value={1}>上线中</Select.Option>
                        <Select.Option value={2}>已驳回</Select.Option>
                        <Select.Option value={3}>待审核</Select.Option>
                    </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary'
                                onClick={()=>this.research()}>
                            搜索</Button>
                    </Form.Item>
                </Form>
                <Divider/>
                <Table
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
                <div style={{paddingTop:"25px"}}>
                    <Pagination
                        onChange={(pageNum,pageSize)=>this.pageOnChange(
                            pageNum,pageSize
                        )}
                        pageSize={this.state.pageSize}
                        current={this.state.pageNum}
                        total={this.state.totalNum}
                    />
                </div>
            </MyLayout>
        )
    }
}

export default PendingTchList;