import { Badge, Col, Icon, Menu, message, Row } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Action from '../../actions/Action'
import api from '../../api/api'
import logImg from '../../assets/images/logo.jpg'
import logoM from '../../assets/images/logo_m.png'
import CON from '../../config/const'
import RoutePath from '../../config/RoutePath'
import Sfs from '../../config/Sfs'


const {SubMenu} = Menu

/**
 * role:0(普通用户)
 * role:1(企业用户)
 * role:2(老师)
 * role:3(第三方平台)
 * role:5(admin)
 */
class NavbarSelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            /*
             *0--德师平台模式
             *1--学习平台模式
             */
            //viewMode: 1
        }
    }

    /**
    setViewMode = () => {
        let learnBlankList = [
            RoutePath.VideoPlay,
            RoutePath.VideoDetails,
            RoutePath.videoDetail,
            RoutePath.SeriesPub,
        ];

        let pathName = this.props.location.pathname;
        for (let i = 0; i < learnBlankList.length; i++) {
            if (pathName.indexOf(learnBlankList[i]) == 0) {
                this.setState({ viewMode: 1 });
                return;
            }
        }

        this.setState({ viewMode: 0 })
    }
     */

    getParam(name) {
        var search = this.props.location.search
        if (search == '') {
            return null
        }

        var query = search.substring(1)
        var list = query.split('&')
        var item = []
        for (var i = 0; i < list.length; i++) {
            item = list[i].split('=')
            if (item[0] == name) {
                return item[1]
            }
        }
        return null
    }

    startUpLogin() {
        let token = this.getParam('token')
        if (token) {
            api.user.tokenLogin({token}, (state, data) => {
                if (state && data.status == 200) {
                    //Toast.info('登录成功');
                    this.props.saveUserInfoToStore(data.data)
                } else {
                    message.error(data.msg)
                }
            })
        }
    }

    componentDidMount() {
        //this.setViewMode();
        this.startUpLogin()
    }

    logout() {
        api.user.logout((state, data) => {
            message.info('注销成功')
        })
        this.props.resetUser()
        this.props.history.push(RoutePath.Login)
    }

    restructMenu(data, role) {
        let dt = data.map((cv, index) => {
            if (cv.isIngnore) {
                return null
            }

            if (cv.role.indexOf(role) < 0) {
                return null
            }

            if (cv.childs == null) {
                return (
                    <Menu.Item key={cv.key} onClick={cv.onClick}>
                        {cv.txt}
                    </Menu.Item>
                )
            } else {
                return (
                    <SubMenu key={cv.key} title={cv.txt}>
                        {this.restructMenu(cv.childs, role)}
                    </SubMenu>
                )
            }
        })
        return dt
    }

    lastIndexKey(params) {
        let index = params.lastIndexOf('/')
        return params.substring(index + 1, params.length)
    }

    render() {
        let history = this.props.history
        let userInfo = this.props.userInfo
        let role = -1
        let userData = {name: '未登录'}
        let uid = 'temp'
        if (userInfo != null) {
            uid = userInfo.uid
            role = userInfo.role
            userData = userInfo
        }

        let userName = '未登录'
        if (userData.name.length > 3) {
            userName = userData.name.substring(0, 3) + '...'
        } else {
            userName = userData.name
        }

        let roleAnon = -1
        let roleConstomer = 0
        let roleCompany = 1
        let roleTeacher = 2
        let rolePlateform = 3
        let roleAdmin = 5

        let allroles = [
            roleAnon,
            roleConstomer,
            roleCompany,
            roleTeacher,
            rolePlateform,
            roleAdmin,
        ]
        let dataleft = [
            {
                key: 'home',
                role: allroles,
                onClick: () => history.push('/home'),
                txt: '首页',
                childs: null,
            },
            {
                key: 'newsInformation',
                role: allroles,
                onClick: () => history.push('/news/newsInformation'),
                txt: '资讯',
                childs: null,
            },
            {
                key: 'moreLectureList',
                role: allroles,
                onClick: () => history.push('/company/moreLectureList'),
                txt: '精品讲座',
                isIngnore: CON.IS_FREE || !Sfs.isLectureOn,
                childs: null,
            },
            {
                key: 'moreVideoList',
                role: allroles,
                onClick: () => history.push('/Personal/moreVideoList'),
                txt: '精品课程',
                childs: null,
            },
            {
                key: this.lastIndexKey(RoutePath.SeriesPub),
                role: allroles,
                onClick: () => history.push(RoutePath.SeriesPub),
                txt: '精品系列',
                childs: null,
            },
            {
                key: 'SearchExamPaper',
                role: allroles,
                onClick: () => history.push('/taskadmin/SearchExamPaper'),
                txt: '精品习题',
                childs: null,
            },
            {
                key: 'famousTeacherList',
                role: allroles,
                onClick: () => history.push('/famousTeacherList'),
                txt: '名师风云榜',
                childs: null,
            },
            {
                key: this.lastIndexKey(RoutePath.OrgList),
                role: allroles,
                onClick: () => history.push(RoutePath.OrgList),
                txt: '培训机构',
                isIngnore: true,
                childs: null,
            },
            //{
            //    key: 'qzzhaopin',
            //    role: allroles,
            //    onClick: () => {
            //        if (userInfo != null) {
            //            window.open(
            //                'https://www.gongjiangpt.com/index.html#!/login?token=' +
            //                    userInfo.token
            //            )
            //        } else {
            //            window.open('https://www.gongjiangpt.com')
            //        }
            //    },
            //    txt: '求职招聘',
            //    childs: null,
            //},
            {
                key: 'CertificateQuery',
                role: allroles,
                onClick: () => history.push('/CertificateQuery'),
                txt: '证书查询',
                childs: null,
            },
            {
                key: 'MyOrgList',
                role: [roleCompany],
                onClick: () => history.push('/company/MyOrgList'),
                txt: '信息管理',
                childs: [
                    {
                        key: this.lastIndexKey(RoutePath.MyOrgList),
                        role: [roleCompany],
                        onClick: () => history.push(RoutePath.MyOrgList),
                        txt: '组织管理',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.TeacherList),
                        role: [roleCompany],
                        onClick: () => history.push(RoutePath.TeacherList),
                        txt: '名师管理',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.EditCertList),
                        role: [roleCompany],
                        onClick: () => history.push(RoutePath.EditCertList),
                        txt: '证书管理',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.VideoList),
                        role: [roleCompany],
                        onClick: () => history.push(RoutePath.VideoList),
                        txt: '视频管理',
                        childs: [
                            {
                                key: this.lastIndexKey(RoutePath.VideoList),
                                role: [roleCompany],
                                onClick: () =>
                                    history.push(RoutePath.VideoList),
                                txt: '上传管理',
                                childs: null,
                            },
                            {
                                key: this.lastIndexKey(RoutePath.SeriesList),
                                role: [roleCompany],
                                onClick: () =>
                                    history.push(RoutePath.SeriesList),
                                txt: '系列管理',
                                childs: null,
                            },
                        ],
                    },
                    {
                        key: this.lastIndexKey(RoutePath.MyGroundModelList),
                        role: [roleCompany],
                        onClick: () =>
                            history.push(RoutePath.MyGroundModelList),
                        txt: '讲座管理',
                        isIngnore: CON.IS_FREE || !Sfs.isLectureOn,
                        childs: [
                            {
                                key: this.lastIndexKey(
                                    RoutePath.MyGroundModelList
                                ),
                                role: [roleCompany],
                                onClick: () =>
                                    history.push(RoutePath.MyGroundModelList),
                                txt: '场地模板管理',
                                childs: null,
                            },
                            {
                                key: this.lastIndexKey(RoutePath.MyVenueList),
                                role: [roleCompany],
                                onClick: () =>
                                    history.push(RoutePath.MyVenueList),
                                txt: '我的会场管理',
                                childs: null,
                            },
                            {
                                key: this.lastIndexKey(RoutePath.LectureList),
                                role: [roleCompany],
                                onClick: () =>
                                    history.push(RoutePath.LectureList),
                                txt: '讲座信息管理',
                                childs: null,
                            },
                        ],
                    },
                ],
            },
            {
                key: this.lastIndexKey(RoutePath.HelpIndex),
                role: allroles,
                onClick: () => history.push(RoutePath.HelpIndex),
                txt: '帮助',
                childs: null,
            },
            {
                key: 'subx',
                role: [roleAdmin],
                onClick: null,
                txt: '系统管理',
                childs: [
                    {
                        key: 'subvideo',
                        role: [roleAdmin],
                        onClick: null,
                        txt: '视频管理',
                        childs: [
                            {
                                key: 'videoTypeAdmin',
                                role: [roleAdmin],
                                onClick: () =>
                                    history.push(RoutePath.VideoTypeAdmin),
                                txt: '视频类型管理',
                                childs: null,
                            },
                            {
                                key: this.lastIndexKey(
                                    RoutePath.PendingVideoList
                                ),
                                role: [roleAdmin],
                                onClick: () =>
                                    history.push(RoutePath.PendingVideoList),
                                txt: '单个视频管理',
                                childs: null,
                            },
                            {
                                key: 'seriesexam',
                                role: [roleAdmin],
                                onClick: () =>
                                    history.push(RoutePath.SeriesExam),
                                txt: '视频系列管理',
                                childs: null,
                            },
                        ],
                    },
                    {
                        key: 'teacher',
                        role: [roleAdmin],
                        onClick: null,
                        txt: '名师管理',
                        childs: [
                            {
                                key: 'FieldList',
                                role: [roleAdmin],
                                onClick: () => history.push('/admin/FieldList'),
                                txt: '领域编辑管理',
                                childs: null,
                            },
                            {
                                key: this.lastIndexKey(
                                    RoutePath.PendingTchList
                                ),
                                role: [roleAdmin],
                                onClick: () =>
                                    history.push(RoutePath.PendingTchList),
                                txt: '名师审核管理',
                                childs: null,
                            },
                            {
                                key: 'StarList',
                                role: [roleAdmin],
                                onClick: () => history.push('/admin/StarList'),
                                txt: '今日名师设置',
                                childs: null,
                            },
                        ],
                    },
                    {
                        key: 'sublecture',
                        role: [roleAdmin],
                        onClick: null,
                        txt: '讲座管理',
                        isIngnore: CON.IS_FREE || !Sfs.isLectureOn,
                        childs: [
                            {
                                key: this.lastIndexKey(RoutePath.ExamineGround),
                                role: [roleAdmin],
                                onClick: () =>
                                    history.push(RoutePath.ExamineGround),
                                txt: '场地审核',
                                childs: null,
                            },
                            {
                                key: this.lastIndexKey(
                                    RoutePath.pendingLectureList
                                ),
                                role: [roleAdmin],
                                onClick: () =>
                                    history.push(RoutePath.pendingLectureList),
                                txt: '讲座审核',
                                childs: null,
                            },
                            {
                                key: this.lastIndexKey(
                                    RoutePath.PendingVenueList
                                ),
                                role: [roleAdmin],
                                onClick: () =>
                                    history.push(RoutePath.PendingVenueList),
                                txt: '场次审核',
                                childs: null,
                            },
                            {
                                key: 'moreLectureList',
                                role: [roleAdmin],
                                onClick: () =>
                                    history.push('/company/moreLectureList'),
                                txt: '讲座订单统计',
                                childs: null,
                            },
                        ],
                    },
                    {
                        key: 'newsManage',
                        role: [roleAdmin],
                        txt: '新闻管理',
                        childs: [
                            {
                                key: this.lastIndexKey(
                                    RoutePath.NewsManagement
                                ),
                                role: [roleAdmin],
                                onClick: () =>
                                    history.push(RoutePath.NewsManagement),
                                txt: '新闻列表',
                            },
                            {
                                key: this.lastIndexKey(
                                    RoutePath.CommentsAuditing
                                ),
                                role: [roleAdmin],
                                onClick: () =>
                                    history.push(RoutePath.CommentsAuditing),
                                txt: '评论审核',
                            },
                        ],
                    },
                    {
                        key: 'PendingOrgList',
                        role: [roleAdmin],
                        onClick: () => history.push('/admin/PendingOrgList'),
                        txt: '组织审核',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.paperExamList),
                        role: [roleAdmin],
                        onClick: () => history.push(RoutePath.paperExamList),
                        txt: '试卷审核',
                        childs: null,
                    },
                    {
                        key: 'UserManage',
                        role: [roleAdmin],
                        onClick: () => history.push('/admin/UserManage'),
                        txt: '用户管理',
                        childs: null,
                    },
                    {
                        key: 'AdvertiseList',
                        role: [roleAdmin],
                        onClick: () => history.push('/admin/AdvertiseList'),
                        txt: '广告管理',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.NoticePub),
                        role: [roleAdmin],
                        onClick: () => history.push(RoutePath.NoticePub + '/0'),
                        txt: '系统公告管理',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.SystemStatics),
                        role: [roleAdmin],
                        onClick: () => history.push(RoutePath.SystemStatics),
                        txt: '系统总体统计',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.AccountClosePay),
                        role: [roleAdmin],
                        onClick: () => history.push(RoutePath.AccountClosePay),
                        txt: '系统账务结算',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.EditCertList),
                        role: [roleAdmin],
                        onClick: () => history.push(RoutePath.EditCertList),
                        txt: '证书审核管理',
                        childs: null,
                    },
                ],
            },
        ]

        let nrn = this.props.notReadNoticeNum
        let dataright = [
            {
                key: 'login',
                role: [roleAnon],
                onClick: () => history.push('/login'),
                txt: '登录',
                childs: null,
            },
            {
                key: 'register',
                role: [roleAnon],
                onClick: () => history.push('/register'),
                txt: '注册',
                childs: null,
            },
            {
                key: this.lastIndexKey(RoutePath.NewSginUp),
                role: [roleAnon],
                onClick: () => history.push(RoutePath.NewSginUp),
                txt: '招生报到',
                isIngnore: CON.IS_FREE,
                childs: null,
            },
            {
                key: 'sub1',
                role: [
                    roleConstomer,
                    roleCompany,
                    roleTeacher,
                    rolePlateform,
                    roleAdmin,
                ],
                onClick: null,
                txt: '个人中心',
                childs: [
                    {
                        key: 'userInfo',
                        role: [
                            roleConstomer,
                            roleCompany,
                            roleTeacher,
                            rolePlateform,
                            roleAdmin,
                        ],
                        onClick: () => history.push('/userInfo'),
                        txt: '个人信息',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.AccountInfo),
                        role: [roleCompany],
                        onClick: () =>
                            history.push(RoutePath.AccountInfo + '/' + uid),
                        txt: '我的财务',
                        childs: null,
                    },
                    {
                        key: 'myBuyVideoList',
                        role: [roleConstomer],
                        onClick: () => history.push('/Personal/myBuyVideoList'),
                        isIngnore: CON.IS_FREE,
                        txt: '已购课程',
                        childs: null,
                    },
                    {
                        key: 'myPlayVideoList',
                        role: [roleConstomer],
                        onClick: () =>
                            history.push('/Personal/myPlayVideoList'),
                        txt: '播放记录',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.MyAnswerPaperList),
                        role: [roleConstomer],
                        onClick: () =>
                            history.push(RoutePath.MyAnswerPaperList),
                        txt: '答题记录',
                        childs: null,
                    },
                    {
                        key: 'GetMyJoinedClasses',
                        role: [roleConstomer],
                        onClick: () =>
                            history.push(
                                '/Personal/student/GetMyJoinedClasses'
                            ),
                        txt: '我的班级',
                        childs: null,
                    },
                    {
                        key: 'MyPartinTaskList',
                        role: [roleConstomer],
                        onClick: () =>
                            history.push('/Personal/student/MyPartinTaskList'),
                        txt: '我的任务',
                        childs: null,
                    },
                    {
                        key: 'FetchPersonalStatics',
                        role: [roleConstomer],
                        onClick: () =>
                            history.push(
                                '/statistic/FetchPersonalStatics' +
                                    '/' +
                                    userInfo.uid
                            ),
                        txt: '学习信息统计',
                        childs: null,
                    },
                    {
                        key: 'PersonalSnapshots',
                        role: [roleConstomer],
                        onClick: () =>
                            history.push(
                                '/statistic/PersonalSnapshots' +
                                    '/' +
                                    userInfo.uid
                            ),
                        txt: '学习历史统计',
                        childs: null,
                    },
                    {
                        key: 'MyBizOrderLis',
                        role: [roleConstomer],
                        onClick: () =>
                            history.push('/Personal/MyVideoOrderList'),
                        txt: '我的订单列表',
                        isIngnore: CON.IS_FREE,
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.NewsHistory),
                        role: [roleConstomer],
                        onClick: () =>
                            history.push(RoutePath.NewsHistory),
                        txt: '资讯浏览记录',
                        childs: null,
                    },
                    {
                        key: 'GetMyOcuppyClasses',
                        role: [roleTeacher],
                        onClick: () =>
                            history.push('/taskadmin/GetMyOcuppyClasses'),
                        txt: '学员管理',
                        childs: null,
                    },
                    {
                        key: 'GetMyCreatedTasks',
                        role: [roleTeacher],
                        onClick: () =>
                            history.push('/taskadmin/GetMyCreatedTasks'),
                        txt: '任务管理',
                        childs: null,
                    },
                    {
                        key: 'MyCreatePaperList',
                        role: [roleTeacher],
                        onClick: () =>
                            history.push('/taskadmin/MyCreatePaperList'),
                        txt: '试卷管理',
                        childs: null,
                    },
                    {
                        key: 'GetFollowmeTopicList',
                        role: [roleTeacher],
                        onClick: () =>
                            history.push(RoutePath.GetFollowmeTopicList),
                        txt: '我的答疑',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.StaticPersonalList),
                        role: [roleTeacher],
                        onClick: () =>
                            history.push(RoutePath.StaticPersonalList),
                        txt: '综合学习统计',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.StaticMonthLearn),
                        role: [roleTeacher],
                        onClick: () =>
                            history.push(RoutePath.StaticMonthLearn),
                        txt: '分月明细统计',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.StaticTotalMonth),
                        role: [roleTeacher],
                        onClick: () =>
                            history.push(RoutePath.StaticTotalMonth),
                        txt: '综合分月统计',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.StaticRangeList),
                        role: [roleTeacher],
                        onClick: () =>
                            history.push(RoutePath.StaticRangeList),
                        txt: '综合分段统计',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.StaticVideo),
                        role: [roleTeacher],
                        onClick: () => history.push(RoutePath.StaticVideo),
                        txt: '视频学习统计',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.StaticPaper),
                        role: [roleTeacher],
                        onClick: () => history.push(RoutePath.StaticPaper),
                        txt: '试卷学习统计',
                        childs: null,
                    },
                    {
                        key: this.lastIndexKey(RoutePath.StaticExamWhole),
                        role: [roleTeacher],
                        onClick: () => history.push(RoutePath.StaticExamWhole),
                        txt: '考试综合统计',
                        childs: null,
                    },
                ],
            },
            {
                key: 'userinfo',
                role: [
                    roleConstomer,
                    roleCompany,
                    roleTeacher,
                    rolePlateform,
                    roleAdmin,
                ],
                onClick: null,
                txt: userName,
                childs: [
                    {
                        key: 'quitsystem',
                        role: [
                            roleConstomer,
                            roleCompany,
                            roleTeacher,
                            rolePlateform,
                            roleAdmin,
                        ],
                        onClick: () => this.logout(),
                        txt: '退出系统',
                        childs: null,
                    },
                ],
            },
            {
                key: 'notice',
                role: [roleConstomer],
                onClick: () => history.push(RoutePath.NoticeList),
                txt: (
                    <Badge
                        count={
                            nrn.numSys + nrn.numOrg + nrn.numCls + nrn.numDes
                        }
                    >
                        <Icon type="bell"></Icon>
                    </Badge>
                ),
                childs: null,
            },
        ]

        let pathName = this.props.location.pathname
        let index = pathName.lastIndexOf('/') //获取/后的Url值
        let urlKeyStr = pathName.substring(index + 1, pathName.length)

        if (this.props.viewMode === 0) {
            document.title = '德师平台'
            // document.title = '德师平台';
        } else {
            document.title = '综合学习平台'
        }

        return (
            <div>
                <div>
                    <Row>
                        <Col span={19}>
                            <Menu
                                mode="horizontal"
                                theme="light"
                                defaultSelectedKeys={[urlKeyStr]}
                                style={{
                                    lineHeight: '80px',
                                    paddingLeft: '45px',
                                }}
                            >
                                {this.props.viewMode === 0 ? (
                                    <Menu.Item
                                        key="log"
                                        style={{border: '0px'}}
                                        onClick={() => {
                                            this.props.changeViewMode(1)
                                        }}
                                    >
                                        <img
                                            alt=""
                                            style={{width: '70px'}}
                                            src={logImg}
                                        />
                                    </Menu.Item>
                                ) : (
                                    <Menu.Item
                                        key="logm"
                                        style={{border: '0px'}}
                                        onClick={() => {
                                            this.props.changeViewMode(0)
                                        }}
                                    >
                                        <img
                                            alt=""
                                            style={{width: '70px'}}
                                            src={logoM}
                                        />
                                    </Menu.Item>
                                )}
                                {this.restructMenu(dataleft, role)}
                            </Menu>
                        </Col>
                        <Col span={5}>
                            <Menu
                                mode="horizontal"
                                theme="light"
                                style={{lineHeight: '80px'}}
                            >
                                {this.restructMenu(dataright, role)}
                            </Menu>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
        viewMode: state.user.viewMode,
        notReadNoticeNum: state.user.notReadNoticeNum,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        resetUser: data =>
            dispatch({
                type: Action.RESET_USER_INFO,
            }),
        saveUserInfoToStore: data =>
            dispatch({
                type: Action.SAVE_USER_INFO,
                data: data,
            }),
        changeViewMode: data =>
            dispatch({
                type: Action.CHANGE_VIEW_MODE,
                data: data,
            }),
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(NavbarSelect)
)
