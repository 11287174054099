/* eslint-disable jsx-a11y/alt-text */

import React, { Component } from 'react';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

class SafeSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Typography>
                <Title level={2}>安全设置</Title>
                <Paragraph>
                    本文将会指导用户如何进行安全设置。
                </Paragraph>
                <Paragraph>
                    成功登录后，用户点击右上角的个人中心，选中下拉菜单的第一个选项【个人信息】，点击左侧导航栏中的【安全设置】后，可以看到修改密码的页面。
                </Paragraph>
                <Paragraph>
                    用户点击右侧的【修改】按钮，在弹出的窗口中输入相应的信息后，点击确定即可完成密码的修改。
                </Paragraph>
            </Typography>
        );
    }
}

export default SafeSetting;