import React, { Component } from 'react';
import {connect } from 'react-redux';
import { withRouter } from 'react-router'
import {Descriptions, Badge, Button, Col, Row, Modal, message, Form} from 'antd';
import MyLayout from "../../../common/MyLayout";
import api from "../../../../api/api";
import Dict from '../../../../config/Dict';
import CON from '../../../../config/const';

const { confirm } = Modal;

class videoDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoData:null,
        };
    }

    updateVideoDetail() {
        let {videoId} = this.props.match.params;
        let userInfo = this.props.userInfo;
        api.video.videoDetail({videoId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        videoData:data.data,
                    })
                }
            }
        )
    }

    cancellation(){
        let {videoId} = this.props.match.params;
        confirm({
            title: '你确定要注销这个视频吗?',
            content: '注销的视频不会被删除，注销后你可以选择重新上线或删除视频。',
            onOk:()=>{
                api.video.offVideo({videoId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            if(this.props.userInfo.role == 5){
                                this.props.history.push('/admin/PendingVideoList')
                            }else{
                                this.updateVideoDetail();
                            }
                        }
                    }
                )
            }
        });
    }

    delVideo(){
        let {videoId} = this.props.match.params;
        confirm({
            title: '你确定要删除这个视频吗?',
            content: '你删除的视频将不再显示在视频列表中。',
            onOk:()=>{
                api.video.delVideo(
                    {videoId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.props.history.push('/company/VideoList');
                        }
                    }
                )
            }
        });
    }

    reOnline(){
        let vid = this.props.match.params.videoId;
        let {filePath} = this.state.videoData;
        confirm({
            title: '你确定要重新上线这个视频吗?',
            content: '重新上线这个视频，视频将进入[审批中]的状态，管理员审批通过后，视频将重新上线。',
            onOk:()=>{
                api.video.addOrEditVideo(
                    {vid,filePath},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.updateVideoDetail();
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    }

    examineOrg(status){
        let videoId = this.props.match.params.videoId;
        let title = "";
        let content = "";
        if (status == 1){
            title = "你确定要[审批通过]这个视频吗?";
            content = "视频[审批通过]后才可以用户所看见哦。"
        }else{
            title = "你确定要[审批驳回]这个视频吗?";
            content = "视频[审批驳回]后信息会返回给请求者。"
        }
        confirm({
            title: title,
            content:content,
            onOk:()=>{
                api.video.examineVideo({videoId,status},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.props.history.push('/admin/PendingVideoList');
                        }
                    }
                )
            }
        });
    }

    componentDidMount() {
        this.updateVideoDetail();
    }

    render() {
        const {
            videoData= null
        } = this.state;
        return(
            <MyLayout>
                <div>
                    <Descriptions title="视频详情" bordered>
                        <Descriptions.Item key="name" label="视频名" span={2}>
                            {videoData!= null?videoData.name:""}
                        </Descriptions.Item>
                        <Descriptions.Item key='status' label="上线状态">
                            {videoData!= null? <Badge
                                status="processing"
                                text={Dict.getValue('videoState',videoData.status,'')} />:null
                            }
                        </Descriptions.Item>
                        <Descriptions.Item key='createdTime' label="创建时间">
                            {videoData!= null?videoData.createdTime:""}
                        </Descriptions.Item>
                        <Descriptions.Item key='updatedTime' label="更新时间">
                            {videoData!= null?videoData.updatedTime:""}
                        </Descriptions.Item>
                        <Descriptions.Item key='vid' label="视频id" >
                            {videoData!= null?videoData.vid:""}
                        </Descriptions.Item>
                        <Descriptions.Item key='relateLId' label="所属讲座" span={2}>
                            {videoData!= null && videoData.lecture?videoData.lecture.name:"无"}
                        </Descriptions.Item>
                        <Descriptions.Item key='tid' label="讲师">
                            {videoData!= null && videoData.teacher?videoData.teacher.name:"未知"}
                        </Descriptions.Item>
                        <Descriptions.Item key='keywords' label="视频关键字" span={2}>
                            {videoData!= null?videoData.keywords:""}
                        </Descriptions.Item>
                        <Descriptions.Item key='duration' label="视频时长" span={1}>
                            {videoData!= null?videoData.duration+'分钟':""}
                        </Descriptions.Item>
                        <Descriptions.Item key='posterPath' label="视频海报" span={3}>
                            {videoData!= null?<img
                                alt=''
                                width={220}
                                src={videoData.posterPath}
                            />:null}
                        </Descriptions.Item>
                        <Descriptions.Item key='filePath' label="视频预览" span={3}>
                            <video width="300" height="220" controls>
                                {videoData!= null?<source
                                    src={CON.VIDEO_SERVER + videoData.filePath}
                                    type="video/mp4"
                                />:null}
                            </video>
                        </Descriptions.Item>
                        <Descriptions.Item key='intro' label="视频描述">
                            {videoData!= null?videoData.intro:""}
                        </Descriptions.Item>
                    </Descriptions>
                    {
                        this.props.userInfo.role != 5 ?
                        <Row style={{padding:20}}>
                            {
                                videoData!= null && videoData.status !=0 ?<Col span={4}>
                                    <Button type="primary" onClick={()=>this.cancellation()}>下线视频</Button>
                                </Col>: <div>
                                    <Col span={4}>
                                        <Button type="primary" onClick={()=>this.reOnline()}>重新上线</Button>
                                    </Col>
                                    <Col span={4}>
                                        <Button type='danger' onClick={()=>this.delVideo()}>删除视频</Button>
                                    </Col>
                                </div>
                            }
                            <Col span={4}>
                                <Button type="primary"
                                        onClick={()=>
                                            this.props.history.push('/AddOrEditVideo'+'/'+this.props.match.params.videoId)}>
                                    编辑视频</Button>
                            </Col>
                        </Row>:
                        <Row style={{paddingTop:"25px"}}>
                            <Col span={4}>
                                <Button type="primary"
                                        onClick={()=>this.examineOrg(1)}
                                >
                                    通过审批</Button>
                            </Col>
                            <Col span={4}>
                                <Button type="primary"
                                        onClick={()=>this.examineOrg(2)}
                                >
                                    驳回审批</Button>
                            </Col>
                            <Col span={4}>
                                <Button type="primary" onClick={()=>this.cancellation()}>下线视频</Button>
                            </Col>
                        </Row>
                    }
                </div>
            </MyLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default connect(mapStateToProps,null)(Form.create({ name: 'videoDetail' })(videoDetail));