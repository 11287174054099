import React, { Component } from 'react';
import {Modal, Input, InputNumber, Button, Icon,Form} from 'antd';
import api from '../../../../api/api';

class AddOrEditGround extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:[],
            visible: false,
            noBody:""
        };
    }

    colsRowsToJson(rows,cols){ //生成json
        let json={};
        for(let i=1; i<=rows; i++){
            let row = [];
            for(let j=1; j<=cols; j++){
                let column = {};
                column[j] = ""+j;
                row.push(column);
            }
            json[i] = row;
        }
        return json;
    }

    handleOk (values) {
        let id = "";
        if (this.props.tagType == "edit"){
            id = this.props.listData.id;
        }
        api.lecture.addOrEditGround({id, ...values},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        visible: false,
                    });
                    this.props.onChange(state);
                }
            }
        )
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let noBody = this.colsRowsToJson(values.rows,values.cols);
                values.noBody = JSON.stringify(noBody); //将JSON对象转化为JSON字符
                this.handleOk(values);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                sm: { span: 5 },
            },
            wrapperCol: {
                sm: { span: 18},
            },
        };
        return (
            <div>
                <Button type="primary" onClick={()=>{
                    this.setState({
                        visible: true,
                    });
                }}>
                    {
                        this.props.tagType=="add"?"添加场地模板":"编辑模板"
                    }
                </Button>
                <Modal
                    title="创建/编辑模板"
                    visible={this.state.visible}
                    onOk={this.handleSubmit}
                    onCancel={()=>{
                        this.setState({
                            visible: false,
                        })
                    }}
                >
                    <Form {...formItemLayout}>
                        <Form.Item label='模板名称'>
                            {getFieldDecorator('name', {
                                initialValue:this.props.listData?this.props.listData.name:"",
                                rules: [
                                    {
                                        validateStatus:'error',
                                        required: true,
                                        message: '请输入一个场地模板名称!'
                                    },
                                ],
                            })(
                                <Input size="large"
                                       prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                       placeholder="场地模板名称"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='模板行数'>
                            {getFieldDecorator('rows', {
                                initialValue:this.props.listData?this.props.listData.rows:null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输模板行数!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(value>=1)
                                            {
                                                callback();
                                                return;
                                            }

                                            callback('error');
                                        },
                                        message: '行数应大于1',
                                    },
                                ],
                            })(
                                <InputNumber size="large"
                                             min={1}
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='模板列数'>
                            {getFieldDecorator('cols', {
                                initialValue:this.props.listData?this.props.listData.cols:null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输模板列数!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(value>=1)
                                            {
                                                callback();
                                                return;
                                            }

                                            callback('error');
                                        },
                                        message: '列数应大于1',
                                    },
                                ],
                            })(
                                <InputNumber size="large"
                                             min={1}
                                />,
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}
export default Form.create({ name: 'AddOrEditGround' })(AddOrEditGround);