import React, { Component } from 'react';
import {Modal, Table, Divider, PageHeader, Tag, message} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import Dict from "../../../config/Dict";
import ComFunction from '../../../api/ComFunction';
import ExportJsonExcel from 'js-export-excel';

const { confirm } = Modal;

class GetStudentsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:[],
        };
    }

    examineOrg(status,uid){
        let title = "";
        let content = "";
        if (status == 1){
            title = "你确定要[审批通过]这个学生吗?";
            content = "学生[审批通过]后才可以进入这个班级。"
        }else{
            title = "你确定要[审批驳回]这个学生吗?";
            content = "学生[审批驳回]后这个学生无法进入这个班级。"
        }
        confirm({
            title: title,
            content:content,
            onOk:()=>{
                api.taskadmin.changeStudentStatus({
                        "classId": this.props.match.params.classId,
                        "status": status,
                        "uid": uid
                    },
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.getStudentsListByClassId();
                        }else{
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    }
    
    //根据index与学期，找出本学期key.
    findKeyNumber(achive,  xueqi, key)
    {
        for(var i = 0; i < achive.scores.length; i++)
        {
            let score = achive.scores[i];
            if(xueqi == score.rankIndex)
            {
                return score[key];
            }
        }
        return 0;
    }

    downloadWithData(data)
    {
        //文件名
        let user = data.user;
        let achives = data.achives;
        var option={};
        option.fileName = user.name;

        //Sheet 毕业成绩单
        option.datas=[];
        let biyeChengjidan ={
            sheetData:[],
            sheetName:'毕业成绩单',
            sheetHeader:['课程名称'],
        };
        for(var i = 1; i <= 8; i++)
        {
            biyeChengjidan.sheetHeader.push('第'+i+'学年上学期学时');
            biyeChengjidan.sheetHeader.push('第'+i+'学年上学期成绩');
            biyeChengjidan.sheetHeader.push('第'+i+'学年下学期学时');
            biyeChengjidan.sheetHeader.push('第'+i+'学年下学期成绩');
        }

        let sheetData = biyeChengjidan.sheetData;
        for(var j = 0; j < achives.length; j++)
        {
            let achive = achives[j];
            let oneData = [];
            oneData.push(achive.achiveName);
            for(var i = 1; i <= 8; i++)
            {
                for(var k = 1; k <= 2; k++)
                {
                    let xueqi = 10*i + k;
                    let learnTimes = this.findKeyNumber(achive, xueqi, 'learnTimes');
                    oneData.push(learnTimes);

                    let examScore = this.findKeyNumber(achive, xueqi, 'examScore');
                    let rexamScore = this.findKeyNumber(achive, xueqi, 'rexamScore');
                    let maxScore = Math.max(examScore,rexamScore);
                    oneData.push(maxScore);
                }
            }
            sheetData.push(oneData);
        }
        option.datas.push(biyeChengjidan);
        
        //分学期成绩单
        for(var i = 1; i <= 8; i++)
        {
            for(var k = 1; k <= 2; k++)
            {
                //第i学年，第k学期.
                let sx = k==1?'上':'下';
                let ikChengjidan = {
                    sheetData:[],
                    sheetName:'第' + i +'学年'+sx,
                    sheetHeader:['序号','课程名称','总学时','已完成学时','考试成绩','补考成绩'],
                };
                let xueqi = 10*i + k;

                for(var j = 0; j < achives.length; j++)
                {

                    let achive = achives[j];
                    let oneData = [];
                    oneData.push(j+1);
                    oneData.push(achive.achiveName);

                    let totalTimes = this.findKeyNumber(achive, xueqi, 'totalTimes');
                    oneData.push(totalTimes);

                    let learnTimes = this.findKeyNumber(achive, xueqi, 'learnTimes');
                    oneData.push(learnTimes);

                    let examScore = this.findKeyNumber(achive, xueqi, 'examScore');
                    oneData.push(examScore);
                    let rexamScore = this.findKeyNumber(achive, xueqi, 'rexamScore');
                    oneData.push(rexamScore);
                    ikChengjidan.sheetData.push(oneData);
                }
                option.datas.push(ikChengjidan);
            }  
        }

        var toExcel = new ExportJsonExcel(option); //new
        toExcel.saveExcel(); //保存
    }

    downloadStatic(record){
        let cbk = (state,data)=>{
            if(state && data.status == 200) {
                this.downloadWithData(data.data);
            }else{
                message.error(data.msg);
            }
        }

        api.class.getPersonAchiveByUidAndClassId({
            classId:this.props.match.params.classId,
            uid: record.uid,
        },cbk);
    }

    getStudentsListByClassId() {
        api.taskadmin.getStudentsListByClassId({
                classId:this.props.match.params.classId,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }else{
                    message.error(data.msg);
                }
            }
        )
    }

    componentDidMount() {
        this.getStudentsListByClassId();
    }

    render() {
        let history = this.props.history;
        const columns = [
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow",
            },
            {
                title: '城市',
                dataIndex: 'pcity',
                key: 'pcity',
                render: (text, record) => (
                    <span>{Dict.getAdressLabelValue(record.pcity)}</span>
                ),
            },
            {
                title: '身份证号',
                dataIndex: 'idNumber',
                key: 'idNumber',
            },
            {
                title: '申请日期',
                dataIndex: 'createdTime',
                key: 'createdTime',
                sorter: (a, b) => ComFunction.compareTwoDate(a.createdTime,b.createdTime),
                sortDirections: ['descend', 'ascend'],
                render: (text, record) => (
                    <span>{ComFunction.getShortTime(text)}</span>
                ),
            },
            {
                title: '审核状态',
                key: 'status',
                dataIndex: 'status',
                sorter: (a, b) => a.status-b.status,
                sortDirections: ['descend', 'ascend'],
                render: (text, record) => (
                    <Tag color={text==0?"#2db7f5":text==1?"#87d068":"#f50"}>
                        {
                            Dict.getValue('student',text,'')
                        }
                    </Tag>
                ),
            },
            {
                title: '审核事件',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <a onClick={()=>this.examineOrg(1,record.uid)} disabled={record.status==0?false:true}>同意</a>
                        <Divider type="vertical" />
                        <a onClick={()=>this.examineOrg(2,record.uid)} disabled={record.status==0?false:true}>驳回</a>
                    </span>
                ),
            },
            {
                title: '统计',
                key: 'action1',
                render: (text, record) => (
                    <span>
                        <a onClick={()=>this.downloadStatic(record)}>下载</a>
                    </span>
                ),
            },
        ];
        return(
            <MyLayout>
                <PageHeader
                    style={{padding:"0"}}
                    title={this.props.match.params.classId}
                    subTitle={"本班人数:" + this.state.data.length}
                />
                <Divider/>
                <Table
                    rowKey='uid'
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
            </MyLayout>
        )
    }
}

export default GetStudentsList;