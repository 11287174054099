import React, { Component } from 'react';
import {Modal, Avatar, Input, List, Pagination, Tooltip, Tag, Button, message} from 'antd';
import api from "../../../api/api";

/************* 教师选择Popover ****************/
class addNewClassStaff extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:[],
            visible: false,
        }
    }

    getOrgStaffsByOrgId(){
        let orgId = this.props.orgId;
        api.staff.getOrgStaffsByOrgId({
                "orgId":orgId,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    listClick=(uid)=>{
        let classId = this.props.classId;
        api.class.addNewClassStaff({
                "classId": classId,
                "headmaster": 0,
                "uid": uid
            },
            (state,data)=>{
                if (state) {
                    if(data.status == 200) {
                        this.setState({
                            visible: false,
                        });
                        this.props.onChange(state);
                        message.success('添加成功!');
                    }else if (data.status == 6000320){
                        this.setState({
                            visible: false,
                        });
                        Modal.error({
                            title: '提示',
                            content: '这个员工已经添加到本班级了,请选择其他员工!',
                        });
                    }
                }
            }
        )
    };

    componentDidMount() {
        this.getOrgStaffsByOrgId();
    }

    render() {
        return (
            <div>
                <Button type='primary'
                        style={{
                            marginBottom: 16,
                        }}
                        onClick={()=>{
                            this.setState({
                                visible: true,
                            });
                            this.getOrgStaffsByOrgId();
                        }}>
                    添加任课老师</Button>
                <Modal
                    className="modal-form-style"
                    title="添加任课老师"
                    visible={this.state.visible}
                    onCancel={()=>{
                        this.setState({
                            visible:false
                        })
                    }}
                    footer={null}
                >
                    <div className="modal-form-style">
                        <List
                            size="large"
                            dataSource={this.state.data}
                            renderItem={item =>
                                <List.Item onClick={()=>this.listClick(item.uid)}>
                                    <List.Item.Meta
                                        title={<a>{item.name}</a>}
                                    />
                                </List.Item>}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default addNewClassStaff;