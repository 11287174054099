import React, { Component } from 'react';
import {Select} from 'antd';
const { Option } = Select;

class DataSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onClick(index) {
        this.props.onChange(index)
    }

    render() {
        let optionArr = [];
        let option = this.props.option;
        if (typeof (option) =="object") {
            for (let i in option) {
                optionArr.push(option[i]);
            }
        }else{
            optionArr = option;
        }

        let value = this.props.value;
        if(value == null || value == "") {
            value = 0;
        }

        return (
            <Select size="large" value={typeof (value) =="string" ? parseInt(value) : value}>
            {
                optionArr.map((value,index,arr)=>(
                    <Option
                        key={index}
                        value={index}
                        onClick={()=>this.onClick(index)}
                    >{value}</Option>
                ))
            }
            </Select>
        );
    }
}

export default DataSelect;