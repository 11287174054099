import React, { Component } from 'react';
import {Col, Row, Table, Pagination, Divider, Input, Button, Select} from 'antd';
import api from '../../../../api/api';
import Dict from "../../../../config/Dict";
import MyLayout from "../../../common/MyLayout";
import AddOrEditVenue from "./AddOrEditVenue";
import OrgSelectModal from "../../../../components/OrgSelectModal";
import FieldSelectModal from "../../../../components/FieldSelectModal";
const { Option } = Select;
const InputGroup = Input.Group;

class MyVenueList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectType:"name",
            name: "",
            orgName:"",
            status:null,
            orgIds: [],

            data:[],
            pageNum:1,
            pageSize:8,
            totalNum:9,
        };
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.updateData(), 200);
    }

    updateData() {
        api.lecture.myVenueList({
                "condition": {
                    "name": this.state.name,
                    "orgName":this.state.orgName,
                    "orgIds": this.state.orgIds,
                    "status":this.state.status
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:4,
            totalNum:9,
        });
        setTimeout(()=>this.updateData(), 200,)
    }

    onChange(ar){
        if (ar){
            this.updateData();
        }
    }

    componentDidMount() {
        this.updateData();
    }

    render() {
        let history = this.props.history;
        const columns = [
            {
                title: '场地名称',
                dataIndex: 'name',
                key: 'name',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow:'ellipsis',
                            cursor:'pointer'
                        }
                    }
                },
                render:  (text, record) => (
                    <a onClick={()=>history.push('/company/VenueDetail'+'/'+record.id)}>{text}</a>
                )
            },
            {
                title: '会场位置',
                dataIndex: 'location',
                key: 'location',
            },
            {
                title: '审核状态',
                key: 'status',
                dataIndex: 'status',
                render: (text, record) => (
                    <span>
                        {
                            Dict.getValue('orgState',text,'')
                        }
                    </span>
                ),
            }
        ];
        return(
            <MyLayout sider={"InfoManageSider"}>
                <p className="userEditTitle">会场列表</p>
                <Divider />
                <Row gutRer={5}>
                    <Col span={3}>
                        <Select size="large"
                                style={{ width: 120 }}
                                defaultValue={this.state.status}
                                onChange={(v)=>{
                                    this.setState({status:v});
                                    this.research();
                                }}>
                            <Option value={null}>所有</Option>
                            <Option value={0}>下线</Option>
                            <Option value={1}>上线</Option>
                            <Option value={2}>驳回</Option>
                            <Option value={3}>审批中</Option>
                        </Select>
                    </Col>
                    <Col span={17}>
                        <InputGroup compact style={{marginLeft:"20px"}}>
                            <Select size="large"
                                    defaultValue="name"
                                    onChange={(value)=>this.setState({selectType:value})}>
                                <Option value="name">会场名称</Option>
                                <Option value="org">所属组织</Option>
                            </Select>
                            <span style={{ width: '40%'}} >{(() => {
                                    switch (this.state.selectType) {
                                        case "name":
                                            return <Input size="large"
                                                          defaultValue={this.state.name}
                                                          placeholder='请输入会场名称'
                                                          onChange={(e)=>this.setState({name:e.target.value})}
                                            />;
                                        case "org":
                                            return <OrgSelectModal userSetting={"myUser"} onChange={(data)=>this.setState({orgIds:[data]})}/>;
                                        default:
                                            return null
                                    }
                                }
                            )()}</span>
                            <div>
                                <Button type='primary'
                                        size="large"
                                        icon="search"
                                        onClick={()=>this.research()}>
                                    搜索</Button>
                            </div>
                        </InputGroup>
                    </Col>
                    <Col span={4}>
                        <AddOrEditVenue tagType={"add"}
                                         onChange={(ar)=>this.onChange(ar)}/>
                    </Col>
                </Row>
                <Divider dashed/>
                <Table
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
                <div style={{paddingTop:"25px"}}>
                    <Pagination
                        onChange={(pageNum,pageSize)=>this.pageOnChange(
                            pageNum,pageSize
                        )}
                        pageSize={this.state.pageSize}
                        current={this.state.pageNum}
                        total={this.state.totalNum}
                    />
                </div>
            </MyLayout>
        )
    }
}

export default MyVenueList;