import React, { Component } from 'react';
import {Icon, Modal, Table, Divider, PageHeader, Button, Tooltip,Form,Input,message} from 'antd';
import MyLayout from '../../common/MyLayout';
import api from '../../../api/api';
import ComFunction from '../../../api/ComFunction';
import RoutePath from '../../../config/RoutePath';

const { confirm } = Modal;

class ExportSetting extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:[]
        }
    }

    removeAchiveById(classAchiveId){
        confirm({
            title: "提示",
            content:"你确定要删除这个导出科目吗?",
            onOk:()=>{
                api.class.deleteAchiveByClassAchiveId({
                    classAchiveId
                    },
                    (state,data)=>{
                        if (state) {
                            if(data.status === 200) {
                                setTimeout(()=>this.getAchiveList(), 200);
                                message.success('删除成功!');
                            }
                            else{
                                message.error(data.msg);
                            }
                        }
                    }
                )
            }
        });
    }

    getAchiveList(){ //视频列表
        api.class.getClassAchiveListById(
            {
                classId:this.props.match.params.classId,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    };

    handleSumbit=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let cbk = (state,data)=>{
                    if(state && data.status === 200) {
                        this.setState({
                            visible: false,
                        });
                        this.getAchiveList();
                    } else {
                        message.error(data.msg);
                    }
                };
                api.class.addOrEditClassAchive(values,cbk);
            }
        });
    }

    componentDidMount()
    {
        this.getAchiveList();
    }

    render() { 
        let history = this.props.history;

        const { getFieldDecorator } = this.props.form;
        getFieldDecorator('classAchiveId',{});
        getFieldDecorator('classId',{});

        const formItemLayout = {
            labelCol: {
                sm: { span: 4 },
            },
            wrapperCol: {
                sm: { span: 18},
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                sm: {
                    span: 8,
                    offset: 4,
                },
            },
        };

        const columns = [
            {
                title: '导出科目',
                dataIndex: 'achiveName',
                key: 'achiveName',
                className:"tableOverflow",
                render: (text, record) => (
                    <Tooltip
                    title={text}
                    >{text}</Tooltip>
                ),
            },
            {
                title: '添加时间',
                dataIndex: 'updateTime',
                key: 'updateTime',
                render: (text, record) => (ComFunction.getShortTime(text))
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button 
                            onClick={()=>{
                                this.props.form.setFieldsValue(record);
                                this.setState({
                                    visible: true,
                                });
                            }}
                            type='ghost'
                        ><Icon type='edit'/></Button>
                        <Divider type="vertical" />
                        <Button 
                            onClick={
                              ()=>this.removeAchiveById(record.classAchiveId)
                            }
                            type='danger'
                        >删除</Button>
                        <Divider type="vertical"/>
                        <Button type='primary'
                         onClick={()=>history.push(RoutePath.EItemSetting + '/' + record.classAchiveId)}
                        >详情...</Button>
                    </span>
                ),

            }
        ];
        
        return ( <>
            <MyLayout>
                <PageHeader
                    style={{padding:"0"}}
                    title="班级导出成绩配置"
                    subTitle={this.props.match.params.classId}
                />
                <Divider/>
                <Button type="primary"
                        onClick={()=>{
                            this.props.form.setFieldsValue({
                                    classAchiveId:null,
                                    classId:this.props.match.params.classId,
                                    achiveName:null,
                                    });
                            this.setState({
                                visible: true,
                            })
                        }}>
                    添加科目
                </Button>
                <Divider dashed/>
                <Table
                    rowKey='classAchiveId'
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />

                <Modal
                        title="添加/编辑导出科目"
                        visible={this.state.visible}
                        onCancel={()=>{
                                this.setState({
                                    visible:false
                                })
                        }}
                        okText="确认"
                        cancelText="取消"
                        footer={null}
                        forceRender={true}
                    >
                        <Form {...formItemLayout} onSubmit={this.handleSumbit}>
                            <Form.Item label='科目名'>
                                {getFieldDecorator('achiveName', {
                                    rules: [
                                        {
                                            required: true,
                                            validateStatus:'error',
                                            message: '请输入科目名!'
                                        },
                                    ],
                                })(
                                    <Input size="large"
                                           placeholder="科目名"
                                    />,
                                )}
                            </Form.Item>
                            
                            <Form.Item  {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit" size="large" block>
                                    确定
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>

            </MyLayout>
        </> );
    }
}
 
export default Form.create()(ExportSetting);

