
import React, { Component } from 'react';
import MyLayout from '../../common/MyLayout';
import api from '../../../api/api';
import { Button, Pagination, Table, Tag, PageHeader, Divider, Select } from 'antd';
import Dict from '../../../config/Dict';


class paperExamList extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:[],
            status: 0,
         };

         this.initPageSize = 8;
         this.pageNum = 1;
         this.pageSize = this.initPageSize;
         this.totalNum = 10000;
    }

    featchExamList(){
        api.exam.searchExamPaper(
            {
                "condition": {
                    "status":this.state.status,
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    componentDidMount()
    {
        this.featchExamList();
    }

    pageOnChange(pageNum,pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(()=>this.featchExamList(), 200);
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        setTimeout(()=>this.featchExamList(), 200,);
    }

    examPassOrReject(param){
        api.exam.examExamPaper(
            param,
            (state,data)=>{
                this.featchExamList();
            }
        )
    }


    render() {


        let history = this.props.history;
        const columns = [
            {
                title: '试卷题目',
                dataIndex: 'title',
                key: 'title',
                className:"tableOverflow",
                render: (text, record) => (
                    <div>
                        <a  onClick={()=>
                            history.push("/taskadmin/AddOrEditQuestion"+'/'+record.paperId)}>{text}</a>
                    </div>
                ),
            },
            {
                title: ' 试卷类型',
                dataIndex: 'examType',
                key: 'examType',
                render: (text, record) => (
                    <Tag color={text==0?"#87d068":"#2db7f5"}>
                        {
                            Dict.getValue('examType',text,'')
                        }
                    </Tag>
                ),
            },
            {
                title: '题数',
                dataIndex: 'itemNum',
                key: 'itemNum',
            },
            {
                title: '总分值',
                dataIndex: 'totalScore',
                key: 'totalScore',
            },
            {
                title: '答题人数',
                dataIndex: 'answerNum',
                key: 'answerNum',
            },
            {
                title: '创建日期',
                dataIndex: 'createTime',
                key: 'createTime',
                render: (text, record) => (
                    text.substring(0,10)
                ),
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button
                        type='primary'
                        onClick = {
                            ()=>
                            {
                                this.examPassOrReject(
                                    {
                                        paperId:record.paperId,
                                        status:2
                                    }
                                )
                            }
                        }
                        >
                        审核通过
                        </Button>
                        <Divider type='vertical'/>
                        <Button
                        type='danger'
                        onClick = {
                            ()=>
                            {
                                this.examPassOrReject(
                                    {
                                        paperId:record.paperId,
                                        status:3
                                    }
                                )
                            }
                        }
                        >
                        审核拒绝
                        </Button>
                    </span>
                ),
            },
        ];

        //0--编辑中 1---提交审核  2---通过审核  3---驳回审核
        return (
            <MyLayout>
            <PageHeader
                style={{padding:"0"}}
                title="试卷审核"
            />
            <Divider />
            <Select value={this.state.status} onChange={
                (status)=>{
                    this.setState({status});
                    this.research();
                }
            }>
                <Select.Option value={0}>编辑中</Select.Option>
                <Select.Option value={1}>待审核</Select.Option>
                <Select.Option value={2}>通过</Select.Option>
                <Select.Option value={3}>驳回</Select.Option>
            </Select>
            <Divider/>
            <Table
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
            <Pagination
                    onChange={(pageNum,pageSize)=>this.pageOnChange(
                        pageNum,pageSize
                    )}
                    pageSize={this.pageSize}
                    current={this.pageNum}
                    total={this.totalNum}
                />
            </MyLayout>
        );
    }
}

export default paperExamList;

