import React, {Component} from 'react';
import {Tabs, Tag} from 'antd';
import {connect} from 'react-redux';

const {TabPane} = Tabs;
const {CheckableTag} = Tag;

class VideoTypeTabSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkTypeId: null,
        };
    }

    getSubArrayByTypeId(typeId) {
        let videoTypes = this.props.videoTypes;
        if (!videoTypes) {
            return [];
        }
        let res = [];
        for (let i = 0; i < videoTypes.length; i++) {
            if (videoTypes[i].pid == typeId) {
                res.push(videoTypes[i]);
            }
        }
        return res;
    }

    onSelectTypeId(checkTypeId) {
        if (this.props.onChange) {
            this.props.onChange(checkTypeId);
        }

        this.setState({checkTypeId});
    }

    render() {
        let firstArray = this.getSubArrayByTypeId(null);

        return (
            <Tabs
                defaultActiveKey={null}
                onChange={key => {
                    if (key == '----') {
                        this.onSelectTypeId(null);
                    }
                }}
            >
                <TabPane tab="不限" key={'----'}>
                    不限
                </TabPane>
                {firstArray.map(item => (
                    <TabPane tab={item.name} key={item.typeId}>
                        {this.getSubArrayByTypeId(item.typeId).map(
                            (tagsItem, index) => (
                                <CheckableTag
                                    checked={
                                        this.state.checkTypeId ==
                                        tagsItem.typeId
                                    }
                                    onChange={checked => {
                                        this.onSelectTypeId(tagsItem.typeId);
                                    }}
                                    key={index}
                                >
                                    {tagsItem.name}
                                </CheckableTag>
                            )
                        )}
                    </TabPane>
                ))}
            </Tabs>
        );
    }
}

function mapStateToProps(state) {
    return {
        videoTypes: state.user.videoTypes,
    };
}

export default connect(mapStateToProps, null)(VideoTypeTabSelect);
