import React, { Component } from 'react';
import MyLayout from '../../common/MyLayout';
import {connect } from 'react-redux';
import { Modal, Row, Table, Pagination, Badge, Tabs,Col,Input, Divider,Tag } from 'antd';
import api from '../../../api/api';
import Action from '../../../actions/Action';

const { TabPane } = Tabs;
const { Search } = Input;
class NoticeList extends Component {

    constructor(props) {
        super(props);
        this.state = {
           
            keyword: "",
            noticeType: '0',

            data:[],
            detailData:{},
            visible:false,
         };

         this.initPageSize = 8;

         this.pageNum = 1;
         this.pageSize = this.initPageSize;
         this.totalNum = 10000;
    }

    fetchData()
    {
        api.notice.searchToMeNotice(
            {
                "condition": {
                    "noticeType":this.state.noticeType,
                    "keyword":this.state.keyword
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    componentDidMount()
    {
        this.fetchData();
    }

    pageOnChange(pageNum,pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(()=>this.fetchData(), 200);
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        setTimeout(()=>this.fetchData(), 200,);
    }

    fetchNoticeDetail(noticeId)
    {
        api.notice.getNoticeDetail(
            {noticeId},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        detailData:data.data,
                    });
                    this.pageOnChange(this.pageNum,this.pageSize);
                    this.props.refreshNotReadNum();
                }
            }
        )
    }

    render() {
        let notReadNoticeNum = this.props.notReadNoticeNum;

        const columns = [
            {
                title: '公告名',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '发布者',
                dataIndex: 'userName',
                key: 'userName',
            }, 
            {
                title: '发布组织',
                dataIndex: 'itemName',
                key: 'itemName',
            },            
            {
                title: '发布日期',
                dataIndex: 'createdTime',
                key: 'createdTime',
            },
            {
                title: '状态',
                dataIndex: 'readTime',
                render: (text, record) => (
                    record.readTime?
                    <Tag color='blue'>已读</Tag>
                    :<Tag color='red'>未读</Tag>
                )
            },
        ];

        return (
            <MyLayout>
                   <Row>
                    <Col span={4}></Col>
                    <Col span={8}>
                        <Search
                            placeholder="请输入关键字"
                            enterButton="搜索"
                            size="large"
                            onSearch={
                                (keyword)=>{
                                    this.setState({keyword});
                                    this.research();
                                }
                            }
                        />
                    </Col>
                    <Col span={8}></Col>
                </Row>
                <Divider/>
              <Tabs defaultActiveKey={0} 
                    onChange={(activeKey)=>{
                      this.setState({
                        noticeType:activeKey
                      });
                      this.research();
                    }}>
                <TabPane tab={<Badge count={notReadNoticeNum.numSys}>系统通知</Badge>} key={'0'}>
                <div></div>
                </TabPane>
                <TabPane tab={<Badge count={notReadNoticeNum.numOrg}>学校通知</Badge>} key={'1'}>
                <div></div>
                </TabPane>
                <TabPane tab={<Badge count={notReadNoticeNum.numCls}>班级通知</Badge>} key={'2'}>
                <div></div>
                </TabPane>
                <TabPane tab={<Badge count={notReadNoticeNum.numDes}>定向通知</Badge>} key={'3'}>
                <div></div>
                </TabPane>
              </Tabs>
              <Table
                  dataSource={this.state.data}
                  columns={columns}
                  pagination={false}
                  rowKey='noticeId'
                  onRow={
                      (record)=>{
                          return{
                              onClick:(e)=>{
                                this.fetchNoticeDetail(record.noticeId);
                                this.setState({visible:true})
                              }
                          }
                      }
                  }
              />
              <Divider/>
              <Pagination
                            onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.pageSize}
                            current={this.pageNum}
                            total={this.totalNum}
                        />
                <Modal                
                    title={this.state.detailData.title}
                    visible={this.state.visible}
                    onCancel={()=>{
                                this.setState({
                                    visible:false
                                })
                            }}
                    onOk={()=>{
                                this.setState({
                                    visible:false
                                })
                            }}
                    okText="确认"
                    cancelText="取消"
                    //footer={null}
                >
                {
                  this.state.detailData.content?
                  this.state.detailData.content.split("\n").map(
                    (txt)=>(
                        <p>{txt}</p>
                    )
                ):null
                }
                </Modal>
            </MyLayout>
        );
    }
}

function mapStateToProps(state) {
    return {
        notReadNoticeNum: state.user.notReadNoticeNum,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        refreshNotReadNum: (data) => dispatch({
            type: Action.FEATCH_READ_NUM,
        })
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(NoticeList);

