import React, { Component } from 'react';

class NoTimeContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            day: 0,
            hour: 0,
            minute: 0,
            second: 0
        };

        this.timer = null;
    }

    countFun = (end) => {
        let now_time = Date.parse(new Date());
        let remaining = end - now_time;

        if (this.timer)
        {
            clearInterval(this.timer);
        }

        this.timer = setInterval(() => {
            //防止出现负数
            if (remaining > 1000) {
                remaining -= 1000;
                let day = Math.floor((remaining / 1000 / 3600) / 24);
                let hour = Math.floor((remaining / 1000 / 3600) % 24);
                let minute = Math.floor((remaining / 1000 / 60) % 60);
                let second = Math.floor(remaining / 1000 % 60);

                this.setState({
                    day:day,
                    hour:hour < 10 ? "0" + hour : hour,
                    minute:minute < 10 ? "0" + minute : minute,
                    second:second < 10 ? "0" + second : second
                })
            } else {
                clearInterval(this.timer);
                //倒计时结束时触发父组件的方法
                this.props.timeEnd();
            }
        }, 1000);
    };

    componentWillReceiveProps(props) {
        if (props.endTime) {
            let endTime = props.endTime;
            const end = Date.parse(new Date(endTime));
            this.countFun(end);
        }
    }

    //卸载组件取消倒计时
    componentWillUnmount(){
        if (this.timer)
        {
            clearInterval(this.timer);
        }
    }

    render() {
        return (
            <div>
                {/*
                <span className="remaining-title">剩余支付时间 </span>
                <span>{this.state.day}天 {this.state.hour}:{this.state.minute}:{this.state.second}</span>
                */}
                <span>{this.state.hour}:{this.state.minute}:{this.state.second}</span>
            </div>
        )
    }
}
export default NoTimeContent;