

import React, { Component } from 'react';
import api from '../../api/api';
import { Pagination, Button, Modal, Table, Row, Col, Input, Divider, Avatar } from 'antd';

class VideoListBatchSelectModal extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            keyword:null,
            visible:false,
            data:[],

            selectedRowKeys:[],
        };

        this.initPageSize = 8;

        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        this.totalNum = 1000;
    }

    videoList(){ //视频列表
        api.video.videoList(
            {
                "condition": {
                    "videoKeywords": [this.state.keyword]
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data)=>{
                if(state && data.status === 200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        data:data.data
                    })
                }
            }
        )
    }

    pageOnChange(pageNum,pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        this.setState({selectedRowKeys:[]})
        setTimeout(()=>this.videoList(), 200);
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        this.totalNum = 1000;
        this.setState({selectedRowKeys:[]})
        setTimeout(()=>this.videoList(), 200,);
    }

    componentDidMount() {
        this.videoList();
    }

    isInExcludes(item)
    {
        if(this.props.excludes)
        {
            let excludes = this.props.excludes;
            for(let i = 0; i < excludes.length; i++)
            {
                let exclude = excludes[i];
                if(exclude.vid)
                {
                    if(item.vid)
                    {
                        if(item.vid === exclude.vid)
                        {
                            return true;
                        }
                    }
                    else if(item.videoId)
                    {
                        if(item.videoId === exclude.vid)
                        {
                            return true;
                        }  
                    }
                }
                else if(exclude.videoId)
                {
                    if(item.vid)
                    {
                        if(item.vid === exclude.videoId)
                        {
                            return true;
                        }
                    }
                    else if(item.videoId)
                    {
                        if(item.videoId === exclude.videoId)
                        {
                            return true;
                        }  
                    }                    
                }
            }
        }
        else
        {
            return false;
        }

        return false;
    }
    
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys });
    };
    
    handleAdd=()=>{
        if(this.props.handleAdd)
        {
            this.props.handleAdd(this.state.selectedRowKeys);
            setTimeout(()=>{
                this.setState({selectedRowKeys:[]})
            },100)
        }
    };

    render() {

        let {selectedRowKeys} = this.state;

        const columns = [
            {
                title: '封面',
                dataIndex: 'posterPath',
                key: 'posterPath',
                className:"tableOverflow",
                width:20,
                render: (text, record) => (
                    <Avatar 
                     size='large'
                     shape="square"
                     src={record.posterPath} 
                    />
                ),
            },
            {
                title: '视频名',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow",
            },
        ];

        const hasSelected = selectedRowKeys.length > 0;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps:(record)=>{
                if(this.isInExcludes(record))
                {
                    return {disabled:true};
                }
                else
                {
                    return null;
                }
            }
        };

        return (
            <>
               <Button type="primary"
                        style={{marginBottom:"18px"}}
                        onClick={()=>{
                            this.setState({
                                visible: true,
                            })
                        }}>
                    {this.props.title}
                </Button>
                <Modal
                    title="添加视频"
                    width={800}
                    centered={true}
                    visible={this.state.visible}
                    onCancel={()=>{
                        this.setState({
                            visible:false
                        })
                    }}
                    footer={null}
                >
                 <Row gutter={15}>
                        <Col span={7} >
                            <span>
                                <Input defaultValue={this.state.keyword}
                                       placeholder='视频关键字搜索'
                                       onChange={(e)=>this.setState({keyword:e.target.value})}
                                />
                            </span>
                        </Col>
                        <Col span={3} >
                            <div>
                                <Button type='primary'
                                        onClick={()=>this.research()}>
                                    搜索</Button>
                            </div>
                        </Col>
                    </Row>
                    <Divider/>
                    <Button type="primary" 
                    onClick={this.handleAdd} 
                    disabled={!hasSelected}>
                        添加
                    </Button>
                    <span style={{ marginLeft: 8 }}>
                        {hasSelected ? `共选中 ${selectedRowKeys.length} 人` : ''}
                    </span>
                    <Divider/>
                    <Table
                        className="components-table-demo-nested"
                        dataSource={this.state.data}
                        columns={columns}
                        rowKey='vid'
                        rowSelection={rowSelection}
                        pagination={false}
                    />
                    <div style={{paddingTop:"25px"}}>
                        <Pagination
                            onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.pageSize}
                            current={this.pageNum}
                            total={this.totalNum}
                        />
                    </div>
                </Modal>
            </>
        );
    }
}

export default VideoListBatchSelectModal;
