import React, {Component} from 'react';
import {Row, Col, Select, Input, List, Tag, Icon, Divider, Button, Tooltip, Form, Card, Pagination} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import { PlayCircleOutlined } from '@ant-design/icons';
import ComFunction from '../../../api/ComFunction';

const {Option} = Select;
const {Meta} = Card;
const InputGroup = Input.Group;

class moreVideoList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectType: "keyWords",
            lectureName: "",
            teacherName: "",
            videoKeywords: [],

            data: [],
        };
        this.initPageSize = 8;

        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        this.totalNum = this.initPageSize;
    }

    pageOnChange(pageNum, pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(() => this.updateData(), 200);
    }

    updateData() {
        api.video.videoList(
            {
                "condition": {
                    "lectureDateEnd": this.state.lectureDateEnd,
                    "lectureDateStart": this.state.lectureDateStart,
                    "lectureName": this.state.lectureName,
                    "teacherName": this.state.teacherName,
                    "status": null,
                    "videoKeywords": this.state.videoKeywords
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data) => {
                if (state && data.status == 200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        data: data.data,
                    })
                }
            }
        )
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        setTimeout(() => this.updateData(), 200,);
    }

    resetData() {
        this.props.form.resetFields();
        //setTimeout(()=>this.research(), 200,)
    }

    componentDidMount() {
        this.updateData();
    }

    render() {
        let history = this.props.history;
        const {getFieldDecorator} = this.props.form;
        return (
            <MyLayout>
                {/*
                <p className="userEditTitle">视频列表</p>
                */}
                <Row>
                    <Col push={6} span={18} className="groupPosition">
                        <InputGroup compact>
                            <Select defaultValue="keyWords"
                                    size="large"
                                    onChange={(value) => this.setState({selectType: value})}>
                                <Option value="keyWords">关键字</Option>
                                <Option value="teacher">讲师名</Option>
                            </Select>
                            <span style={{width: '30%'}}>{(() => {
                                    switch (this.state.selectType) {
                                        case "keyWords":
                                            return <Input size="large"
                                                          defaultValue={this.state.videoKeywords}
                                                          placeholder='请输入视频关键字'
                                                          onChange={(e) => this.setState({videoKeywords: [e.target.value]})}
                                            />;
                                        case "teacher":
                                            return <Input size="large"
                                                          defaultValue={this.state.teacherName}
                                                          placeholder='请输入讲师名'
                                                          onChange={(e) => this.setState({teacherName: e.target.value})}
                                            />;
                                        default:
                                            return null
                                    }
                                }
                            )()}</span>
                            <div>
                                <Button type='primary'
                                        size="large"
                                        icon="search"
                                        onClick={() => this.research()}>
                                    搜索</Button>
                            </div>
                        </InputGroup>
                    </Col>
                    <Divider/>
                    <List
                        grid={{gutter: 20, column: 4}}
                        itemLayout="vertical"
                        size="large"
                        dataSource={this.state.data}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                <Card hoverable
                                      cover={<img alt="example" style={{height: "170px"}} src={item.posterPath}/>}
                                      onClick={() =>
                                          history.push("/Personal/VideoDetail" + '/' + item.vid)
                                      }
                                >
                                    <Meta title={item.name}
                                          style={{height: "100px"}}
                                          description={
                                              <div>
                                                  <div className="twoElements">
                                                      <Tooltip title={item.intro}>
                                                          {item.intro}
                                                      </Tooltip>
                                                  </div>
                                                  <Row>
                                                      <Col span={16}>
                                                          <div className="home-PriceValue">
                                                              {
                                                                  item.price == 0 ?
                                                                      <div>
                                                                          限时免费
                                                                      </div> :
                                                                      <div>
                                                                 <span style={{fontSize: "16px", color: "#aaaaaa"}}>
                                                                     价格：
                                                                 </span>
                                                                          ￥{item.price / 100}
                                                                      </div>
                                                              }
                                                          </div>
                                                      </Col>
                                                      <Col span={8} style={{color: "#b7b7b7", paddingTop: "10px"}}>
                                                    <span>
                                                         <PlayCircleOutlined />{ComFunction.getVisitedTimesDisplayText(item.visitTimes)}
                                                    </span>
                                                      </Col>
                                                  </Row>
                                              </div>
                                          }/>
                                </Card>
                            </List.Item>
                        )}
                    />
                </Row>
                <Pagination
                    onChange={(pageNum, pageSize) => this.pageOnChange(
                        pageNum, pageSize
                    )}
                    pageSize={this.pageSize}
                    current={this.pageNum}
                    total={this.totalNum}
                />
            </MyLayout>
        )
    }
}

export default Form.create()(moreVideoList);