import React, { Component } from 'react';
import {BackTop, Form, Layout} from 'antd';
import { withRouter } from 'react-router'
import {connect } from 'react-redux';

import NavbarSelect from './NavbarSelect';
import Animation from "./Animation";
import CommonFooter from './CommonFooter';
let {Content} = Layout;

class HomeLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        let history = this.props.history;
        let userInfo = this.props.userInfo;
        return (
            <>
                <Layout>
                    <NavbarSelect/>
                    <Animation/>
                    <Layout style={{
                        background: '#fff',
                        paddingLeft:50,
                        paddingRight:50,
                        paddingTop:5,
                    }}>
                        <Content
                            style={{
                                background: '#fff',
                                paddingLeft:50,
                                paddingRight:50,
                                paddingTop:5,
                            }}
                        >
                            {this.props.children}
                        </Content>
                    </Layout>
                    <CommonFooter/>
                </Layout>
                {
                    userInfo && userInfo.role == 0?
                        <BackTop visibilityHeight={-1}>
                            <div className="homeQa" onClick={()=>history.push('/qa/GetMyCreatedTopicList')}/>
                        </BackTop>:""
                }
            </>
        );
    }
}


function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default withRouter(connect(mapStateToProps,null)(HomeLayout));