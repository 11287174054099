import React, { Component } from 'react';
import {Modal, Table, Divider, PageHeader, Button} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import majorData from "../../../config/majorData";
import RoutePath from "../../../config/RoutePath";
import ComFunction from '../../../api/ComFunction';
import TaskModalSearch from '../../common/TaskModalSearch';
const { confirm } = Modal;

class GetMyOcuppyClasses extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:[],
        };
    }

    getMyOcuppyClasses() {
        api.taskadmin.getMyOcuppyClasses("",
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.getMyOcuppyClasses();
    }

    render() {
        let history = this.props.history;
        const columns = [
            {
                title: '班级名称',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow",
            },
            {
                title: '班级编号',
                dataIndex: 'classId',
                key: 'classId',
            },
            {
                title: '班级所属专业',
                dataIndex: 'subjectId',
                key: 'subjectId',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 60,
                        }
                    }
                },
                render: (text, record) => (
                    <span>
                        {majorData.getValue(text,'')}
                    </span>
                ),
            },
            {
                title: '待审核/正式学员',
                dataIndex: 'studentNumOk',
                key: 'studentNumOk',
                render: (text, record) => (
                    <span>{record.studentNumApp}/{record.studentNumOk}</span>
                ),
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime',
                key: 'createdTime',
                render: (text, record) => (
                    <span>{ComFunction.getShortTime(record.createdTime)}</span>
                ),
            },
            {
                title: '操作',
                dataIndex: 'NoticePub',
                key: 'NoticePub',
                render: (text, record) => (
                    <span>
                        <Button
                         type='primary'
                         onClick={()=>history.push(RoutePath.NoticePub+'/2/'+record.classId)}
                        >发布公告</Button>
                        <Divider type='vertical'/>
                        <Button
                         type='primary'
                         onClick={()=>history.push('/taskadmin/GetStudentsList'+'/'+record.classId)}
                        >学员管理</Button>
                        <Divider type='vertical'/>
                        <Button  
                        icon="setting" 
                        onClick={()=>history.push(RoutePath.ExportSetting+'/'+record.classId)}
                        />
                          
                    </span>
                ),
            },
        ];
        return(
            <MyLayout>
                <PageHeader
                    style={{padding:"0"}}
                    title="我的班级列表"
                    subTitle="我的班"
                />
                <Divider/>
                <Table
                    rowKey='classId'
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
            </MyLayout>
        )
    }
}

export default GetMyOcuppyClasses;