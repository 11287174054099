

import React, { Component } from 'react';

import { Pagination, Button, Col, Divider, Input, Row, Table, Tag, Icon, Tooltip } from 'antd';
import api from '../../api/api';
import Dict from '../../config/Dict';


class TestPaperSearchCompnent extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            keyword:null,

            data:[],
            pageNum:1,
            pageSize:4,
            totalNum:99,
         };

         this.initPageSize = 4;

         this.pageNum = 1;
         this.pageSize = this.initPageSize;
         this.totalNum = this.initPageSize;
    }

    pageOnChange(pageNum,pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(()=>this.searchExamPaper(), 200);
    }

    searchExamPaper() {
        api.exam.searchExamPaper(
            {
                "condition": {
                    "keyword": this.state.keyword,
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    if (this.state.keyword){
                        for (let i = 0; i < data.data.length; i++) {
                            const cut =  data.data[i].description.split(this.state.keyword);
                            data.data[i].description = cut.join('<span style="color:red;">' + this.state.keyword + '</span>');
                        }
                    }
                    this.totalNum = data.totalNum;
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        setTimeout(()=>this.searchExamPaper(), 200,)
    }

    componentDidMount() {
        this.searchExamPaper();
    }


    isDisabled(record)
    {
        let hasSelectedData = this.props.hasSelectedData;
        if(hasSelectedData)
        {
            for(let i = 0; i < hasSelectedData.length; i++)
            {
                if(hasSelectedData[i].paperId === record.paperId)
                {
                    return true;
                }
            }
        }
        return false;
    }

    render() {
        const columns = [
            {
                title: '试卷题目',
                dataIndex: 'title',
                key: 'title',
                className:"tableOverflow",
                render: (text, record) => (
                    <Tooltip title={text}>
                        {text}
                    </Tooltip>
                ),
            },
            {
                title: ' 试卷类型',
                dataIndex: 'examType',
                key: 'examType',
                render: (text, record) => (
                    <Tag color={text==0?"#87d068":"#2db7f5"}>
                        {
                            Dict.getValue('examType',text,'')
                        }
                    </Tag>
                ),
            },
            {
                title: '总题目数',
                dataIndex: 'itemNum',
                key: 'itemNum',
                className:"tableOverflow",
            },
            {
                title: '考试时长(分钟)',
                dataIndex: 'duration',
                key: 'duration',
            },
            {
                title: '创建日期',
                dataIndex: 'createTime',
                key: 'createTime',
                render: (text, record) => (
                    text.substring(0,10)
                ),
            },
            {
                title: '操作',
                dataIndex: 'opr',
                key: 'opr',
                render: (text, record) => (
                    <Button
                     type='primary'
                     disabled={this.isDisabled(record)}
                     onClick={()=>{
                         if(this.props.onSelectItem)
                         {
                             this.props.onSelectItem(record);
                         }
                     }}
                    >选择</Button>
                ),
            }
        ];

        return (
            <div>
                <Row gutter={16}>
                        <Col span={7} ></Col>
                        <Col span={7} >
                            <span>
                                <Input value={this.state.keyword}
                                       placeholder='请输入关键字'
                                       onChange={(e)=>{
                                           this.setState({keyword:e.target.value})
                                       }}
                                />
                            </span>
                        </Col>
                        <Col span={3} >
                            <div>
                                <Button type='primary'
                                        onClick={()=>this.research()}>
                                    搜索</Button>
                            </div>
                        </Col>
                        <Col span={5}></Col>
                        <Col span={2}>
                        {
                            this.props.onClose?<Button 
                            shape='circle'
                            onClick={()=>this.props.onClose()}
                            ><Icon type='close'/></Button>:null
                        }
                        </Col>
                </Row>
                <Divider/>
                <Table
                        rowKey='paperId'
                        pagination={false}
                        dataSource={this.state.data}
                        columns={columns}
                    />
                    <Divider/>
                <Pagination
                        onChange={(pageNum,pageSize)=>this.pageOnChange(
                            pageNum,pageSize
                        )}
                        pageSize={this.pageSize}
                        current={this.pageNum}
                        total={this.totalNum}
                /> 
            </div>
        );
    }
}

export default TestPaperSearchCompnent;


