import { Divider, Modal, Typography } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import api from '../../../api/api'
import ComFunction from '../../../api/ComFunction'
import RoutePath from '../../../config/RoutePath'
import MyLayout from '../../common/MyLayout'
import CommonComments from '../comment/CommonComments'
import OkOrNg from '../newsInformation/OkOrNg'
import AddComment from './AddComment'

const {Title, Paragraph, Text} = Typography

class NewsDetail extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            newsData: {},
            submitting: false,
            showReply: false,
            flushData: 123,
        }
    }

    showReply = () => {

        if (this.props.userInfo == null) {
            Modal.confirm(
                {
                    title: '没有登录',
                    content: '只有登录用户才能点赞评论，登录?',
                    onOk: () => this.props.history.push(RoutePath.Login),
                    okText: '去登录',
                    onCancel: () => {},
                    cancelText: '取消',
                }
            )
            return;
        }

        this.setState({showReply: !this.state.showReply})
    }

    getNewsId()
    {
        return this.props.match.params.newsId;
    }

    getNewsDetail = () => {
        api.news.getNewsDetail({newsId:this.getNewsId()}, (state, data) => {
            if (state && data.status === 200) {
                this.setState({
                    newsData: data.data,
                })
            }
        })
    }

    onUpdate=()=>{
        this.setState({flushData:Math.random()})
    }


    componentDidMount()
    {
        this.getNewsDetail()
    }

    render() {
        const {newsData} = this.state
        return (
            <MyLayout>
                <Typography>
                    <Title level={2} align="middle">
                        {this.state.newsData.newsTitle}
                    </Title>
                    <Paragraph align="middle">
                        <Text style={{marginRight: 20}}> {this.state.newsData.createdTime}</Text>
                        <Text style={{marginRight: 20}}>{this.state.newsData.newsAuthor}</Text>
                        <Text style={{marginRight: 20}}>评论数&nbsp; {ComFunction.getVisitedTimesDisplayText(this.state.newsData.newsCommentNum)}</Text>
                        <Text style={{marginRight: 20}}>阅读数&nbsp; {ComFunction.getVisitedTimesDisplayText(this.state.newsData.newsVisitedTimes)}</Text>
                    </Paragraph>
                    <Divider></Divider>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: this.state.newsData.newsContent,
                        }}
                    />
                    <OkOrNg
                        style={{display: 'inlineBlock'}}
                        okId={newsData.newsId}
                        okNumber={newsData.newsOks}
                        ngNumber={newsData.newsNgs}
                        likeStatus={newsData.likeStatus}
                        okType="0"
                    />
                    <a style={{marginLeft: 15}} onClick={this.showReply}>
                    {this.state.showReply ? '收起' : '回复'}
                    </a>
                </Typography>
                <Divider></Divider>
                {this.state.showReply ? (
                    <AddComment
                        commentRows="4"
                        commonCommentId={this.getNewsId()}
                        commentType="0"
                        onUpdate={this.onUpdate}
                    ></AddComment>
                ) : null}

                <CommonComments flushData={this.state.flushData} commentType="0" commonId={this.getNewsId()} />
            </MyLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}

export default connect(mapStateToProps, null)(NewsDetail)
