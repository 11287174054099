import React, { Component } from 'react';
import {Form, Modal, Table, Pagination, Input, Button, message, Icon, Divider} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";

const { confirm } = Modal;

class FieldList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fid:null,
            name:"",
            visible:false,
            data:null,

            pageNum:1,
            pageSize:8,
            totalNum:9,
        };
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.getFieldList(), 200);
    }

    handleOk = () => {
        let fid = this.state.fid;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.admin.addOrEditField(
                    {fid, ...values},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.setState({
                                visible: false,
                                name:""
                            });
                            this.props.form.resetFields();
                            setTimeout(()=>this.getFieldList(), 200,)
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });

    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    getFieldList(){
        api.anon.fieldList({
                "condition": "",
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    delField(fieldId){
        confirm({
            title: '你确定要删除这个领域吗?',
            content: '你删除的领域将不再显示在领域列表中。',
            onOk:()=>{
                api.admin.delField({fieldId},
                    (state, data)=>{
                        if(state && data.status==200) {
                            setTimeout(()=>this.getFieldList(), 200,)
                        }
                    }
                )
            },
            onCancel() {
            },
        });

    }

    componentDidMount() {
        this.getFieldList();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const columns = [
            {
                title: '领域名称',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <a onClick={()=>{
                            this.setState({
                                visible: true,
                                fid:record.fid,
                                name:record.name,
                            })


                        }}>编辑领域</a>
                        <Divider type="vertical" />
                        <a onClick={()=>this.delField(record.fid)}>删除领域</a>
                    </span>
                ),
            }];
        return(
            <MyLayout>
                <p className="userEditTitle">领域管理</p>
                <Divider/>
                <div>
                    <Button onClick={()=>{
                        this.setState({
                            visible: true,
                            fid:null,
                            name:"",
                        })
                    }} type="primary" style={{ marginBottom: 16 }}>
                        添加领域
                    </Button>
                    <Divider dashed/>
                    <Table
                        pagination={false}
                        dataSource={this.state.data}
                        columns={columns}
                    />
                    {/**
                    <div style={{paddingTop:"25px"}}>
                        <Pagination
                            onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.state.pageSize}
                            current={this.state.pageNum}
                            total={this.state.totalNum}
                        />
                    </div>
                     */}
                </div>
                <div>
                    <Modal
                        title="添加/编辑领域"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <Form className="modal-form-style">
                            <Form.Item label="领域名">
                                {getFieldDecorator('name', {
                                    initialValue:this.state.name,
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入领域名!'
                                        },
                                    ],
                                })(
                                    <Input size="large"
                                           prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                           placeholder="领域名"
                                    />,
                                )}
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </MyLayout>
        )
    }
}

export default Form.create()(FieldList);