import React, { Component } from 'react';
import {Button, Badge, message, Modal, Form} from 'antd';
import MyLayout from "../../../common/MyLayout";
import Dict from '../../../../config/Dict';
import api from "../../../../api/api";
import SceneList from "../scene/SceneList";
import '../../../../assets/style/common.css';
import connect from "react-redux/es/connect/connect";

const { confirm } = Modal;

class LectureDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lectureData:null,
        };
    }

    lectureDetail() {
        let lectureId = this.props.match.params.lid;
        api.lecture.lectureDetail({lectureId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        lectureData:data.data,
                    })
                }
            }
        )
    }

    cancellation(){
        let lectureId = this.props.match.params.lid;
        
        confirm({
            title: '你确定要注销这讲座吗?',
            content: "注销的讲座不会被删除，注销后申请者可以重新'提交审核',重新上线这个讲座。",
            onOk:()=>{
                api.lecture.offLecture({lectureId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            /*
                            if(this.state.role == 5){
                                this.props.history.push('/admin/pendingLectureList')
                            }else{
                                this.lectureDetail();
                            }*/
                            this.lectureDetail();
                        }
                    }
                )
            }
        });
    }

    reOnline(){
        let lectureId = this.props.match.params.lid;
        confirm({
            title: '你确定要申请上线这个讲座吗?',
            content: '申请上线的讲座将进入[审批中]的状态，管理员审批通过后，讲座才发布成功。',
            onOk:()=>{
                api.lecture.applyLecture(
                    {lectureId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.lectureDetail();
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            },
        });
    }

    delLecture(){
        let lectureId = this.props.match.params.lid;
        confirm({
            title: '你确定要删除这个讲座吗?',
            content: '你删除的讲座将不再显示在讲座列表中。',
            onOk:()=>{
                api.lecture.delLecture(
                    {lectureId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.props.history.push('/company/LectureList');
                        }
                    }
                )
            }
        });
    }

    examineOrg(status){
        let lectureId = this.props.match.params.lid;
        let title = "";
        let content = "";
        if (status == 1){
            title = "你确定要[审批通过]这个讲座吗?";
            content = "讲座[审批通过]后才这个讲座才能被用户看见。"
        }else{
            title = "你确定要[审批驳回]这个讲座吗?";
            content = "讲座[审批驳回]后信息会返回给请求者。"
        }
        confirm({
            title: title,
            content:content,
            onOk:()=>{
                api.lecture.examineLecture({lectureId,status},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.props.history.push('/admin/pendingLectureList')
                        }
                    }
                )
            }
        });
    }

    componentDidMount() {
        this.lectureDetail();
    }

    render() {
        const {
            lectureData= null
        } = this.state;
        let history = this.props.history;
        return(
            <MyLayout>
                <div className="heading">
                    <div className="heading_title">{lectureData!= null?lectureData.name:""}</div>
                    <div className="heading_content">
                        <div className="heading_img">
                        {lectureData!= null && lectureData.posterPath != null ? <img
                        width={220}
                        src={lectureData.posterPath}
                    />:"未上传"}</div>
                        <div className="heading_mes">
                            <div className="audit-hints">
                                {lectureData!= null? Dict.getValue('lectureState',lectureData.status,''):null}
                            </div>
                            <p className="mes_title"><span>课程时间：</span>
                                {lectureData!= null?lectureData.startDate.substring(0,16)+" -- "+
                                lectureData.endDate.substring(0,16):""}
                            </p>
                            <p className="mes_title"><span>主讲人：</span>
                                {lectureData!= null?lectureData.teachers:""}
                            </p>
                            <p className="mes_title"><span>举办单位：</span>
                                {lectureData!= null?
                                    <a onClick={()=>
                                        history.push('/Personal/OrgDetailsPersonal'+'/'+lectureData.org.orgId)}>
                                        {lectureData.org.orgName}
                                    </a>:""}
                            </p>
                            <p className="mes_title"><span>审核状态：</span>
                                {lectureData!= null? <Badge style={{color:"#f42429"}}
                                status="processing"
                                text={
                                    Dict.getValue('lectureState',lectureData.status,'')} />:null}
                            </p>
                            <p className="mes_title"><span>讲座介绍：</span>
                                {lectureData!= null?lectureData.intro:""}
                            </p>
                        </div>
                        <div className="btn_item">
                            {
                                this.props.userInfo && this.props.userInfo.role == 5 ?
                                <div>
                                    <Button type="primary"
                                            onClick={()=>this.examineOrg(1)}
                                    >
                                        通过审批</Button>
                                    <Button type="primary"
                                            onClick={()=>this.examineOrg(2)}
                                    >
                                        驳回审批</Button>
                                </div>:
                                <div>
                                    <Button type="primary" onClick={()=>this.reOnline()}>提交审核</Button>
                                    {
                                        lectureData!= null && lectureData.status !=0 ?
                                        <Button type="primary" onClick={()=>this.cancellation()}>注销讲座</Button>
                                        : <Button type="primary" onClick={()=>this.delLecture()}>删除讲座</Button>
                                    }
                                    <Button type="primary"
                                            onClick={()=>
                                                this.props.history.push('/company/addOrEditLecture'+'/'+ this.props.match.params.lid)}>
                                        编辑讲座</Button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <SceneList lectureId = {this.props.match.params.lid}/>
            </MyLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default connect(mapStateToProps,null)(LectureDetail);
