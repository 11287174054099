import React from 'react'
import MyLayout from '../../common/MyLayout'
import {Col, Row, Table, Divider, Button, Pagination, Select} from 'antd'
import api from '../../../api/api'

class CommentsAuditing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            totalNum: null,
        }
        this.initPageSize = 10
        this.pageNum = 1
        this.pageSize = this.initPageSize
        this.getComments()
    }
    getComments = () => {
        api.news.pageSearchNewsComment(
            {
                condition: {
                    pid: '',
                    status: 0,
                },
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            },
            (state, data) => {
                if (state && data.status === 200) {
                    console.log(this.state.data)
                    this.setState({
                        data: data.data,
                        totalNum: data.totalNum,
                    })
                }
            }
        )
    }
    examComment = (status, commentId) => {
        api.news.examComment(
            {
                commentId,
                status,
            },
            (state, data) => {
                if (state && data.status === 200) {
                    this.getComments()
                }
            }
        )
    }
    pageOnChange = (pageNum, pageSize) => {
        this.pageNum = pageNum
        this.pageSize = pageSize
        setTimeout(() => this.examComment(), 200)
    }
    render() {
        const columns = [
            {
                title: '评论者',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '评论内容',
                dataIndex: 'content',
                key: 'content',
            },
            {
                title: '评论时间',
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <a
                            onClick={() =>
                                this.examComment(1, record.commentId)
                            }
                        >
                            通过
                        </a>
                        <Divider type="vertical"></Divider>
                        <a
                            onClick={() =>
                                this.examComment(2, record.commentId)
                            }
                        >
                            驳回
                        </a>
                    </span>
                ),
            },
        ]
        return (
            <MyLayout>
                <p className="userEditTitle">评论审核</p>
                <Divider />
                <Row gutter={5}>
                    <Table
                        pagination={false}
                        dataSource={this.state.data}
                        columns={columns}
                        rowKey="commentId"
                    />
                    <Divider style={{background: 'transparent'}} />
                    <Pagination
                        onChange={(pageNum, pageSize) =>
                            this.pageOnChange(pageNum, pageSize)
                        }
                        pageSize={this.pageSize}
                        current={this.pageNum}
                        total={this.state.totalNum}
                    />
                </Row>
            </MyLayout>
        )
    }
}
export default CommentsAuditing
