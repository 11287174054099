import React, { Component } from 'react';
import {Col, Row, Button, Descriptions, Modal} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import poster from '../../../assets/images/1.jpg';
import NoTimeContent from "../../../components/NoTimeContent";
import moment  from 'moment';
const { confirm } = Modal;

class OrderForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:null,
            sceneData:null,

            endTimeFlg:true //支付是否结束 true:没结束，false:结束了
        };
    }

    lectureDetail(sceneId) {
        api.lecture.sceneDetail({sceneId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        sceneData:data.data,
                    });
                }
            }
        )
    }

    getBizOrderById=()=> {
        let outTradeNo = this.props.match.params.outTradeNo;
        api.biz.getBizOrderById({outTradeNo},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    });
                    this.lectureDetail(data.data.sceneId);
                }
            }
        )
    };

    SeatNumber(){
        if (this.state.data == null) {
            return ;
        }
        let result = this.state.data.body.split(",");
        let spans = [];
        for(let i=0; i < result.length; i++){
            spans.push(
                <p key={i} className="order-subtitle">{result[i]}</p>
            );
        }
        return spans;
    }

    lockTime(){
        if (this.state.data == null) {
            return ;
        }
        let date = this.state.data.createdTime;
        //生成订单后15分钟过期
        let endDate = moment(new Date(date)).add(15, 'minute').format('YYYY/MM/DD HH:mm');

        return endDate
    }

    alipay=()=>{
        let outTradeNo = this.props.match.params.outTradeNo;
        if(window.location.protocol == "http:")
        {
            window.open("http://www.deshipt.com/api/alipay/alipay?scheme=http&outTradeNo="+ outTradeNo);
        }
        else
        {
            window.open("https://www.deshipt.com/api/alipay/alipay?scheme=https&outTradeNo="+ outTradeNo);
        }

        confirm({
            title: '支付确认',
            content: '您的订单是否支付成功?',
            onOk:()=>{
                this.getBizOrderById();
            },
            onCancel:()=> {
                this.getBizOrderById();
            },
        });
    };

    componentDidMount() {
        this.getBizOrderById();
    }

    render() {
        let history = this.props.history;
        const {data, sceneData, endTimeFlg} = this.state;

        return(
            <MyLayout>
                <div>
                    <div className="order-time">
                        {
                            data && data.paidTime != null?
                                <span className="order-tips">这个订单已经支付完成。</span>:
                                <div>
                                    {
                                        endTimeFlg?
                                        <span className="order-tips">
                                            请确认您的订单信息，并在15分钟完成付款，如果超时系统将释放座位。
                                        </span>:
                                        <span className="order-tips">
                                            这个订单已经超时(结束)，请重新下单
                                        </span>
                                    }
                                    <div className="order-timeContent">
                                        <span className="order-timeTitle">剩余支付时间</span>
                                        <span className="order-timeSurplus">
                                            <NoTimeContent endTime={this.lockTime()}
                                                   timeEnd={(e)=>{
                                                       this.setState({
                                                           endTimeFlg:false,
                                                       })
                                                   }}
                                            />
                                        </span>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="order-number">订单号： {data!= null?data.outTradeNo:""}</div>
                    <Descriptions layout="vertical" column={6} bordered>
                        <Descriptions.Item key='createdTime' label="讲座" span={2}>
                            <div className="order-intro">
                                <div className="order-img">
                                    {sceneData!= null && sceneData.teacher.photoPath != null ? <img
                                        width={120}
                                        src={sceneData.teacher.photoPath}
                                    />:""}
                                </div>
                                <div className="order-content">
                                    <p className="order-title">{sceneData!= null?sceneData.name:""}</p>
                                    <p className="order-subtitle">开始时间：
                                        {sceneData!= null?sceneData.startTime.substring(0,16):""}
                                    </p>
                                    <p className="order-subtitle">结束时间：
                                        {sceneData!= null?sceneData.endTime.substring(0,16):""}
                                    </p>
                                </div>
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item key='updatedTime' label="场次" span={1}>
                            <div className="order-content">
                                <p className="order-title">
                                    {sceneData!= null && sceneData.venue?sceneData.venue.location:""}
                                </p>
                                <p className="order-startTime">
                                    {sceneData!= null?sceneData.startTime.substring(0,16):""}
                                </p>
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item key='status' label="票数/座位" span={1}>
                            <div className="order-content">
                                {this.SeatNumber()}
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item key="name" label="总金额" span={1} >
                            <div className="order-content">
                                <p className="order-startTime">￥ {data!= null ?
                                    (data.totalAmount/100).toFixed(2) :""}元</p>
                            </div>
                        </Descriptions.Item>
                        {/*
                        <Descriptions.Item key='vid' label="接收电子码电话"  span={1}>
                            <div className="order-content">
                                <p className="order-title">13940868314</p>
                                <p className="order-subtitle">1.短息可能被手机拦截，请到该软件中查看购票信息。</p>
                                <p className="order-subtitle">2.若修改手机号，需要重新下单。</p>
                            </div>
                        </Descriptions.Item>
                        */}
                    </Descriptions>

                    <Row>
                        <Col span={24}>
                            <p className="order-money">
                                实付款：￥
                                {data!= null ?
                                    (data.totalAmount/100).toFixed(2) :""}元
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button type="danger"
                                    htmlType="submit"
                                    className="order-submitButton"
                                    disabled={!this.state.endTimeFlg ||(data && data.paidTime != null)}
                                    onClick={()=>this.alipay()}
                                    block>
                                确认订单，立即支付
                            </Button>
                        </Col>
                    </Row>
                </div>
            </MyLayout>
        )
    }
}

export default OrderForm;