import React, { Component } from 'react';
import {Col, Row, Modal, Table, Pagination, Divider, Input, Button, Select} from 'antd';
import api from '../../../../api/api';
import Dict from "../../../../config/Dict";
import MyLayout from "../../../common/MyLayout";
import AddOrEditGround from "./AddOrEditGround";

const InputGroup = Input.Group;

class MyGroundModelList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",

            data:[],
            pageNum:1,
            pageSize:8,
            totalNum:9,
        };
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.updateData(), 200);
    }

    updateData() {
        api.lecture.myGroundModelList({
                "condition": {
                    "name": this.state.name,
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:4,
            totalNum:9,
        });
        setTimeout(()=>this.updateData(), 200,)
    }

    onChange(ar){
        if (ar){
            this.updateData();
        }
    }

    componentDidMount() {
        this.updateData();
    }

    render() {
        let history = this.props.history;
        const columns = [
            {
                title: '场地名称',
                dataIndex: 'name',
                key: 'name',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow:'ellipsis',
                            cursor:'pointer'
                        }
                    }
                },
                render:  (text, record) => (
                    <a onClick={()=>history.push('/company/GroundDetail'+'/'+record.id)}>{text}</a>
                )
            },
            {
                title: '更新时间',
                dataIndex: 'createdTime',
                key: 'createdTime',
                render: (text, record) => (
                    <span>
                        {record.updatedTime?record.updatedTime:record.createdTime}
                    </span>
                ),
            },
            {
                title: '座位行/列数',
                key: 'rows',
                render: (text, record) => (
                    <span>
                        {record.rows}行/{record.cols}列
                    </span>
                ),
            },
            {
                title: '审核状态',
                key: 'status',
                dataIndex: 'status',
                render: (text, record) => (
                    <span>
                        {
                            Dict.getValue('orgState',text,'')
                        }
                    </span>
                ),
            }
        ];
        return(
            <MyLayout sider={"InfoManageSider"}>
                <p className="userEditTitle">场地模板列表</p>
                <Divider />
                <Row gutter={15}>
                    <Col span={17} >
                        <InputGroup compact>
                            <span style={{ width: '40%'}}>
                                <Input size="large"
                                       defaultValue={this.state.name}
                                       placeholder='请输入模板名称'
                                       onChange={(e)=>this.setState({name:e.target.value})}
                                />
                            </span>
                            <div>
                                <Button type='primary'
                                size="large"
                                onClick={()=>this.research()}>
                                搜索</Button>
                            </div>
                        </InputGroup>
                    </Col>
                    <Col span={7} >
                        <AddOrEditGround tagType={"add"} onChange={(ar)=>this.onChange(ar)}/>
                    </Col>
                </Row>
                <Divider dashed/>
                <Table
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                    rowKey='id'
                />
                <div style={{paddingTop:"25px"}}>
                    <Pagination
                        onChange={(pageNum,pageSize)=>this.pageOnChange(
                            pageNum,pageSize
                        )}
                        pageSize={this.state.pageSize}
                        current={this.state.pageNum}
                        total={this.state.totalNum}
                    />
                </div>
            </MyLayout>
        )
    }
}

export default MyGroundModelList;