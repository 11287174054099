import {Card, Col, List, Pagination, Row} from 'antd'
import React from 'react'
import api from '../../../api/api'
import RoutePath from '../../../config/RoutePath'
import MyLayout from '../../common/MyLayout'
import NewsClassificationData from '../../../config/newsClassificationData.js'

const {Meta} = Card

class NewsHistory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            totalNum: null,
        }
        this.initPageSize = 8
        this.pageNum = 1
        this.pageSize = this.initPageSize
    }

    fetchNewsList = () => {
        let newsParams = {
            condition: {},
            pageNum: this.pageNum,
            pageSize: this.pageSize,
        }

        api.news.pageSeachNewsHistory(newsParams, (state, data) => {
            if (state && data.status === 200) {
                this.setState({
                    data: data.data,
                    totalNum: data.totalNum,
                })
            }
        })
    }

    componentDidMount() {
        this.fetchNewsList()
    }

    pageOnChange = (pageNum, pageSize) => {
        this.pageNum = pageNum
        this.pageSize = pageSize
        setTimeout(() => this.fetchNewsList(), 200)
    }

    research = () => {
        this.pageNum = 1
        this.pageSize = this.initPageSize
        setTimeout(() => this.fetchNewsList(), 200)
    }

    newsClassConvert = subNumber => {
        if (!subNumber) {
            return '未知'
        }
        const mainNumber = subNumber.toString().substr(0, 3)
        let mainClassValue = NewsClassificationData.mainClass[mainNumber]
        let subClassValue = NewsClassificationData.subClass[subNumber]
        return `${mainClassValue}/${subClassValue}`
    }

    render() {
        let history = this.props.history
        return (
            <MyLayout>
                <List
                    grid={{
                        gutter: 20,
                        column: 4,
                    }}
                    itemLayout="vertical"
                    size="large"
                    dataSource={this.state.data}
                    renderItem={(item, index) => (
                        <List.Item key={index}>
                            <Card
                                hoverable
                                cover={
                                    <img
                                        alt="封面图片"
                                        style={{height: '170px'}}
                                        src={item.newsCoverpath}
                                    />
                                }
                                onClick={() =>
                                    history.push(
                                        RoutePath.NewsDetail + '/' + item.newsId
                                    )
                                }
                            >
                                <Meta
                                    title={item.newsTitle}
                                    style={{height: '50px'}}
                                    description={[
                                        <div
                                            className="multipleTextOverflow"
                                            key={'des:' + index}
                                        ></div>,
                                    ]}
                                />
                                <Row>
                                    <Col span={16}>
                                        <div>{item.visitedTime}</div>
                                    </Col>
                                    <Col
                                        span={8}
                                        style={{
                                            color: '#b7b7b7',
                                        }}
                                    >
                                        <span style={{marginRight: 15}}>
                                            {this.newsClassConvert(
                                                item.newsClassification
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
                <Pagination
                    onChange={(pageNum, pageSize) =>
                        this.pageOnChange(pageNum, pageSize)
                    }
                    pageSize={this.pageSize}
                    current={this.pageNum}
                    total={this.state.totalNum}
                />
            </MyLayout>
        )
    }
}

export default NewsHistory
