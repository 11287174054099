
import React, { Component } from 'react';

let ComFunction = {

    objExtMixin:(target,mixobj)=>{
        let objAll = Object.assign(target,mixobj);
        return Object.assign(objAll,target);
    },

    getScareImgeUrl:(orgFilePath,w,h)=>{
        return "api/authc/download"+orgFilePath+"?w="+w+"&h="+h;
    },

    getVisitedTimesDisplayText:(visitedTimes)=>{
        if(!visitedTimes)
        {
            return '0';
        }

        if(visitedTimes < 10000)
        {
            return ''+visitedTimes;
        }

        return (visitedTimes/10000).toFixed(2)+"w+"
    },

    getShortTime:(tm)=>
    {
        if(tm)
        {
            return tm.substring(0,10);
        }

        return "--";
    },

    getMultiLineNode(txt)
    {
        if(!txt)
        {
            return null;
        }

        return <>
        {
            txt.split('\n').map((item,index)=><p key={"xjkdjad"+index}>{item}</p>)
        }
        </>
    },

    getIntDiveder(fenz,fenm,fixnum)
    {
        if(fenm)
        {
            let tfixnum = 2;
            if(fixnum)
            {
                tfixnum=fixnum;
            }
            return parseFloat((fenz/fenm).toFixed(tfixnum));
        }
        return 0;
    },

    compareTwoDate(dt1,dt2)
    {
        let d1 = new Date(dt1);
        let d2 = new Date(dt2);

        if(d1 > d2)
        {
            return 1;
        }

        if(d1 < d2)
        {
            return -1;
        }

        return 0;
    },
}

export default ComFunction;
