import React, { Component } from 'react';
import { DatePicker } from 'antd';
import moment  from 'moment';


class SimpleDateSel extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    onChange(date,dateString)
    {
        this.props.onChange(dateString);
    }


    render() {
        let dateFormat = "YYYY/MM/DD 00:00:00";
        let value = null;
        if(this.props.value)
        {
            value = moment(this.props.value,dateFormat)
        }
        let picker = "date";
        if(this.props.picker)
        {
            picker = this.props.picker;
        }

        let size = 'large';
        if(this.props.size)
        {
            size = this.props.size;
        }
        return (
            <>
            {
                picker === "month"?
                <DatePicker.MonthPicker 
                size={size}
                value={value}
                format="YYYY/MM"
                onChange={(d,s)=>this.onChange(d,s)}
            />:
                <DatePicker 
                size={size}
                value={value}
                format={dateFormat}
                onChange={(d,s)=>this.onChange(d,s)}
            />
            }
            </>

        );
    }
}

export default SimpleDateSel;