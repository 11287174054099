import React, { Component } from 'react';
import { Modal, Divider, message } from 'antd';
import api from '../../api/api';
let QRCode = require('qrcode.react');
class PlateformConnect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            code: null,
            state: null,
            CodeStatus: {},
            errorMsg: null,
        };
    }

    getCodeStatus() {
        setInterval(() => {
            api.connect.getCodeStatus(
                {
                    code: this.state.code
                },
                (state, data) => {
                    if (state && data.status === 200) {
                        let CodeStatus = data.data;
                        this.setState({ CodeStatus: CodeStatus });
                        if (CodeStatus.role === 0) {
                            let code = this.state.code;
                            let redirectUri = this.getParam("redirectUri");
                            let state = this.getParam("state");
                            window.open(redirectUri + "?code=" + code + "&state=" + state, "_self");
                        }
                    }
                }
            )
        }, 3000);
    }

    getParam(name) {
        var search = this.props.location.search;
        if (search == "") {
            return null;
        }

        var query = search.substring(1);
        var list = query.split('&');
        var item = [];
        for (var i = 0; i < list.length; i++) {
            item = list[i].split('=');
            if (item[0] == name) {
                return item[1];
            }
        }
        return null;
    }

    componentDidMount() {
        let appId = this.getParam("appId");
        if (!appId) {
            return;
        }
        let redirectUri = this.getParam("redirectUri");
        if (!redirectUri) {
            return;
        }
        let state = this.getParam("state");
        if (!state) {
            return;
        }
        this.setState({ state })
        api.connect.getThirdPartCode({
            appId, redirectUri
        },
            (state, data) => {
                if (state && data.status === 200) {
                    this.setState({ code: data.data });
                    this.getCodeStatus();
                }
                else {
                    this.setState({ errorMsg: data.msg });
                }
            }
        )
    }

    render() {
        return (
            <div>
                {
                    this.state.code ?
                        <Modal
                            visible={true}
                            closable={false}
                            footer={null}
                            width={350}
                        >
                            <QRCode
                                size={300}
                                value={this.state.code}
                            />
                            <Divider />
                            <div style={{ textAlign: 'center' }}>{this.state.CodeStatus.name}</div>
                            <div>请使用德师平台公众号开始学习扫码登录</div>
                            {/* <div>请使用德师平台公众号开始学习扫码登录</div> */}
                        </Modal>
                        : <div>{this.state.errorMsg}</div>
                }
            </div>
        );
    }
}

export default PlateformConnect;





