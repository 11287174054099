import React, { Component } from 'react';
import {Tabs} from 'antd';
import '../../assets/style/common.css';
import MyWholePlayRecord from './MyWholePlayRecord';
import MyWholeAnswerRecord from './MyWholeAnswerRecord';
import MyWholeTaskRecord from './MyWholeTaskRecord';
import MyPagePlayRecord from './MyPagePlayRecord';
import { connect } from 'react-redux';
const { TabPane } = Tabs;

class LearningRecords extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {

        let isMe = false;
        if(this.props.userInfo)
        {
            if(this.props.uid === this.props.userInfo.uid)
            {
                isMe = true;
            }
        }

        return(
            <div style={{paddingTop:"20px"}}>
                <Tabs defaultActiveKey="3">
                    <TabPane tab="播放记录" key="1">
                            {
                                isMe?
                                <MyPagePlayRecord uid={this.props.uid}/>
                                : <MyWholePlayRecord uid={this.props.uid}/>
                            }
                    </TabPane>
                    <TabPane tab="答题记录" key="2">
                            <MyWholeAnswerRecord uid={this.props.uid}/>
                    </TabPane>
                    <TabPane tab="任务" key="3">
                            <MyWholeTaskRecord uid={this.props.uid}/>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}

export default connect(mapStateToProps,null)(LearningRecords);
