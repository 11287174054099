import React, { Component } from 'react';
import MyLayout from '../../common/MyLayout';
import api from '../../../api/api';
import { Table, Divider, Button, Form, message, Modal, Input } from 'antd';

class VideoTypeAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            alltypes: [],
            visible:false,
         };
    }

    refreshAllData()
    {
        api.series.getAllVideoTypes(
            null,
            (status,data)=>
            {
                if(status)
                {
                    this.setState(
                        {
                            alltypes:data.data
                        }
                        );
                }
            }
        )
    }

    componentDidMount()
    {
        this.refreshAllData();
    }

    getSubByTypeId(typeId)
    {
        let res  = [];
        for(let i = 0; i <this.state.alltypes.length; i++)
        {
            if(this.state.alltypes[i].pid == typeId)
            {
                res.push(this.state.alltypes[i]);
            }
        } 
        return res;
    }

    handleSumbit=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.series.addOrEditVideoType(
                    values,
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.refreshAllData();
                        }
                        else
                        {
                            message.error(data.msg);
                        }
                    }
                );
                this.setState({visible:false});
            }
        });
    };


    handleDelete=(record)=>{


        Modal.confirm({
            title: '确认删除',
            content: '真的要删除'+record.name,
            onOk:()=>{
                api.series.deleteTypeByTypeId(
                    {typeId:record.typeId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.refreshAllData();
                        }
                        else
                        {
                            message.error(data.msg);
                        }
                    } 
                )
            },
            okText: '确认',
            cancelText: '取消',
          });

    }

    render() {
        const columns = [
            {
                title: '类别名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        {
                            record.pid == null?
                            <>
                            <Button 
                            onClick={()=>{
                                let form = this.props.form;
                                form.setFieldsValue({
                                    typeId:null,
                                    name:null,
                                    pid:record.typeId
                                    });
                                this.setState({visible:true});
                            }}
                            >添加子类别</Button>
                            <Divider type='vertical'/>
                            </>
                            :
                            null
                        }
                        <Button 
                            onClick={()=>{
                                let form = this.props.form;
                                form.setFieldsValue({
                                    typeId:record.typeId,
                                    name:record.name,
                                    pid:record.pid
                                    });
                                this.setState({visible:true});
                            }}
                        >编辑</Button>
                        <Divider type='vertical'/>
                        <Button
                        onClick={
                            ()=>{
                                this.handleDelete(record);
                            }
                        }
                        >
                        删除
                        </Button>
                    </span>
                ),
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                key: 'createTime',
            },
        ];
        let parentData = this.getSubByTypeId(null);
        const { getFieldDecorator } = this.props.form;
        getFieldDecorator('pid',{});
        getFieldDecorator('typeId',{});
        return (
            <MyLayout title='视频类型管理'>
                <Button type='primary'
                        onClick={
                            ()=>{
                                let form = this.props.form;
                                form.setFieldsValue({
                                    typeId:null,
                                    name:null,
                                    pid:null
                                    });
                                this.setState({
                                visible:true
                            });
                            }
                        }
                
                >添加一级类别</Button>
                <Divider/>
                <Table
                    pagination={false}
                    dataSource={parentData}
                    expandedRowRender= {(record)=>{
                        return <Table
                                pagination={false}
                                dataSource={this.getSubByTypeId(record.typeId)} 
                                showHeader={false}
                                columns={columns}/>
                    }}
                    columns={columns}
                />
                <Modal
                title='添加编辑类别'
                visible={this.state.visible}
                onCancel={()=>{
                            this.setState({
                                visible:false
                            })
                        }}
                okText="确认"
                cancelText="取消"
                footer={null}
                >
                <Form onSubmit={this.handleSumbit}>
                    <Form.Item label='类别名'>
                    {
                            getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '类别名是必须输入的!'
                                    },
                                ],
                            })(
                            <Input size="large"
                                 placeholder="类别名"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit" size="large" block>
                            确定
                        </Button>
                    </Form.Item>
                </Form>

                </Modal>

            </MyLayout>
        );
    }
}

export default Form.create()(VideoTypeAdmin);

