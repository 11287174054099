
import { message, Button, Select, Radio, Icon, Input, Form, Result } from 'antd';
import React, { Component } from 'react';
import MyLayout from '../../common/MyLayout';
import DataValidate from '../../../config/DataValidate';
import api from '../../../api/api';
let {Option} = Select;

class NewSginUp extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isSuccess:false,
            values:{},
         };
    }

    handleSubmit=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({values});
                api.signup.addNewSignUp(values,
                    (state,data)=>{
                        if(state && data.status == 200)
                        {
                            this.setState({isSuccess:true})
                        }
                        else
                        {
                            message.error(data.msg);
                        }
                    }
                )

            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const layout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
        };

        const tailLayout = {
            wrapperCol: { offset: 6, span: 18 },
          };

        return (
            <MyLayout>
                {
                this.state.isSuccess?
                <Result
                    status="success"
                    title="恭喜您，您已报到成功!"
                    subTitle={"请保持您的手机"+this.state.values.phone+"开机状态，以便沟通。"}
                />
                :
                <Form  {...layout} onSubmit={this.handleSubmit} className="login-form">
                
                    <Form.Item  label='姓名' hasFeedback>
                        {getFieldDecorator('name', {
                            rules: [
                                {
                                    required: true,
                                    validateStatus:'error',
                                    message: '用户名必须输入!'
                                },
                            ],
                        })(
                            <Input
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="姓名"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label='性别'>
                            {
                                getFieldDecorator('sex', {
                                    initialValue:1,
                                    rules: [
                                        {
                                            required: true,
                                            message: '性别是必选的!'
                                        },
                                    ],
                                })(
                                <Radio.Group>
                                    <Radio value={1}>男</Radio>
                                    <Radio value={0}>女</Radio>          
                                </Radio.Group>)
                            }
                    </Form.Item>
                    <Form.Item  label='手机号'>
                    {
                            getFieldDecorator("phone", {
                            rules: [
                                {
                                    required: true,
                                    validateStatus:'error',
                                    message: '电话号码必须输入!'
                                },
                                {

                                    validator: (rule, value, callback) => {
                                        if(DataValidate.isMobile(value)) 
                                        {
                                            callback();
                                        }
                                        else
                                        {
                                            callback('error');
                                        }
                                    },
                                    message: '电话格式不对',
                                },
                            ],
                        })(
                            <Input
                                prefix={<Icon type="info" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="手机号"
                            />,

                        )}
                    </Form.Item>
                    <Form.Item label='身份证号'>
                                    {
                                    getFieldDecorator('idNumber', {
                                        validateFirst:true,
                                        rules: [
                                            {
                                                required: true,
                                                validateStatus:'error',
                                                message: '身份证号是必须输入的!'
                                            },
                                            {
                                                pattern:/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
                                                message: '身份证号不满足规则!'
                                            }
                                        ],
                                    })(
                                    <Input
                                        placeholder="例如:230103195607117012"
                                    />,
                                )}
                    </Form.Item>
                    <Form.Item label='报考专业'>
                            {
                                    getFieldDecorator('major', {
                                        validateFirst:true,
                                        rules: [
                                            {
                                                required: true,
                                                validateStatus:'error',
                                                message: '报考专业是必须输入的!'
                                            }
                                        ],
                                    })(
                                    <Input
                                        placeholder="报考专业"
                                    />,
                                )
                            }
                    </Form.Item>
                    <Form.Item label='考生类别'>
                                    {
                                        getFieldDecorator('category', {
                                            initialValue:"退伍军人",
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '类别是必选的!'
                                                },
                                            ],
                                        })(
                                        <Select>
                                            <Option value={'农村往届'}>农村往届</Option>
                                            <Option value={'农村应届'}>农村应届</Option>
                                            <Option value={'城市往届'}>城市往届</Option>
                                            <Option value={'城市应届'}>城市应届</Option>
                                            <Option value={'农民工'}>农民工</Option>
                                            <Option value={'退伍军人'}>退伍军人</Option>
                                            <Option value={'下岗失业人员'}>下岗失业人员</Option>
                                            <Option value={'新型职业农民'}>新型职业农民</Option>
                                            <Option value={'其它'}>其它</Option>
                                        </Select>)
                                    }
                    </Form.Item>

                    <Form.Item label='报考学校'>
                                    {
                                        getFieldDecorator('orgId', {
                                            initialValue:"cc00c344ed9d47f5a00d506d833bc097",
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '类别是必选的!'
                                                },
                                            ],
                                        })(
                                        <Select>
                                            <Option value={'cc00c344ed9d47f5a00d506d833bc097'}>大连汽车职业技术学院</Option>
                                        </Select>)
                                    }
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" size="large" block>
                            我要报到
                        </Button>
                    </Form.Item>
                </Form>
                }
            </MyLayout>
        );
    }
}

export default Form.create()(NewSginUp);


