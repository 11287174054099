import { Button, Modal } from 'antd';
import React, { Component } from 'react';
import TestPaperSearchCompnent from './TestPaperSearchCompnent';

class TestPaperSearchModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            visible:false,
         };
    }
    render() {
        return (
            <>
            {
                this.props.title?
                <div
                onClick={()=>{
                    this.setState({
                        visible: true,
                    })
                }}
                >
                {this.props.title}
                </div>:
                <Button
                onClick={()=>this.setState({visible:true})}
                type='primary'
                >试卷选择</Button>
            }


            <Modal
              visible={this.state.visible}
              width={800}
              footer={null}
              onCancel={()=>this.setState({visible:false})}
            >
            <TestPaperSearchCompnent
                {...this.props}
                onSelectItem={(record)=>{
                    this.setState({visible:false});
                    if(this.props.onSelectItem)
                    {
                        this.props.onSelectItem(record);
                    }
                }}
            />
            </Modal>
            </>
        );
    }
}

export default TestPaperSearchModal;
