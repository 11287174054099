import React, { Component } from 'react';
import {Row, Col, Input, List, Tag, Divider, Button, Tooltip, Form, Card, Pagination} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import Dict from "../../../config/Dict";
import ComFunction from '../../../api/ComFunction';
const InputGroup = Input.Group;
const {Meta} = Card;

class SearchExamPaper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keyword:null,

            data:[],
            pageNum:1,
            pageSize:5,
            totalNum:99,
        };

        this.initPageSize = 8;

        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        this.totalNum = this.initPageSize;
    }

    pageOnChange(pageNum,pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(()=>this.searchExamPaper(), 200);
    }

    searchExamPaper() {
        api.exam.searchExamPaper(
            {
                "condition": {
                    "keyword": this.state.keyword,
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    if (this.state.keyword){
                        for (let i = 0; i < data.data.length; i++) {
                            const cut =  data.data[i].description.split(this.state.keyword);
                            data.data[i].description = cut.join('<span style="color:red;">' + this.state.keyword + '</span>');
                        }
                    }
                    this.totalNum = data.totalNum;
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        setTimeout(()=>this.searchExamPaper(), 200,)
    }

    componentDidMount() {
        this.searchExamPaper();
    }

    render() {
        let history = this.props.history;
        return(
            <MyLayout>
                <Row>
                    <Col push={6} span={18} className="groupPosition">
                        <InputGroup compact>
                            <span style={{ width: '40%'}}>
                                <Input size="large"
                                       defaultValue={this.state.keyword}
                                       placeholder='请输入关键字'
                                       onChange={(e)=>this.setState({keyword:e.target.value})}
                                />
                            </span>
                            <div>
                                <Button type='primary'
                                        size="large"
                                        icon="search"
                                        onClick={()=>this.research()}>
                                    搜索</Button>
                            </div>
                        </InputGroup>
                    </Col>
                    <Divider/>
                    <List
                        grid={{gutter: 20, column: 4}}
                        itemLayout="vertical"
                        size="large"
                        dataSource={this.state.data}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                <Card hoverable
                                      cover={<img alt="example"
                                                  style={{height: "170px"}}
                                                  src={ComFunction.getScareImgeUrl(item.coverPath,280,170)}
                                      />}
                                      onClick={() =>
                                          history.push("/taskadmin/AnswerDetail"+'/'+item.paperId)
                                      }
                                >
                                    <Meta title={item.title}
                                          style={{height: "100px"}}
                                          description={
                                                  <div className="twoElements">
                                                      <Tooltip title={item.description}>
                                                          <div className="multipleTextOverflow" dangerouslySetInnerHTML = {{ __html: item.description}}>
                                                          </div>
                                                      </Tooltip>
                                                  </div>
                                                }/>
                                                <Row>
                                                <Col span={24}>
                                                         <div>
                                                                <span>考试开始时间:</span>
                                                                <span>{item.startTime?item.startTime:'不限定'}</span>
                                                          </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                        <div>
                                                                <span>考试结束时间:</span>
                                                                <span>{item.endTime?item.endTime:'不限定'}</span>
                                                          </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24}>
                                                    <div>
                                                                <span>考试持续时长:</span>
                                                                <span>{item.duration?item.duration+'分钟':'不限定'}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>
                                                    <div className="home-PriceValue">
                                                        <Tooltip title="试卷类型" >
                                                            <Tag style={{marginRight:"20px"}}
                                                                color={item.examType==0?"#87d068":"#2db7f5"}>
                                                                {Dict.getValue('examType',item.examType,'')}
                                                            </Tag>
                                                        </Tooltip>
                                                    </div>
                                                </Col>
                                                <Col span={12} style={{color: "#b7b7b7",marginTop:'10px'}}>
                                                <span>
                                                    <Tag color="#f50">
                                                        {item.itemNum + "(题)" + " / " + item.totalScore + "(分)"}
                                                    </Tag>
                                                </span>
                                                </Col>
                                            </Row>
                                </Card>
                            </List.Item>
                        )}
                    />
                </Row>
                <Pagination
                    onChange={(pageNum, pageSize) => this.pageOnChange(
                        pageNum, pageSize
                    )}
                    pageSize={this.pageSize}
                    current={this.pageNum}
                    total={this.totalNum}
                />
            </MyLayout>
        )
    }
}
export default Form.create()(SearchExamPaper);