import React, {Component} from 'react';
import {DatePicker} from 'antd';
import moment from 'moment';


class SimpleDateShowTime extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    disabledDate = (current) => {
        if (this.props.selectAllDays) {
            return false;
        }
        return current && current < moment().subtract(1, "days"); // 小于当前日期不能选
        //current > moment() // 大于当前日期不能选
        //moment().endOf('day');//小于等于当前日期不能选
        //time < moment().subtract(7, "days") || time > moment().add(7, 'd')// 只能选前7后7
    };

    onChange(date, dateString) {
        this.props.onChange(dateString);
    }

    render() {
        let dateFormat = "YYYY/MM/DD HH:mm:ss";
        let value = null;
        if (this.props.value) {
            value = moment(this.props.value, dateFormat)
        }

        let showTimeValue = {defaultValue: moment('00:00:00', 'HH:mm:ss')};
        if(this.props.showTime)
        {
            showTimeValue = this.props.showTime;
        }
        return (
            <DatePicker size="large"
                        placeholder={this.props.placeholder}
                        value={value}
                        format={dateFormat}
                        disabledDate={this.disabledDate}
                        showTime={showTimeValue}
                        onChange={(d, s) => this.onChange(d, s)}
            />
        );
    }
}

export default SimpleDateShowTime;