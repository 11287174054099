import React, { Component } from 'react';
import {Col, Row, Table, Pagination, Divider, Button, message, Modal} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";

const { confirm } = Modal;

class AdvertiseList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:[],
        };
    }


    allAdvertisements() {
        api.admin.allAdvertisements({},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    deleteAdByLinkId(linkId){
        confirm({
            title: '你确定要删除这个广告吗?',
            content: '删除的广告将不再显示在列表中。',
            onOk:()=>{
                api.admin.deleteAdByLinkId(
                    {linkId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.allAdvertisements();
                        }
                        else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    }

    handle=(link)=>{
        const w=window.open('about:blank');
        w.location.href = link;
    };

    componentDidMount() {
        this.allAdvertisements();
    }

    render() {
        let history = this.props.history;
        const columns = [
            {
                title: '广告名',
                key: 'name',
                render:  (text, record) => (
                    <a onClick={()=>
                        this.props.history.push('/admin/AddOrEditAdvertise'+'/'+record.linkId)}>
                        {record.name}
                    </a>
                )
            },
            {
                title: '链接',
                key: 'link',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow:'ellipsis',
                            cursor:'pointer'
                        }
                    }
                },
                render: (text, record) => (
                    <a onClick={()=>this.handle(record.link)}>
                        {record.link}
                    </a>
                ),
            },
            {
                title: '开始时间',
                dataIndex: 'fromDate',
                key: 'fromDate',
            },
            {
                title: '结束时间',
                dataIndex: 'endDate',
                key: 'endDate',
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <a onClick={()=>
                            this.props.history.push('/admin/AddOrEditAdvertise'+'/'+record.linkId)}>编辑
                        </a>
                        <Divider type="vertical" />
                        <a onClick={()=>this.deleteAdByLinkId(record.linkId)}>删除</a>
                    </span>
                ),
            },
        ];
        return(
            <MyLayout>
                <p className="userEditTitle">广告文件列表</p>
                <Divider/>
                <Row gutter={5}>
                    <Col span={6}>
                        <Button type="primary"
                                onClick={()=>history.push('/admin/AddOrEditAdvertise'+'/'+'addAdvertise')}
                                style={{ marginBottom: 16 }}>
                            添加广告
                        </Button>
                    </Col>
                </Row>
                <Divider dashed/>
                <Table
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
            </MyLayout>
        )
    }
}

export default AdvertiseList;