import React, { Component } from 'react';
import {message, Form, Icon, Input, Button, Select} from 'antd';
import {connect } from 'react-redux';
import api from '../../../../api/api';
import ImageUpload from '../../../../components/ImageUpload';
import MyLayout from "../../../common/MyLayout";
import { dict } from '../../../../config/Dict';
import DataSelect from '../../../../components/DataSelect';
import Action from '../../../../actions/Action';

const { TextArea } = Input;


class AddApplyOrg extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orgData:null,
        };
    }

    addApplyOrg(values){
        let orgId = this.achieveParams();
        if (orgId == ""){
            // 新建
            api.org.applyOrg(
                {orgId, ...values},
                (state,data)=>{
                    if(state && data.status == 200) {
                        this.props.history.push('/company/MyOrgList');
                    } else {
                        message.error(data.msg);
                    }
                }
            )
        } else{
            // 编辑
            api.org.editOrg(
                {orgId, ...values},
                (state,data)=>{
                    if(state && data.status == 200) {
                        this.props.history.push('/company/MyOrgList');
                    } else {
                        message.error(data.msg);
                    }
                }
            )
        }

    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.addApplyOrg(values);
            }
        });
    };

    orgDetail(orgId) {
        api.org.orgDetail({orgId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.props.form.resetFields();
                    this.setState({
                        orgData:data.data,
                    })
                }
            }
        )
    }

    achieveParams(){
        let {orgId} = this.props.match.params;
        if (orgId && orgId =="addOrg"){
            orgId = "";
        }
        return orgId;
    }

    componentDidMount() {
        if (this.achieveParams() && this.achieveParams() !="" ){
            this.orgDetail(this.achieveParams());
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            orgData= null
        } = this.state;

        const formItemLayout = {
            labelCol: {
                sm: { span: 8 },
            },
            wrapperCol: {
                sm: { span: 10},
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                sm: {
                    span: 10,
                    offset: 8,
                },
            },
        };
        return(
            <MyLayout>
                <div style={{marginTop:'60px'}}>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit} >
                        <Form.Item label='公司名' hasFeedback>
                            {getFieldDecorator('orgName', {
                                initialValue:orgData?orgData.orgName:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '公司名是必须输入的!'
                                    },
                                ],
                            })(
                                <Input size="large"
                                       prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                       placeholder="公司名"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='公司性质'>
                            {getFieldDecorator('nature', {
                                initialValue:orgData?orgData.nature:null,
                                rules: [
                                    {
                                        required: true,
                                        message: '公司所属行业是必须输入的!'
                                    },
                                ],
                            })(
                                <DataSelect
                                    option={dict.nature}
                                />)
                            }
                        </Form.Item>
                        <Form.Item label='所属行业'>
                            {getFieldDecorator('companyClass', {
                                initialValue:orgData?orgData.companyClass:null,
                                rules: [
                                    {
                                        required: true,
                                        message: '公司所属行业是必须输入的!'
                                    },
                                ],
                            })(
                                <DataSelect
                                    option={dict.companyClass}
                                />)
                            }
                        </Form.Item>
                        <Form.Item label='公司规模'>
                            {getFieldDecorator('personNum', {
                                initialValue:orgData?orgData.personNum:null,
                                rules: [
                                    {
                                        required: true,
                                        message: '公司规模是必须输入的!'
                                    },
                                ],
                            })(
                                <DataSelect
                                    option={dict.personNum}
                                />)}
                        </Form.Item>
                        <Form.Item label='法人名称'>
                            {getFieldDecorator('legalPersonName', {
                                initialValue:orgData?orgData.legalPersonName:'',
                                rules: [
                                    {
                                        required: true,
                                        message: '法人名称是必须输入的!'
                                    },
                                ],
                            })(
                                <Input size="large"
                                       placeholder="法人名称"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='联系人'>
                            {getFieldDecorator('contactsNames', {
                                initialValue:orgData?orgData.contactsNames:'',
                                rules: [
                                    {
                                        required: true,
                                        message: '公司联系人是必须输入的!'
                                    },
                                ],
                            })(
                                <Input size="large"
                                       placeholder="公司联系人"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='联系地址'>
                            {getFieldDecorator('detailAddress', {
                                initialValue:orgData?orgData.detailAddress:'',
                                rules: [
                                    {
                                        required: true,
                                        message: '公司地址是必须输入的!'
                                    },
                                ],
                            })(
                                <Input size="large"
                                       placeholder="公司地址"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='联系电话'>
                            {getFieldDecorator('contactsPhones', {
                                initialValue:orgData?orgData.contactsPhones:'',
                                rules: [
                                    {
                                        required: true,
                                        message: '联系电话是必须输入的!'
                                    },
                                ],
                            })(
                                <Input size="large"
                                       placeholder="联系电话"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='联系邮箱'>
                            {getFieldDecorator('contactsEmails', {
                                initialValue:orgData?orgData.contactsEmails:'',
                                rules: [
                                    {
                                        required: true,
                                        message: '联系邮箱是必须输入的!'
                                    },
                                ],
                            })(
                                <Input size="large"
                                       placeholder="联系邮箱"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='公司logo'>
                            {getFieldDecorator('logoFileId', {
                                initialValue:orgData?orgData.logoFileId:'',
                                rules: [
                                    {
                                        required: false,
                                        message: '公司logo必须上传!'
                                    },
                                ],
                            })(
                                <ImageUpload/>
                            )}
                        </Form.Item>
                        <Form.Item label='公司执照'>
                            {getFieldDecorator('certificatesFileIds', {
                                initialValue:orgData?orgData.certificatesFileIds:'',
                                rules: [
                                    {
                                        required: false,
                                        message: '公司执照必须上传!'
                                    },
                                ],
                            })(
                                <ImageUpload/>
                            )}
                        </Form.Item>
                        <Form.Item label='公司介绍' >
                            {getFieldDecorator('introduce', {
                                initialValue:orgData?orgData.introduce:'',
                                rules: [
                                    {
                                        required: true,
                                        message: '公司介绍是必须输入的!'
                                    },
                                ],
                            })(
                                <TextArea rows={4}
                                          placeholder="公司介绍"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit" size="large" block>
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </MyLayout>
        )
    }
}
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        saveUserInfoToStore: (data) => dispatch(
            {
                type: Action.SAVE_USER_INFO,
                data: data,
            }),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Form.create()(AddApplyOrg));
//export default Form.create()(AddApplyOrg);