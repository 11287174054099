import React, { Component } from 'react';
import {Modal, Input, List, Pagination, Button} from 'antd';
import api from "../api/api";


/************* 领域Select ****************/
class FieldSelectModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldData:[],
            fid:"",
            name:"",
            visible: false,

            pageNum:1,
            pageSize:8,
            totalNum:9,
        }
    }

    getFieldList(){ //获取领域
        api.anon.fieldList({
                "condition": "",
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        fieldData:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.getFieldList(), 200);
    }

    listClick(item) {
        this.setState({
            name:item.name,
            visible: false,
        });
        this.props.onChange(item.fid)
    }

    componentDidMount() {
        this.getFieldList();
    }

    render() {
        if (this.props.value && this.props.value.fid && this.props.value.name){
            this.props.onChange(this.props.value.fid);
            this.setState({
                name: this.props.value.name,
            })
        }
        return (
            <div>
                <Input 
                       placeholder="所属领域"
                       value={this.state.name}
                       onClick={()=>{
                           this.setState({
                               visible: true,
                           })}
                       }/>
                <Modal
                    title="所属领域"
                    visible={this.state.visible}
                    onCancel={()=>{
                        this.setState({
                            visible:false,
                        });
                    }}
                    footer={null}
                >
                    <List
                        dataSource={this.state.fieldData}
                        renderItem={item =>
                            <List.Item onClick={()=>this.listClick(item)}>
                                <a>{item.name}</a>
                            </List.Item>}
                    />
                    <div style={{paddingTop:"25px"}}>
                        <Pagination
                            onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.state.pageSize}
                            current={this.state.pageNum}
                            total={this.state.totalNum}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default FieldSelectModal;