import React from 'react'
import {Form, Input, Button, Radio, message} from 'antd'
import api from '../../../api/api'
import MyLayout from '../../common/MyLayout'
import ImageUpload from '../../../components/ImageUpload'
import DraftInput from '../../taskadmin/testPaper/DraftInput'
import NewsClassification from './NewsClassification'

const {TextArea} = Input

class AddOrEditNews extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            newsData: {},
        }
    }

    getNewsId = () => {
        let {newsId} = this.props.match.params
        if (newsId === 'addNews') {
            newsId = ''
        }
        return newsId
    }
    addOrEditNews = values => {
        values.newsId = this.getNewsId()
        api.news.addOrEditNews(values, (state, data) => {
            if (state && data.status === 200) {
                this.props.history.push('/news/newsManagement')
            } else {
                message.error(data.msg)
            }
        })
    }
    getNewsDetail = newsId => {
        api.news.getNewsDetail({newsId}, (state, data) => {
            if (state && data.status === 200) {
                this.setState({
                    newsData: data.data,
                })
            }
        })
    }
    handleSubmit = e => {
        e.preventDefault()
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.addOrEditNews(values)
            }
        })
    }

    componentDidMount() {
        const newsId = this.getNewsId()
        if (newsId && newsId !== 'addNews') {
            this.getNewsDetail(newsId)
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form
        const {newsData} = this.state
        return (
            <MyLayout>
                <Form
                    labelCol={{span: 5}}
                    wrapperCol={{span: 12}}
                    onSubmit={this.handleSubmit}
                >
                    <Form.Item label="新闻作者">
                        {getFieldDecorator('newsAuthor', {
                            initialValue: newsData ? newsData.newsAuthor : '',
                            rules: [{required: false}],
                        })(<Input/>)}
                    </Form.Item>
                    <Form.Item label="新闻标题">
                        {getFieldDecorator('newsTitle', {
                            initialValue: newsData ? newsData.newsTitle : '',
                            rules: [
                                {required: true, message: '请输入新闻标题'},
                            ],
                        })(<Input/>)}
                    </Form.Item>
                    <Form.Item label="新闻描述">
                        {getFieldDecorator('newsDesc', {
                            initialValue: newsData ? newsData.newsDesc : '',
                            rules: [
                                {required: true, message: '请输入新闻描述'},
                            ],
                        })(<TextArea rows={6}/>)}
                    </Form.Item>
                    <Form.Item label="新闻来源">
                        {getFieldDecorator('newsRefrence', {
                            initialValue: newsData ? newsData.newsRefrence : '',
                            rules: [{required: false}],
                        })(<Input/>)}
                    </Form.Item>
                    <Form.Item label="新闻封面">
                        {getFieldDecorator('newsCoverpath', {
                            initialValue: newsData
                                ? newsData.newsCoverpath
                                : '',
                            rules: [{required: false}],
                        })(<ImageUpload/>)}
                    </Form.Item>
                    <Form.Item label="新闻置顶">
                        {getFieldDecorator('newsIstop', {
                            initialValue: newsData ? newsData.newsIstop : '',
                            rules: [{required: false}],
                        })(
                            <Radio.Group>
                                <Radio value={1}>是</Radio>
                                <Radio value={0}>否</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    <Form.Item label="新闻状态">
                        {getFieldDecorator('newsStatus', {
                            initialValue: newsData ? newsData.newsStatus : '',
                            rules: [{required: false}],
                        })(
                            <Radio.Group>
                                <Radio value={1}>发布</Radio>
                                <Radio value={0}>编辑</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    <Form.Item label="新闻分类">
                        {getFieldDecorator('newsClassification', {
                            initialValue: newsData
                                ? newsData.newsClassification
                                : '',
                            rules: [{required: true, message: '请选择分类'}],
                        })(<NewsClassification/>)}
                    </Form.Item>
                    <Form.Item label="新闻内容">
                        {getFieldDecorator('newsContent', {
                            initialValue: newsData ? newsData.newsContent : '',
                            rules: [{required: true}],
                        })(<DraftInput/>)}
                    </Form.Item>
                    <Form.Item wrapperCol={{span: 12, offset: 5}}>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </MyLayout>
        )
    }
}

export default Form.create()(AddOrEditNews)
