import React, { Component } from 'react';
import { Col, Tag,Tabs, List, Card, Tooltip, Button, Row} from 'antd';
import MyLayout from "../../common/MyLayout";
import api from "../../../api/api";
import GetVideoList from "../tasksManage/GetVideoList";
import GetJoinStudent from "../tasksManage/GetJoinStudent";
import PaperTaskSelect from './PaperTaskSelect';
import { setLoadingState } from '../../../reducers/UserInfo';

const { TabPane } = Tabs;

class TaskDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:null,
            updateLoading:false,
        };
    }

    getTaskDetailById() {
        let mtaskId = this.props.match.params.mtaskId;
        setLoadingState(true);
        api.taskadmin.getTaskDetailById({mtaskId},
            (state,data)=>{
                setLoadingState(false);
                if(state && data.status == 200) {
                    this.setState({
                        data:data.data,
                    });
                }
            }
        )
    }

    componentDidMount() {
        this.getTaskDetailById();
    }

    render() {
        const {
            data= null
        } = this.state;
        let mtaskId = this.props.match.params.mtaskId;
        return(
            <MyLayout >


                
                <div>
                    <div className="heading_title">{data!= null?data.taskName:""}</div>
                    <Row>
                    <Col span={22}>
                    <div className="heading_content">
                        <div className="heading_mes">
                            <p className="mes_title"><span>任务类型：</span>
                                {data!= null?
                                    data.taskType == 0?
                                    <Tag color="orange">视频</Tag>
                                    :<Tag color="cyan">试题</Tag>
                                :""}
                            </p>
                            <p className="mes_title"><span>起止时间：</span>
                                {data!= null?data.startTime:""}-{data!= null?data.endTime:""}
                            </p>
                            <p className="mes_title"><span>任务描述：</span>
                                {data!= null?data.taskDescription:""}
                            </p>
                        </div>
                    </div>
                    </Col>
                    <Col span={2} >
                            <Button
                            style={{marginTop:"50px"}}
                            loading={this.state.updateLoading}
                            onClick={()=>{
                                this.setState({updateLoading:true});
                                api.taskadmin.updateTaskPercentByTaskId(
                                    {mtaskId:mtaskId},
                                    (state,data)=>{
                                        this.setState({updateLoading:false});
                                    }
                                )
                            }}
                            type='primary'
                            >进度更新</Button>
                     </Col>
                    </Row>
                </div>

                <div>
                    <Tabs defaultActiveKey="1" style={{padding:"20px"}}>
                        <TabPane tab="任务项目" key="1">
                            {
                              data && data.taskType == 0 ?
                              <GetVideoList mtaskId={mtaskId}/>:null
                            }
                            {
                                data && data.taskType == 1?
                               <PaperTaskSelect mtaskId={mtaskId}/>:null
                            }
                        </TabPane>
                        <TabPane tab="本任务学生" key="2">
                            <GetJoinStudent mtaskId={mtaskId}/>
                        </TabPane>
                    </Tabs>
                </div>
            </MyLayout>
        )
    }
}
export default TaskDetail;