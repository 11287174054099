import React, { Component } from 'react';
import {Modal, Avatar, Input, List, Pagination, Tooltip, Tag} from 'antd';
import api from "../api/api";

/************* 教师选择Popover ****************/
class TeacherSelectModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teacherData:[],
            name:"",
            visible: false,

            pageNum:1,
            pageSize:8,
            totalNum:9,
        }
    }

    teacherList(){ //获取讲师
        api.teacher.teacherList(
            {
                "condition": {
                    "name": ""
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status===200) {
                    this.setState({
                        teacherData:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.teacherList(), 200);
    }

    listClick(name,tid) {
        this.setState({
            name:name,
            visible: false,
        });
        this.props.onChange(tid)
    }

    componentDidMount() {
        this.teacherList();
    }

    render() {
        if (this.props.value && this.props.value.tid && this.props.value.name){
            this.props.onChange(this.props.value.tid);
            this.setState({
                name: this.props.value.name,
            })
        }
        return (
            <div>
                <Input size="large"
                       placeholder="讲师选择"
                       value={this.state.name}
                       onClick={()=>{
                           this.setState({
                               visible: true,
                           })}
                       }/>
                <Modal
                    title="讲师选择"
                    visible={this.state.visible}
                    onCancel={()=>{
                        this.setState({
                            visible:false
                        })
                    }}
                    footer={null}
                >
                    <List
                        size="large"
                        dataSource={this.state.teacherData}
                        renderItem={item =>
                            <List.Item onClick={()=>this.listClick(item.name,item.tid)}>
                                <List.Item.Meta
                                    avatar={<Avatar src={item.photoPath} />}
                                    title={<a>{item.name}</a>}
                                    description={
                                        <p>
                                            <Tooltip title="所属组织" >
                                                <Tag color="cyan">{item.org.orgName}</Tag>
                                            </Tooltip>
                                            <Tooltip title=" 所属领域">
                                                <Tag color="purple">{item.field.name}</Tag>
                                            </Tooltip>
                                        </p>
                                    }
                                />
                            </List.Item>}
                    />
                    <div style={{paddingTop:"25px"}}>
                        <Pagination
                            onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.state.pageSize}
                            current={this.state.pageNum}
                            total={this.state.totalNum}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default TeacherSelectModal;