import React, { Component } from 'react';
import {Row, Col, Tabs, List, Card, Tooltip} from 'antd';
import MyLayout from "../../common/MyLayout";
import Dict from '../../../config/Dict';
import api from "../../../api/api";
import CON from '../../../config/const';
import Sfs from '../../../config/Sfs';

const { TabPane } = Tabs;
const { Meta } = Card;

class OrgDetailsPersonal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgData:null,
            teacherData:null,
            lectureListData:null
        };
    }

    updateOrgDetail() {
        let orgId = this.props.match.params.orgId;
        api.org.orgDetail({orgId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        orgData:data.data,
                    });
                    this.teacherList(data.data.orgId);
                    this.lectureList(data.data.orgId);
                }
            }
        )
    }

    teacherList(orgId){
        api.teacher.teacherList(
            {
                "condition": {
                    "orgIds":[orgId],
                },
                "pageNum": 1,
                "pageSize": 8,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        teacherData:data.data,
                    })
                }
            }
        )
    }

    lectureList(orgId){
        api.lecture.lectureList(
            {
                "condition": {
                    "beforeEnd": true,
                    "orgIds": [orgId],
                },
                "pageNum": 1,
                "pageSize": 8,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        lectureListData:data.data,
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.updateOrgDetail();
    }

    render() {
        const {
            orgData= null
        } = this.state;
        let history = this.props.history;
        return(
            <MyLayout >
                <div className="heading">
                    <div className="heading_title">{orgData!= null?orgData.orgName:""}</div>
                    <div className="heading_content">
                        <div className="heading_img">
                            {orgData!= null && orgData.logoFileId!=null ? <img
                                width={220}
                                src={orgData.logoFileId}
                            />:"未上传"}</div>
                        <div className="heading_mes">
                            <p className="mes_title"><span>公司名称：</span>
                                {orgData!= null?orgData.orgName:""}
                            </p>
                            <p className="mes_title"><span>所属行业：</span>
                                {Dict.getValue('companyClass',orgData!= null?orgData.companyClass:"",'')}
                            </p>
                            <p className="mes_title"><span>公司性质：</span>
                                {Dict.getValue('nature',orgData!= null?orgData.nature:"",'')}
                            </p>
                            <p className="mes_title"><span>公司规模：</span>
                                {Dict.getValue('personNum',orgData!= null?orgData.personNum:"",'')}
                            </p>
                            <p className="mes_title"><span>公司地址：</span>
                                {orgData!= null?orgData.detailAddress:""}
                            </p>
                            <p className="mes_title"><span>联&nbsp;&nbsp;系&nbsp;&nbsp;人：</span>
                                {orgData!= null?orgData.contactsNames:""}
                            </p>
                            <p className="mes_title"><span>联系电话：</span>
                                {orgData!= null?orgData.contactsPhones:""}
                            </p>
                            <p className="mes_title"><span>联系邮箱：</span>
                                {orgData!= null?orgData.contactsEmails:""}
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <Tabs defaultActiveKey="1" style={{padding:"20px"}}>
                        <TabPane tab="公司介绍" key="1">
                            {orgData!= null?orgData.introduce:""}
                        </TabPane>
                        <TabPane tab="优秀师资" key="2">
                            <div style={{textAlign:"right"}}>
                                <a onClick={()=>
                                    history.push('/famousTeacherList')
                                }>查看更多师资</a>
                            </div>
                            <List
                                grid={{ gutter: 10, column: 4 }}
                                itemLayout="vertical"
                                size="large"
                                dataSource={this.state.teacherData}
                                renderItem={(item,index) => (
                                    <List.Item
                                        key={index}
                                    >
                                        <Card hoverable
                                            cover={
                                                <img alt="讲师照"
                                                     className="homeImg"
                                                     src={item.photoPath}
                                                />
                                            }
                                            onClick={()=>
                                                history.push('/Personal/FamousTeacherDetail'+'/'+item.tid)
                                            }
                                        >
                                                <Meta title={item.name}
                                                      style={{height:"100px"}} description={
                                                    <div className="multipleTextOverflow">
                                                        <Tooltip title= {item.motto}>
                                                            {item.motto}
                                                        </Tooltip>
                                                    </div>
                                                }/>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </TabPane>
                        {/*
                        <TabPane tab="相关视频" key="3">

                        </TabPane>
                        */}

                        {!CON.IS_FREE && Sfs.isLectureOn ?
                        <TabPane tab="相关讲座" key="4">
                            <div style={{textAlign:"right"}}>
                                <a onClick={()=>
                                    history.push('/Personal/moreLectureList')
                                }>查看更多讲座</a>
                            </div>
                            <List
                                grid={{ gutter: 20, column: 4 }}
                                itemLayout="vertical"
                                size="large"
                                dataSource={this.state.lectureListData}
                                renderItem={(item,index) => (
                                    <List.Item
                                        key={index}
                                    >
                                        <Card hoverable
                                              cover={<img alt="example" style={{height:"170px"}} src={item.posterPath} />}
                                              onClick={()=>
                                                  history.push('/Personal/LectureSceneDetail'+'/'+item.lid)
                                              }
                                              actions={[
                                                  <div>选座购票</div>
                                              ]}
                                        >
                                            <Meta title={item.name} style={{height:"110px"}} description={
                                                <div className="multipleTextOverflow">
                                                    <Tooltip title= {item.intro}>
                                                        {item.intro}
                                                    </Tooltip>
                                                </div>
                                            } />
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </TabPane>
                        :null}
                    </Tabs>
                </div>
            </MyLayout>
        )
    }
}
export default OrgDetailsPersonal;