import React, { Component } from 'react';
import {Modal, Button} from 'antd';

class PraviteProtocalModal extends Component {
    state = { visible: false };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        return (
            <span>
                <a type="primary" onClick={this.showModal}>
                    隐私权条款
                </a>
                <Modal
                    title="隐私权条款"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >

                        <h3>“德师平台”隐私政策</h3>

                        本政策仅适用兑泽科技德师平台（以下简称“德师平台”或“我们”）的产品或服务。<br />

                        您有权选择关闭网页或退出浏览器（app）以拒绝接受本次隐私政策的更新，不再使用我们的产品和服务。<br />

                        兑泽科技德师平台（以下简称“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。<br />

                        请在使用我们的产品（或服务）前，仔细阅读并了解本《隐私权政策》，以便做出适当的选择。<br />

                        <strong>一、我们如何收集和使用您的个人信息</strong><br />

                        个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。<br />

                        我们仅会出于本政策所述的以下目的，收集和使用您的个人信息：<br />

                        （一）为您提供网上购课和在线学习服务<br />

                        1、业务功能一：注册成为用户<br />

                        为完成创建账号，您需提供以下真实信息：您的姓名、手机号、电子邮箱地址、创建的用户名和密码。<br />

                        你也可以使用第三方账号（如QQ、微信、微博登）登录进入德师平台，你此时将授权我们获取你在第三方平台注册的公开信息（头像、昵称等），并在你同意本隐私政权政策后将你的第三方账号与你的德师平台账号绑定，使你可以通过第三方账号直接登录并使用本产品和相关服务。<br />

                        在注册过程中，如果您提供以下额外信息，将有助于我们给您提供更好的服务和体验：头像、职位、身份证件、学生证件、收货地址。但如果您不提供这些信息，将不会影响使用本服务的基本功能。<br />

                        您提供的上述信息，将在您使用本服务期间持续授权我们使用。在您申请注销账号时，我们将停止使用并删除上述信息。<br />

                        上述信息将存储于中华人民共和国境内。如需跨境传输，我们将会单独征得您的授权同意。<br />

                        <strong>2、业务功能二：账号实名认证</strong><br />

                        根据《网络安全法》第二十四条第一款之规定，在您开始使用我们的产品（或服务）时，需要提供您的真实身份信息，包括您的姓名、身份证、手机号，以及因无法进行自动识别需要人工审核时，可能需要您提供手持身份证照片以确认您的真实身份。为了确保您注册或使用账号的拥有及使用权是您本人，我们会将您提供的这些信息与您注册或使用的账号进行绑定，以确保您对本账号的拥有及使用权。请您注意为了保障账号始终与您的身份相匹配，一旦您的实名信息认证通过并与您的账号绑定后，账号绑定的实名信息将不能再更改，故而请您在决定提供您的真实身份信息并绑定到您即将注册或使用的账号时进行最终确认。<br />

                        3、业务功能二：课程内容展示、个性化推荐、发送促销营销信息。<br />

                        （一）德师平台会基于你内容订阅和算法等决策机制推荐和推送你可能感兴趣的内容。<br />

                        （二）开展内部数据分析和研究，第三方SDK统计服务，改善我们的产品或服务<br />

                        我们收集数据是根据您与我们的互动和您所做出的选择，包括您的隐私设置以及您使用的产品和功能。我们收集的数据可能包括SDK/API/JS代码版本、浏览器、互联网服务提供商、IP地址、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、独立设备标识符、iOS广告标识符（IDFA)、安卓广告主标识符、网卡（MAC）地址、国际移动设备识别码（IMEI）、设备型号、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、时区和网络状态（WiFi等）、硬盘、CPU和电池使用情况等。<br />

                        （三）手机号码数据为个人敏感信息，你有权拒绝提供，这将使你无法学习课程和其他教学相关服务。<br />

                        当我们要将信息用于本策略未载明的其它用途时，会事先征求您的同意。<br />

                        当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。<br />

                        <strong>二、我们如何使用 Cookie 和同类技术</strong><br />

                        （一）Cookie<br />

                        为确保网站正常运转，我们可能会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，网站能够存储您的偏好或购物篮内的商品等数据。<br />

                        我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。<br />

                        （二）网站信标和像素标签<br />

                        除 Cookie 外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击 URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。<br />

                        （三）Do Not Track（请勿追踪）<br />

                        很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not Track 请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了 Do Not Track，那么我们的所有网站都会尊重您的选择。<br />



                        <strong>三、我们如何共享、转让、公开披露您的个人信息</strong><br />

                        （一）共享<br />

                        我们不会向其他任何公司、组织和个人分享您的个人信息，但以下情况除外：<br />

                        1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。<br />

                        2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。<br />

                        3、与我们的关联公司共享：您的个人信息可能会与我们关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。我们的关联公司包括:北京奥鹏远程教育中心有限公司；北京奥鹏文化传媒有限公司<br />

                        4、与授权合作伙伴共享：仅为实现本隐私权政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，我们聘请来提供第三方数据统计和分析服务的公司可能需要采集和访问个人数据以进行数据统计和分析。在这种情况下，这些公司必须遵守我们的数据隐私和安全要求。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。<br />

                        对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。<br />

                        （二）转让<br />

                        我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：<br />

                        1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；<br />

                        2、在涉及合并、收购、资产转让或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。<br />

                        （三）公开披露<br />

                        我们仅会在以下情况下，公开披露您的个人信息：<br />

                        1.获得您明确同意后；<br />

                        2.对违规账号、欺诈行为进行处罚公告时，我们会披露相关账号的信息；<br />

                        3.基于法律的披露:在法律、法律程序、诉讼或政府主管部门强制性要求的情况下,在下列情形中，我们可能会公开披露您的个人信息而无需征得您的授权同意：<br />

                        a.与国家安全、国防安全直接相关的；<br />

                        b.与公共安全、公共卫生、重大公共利益直接相关的；<br />

                        c.与犯罪侦查、起诉、审判和判决执行等直接相关的；<br />

                        d.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br />

                        e.您自行向社会公众公开的个人信息；<br />

                        f.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；<br />

                        g. 法律法规规定的其他情形。<br />

                        <strong>四、我们如何保护您的个人信息</strong><br />

                        （一）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据（如信用卡信息）时受 SSL 加密保护；我们同时对我们网站提供 https 安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。<br />

                        （二）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。<br />

                        （三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他我们用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。<br />

                        （四）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。尽管已经采取了合理有效的措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。<br />

                        （五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。<br />

                        同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。<br />



                        <strong>五、您的权利及责任</strong><br />

                        按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：<br />

                        （一）访问您的个人信息<br />

                        您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：<br />

                        账户信息——如果您希望访问或编辑您的账户中的个人资料信息和支付信息、更改您的密码、添加安全信息或关闭您的账户等，您可以通过访问德师平台执行此类操作。<br />

                        搜索信息——您可以在德师平台中访问或清除您的搜索历史记录、查看和修改兴趣以及管理其他数据。<br />

                        （二）更正您的个人信息<br />

                        当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过“（一）访问您的个人信息”中罗列的方式提出更正申请。<br />

                        （三）删除您的个人信息<br />

                        在以下情形中，您可以发送邮件向我们提出删除个人信息的请求：<br />

                        1、如果我们处理个人信息的行为违反法律法规；<br />

                        2、如果我们收集、使用您的个人信息，却未征得您的同意；<br />

                        3、如果我们处理个人信息的行为违反了与您的约定；<br />

                        4、如果您不再使用我们的产品或服务，或您注销了账号；<br />

                        5、如果我们不再为您提供产品或服务。<br />

                        若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。<br />

                        当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。<br />

                        （四）改变您授权同意的范围<br />

                        每个业务功能需要一些基本的个人信息才能得以完成（见本策略“第一部分”）。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。<br />


                        （五）约束信息系统自动决策<br />

                        在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。<br />


                         在使用本产品（或服务）的过程中，您有责任保管好您的个人信息：<br />

                        （一）不将您的个人信息，如您的个人账号及登录密码主动透露给与本产品（或服务）无关的人员或机构。<br />

                        （二） 不将您的权责内获取的他人个人信息用于超出服务合同约定以外的用途；在未经个人信息所有者同意的前提下不得复制、转发、传递给其他人或机构。<br />

                        （三）因您违反了您的责任，造成了您或他人的损失及相应的法律法规及合同风险，您将自行承担相应责任，我们有权不再提供上述“您的权力”的支持。<br />



                        <strong>六、我们如何处理儿童的个人信息</strong><br />

                        我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不得创建自己的用户账户。<br />

                        对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。<br />

                        尽管当地法律和习俗对儿童的定义不同，但我们将不满 14 周岁的任何人均视为儿童。<br />

                        如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。<br />



                        <strong>七、您的个人信息如何在全球范围转移</strong><br />

                        我们收集的您的信息都在中华人民共和国境内收集和产生的个人信息,都存储在中华人民共和国境内，没有在全球范围转移。<br />

                        <strong>八、本政策如何更新</strong><br />

                        我们可能适时会对本隐私权政策进行调整或变更，本隐私权政策的任何更新将以标注更新时间的方式公布在我们网站上，除法律法规或监管规定另有强制性规定外，经调整或变更的内容一经通知或公布后的7日后生效。如您在隐私权政策调整或变更后继续使用我们提供的任一服务或访问我们相关网站的，我们相信这代表您已充分阅读、理解并接受修改后的隐私权政策并受其约束。<br />
                </Modal>
            </span>
        );
    }
}
export default PraviteProtocalModal;