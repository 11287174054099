import React, { Component } from 'react';
import {Col, Row, Form, Table, Divider, Modal, PageHeader, Tag, message, Switch} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import AddNewClassStaff from "../class/addNewClassStaff";

const { confirm } = Modal;

class getClassStaffs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            staffsData:[],
            classData:[]
        };
    }

    getClassDetail() {
        api.class.getClassDetail({
                "classId": this.props.match.params.classId
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        classData:data.data,
                    })
                }
            }
        )
    }

    getClassStaffs() {
        api.class.getClassStaffs({
                "classId": this.props.match.params.classId
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        staffsData:data.data,
                    })
                }
            }
        )
    }

    editClassStaff(headmaster,uid){
        let title = "";
        let content = "";
        if (headmaster == 0){
            title = "你确定要取消[班主任]的资格吗?";
            content = "[班主任]置成普通老师后不可批学生的操作。"
        }else{
            title = "你确定要把这个老师设置成[班主任]吗?";
            content = "用户设置成[班主任]后该用可审批学生的操作。"
        }
        confirm({
            title: title,
            content:content,
            onOk:()=>{
                api.class.editClassStaff({
                        "classId": this.props.match.params.classId,
                        "headmaster": headmaster,
                        "uid": uid
                    },
                    (state,data)=>{
                        if(state && data.status == 200) {
                            setTimeout(()=>this.getClassStaffs(), 200,);
                            message.success('设置成功!');
                        }
                    }
                )
            },
            onCancel() {
                
            },
        });
    }

    onChange(ar){
        if (ar){
            this.getClassStaffs();
        }
    }

    removeClassStaff(uid){
        confirm({
            title: "删除",
            content:"你确定要删除这个员工吗?",
            onOk:()=>{
                api.class.removeClassStaff({
                        "classId": this.props.match.params.classId,
                        "uid": uid
                    },
                    (state,data)=>{
                        if (state) {
                            if(data.status == 200) {
                                setTimeout(()=>this.getClassStaffs(), 200,);
                                message.success('删除成功!');
                            }else{
                                message.error(data.msg);
                            }
                        }
                    }
                )
            }
        });
    }

    componentDidMount() {
        this.getClassDetail();
        this.getClassStaffs();
    }

    render() {
        let history = this.props.history;
        const {classData} = this.state;
        const columns = [
            {
                title: '任课老师名',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '任课老师ID',
                dataIndex: 'uid',
                key: 'uid',
            },
            {
                title: '班级编号',
                dataIndex: 'classId',
                key: 'classId',
            },
            {
                title: '是否是班主任',
                key: 'headmaster',
                dataIndex: 'headmaster',
                render: (text, record) => (
                    <span>
                        {
                            <Switch checked={text==0?false:true}
                                    checkedChildren="不是"
                                    unCheckedChildren="是"
                                    onChange={()=>this.editClassStaff(text==0?1:0,record.uid)}
                            />}
                    </span>
                ),
            },
            {
                title: '操作',
                key: 'action',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 120,
                        }
                    }
                },
                render: (text, record) => (
                    <Row>
                        <Col span={12}>
                            <a onClick={()=>this.removeClassStaff(record.uid)}>删除任课老师</a>
                        </Col>
                    </Row>
                ),
            }
        ];
        return(
            <MyLayout>
                <PageHeader
                    style={{padding:"0"}}
                    title={classData?classData.name:""}
                    subTitle="任课老师管理"
                    tags={<Tag color="blue">{classData?classData.orgName:""}</Tag>}
                />
                <Divider />
                <div>
                    <AddNewClassStaff orgId={this.props.match.params.orgId}
                                      classId={this.props.match.params.classId}
                                      onChange={(ar)=>this.onChange(ar)}/>
                </div>
                <Table
                    pagination={false}
                    dataSource={this.state.staffsData}
                    columns={columns}
                />
            </MyLayout>
        )
    }
}
export default Form.create()(getClassStaffs);