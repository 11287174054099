import React, { Component } from 'react';
import {Descriptions, Badge, Button, Col, Row, Modal, message, Form} from 'antd';
import MyLayout from "../../../common/MyLayout";
import api from "../../../../api/api";
import '../../../../assets/style/ground.css';
import classNames from 'classnames';

import empty from  '../../../../assets/images/empty.png';
import occupy from '../../../../assets/images/occupy.png';
import select from '../../../../assets/images/select.png';
import screen from '../../../../assets/images/screen.png';
import poster from '../../../../assets/images/1.jpg';
import AddOrEditGround from "./AddOrEditGround";
import Dict from "../../../../config/Dict";
import connect from "react-redux/es/connect/connect";

const { confirm } = Modal;

class GroundDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            row: 10,
            column: 10,
            seatsData:[],
            groundData:null
        };
        // 0: "这个位置没有座位"，
        // 1: "可选座位"，
        // 2: "已售座位",
        // 3: "可选座位被选后"
/*
        this.seatsData = [
            [0,0,0,0,0,2,1,1,1,1,1,1,0,0,0,1,0,1,1],
            [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
            [1,1,1,1,1,2,1,2,1,1,1,1,1,1,1,2,0,0,3],
            [1,1,1,1,1,1,1,2,1,1,1,1,1,1,1,0,2,0,0],
            [1,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,0,0,0]
        ];
        */
    }
    createRowAndCol(rows,cols){ //生成座位行列数组
        var arr = new Array();
        for(var i = 0; i < rows; i++) {
            arr[i] = new Array();
            for(var j = 0; j < cols; j++) {
                arr[i][j] = 1; //1表示可选
            }
        }
        this.setState({
            seatsData:arr,
        });
    }

    groundDetail() {
        let {modelId} = this.props.match.params;
        api.lecture.groundDetail({modelId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        groundData:data.data,
                    });
                    this.createRowAndCol(data.data.rows,data.data.cols);
                }
            }
        )
    }

    click = (type) => {
        let {modelId} = this.props.match.params;
        switch (type) {
            case "cancel":
                confirm({
                    title: '你确定要注销这个场地模板吗?',
                    content: '注销的场地模板不会被删除，注销后你可以选择重新上线或删除。',
                    onOk:()=>{
                        api.lecture.offGround(
                            {modelId},
                            (state,data)=>{
                                if(state && data.status == 200) {
                                    this.groundDetail();
                                }
                            }
                        )
                    }
                });
                break;
            case "online":
                let id = this.props.match.params.modelId;
                confirm({
                    title: '你确定要重新上线这个场地模板吗?',
                    content: '重新上线这个场地模板，场地模板将进入[审批中]的状态，管理员审批通过后，将重新上线。',
                    onOk:()=>{
                        api.lecture.addOrEditGround(
                            {id},
                            (state,data)=>{
                                if(state && data.status == 200) {
                                    this.groundDetail();
                                } else {
                                    message.error(data.msg);
                                }
                            }
                        )
                    }
                });
                break;
            case "delete":
                confirm({
                    title: '你确定要删除这个场地模板吗?',
                    content: '你删除的场地模板将不再显示在场地模板列表中。',
                    onOk:()=>{
                        api.lecture.delGround(
                            {modelId},
                            (state,data)=>{
                                if(state && data.status == 200) {
                                    this.props.history.push('/company/MyGroundModelList');
                                }
                            }
                        )
                    }
                });
                break;
            default:
                break;
        }
    };

    getSum(arr){
        return arr.reduce(function(prev, curr, index, arr){
            return prev + curr;
        });
    }

    onChange(ar){
        if (ar){
            this.groundDetail();
        }
    }

    renderSeat(){
        if (this.state.seatsData.length <= 0) {
            return ;
        }
        let columns = [];
        for(let i=0,len=this.state.seatsData.length; i<len;i++) {
            columns.push(
                <div className="row">
                {this.state.seatsData[i].map((val,index)=>{
                    return <span key={index}
                                 onClick={e=>this.click(i,index)}
                                 className={classNames('seat',{'empty':val===1,'occupy':val===2,'select':val===3})}>
                    </span>
                })}
            </div>);
        }
        return columns;
    };

    renderNumber(){
        if (this.state.seatsData.length <= 0) {
            return ;
        }
        let spans = [];
        let row = 0;
        //检验roomInfo下每个数组的合并值
        for(let i=0,len=this.state.seatsData.length; i<len; i++) {
            if(this.getSum(this.state.seatsData[i])>0) {
                spans.push(<span >{++row}</span>);
            } else {
                spans.push(<span></span>);
            }
        }
        return spans;
    }

    componentDidMount() {
        this.groundDetail();
    }

    render() {
        const {
            groundData= null
        } = this.state;
        return(
            <MyLayout>
                <div className="App">
                    <div className="container">
                        <div className="seat-container">
                            <div className="seat-header">
                                <ul>
                                    <li>
                                        <img src={empty} alt="可选座位"/><span>可选座位</span>
                                    </li>
                                    <li>
                                        <img src={occupy} alt="已售座位"/><span>已售座位</span>
                                    </li>
                                    <li>
                                        <img src={select} alt="已选座位"/><span>已选座位</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="seat-main">
                                <div className="screen">
                                    <img src={screen} alt="屏幕"/>
                                    <h4>讲台中央</h4>
                                </div>
                                <div className="seats">
                                    <span className="line"></span>
                                        { this.renderSeat() }
                                    <div className="seat-number">
                                        { this.renderNumber() }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="msg-container">
                            <div className="info-main">
                                <p><span>模板名称：</span>{groundData!= null ? groundData.name:""}</p>
                                <p><span>行数：</span>{groundData!= null ? groundData.rows:""}行</p>
                                <p><span>列数：</span>{groundData!= null ? groundData.cols:""}列</p>
                                <p><span>审核状态：</span>{groundData!= null ? Dict.getValue('orgState',groundData.status,''):""}</p>
                            </div>
                            <div>
                                {this.props.userInfo.role != 5 ?
                                    <Row style={{padding:20}}>
                                        {
                                            groundData!= null && groundData.status !=0 ?
                                            <Col span={8}>
                                                <Button type="primary" onClick={()=>this.click("cancel")}>注销模板</Button>
                                            </Col>:
                                            <div>
                                                <Col span={8}>
                                                    <Button type="primary" onClick={()=>this.click("online")}>重新上线</Button>
                                                </Col>
                                                <Col span={8}>
                                                    <Button type="primary" onClick={()=>this.click("delete")}>删除模板</Button>
                                                </Col>
                                            </div>
                                        }
                                        <Col span={2}>
                                            <AddOrEditGround tagType={"edit"}
                                                             listData={groundData}
                                                             onChange={(ar)=>this.onChange(ar)}
                                            />
                                        </Col>
                                    </Row>:""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </MyLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default connect(mapStateToProps,null)(Form.create({ name: 'GroundDetail' })(GroundDetail));
//export default GroundDetail;