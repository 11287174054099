

import { Area } from '@ant-design/charts';
import React, { Component } from 'react';


class AreaChart extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {

        let data = this.props.data;
        let  valueKey = this.props.valueKey;
        let  valueName = this.props.valueName;
        let  chartName = this.props.chartName;
        let dispData = [];
        for(let i = 0; i < data.length; i++)
        {
            dispData.push(
                {
                    addTime: data[i].addTime,
                    valueKey: data[i][valueKey],
                    valueName: valueName,
                });
        }

        const config = {
            data:dispData,
            title: {
                visible: true,
                text: chartName,
            },
            xField: 'addTime',
            yField: 'valueKey',
            seriesField: 'valueName',
            xAxis: {
                type: 'dateTime',
                tickCount: 3,
            },
        };

        return (
            <Area {...config}/>
        );
    }
}

export default AreaChart;