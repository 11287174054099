import React, { Component } from 'react';
import {Row, Col, message, Form, InputNumber, Input, Button} from 'antd';
import api from '../../../../api/api';
import MyLayout from "../../../common/MyLayout";
import MyVenueModel from "../../../../components/MyVenueModel";
import SimpleDateShowTime from "../../../../components/SimpleDateShowTime";
import TeacherSelectModal from "../../../../components/TeacherSelectModal";

class AddOrEditScene extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sceneData:null,
            startTime:"",
            ticketPrice:0, // 票价
            tickets:[]
        };
    }

    addOrEditScene(values){
        let id = this.achieveParams(); // 编辑还是新建
        let lid = this.props.match.params.lid;
        let tickets = this.state.tickets; // 生成的tickets

        tickets.forEach((num, index) => {// 统一遍历设置票价
            return tickets[index].price = this.state.ticketPrice*100;
        });

        api.lecture.addOrEditScene(
            {id, lid, tickets, ...values},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.props.history.push('/company/lectureDetail'+'/'+lid);
                } else {
                    message.error(data.msg);
                }
            }
        )
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.addOrEditScene(values);
            }
        });
    };


    sceneDetail(sceneId) {
        api.lecture.sceneDetail({sceneId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        sceneData:data.data,
                    })
                }
            }
        )
    }

    getRowsCols(rowsAndCols){
        let tickets=[];
        for(let i = 1;i <= rowsAndCols.rows;i++){
            for(let j = 1;j <= rowsAndCols.cols;j++){
                tickets.push({ "rowNo":i,"colNo": j, "price": 0});
            }
        }
        this.setState({
            tickets:tickets
        })
    }

    achieveParams(){
        let sceneId = this.props.match.params.sceneId;
        if (sceneId && sceneId =="addScene"){
            sceneId = "";
        }
        return sceneId;
    }

    componentDidMount() {
        if (this.achieveParams() && this.achieveParams() !="" ){
            this.sceneDetail(this.achieveParams());
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            sceneData= null
        } = this.state;

        const formItemLayout = {
            labelCol: {
                sm: { span: 8 },
            },
            wrapperCol: {
                sm: { span: 10},
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                sm: {
                    span: 10,
                    offset: 8,
                },
            },
        };
        return(
            <MyLayout>
                <div style={{marginTop:'60px'}}>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit} >
                        <Form.Item label='本场讲座名' >
                            {getFieldDecorator('name', {
                                initialValue:sceneData?sceneData.name:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '本场讲座名是必须输入的!'
                                    },
                                ],
                            })(
                                <Input size="large"
                                       placeholder="本场讲座名"
                                />,
                            )}
                        </Form.Item>
                        <Row>
                            <Col span={6} offset={6}>
                                <Form.Item label='本场日期'>
                                    {getFieldDecorator('startTime', {
                                        initialValue:sceneData?sceneData.startTime:null,
                                        rules: [
                                            {
                                                required: false,
                                                message: '讲座开始日期是必填的!'
                                            },
                                        ],
                                    })(
                                        <SimpleDateShowTime placeholder={"开始日期"}
                                            onChange={(e)=>{
                                                this.setState({
                                                    startTime:e
                                                })
                                            }}
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item>
                                    {getFieldDecorator('endTime', {
                                        initialValue:sceneData?sceneData.endTime:null,
                                        rules: [
                                            {
                                                required: false,
                                                message: '讲座结束日期是必填的!'
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if (this.state.startTime =="" && sceneData.startTime) {
                                                        if (new Date(value) > new Date(sceneData.startTime)) {
                                                            callback();
                                                            return;
                                                        }
                                                    }
                                                    else if(new Date(value) > new Date(this.state.startTime)){
                                                        callback();
                                                        return;
                                                    }
                                                    callback('error');
                                                },
                                                message: '结束时间不能小于开始时间',
                                            },
                                        ],
                                    })(
                                        <SimpleDateShowTime placeholder={"结束日期"}/>,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label='会场选择'>
                            {getFieldDecorator('vid', {
                                initialValue:sceneData?sceneData.venue:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '会场是必须选择的!'
                                    },
                                ],
                            })(
                                <MyVenueModel rowsColsFun={(rowsAndCols)=>this.getRowsCols(rowsAndCols)}/>
                            )}
                        </Form.Item>
                        <Form.Item label='本场主讲人'>
                            {getFieldDecorator('tid', {
                                initialValue:sceneData?sceneData.teacher:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '本场主讲人是必须选择的!'
                                    },
                                ],
                            })(
                                <TeacherSelectModal />
                            )}
                        </Form.Item>
                        <Form.Item label='本场嘉宾' >
                            {getFieldDecorator('guests', {
                                initialValue:sceneData?sceneData.guests:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '本场嘉宾是必须输入的!'
                                    },
                                ],
                            })(
                                <Input size="large"
                                       placeholder="本场嘉宾(多个用逗号分隔)"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='本场票价(元)' >
                            {getFieldDecorator('ticketPrice', {
                                initialValue:sceneData && sceneData.tickets[0] ?sceneData.tickets[0].price/100:'',
                                rules: [
                                    {
                                        required: true,
                                        message: '本场票价是必须输入的,最少0.1!'
                                    },
                                ],
                            })(
                                <InputNumber min={0}
                                             size="large"
                                             onChange={(value)=>this.setState({ticketPrice:value})}/>,
                            )}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit" size="large" block>
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </MyLayout>
        )
    }
}
export default Form.create()(AddOrEditScene);