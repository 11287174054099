
import {store} from '../../index';
import {createHashHistory } from 'history';
import Action from '../../actions/Action';
const history = createHashHistory({
    forceRefresh: true        //是否强制刷新
});


const URLBuild = require("./URLBuild")
var DEBUG = true;

function randomString(len) {
    　　len = len || 32;
    　　var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; 
    　　var maxPos = $chars.length;
    　　var pwd = '';
    　　for (var i = 0; i < len; i++) {
    　　　　pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    　　}
    　　return pwd;
}

let authtoken = null;
if(sessionStorage)
{
    authtoken = sessionStorage.getItem('authtoken');
}
let ready = false;

if(authtoken == null)
{
	authtoken = randomString(32);
    if(sessionStorage)
	{
		sessionStorage.setItem('authtoken',authtoken);
	}
}

function setauthtoken(tk)
{
    if(tk == null || tk == 'null')
    {
        return;
    }
    authtoken = tk;
    if(sessionStorage)
    {
        sessionStorage.setItem('authtoken',authtoken);
    }

    if(!ready)
    {
        store.dispatch(
        { 
            type: Action.SYS_REDAY_OK,
        });
        ready = true;
    }
}

function authtokenHandle(response) {
    let authtoken = response.headers.has('authtoken');
    if (authtoken) {
        authtoken = response.headers.get('authtoken');
        setauthtoken(authtoken);
    }
    return response;
}


function callbackFilter(state, data, cbk, param) {
    if (DEBUG) {
        console.log("||================================");
        console.log("||\tURL:", param.url);
        console.log("||\tstatus:", state);
        console.log("||\tdata:", data);
        console.log("||\trequest:", param);
        console.log("||================================");
    }
    if (data.status == 401 || data.status == 403) {
        history.replace('/login');
    }
    try {
        if (cbk)
            cbk(state, data);
    } catch (e) {
        console.log("||=================ERROR===================");
        console.log("||\turl:", param.url);
        console.log("||\terr:", e);
        console.log("||=========================================");
    }
}

function GET(path) {
    return function (param, cbk) {
        var url = new URLBuild(path).addAll(param).build();
        var rs = fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "authtoken":authtoken,
            },
            method: 'GET',
        });
        rs.then(authtokenHandle);
		
        rs.then((response) => response.json())
            .then(function (res, request) {
                callbackFilter(true, res, cbk, {
                    url: url,
                    data: param,
                    res: res
                });
            }).catch((error) => {
            callbackFilter(false, error, cbk, {
                url: url,
                data: param,
                res: error
            });
        });

    }
}

function POST(url) {
    return function (data, cbk) {
        var rs = fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "authtoken":authtoken,
            },
            method: 'POST',
            body: JSON.stringify(data)
        });
        rs.then(authtokenHandle);
        rs.then((response) => response.json())
            .then(function (res, request) {
                callbackFilter(true, res, cbk, {
                    url: url,
                    data: data,
                    res: res
                });
            }).catch((error) => {
            callbackFilter(false, error, cbk, {
                url: url,
                data: data,
                res: error
            });
        });
    }
}

function setDebug(d) {
    DEBUG = d;
}

export {POST, GET, setDebug,authtoken}

