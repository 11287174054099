import React, { Component } from 'react';
import { Layout,Menu,PageHeader } from 'antd';
import InfoManageSider from "./sider/Company_InfoManageSider";
import UserSettingSider from "./sider/UserSettingSider";
import PlatformPendingSider from "./sider/PlatformPendingSider";
import VideoLectureOrderSider from "./sider/VideoLectureOrderSider";

class SiderSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                {this.props.siders=="InfoManageSider" ? <InfoManageSider/> : null}
                {this.props.siders=="UserSettingSider" ? <UserSettingSider/> : null}
                {this.props.siders=="PlatformPendingSider" ? <PlatformPendingSider/> : null}
                {this.props.siders=="VideoLectureOrderSider" ? <VideoLectureOrderSider/> : null}
            </div>
        );
    }
}

export default SiderSelect;