let Action = {
    SAVE_USER_INFO:'UserInfo_Save_user_info_to_store',
    RESET_USER_INFO:'UserInfo_Reset_user_info_to_store',
    SET_LOADING_STATE:'UserInfo_Set_loading_state',
    SYS_REDAY_OK:'System_init_ready',
    GET_USER_INFO:'UserInfo_get_user_info',
    SAVE_VIDEO_TYPE:'Save_Video_Types_Data',
    FEATCH_READ_NUM:'Feactch_Not_read_num_data',
    SAVE_READ_NUM:'Save_read_num_to_store',
    CHANGE_VIEW_MODE: 'Change_view_mode',
};
export default Action;
