import React, { Component } from "react";
import { message, Form, Icon, Input, Button, Radio, Row, Col, Modal, Divider, Typography } from 'antd';
import DataValidate from '../../config/DataValidate'
import api from '../../api/api';
import { connect } from 'react-redux';
import Background from '../../assets/images/login.jpg';
import ResetPassword from "../user/userSetting/ResetPassword";
import Action from "../../actions/Action";
const InputGroup = Input.Group;

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isNeedImageCode: false,
            imgenum: 20,
            loginmethod: '0',

            phone: null,
            visible: false,
            imagecode: null,
            changeNewPwd: ""
        }
    }

    loginSuccess(data) {
        this.props.saveUserInfoToStore(data.data);
        let user = data.data;
        if (user.role === 0 || user.role === 2 || user.role === 3 || user.role === 5) { //普通用户.教师.admin
            this.props.history.push('/home');
        }
        if (user.role === 1) {
            this.props.history.push('/CompanyHome');
        }
        //if (user.role == 5){ //Admin
        //this.props.history.push('/home');
        //}
        //this.props.history.push('/');
    }

    refreshImage() {
        this.setState(
            {
                imgenum: Math.ceil(Math.random() * 1000000)
            }
        )
    }

    getParam(name) {
        var search = this.props.location.search;
        if (search == "") {
            return null;
        }

        var query = search.substring(1);
        var list = query.split('&');
        var item = [];
        for (var i = 0; i < list.length; i++) {
            item = list[i].split('=');
            if (item[0] == name) {
                return item[1];
            }
        }
        return null;
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let wxkey = this.getParam('wxkey');
                let token = this.getParam('token');
                let cbk = (state, data) => {
                    if (state && data.status == 200) {
                        this.loginSuccess(data);
                        return;
                    }
                    else if (state && data.status == 1014) {
                        this.setState({
                            isNeedImageCode: true
                        });
                        message.error("密码三次错误，需要验证码验证！");
                    } else {
                        message.error(data.msg);
                    }
                };
                if (this.state.loginmethod == '0') {
                    api.user.login({ ...values, wxkey, token }, cbk);
                } else {
                    api.user.phonelogin({ ...values, wxkey, token }, cbk);
                }
            }
        });
    };

    startUpLogin() {
        let token = this.getParam('token');
        if (token) {
            api.user.tokenLogin({ token },
                (state, data) => {
                    if (state && data.status == 200) {
                        this.loginSuccess(data);
                    } else {
                        //Do nothing.
                    }
                });
        }
    }

    componentDidMount() {
        this.startUpLogin();
    }

    renderPhoneValidate(name) {
        const { getFieldDecorator } = this.props.form;
        return getFieldDecorator(name, {
            rules: [
                {
                    required: true,
                    message: '验证码必须输入!'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value && value.length == 5) {
                            api.user.validateImgCode({ validateCode: value },
                                function (state, data) {
                                    if (state && data.status == 200) {
                                        callback()
                                    } else {
                                        callback('error');
                                    }
                                }
                            );
                        } else {
                            callback('error');
                        }
                    },
                    message: '验证码输入错误',
                },
            ],
        })(
            <Row gutter={8}>
                <Col span={12}>
                    <Input size="large"
                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="请输入验证码"
                        onChange={(e) => {
                            this.setState({ imagecode: e.target.value })
                        }}
                    />
                </Col>
                <Col span={12}>
                    <img
                        onClick={() => this.refreshImage()}
                        style={{ width: 120, height: 40 }}
                        src={'/api/user/generateCodeImg?imgnum' + this.state.imgenum} />
                </Col>
            </Row>

        );
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let wxkey = this.getParam('wxkey');
        let token = this.getParam('token');


        return (
            <div >
                <img className="login_Background" src={Background} />
                <p className="login_title_1">德师</p>
                <p className="login_title_2">师者，所以传道受业解惑也</p>

                <Form onSubmit={this.handleSubmit} className="login-form">

                    {
                        wxkey ?
                            <Form.Item>
                                <Typography.Title level={4} strong type='danger'>该用户尚未绑定任何用户，请先登录绑定或者注册</Typography.Title>
                            </Form.Item>
                            : null
                    }
                    {
                        token ?
                            <Form.Item>
                                <Typography.Title level={4} strong type='danger'>欢迎来自工匠平台的用户，请先登录或者注册</Typography.Title>
                            </Form.Item>
                            : null
                    }

                    <Form.Item>
                        <Radio.Group size="large"
                            value={this.state.loginmethod}
                            onChange={(e) => this.setState({ loginmethod: e.target.value })}>
                            <Radio.Button value="0">用户名密码登录 </Radio.Button>
                            <Radio.Button value="1">手机验证码登录</Radio.Button>
                        </Radio.Group>

                    </Form.Item>
                    {this.state.loginmethod == '0' ? <div>
                        <Form.Item hasFeedback>
                            {getFieldDecorator('username', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus: 'error',
                                        message: '用户名必须输入!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (DataValidate.isEmail(value)
                                                || DataValidate.isMobile(value)
                                                || DataValidate.isUid(value)
                                                || DataValidate.isIdNumber(value)) {
                                                api.user.isNeedPictureValide({ "username": value },
                                                    (state, data) => {
                                                        if (state && data.status == 200 && data.data == 1) {
                                                            this.setState({
                                                                isNeedImageCode: true
                                                            })
                                                        }
                                                    });
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '输入格式不对',
                                    },
                                ],
                            })(
                                <Input size="large"
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="手机/邮箱/身份证号/会员名"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: '密码必须输入!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (DataValidate.isPassWord(value)) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '密码格式不对',
                                    },
                                ],
                            })(
                                <Input size="large"
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                    placeholder="请输入密码"
                                />,
                            )}
                        </Form.Item>
                    </div> :

                        <div className='mobileLogin'>
                            <Form.Item>
                                <InputGroup compact>
                                    {getFieldDecorator('phone', {
                                        rules: [
                                            {
                                                required: true,
                                                validateStatus: 'error',
                                                message: '电话号码必须输入!'
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if (DataValidate.isMobile(value)) {
                                                        api.user.isNeedPictureValide({ "username": value },
                                                            (state, data) => {
                                                                if (state && data.status == 200 && data.data == 1) {
                                                                    this.setState({
                                                                        isNeedImageCode: true
                                                                    })
                                                                }
                                                            });
                                                        callback();
                                                        return;
                                                    }
                                                    callback('error');
                                                },
                                                message: '电话输入格式不对',
                                            },
                                        ],
                                    })(
                                        <Input size="large" style={{ width: '70%' }}
                                            prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            onChange={(e) => {
                                                this.setState({
                                                    phone: e.target.value
                                                })
                                            }}
                                            placeholder="手机号"
                                        />,
                                    )}
                                    <Button size="large"
                                        onClick={() => {
                                            if (DataValidate.isMobile(this.state.phone)) {
                                                this.setState({ visible: true })
                                            } else {
                                                message.error('请先输入电话号码')
                                            }

                                        }}>获取验证码</Button>
                                </InputGroup>
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('phoneValidateCode', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '手机验证码必须输入!'
                                        },
                                        {
                                            validator: (rule, value, callback) => {
                                                if (DataValidate.isMobileCode(value)) {
                                                    callback();
                                                    return;
                                                }
                                                callback('error');
                                            },
                                            message: '验证码格式不对',
                                        },
                                    ],
                                })(
                                    <Input size="large"
                                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder="手机验证码"
                                    />,
                                )}
                            </Form.Item>

                        </div>
                    }
                    {
                        this.state.isNeedImageCode ?
                            (
                                <Form.Item>
                                    {this.renderPhoneValidate("validateKey")}
                                </Form.Item>
                            ) : null
                    }

                    <Form.Item>
                        <Button size="large" type="primary" htmlType="submit" block>
                            登录
                        </Button>
                    </Form.Item>
                    <Form.Item >
                        <div>{/*
                            还没有账号？ <a href="#/register" >立即注册?</a>&nbsp;&nbsp;
                            忘记密码？<a href="#/findbackpwd" >找回密码?</a>*/}
                            <Row>
                                <a href="/api/weixin/getLoginQR" target='_self'>
                                    <Divider><img src={require('../../assets/images/wechat.jpg')} /></Divider>
                                </a>
                            </Row>
                            <Row>
                                <Col span={14}>
                                    还没有账号?
                                    {
                                        wxkey ?
                                            <a href={"#/register?wxkey=" + wxkey}>立即注册?</a>
                                            :
                                            <>
                                                {
                                                    token ?
                                                        <a href={"#/register?token=" + token}>立即注册?</a>
                                                        :
                                                        <a href="#/register">立即注册?</a>
                                                }
                                            </>
                                    }
                                </Col>
                                <Col span={10}>
                                    忘记密码?<ResetPassword btnName={"找回密码?"} />
                                </Col>
                            </Row>
                        </div>
                    </Form.Item>
                </Form>
                {this.state.visible ?
                    <Modal
                        title="获取手机验证码"
                        visible={this.state.visible}//对话框是否显示
                        onOk={() => {
                            api.user.sendPhoneKey({
                                phone: this.state.phone,
                                validatekey: this.state.imagecode,
                            },
                                (status, data) => {
                                    if (status && data.status == 200) {
                                        this.setState({
                                            visible: false,
                                        }
                                        );
                                        message.info("验证码发送成功");
                                    } else {
                                        message.error(data.msg);
                                    }
                                }
                            );
                        }}
                        onCancel={() => {
                            this.setState({
                                visible: false
                            })
                        }
                        }
                        okText="确认"
                        cancelText="取消"
                    >
                        <Form.Item>
                            {this.renderPhoneValidate("validatePhone")}
                        </Form.Item>
                    </Modal> : null
                }
            </div>
        )
    }
}

//需要触发什么行为
function mapDispatchToProps(dispatch) {
    return {
        saveUserInfoToStore: (data) => dispatch({
            type: Action.SAVE_USER_INFO, //CON.SAVE_USER_INFO
            data: data,
        }),
    }
}
export default connect(null, mapDispatchToProps)(Form.create({ name: 'normal_login' })(Login));