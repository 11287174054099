
import React, { Component } from 'react';
import { Progress, Table, Tag } from 'antd';
import api from '../../api/api';

class MyWholeAnswerRecord extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            paperData:[], // 答题记录
         };
    }

    fetchMyPaperRecord() {
        let uid = this.props.uid;
        if(!uid)
        {
            return;
        }
        api.exam.getMyAnswerRecord({uid},
            (state,data)=>{
                if(state && data.status === 200) {
                    this.setState({paperData:data.data})
                }
            }
        )
    }

    componentDidMount() {
        this.fetchMyPaperRecord();
    }

    render() {
        const {paperData} = this.state;
        const paperColumns = [
            {
                title: '试卷名',
                dataIndex: 'title',
                key: 'title',
                className:"tableOverflow",
            },
            {
                title: '考试时间',
                dataIndex: 'kssj',
                key: 'kssj',
                className:"tableOverflow",
                render: (text, record) => (
                    <div>
                    <span>{record.startTime?record.startTime:'不定'}</span>
                    -
                    <span>{record.endTime?record.endTime:'不定'}</span>
                </div>
                )
            },
			/***
            {
                title: '答题状态',
                dataIndex: 'answerSumbit',
                key: 'answerSumbit',
                render: (text, record) => (
                    record.answerSumbit == 0?
                        <Tag color="#f50">未交卷</Tag>
                        :<Tag color="#108ee9">已交卷</Tag>
                )
            },
			***/
            {
                title: '判卷',
                dataIndex: 'judge',
                key: 'judge',
                render: (text, record) => (
                    record.judge == 0?
                        <Tag color="#f50">未判卷</Tag>
                        :<Tag color="#108ee9">已判卷</Tag>
                )
            },
            {
                title: '完成百分比',
                dataIndex: 'percent',
                key: 'percent',
                render: (text, record) => (
                    <Progress percent={record.percent}/>
                )
            },
            {
                title: '得分',
                dataIndex: 'score',
                key: 'score',
                render: (text, record) => (
                    <span>
                        {record.choiceScore+record.completionScore+record.shortScore}分
                    </span>
                )
            }
        ];

        return (
            <div>
                    <Table
                            pagination={false}
                            dataSource={paperData}
                            columns={paperColumns}
                            rowKey='answerId'
                        />
            </div>
        );
    }
}

export default MyWholeAnswerRecord;



