import React, { Component } from 'react';
import {Tag, Divider, Table} from 'antd';
import api from '../../../api/api';

class MyLearnVideoList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:[],
        };
    }

    getMyLearnVideoListByTaskId(){
        let mtaskId = this.props.data.match.params.mtaskId;
        api.student.getMyLearnVideoListByTaskId({"mtaskId":mtaskId},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.getMyLearnVideoListByTaskId();
    }

    render() {
        const {data} = this.state;
        let history = this.props.data.history;
        const columns = [
            {
                title: '视频名',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow",
                render: (text, record) => (
                    <a onClick={()=>
                        history.push("/Personal/VideoDetail"+'/'+record.vid)}>
                        {record.name}
                    </a>
                ),
            },
            {
                title: '讲师',
                dataIndex: 'teacher',
                key: 'teacher',
                render: (text, record) => (
                    <div>
                        {record.teacher.name}
                    </div>
                ),
            },
            {
                title: '关键字',
                dataIndex: 'keywords',
                key: 'keywords',
                className:"tableOverflow",
                render: (text, record) => (
                    <Tag color="#87d068">
                        {text}
                    </Tag>
                ),
            },
            {
                title: '访问量',
                dataIndex: 'visitTimes',
                key: 'visitTimes',
                render: (text, record) => (
                    <Tag color="geekblue">
                        {text}
                    </Tag>
                ),
            },
        ];
        return(
            <div>
                <Table
                    pagination={false}
                    dataSource={data}
                    columns={columns}
                />
            </div>
        )
    }
}
export default MyLearnVideoList;