import React,{ Component } from "react";
import {List, Row, Col, Card, Divider, Tooltip} from 'antd';
import api from "../../api/api";
import HomeLayout from "../common/HomeLayout";
import ComFunction from "../../api/ComFunction";
import CON from "../../config/const";
import Sfs from "../../config/Sfs";
const { Meta } = Card;

class CompanyHome extends Component{

    constructor(props)
    {
        super(props);
        this.state = {
            listData:[],
            pageNum:1,
            pageSize:8,
            totalNum:8,
        };
    }

    updateData() {
        api.video.videoList(
            {
                "condition": {
                    "lectureDateEnd": "",
                    "lectureDateStart": "",
                    "lectureName": "",
                    "teacherName": "",
                    "status":null,
                    "videoKeywords": []
                },
                "pageNum": 1,
                "pageSize": 8,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        listData:data.data,
                    })
                }
            }
        )
    }
    getTodayStar(){
        api.teacher.getTodayStar("",
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        starListData:data.data,
                    })
                }
            }
        )
    }

    lectureList(){
        api.lecture.lectureList(
            {
                "condition": {
                    "beforeEnd": true,
                    "endTime": "",
                    "lectureName": "",
                    "orgIds": [],
                    "startTime": "",
                    "teahcerNames": []
                },
                "pageNum": 1,
                "pageSize": 8,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        lectureListData:data.data,
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.updateData();
        this.lectureList();
        this.getTodayStar();
    }

    render()
    {
        let history = this.props.history;
        return(
            <HomeLayout>
                <Row style={{marginTop: "45px", marginBottom: "20px",display:'flex'}}>
                    <Col span={22}>
                        <div style={{fontSize:"24px"}}>热门视频</div>
                    </Col>
                    <Col span={2} style={{top:"20px"}}>
                        <a onClick={()=>
                            history.push('/Personal/moreVideoList')
                        }>查看更多视频</a>
                    </Col>
                </Row>
                <List
                    grid={{ gutter: 20, column: 4 }}
                    itemLayout="vertical"
                    size="large"
                    dataSource={this.state.listData}
                    renderItem={(item,index) => (
                        <List.Item key={index}>
                            <Card hoverable
                                  cover={<img alt="example" style={{height:"170px"}} src={item.posterPath} />}
                                  onClick={()=>
                                      history.push('/Personal/VideoDetail'+'/'+item.vid)
                                  }
                            >
                                <Meta title={item.name} style={{height:"100px"}} description={
                                    <div className="multipleTextOverflow">
                                        <Tooltip title= {item.intro}>
                                            {item.intro}
                                        </Tooltip>
                                    </div>
                                } />
                            </Card>
                        </List.Item>
                    )}
                />
                {!CON.IS_FREE && Sfs.isLectureOn ?<>
                <Divider />
                <Row style={{marginTop: "45px", marginBottom: "20px",display:'flex'}}>
                    <Col span={22}>
                        <div style={{fontSize:"24px"}}>热门讲座</div>
                    </Col>
                    <Col span={2} style={{top:"20px"}}>
                        <a onClick={()=>
                            history.push('/Personal/moreLectureList')
                        }>查看更多讲座</a>
                    </Col>
                </Row>
                <List
                    grid={{ gutter: 20, column: 4 }}
                    itemLayout="vertical"
                    size="large"
                    dataSource={this.state.lectureListData}
                    renderItem={(item,index) => (
                        <List.Item
                            key={index}
                        >
                            <Card hoverable
                                  cover={<img alt="example" style={{height:"170px"}} src={item.posterPath} />}
                                  onClick={()=>
                                      history.push('/Personal/LectureSceneDetail'+'/'+item.lid)
                                  }
                                  actions={[
                                      <div>选座购票</div>
                                  ]}
                            >
                                <Meta title={item.name} style={{height:"60px"}} description={
                                    <div className="twoElements">
                                        <Tooltip title= {item.intro}>
                                            {item.intro}
                                        </Tooltip>
                                    </div>
                                } />
                            </Card>
                        </List.Item>
                    )}
                />
                </>:null}
                <Divider />
                <Row style={{marginTop: "45px", marginBottom: "20px",display:'flex'}}>
                    <Col span={22}>
                        <div style={{fontSize:"24px"}}>今日名师</div>
                    </Col>
                </Row>
                <List
                    grid={{ gutter: 20, column: 4 }}
                    itemLayout="vertical"
                    size="large"
                    dataSource={this.state.starListData}
                    renderItem={(item,index) => (
                        <List.Item
                            key={index}
                        >
                            <Card hoverable
                                  cover={<img alt="example"
                                              className="homeImg"
                                              src={ComFunction.getScareImgeUrl(item.teacher.photoPath,200,260)} />
                                  }
                                  onClick={()=>
                                      history.push('/Personal/FamousTeacherDetail'+'/'+item.teacher.tid)
                                  }
                            >
                                <Meta title={item.teacher.name}
                                      style={{height:"60px"}} description={
                                    <div className="twoElements">
                                        <Tooltip title= {item.teacher.motto}>
                                            {item.teacher.motto}
                                        </Tooltip>
                                    </div>
                                }/>
                            </Card>
                        </List.Item>
                    )}
                />
            </HomeLayout>
        )
    }

}

export default CompanyHome;