import React, { Component } from 'react';
import {Form,Modal, InputNumber, Input,Button,message} from 'antd';
import api from "../../../api/api";
import DataValidate from "../../../config/DataValidate";
import DraftInput from './DraftInput';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';

const { TextArea } = Input;

/************* 添加或者编辑一个选项 ****************/
class AddOrEditChoiceModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            data:[],
        }
    }

    handleOk = e => {
        e.preventDefault();
        let choiceId = this.props.choiceData?this.props.choiceData.choiceId:"";
        let questionId = this.props.questionId;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.exam.addOrEditChoice(
                    {questionId, choiceId, ...values},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.setState({
                                visible: false,
                            });
                            this.props.onChange(state);
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const choiceData = this.props.choiceData;
        const formItemLayout = {
            labelCol: {
                sm: { span: 4 },
            },
            wrapperCol: {
                sm: { span: 18},
            },
        };
        return (
            <div>

                <Button type={this.props.type==="add"?"primary":"link"}
                        style={{ marginBottom: 16 }}
                        onClick={()=>{
                            this.setState({
                                visible: true,
                            })
                        }}>
                    {this.props.type === "add"?<PlusOutlined/>:<EditOutlined/>}
                    {this.props.type === "add"?"添加选项":"编辑"}
                </Button>

                <Modal
                    title={this.props.type === "add"?"添加选项":"编辑选项"}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    width={900}
                    onCancel={this.handleCancel}
                >
                    <Form {...formItemLayout}>
                    <Form.Item label='选项标识' hasFeedback>
                            {getFieldDecorator('tmark', {
                                initialValue:choiceData?choiceData.tmark:"A",
                                rules: [
                                    {
                                        required: true,
                                        message: '选项标识是必须输入的!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(DataValidate.isChoice(value)) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '选项标识输入错误!(只允许输入A-Z或a-z,且只能输入1个字母)',
                                    },
                                ],
                            })(
                                <Input size="large"
                                       maxLength={1}
                                       placeholder="选项标识"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='选项内容' hasFeedback style={{height:'320px'}}>
                            {getFieldDecorator('content', {
                                initialValue:choiceData?choiceData.content:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '选项内容是必须输入的!'
                                    },
                                ],
                            })(
                                <DraftInput/>
                            )}
                        </Form.Item>

                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(AddOrEditChoiceModal);