import React,{ Component } from "react";
import MyLayout from "../../common/MyLayout";
import {Row, Col, Tooltip, List, Card,Divider, Progress, Button, Input, Pagination} from 'antd';
import api from "../../../api/api";
const { Meta } = Card;


const InputGroup = Input.Group;
class myPlayVideoList extends Component{

    constructor(props) {
        super(props);
        this.state = {
            videoListData:[],

            keyword:null,
        };

        this.initPageSize = 8;
        this.pageNum = 1;

        this.pageSize = this.initPageSize;
        this.totalNum = this.initPageSize;
    }

    pageOnChange(pageNum, pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(() => this.updateData(), 200);
    }

    updateData() {
        api.video.pageMyPlayVideoList(
            {
                "condition": {
                    "keyword":this.state.keyword
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data) => {
                if (state && data.status == 200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        videoListData: data.data,
                    })
                }
            }
        )
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        setTimeout(() => this.updateData(), 200,);
    }

    componentDidMount() {
        this.updateData();
    }

    render()
    {
        let history = this.props.history;
        return(
            <MyLayout >
                <Row style={{marginBottom: "40px"}}>
                    <Col span={8}>
                    </Col>
                    <Col span={16} style={{top:"20px"}}>
                        <InputGroup compact>
                            <span>
                            <Input size="large"
                                        defaultValue={this.state.keyword}
                                        placeholder='请输入播放记录视频关键字'
                                        onChange={(e) => this.setState({keyword: e.target.value})}
                            />
                            </span>
                            <Button type='primary'
                                        size="large"
                                        icon="search"
                                        onClick={() => this.research()}>
                                    搜索</Button>
                        </InputGroup>
                    </Col>
                </Row>
                <Divider />
                <List
                    grid={{ gutter: 20, column: 4 }}
                    itemLayout="vertical"
                    size="large"
                    dataSource={this.state.videoListData}
                    renderItem={(item,index) => (
                        <List.Item key={index}>
                            <Card hoverable
                                  cover={<img 
                                  alt="example" 
                                  style={{height:"170px"}} 
                                  onClick={()=>
                                      history.push('/Personal/VideoPlay'+'/'+item.vid)
                                  }
                                  src={item.posterPath} />}

                            >
                                <Meta title={item.name}
                                      style={{height:"110px"}}
                                      description={
                                          <div>
                                              <div style={{marginBottom:"10px"}} className="twoElements">
                                                  <Tooltip title= {item.intro}>
                                                      {item.intro}
                                                  </Tooltip>
                                              </div>
                                              {
                                                item.certNo?
                                                <Button type="primary"
                                                        target="_blank"
                                                        shape="round"
                                                        size="default"
                                                        block
                                                        onClick={()=> window.open("http://www.deshipt.com/api/anon/getVideoPlayCertPng?certNo="+item.certNo)}
                                                        >
                                                    学习完了证明下载
                                                </Button>:<Progress percent={item.percent} />
                                              }
                                          </div>
                                      } />
                            </Card>
                        </List.Item>
                    )}
                />
            <Pagination
                    onChange={(pageNum, pageSize) => this.pageOnChange(
                        pageNum, pageSize
                    )}
                    pageSize={this.pageSize}
                    current={this.pageNum}
                    total={this.totalNum}
                />
            </MyLayout>
        )
    }
}

export default myPlayVideoList;