
import { InputNumber, Avatar, Button, Col, Divider, Form, Modal, Pagination, Popover, Row, Select, Table, Tooltip, message } from 'antd';
import React, { Component } from 'react';
import api from '../../../api/api';
import ImageUpload from '../../../components/ImageUpload';
import PackButton from '../../../components/PackButton';
import RoutePath from '../../../config/RoutePath';
import MyLayout from '../../common/MyLayout';

class AccountClosePay extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:[],
            status:0,
            visible:false,
         };

         this.initPageSize = 8;
         this.pageNum = 1;
         this.pageSize = this.initPageSize;
         this.totalNum = 10000;
    }

    featchBizOrderList() {
        api.biz.pageSearchBizCloses(
            {
                "condition": {
                    uid:null,
                    status:this.state.status,
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data) => {
                if (state && data.status == 200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        data: data.data,
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.featchBizOrderList();
    }

    pageOnChange(pageNum, pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(() => this.featchBizOrderList(), 200);
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        setTimeout(() => this.featchBizOrderList(), 200,);
    }


    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    handleOk = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.biz.payClose(
                    {
                        ...values,
                        payNum:Math.floor(values.payNum * 100),
                    },
                    (state,data)=>{
                        if(state && data.status === 200)
                        {
                            this.featchBizOrderList();
                        }
                        else
                        {
                            message.error("未知错误");
                        }
                })
                this.setState({
                    visible: false,
                });
            }
        });
    };

    delClose=(closeId)=>
    {
        Modal.confirm({
            title:'警告',
            content:'真的要删除此条记录？',
            onOk:()=>{
                api.biz.delClose({closeId},
                    (state,data)=>{
                        if(state && data.status === 200)
                        {
                            this.featchBizOrderList();
                        }
                        else
                        {
                            message.error("未知错误");
                        }
                    }
                )
            }
        })
    }

    render() {

        const { getFieldDecorator } = this.props.form;
        getFieldDecorator('closeId',{});
        getFieldDecorator('payNum',{});

        const columns = [
            {
                title: '结算单',
                dataIndex: 'payEvidence',
                key: 'payEvidence',
                render: (text, record) => {
                    if(text)
                    {
                        return <a href={text} target='_blank'><Avatar src={text}/ ></a>
                    }
                    else
                    {
                        return <div>无结算单</div>
                    }
                },
            },
            {
                title: '结算状态',
                dataIndex: 'status',
                key: 'status',
                className:"tableOverflow",
                render: (text, record) => (
                        <div>{text === 1?'已结算':'未结算'}</div>
                ),
            },
            {
                title: '账目金额(元)',
                dataIndex: 'totalNum',
                key: 'totalNum',
                render: (text, record) => (
                    <div>{record.totalNum/100}</div>
                )
            },
            {
                title: '支付金额(元)',
                dataIndex: 'payNum',
                key: 'payNum',
                render: (text, record) => (
                    <div>{record.payNum/100}</div>
                )
            },
            {
                title: '申请日期',
                dataIndex: 'sumbitDate',
                key: 'sumbitDate',
                render: (text, record) => (
                    <div>{record.sumbitDate?record.sumbitDate:'无日期'}</div>
                )
            },
            {
                title: '支付日期',
                dataIndex: 'payDate',
                key: 'payDate',
                render: (text, record) => (
                    <div>{record.payDate?record.payDate:'未支付'}</div>
                )
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button
                        type='primary'
                        onClick={()=>{
                            this.setState({visible:true});
                            this.props.form.setFieldsValue(
                                {...record,
                                payNum:record.payNum/100});
                        }}>
                        支付
                        </Button>
                        <Divider type='vertical'/>
                        <Button type='danger'
                        onClick={()=>this.delClose(record.closeId)}
                        >
                            删除
                        </Button>
                        <Divider type='vertical'/>
                        <Button onClick={()=>this.props.history.push(RoutePath.AccountInfo + '/' + record.uid)}>详情</Button>
                    </span>
                ),
            },
        ];

        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
          };

          const tailFormItemLayout = {
            wrapperCol: {
                sm: {
                    span: 10,
                    offset: 8,
                },
            },
        };

        return (
            <MyLayout title="结算">
                <Row>
                    <Col span={8}>
                    <Form layout='inline'>
                        <Form.Item label="支付状态">
                        <Select
                              value={this.state.status}
                              onChange={(value)=>{
                                  this.setState({
                                      status:value
                                  })
                                  this.research();
                              }}
                              >
                                <Select.Option value={null}>不限制</Select.Option>
                                <Select.Option value={0}>未支付</Select.Option>
                                <Select.Option value={1}>已支付</Select.Option>
                            </Select>
                        </Form.Item>
                    </Form>
                    </Col>
                </Row>
                <Divider/>
                <Table
                            rowKey='closeId'
                            pagination={false}
                            dataSource={this.state.data}
                            columns={columns}
                />
                <Divider/>
                <Pagination
                        onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.pageSize}
                            current={this.pageNum}
                            total={this.totalNum}
                            />
            <Modal
                title="支付"
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={null}
            >
            <Form onSubmit={this.handleOk}  {...formItemLayout}>
                       <Form.Item hasFeedback label="实际支付金额(元)">
                            {getFieldDecorator('payNum', {
                                rules: [
                                    {
                                        required: true,
                                        message: '必须输入!'
                                    },
                                ],
                            })(
                                <InputNumber
                                 placeholder="金额"
                                precision={2}
                                min={0}
                                step={1}
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='支付单据'>
                                {
                                    getFieldDecorator('payEvidence', {
                                        rules: [
                                            {
                                                required: false,
                                                message: '单据不是必须上传的!'
                                            },

                                        ],
                                    })(
                                        <ImageUpload/>,
                                    )
                                }
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <PackButton
                            type='primary'
                            htmlType="submit"
                            block
                            onClick={this.handleOk}
                            >确定</PackButton>
                        </Form.Item>
                        </Form>
                </Modal>
            </MyLayout>
        );
    }
}

export default Form.create()(AccountClosePay);


