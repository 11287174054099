import React, { Component } from 'react';
import api from '../../api/api';
import { setLoadingState } from '../../reducers/UserInfo';
import MyLayout from '../common/MyLayout';
import { Checkbox, Table, Divider, Row, Col, Form, Select, Button, message, Tooltip } from 'antd';
import RoutePath from '../../config/RoutePath';
import moment from 'moment';
import SimpleDateSel from '../../components/SimpleDateSel';
import ComFunction from '../../api/ComFunction';

class StaticTotalMonth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            yearMonth: moment().format("YYYY/MM/DD HH:mm:ss"),

            data:[],
            loading:false,
            acc:false,
         };
    }

    featchPersonalList()
    {
        setLoadingState(true);
        let orgId = this.props.match.params.orgId;
        api.statics.getMtClassYearMonthTotal({
            orgId:orgId,
            yearMonth:this.state.yearMonth,
            acc:this.state.acc,
        },
        (state, data)=>{
            if(state && data.status==200) {

                let mdata = data.data;
                for(let i = 0; i < mdata.length; i++)
                {
                    let studentNumOk = mdata[i].studentNumOk;
                    mdata[i].avgNumVideo =ComFunction.getIntDiveder(mdata[i].numVideo,studentNumOk);
                    mdata[i].avgNumVideoDuration =ComFunction.getIntDiveder(mdata[i].numVideoDuration,studentNumOk);
                    mdata[i].avgNumAnswer =ComFunction.getIntDiveder(mdata[i].numAnswer,studentNumOk);
                    mdata[i].avgNumAnswerItems =ComFunction.getIntDiveder(mdata[i].numAnswerItems,studentNumOk);
                }
                this.setState({
                    data:mdata,
                })
            }
            else
            {
                message.error("日期超限或者其它错误");
            }
            setLoadingState(false);
        }
        )
    }

    research() {
        setTimeout(()=>this.featchPersonalList(), 200);
    }

    componentDidMount()
    {
        this.featchPersonalList();
    }
    render() {
        let columns = [
            {
                title: '#班号#',
                dataIndex: 'classId',
                key: 'classId',
            },
            {
                title: '班级名',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow",
                render:(text,record)=>(
                    <Tooltip title={text}>
                            {text}
                    </Tooltip>
                )
            },
            {
                title: '人数',
                dataIndex: 'studentNumOk',
                key: 'studentNumOk',
                sorter: (a, b) => a.studentNumOk-b.studentNumOk,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '总视频数',
                dataIndex: 'numVideo',
                key: 'numVideo',
                sorter: (a, b) => a.numVideo-b.numVideo,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '平均视频数',
                dataIndex: 'avgNumVideo',
                key: 'avgNumVideo',
                sorter: (a, b) => a.avgNumVideo-b.avgNumVideo,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '总时长(分)',
                dataIndex: 'numVideoDuration',
                key: 'numVideoDuration',
                sorter: (a, b) => a.numVideoDuration-b.numVideoDuration,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '平均时长(分)',
                dataIndex: 'avgNumVideoDuration',
                key: 'avgNumVideoDuration',
                sorter: (a, b) => a.avgNumVideoDuration-b.avgNumVideoDuration,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '总试卷数',
                dataIndex: 'numAnswer',
                key: 'numAnswer',
                sorter: (a, b) => a.numAnswer-b.numAnswer,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '平均卷数',
                dataIndex: 'avgNumAnswer',
                key: 'avgNumAnswer',
                sorter: (a, b) => a.avgNumAnswer-b.avgNumAnswer,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '总做题数',
                dataIndex: 'numAnswerItems',
                key: 'numAnswerItems',
                sorter: (a, b) => a.numAnswerItems-b.numAnswerItems,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '平均做题数',
                dataIndex: 'avgNumAnswerItems',
                key: 'avgNumAnswerItems',
                sorter: (a, b) => a.avgNumAnswerItems-b.avgNumAnswerItems,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title:'历史数据',
                dataIndex: 'descrp',
                key: 'descrp',
                render: (text, record) => (
                  <Button
                   type="primary"
                   onClick={
                       ()=>this.props.history.push(RoutePath.ClassSnapshots + "/" + record.classId)
                   }
                  >
                    历史数据
                  </Button>
              )
          }
        ];
        return (
            <MyLayout>
                <Row>
                    <Col span={24}>
                    <Form layout='inline'>
                        <Form.Item label='选择目标月份'>
                            <SimpleDateSel
                            picker="month"
                            size="default"
                            value={this.state.yearMonth}
                            onChange={
                                (yearMonth)=>{
                                    this.setState({yearMonth: moment(yearMonth).format("YYYY/MM/DD HH:mm:ss")});
                                    this.research();
                                }
                            }
                            />
                        </Form.Item>
                        <Form.Item label="当月还是累积?">
                            <Checkbox
                            onChange={(e)=>{
                                this.setState({acc:e.target.checked});
                                this.research();
                            }}
                            checked={this.state.acc}>
                            累积</Checkbox>
                        </Form.Item>
                    </Form>
                    </Col>
                    </Row>
                    <Table
                        dataSource={this.state.data}
                        columns={columns}
                        rowKey='classId'
                        pagination={false}
                    />
            </MyLayout>
        );
    }
}

export default StaticTotalMonth;


