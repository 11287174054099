import React,{ Component } from "react";

import { Upload, Icon, message } from 'antd';
import {authtoken} from "../api/utils/Request";
import connect from "react-redux/es/connect/connect";

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('你只能上传JPG/PNG类型的图片!');
    }
    return isJpgOrPng;
}

class ImageUpload extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
        };
    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            let fileId = info.file.response.data;
            if(this.props.onChange)
            {
                this.props.onChange(fileId);
            }
            this.setState({ loading: false });
            //getBase64(info.file.originFileObj, imageUrl =>{
            // this.setState({
            //   imageUrl,
            //   loading: false,
            // })},
            //);
        }
    };

    render() {
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">上传</div>
            </div>
        );
        //const imageUrl = this.state.imageUrl;
        let imageUrl = this.props.value;
        return (
            <Upload
                name="upfile"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="/api/authc/upload"
                beforeUpload={beforeUpload}
                onChange={this.handleChange}
                headers={{authtoken}}
            >
                {
                    this.props.userInfo && this.props.userInfo.role == 5
                        ?
                        imageUrl ? <img width={510} height={200} src={imageUrl} alt="upfile" /> : uploadButton
                        :
                        imageUrl ? <img width={100} height={100} src={imageUrl} alt="upfile" /> : uploadButton
                }
            </Upload>
        );
    }
}

//export default ImageUpload;
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default connect(mapStateToProps,null)(ImageUpload);

