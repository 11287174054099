import React, {Component} from 'react';
import MyLayout from '../../common/MyLayout';
import VideoTypeTabSelect from '../../../components/VideoTypeTabSelect';
import {Row, Col, Input, Divider, Pagination, Tooltip, List, Card} from 'antd';
import api from '../../../api/api';
import RoutePath from '../../../config/RoutePath';
import { PlayCircleOutlined } from '@ant-design/icons';
import ComFunction from '../../../api/ComFunction';

const {Search} = Input;
const {Meta} = Card;

class SeriesPub extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keyword: "",
            typeId: null,
            data: [],
        };

        this.initPageSize = 8;

        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        this.totalNum = 10000;
    }

    fetachSeriesList() {


        api.series.pageSerchSeries(
            {
                "condition": {
                    "typeId": this.state.typeId,
                    "keyword": this.state.keyword
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data) => {
                if (state && data.status == 200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        data: data.data,
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.fetachSeriesList();
    }

    pageOnChange(pageNum, pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(() => this.fetachSeriesList(), 200);
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        setTimeout(() => this.fetachSeriesList(), 200,);
    }

    render() {
        let history = this.props.history;
        return (
            <MyLayout>
                <Row>
                    <Col span={6}></Col>
                    <Col span={8}>
                        <Search
                            placeholder="请输入系列关键字"
                            enterButton="搜索"
                            size="large"
                            onSearch={
                                (keyword) => {
                                    this.setState({keyword});
                                    this.research();
                                }
                            }
                        />
                    </Col>
                    <Col span={8}></Col>
                </Row>
                <Divider/>
                <VideoTypeTabSelect
                    onChange={
                        (typeId) => {
                            this.setState({typeId});
                            this.research();
                        }
                    }
                />
                <Divider/>
                <List
                    grid={{gutter: 20, column: 4}}
                    itemLayout="vertical"
                    size="large"
                    dataSource={this.state.data}
                    renderItem={(item, index) => (
                        <List.Item
                            key={index}
                        >
                            <Card hoverable
                                  cover={<img alt="封面图片"
                                              style={{height: "170px"}}
                                              src={item.coverPath}
                                  />
                                  }
                                  onClick={() =>
                                      history.push(RoutePath.SeriesDetail + '/' + item.seriesId)
                                  }
                            >
                                <Meta title={item.title}
                                      style={{height: "100px"}} description={[
                                    <div className="multipleTextOverflow">
                                        <Tooltip title={item.description}>
                                            {item.description}
                                        </Tooltip>
                                    </div>    
                                    ]
                                }/>
                                <Row>
                                    <Col span={16}>
                                    <div className="home-PriceValue">
                                    限时免费
                                    </div>
                                    </Col>
                                    <Col span={8} style={{color:"#b7b7b7",paddingTop:"10px"}}>
                                        <span><PlayCircleOutlined />{ComFunction.getVisitedTimesDisplayText(item.visitedTimes)}</span>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
                <Pagination
                    onChange={(pageNum, pageSize) => this.pageOnChange(
                        pageNum, pageSize
                    )}
                    pageSize={this.pageSize}
                    current={this.pageNum}
                    total={this.totalNum}
                />
            </MyLayout>
        );
    }
}

export default SeriesPub;


