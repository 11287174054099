import React, { Component } from 'react';
import {Col, Row, Button, Descriptions, Modal, Popover} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import NoTimeContent from "../../../components/NoTimeContent";
import moment  from 'moment';
const { confirm } = Modal;

class VideoOrderForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:null,
            endTimeFlg:true //支付是否结束 true:没结束，false:结束了
        };
    }

    getBizOrderById=()=> {
        let outTradeNo = this.props.match.params.outTradeNo;
        api.biz.getBizOrderById({outTradeNo},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    });
                }
            }
        )
    };

    lockTime(){
        if (this.state.data == null) {
            return ;
        }
        let date = this.state.data.createdTime;
        //生成订单后15分钟过期
        let endDate = moment(new Date(date)).add(15, 'minute').format('YYYY/MM/DD HH:mm');

        return endDate
    }

    alipay=()=>{
        let outTradeNo = this.props.match.params.outTradeNo;
        window.open("/api/alipay/alipay?outTradeNo="+ outTradeNo);
        confirm({
            title: '支付确认',
            content: '您的订单是否支付成功?',
            onOk:()=>{
                this.getBizOrderById();
            },
            onCancel:()=> {
                this.getBizOrderById();
            },
        });
    };

    componentDidMount() {
        this.getBizOrderById();
    }

    render() {
        const {data, endTimeFlg} = this.state;
        return(
            <MyLayout>
                <div>
                    <div className="order-time">
                        {
                            data && data.paidTime != null?
                                <span className="order-tips">这个订单已经支付完成。</span>:
                                <div>
                                    {
                                        endTimeFlg?
                                            <span className="order-tips">
                                            请确认您的订单信息，并在15分钟完成付款，如果超时系统将自动取消订单。
                                        </span>:
                                            <span className="order-tips">
                                            这个订单已经超时(结束)，请重新下单
                                        </span>
                                    }
                                    <div className="order-timeContent">
                                        <span className="order-timeTitle">剩余支付时间</span>
                                        <span className="order-timeSurplus">
                                            <NoTimeContent endTime={this.lockTime()}
                                                           timeEnd={(e)=>{
                                                               this.setState({
                                                                   endTimeFlg:false,
                                                               })
                                                           }}
                                            />
                                        </span>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="order-number">订单号： {data!= null?data.outTradeNo:""}</div>
                    <Descriptions layout="vertical" column={6} bordered>
                         <Descriptions.Item key='body' label="订单内容" span={4}>
                            <Popover
                            content={data!= null?data.body:""}
                            >
                            {data!= null?data.body:""}
                            </Popover>
                        </Descriptions.Item>
                        <Descriptions.Item key='status' label="创建时间" span={1}>
                            <div className="order-content">
                                <p className="order-startTime">
                                    {data!= null?data.createdTime.substring(0,16):""}
                                </p>
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item key="name" label="总金额" span={1} >
                            <div className="order-content">
                                <p className="order-startTime">￥ {data!= null ?
                                    (data.totalAmount/100).toFixed(2) :""}元</p>
                            </div>
                        </Descriptions.Item>
                    </Descriptions>

                    <Row>
                        <Col span={24}>
                            <p className="order-money">
                                实付款：￥
                                {data!= null ?
                                    (data.totalAmount/100).toFixed(2) :""}元
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button type="danger"
                                    htmlType="submit"
                                    className="order-submitButton"
                                    disabled={!this.state.endTimeFlg ||(data && data.paidTime != null)}
                                    onClick={()=>this.alipay()}
                                    block>
                                确认订单，立即支付
                            </Button>
                        </Col>
                    </Row>
                </div>
            </MyLayout>
        )
    }
}

export default VideoOrderForm;