import React, { Component } from 'react';
import {Modal, Input, InputNumber, Button, Icon,Form} from 'antd';
import api from '../../../../api/api';
import OrgSelectModal from "../../../../components/OrgSelectModal";
import MyGroundModel from "../../../../components/MyGroundModel";

class AddOrEditVenue extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:[],
            visible: false,
        };
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = (values)=> {
        let id = "";
        if (this.props.tagType == "edit"){
            id = this.props.listData.id;
        }
        api.lecture.addOrEditVenue({id, ...values},
            (state, data)=>{
                if(state && data.status==200) {

                    this.setState({
                        data:data.data,
                        visible: false,
                    });
                    this.props.onChange(state);
                }
            }
        )
    };

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                
                this.handleOk(values);
            }
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                sm: { span: 5 },
            },
            wrapperCol: {
                sm: { span: 18},
            },
        };
        return (
            <div>
                <Button type="primary" onClick={this.showModal}>
                    {
                        this.props.tagType=="add"?"添加会场":"编辑会场"
                    }
                </Button>
                <Modal width={800}
                    title="创建/编辑会场"
                    visible={this.state.visible}
                    onOk={this.handleSubmit}
                    onCancel={this.handleCancel}
                >
                    <Form {...formItemLayout}>
                        <Form.Item label='会场名称'>
                            {getFieldDecorator('name', {
                                initialValue:this.props.listData?this.props.listData.name:"",
                                rules: [
                                    {
                                        validateStatus:'error',
                                        required: true,
                                        message: '请输入一个会场名称!'
                                    },
                                ],
                            })(
                                <Input size="large"
                                       prefix={<Icon type="idcard" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                       placeholder="会场名称"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='会场地址'>
                            {getFieldDecorator('location', {
                                initialValue:this.props.listData?this.props.listData.location:"",
                                rules: [
                                    {
                                        required: true,
                                        message: '请输会场地址!'
                                    }
                                ],
                            })(
                                <Input size="large"
                                       placeholder="会场地址"
                                />
                            )}
                        </Form.Item>
                        <Form.Item label='场地模板'>
                            {getFieldDecorator('modelId', {
                                initialValue:this.props.listData?this.props.listData.model:"",
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择符合您的场地的模板，若不符合请重建模板!'
                                    }
                                ],
                            })(
                                <MyGroundModel />
                            )}
                        </Form.Item>
                        <Form.Item label='会场所属单位'>
                            {getFieldDecorator('orgId', {
                                initialValue:this.props.listData?this.props.listData.org:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '会场单位是必须输入的!'
                                    },
                                ],
                            })(
                                <OrgSelectModal userSetting={"myUser"}/>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}
export default Form.create({ name: 'AddOrEditVenue' })(AddOrEditVenue);