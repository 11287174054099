
import React, { Component } from 'react';
import { Cascader, Tag } from 'antd';
import {connect } from 'react-redux';
import { withRouter } from 'react-router';


class VideoTypeSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    getSubByTypeId(typeId)
    {
        let videoTypes = this.props.videoTypes;
        if(!videoTypes)
        {
            return null;
        }
        let res  = [];
        for(let i = 0; i <videoTypes.length; i++)
        {
            if(videoTypes[i].pid == typeId)
            {
                res.push(videoTypes[i]);
            }
        } 
        return res;
    }

    getNameByTypeId(typeId)
    {
        let videoTypes = this.props.videoTypes;
        if(!videoTypes)
        {
            return "--";
        }
        for(let i = 0; i <videoTypes.length; i++)
        {
            if(videoTypes[i].typeId == typeId)
            {
                return videoTypes[i].name;
            }
        } 
        return "--";
    }

    getParentByTypeId(typeId)
    {
        let videoTypes = this.props.videoTypes;
        if(!videoTypes)
        {
            return null;
        }
        for(let i = 0; i <videoTypes.length; i++)
        {
            if(videoTypes[i].typeId == typeId)
            {
                return videoTypes[i].pid;
            }
        } 
        return null;
    }

    getCascadeTypeidData()
    {
        let root = this.getSubByTypeId(null);
        let datas = [];
        for(let i = 0; i < root.length; i++)
        {
            var data = {
                value:root[i].typeId,
                label:root[i].name,
                children:[],
            }
            var childs = this.getSubByTypeId(root[i].typeId);
            if(childs.length > 0)
            {
                let childrendata = [];
                for(let j = 0; j < childs.length; j++)
                {
                    var datain = {
                        value:childs[j].typeId,
                        label:childs[j].name,
                        children:[],
                    }
                    childrendata.push(datain);
                }
                data.children = childrendata;
                datas.push(data);
            }
        }
        return datas;
    }

    render() {

        let cascadeDefautValue = null;
        let firstName = '--';
        let setcondName = '--';
        if(this.props.value)
        {
            let firstValue = this.getParentByTypeId(this.props.value);
            firstName = this.getNameByTypeId(firstValue);
            setcondName = this.getNameByTypeId(this.props.value);

            cascadeDefautValue = [
            firstValue,
            this.props.value
           ];
       }
        

        return (
            <>
                {
                this.props.display?
                <Tag color="#f50">
                    {firstName}/{setcondName}
                </Tag>
                :
                <Cascader
                    defaultValue={null}
                    value={cascadeDefautValue}
                    onChange={(e)=>{
                        if(this.props.onChange)
                        {
                            this.props.onChange(e[1])
                        }
                    }}
                    disabled={this.props.disabled}
                    bordered={this.props.bordered}
                    options={this.getCascadeTypeidData()}
                    placeholder="请选择系列类别"
                />
                }
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        videoTypes: state.user.videoTypes,
    };
}

export default withRouter(connect(mapStateToProps,null)(VideoTypeSelect));
