
import { Progress, Table } from 'antd';
import React, { Component } from 'react';
import api from '../../api/api';

class MyWholePlayRecord extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            videoListData:[], //播放记录
         };
    }

    playVideoList() {
        let uid = this.props.uid;
        if(!uid)
        {
            return;
        }
        api.video.myPlayVideoList({uid},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        videoListData:data.data,
                    });
                }
            }
        )
    }

    componentDidMount() {
        this.playVideoList();
    }

    render() {
        const {videoListData} = this.state;
        const videoColumns = [
            {
                title: '视频名',
                dataIndex: 'name',
                key: 'name',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 280,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow:'ellipsis',
                            cursor:'pointer'
                        }
                    }
                },
            },
            {
                title: '视频时长(分)',
                dataIndex: 'duration',
                key: 'duration',
            },
            {
                title: '学习时长(分)',
                dataIndex: 'durationLearn',
                key: 'durationLearn',
                render: (text, record) => (
                    <span>{(record.duration*record.percent/100).toFixed(1)}</span>
                )
            },
            {
                title: '完成百分比',
                dataIndex: 'percent',
                key: 'percent',
                render: (text, record) => (
                    <Progress percent={record.percent}/>
                )
            },
            {
                title: '开始学习时间',
                dataIndex: 'startLearnTime',
                key: 'startLearnTime',
                render: (text, record) => (
                    <span>
                        {record.startLearnTime?record.startLearnTime.substring(0,16):'--'}
                    </span>
                )
            },
            {
                title: '完成学习时间',
                dataIndex: 'lastLearnTime',
                key: 'lastLearnTime',
                render: (text, record) => (
                    <span>
                        {record.lastLearnTime?record.lastLearnTime.substring(0,16):'--'}
                    </span>
                )
            },
        ];

        return (
            <div>
                <Table
                    pagination={false}
                    dataSource={videoListData}
                    columns={videoColumns}
                    rowKey='answerId'
                />
            </div>
        );
    }
}

export default MyWholePlayRecord;

