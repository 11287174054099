
import React, { Component } from 'react';
import {Modal, Avatar, Input, List, Pagination, Tooltip, Tag, Row, Col, Button, Divider} from 'antd';
import api from '../../api/api';

class VideoMoalSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskData:[],
            data:[],
            videoKeywords:[],
            teacherName:"",
            visible: false,

            pageNum:1,
            pageSize:5,
            totalNum:9,
        }
    }

    videoList(){ //视频列表
        api.video.videoList(
            {
                "condition": {
                    "lectureDateEnd": "",
                    "lectureDateStart": "",
                    "lectureName": "",
                    "teacherName": this.state.teacherName,
                    "status":null,
                    "videoKeywords": this.state.videoKeywords
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.videoList(), 200);
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:5,
            totalNum:9,
        });
        setTimeout(()=>this.videoList(), 200,);
    }

    isInExcludes(item)
    {
        if(this.props.excludes)
        {
            let excludes = this.props.excludes;
            for(let i = 0; i < excludes.length; i++)
            {
                let exclude = excludes[i];
                if(exclude.vid)
                {
                    if(item.vid)
                    {
                        if(item.vid === exclude.vid)
                        {
                            return true;
                        }
                    }
                    else if(item.videoId)
                    {
                        if(item.videoId === exclude.vid)
                        {
                            return true;
                        }  
                    }
                }
                else if(exclude.videoId)
                {
                    if(item.vid)
                    {
                        if(item.vid === exclude.videoId)
                        {
                            return true;
                        }
                    }
                    else if(item.videoId)
                    {
                        if(item.videoId === exclude.videoId)
                        {
                            return true;
                        }  
                    }                    
                }
            }
        }
        else
        {
            return false;
        }

        return false;
    }

    render() {



        return (
            <>
                <Button type="primary"
                        style={{marginBottom:"18px"}}
                        onClick={()=>{
                            this.videoList();
                            this.setState({
                                visible: true,
                            })
                        }}>
                    {this.props.title}</Button>
                <Modal
                    title="视频选择"
                    width={800}
                    centered={true}
                    visible={this.state.visible}
                    onCancel={()=>{
                        this.setState({
                            visible:false
                        })
                    }}
                    footer={null}
                >
                    <Row gutter={15}>
                        <Col span={7} >
                            <span>
                                <Input defaultValue={this.state.videoKeywords}
                                       placeholder='视频关键字搜索'
                                       onChange={(e)=>this.setState({videoKeywords:[e.target.value]})}
                                />
                            </span>
                        </Col>
                        <Col span={7} >
                            <span>
                                <Input defaultValue={this.state.teacherName}
                                       placeholder='教师名搜索'
                                       onChange={(e)=>this.setState({teacherName:e.target.value})}
                                />
                            </span>
                        </Col>
                        <Col span={3} >
                            <div>
                                <Button type='primary'
                                        onClick={()=>this.research()}>
                                    搜索</Button>
                            </div>
                        </Col>
                    </Row>
                    <Divider dashed/>
                    <List
                        size="large"
                        dataSource={this.state.data}
                        renderItem={item =>
                            <List.Item>
                                <List.Item.Meta
                                    avatar={<Avatar src={item.posterPath} />}
                                    title={
                                        <a className="oneTextOverflow">{item.name}</a>
                                    }
                                    description={
                                        <Row gutter={15}>
                                            <Col span={20}>
                                                <Tooltip title="视频关键字" >
                                                    <Tag color="cyan" style={{marginRight:"20px"}}>
                                                        {item.keywords}
                                                    </Tag>
                                                </Tooltip>
                                                <span >
                                                主讲:{item.teacher?item.teacher.name +"":"无"}
                                                </span>
                                            </Col>
                                            <Col span={4}>
                                                <Button type='primary'
                                                        disabled={this.isInExcludes(item)}
                                                        onClick={()=>{
                                                            if(this.props.onClick)
                                                            {
                                                                if(this.props.onClick(item.vid,item))
                                                                {
                                                                    this.setState({visible:false})
                                                                }
                                                            }                                                    
                                                        }}>
                                                    {this.props.addText?this.props.addText:"添加"}</Button>
                                            </Col>
                                        </Row>
                                    }
                                />
                            </List.Item>}
                    />
                    <div style={{paddingTop:"25px"}}>
                        <Pagination
                            onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.state.pageSize}
                            current={this.state.pageNum}
                            total={this.state.totalNum}
                        />
                    </div>
                </Modal>
            </>
        );
    }
}

export default VideoMoalSearch;



