
import createReducer from './create-reducer';
import api from '../api/api';
import {store} from '../index';
import Action from '../actions/Action';

//User Info
var userInfo = api.cache.userInfo.getSession();

const initialState = {
   userInfo:userInfo, //null 或 userInfo
   isLoading:false,
   sysReady:false,
   viewMode:0,
   videoTypes:[],
   notReadNoticeNum:
   {
      numSys:0,
      numOrg:0,
      numCls:0,
      numDes:0,
   },
};

//每5分钟心跳一次
setInterval(
   ()=>{
      api.anon.exchangeAuthToken(
         null,
         (state,data)=>{
         }
      );
   },1000*60*5
)

function fetchVideoTypes()
{
   api.series.getAllVideoTypes(
      null,
      (status,data)=>
      {
          if(status)
          {
            store.dispatch(
            { 
               type: Action.SAVE_VIDEO_TYPE,
               data: data.data,
            });
          }
      }
  )
}

api.anon.exchangeAuthToken(
   null,
   (s,d)=>{
       api.user.islogin(
         null,
         (st,dt)=>
         {
           if(st && dt.status === 200)
           {
             //已经登陆
             store.dispatch(
             { 
                 type: Action.SAVE_USER_INFO,
                 data: dt.data,
             });
 
             //Ready!
             store.dispatch(
             { 
               type: Action.SYS_REDAY_OK,
             });
           }
           else if(userInfo)
           {
             api.user.tokenLogin(
             {
               token:userInfo.token
             },
             (state,data)=>{
                  
                   //Ready!
                   store.dispatch(
                   { 
                         type: Action.SYS_REDAY_OK,
                   });
 
                   //成功登陆
                   if(state && data.status === 200)
                   {
                     store.dispatch(
                     { 
                         type: Action.SAVE_USER_INFO,
                         data: data.data,
                     });
                   }
               })
 
           }
           else
           {
             store.dispatch(
               { 
                 type: Action.SYS_REDAY_OK,
               });
           }
         }
       );
       fetchVideoTypes();
   }
);

function getMyUserInfo()
{
  api.user.getMyUserInfo(
    null,
    (state,data)=>{
      if(state && data.status == 200)
      {
        store.dispatch(
          { 
            type: Action.SAVE_USER_INFO,
            data: data.data,
        })
      }
  }
  )
}

function setLoadingState(isLoading)
{
   store.dispatch(
      { 
        type: Action.SET_LOADING_STATE,
        data: isLoading,
    }) 
}

function fetchNotReadNum()
{
   api.notice.getNotReadNum(
      null,
      (state,data)=>{
         if(state && data.status == 200)
         {
           store.dispatch(
             { 
               type: Action.SAVE_READ_NUM,
               data: data.data,
           })
         }
     }
   )
}

const actionHandler = {

      [Action.FEATCH_READ_NUM]:(state,action) =>{
         fetchNotReadNum();
         return {}
      },

      [Action.SAVE_READ_NUM]: (state, action) => {
         return {notReadNoticeNum:action.data};
      },

      [Action.CHANGE_VIEW_MODE]: (state, action) => {
         return {viewMode:action.data};
      },

      [Action.SAVE_VIDEO_TYPE]: (state, action) => {
         let videoTypes = action.data;
         return {videoTypes};
      },
      
	  [Action.SAVE_USER_INFO]: (state, action) => {
         let userInfo = action.data;
         api.cache.userInfo.setSession(userInfo);
         if(userInfo && userInfo.role == 0)
         {
            fetchNotReadNum();
         }
         return {userInfo};
	  },

      [Action.RESET_USER_INFO]:(state, action) => {
        api.cache.userInfo.invalidate();
        return {userInfo:null};
      },

      [Action.SET_LOADING_STATE]:(state,action) =>{
       return {isLoading:action.data}
      },

      [Action.SYS_REDAY_OK]:(state,action) =>{
         return {sysReady:true}
      },

      [Action.GET_USER_INFO]:(state,action) =>{
         getMyUserInfo();
         return {}
      },
};

export {setLoadingState};
export default createReducer(initialState, actionHandler);

