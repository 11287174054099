
import React, { Component } from 'react';
import {Row, Col, Tooltip, List, Card,Divider, Progress, Button, Input, Pagination, Table} from 'antd';
import api from "../../api/api";

class MyPagePlayRecord extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            videoListData:[], //播放记录
            keyword:null,
         };

         this.initPageSize = 8;
         this.pageNum = 1;
 
         this.pageSize = this.initPageSize;
         this.totalNum = this.initPageSize;
    }

    pageOnChange(pageNum, pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(() => this.updateData(), 200);
    }

    updateData() {
        api.video.pageMyPlayVideoList(
            {
                "condition": {
                    "keyword":this.state.keyword
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data) => {
                if (state && data.status == 200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        videoListData: data.data,
                    })
                }
            }
        )
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        setTimeout(() => this.updateData(), 200,);
    }

    componentDidMount() {
        this.updateData();
    }

    render() {
        const {videoListData} = this.state;
        const videoColumns = [
            {
                title: '视频名',
                dataIndex: 'name',
                key: 'name',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 280,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow:'ellipsis',
                            cursor:'pointer'
                        }
                    }
                },
            },
            {
                title: '视频时长(分)',
                dataIndex: 'duration',
                key: 'duration',
            },
            {
                title: '学习时长(分)',
                dataIndex: 'durationLearn',
                key: 'durationLearn',
                render: (text, record) => (
                    <span>{(record.duration*record.percent/100).toFixed(1)}</span>
                )
            },
            {
                title: '完成百分比',
                dataIndex: 'percent',
                key: 'percent',
                render: (text, record) => (
                    <Progress percent={record.percent}/>
                )
            },
            {
                title: '开始学习时间',
                dataIndex: 'startLearnTime',
                key: 'startLearnTime',
                render: (text, record) => (
                    <span>
                        {record.startLearnTime?record.startLearnTime.substring(0,16):'--'}
                    </span>
                )
            },
            {
                title: '完成学习时间',
                dataIndex: 'lastLearnTime',
                key: 'lastLearnTime',
                render: (text, record) => (
                    <span>
                        {record.lastLearnTime?record.lastLearnTime.substring(0,16):'--'}
                    </span>
                )
            },
        ];
        return (
            <div>
                    <Table
                            pagination={false}
                            dataSource={videoListData}
                            columns={videoColumns}
                            rowKey='answerId'
                        />
                        <Divider/>
                        <Pagination
                    onChange={(pageNum, pageSize) => this.pageOnChange(
                        pageNum, pageSize
                    )}
                    pageSize={this.pageSize}
                    current={this.pageNum}
                    total={this.totalNum}
                />
            </div>
        );
    }
}

export default MyPagePlayRecord;


