/* eslint-disable jsx-a11y/alt-text */

import React, { Component } from 'react';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

class Certification extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Typography>
                <Title level={2}>实名认证</Title>
                <Paragraph>
                    本文将会指导用户如何进行实名认证。
                </Paragraph>
                <Paragraph>
                    成功登录后，用户点击右上角的个人中心，选中下拉菜单的第一个选项【个人信息】，点击左侧导航栏中的【实名认证】后，可以看到申请实名认证的页面。
                </Paragraph>
                <Paragraph>
                    本网站实名认证的的流程分为三个步骤：
                </Paragraph>
                <Paragraph>
                    <ul>
                        <li>
                            材料提交
                        </li>
                        <li>
                            进行审核
                        </li>
                        <li>
                            审核完成
                        </li>
                    </ul>
                </Paragraph>
                <Title level={4}>提交材料</Title>
                <Paragraph>
                    在提交材料阶段用户需上传本人的身份证正面照，上传方法为：在实名认证栏目的右侧点击正中央的【上传】按钮，在弹出的窗口中选择相应的正面照图片后，点击下方的【实名认证】按钮，即可完成材料的提交。
                </Paragraph>
                <Paragraph>
                    用户提交材料后，本网站会进行审核。审核通过后，用户的实名认证状态会变成步骤三【审核完成】。
                </Paragraph>
            </Typography>
        );
    }
}

export default Certification;