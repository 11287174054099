
import React, { Component } from 'react';
import MyLayout from "../common/MyLayout";
import { Form, Input, Button, message, Modal } from 'antd';
import api from '../../api/api';
import SimpleDateShowTime from '../../components/SimpleDateShowTime';
import { setLoadingState } from '../../reducers/UserInfo';

const { TextArea } = Input;

class DummyData extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         };
    }


    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {

                let pps = values.pps.split(",");
                setLoadingState(true);
                api.dummy.taskdummy({
                    ppwd:values.ppwd,
                    pps:pps,
                    startDate:values.startDate,
                    endDate:values.endDate
                },
                (state,data)=>{
                    setLoadingState(false);
                    if(state && data.status === 200)
                    {
                        Modal.confirm({
                            title:"恭喜",
                            content:<>
                                {
                                    data.data.map((item)=><div>{item}</div>)
                                }
                            </>
                        })
                    }
                    else
                    {
                        message.error(data.msg);
                    }
                }
                )
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <MyLayout title="测试数据">
                <Form  onSubmit={this.handleSubmit}>
                <Form.Item label="ppwd">
                                {getFieldDecorator('ppwd', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'ppwd必须输入!'
                                        }
                                    ],
                                })(
                                    <Input size="large"
                                        placeholder="ppwd"
                                    />,
                                )}
                 </Form.Item>
                 <Form.Item label="pps">
                        {getFieldDecorator('pps', {
                            rules: [
                                {
                                    required: true,
                                    message: 'pps必须输入!'
                                }
                            ],
                        })(
                            <TextArea size="large"
                                placeholder="pps"
                            />,
                        )}
                 </Form.Item>
                 <Form.Item label='开始时间'>
                                {getFieldDecorator('startDate', {
                                    rules: [
                                        {
                                            required: true,
                                            validateStatus:'error',
                                            message: '任务开始日期是必填的!'
                                        },
                                    ],
                                })(
                                    <SimpleDateShowTime placeholder={"开始日期"} selectAllDays={true}/>,
                                )}
                            </Form.Item>
                            <Form.Item label='结束时间'>
                                {getFieldDecorator('endDate', {
                                    rules: [
                                        {
                                            required: true,
                                            validateStatus:'error',
                                            message: '任务结束日期是必填的!'
                                        },
                                    ],
                                })(
                                    <SimpleDateShowTime placeholder={"结束日期"} selectAllDays={true}/>,
                                )}
                            </Form.Item>
                 <Form.Item>
                        <Button size="large" type="primary" htmlType="submit" block>
                            提交
                        </Button>
                 </Form.Item>
                </Form>
            </MyLayout>
        );
    }
}

export default Form.create()(DummyData);


