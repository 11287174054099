import React from 'react'
import {HashRouter, Route} from 'react-router-dom'
import RoutePath from '../config/RoutePath'
import AddOrEditQuestion from './taskadmin/testPaper/AddOrEditQuestion'
import AccountClosePay from './admin/account/AccountClosePay'
/* Admin 路由添加 */
import Admin from './admin/Admin'
import AddOrEditAdvertise from './admin/advertise/AddOrEditAdvertise'
import AdvertiseList from './admin/advertise/AdvertiseList'
import DummyData from './admin/DummyData'
import AddOrEditStar from './admin/famousTeacher/AddOrEditStar'
import AdminTeacherDetail from './admin/famousTeacher/AdminTeacherDetail'
import StarDetail from './admin/famousTeacher/StarDetail'
import StarList from './admin/famousTeacher/StarList'
import FieldList from './admin/field/FieldList'
import paperExamList from './admin/paperExam/paperExamList'
import ExamineGround from './admin/pendingGround/ExamineGround'
import pendingLectureList from './admin/PendingLecture/pendingLectureList'
import PendingOrgList from './admin/pendingOrg/PendingOrgList'
import PendingTchList from './admin/PendingTeacher/PendingTchList'
import PendingVenueList from './admin/PendingVenue/PendingVenueList'
import PendingVideoList from './admin/pendingVideo/PendingVideoList'
import SeriesExam from './admin/series/SeriesExam'
import SystemStatics from './admin/sysstatic/AdminStatics'
import UserManage from './admin/UserManage'
import VideoTypeAdmin from './admin/videotype/VideoTypeAdmin'
import EditCertList from './cert/EditCertList'
import CertificateQuery from './common/CertificateQuery'
/* company 路由添加 */
import CompanyHome from './company/CompanyHome'
import AddApplyOrg from './company/infoManage/organization/AddApplyOrg'
import MyOrgList from './company/infoManage/organization/MyOrgList'
import OrgAdmin from './company/infoManage/organization/OrgAdmin'
import OrgDetail from './company/infoManage/organization/OrgDetail'
import SeriesDetail from './company/infoManage/series/SeriesDetail'
import SeriesList from './company/infoManage/series/SeriesList'
import AddOrEditTeacher from './company/infoManage/teacherInfo/AddOrEditTeacher'
import TeacherDetail from './company/infoManage/teacherInfo/TeacherDetail'
import TeacherList from './company/infoManage/teacherInfo/TeacherList'
import AddOrEditVideo from './company/infoManage/video/AddOrEditVideo'
import videoDetail from './company/infoManage/video/videoDetail'
import VideoList from './company/infoManage/video/VideoList'
import GroundDetail from './company/lectureManage/ground/GroundDetail'
import MyGroundModelList from './company/lectureManage/ground/MyGroundModelList'
import AddOrEditLecture from './company/lectureManage/lecture/AddOrEditLecture'
import LectureDetail from './company/lectureManage/lecture/LectureDetail'
import LectureList from './company/lectureManage/lecture/LectureList'
import AddOrEditScene from './company/lectureManage/scene/AddOrEditScene'
import SceneOrders from './company/lectureManage/scene/CheckSceneOrders'
import SceneDetail from './company/lectureManage/scene/SceneDetail'
import AddOrEditVenue from './company/lectureManage/venue/AddOrEditVenue'
import MyVenueList from './company/lectureManage/venue/MyVenueList'
import VenueDetail from './company/lectureManage/venue/VenueDetail'
import HelpIndex from './help/HelpIndex'
import CommentsAuditing from './news/comment/CommentsAuditing'
import AddOrEditNews from './news/newsedit/AddOrEditNews'
/* news 路由添加 */
import NewsManagement from './news/newsedit/NewsManagement'
import NewsHistory from './news/newshistory/NewsHistory'
import NewsDetail from './news/newsInformation/NewsDetail'
import NewsInformation from './news/newsInformation/NewsInformation'
import PaymentFail from './PaymentFail'
import PaymentSuccess from './PaymentSuccess'
import AccountInfo from './personal/AccountInfo'
/* Personal 路由添加  */
import Home from './personal/Home'
import LectureSceneDetail from './personal/lecture/LectureSceneDetail'
import moreLectureList from './personal/lecture/moreLectureList'
import MyBizOrderLis from './personal/lecture/MyBizOrderLis'
import OrderForm from './personal/lecture/OrderForm'
import SeatOrder from './personal/lecture/SeatOrder'
import OrgDetailsPersonal from './personal/organization/OrgDetailsPersonal'
import GetMyJoinedClasses from './personal/student/GetMyJoinedClasses'
import MyAnswerPaperList from './personal/student/MyAnswerPaperList'
import MyPartinTaskList from './personal/student/MyPartinTaskList'
import StudentTaskDetail from './personal/student/StudentTaskDetail'
import FamousTeacherDetail from './personal/Teacher/FamousTeacherDetail'
import famousTeacherList from './personal/Teacher/famousTeacherList'
import moreVideoList from './personal/video/moreVideoList'
import myBuyVideoList from './personal/video/myBuyVideoList'
import myPlayVideoList from './personal/video/myPlayVideoList'
import MyVideoOrderList from './personal/video/MyVideoOrderList'
import StudyProgressDetail from './personal/video/StudyProgressDetail'
import VideoDetails from './personal/video/VideoDetails'
import VideoOrderForm from './personal/video/VideoOrderForm'
import VideoPlay from './personal/video/VideoPlay'
import PlateformConnect from './plateform/PlateformConnect'
import OrgList from './pub/com/OrgList'
import NoticeList from './pub/notice/NoticeList'
import NoticePub from './pub/notice/NoticePub'
import NoticeUserAdmin from './pub/notice/NoticeUserAdmin'
import SeriesPub from './pub/series/SeriesPub'
import NewSginUp from './pub/signup/NewSginUp'
import PageSearchSignup from './pub/signup/PageSearchSignup'
import GetFollowmeTopicList from './qa/GetFollowmeTopicList'
/* qa 路由添加 */
import GetMyCreatedTopicList from './qa/GetMyCreatedTopicList'
import GetQaDetail from './qa/GetQaDetail'
import getClassStaffs from './school/class/getClassStaffs'
import SearchClass from './school/class/SearchClass'
/* school 路由添加 */
import GetOrgStaffs from './school/staff/GetOrgStaffs'
import GetOrgStaticsList from './statistic/charts/GetOrgStaticsList'
import FetchPersonalStatics from './statistic/FetchPersonalStatics'
import StaticExamWhole from './statistic/StaticExamWhole'
import StaticPaper from './statistic/StaticPaper'
import StaticPersonalList from './statistic/StaticPersonalList'
import StaticVideo from './statistic/StaticVideo'
import AnswerDetail from './taskadmin/answer/AnswerDetail'
import SearchExamPaper from './taskadmin/answer/SearchExamPaper'
import GetMyCreatedTasks from './taskadmin/tasksManage/GetMyCreatedTasks'
import TaskDetail from './taskadmin/tasksManage/TaskDetail'
import JudgePaper from './taskadmin/testPaper/JudgePaper'
import MyCreatePaperList from './taskadmin/testPaper/MyCreatePaperList'
/*** taskadmin ***/
import GetMyOcuppyClasses from './taskadmin/toExamine/GetMyOcuppyClasses'
import GetStudentsList from './taskadmin/toExamine/GetStudentsList'
/* Common 路由添加  */
import Login from './user/Login'
import Register from './user/Register'
import BankAccount from './user/userSetting/BankAccount'
import numberBinding from './user/userSetting/numberBinding'
import PlateFormInfo from './user/userSetting/plateform/PlateFormInfo'
import PlateformValidate from './user/userSetting/plateform/PlateformValidate'
import RealNameAuth from './user/userSetting/RealNameAuth'
import UserInfo from './user/userSetting/UserInfo'
import UserSetting from './user/userSetting/UserSetting'

import PersonalSnapshots from './statistic/charts/PersonalSnapshots'
import StaticMonthLearn from './statistic/StaticMonthLearn'
import StaticTotalMonth from './statistic/StaticTotalMonth'
import ClassSnapshots from './statistic/charts/ClassSnapshots'
import StaticRangeList from './statistic/StaticRangeList'
import ExportSetting from './taskadmin/toExamine/ExportSetting'
import EItemSetting from './taskadmin/toExamine/EItemSetting'


const Root = () => (
    <HashRouter>
        <Route exact path="/" component={Home} />

        {/*** Common  ***/}
        <Route exact path={'/login'} component={Login} />
        <Route exact path={'/register'} component={Register} />
        <Route exact path={'/userSetting'} component={UserSetting} />
        <Route exact path={'/userInfo'} component={UserInfo} />
        <Route exact path={'/numberBinding'} component={numberBinding} />
        <Route exact path={'/PaymentFail'} component={PaymentFail} />
        <Route exact path={'/PaymentSuccess'} component={PaymentSuccess} />
        <Route exact path={'/CertificateQuery'} component={CertificateQuery} />
        <Route
            exact
            path={RoutePath.PlateformValidate}
            component={PlateformValidate}
        />
        <Route exact path={RoutePath.PlateFormInfo} component={PlateFormInfo} />

        {/*** Personal ***/}
        <Route exact path={'/home'} component={Home} />
        <Route
            exact
            path={'/Personal/VideoDetail' + '/:videoId'}
            component={VideoDetails}
        />
        <Route 
            exact
            path={RoutePath.ExportSetting + "/:classId"}
            component={ExportSetting}
        />
        <Route 
            exact 
            path={RoutePath.EItemSetting + "/:classAchiveId"} 
            component={EItemSetting}
        />
        <Route
            exact
            path={'/Personal/VideoPlay' + '/:videoId'}
            component={VideoPlay}
        />
        <Route
            exact
            path={'/Personal/moreVideoList'}
            component={moreVideoList}
        />
        <Route
            exact
            path={'/Personal/LectureSceneDetail' + '/:lid'}
            component={LectureSceneDetail}
        />
        <Route
            exact
            path={'/Personal/SeatOrder' + '/:sceneId'}
            component={SeatOrder}
        />
        <Route
            exact
            path={'/Personal/OrderForm' + '/:outTradeNo'}
            component={OrderForm}
        />
        <Route
            exact
            path={'/Personal/MyBizOrderLis'}
            component={MyBizOrderLis}
        />
        <Route
            exact
            path={'/famousTeacherList'}
            component={famousTeacherList}
        />
        <Route
            exact
            path={'/Personal/FamousTeacherDetail' + '/:teacherId'}
            component={FamousTeacherDetail}
        />
        <Route
            exact
            path={'/Personal/moreLectureList'}
            component={moreLectureList}
        />
        <Route
            exact
            path={'/Personal/OrgDetailsPersonal' + '/:orgId'}
            component={OrgDetailsPersonal}
        />
        <Route
            exact
            path={'/SceneOrders' + '/:sceneId'}
            component={SceneOrders}
        />
        <Route
            exact
            path={'/Personal/VideoOrderForm' + '/:outTradeNo'}
            component={VideoOrderForm}
        />
        <Route
            exact
            path={'/Personal/MyVideoOrderList'}
            component={MyVideoOrderList}
        />
        <Route
            exact
            path={'/Personal/myBuyVideoList'}
            component={myBuyVideoList}
        />
        <Route
            exact
            path={'/Personal/myPlayVideoList'}
            component={myPlayVideoList}
        />
        <Route
            exact
            path={'/Personal/StudyProgressDetail' + '/:videoId'}
            component={StudyProgressDetail}
        />
        <Route
            exact
            path={'/Personal/student/GetMyJoinedClasses'}
            component={GetMyJoinedClasses}
        />
        <Route
            exact
            path={'/Personal/student/MyPartinTaskList'}
            component={MyPartinTaskList}
        />
        <Route
            exact
            path={'/Personal/student/StudentTaskDetail' + '/:mtaskId'}
            component={StudentTaskDetail}
        />

        {/*** company ***/}
        <Route exact path={'/CompanyHome'} component={CompanyHome} />
        <Route exact path={'/company/TeacherList'} component={TeacherList} />
        <Route
            exact
            path={'/company/AddOrEditTeacher' + '/:teacherId'}
            component={AddOrEditTeacher}
        />
        <Route
            exact
            path={'/company/TeacherDetail' + '/:teacherId'}
            component={TeacherDetail}
        />
        <Route exact path={'/company/VideoList'} component={VideoList} />
        <Route
            exact
            path={'/videoDetail' + '/:videoId'}
            component={videoDetail}
        />
        <Route
            exact
            path={'/AddOrEditVideo' + '/:videoId'}
            component={AddOrEditVideo}
        />
        <Route exact path={'/company/MyOrgList'} component={MyOrgList} />
        <Route
            exact
            path={'/company/AddApplyOrg' + '/:orgId'}
            component={AddApplyOrg}
        />
        <Route
            exact
            path={'/company/OrgDetail' + '/:orgId'}
            component={OrgDetail}
        />
        <Route
            exact
            path={'/company/MyGroundModelList'}
            component={MyGroundModelList}
        />
        <Route
            exact
            path={'/company/GroundDetail' + '/:modelId'}
            component={GroundDetail}
        />
        <Route exact path={'/company/LectureList'} component={LectureList} />
        <Route
            exact
            path={'/company/AddOrEditLecture' + '/:lid'}
            component={AddOrEditLecture}
        />
        <Route
            exact
            path={'/company/LectureDetail' + '/:lid'}
            component={LectureDetail}
        />
        <Route exact path={'/company/MyVenueList'} component={MyVenueList} />
        <Route
            exact
            path={'/company/AddOrEditVenue'}
            component={AddOrEditVenue}
        />
        <Route
            exact
            path={'/company/VenueDetail' + '/:venueId'}
            component={VenueDetail}
        />
        <Route
            exact
            path={'/company/AddOrEditScene' + '/:lid' + '/:sceneId'}
            component={AddOrEditScene}
        />
        <Route
            exact
            path={'/company/SceneDetail' + '/:lid' + '/:sceneId'}
            component={SceneDetail}
        />
        <Route
            exact
            path={'/company/moreVideoList'}
            component={moreVideoList}
        />
        <Route
            exact
            path={'/company/moreLectureList'}
            component={moreLectureList}
        />
        <Route exact path={RoutePath.SeriesList} component={SeriesList} />
        <Route
            exact
            path={RoutePath.SeriesDetail + '/:seriesId'}
            component={SeriesDetail}
        />
        <Route exact path={RoutePath.OrgList} component={OrgList} />

        {/*** staff ***/}
        <Route
            exact
            path={'/staff/GetOrgStaffs' + '/:orgId'}
            component={GetOrgStaffs}
        />
        <Route
            exact
            path={'/class/SearchClass' + '/:orgId'}
            component={SearchClass}
        />
        <Route
            exact
            path={'/class/getClassStaffs' + '/:classId' + '/:orgId'}
            component={getClassStaffs}
        />

        {/*** taskadmin ***/}
        <Route
            exact
            path={'/taskadmin/GetMyOcuppyClasses'}
            component={GetMyOcuppyClasses}
        />
        <Route
            exact
            path={'/taskadmin/GetStudentsList' + '/:classId'}
            component={GetStudentsList}
        />
        <Route
            exact
            path={'/taskadmin/GetMyCreatedTasks'}
            component={GetMyCreatedTasks}
        />
        <Route
            exact
            path={'/taskadmin/TaskDetail' + '/:mtaskId'}
            component={TaskDetail}
        />

        {/*** exam ***/}
        <Route
            exact
            path={'/taskadmin/MyCreatePaperList'}
            component={MyCreatePaperList}
        />
        <Route
            exact
            path={'/taskadmin/AddOrEditQuestion' + '/:paperId'}
            component={AddOrEditQuestion}
        />
        <Route
            exact
            path={'/taskadmin/AnswerDetail' + '/:paperId'}
            component={AnswerDetail}
        />
        <Route
            exact
            path={'/taskadmin/SearchExamPaper'}
            component={SearchExamPaper}
        />

        {/*** qa ***/}
        <Route
            exact
            path={'/qa/GetMyCreatedTopicList'}
            component={GetMyCreatedTopicList}
        />
        <Route
            exact
            path={RoutePath.GetQaDetail + '/:topicId'}
            component={GetQaDetail}
        />
        <Route
            exact
            path={RoutePath.GetFollowmeTopicList}
            component={GetFollowmeTopicList}
        />

        {/*** admin ***/}
        <Route exact path={'/admin'} component={Admin} />
        <Route
            exact
            path={'/admin/PendingOrgList'}
            component={PendingOrgList}
        />
        <Route exact path={'/admin/FieldList'} component={FieldList} />
        <Route exact path={'/admin/UserManage'} component={UserManage} />
        <Route
            exact
            path={'/admin/PendingVideoList'}
            component={PendingVideoList}
        />
        <Route
            exact
            path={'/admin/PendingTchList'}
            component={PendingTchList}
        />
        <Route exact path={'/admin/StarList'} component={StarList} />
        <Route
            exact
            path={'/admin/AddOrEditStar' + '/:id'}
            component={AddOrEditStar}
        />
        <Route
            exact
            path={'/admin/StarDetail' + '/:starId'}
            component={StarDetail}
        />
        <Route
            exact
            path={'/admin/AdminTeacherDetail' + '/:teacherId'}
            component={AdminTeacherDetail}
        />
        <Route exact path={'/admin/ExamineGround'} component={ExamineGround} />
        <Route
            exact
            path={'/admin/PendingVenueList'}
            component={PendingVenueList}
        />
        <Route
            exact
            path={'/admin/pendingLectureList'}
            component={pendingLectureList}
        />
        <Route
            exact
            path={'/admin/AddOrEditAdvertise' + '/:linkId'}
            component={AddOrEditAdvertise}
        />
        <Route exact path={'/admin/AdvertiseList'} component={AdvertiseList} />
        <Route
            exact
            path={RoutePath.VideoTypeAdmin}
            component={VideoTypeAdmin}
        />
        <Route exact path={RoutePath.SeriesExam} component={SeriesExam} />
        <Route exact path={RoutePath.SeriesPub} component={SeriesPub} />
        <Route
            exact
            path={RoutePath.NoticePub + '/:noticeType' + '/:itemId?'}
            component={NoticePub}
        />
        <Route exact path={RoutePath.NoticeList} component={NoticeList} />
        <Route exact path={RoutePath.NewSginUp} component={NewSginUp} />
        <Route exact path={RoutePath.RealNameAuth} component={RealNameAuth} />
        <Route
            exact
            path={RoutePath.StaticVideo + '/:orgId?'}
            component={StaticVideo}
        />
        <Route exact path={RoutePath.paperExamList} component={paperExamList} />
        <Route
            exact
            path={RoutePath.StaticPaper + '/:orgId?'}
            component={StaticPaper}
        />
        <Route
            exact
            path={RoutePath.JudgePaper + '/:uid/:paperId'}
            component={JudgePaper}
        />
        <Route
            exact
            path={RoutePath.MyAnswerPaperList}
            component={MyAnswerPaperList}
        />
        <Route
            exact
            path={RoutePath.OrgAdmin + '/:orgId'}
            component={OrgAdmin}
        />
        <Route
            exact
            path={RoutePath.NoticeUserAdmin + '/:noticeId' + '/:orgId?'}
            component={NoticeUserAdmin}
        />
        <Route exact path={RoutePath.DummyData} component={DummyData} />
        <Route
            exact
            path={RoutePath.PageSearchSignup + '/:orgId?'}
            component={PageSearchSignup}
        />
        <Route exact path={RoutePath.SystemStatics} component={SystemStatics} />

        {/*** statics ***/}
        <Route
            exact
            path={RoutePath.StaticPersonalList + '/:orgId?'}
            component={StaticPersonalList}
        />
        <Route
            exact
            path={RoutePath.FetchPersonalStatics + '/:uid'}
            component={FetchPersonalStatics}
        />
        <Route
            exact
            path={RoutePath.GetOrgStaticsList + '/:orgId'}
            component={GetOrgStaticsList}
        />
        <Route
            exact
            path={RoutePath.StaticExamWhole}
            component={StaticExamWhole}
        />

        {/**connect */}
        <Route
            exact
            path={RoutePath.PlateformConnect}
            component={PlateformConnect}
        />

        {/**help */}
        <Route exact path={RoutePath.HelpIndex} component={HelpIndex} />

        {/**news */}
        <Route
            exact
            path={RoutePath.NewsManagement}
            component={NewsManagement}
        />
        <Route
            exact
            path={RoutePath.AddOrEditNews + '/:newsId'}
            component={AddOrEditNews}
        />
        <Route
            exact
            path={RoutePath.NewsInformation}
            component={NewsInformation}
        />
        <Route
            exact
            path={RoutePath.NewsDetail + '/:newsId'}
            component={NewsDetail}
        />
        <Route
            exact
            path={RoutePath.CommentsAuditing}
            component={CommentsAuditing}
        />
        <Route exact path={RoutePath.BankAccount} component={BankAccount} />
        <Route
            exact
            path={RoutePath.AccountInfo + '/:uid'}
            component={AccountInfo}
        />
        <Route
            exact
            path={RoutePath.AccountClosePay}
            component={AccountClosePay}
        />
        <Route exact path={RoutePath.NewsHistory} component={NewsHistory} />

        <Route
            exact
            path={RoutePath.PersonalSnapshots + '/:uid'}
            component={PersonalSnapshots}
        />
        <Route exact path={RoutePath.EditCertList} component={EditCertList}/>
        <Route
            exact
            path={RoutePath.StaticMonthLearn + '/:orgId?'}
            component={StaticMonthLearn}
        />
        <Route
            exact
            path={RoutePath.StaticTotalMonth + '/:orgId?'}
            component={StaticTotalMonth}
        />
        <Route
            exact
            path={RoutePath.ClassSnapshots + '/:classId'}
            component={ClassSnapshots}
        />
        <Route
            exact
            path={RoutePath.StaticRangeList + '/:orgId?'}
            component={StaticRangeList}
        />
    </HashRouter>
)

export default Root
