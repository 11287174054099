import React, { Component } from 'react';
import {Input, Tabs, Modal, message, Badge, Button, Divider} from 'antd';
import '../../../assets/style/common.css';
import AnserPageModal from "./AnserPageModal";

const { TabPane } = Tabs;

class AddOrEditAnser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:null,
            activeKey:"0",
            ansContent:"",
        };
    }

    onChange(data){
        let questionData = this.props.questionData;
        if (data.state){

            if(this.props.onChange)
            {
                this.props.onChange();
            }

            if (questionData.length > data.activeKey) {
                this.setState({
                    activeKey:data.activeKey.toString(),
                });
            }else{
                Modal.success({
                    title: '提示',
                    content: '该类题目已经是最后一题,请选择其它类型题目!',
                });
            }
        }
    }

    answerBadgeShow=(anserItem)=>{
        let answerDetail = this.props.answerDetail;
        let badgeShow = true;
        if (answerDetail){
            for (let i = 0; i < answerDetail.examAnsitems.length; i++) {
                if (answerDetail.examAnsitems[i].questionId === anserItem.questionId) {
                    badgeShow = false;
                    break;
                }
            }
        }
        return badgeShow;
    };

    render() {
        let questionData = this.props.questionData;
        return(
            <div>
                {questionData.length>0?
                    <Tabs defaultActiveKey={this.state.activeKey}
                          activeKey={this.state.activeKey}
                          tabPosition="top"
                          onChange={(activeKey)=>{
                              this.setState({activeKey});
                          }}>
                        {questionData.map((val,index) => (
                            <TabPane tab={
                                    <Badge
                                    status={this.answerBadgeShow(val)?"error":"success"} 
                                    dot={this.answerBadgeShow(val)}>
                                    {`第${index+1}题(${val.examQuestion.itemScore}分)`}
                                    </Badge>} 
                                    key={index}>
                                <div className="examQuestion">
                                    <div dangerouslySetInnerHTML={{__html:val.examQuestion.content}}></div>
                                </div>
                                <div>
                                    <AnserPageModal anserItem={val}  // 每一道题
                                                    questionType={this.props.questionType} // 类型
                                                    //answerDetail={this.state.data} // 答过的题
                                                    examType={this.props.examType}
                                                    answerDetail={this.props.answerDetail}
                                                    onChange={(success)=>this.onChange(success)} // 提交答案后返回的结果
                                                    activeKey={this.state.activeKey}/>
                                </div>

                            </TabPane>
                        ))}
                    </Tabs>:
                    <div>还没有添加该类型试题!</div>
                }
            </div>
        )
    }
}
export default AddOrEditAnser;