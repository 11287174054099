import React, { Component } from 'react';
import {Row, Col, message, Form, Icon, Rate, Input, Button} from 'antd';
import api from '../../../../api/api';
import ImageUpload from '../../../../components/ImageUpload';
import MyLayout from "../../../common/MyLayout";
import OrgSelectModal from "../../../../components/OrgSelectModal";
import SimpleDateShowTime from "../../../../components/SimpleDateShowTime";

const { TextArea } = Input;

class AddOrEditLecture extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lectureData:null,
            startTime:"",
        };
    }

    addOrEditLecture(values){
        let lid = this.achieveParams();
        api.lecture.addOrEditLecture(
            {lid, ...values},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.props.history.push('/company/LectureList');
                } else {
                    message.error(data.msg);
                }
            }
        )
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.addOrEditLecture(values);
            }
        });
    };


    lectureDetail(lectureId) {
        api.lecture.lectureDetail({lectureId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        lectureData:data.data,
                    })
                }
            }
        )
    }

    achieveParams(){
        let {lid} = this.props.match.params;
        if (lid && lid =="addLecture"){
            lid = "";
        }
        return lid;
    }

    componentDidMount() {
        if (this.achieveParams() && this.achieveParams() !="" ){
            this.lectureDetail(this.achieveParams());
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            lectureData= null
        } = this.state;

        const formItemLayout = {
            labelCol: {
                sm: { span: 8 },
            },
            wrapperCol: {
                sm: { span: 10},
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                sm: {
                    span: 10,
                    offset: 8,
                },
            },
        };
        return(
            <MyLayout>
                <div style={{marginTop:'60px'}}>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit} >
                        <Form.Item label='讲座名' >
                            {getFieldDecorator('name', {
                                initialValue:lectureData?lectureData.name:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '讲座名是必须输入的!'
                                    },
                                ],
                            })(
                                <Input size="large"
                                       placeholder="讲座名"
                                />,
                            )}
                        </Form.Item>
                        <Row>
                            <Col span={6} offset={6}>
                                <Form.Item label='讲座日期'>
                                    {getFieldDecorator('startDate', {
                                        initialValue:lectureData?lectureData.startDate:null,
                                        rules: [
                                            {
                                                required: false,
                                                message: '讲座开始日期是必填的!'
                                            },
                                        ],
                                    })(
                                        <SimpleDateShowTime placeholder={"开始日期"}
                                            onChange={(e)=>{
                                                this.setState({
                                                    startTime:e
                                                })
                                            }}
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item>
                                    {getFieldDecorator('endDate', {
                                        initialValue:lectureData?lectureData.endDate:null,
                                        rules: [
                                            {
                                                required: false,
                                                message: '讲座结束日期是必填的!'
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if (this.state.startTime =="" && lectureData.startDate) {
                                                        if (new Date(value) > new Date(lectureData.startDate)) {
                                                            callback();
                                                            return;
                                                        }
                                                    }
                                                    else if(new Date(value) > new Date(this.state.startTime)){
                                                        callback();
                                                        return;
                                                    }
                                                    callback('error');
                                                },
                                                message: '结束时间不能大于开始时间',
                                            }
                                        ],
                                    })(
                                        <SimpleDateShowTime placeholder={"结束日期"}/>,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label='主办方'>
                            {getFieldDecorator('oid', {
                                initialValue:lectureData?lectureData.org:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '主办方是必须输入的!'
                                    },
                                ],
                            })(
                                <OrgSelectModal userSetting={"myUser"}/>
                            )}
                        </Form.Item>
                        {/*
                        <Form.Item label='讲师名(多个,分隔)'>
                            {getFieldDecorator('teachers', {
                                initialValue:lectureData?lectureData.teachers:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '讲师名是必须输入的!'
                                    },
                                ],
                            })(
                                <Input size="large"
                                       placeholder="讲师名(例:张三,李四,王五)"
                                />,
                            )}
                        </Form.Item>
                        */}
                        <Form.Item label='宣传海报'>
                            {getFieldDecorator('posterPath', {
                                initialValue:lectureData?lectureData.posterPath:'',
                                rules: [
                                    {
                                        required: false,
                                        message: '宣传海报必须上传!'
                                    },
                                ],
                            })(
                                <ImageUpload/>,
                            )
                            }
                        </Form.Item>
                        <Form.Item label='讲座介绍' >
                            {getFieldDecorator('intro', {
                                initialValue:lectureData?lectureData.intro:'',
                                rules: [
                                    {
                                        required: true,
                                        message: '讲座介绍是必须输入的!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(value.length > 20) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '讲座介绍必须大于20个字。',
                                    }
                                ],
                            })(
                                <TextArea rows={4}
                                          placeholder="讲座介绍"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit" size="large" block>
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </MyLayout>
        )
    }
}
export default Form.create()(AddOrEditLecture);