import React, { Component } from 'react';
import {Tooltip, Table, Divider, PageHeader, Tag, Button} from 'antd';
import api from '../../api/api';
import MyLayout from "../common/MyLayout";
import AddTopicModal from '../qa/AddTopicModal';
import RoutePath from "../../config/RoutePath";

class GetMyCreatedTopicList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            topData:[],
            visible:false,
        };
    }

    getMyCreatedTopicList() {
        api.qa.getMyCreatedTopicList("",
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        topData:data.data,
                    })
                }
            }
        )
    }

    onChange(success){
        if (success){
            this.getMyCreatedTopicList();
        }
    }

    componentDidMount() {
        this.getMyCreatedTopicList();
    }

    render() {
        let history = this.props.history;
        const columns = [
            {
                title: '我的提问',
                dataIndex: 'content',
                key: 'content',
                className:"tableOverflow",
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 120,
                        }
                    }
                }
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => (
                    record.status === 1?
                    <Tag color='#f50'>待我追加</Tag>
                    :<Tag color='#87d068'>待老师回答</Tag>
                ),
            },
            {
                title: '答疑老师',
                dataIndex: 'teacherName',
                key: 'teacherName',
            },
            {
                title: '问题更新时间',
                dataIndex: 'createdTime',
                key: 'createdTime',
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                render: (text, record) => (
                    <Button 
                    type='primary'
                    onClick={()=>history.push(RoutePath.GetQaDetail+'/'+record.topicId)}>
                    追加提问</Button>
                ),
            },
        ];
        return(
            <MyLayout>
                <PageHeader
                    style={{padding:"0"}}
                    title="问题列表"
                    subTitle="我创建的问题列表"
                />
                <Divider />
                <AddTopicModal onChange={(success)=>this.onChange(success)}/>
                <Table
                    pagination={false}
                    dataSource={this.state.topData}
                    columns={columns}
                />
            </MyLayout>
        )
    }
}
export default GetMyCreatedTopicList;