/* eslint-disable jsx-a11y/alt-text */

import React, { Component } from 'react';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

class HelpLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Typography>
                <Title level={2}>网站登录与注册</Title>
                <Paragraph>
                    本文将会指导用户如何使用本网站的注册登录功能。
                </Paragraph>
                <Title level={4}>网站注册</Title>
                <Paragraph>
                    进入网站首页，点击右上角的注册按钮，跳转到注册页面。在注册页面中，填写相应信息后点击注册按钮即可成功注册。
                </Paragraph>
                <Title level={4}>网站登录</Title>
                <Paragraph>
                    进入网站首页，点击右上角的登录按钮，跳转到登录页面。在登录页面中，填写用户名密码后点击登录按钮即可进行注册。
                </Paragraph>
            </Typography>
        );
    }
}

export default HelpLogin;
