import React, { Component } from 'react';
import {Input, Button, Divider, Row, Col, Form, message} from 'antd';
import MyLayout from "../common/MyLayout";
import connect from "react-redux/es/connect/connect";
import DataValidate from "../../config/DataValidate";
const InputGroup = Input.Group;

class CertificateQuery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            certNo:"",
            visible: false,
        };
    }

    submitQuery = e => {
        if (this.state.certNo) {
            this.setState({
                visible: DataValidate.isCertNo(this.state.certNo)? true :false,
            });
        }else{
            message.error('你还没有输入要查询的证书编号!');
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                sm: { span: 8 },
            },
            wrapperCol: {
                sm: { span: 16},
            },
        };
        return (
            <MyLayout>
                <div>
                    <div className="userEditTitle">证书查询</div>
                    <Divider />
                    <Form {...formItemLayout}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="证书编号">
                                    {
                                        getFieldDecorator('certNo', {
                                            rules: [
                                                {
                                                    required: true,
                                                    validateStatus:'error',
                                                    message: '证书编号是必须的!'
                                                },
                                                {
                                                    validator: (rule, value, callback) => {
                                                        if(DataValidate.isCertNo(value)) {
                                                            callback();
                                                            return;
                                                        }
                                                        callback('error');
                                                    },
                                                    message: '证书编号格式不对',
                                                },
                                            ],
                                        })(
                                            <Input style={{ width: '100%'}}
                                                   size="large"
                                                   placeholder='请输入证书编号'
                                                   onChange={(e)=>this.setState({certNo:e.target.value})}
                                            />
                                        )
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item>
                                    <Button size="large"
                                            type="primary"
                                            htmlType="submit"
                                            onClick={()=>this.submitQuery()}
                                            >点击查询
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider dashed/>
                        <Form.Item>
                            {
                               this.state.visible?
                                   <div align="center">
                                        <img height="70%"
                                             width="70%"
                                             src={"http://www.deshipt.com/api/anon/getVideoPlayCertPng?certNo="+ this.state.certNo}
                                        />
                                   </div>:null}
                        </Form.Item>
                    </Form>
                </div>
            </MyLayout>
        );
    }
}
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default connect(mapStateToProps,null)(Form.create()(CertificateQuery));