import React, { Component } from 'react';
import {Form, Modal, Input, Button, message} from 'antd';
import api from '../../../api/api';
import DataValidate from "../../../config/DataValidate";
import MajorAutoComplete from "../../../components/MajorAutoComplete";
import {dataSubjectSubClass } from '../../../config/majorData';

class addNewClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            data:{},
        };
    }

    getClassDetail() {
        api.class.getClassDetail({"classId": this.props.classId},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                    setTimeout(()=>{
                        this.setState({
                            visible: true,
                        })
                    },200)
                }
            }
        )
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    handleOk = () => {
        let orgId = this.props.orgId;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let cbk = (state,data)=>{
                    if(state && data.status==200) {
                        this.setState({
                            visible: false,
                        });
                        this.props.onChange(state);
                    } else {
                        message.error(data.msg);
                    }
                };
                if(this.props.type=="add") {
                    api.class.addNewClass({orgId, ...values},cbk);
                }
                if (this.props.type=="edit") {
                    values.classId = this.props.classId;
                    api.class.editClass({...values},cbk);
                }
            }
        });
    };

    componentDidMount() {
        //this.getClassDetail();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                sm: { span: 6 },
            },
            wrapperCol: {
                sm: { span: 16},
            },
        };
        const {
            data= null
        } = this.state;
        return(
            <>
                {this.props.type=="add"?
                    <Button type="primary"
                            style={{ marginBottom: 16 }}
                            onClick={()=>{
                                this.setState({
                                    visible: true,
                                })
                                this.props.form.setFields({
                                    classId:null,
                                    name:null,
                                    subjectId:null,
                                })
                            }}>
                        添加班级
                    </Button>:
                    <Button type="link"
                            style={{ marginBottom: 16 }}
                            onClick={()=>{
                                this.getClassDetail();
                            }}>
                        编辑
                    </Button>
                }
                <Modal
                    title="添加/编辑班级"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Form {...formItemLayout}>
                        {this.props.type=="add"?
                        <Form.Item label='班级编号' hasFeedback>
                            {getFieldDecorator('classId', {
                                initialValue:data?data.classId:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '班级编号是必须的!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(DataValidate.isClassId(value)) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '班级编号格式错误!(6~16位数字和字母)!',
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            api.class.checkClassId({
                                                    "classId": value,
                                                },
                                                (state,data)=>{
                                                    if(state && data.status == 200) {
                                                        callback();
                                                    }
                                                    else  {
                                                        callback('error');
                                                    }
                                                });
                                        },
                                        message: '这个班级编号已经被注册或者无效.(6~16位数字和字母)！',
                                    }
                                ],
                            })(
                                <Input placeholder="6~16位数字和字母(例:QX0001)"
                                />,
                            )}
                        </Form.Item>:""}
                        <Form.Item label='班级名' hasFeedback>
                            {getFieldDecorator('name', {
                                initialValue:data?data.name:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '班级名是必须的!'
                                    },

                                ],
                            })(
                                <Input placeholder="例:物流管理102"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item  label='班级所属专业' hasFeedback>
                            {
                                getFieldDecorator('subjectId', {
                                    initialValue:data?data.subjectId:'',
                                    rules: [
                                        {
                                            required: true,
                                            validateStatus:'error',
                                            message: '班级所属专业是必须选择的!'
                                        },
                                        {
                                            validator: (rule, value, callback) => {
                                                let optionArr = Object.entries(dataSubjectSubClass);
                                                let isMatch = false;
                                                for(var data of optionArr) {
                                                    if (RegExp(value).exec(data[0]) !== null){
                                                        isMatch = true;
                                                        break;
                                                    }
                                                }
                                                if (isMatch) {
                                                    callback();
                                                } else {
                                                    callback('error');
                                                }
                                            },
                                            message: '输入的专业没有匹配,请检查专业的正确性！',
                                        }
                                    ],
                                })(
                                    <MajorAutoComplete option={dataSubjectSubClass}/>,
                                )}
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        )
    }
}
export default Form.create({ name: 'addNewClass' })(addNewClass);