import React, { Component } from 'react';
import {Col, Row, Modal, Table, Pagination, Divider, Input, Button, Form, Select} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import Dict from "../../../config/Dict";
const { confirm } = Modal;

class PendingVideoList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            legalPersonName: "",
            teacherName:"",
            videoKeywords: [],
            status:null,

            data:[],
            pageNum:1,
            pageSize:8,
            totalNum:9,
        };
    }

    examineOrg(status,videoId){
        let title = "";
        let content = "";
        if (status == 1){
            title = "你确定要[审批通过]这个视频吗?";
            content = "视频[审批通过]后才可以用户所看见哦。"
        }else{
            title = "你确定要[审批驳回]这个视频吗?";
            content = "视频[审批驳回]后信息会返回给请求者。"
        }
        confirm({
            title: title,
            content:content,
            onOk:()=>{
                api.video.examineVideo({videoId,status},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.research();
                        }
                    }
                )
            }
        });
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.updateData(), 200);
    }

    updateData() {
        api.video.pendingVideoList({
                "condition": {
                    "lectureDateEnd": "",
                    "lectureDateStart": "",
                    "lectureName": this.state.lectureName,
                    "status":this.state.status,
                    "teacherName":this.state.teacherName,
                    "videoKeywords":this.state.videoKeywords,
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:4,
            totalNum:9,
        });
        setTimeout(()=>this.updateData(), 200,)
    }

    componentDidMount() {
        this.updateData();
    }

    render() {
        let history = this.props.history;
        const columns = [
            {
                title: '视频名称',
                dataIndex: 'name',
                key: 'name',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow:'ellipsis',
                            cursor:'pointer'
                        }
                    }
                },
                render:  (text, record) => (
                    <a onClick={()=>history.push('/videoDetail'+'/'+record.vid)}>{text}</a>
                )
            },
            {
                title: '更新时间',
                dataIndex: 'createdTime',
                key: 'createdTime',
                render: (text, record) => (
                    <span>
                        {record.updatedTime?record.updatedTime:record.createdTime}
                    </span>
                ),
            },
            {
                title: '视频关键字',
                dataIndex: 'keywords',
                key: 'keywords',
            },
            {
                title: '审核状态',
                key: 'status',
                dataIndex: 'status',
                render: (text, record) => (
                    <span>
                        {
                            Dict.getValue('orgState',text,'')
                        }
                    </span>
                ),
            },
            {
                title: '审核事件',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <a onClick={()=>this.examineOrg(1,record.vid)}>同意</a>
                        <Divider type="vertical" />
                        <a onClick={()=>this.examineOrg(2,record.vid)}>驳回</a>
                    </span>
                ),
            },
        ];
        return(
            <MyLayout sider={"PlatformPendingSider"}>
                <p className="userEditTitle">视频审核</p>
                <Form layout='inline'>
                    <Form.Item>
                    <Input 
                               defaultValue={this.state.videoKeywords}
                               placeholder='请输关键字'
                               onChange={(e)=>{
                                   this.setState({videoKeywords:[e.target.value]})}}
                    />
                    </Form.Item>
                    <Form.Item>
                    <Select value={this.state.status} onChange={
                        (status)=>{
                            this.setState({status});
                            this.research();
                        }
                    }>
                        <Select.Option value={null}>不限制</Select.Option>
                        <Select.Option value={0}>已注销</Select.Option>
                        <Select.Option value={1}>上线中</Select.Option>
                        <Select.Option value={2}>已驳回</Select.Option>
                        <Select.Option value={3}>待审核</Select.Option>
                    </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary'
                                onClick={()=>this.research()}>
                            搜索</Button>
                    </Form.Item>
                </Form>
                <Divider/>
                <Table
                pagination={false}
                dataSource={this.state.data}
                columns={columns}
            />
                <div style={{paddingTop:"25px"}}>
                    <Pagination
                        onChange={(pageNum,pageSize)=>this.pageOnChange(
                            pageNum,pageSize
                        )}
                        pageSize={this.state.pageSize}
                        current={this.state.pageNum}
                        total={this.state.totalNum}
                    />
                </div>
            </MyLayout>
        )
    }
}

export default PendingVideoList;