import React, { Component } from 'react';
import {Table, Divider, Button, Row, Col, Modal, message} from 'antd';
import api from '../../../../api/api';
import { withRouter } from 'react-router'
import connect from "react-redux/es/connect/connect";

const { confirm } = Modal;

class SceneList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:[],
        };
    }

    updateData() {
        let lectureId = this.props.lectureId;
        api.lecture.sceneList({lectureId},
            (state, data)=>{
                if(state && data.status===200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    delScene(sceneId){
        confirm({
            title: '你确定要删除这个场次吗?',
            content: '你删除的场次将不再显示在场次表中。',
            onOk:()=>{
                api.lecture.delScene(
                    {sceneId},
                    (state,data)=>{
                        if(state && data.status === 200) {
                            this.updateData();
                        }
                        else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    }

    componentDidMount() {
        this.updateData();
    }

    render() {
        let history = this.props.history;
        const columns = [
            {
                title: '本场讲座名',
                dataIndex: 'name',
                key: 'name',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow:'ellipsis',
                            cursor:'pointer'
                        }
                    }
                },
                render:  (text, record) => (
                    <Button type='link' 
                    onClick={()=>history.push('/company/SceneDetail/'+ this.props.lectureId +'/'+record.id)}>
                    {text}</Button>
                )
            },
            {
                title: '会场',
                dataIndex: 'vname',
                key: 'vname',
            },
            {
                title: '会场地址',
                dataIndex: 'vlocation',
                key: 'vlocation',
            },
            {
                title: '主讲人',
                dataIndex: 'tname',
                key: 'tname',
                render:  (text, record) => (
                    <Button type='link'
                    onClick={()=>{history.push('/Personal/FamousTeacherDetail/'+record.tid)}}>
                    {text}</Button>
                )
            },
            {
                title: '开始时间',
                dataIndex: 'startTime',
                key: 'startTime',
                render:  (text, record) => (
                    <div>{text.substring(0,16)}</div>
                )
            },
            {
                title: '结束时间',
                dataIndex: 'endTime',
                key: 'endTime',
                render:  (text, record) => (
                    <div>{text.substring(0,16)}</div>
                )
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button type='link'
                        onClick={()=>history.push('/company/AddOrEditScene/'+ this.props.lectureId + '/' + record.id)}>
                        编辑</Button>
                        <Divider type="vertical" />
                        <Button type='link'
                        onClick={()=>this.delScene(record.id)}>
                        删除</Button>
                        <Divider type="vertical" />
                        <Button 
                        type='link'
                        onClick={()=>this.props.history.push('/SceneOrders'+'/'+record.id)}
                        >本场订单</Button>
                    </span>
                ),
            }
        ];
        return(
            <div>
                <Divider/>
                <Row>
                    <Col span={21}>
                        <p className="userEditTitle">讲座场次</p>
                    </Col>
                    {
                        this.props.userInfo && this.props.userInfo.role !== 5 ?
                            <Col span={3}>
                                <Button type="primary"
                                        onClick={() => history.push('/company/AddOrEditScene/' + this.props.lectureId + '/addScene')}>
                                    添加场次</Button>
                            </Col>:""
                    }
                </Row>
                <Table
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
            </div>
        )
    }
}
//export default withRouter(SceneList);
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default withRouter(connect(mapStateToProps,null)(SceneList));