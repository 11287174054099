import React,{ Component } from "react";
import { Carousel} from 'antd';
import api from "../../api/api";

class Animation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
        };
        this.imgArray = [
            'https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg',
            'https://imgs.wanmen.org/24b141d77df67ea40b8545876c1acb37.png',
            'https://imgs.wanmen.org/1d119af6d5a86f0e77d4f456e673d14a.png',
            'https://imgs.wanmen.org/292fcd163c88699fef8b60b7aace14f3.png',
            'https://zos.alipayobjects.com/rmsportal/hzPBTkqtFpLlWCi.jpg',
        ];
    }

    allAdvertisements() {
        api.admin.allAdvertisements({},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    handle=(link)=>{
        const w = window.open('about:blank');
        w.location.href = link;
    };

    componentDidMount() {
        this.allAdvertisements();
    }

    render(){
        const {data} = this.state;
        return(
		    <>
			{
			data.length > 0 ?
            <Carousel className="animationImg" autoplay effect="fade">
                {data.map((img,index)=>{
                    return(
                        <div align="center" key={index}>
                            <img src={img.filePath}
                                 onClick={()=>this.handle(img.link)}
                            />
                        </div>
                    )
                })}
            </Carousel>
			:null
			}
			</>
        )
    }
}
export default Animation;