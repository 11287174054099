
import React, { Component } from 'react';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import { authtoken } from '../../../api/utils/Request';

class DraftInput extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            editorState:null,
        };
    }

    handleEditorChange = (editorState) => {
        this.setState({editorState});
        if(this.props.onChange)
        {
            if(this.props.value != editorState.toHTML())
            {
                this.props.onChange(editorState.toHTML());
            }
        }
    }

    myUploadFn = (param) => {

        const serverURL = "/api/authc/upload";
        const xhr = new XMLHttpRequest();
        const fd = new FormData()
      
        const successFn = (response) => {
          let url = JSON.parse(xhr.response).data;
          param.success({
            url: url,
            meta: {
              id: 'xxx',
              title: 'xxx',
              alt: 'xxx',
            }
          })
      }

      const progressFn = (event) => {
        param.progress(event.loaded / event.total * 100)
      }
    
      const errorFn = (response) => {
        param.error({
          msg: 'unable to upload.'
        })
      }
    
      
      xhr.upload.addEventListener("progress", progressFn, false);
      xhr.addEventListener("load", successFn, false);
      xhr.addEventListener("error", errorFn, false);
      xhr.addEventListener("abort", errorFn, false);
    
      fd.append('upfile', param.file);
      xhr.open('POST', serverURL, true);
      xhr.setRequestHeader('authtoken',authtoken);
      xhr.send(fd);
    }

    render() {
        let {editorState} = this.state;
        if(this.props.value)
        {
            if(editorState)
            {
                if(this.props.value != editorState.toHTML())
                {
                    editorState = BraftEditor.createEditorState(this.props.value);
                }
            }
            else
            {
                editorState = BraftEditor.createEditorState(this.props.value);
            }
        }

        return (
                <div style={{backgroundColor:'#FFFFF0'}} className="editor-wrapper">
                    <BraftEditor
                    controls ={
                        [
                            'undo',
                            'redo',
                            'font-size',
                            'media'
                        ]
                    }
                    contentStyle={{height: 300}}
                    value={editorState}
                    onChange={this.handleEditorChange}
                    media={
                        {
                            uploadFn:this.myUploadFn,
                            accepts:{
                                image: 'image/png,image/jpeg,image/gif',
                                video:false,
                                audio:false,
                            },
                            externals:false,
                        }
                    }
                    />
                </div>
        );
    }
}

export default DraftInput;

