import React, { Component } from 'react';
import {Modal, Input, List, Pagination, Button} from 'antd';
import api from "../api/api";

/************* 组织Popover ****************/
class OrgSelectModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orgData:[],
            orgName:"",
            orgId:"",
            visible: false,

            pageNum:1,
            pageSize:8,
            totalNum:9,
        }
    }

    orgList(){ //获取组织
        let param = {
                "condition": {
                    "legalPersonName": "",
                    "orgName": "",
                    "status":null,
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            };
        let cbk = (state,data)=>{
            if(state && data.status==200) {
                this.setState({
                    orgData:data.data,
                    pageNum: this.state.pageNum,
                    pageSize: this.state.pageSize,
                    totalNum:data.totalNum
                })
            }
        };
        if(this.props.userSetting=="allUser") {
            api.org.orgList(param,cbk);
        }
        if (this.props.userSetting=="myUser") {
            api.org.myOrgList(param,cbk);
        }
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.orgList(), 200);
    }

    listClick(orgName,orgId) {
        this.setState({
            orgName:orgName,
            visible: false,
        });
        this.props.onChange(orgId)
    }

    componentDidMount() {

        this.orgList();
    }

    render() {
        if (this.props.value && this.props.value.orgId && this.props.value.orgName){
            this.props.onChange(this.props.value.orgId);
            this.setState({
                orgName: this.props.value.orgName,
            })
        }
        return (
            <div>
                <Input
                       placeholder="所属组织"
                       value={this.state.orgName}
                       onClick={()=>{
                            this.setState({
                                visible: true,
                            })}
                    }/>
                <Modal
                    title="所属组织"
                    visible={this.state.visible}
                    onCancel={()=>{
                        this.setState({
                            visible:false
                        })
                    }}
                    footer={null}
                >
                    <List
                        dataSource={this.state.orgData}
                        renderItem={item =>
                            <List.Item onClick={()=>this.listClick(item.orgName,item.orgId)}>
                                <a>{item.orgName}</a>
                            </List.Item>}
                    />
                    <div style={{paddingTop:"25px"}}>
                        <Pagination
                            onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.state.pageSize}
                            current={this.state.pageNum}
                            total={this.state.totalNum}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default OrgSelectModal;