import NewsClassificationData from '../../../config/newsClassificationData.js';

const getClassification = () => {
    const newsOptions = [];
    const mainClass = Object.keys(NewsClassificationData.mainClass).sort();
    const subClass = Object.keys(NewsClassificationData.subClass).sort();

    for (let i = 0; i < mainClass.length; i++) {
        newsOptions.push({
            value: mainClass[i],
            label: NewsClassificationData.mainClass[mainClass[i]],
            children: [],
        });
        for (let j = 0; j < subClass.length; j++) {
            if (subClass[j].substr(0, 3) === mainClass[i]) {
                newsOptions[i].children.push({
                    value: subClass[j],
                    label: NewsClassificationData.subClass[subClass[j]],
                });
            }
        }
    }

    return newsOptions;
};

export default getClassification;
