import React, { Component } from 'react';
import MyLayout from '../../common/MyLayout';
import { Modal,Pagination,Divider, Table, Button, Form, message, Input, Popover, Ico, Row, Col, Select, Icon } from 'antd';
import Dict from '../../../config/Dict';
import api from '../../../api/api';
import TextArea from 'antd/lib/input/TextArea';
import RoutePath from '../../../config/RoutePath';
class NoticePub extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            itemName:"",
            data:[],
            visible:false,
         };
    }

    handleSumbit=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let noticeType = parseInt(this.props.match.params.noticeType);
                api.notice.addOrEditNotice(
                    {...values,noticeType},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.fetchMyEditNoticeList();
                        }
                        else
                        {
                            message.error(data.msg);
                        }
                    }
                );
                this.setState({visible:false});
            }
        });
    };

    fetchMyEditNoticeList()
    {
        let param = null;
        let itemId = this.props.match.params.itemId;
        if(itemId)
        {
            param = {itemId};
        }

        api.notice.getMyEditNoticeList(
            param,
            (state, data)=>{
                if(state && data.status == 200)
                {
                    this.setState({data:data.data})
                }
            }
        );
    }


    handleDelete=(record)=>{
        Modal.confirm({
            title: '警告',
            content: '删除操作无法挽回，还真的要删除'+record.title,
            onOk:()=>{
                api.notice.deleteNoticeByNoticeId(
                    {noticeId:record.noticeId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.fetchMyEditNoticeList();
                        }
                        else
                        {
                            message.error(data.msg);
                        }
                    } 
                )
            },
            okText: '确认',
            cancelText: '取消',
          });
    }

    parmCheck()
    {
        let noticeType = this.props.match.params.noticeType;
        if(!(noticeType == '0'
          ||noticeType == '1'
          ||noticeType == '2'
          ||noticeType == '3'))
        {
            Modal.error({
                title:'非法访问',
            })
        }

        let itemId = this.props.match.params.itemId;
        if(itemId)
        {
            api.notice.getItemName({itemId},
                (state,data)=>{
                    if(state && data.status == 200)
                    {
                        this.setState({
                            itemName:data.data
                        })
                    }
                })
        }
    }

    componentDidMount()
    {
        this.parmCheck();
        this.fetchMyEditNoticeList();
    }

    render() {
        let noticeType = parseInt(this.props.match.params.noticeType);
        let itemId = this.props.match.params.itemId;
        const { getFieldDecorator } = this.props.form;
        getFieldDecorator('noticeId',{});
        getFieldDecorator('itemId',{});

        const columns = [
            {
                title: '公告名',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '状态',
                key: 'status',
                render: (text, record) => (
                    <div>
                    {
                        Dict.getValue('noticeStatus',record.status)
                    }
                    </div>
                )
            },
            {
                title: '类型',
                key: 'noticeType',
                filteredValue:[noticeType],
                onFilter: (value, record) => {
                    return record.noticeType == value
                },
                render: (text, record) => (
                    <div>
                    {
                        Dict.getValue('noticeType',record.noticeType)
                    }
                    </div>
                )
            },
            {
                title: '创建日期',
                dataIndex: 'createdTime',
                key: 'createdTime',
            },
            {
                title: '详情',
                key: 'content',
                render: (text, record) => (
                    <Popover
                    content={record.content}
                    >
                    {record.content.substring(0,10)}
                    </Popover>
                )
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button
                            onClick={()=>{
                                let form = this.props.form;
                                form.setFieldsValue(record);
                                this.setState({visible:true});
                            }}
                        ><Icon type='edit'/></Button>
                        <Divider type='vertical'/>
                        <Button
                        type='danger'
                        onClick={
                            ()=>{
                                this.handleDelete(record);
                            }
                        }
                        >
                        删除
                        </Button>
                        {
                            noticeType == 3?
                            <>
                            <Divider type='vertical'/>
                            <Button
                            type='primary'
                            onClick={
                                ()=>{
                                    this.props.history.push(
                                        RoutePath.NoticeUserAdmin+"/"+record.noticeId+"/"+itemId);
                                }
                            }
                            >
                            接受者管理
                            </Button>
                            </>
                            :null
                        }
                    </span>
                ),
            },
        ];

       

        return (
            <MyLayout title={"["+Dict.getValue('noticeType',noticeType)+"]"+this.state.itemName}>
                <Button type='primary'
                        onClick={
                            ()=>{
                                let form = this.props.form;
                                form.setFieldsValue({
                                    noticeId:null,
                                    itemId:itemId,
                                    status:0,
                                    title:null,
                                    content:null,
                                    });
                                this.setState({
                                visible:true
                                });
                        }}
                
                >添加新公告</Button>
                <Divider/>
                <Table
                    dataSource={this.state.data}
                    columns={columns}
                    pagination={false}
                />


                <Modal                
                    title='添加编辑公告'
                    visible={this.state.visible}
                    onCancel={()=>{
                                this.setState({
                                    visible:false
                                })
                            }}
                    okText="确认"
                    cancelText="取消"
                    footer={null}
                >
                <Form onSubmit={this.handleSumbit}>
                    <Form.Item label='公告名'>
                    {
                            getFieldDecorator('title', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '公告名是必须输入的!'
                                    },
                                ],
                            })(
                            <Input size="large"
                                 placeholder="公告名"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label='公告状态'>
                    {
                            getFieldDecorator('status', {
                                rules: [
                                    {
                                        required: true,
                                        message: '公告状态是必须输入的!'
                                    },
                                ],
                            })(
                                <Select>
                                    <Select.Option value={0}>有效</Select.Option>
                                    <Select.Option value={1}>无效</Select.Option>
                                </Select>
                        )}
                    </Form.Item>
                    <Form.Item label='公告内容'>
                    {
                            getFieldDecorator('content', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '公告内容是必须输入的!'
                                    },
                                ],
                            })(
                            <TextArea size="large"
                                 placeholder="公告内容"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit" size="large" block>
                            确定
                        </Button>
                    </Form.Item>
                </Form>

                </Modal>

            </MyLayout>


        );
    }
}

export default Form.create()(NoticePub);
