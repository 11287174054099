import React, { Component } from 'react';
import {Modal, Input, Form, Button, message} from 'antd';
import api from "../../api/api";
import MyTeachersModal from '../qa/MyTeachersModal';
const { TextArea } = Input;

class AddTopicModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teachersData:null,
            visible:false
        };
    }

    getMyTeachers = () => {
        api.qa.getMyTeachers("",
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        teachersData: data.data
                    });
                }
            }
        )
    };

    handleOk = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.qa.addTopic(
                    {...values},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.setState({
                                visible: false,
                            });
                            this.props.onChange(state)
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    };

    componentDidMount() {
        this.getMyTeachers();
    }

    render() {
        //let history = this.props.history;
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Button type="primary"
                        style={{ marginBottom: 16 }}
                        onClick={()=>{
                            this.setState({
                                visible: !this.state.visible,
                            })
                        }}>
                    发起一次提问
                </Button>
                <Modal
                    title="发起一次提问"
                    width={700}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={()=>{this.setState({visible: false})}}
                >
                    <Form>
                        <Form.Item  label='问题' hasFeedback>
                            {
                                getFieldDecorator('content', {
                                    rules: [
                                        {
                                            required: true,
                                            validateStatus:'error',
                                            message: '问题是必须输入的!'
                                        },
                                    ],
                                })(
                                    <TextArea rows={8}
                                              size="large"
                                              placeholder="问题是必须输入的"
                                    />,
                                )}
                        </Form.Item>
                    </Form>
                    <Form.Item label='答疑老师'>
                        {getFieldDecorator('tuid', {
                            //initialValue:starData?starData.teacher:null,
                            rules: [
                                {
                                    required: true,
                                    message: '推荐的讲师是必须输入的!'
                                },
                            ],
                        })(
                            <MyTeachersModal teachersData={this.state.teachersData}/>
                        )}
                    </Form.Item>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(AddTopicModal);