import React, { Component } from 'react';
import { Menu, Icon, Layout} from 'antd';
import {connect } from 'react-redux';
import {withRouter } from 'react-router'
import Action from '../../../actions/Action';

class Company_InfoManageSider extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {   
        return (
             <>
             </>
        );
    }
}
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
        remain: state.user.remain,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetUser: (data) => dispatch(
            {
                type: Action.RESET_USER_INFO,
            }),
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Company_InfoManageSider));