import React, { Component } from 'react';
import {Descriptions,Divider, Row, Col,message,Button} from 'antd';
import MyLayout from "../../common/MyLayout";
import UserSettingSider from "../../common/sider/UserSettingSider";
import Bindphoneoremail from "../../user/userSetting/Bindphoneoremail";
import IdCarModal from "../../user/userSetting/IdCarModal";
import InfoModify from "./InfoModify";
import connect from "react-redux/es/connect/connect";
import Dict from '../../../config/Dict';
import api from '../../../api/api';
import {authtoken} from "../../../api/utils/Request";
import Action from '../../../actions/Action';

class numberBinding extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    
    getParam(name) {
        var search = this.props.location.search;
        if (search == "") {
            return null;
        }

        var query = search.substring(1);                                                                                                                                     
        var list = query.split('&');
        var item = [];
        for(var i = 0; i < list.length; i++) {
            item = list[i].split('=');
            if(item[0] == name) {
                return item[1];
            }
        }
        return null;
    }

    startUpLogin() {
        let token = this.getParam('token'); 
        if(token) {
            api.user.tokenLogin({token},
                (state,data)=>{
                if(state && data.status == 200) {
                    this.props.saveUserInfoToStore(data.data);
                } else {
                    message.error(data.msg)
                }
            });
        }      
    }

    componentDidMount()
    {
        this.startUpLogin();
    }

    render() {
        let data = this.props.userInfo;
        return (
            <MyLayout sider={"UserSettingSider"}>
                <div>
                    <p className="userEditTitle">我的账号绑定</p>
                    <Divider dashed/>
                    <Descriptions bordered>
                        <Descriptions.Item key="email" label="绑定邮箱" span={4}>
                            <Row gutter={16}>
                                <Col span={6}>
                                    {data && data.email ? data.email:"未绑定"}
                                </Col>
                                <Col span={6}>
                                    <Bindphoneoremail btnName={data && data.email ? "修改邮箱":"绑定邮箱"} />
                                </Col>
                            </Row>
                        </Descriptions.Item>
                        <Descriptions.Item key="phone" label="绑定手机" span={4}>
                            <Col span={6}>
                                {data && data.phone ?data.phone:"未绑定"}
                            </Col>
                            <Col span={6}>
                                <Bindphoneoremail btnName={data && data.phone ?"修改手机":"绑定手机"} />
                            </Col>
                        </Descriptions.Item>
                        <Descriptions.Item key="unionid" label="绑定微信" span={4}>
                            <Row gutter={16}>
                            <Col span={6}>
                                {data && data.unionid ?data.unionid.substring(0,10)+'...':"未绑定"}
                            </Col>
                            <Col span={6}>
                                    <Button type='link'
                                    href={"/api/weixin/getBindQR?authtoken="+authtoken} 
                                    >{data && data.unionid ?"重新绑定":"绑定微信"}</Button>
                            </Col>
                            </Row>
                        </Descriptions.Item>
                        {
                            data&&data.role == 0 ?
                                <Descriptions.Item key='idNumber' label="绑定身份证" span={1}>
                                    <Row gutter={16}>
                                        <Col span={6}>
                                            {data && data.idNumber ? data.idNumber:"未绑定"}
                                        </Col>
                                        <Col span={6}>
                                            <IdCarModal btnName={data && data.idNumber ? "修改身份证":"绑定身份证"} />
                                        </Col>
                                    </Row>
                                </Descriptions.Item>:""
                        }
                    </Descriptions>
                </div>
            </MyLayout>
        );
    }
}
//export default UserSetting;
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}

//需要触发什么行为
function mapDispatchToProps(dispatch) {
    return {
        saveUserInfoToStore: (data) => dispatch({
            type: Action.SAVE_USER_INFO, //CON.SAVE_USER_INFO
            data: data,
        }),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(numberBinding);