import React, { Component } from 'react';
import {Tabs, Row, Col, Rate, Button, Tooltip, Card, Progress,} from 'antd';
import api from '../../../api/api';
import '../../../assets/style/common.css';

import MyLayout from "../../common/MyLayout";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";


const { TabPane } = Tabs;


class StudyProgressDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            videoData:null,
        };
    }
    updateVideoDetail() {
        let {videoId} = this.props.match.params;
        api.video.videoDetail({videoId},
            (state,data)=>{
                if(state && data.status === 200) {
                    
                    this.setState({
                        videoData:data.data,
                    });
                    //this.lectureList(data.data.lecture.name);
                }
            }
        )
    }

    videoBuyit(){
        let {videoId} = this.props.match.params;
        api.video.buyit({videoId},
            (state,data)=>{
                if(state && data.status === 200) {
                    this.props.history.push('/Personal/VideoOrderForm/'+ data.data.outTradeNo + '/'+videoId);
                }
                /*
                else if (state && data.status == 1000150) { //该视频已经提交过订单或已经购买
                    Modal.warning({
                        title: '售票时间已经结束啦!',
                        content: '该视频已经提交过订单或已经购买，请去支付或直接观看。',
                    });
                }*/
            })
    }

    componentDidMount() {
        this.updateVideoDetail();
    }

    render() {
        let history = this.props.history;
        let userInfo = this.props.userInfo;
        const {
            videoData= null
        } = this.state;
        return(
            <MyLayout>
                <div className="course-intro">
                    <div className="intro-img">
                        {videoData!= null && videoData.posterPath != null ? <img
                            alt="" src={videoData.posterPath}
                        />:"未上传"}
                    </div>
                    <div className="intro-content">
                        <div style={{paddingBottom:"16px"}}>
                            <p className="intro-title">
                                <div className="oneTextOverflow">
                                    {videoData!= null?
                                        <Tooltip title= {videoData.name}>
                                            {videoData.name}
                                        </Tooltip>:""
                                    }
                                </div>
                            </p>
                            <p className="intro-msg">
                                {videoData&&videoData.teacher?
                                    <div>
                                        主讲老师：
                                        <Button type='link' onClick={()=>
                                            history.push('/Personal/FamousTeacherDetail/'+videoData.teacher.tid)}>
                                            {videoData.teacher.name}<br/>
                                        </Button>
                                    </div>:
                                    <div>主讲老师：<span>未知</span></div>
                                }
                                {videoData&&videoData.lecture?
                                    <div>
                                        所属讲座：
                                        <Button type='link' onClick={()=>
                                            history.push('/Personal/LectureSceneDetail/'+videoData.lecture.lid)}>
                                            {videoData.lecture.name}<br/>
                                        </Button>
                                    </div>:
                                    <div>
                                        所属讲座：<span>无</span>
                                    </div>
                                }
                                视频评价：
                                {videoData!= null && videoData.praiseRate != null?
                                    <Rate disabled allowHalf defaultValue={videoData.praiseRate/10/2}/>:"暂无评价"}
                            </p>
                            <p className="intro-subtitle">
                                <div className="multipleTextOverflow">
                                    {videoData!= null?videoData.intro:""}
                                </div>
                            </p>
                        </div>

                        {videoData!= null && videoData.price === 0 ?
                            <div>
                                <div className="intro-price">
                                    <span className="current-freeAdmission">限时免费</span>
                                </div>
                                <div className="button-item">
                                    <Button type="primary"
                                            shape="round"
                                            icon="play-circle"
                                            onClick={()=>
                                                history.push('/Personal/VideoPlay/'+ this.props.match.params.videoId)}>
                                        立即学习
                                    </Button>
                                </div>
                            </div>:
                            <div>
                                {videoData!= null && videoData.filePath == null ?
                                    <div>
                                        <div className="intro-price">
                                           价格：
                                            <span className="current-price">￥{videoData!= null?videoData.price/100:""}</span>
                                        </div>
                                        <div className="button-item">
                                            <Button type="primary"
                                                    shape="round"
                                                    icon="play-circle"
                                                    onClick={()=>this.videoBuyit()}>
                                                立即购买
                                            </Button>
                                        </div>
                                    </div>:
                                    <div>
                                        <div className="intro-price">
                                            <span className="current-freeAdmission">
                                                {
                                                    userInfo!=null && userInfo.role == 0?
                                                        "您已经购买过该视频，可以“立即学习”！":"您为视频所有者，可以“立即学习”"
                                                }
                                            </span>
                                        </div>
                                        <div className="button-item">
                                            <Button type="primary"
                                                    shape="round"
                                                    icon="play-circle"
                                                    onClick={()=>
                                                        history.push('/Personal/VideoPlay' + '/'+ this.props.match.params.videoId)}>
                                                立即学习
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </div>

                        }
                    </div>
                </div>
                <div>
                    <Row >
                        <Col span={16}>
                            <Tabs defaultActiveKey="1" style={{padding:"20px"}}>
                                <TabPane tab="课程描述" key="1">
                                    {videoData!= null?videoData.intro:""}
                                </TabPane>
                                <TabPane tab="视频评价" key="2">
                                    <div>
                                        {videoData!= null && videoData.praiseRate != null?
                                            <Rate disabled allowHalf defaultValue={videoData.praiseRate/10/2}/>:
                                            <div>
                                                <p>还没有人评价过这个视频哦！</p>
                                                <p>快点去学习吧，学习后可以给这个视频评论哦！</p>
                                            </div>
                                        }
                                    </div>
                                </TabPane>
                            </Tabs>
                        </Col>
                        <Col span={4} style={{paddingTop:"75px"}}>
                            <div style={{marginLeft:"80px"}}>
                                <div style={{fontSize:"20px", marginBottom: "15px"}}>学习进度</div>
                                <Progress type="circle"
                                    strokeColor={{
                                        '0%': '#108ee9',
                                        '100%': '#87d068',
                                    }}
                                    percent={videoData!= null?videoData.percent:0}
                                />
                            </div>
                        </Col>
                        <Col span={4} style={{paddingTop:"75px"}}>
                            <div style={{marginLeft:"80px"}}>
                                <div style={{fontSize:"20px", marginBottom: "15px"}}>证书下载</div>
                                {videoData&&videoData.certNo!= null?
                                <Button type="primary"
                                        target="_blank"
                                        shape="round"
                                        size="large"
                                        onClick={()=> window.open("http://www.deshipt.com/api/anon/getVideoPlayCertPng?certNo="+videoData.certNo)}
                                        >
                                    证书下载
                                </Button>:
                                    <div style={{color:"#f00"}}>暂未获得证书</div>}
                            </div>
                        </Col>
                    </Row>
                </div>
            </MyLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default withRouter(connect(mapStateToProps,null)(StudyProgressDetail));