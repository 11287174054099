import React, { Component } from 'react';
import api from '../../api/api';
import { setLoadingState } from '../../reducers/UserInfo';
import MyLayout from '../common/MyLayout';
import { Checkbox, Table, Divider, Row, Col, Form, Select, Button, message } from 'antd';
import RoutePath from '../../config/RoutePath';
import moment from 'moment';
import SimpleDateSel from '../../components/SimpleDateSel';

class StaticMonthLearn extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            clses:[],
            classId:null,
            yearMonth: moment().format("YYYY/MM/DD HH:mm:ss"),

            data:[],
            loading:false,
            acc:false,
         };
    }

    feacthClassList()
    {
        let orgId = this.props.match.params.orgId;
        if(orgId)
        {
            //管理员用户
            api.class.getAllClassByOrgId({orgId},
                (state,data)=>{
                    if(state && data.status == 200)
                    {
                        this.setState({clses:data.data});
                        if(data.data.length > 0)
                        {
                            this.setState({classId:data.data[0].classId});
                            this.research();
                        }
                    }
                }
            );
        }
        else
        {
            //老师用户
            api.taskadmin.getMyOcuppyClasses(
                null,
                (state,data)=>{
                    if(state && data.status == 200)
                    {
                        this.setState({clses:data.data});
                        if(data.data.length > 0)
                        {
                            this.setState({classId:data.data[0].classId});
                            this.research();
                        }
                    }
                }
            )
        }
    }

    featchPersonalList()
    {
        setLoadingState(true);
        api.statics.getClassLearnStaticByCondition({
            classId:this.state.classId,
            yearMonth:this.state.yearMonth,
            acc:this.state.acc,
        },
        (state, data)=>{
            if(state && data.status==200) {
                this.setState({
                    data:data.data,
                })
            }
            else
            {
                message.error("日期超限或者其它错误");
            }
            setLoadingState(false);
        }
        )
    }

    research() {
        setTimeout(()=>this.featchPersonalList(), 200);
    }

    componentDidMount()
    {
        this.feacthClassList();
    }

    render() {
        let columns = [
            {
                title: '#编号#',
                dataIndex: 'index',
                key: 'index',
                render: (text, record,index) => (
                    <span>{index+1}</span>
                )
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '电话',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: '身份证号',
                dataIndex: 'idNumber',
                key: 'idNumber',
            },
            {
                title: '学习视频数',
                dataIndex: 'numVideo',
                key: 'numVideo',
                sorter: (a, b) => a.numVideo-b.numVideo,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '学习时长(分)',
                dataIndex: 'numVideoDuration',
                key: 'numVideoDuration',
                sorter: (a, b) => a.numVideoDuration-b.numVideoDuration,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '学习试卷数',
                dataIndex: 'numAnswer',
                key: 'numAnswer',
                sorter: (a, b) => a.numAnswer-b.numAnswer,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '答题道数',
                dataIndex: 'numAnswerItems',
                key: 'numAnswerItems',
                sorter: (a, b) => a.numAnswerItems-b.numAnswerItems,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title:'详情',
                dataIndex: 'descrp',
                key: 'descrp',
                render: (text, record) => (
                  <Button
                   type="primary"
                   onClick={
                       ()=>this.props.history.push(RoutePath.PersonalSnapshots + "/" + record.uid)
                   }
                  >
                    详情
                  </Button>
              )
          }
        ];
        return (
            <MyLayout>
                <Row>
                <Col span={24}>
                <Form layout='inline'>
                    <Form.Item label='选择目标班级'>
                       {
                        this.state.classId?
                        <Select
                        defaultValue={this.state.classId} 
                        onChange={
                            (classId)=>
                            {
                                this.setState({classId});
                                this.research();
                            }
                        }>
                        {
                            this.state.clses.map((item,index)=>(
                                <Select.Option value={item.classId} key={index}>{item.name}</Select.Option>
                            ))
                        }
                        </Select>:null
                       }
                    </Form.Item>
                    <Form.Item label='选择目标月份'>
                        <SimpleDateSel
                           picker="month"
                           size="default"
                           value={this.state.yearMonth}
                           onChange={
                               (yearMonth)=>{
                                   this.setState({yearMonth: moment(yearMonth).format("YYYY/MM/DD HH:mm:ss")});
                                   this.research();
                               }
                           }
                        />
                    </Form.Item>
                    <Form.Item label="当月还是累积?">
                            <Checkbox
                            onChange={(e)=>{
                                this.setState({acc:e.target.checked});
                                this.research();
                            }}
                            checked={this.state.acc}>
                            累积</Checkbox>
                        </Form.Item>
                </Form>
                </Col>
                </Row>
                <Table
                  dataSource={this.state.data}
                  columns={columns}
                  rowKey='uid'
                  pagination={false}
              />
            </MyLayout>
        );
    }
}

export default StaticMonthLearn;

