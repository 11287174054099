import React, { Component } from 'react';
import { Button, Progress, Table, Tag,Tabs, List, Card, Tooltip, PageHeader, Divider} from 'antd';
import MyLayout from "../../common/MyLayout";
import api from "../../../api/api";
import MyLearnVideoList from "../student/MyLearnVideoList";
import RoutePath from '../../../config/RoutePath';


const { TabPane } = Tabs;

class StudentTaskDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:null,

            videoList:[],
            paperList:[],
        };
    }

    getMyLearnVideoListByTaskId(mtaskId){
        api.student.getMyLearnVideoListByTaskId(
            {mtaskId},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        videoList:data.data,
                    })
                }
            }
        )
    }

    getMyLearnPaperListByTaskId(mtaskId){
        api.student.getMyLearnPaperListByTaskId(
            {mtaskId},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        paperList:data.data,
                    })
                }
            }
        )
    }

    getTaskDetailById() {
        let mtaskId = this.props.match.params.mtaskId;
        api.taskadmin.getTaskDetailById({mtaskId},
            (state,data)=>{
                if(state && data.status == 200) {

                    if(data.data.taskType == 0)
                    {
                        this.getMyLearnVideoListByTaskId(mtaskId);
                    }
                    else
                    {
                        this.getMyLearnPaperListByTaskId(mtaskId);
                    }

                    this.setState({
                        data:data.data,
                    });
                }
            }
        )
    }

    componentDidMount() {
        this.getTaskDetailById();
    }

    render() {
        const {
            data= null
        } = this.state;
        let history = this.props.history;

        let taskType = 0;
        if(data)
        {
            taskType = data.taskType;
        }

        const columnsVideo = [
          {
                title: '视频名',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow",
           },
            {
                title: '讲师',
                dataIndex: 'teacher',
                key: 'teacher',
                render: (text, record) => (
                    <div>
                        {record.teacher?record.teacher.name:"无"}
                    </div>
                ),
            },
            {
                title: '关键字',
                dataIndex: 'keywords',
                key: 'keywords',
                className:"tableOverflow",
                render: (text, record) => (
                    <Tag color="#87d068">
                        {text?text:"--"}
                    </Tag>
                ),
            },
            //{
            //    title: '访问量',
            //    dataIndex: 'visitTimes',
            //    key: 'visitTimes',
            //    render: (text, record) => (
            //        <Tag color="geekblue">
            //            {text}
            //        </Tag>
            //    ),
            //},
            {
                title: '百分比',
                dataIndex: 'percent',
                key: 'percent',
                className:"tableOverflow",
                render: (text, record,index) => (
                    <Progress percent={record.percent}/>
                )
           },
           {
                title: '操作',
                key: 'actionPlay',
                render: (text, record,index) => (
                    <Button
                    type='primary'
                    onClick={
                        ()=>{
                            history.push(RoutePath.VideoPlay+'/'+record.vid);
                        }
                    }
                    >播放</Button>
                )
         }
        ];

        const columnsPaper = [
            {
                title: '试卷名',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '考试时间',
                dataIndex: 'kssj',
                key: 'kssj',
                className:"tableOverflow",
                render: (text, record) => (
                    <div>
                    <span>{record.startTime?record.startTime:'不限定'}</span>
                    -
                    <span>{record.endTime?record.endTime:'不限定'}</span>
                </div>
                )
            },
            {
                title: '考试时长(分钟)',
                dataIndex: 'duration',
                key: 'duration',
                render: (text, record) => (
                    <div>{record.duration?record.duration+'分钟':'不限定'}</div>
                )
            },
            {
                title: '题目数',
                dataIndex: 'itemNum',
                key: 'itemNum',
            },
            {
                title: '完成百分比',
                dataIndex: 'percent',
                key: 'percent',
                className:"tableOverflow",
                render: (text, record,index) => (
                    <Progress percent={record.percent}/>
                )
            },
			/***
            {
                title: '答题状态',
                dataIndex: 'answerSumbit',
                key: 'answerSumbit',
                render: (text, record) => (
                    record.answerSumbit == 0?
                    <Tag color="#f50">未交卷</Tag>
                    :<Tag color="#108ee9">已交卷</Tag>
                )
            }, 
			***/
            {
                title: '判卷',
                dataIndex: 'judge',
                key: 'judge',
                render: (text, record) => (
                    record.judge == 0?
                    <Tag color="#f50">未判卷</Tag>
                    :<Tag color="#108ee9">已判卷</Tag>
                )
            }, 
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                render: (text, record) => (
                    <Button type='primary'
                        onClick={()=>history.push(
                            "/taskadmin/AnswerDetail/"+record.paperId)
                        }
                    >
                        答卷
                    </Button>
                )
            }
        ];

        return(
            <MyLayout >
                <div>
                    <PageHeader
                        style={{padding:"0"}}
                        title={data!= null?data.taskName:""}
                    />
                    <Divider/>
                    <div className="heading_content">
                        <div className="heading_mes">
                            <p className="mes_title"><span>起止时间：</span>
                                {data!= null?data.startTime:""}-{data!= null?data.endTime:""}
                            </p>
                            <p className="mes_title"><span>任务描述：</span>
                                {data!= null?data.taskDescription:""}
                            </p>
                        </div>
                    </div>
                </div>
                <Divider/>
                <div>
                    {
                        taskType == 0?
                        <Table
                            pagination={false}
                            dataSource={this.state.videoList}
                            columns={columnsVideo}
                        />:<Table
                            pagination={false}
                            dataSource={this.state.paperList}
                            columns={columnsPaper}
                        />
                    }
                </div>
            </MyLayout>
        )
    }
}
export default StudentTaskDetail;