
import { Button, Descriptions, Divider, Form, InputNumber, message, Modal, Tabs } from 'antd';
import React, { Component } from 'react';
import api from '../../api/api';
import PackButton from '../../components/PackButton';
import MyLayout from '../common/MyLayout';
import BizCloseList from './account/BizCloseList';
import BizOrderList from './account/BizOrderList';

class AccountInfo extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            data: {},
            visible:false,
         };
    }

    getAccountInfo()
    {
        let uid = this.props.match.params.uid;
        api.biz.getAccountInfo({uid},
            (state, data)=>{
                if(state && data.status === 200)
                {
                    this.setState({data:data.data});
                }
            }
        );
    }

    componentDidMount()
    {
        this.getAccountInfo();
    }

    handleOk = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.biz.sumbitClose(
                    {num:values.num * 100},
                    (state,data)=>{
                        if(state && data.status === 200)
                        {
                            this.getAccountInfo();
                        }
                        else
                        {
                            message.error("未知错误");
                        }
                })
                this.setState({
                    visible: false,
                });
            }
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        let {data} = this.state;
        const { getFieldDecorator } = this.props.form;
        let avdt = (data.totalIncome - data.totalSumbit)/100;
        let uid = this.props.match.params.uid;
        const formItemLayout = {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
          };

          const tailFormItemLayout = {
            wrapperCol: {
                sm: {
                    span: 10,
                    offset: 8,
                },
            },
        };

        return (
            <MyLayout title='财务信息(若出现还可结算余额小于0,请等待管理员处理)'>

                <Descriptions bordered column={3}>
                        <Descriptions.Item label="总收入金额" >
                            {data.totalIncome/100}元
                        </Descriptions.Item>
                        <Descriptions.Item label="已提交结算金额" >
                            {data.totalSumbit/100}元
                        </Descriptions.Item>
                        <Descriptions.Item label="还可结算余额" >
                            {(data.totalIncome - data.totalSumbit)/100}元
                        </Descriptions.Item>
                        <Descriptions.Item label="已经结算完毕金额" >
                            {data.totalHandle/100}元
                        </Descriptions.Item>
                        <Descriptions.Item label="实际收到的款额" >
                            {data.totalPay/100}元
                        </Descriptions.Item>
                        <Descriptions.Item label="结算" >
                        <Button type='primary'
                            onClick={()=>this.setState({visible:true})}
                            >
                                结算申请
                            </Button>
                        </Descriptions.Item>
                </Descriptions>
                <Divider/>

                <Tabs>
                    <Tabs.TabPane tab="订单列表" key='1'>
                        <BizOrderList uid = {uid}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="结算历史" key='2'>
                        <BizCloseList uid = {uid}/>
                    </Tabs.TabPane>
                </Tabs>
                <Modal
                     title="结算"
                     width={500}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <Form onSubmit={this.handleOk}  {...formItemLayout}>
                       <Form.Item hasFeedback label="申请提现金额(元)">
                            {getFieldDecorator('num', {
                                initialValue:100,
                                rules: [
                                    {
                                        required: true,
                                        message: '必须输入!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (value%100 === 0)
                                            {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '必须为100的倍数',
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (value <= avdt)
                                            {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '余额不足',
                                    },
                                ],
                            })(
                                <InputNumber
                                 placeholder="金额"
                                precision={0} 
                                min={100}
                                step={100}
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            <PackButton
                            type='primary'
                            block
                            onClick={this.handleOk}
                            >确定</PackButton>
                        </Form.Item>
                        </Form>
                </Modal>
            </MyLayout>
        );
    }
}

export default Form.create()(AccountInfo);


