import React, { Component } from 'react';
import {Modal, Button, Icon, Input, Form, Radio, message, Row, Col} from 'antd';
import api from "../../../api/api";
import DataValidate from "../../../config/DataValidate";
import connect from "react-redux/es/connect/connect";
import Action from '../../../actions/Action';


class Bindphoneoremail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            imgenum:1000,
            imagecode:null, //图片验证码
            emailAndPhone:"",// 邮箱或手机号
            validateCode:"", // 动态验证码
            isPAndM:"", //类型
        };
    }

    refreshImage() {
        this.setState({
                imgenum:Math.ceil(Math.random()*1000000)
            }
        )
    }

    getCode() {
        if(this.state.isPAndM == '0') {
            api.user.sendPhoneKey({
                    phone:this.state.emailAndPhone,
                    validatekey:this.state.imagecode,
                },
                (status, data) => {
                    if (status && data.status == 200) {
                        message.info("验证码发送成功");
                    } else {
                        message.error(data.msg);
                    }
                }
            );
        }
        else if (this.state.isPAndM == '1') {
            api.user.sendvalidateemail({
                    email:this.state.emailAndPhone,
                    validatekey:this.state.imagecode,
                },
                (status, data) => {
                    if (status && data.status == 200) {
                        message.info("验证码发送成功");
                    } else {
                        message.error(data.msg);
                    }
                }
            );
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };


    handleOk = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.user.bindphoneoremail(
                    {
                        "email": this.state.isPAndM == 1 ? this.state.emailAndPhone : "",
                        "emailValidateCode": this.state.isPAndM == 1 ? this.state.validateCode : "",
                        "phone": this.state.isPAndM == 1 ?  "" : this.state.emailAndPhone,
                        "phoneValidateCode": this.state.isPAndM == 1 ?  "" : this.state.validateCode,
                    },
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.props.refreshUserInfo();
                            //this.props.changeNewPwd(data.data);// 子向父传值[changeNewPwd={(newPwd)=>this.newPwdFun(newPwd)}]
                            this.setState({
                                visible: false,
                            });
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const phoneCodeValue = (
            <Button style={{ height: 22, border:0,background: "#FAFAFA"}}  onClick={()=>{
                this.getCode();
            }}>获取验证码</Button>
        );
        return (
            <div>
                <Button type="link" onClick={this.showModal}>
                    {this.props.btnName}
                </Button>
                <Modal
                    title="绑定手机/邮箱"
                    width={620}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Form className="modal-form-style">
                        <Form.Item>
                            {getFieldDecorator('emailAndPhone', {
                                rules: [
                                    {
                                        required: true,
                                        message: '手机/邮箱号是必输入的!'
                                    },
                                    {
                                        validator: (rule, value, callback) =>{
                                            if(DataValidate.isMobile(value) ) {
                                                this.setState({isPAndM:"0"});
                                                callback();
                                                return;
                                            }
                                            else if (DataValidate.isEmail(value)){
                                                this.setState({isPAndM:"1"});
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '输入格式不对',
                                    },
                                ],
                            })(
                                <Input size="large"
                                       prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                       placeholder="手机/邮箱"
                                       onChange={(e)=>{
                                           this.setState({
                                               emailAndPhone:e.target.value,
                                               }
                                           )
                                       }}
                                />,
                            )}
                        </Form.Item>
                        <Form.Item >
                            {getFieldDecorator("validate", {
                                rules: [
                                    {
                                        required: true,
                                        message: '验证码必须输入!'
                                    },
                                ],
                            })(
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <Input size="large"
                                               prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                               placeholder="请输入图片验证码"
                                               onChange={(e)=>{
                                                   this.setState({
                                                           imagecode:e.target.value,
                                                       }
                                                   )
                                               }}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <img
                                            onClick={()=>this.refreshImage()}
                                            style={{width: 120, height: 40}}
                                            src={'/api/user/generateCodeImg?imgnum'+this.state.imgenum}/>
                                    </Col>
                                </Row>

                            )}
                        </Form.Item>
                        <Form.Item >
                            {getFieldDecorator('validateCode', {
                                rules: [
                                    {
                                        required: true,
                                        message: '验证码必须输入!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(DataValidate.isMobileCode(value)) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '验证码格式不对',
                                    },
                                ],
                            })(
                                <Input size="large"
                                       prefix={<Icon type="check" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                       placeholder="验证码" addonAfter={phoneCodeValue}
                                       onChange={(e)=>{
                                           this.setState({
                                               validateCode:e.target.value,
                                               }
                                           )
                                       }}
                                />,
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        refreshUserInfo: (data) => dispatch({
            type: Action.GET_USER_INFO,
        }),
    }
}
//export default connect(mapStateToProps,mapDispatchToProps)(Bindphoneoremail);
export default connect(null,mapDispatchToProps)(Form.create({ name: 'Bindphoneoremail' })(Bindphoneoremail));
