//资讯一级分类
const newsClassification = {
    mainClass: {
        100: '不限',
        110: '新闻',
        120: '财经',
        130: '体育',
        140: '娱乐',
        150: '科技',
        160: '汽车',
        170: '时尚',
        180: '游戏',
        190: '房产',
        200: '文化',
        210: '动漫',
        220: '健康',
        230: '公益',
        240: '旅游',
    },

    //资讯二级分类
    subClass: {
        100001: '不限',

        110010: '国际',
        110020: '社会',
        110030: '图片',
        110040: '军事',
        110050: '评论',

        120010: '宏观',
        120020: '金融',
        120030: '证券',
        120040: '港股',
        120050: '美股',

        130010: 'NBA',
        130020: '国际足球',
        130030: '综合体育',

        140010: '明星',
        140020: '封面',
        140030: '星座',
        140040: '电视剧',
        140050: '综艺',
        140060: '电影',

        150010: '智酷',
        150020: '移动',
        150030: 'IT',
        150040: '数码',

        160010: '热点',

        170010: '视频',
        170020: '服饰',
        170030: '美容',

        180010: '网游',
        180020: '主机',
        180030: '手游',
        180040: '电竞',
        180050: '行业',

        190010: '租赁',
        190020: '家电',
        190030: '楼盘',
        190040: '装修',

        200010: '艺术',
        200020: '教育',
        200030: '出国',
        200040: '高考',

        210010: '漫画',
        210020: '儿童',
        210030: '活动',
        210040: '视频',

        220010: '资讯',
        220020: '养生',
        220030: '视频',
        220040: '育儿',
        220050: '孕产',

        230010: '基金会',
        230020: '新国风',

        240010: '城市',
        240020: '天气',
        240030: '影展',
        240040: '视频',
    },
};

export default newsClassification;
