import React from 'react'
import {Tabs, Tag} from 'antd'
import getClassification from '../newsedit/getClassification'

const {TabPane} = Tabs
const {CheckableTag} = Tag

class ClassificationSelect extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: null,
            tabValue: getClassification(),
        }
    }

    handleChange = checked => {
        if (this.props.onChange) {
            checked = checked === '100001' || checked === '100' ? null : checked
            this.props.onChange(checked)
            this.setState({checked})
        }
    }
    render() {
        return (
            <Tabs
                defaultActiveKey="1"
                onChange={e => {
                    this.handleChange(e)
                }}
            >
                {this.state.tabValue.map(item => {
                    return (
                        <TabPane tab={item.label} key={item.value}>
                            {item.children.map(childrenItem => {
                                if (childrenItem.label === '不限') {
                                    childrenItem.label = null
                                }
                                return (
                                    <CheckableTag
                                        checked={
                                            this.state.checked ===
                                            childrenItem.value
                                        }
                                        onChange={() => {
                                            this.handleChange(
                                                childrenItem.value
                                            )
                                        }}
                                        key={childrenItem.value}
                                    >
                                        {childrenItem.label}
                                    </CheckableTag>
                                )
                            })}
                        </TabPane>
                    )
                })}
            </Tabs>
        )
    }
}

export default ClassificationSelect
