import React, { Component } from 'react';
import {Descriptions,Divider, Row, Col,message,Button, Modal,Form, Input} from 'antd';
import MyLayout from "../../common/MyLayout";

import { connect } from 'react-redux';
import api from '../../../api/api';
import Action from '../../../actions/Action';

class BankAccount extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            visible:false,
         };
    }


    handleOk = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.user.editMyUserInfo(
                    values,
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.props.refreshUserInfo();
                            this.setState({
                                visible: false,
                            });
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        let data = this.props.userInfo;
        if(!data)
        {
            data = {};
        }
        return (
            <MyLayout sider={"UserSettingSider"}>
               <p className="userEditTitle">我的银行账号</p>
               <Button onClick={
                   ()=>{
                    let form = this.props.form;
                    form.setFieldsValue(data);
                    this.setState({visible:true});
                   }
               }>修改银行账号</Button>
               <Divider dashed/>
               <Descriptions bordered column={1}>
                    <Descriptions.Item label="开户行">
                    {data && data.bankName ? data.bankName:"--"}
                    </Descriptions.Item>
                    <Descriptions.Item label="户名">
                    {data && data.bankAccountName ? data.bankAccountName:"--"}
                    </Descriptions.Item>
                    <Descriptions.Item label="账号">
                    {data && data.bankAccount ? data.bankAccount:"--"}
                    </Descriptions.Item>
               </Descriptions>
               <Modal
                    title="修改银行账号信息"
                    width={820}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
               >
                    <Form onSubmit={this.handleOk}>
                       <Form.Item hasFeedback label="开户行">
                            {getFieldDecorator('bankName', {
                                rules: [
                                    {
                                        required: true,
                                        message: '必须输入!'
                                    },
                                ],
                            })(
                                <Input  placeholder="开户行"/>,
                            )}
                        </Form.Item>
                        <Form.Item hasFeedback label="户名">
                            {getFieldDecorator('bankAccountName', {
                                rules: [
                                    {
                                        required: true,
                                        message: '必须输入!'
                                    },
                                ],
                            })(
                                <Input  placeholder="户名"/>,
                            )}
                        </Form.Item>
                        <Form.Item hasFeedback label="账号">
                            {getFieldDecorator('bankAccount', {
                                rules: [
                                    {
                                        required: true,
                                        message: '必须输入!'
                                    },
                                ],
                            })(
                                <Input  placeholder="账号"/>,
                            )}
                        </Form.Item>
                    </Form>
               </Modal>
            </MyLayout>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        refreshUserInfo: (data) => dispatch({
            type: Action.GET_USER_INFO,
        }),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Form.create( )(BankAccount));
