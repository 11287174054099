import React, { Component } from 'react';
import { Popover, Col,Form, Checkbox, Radio, Input, Tabs,Button, message,Row, Divider} from 'antd';
import api from "../../../api/api";
import '../../../assets/style/common.css';
import PackButton from '../../../components/PackButton';

const { TabPane } = Tabs;
const { TextArea } = Input;

/************* 答题页面答案 ****************/
class AnserPageModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tempValue:null,
            checkboxValue:[]
        }
    }

    answerValueSearch(){
        let data = {
            choicesValue:"",
            ansitemId:""
        };
        let answerDetail = this.props.answerDetail;
        let anserItem = this.props.anserItem;
        if (answerDetail){
            for (let i = 0; i < answerDetail.examAnsitems.length; i++) {
                if (answerDetail.examAnsitems[i].questionId === anserItem.questionId) {
                    let ansContent = answerDetail.examAnsitems[i].ansContent;
                    let ansitemId = answerDetail.examAnsitems[i].ansitemId;
                    data = {
                        choicesValue:ansContent,
                        ansitemId:ansitemId
                    };
                    break;
                }
            }
        }
        return data;
    }

    addOrEditAnser(value){
        let data = this.answerValueSearch();
        let ansContent = value;
        if (typeof(value)=== "object"){
            ansContent = value.join('');
        }

        api.exam.addOrEditAnser(
            {
                "ansContent": ansContent,
                "ansitemId": data.ansitemId,
                "answerId": this.props.answerDetail.answerId,
                "questionId": this.props.anserItem.questionId,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    let data = {
                        state:state,
                        activeKey:parseInt(this.props.activeKey) + 1
                    };
                    this.props.onChange(data);
                }else{
                    message.error(data.msg);
                }
            }
        )
    }

    //onChange=(checkedValues)=> {
    // };

    render() {
        let anserItem = this.props.anserItem;
        let data = this.answerValueSearch();
        let questionType = this.props.questionType;

        return (
            <div>
                {
                    //单选题
                    questionType === 0?
                    <>
                        <Radio.Group style={{ width: '100%' }}
                                     value={this.state.tempValue?this.state.tempValue:data.choicesValue}
                                     onChange={(e)=>{
                                         this.setState({tempValue:e.target.value})
                                     }}>
                            {anserItem.choices.map((item,index) => (
                                <Row className="choices">
                                    <Col span={2}>
                                        <Radio value={item.tmark}>
                                            {item.tmark+"、"}
                                        </Radio>
                                    </Col>
                                    <Col span={22}>
                                     <div dangerouslySetInnerHTML={{__html:item.content}}/>
                                    </Col> 
                                </Row>
                            ))}
                        </Radio.Group>
                    </>:null
                }

                {
                    //多选，不定项选择
                    this.props.questionType === 1 
                    || this.props.questionType === 2?
                    <>
                        <Checkbox.Group style={{ width: '100%' }}
                                        value={this.state.tempValue?this.state.tempValue:data.choicesValue.split('')}
                                        onChange={(e)=>{
                                            this.setState({tempValue:e})
                                        }}>
                            {anserItem.choices.map((item,index) => (
                                <Row className="choices">
                                    <Col span={2}>
                                        <Checkbox value={item.tmark}>
                                            {item.tmark}
                                        </Checkbox>
                                    </Col>
                                    <Col span={22}>
                                     <div dangerouslySetInnerHTML={{__html:item.content}}/>
                                    </Col> 
                                </Row>
                            ))}
                        </Checkbox.Group>
                    </>:null
                }

                
                {
                    //填空，大题
                    this.props.questionType ===3 
                    ||this.props.questionType ===4?
                    <TextArea rows={4} placeholder="请输入答案"
                                value={this.state.tempValue?this.state.tempValue:data.choicesValue}
                                onChange={(e)=>{
                                    this.setState({tempValue:e.target.value})
                    }}/>:null
                }
               
                {
                    //判断题
                    questionType === 5?
                    <>
                        <Radio.Group style={{ width: '100%' }}
                                     value={this.state.tempValue?this.state.tempValue:data.choicesValue}
                                     onChange={(e)=>{
                                         this.setState({tempValue:e.target.value})
                                     }}>
                            <Row className="choices">
                                <Col>
                                    <Radio value="T">这种说法正确</Radio>
                                </Col>
                            </Row>
                            <Row className="choices">                     
                                <Col>
                                    <Radio value="F">这种说法错误</Radio>
                                </Col>
                            </Row>
                        </Radio.Group>
                    </>:null
                }

                <Divider/>
                <PackButton type="primary"
                        onClick={
                            ()=>{
                                let answer = this.state.tempValue?
                                    this.state.tempValue:
                                    data.choicesValue;
                                this.addOrEditAnser(answer);
                            }
                        }
                        clickDelayTime={500}
                >提交答案并下一题</PackButton>
                 {
                        this.props.examType === 0?
                        <>
                            <Divider type='vertical'/>
                            <Popover
                                content={<div   style={{color:'red'}} dangerouslySetInnerHTML={{__html:anserItem.examQuestion.standardAnswer}}></div>}
                                trigger='click'
                                title='标准答案'
                            >
                                <Button type='primary'>看答案</Button>
                            </Popover>
                        </>:null
                 }
            </div>
        );
    }
}

export default Form.create()(AnserPageModal);