import React, { Component } from 'react';
import {Modal, Input, List, Pagination} from 'antd';
import api from "../api/api";

/************* 会场Popover ****************/
class MyVenueModel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            orgName:"",
            orgIds: [],
            visible: false,

            data:[],
            pageNum:1,
            pageSize:8,
            totalNum:9,
        }
    }

    myVenueList(){
        api.lecture.myVenueList({
                "condition": {
                    "name": this.state.name,
                    "orgName":this.state.orgName,
                    "orgIds": this.state.orgIds,
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    venueDetail(venueId) {
        api.lecture.venueDetail({venueId},
            (state,data)=>{
                if(state && data.status == 200) {
                    let rowsAndCols = {};
                    rowsAndCols.rows = data.data.model.rows;
                    rowsAndCols.cols = data.data.model.cols;
                    this.props.rowsColsFun(rowsAndCols);
                }
            }
        )
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.myVenueList(), 200);
    }

    listClick(name,id) {
        this.venueDetail(id);
        this.setState({
            name:name,
            visible: false,
        });
        this.props.onChange(id)
    }

    componentDidMount() {
        this.myVenueList();
    }

    render() {
        if (this.props.value && this.props.value.id && this.props.value.name){
            this.props.onChange(this.props.value.id);
            this.venueDetail(this.props.value.id);
            this.setState({
                name: this.props.value.name,
            })
        }
        return (
            <div>
                <Input size="large"
                       placeholder="会场选择"
                       value={this.state.name}
                       onClick={()=>{
                           this.setState({
                               visible: true,
                           })}
                       }/>
                <Modal
                    title="会场选择"
                    visible={this.state.visible}
                    onCancel={()=>{
                        this.setState({
                            visible:false
                        })
                    }}
                    footer={null}
                >
                    <List
                        size="large"
                        dataSource={this.state.data}
                        renderItem={item =>
                            <List.Item onClick={()=>this.listClick(item.name,item.id)}>
                                <a>{item.name}</a>
                            </List.Item>}
                    />
                    <div style={{paddingTop:"25px"}}>
                        <Pagination
                            onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.state.pageSize}
                            current={this.state.pageNum}
                            total={this.state.totalNum}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default MyVenueModel;