import React, { Component } from 'react';
import {Form, Modal, Table, Pagination, Input, Button, message, Icon, Divider, Switch, PageHeader} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import DataValidate from "../../../config/DataValidate";
import ChangePwdModal from "./ChangePwdModal";
import ComFunction from '../../../api/ComFunction';

const { confirm } = Modal;

class GetOrgStaffs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password:"",
            visible:false,
            data:null,
            orgData:null
        };
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    handleOk = () => {
        let orgId = this.props.match.params.orgId;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.staff.addNewStaff(
                    {orgId, ...values},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.setState({
                                visible: false,
                            });
                            this.props.form.resetFields();
                            setTimeout(()=>this.getOrgStaffsByOrgId(), 200,)
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });

    };

    updateOrgDetail() {
        let orgId = this.props.match.params.orgId;
        api.org.orgDetail({orgId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        orgData:data.data,
                    })
                }
            }
        )
    }

    getOrgStaffsByOrgId(){
        let orgId = this.props.match.params.orgId;
        api.staff.getOrgStaffsByOrgId({
                "orgId":orgId,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    swichAvailability(status,uid){
        let title = "";
        let content = "";
        if (status == 0){
            title = "你确定要把这个用户设置成[无效]吗?";
            content = "用户设置成[无效]后该用户不可登录。"
        }else{
            title = "你确定要把这个用户设置成[有效]吗?";
            content = "用户设置成[有效]后该用户方可登录！"
        }
        confirm({
            title: title,
            content:content,
            onOk:()=>{
                api.staff.switchStaffAvailability({
                        "uid": uid,
                        "status": status
                    },
                    (state,data)=>{
                        if(state && data.status == 200) {
                            setTimeout(()=>this.getOrgStaffsByOrgId(), 200,);
                            message.success('设置成功!');
                        }
                    }
                )
            },
            onCancel() {
            },
        });
    }

    deleteStaff(uid){
        confirm({
            title: "你确定要删除这个员工吗?",
            content:"用户删除成功后将不可登录!",
            onOk:()=>{
                api.staff.deleteStaff({
                        "uid": uid,
                    },
                    (state,data)=>{
                        if (state) {
                            if(data.status == 200) {
                                setTimeout(()=>this.getOrgStaffsByOrgId(), 200,);
                                message.success('删除成功!');
                            }
                             else if (data.status == 6000250){
                               // message.error('该员工已经被委任为教学人员,不能删除.请先从任课班级中移除后在删除!');
                                Modal.error({
                                    title: '提示',
                                    content: '该员工已经被委任为教学人员,不能删除.请先从任课班级中移除后在删除!',
                                });
                             }
                        }
                    }
                )
            }
        });
    }

    componentDidMount() {
        this.getOrgStaffsByOrgId();
        this.updateOrgDetail();
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const {orgData} = this.state;
        const columns = [
            {
                title: '员工名',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '员工ID',
                dataIndex: 'uid',
                key: 'uid',
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                key: 'phone',
                render: (text, record) => (
                    <span>
                        {text?text:"未绑定"}
                    </span>
                ),
            },
            {
                title: '邮箱',
                dataIndex: 'email',
                key: 'email',
                render: (text, record) => (
                    <span>
                        {text?text:"未绑定"}
                    </span>
                ),
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime',
                key: 'createdTime',
                sorter: (a, b) => ComFunction.compareTwoDate(a.createdTime,b.createdTime),
                sortDirections: ['descend', 'ascend'],
                render:(text,record)=>(ComFunction.getShortTime(text)),
            },
            {
                title: '更新时间',
                dataIndex: 'updatedTime',
                key: 'updatedTime',
                sorter: (a, b) => ComFunction.compareTwoDate(a.updatedTime,b.updatedTime),
                sortDirections: ['descend', 'ascend'],
                render:(text,record)=>(ComFunction.getShortTime(text)),
            },
            {
                title: '修改密码',
                key: 'newpwd',
                render: (text, record) => (
                    <span>
                        <ChangePwdModal btnName={"修改密码"} UID={record.uid}/>
                    </span>
                ),
            },
            {
                title: '用户状态',
                key: 'status',
                dataIndex: 'status',
                render: (text, record) => (
                    <span>
                        {
                            <Switch checked={text==0?false:true}
                                    checkedChildren="启用"
                                    unCheckedChildren="禁用"
                                    onChange={()=>this.swichAvailability(text==0?1:0,record.uid)}
                            />}
                    </span>
                ),
            },
            {
                title: '删除员工',
                key: 'action',
                render: (text, record) => (
                    <a onClick={()=>this.deleteStaff(record.uid)}>删除员工</a>
                ),
            }
            ];
        return(
            <MyLayout>
                <PageHeader
                    style={{padding:"0"}}
                    title={orgData?orgData.orgName:""}
                    subTitle="员工管理"
                />
                <Divider/>
                <div>
                    <Button type="primary"
                            onClick={()=>{
                                this.setState({
                                    visible: true,
                                })
                            }}>
                        添加员工
                    </Button>
                    <Divider dashed/>
                    <Table
                        pagination={false}
                        dataSource={this.state.data}
                        columns={columns}
                    />
                </div>
                <div>
                    <Modal
                        title="添加新员工"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <Form className="modal-form-style">
                            <Form.Item hasFeedback>
                                {getFieldDecorator('name', {
                                    initialValue:"",
                                    rules: [
                                        {
                                            required: true,
                                            validateStatus:'error',
                                            message: '请输入员工名!'
                                        },
                                        {
                                            validator: (rule, value, callback) => {
                                                if(DataValidate.isChineseName(value) || DataValidate.isEnglishName(value)) {
                                                    callback();
                                                    return;
                                                }
                                                callback('error');
                                            },
                                            message: '员工名格式错误(只支持标准中、英文姓名.中文名不能含有空格,英文名可以!)',
                                        },
                                    ],
                                })(
                                    <Input size="large"
                                           prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                           placeholder="员工名"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item hasFeedback>
                                {getFieldDecorator('uid', {
                                    initialValue:"",
                                    rules: [
                                        {
                                            required: true,
                                            validateStatus:'error',
                                            message: '请输入员工ID(ID:6-16位数字和字母)!'
                                        },
                                        {
                                            validator: (rule, value, callback) => {
                                                if(DataValidate.isUid(value)) {
                                                    callback();
                                                    return;
                                                }
                                                callback('error');
                                            },
                                            message: '员工ID格式错误!',
                                        },
                                        {
                                            validator: (rule, value, callback) => {
                                                api.user.basicinfocheck({
                                                        "checkItem": value,
                                                        "type": 0
                                                    },
                                                    (state,data)=>{
                                                        if(state && data.status == 200) {
                                                            callback();
                                                        }
                                                        else  {
                                                            callback('error');
                                                        }
                                                    });
                                            },
                                            message: '员工ID已经被注册或者无效.(6~16位数字加字母组合)！',
                                        },
                                    ],
                                })(
                                    <Input size="large"
                                           prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                           placeholder="员工ID.(uid:6-16位数字和字母)"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item  hasFeedback>
                                {
                                    getFieldDecorator('password', {
                                        rules: [
                                            {
                                                required: true,
                                                validateStatus:'error',
                                                message: '密码必须输入!'
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if(DataValidate.isPassWord(value)) {
                                                        callback();
                                                        return;
                                                    }
                                                    callback('error');
                                                },
                                                message: '密码格式不对(6~16位数字加字母组合)',
                                            },
                                        ],
                                    })(
                                        <Input size="large"
                                               prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                               type="password"
                                               onChange={(e)=>{
                                                   this.setState({
                                                       password:e.target.value,
                                                   })
                                               }}
                                               placeholder="请设置6-16位密码"
                                        />,
                                    )}
                            </Form.Item>
                            <Form.Item  size="large" hasFeedback>
                                {
                                    getFieldDecorator('password2', {
                                        rules: [
                                            {
                                                required: true,
                                                validateStatus:'error',
                                                message: '第二次密码必须输入!'
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if(DataValidate.isPassWord(value)) {
                                                        callback();
                                                        return;
                                                    }
                                                    callback('error');
                                                },
                                                message: '第二次密码格式不对',
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if(value == this.state.password) {
                                                        callback();
                                                        return;
                                                    }
                                                    callback('error');
                                                },
                                                message: '两次密码输入不相同',
                                            },
                                        ],
                                    })(
                                        <Input size="large"
                                               prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                               type="password"
                                               placeholder="请再次输入6-16位密码"
                                        />,
                                    )}
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </MyLayout>
        )
    }
}

export default Form.create()(GetOrgStaffs);