
import React, { Component } from 'react';
import MyLayout from '../../../common/MyLayout';
import { connect } from 'react-redux';
import api from '../../../../api/api';
import { Steps, Col, Row, Button, Result, Divider } from 'antd';
import ImageUpload from '../../../../components/ImageUpload';
import Action from '../../../../actions/Action';

class PlateformValidate extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            plateformFilepath:null,
            plateformAuth:0,
         };
    }


    editMyUserInfo()
    {
        api.user.editMyUserInfo(
            {plateformFilepath:this.state.plateformFilepath},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.props.refreshUserInfo();
                } else {
                }
            }
        )
    }


    render() {
        let userInfo = this.props.userInfo;
        return (
            <MyLayout sider={"UserSettingSider"}>
            {
                    userInfo?
                    <>
                        <Steps current={userInfo.plateformAuth}>
                            <Steps.Step key={0} title="材料提交"></Steps.Step>
                            <Steps.Step key={1} title="审核中"></Steps.Step>
                            <Steps.Step key={2} title="审核完成"></Steps.Step>
                        </Steps>
                        {
                            userInfo.plateformAuth == 0?
                            <Row style={{marginTop:'20px'}}>
                            <Col span={10}>

                            </Col>
                            <Col span={8}>
                                <div>
                                <ImageUpload 
                                   value={this.state.plateformFilepath?this.state.plateformFilepath:userInfo.plateformFilepath}
                                   onChange={(plateformFilepath)=>this.setState({plateformFilepath})}
                                />
                                </div>
                                <div>请上传营业执照正面照</div>
                                <Divider/>
                                <Button 
                                type='primary'
                                disabled={this.state.plateformFilepath === null}
                                onClick={()=>this.editMyUserInfo()}
                                >实名认证</Button>
                            </Col>
                            </Row>
                            :null
                        }
                        {
                            userInfo.plateformAuth == 1?
                            <>
                                <Result
                                    title="营业执照认证审核中"
                                />
                            </>
                            :null
                        }

                        {
                            userInfo.plateformAuth == 2?
                            <>
                                <Result
                                    status = 'success'
                                    title="营业执照认证完成"
                                />
                            </>
                            :null
                        }
                    </>
                    :
                    null
                }

            </MyLayout>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        refreshUserInfo: (data) => dispatch({
            type: Action.GET_USER_INFO,
        }),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(PlateformValidate);

