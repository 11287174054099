import React, { Component } from 'react';
import {Icon, Row, Col} from 'antd';
import MyLayout from "../../common/MyLayout";
import UserSettingSider from "../../common/sider/UserSettingSider";
import api from "../../../api/api";
import ResetPassword from "../../user/userSetting/ResetPassword";
import EditMyPwd from "../../user/userSetting/EditMyPwd";

class UserInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalShow:false,
            changeNewPwd:""
        };
    }

    newPwdFun(newPwd){
        this.setState({
            changeNewPwd:newPwd
        })
    }

    render() {
        return (
            <MyLayout sider={"UserSettingSider"}>
                <div>
                    <p className="userEditTitle">我的安全服务</p>
                    <div className="userEditInfo">
                        <Row gutter={16}>
                            <Col span={4}>
                                <p><Icon type="edit" /> 密码修改:</p>
                            </Col>
                            <Col span={10}>
                                <p>安全性高的密码可以使账号更安全。建议您定期更换密码，且设置一个包含数字和字母，并长度超过6位以上的密码</p>
                            </Col>
                            <Col span={6}>
                                <EditMyPwd btnName={"修改"} />
                            </Col>
                        </Row>
                        {/*
                        <Row gutter={16}>
                            <Col span={4}>
                                <p ><Icon type="key" />密码重置(找回):</p>
                            </Col>
                            <Col span={10}>
                                <p>如果您忘记了密码，可以使用重置密码功能来获取一个新的随机密码，随机密码生成后，请尽快修改密码。
                                </p>
                            </Col>
                            <Col span={6}>
                                <ResetPassword btnName={"重置"} changeNewPwd={(newPwd)=>this.newPwdFun(newPwd)}/>
                            </Col>
                        </Row>
                        {
                            this.state.changeNewPwd !="" ?
                                <Row gutter={16}>
                                    <Col span={4}>
                                        <p >新密码:</p>
                                    </Col>
                                    <Col span={5}>
                                        <p style={{ color: '#F00' }}>{this.state.changeNewPwd}</p>
                                    </Col>
                                    <Col span={6}>
                                        <p className="blink">请记住随机密码并及时更新新密码</p>
                                    </Col>
                                </Row> :""
                        }
                        */}
                    </div>
                </div>
            </MyLayout>
        );
    }
}
export default UserInfo;