import React, { Component } from 'react';
import {Col, Row, Form, Modal, Table, Pagination, Divider, Input, Button, message, PageHeader} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import majorData from "../../../config/majorData";
import AddNewClass from "../class/addNewClass";

const { confirm } = Modal;
const InputGroup = Input.Group;

class SearchClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name:"",
            data:[],
            orgData:[],

            pageNum:1,
            pageSize:8,
            totalNum:9,
        };
    }

    updateOrgDetail() {
        let orgId = this.props.match.params.orgId;
        api.org.orgDetail({orgId},
            (state,data)=>{
                if(state && data.status === 200) {
                    this.setState({
                        orgData:data.data,
                    })
                }
            }
        )
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.searchClass(), 200);
    }

    searchClass() {
        api.class.searchClass({
                "condition": {
                    "classId": "",
                    "name": this.state.name,
                    "orgId": this.props.match.params.orgId,
                    "subjectId":""
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status === 200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    onChange(ar){
        if (ar){
            this.searchClass();
        }
    }

    deleteClass(classId){
        confirm({
            title: "你确定要删除这个班级吗?",
            content:"只有在本班级没有学生，并且没有添加任课老师的情况下可删除，否则不能删除!",
            onOk:()=>{
                api.class.deleteClass({
                        "classId": classId,
                    },
                    (state,data)=>{
                        if (state) {
                            if(data.status === 200) {
                                setTimeout(()=>this.searchClass(), 200,);
                                message.success('删除成功!');
                            }
                             else if (data.status === 6000270){
                                Modal.error({
                                    title: '提示',
                                    content: '该班级下已经添加任课老师,不能删除,请先卸任该班级下的所有任课老师后再进行删除操作!',
                                });
                             }
                        }
                    }
                )
            }
        });
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:4,
            totalNum:9,
        });
        setTimeout(()=>this.searchClass(), 200,);
    }

    componentDidMount() {
        this.searchClass();
        this.updateOrgDetail();
    }

    render() {
        let history = this.props.history;
        const {orgData} = this.state;
        const columns = [
            {
                title: '班级名称',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow"
            },
            {
                title: '班级编号',
                dataIndex: 'classId',
                key: 'classId',
            },
            {
                title: '班级所属专业',
                dataIndex: 'subjectId',
                key: 'subjectId',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 60,
                        }
                    }
                },
                render: (text, record) => (
                    <span>
                        {majorData.getValue(text,'')}
                    </span>
                ),
            },
            {
                title: '学生数',
                dataIndex: 'studentNumOk',
                key: 'studentNumOk',
            },
            {
                title: '老师数',
                dataIndex: 'staffNum',
                key: 'staffNum',
            },
            {
                title: '创建日期',
                dataIndex: 'createdTime',
                key: 'createdTime',
                render: (text, record) => (
                    <span>
                        {text.substring(0,10)}
                    </span>
                ),
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>  
                            <AddNewClass type="edit"
                                         orgId={this.props.match.params.orgId}
                                         classId ={record.classId}
                                         onChange={(ar)=>this.onChange(ar)}/>
                            
                            <Divider type='vertical'/>
                            <Button
                            type='danger' 
                            onClick={()=>this.deleteClass(record.classId)}>
                            删除</Button>
                            <Divider type='vertical'/>
                            <Button onClick={()=>history.push(
                                '/class/getClassStaffs/'+record.classId+'/'+this.props.match.params.orgId)}
                            >老师管理</Button>
                        
                    </span>
                ),
            }
        ];
        return(
            <MyLayout>
                <PageHeader
                    style={{padding:"0"}}
                    title={orgData?orgData.orgName:""}
                    subTitle="班级管理"
                />
                <Divider />
                <Row gutter={15}>
                    <Col span={20} >
                        <InputGroup compact>
                            <span style={{ width: '40%'}}>
                                <Input defaultValue={this.state.name}
                                       placeholder='请输入班级名称'
                                       onChange={(e)=>this.setState({name:e.target.value})}
                                />
                            </span>
                            <div>
                                <Button type='primary'
                                        onClick={()=>this.research()}>
                                    搜索</Button>
                            </div>
                        </InputGroup>
                    </Col>
                    <Col span={4} >
                        <AddNewClass type="add"
                                     orgId={this.props.match.params.orgId}
                                     classId =""
                                     onChange={(ar)=>this.onChange(ar)}/>
                    </Col>
                </Row>
                <Divider dashed/>
                <Table
                    pagination={false}
                    rowKey="classId"
                    dataSource={this.state.data}
                    columns={columns}
                />
                <div style={{paddingTop:"25px"}}>
                    <Pagination
                        onChange={(pageNum,pageSize)=>this.pageOnChange(
                            pageNum,pageSize
                        )}
                        pageSize={this.state.pageSize}
                        current={this.state.pageNum}
                        total={this.state.totalNum}
                    />
                </div>
            </MyLayout>
        )
    }
}
export default Form.create()(SearchClass);