
import React, { Component } from 'react';
import MyLayout from '../../common/MyLayout';
import api from '../../../api/api';
import Dict from '../../../config/Dict';
import { Table, PageHeader, Divider } from 'antd';

class PageSearchSignup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
            orgData:{},
        };

        this.initPageSize = 8;
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        this.totalNum = 10000;
    }

    fetachSignUpList(){
        let orgId = this.props.match.params.orgId;
        api.signup.pageSerchSignUp(
            {
                "condition": {
                    orgId
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    updateOrgDetail() {
        let orgId = this.props.match.params.orgId;
        api.org.orgDetail({orgId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        orgData:data.data,
                    })
                }
            }
        )
    }

    componentDidMount()
    {
        this.updateOrgDetail();
        this.fetachSignUpList();
    }

    pageOnChange(pageNum,pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(()=>this.fetachSignUpList(), 200);
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        setTimeout(()=>this.fetachSignUpList(), 200);
    }

    render() {
        const columns = [
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow"
            },
            {
                title: '电话',
                dataIndex: 'phone',
                key: 'phone',
                className:"tableOverflow"
            },
            {
                title: '性别',
                dataIndex: 'sex',
                key: 'sex',
                className:"tableOverflow",
                render: (text, record) => (
                    <span>
                        {Dict.getValue("sex",text,'--')}
                    </span>
                ),
            },
            {
                title: '身份证号',
                dataIndex: 'idNumber',
                key: 'idNumber',
                className:"tableOverflow"
            },
            {
                title: '拟报考专业',
                dataIndex: 'major',
                key: 'major',
                className:"tableOverflow"
            },
            {
                title: '考生类别',
                dataIndex: 'category',
                key: 'category',
                className:"tableOverflow"
            },
            {
                title: '申请日期',
                dataIndex: 'createTime',
                key: 'createTime',
                className:"tableOverflow"
            },
        ];
        
        return (
            <MyLayout>
                <PageHeader
                    style={{padding:"0"}}
                    title={this.state.orgData?this.state.orgData.orgName:""}
                    subTitle="报考学生列表"
                />
                <Divider />
                <Table
                    pagination={false}
                    rowKey="dataId"
                    dataSource={this.state.data}
                    columns={columns}
                />
            </MyLayout>
        );
    }
}

export default PageSearchSignup;
