import React, { Component } from 'react';
import { Icon, Tooltip, Tag, Select,Form, Table, Divider, PageHeader, Button, Modal, Input, message} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import SimpleDateShowTime from "../../../components/SimpleDateShowTime";
import TextArea from 'antd/lib/input/TextArea';
import ComFunction from '../../../api/ComFunction';

const { confirm } = Modal;

class GetMyCreatedTasks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            data:[],
        };
    }

    getMyCreatedTasks() {
        api.taskadmin.getMyCreatedTasks(null,
            (state, data)=>{
                if(state && data.status === 200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    handleSumbit=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let cbk = (state,data)=>{
                    if(state && data.status === 200) {
                        this.setState({
                            visible: false,
                        });
                        this.getMyCreatedTasks();
                    } else {
                        message.error(data.msg);
                    }
                };
                if(values.mtaskId)
                {
                    api.taskadmin.editTask(values,cbk);
                }
                else
                {
                    api.taskadmin.createNewTask(values,cbk);
                }
            }
        });
    }

    removeTaskById(mtaskId){
        confirm({
            title: "提示",
            content:"你确定要删除这个任务吗?",
            onOk:()=>{
                api.taskadmin.removeTaskById({
                        "mtaskId": mtaskId,
                    },
                    (state,data)=>{
                        if (state) {
                            if(data.status === 200) {
                                setTimeout(()=>this.getMyCreatedTasks(), 200,);
                                message.success('删除成功!');
                            }
                            else{
                                message.error(data.msg);
                            }
                            /*
                            else if (data.status == 6000270){
                                Modal.error({
                                    title: '提示',
                                    content: '该班级下已经添加任课老师,不能删除,请先卸任该班级下的所有任课老师后再进行删除操作!',
                                });
                            }
                            */
                        }
                    }
                )
            }
        });
    }

    componentDidMount() {
        this.getMyCreatedTasks();
    }

    render() {
        let history = this.props.history;
        const { getFieldDecorator } = this.props.form;
        getFieldDecorator('mtaskId',{});

        const formItemLayout = {
            labelCol: {
                sm: { span: 4 },
            },
            wrapperCol: {
                sm: { span: 18},
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                sm: {
                    span: 8,
                    offset: 4,
                },
            },
        };

        const columns = [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                className:"tableOverflow",
                render: (text, record,index) => (
                    <div>{index+1}</div>
                ),
            },
            {
                title: '任务名',
                dataIndex: 'taskName',
                key: 'taskName',
                className:"tableOverflow",
                render: (text, record) => (
                    <Tooltip
                    title={text}
                    >{text}</Tooltip>
                ),
            },
            {
                title: '任务类型',
                dataIndex: 'taskType',
                key: 'taskType',
                render: (text, record) => (
                    record.taskType === 0?
                    <Tag color="orange">视频</Tag>
                    :<Tag color="cyan">试题</Tag>
                ),
            },
            {
                title: '可见性',
                dataIndex: 'visible',
                key: 'visible',
                render: (text, record) => (
                    record.visible === 0?
                    <Tag color="blue">可见</Tag>
                    :<Tag color="red">不可见</Tag>
                ),
            },
            {
                title: '参与人数',
                dataIndex: 'joinNum',
                key: 'joinNum',
            },
            {
                title: '子项目数',
                dataIndex: 'itemNum',
                key: 'itemNum',
                render: (text, record) => (
                    <Button 
                    type='link'
                    onClick={()=>history.push(
                        '/taskadmin/TaskDetail/'+record.mtaskId)}
                    >{text}</Button>
                ),
            },
            {
                title: '开始时间',
                dataIndex: 'startTime',
                key: 'startTime',
                render: (text, record) => (ComFunction.getShortTime(text))
            },
            {
                title: '结束时间',
                dataIndex: 'endTime',
                key: 'endTime',
                render: (text, record) => (ComFunction.getShortTime(text))
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button 
                            onClick={()=>{
                                this.props.form.setFieldsValue(record);
                                this.setState({
                                    visible: true,
                                });
                            }}
                            type='ghost'
                        ><Icon type='edit'/></Button>
                        <Divider type="vertical" />
                        <Button 
                            onClick={
                              ()=>this.removeTaskById(record.mtaskId)
                            }
                            type='danger'
                        >删除</Button>
                        <Divider type="vertical"/>
                        <Button type='primary'
                         onClick={()=>history.push(
                        '/taskadmin/TaskDetail/'+record.mtaskId)}
                        >任务详情...</Button>
                    </span>
                ),

            }
        ];

        return(
            <MyLayout>
                <PageHeader
                    style={{padding:"0"}}
                    title="任务列表"
                    subTitle="我的任务列表"
                />
                <Divider/>
                <Button type="primary"
                        onClick={()=>{
                            this.props.form.setFieldsValue({
                                    mtaskId:null,
                                    taskType:0,
                                    taskName:null,
                                    startTime:null,
                                    endTime:null,
                                    taskDescription:null,
                                    });
                            this.setState({
                                visible: true,
                            })
                        }}>
                    添加任务
                </Button>
                <Divider dashed/>
                <Table
                    rowKey='mtaskId'
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
                    <Modal
                        title="添加/编辑任务"
                        visible={this.state.visible}
                        onCancel={()=>{
                                this.setState({
                                    visible:false
                                })
                        }}
                        okText="确认"
                        cancelText="取消"
                        footer={null}
                        forceRender={true}
                    >
                        <Form {...formItemLayout} onSubmit={this.handleSumbit}>
                            <Form.Item label='任务名'>
                                {getFieldDecorator('taskName', {
                                    rules: [
                                        {
                                            required: true,
                                            validateStatus:'error',
                                            message: '请输入任务名!'
                                        },
                                    ],
                                })(
                                    <Input size="large"
                                           placeholder="任务名"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item label='任务类型'>
                                {getFieldDecorator('taskType', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择任务类型!'
                                        },
                                    ],
                                })(
                                    <Select>
                                        <Select.Option value={0}>视频任务</Select.Option>
                                        <Select.Option value={1}>试卷任务</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label='可见性'>
                                {getFieldDecorator('visible', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请选择任务可见性!'
                                        },
                                    ],
                                })(
                                    <Select>
                                        <Select.Option value={0}>任务对学员可以见</Select.Option>
                                        <Select.Option value={1}>任务对学员不以见</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label='开始时间'>
                                {getFieldDecorator('startTime', {
                                    rules: [
                                        {
                                            required: true,
                                            validateStatus:'error',
                                            message: '任务开始日期是必填的!'
                                        },
                                    ],
                                })(
                                    <SimpleDateShowTime placeholder={"开始日期"} selectAllDays={true}/>,
                                )}
                            </Form.Item>
                            <Form.Item label='结束时间'>
                                {getFieldDecorator('endTime', {
                                    rules: [
                                        {
                                            required: true,
                                            validateStatus:'error',
                                            message: '任务结束日期是必填的!'
                                        },
                                    ],
                                })(
                                    <SimpleDateShowTime placeholder={"结束日期"} selectAllDays={true}/>,
                                )}
                            </Form.Item>

                            <Form.Item label='任务描述' >
                                {getFieldDecorator('taskDescription', {
                                    rules: [
                                        {
                                            required: true,
                                            validateStatus:'error',
                                            message: '任务描述是必须输入的!'
                                        },
                                        {
                                            validator: (rule, value, callback) => {
                                                if(value && value.length > 10) {
                                                    callback();
                                                    return;
                                                }
                                                callback('error');
                                            },
                                            message: '任务描述必须大于10个字。',
                                        }
                                    ],
                                })(
                                    <TextArea rows={4}
                                              placeholder="任务介绍"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item  {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit" size="large" block>
                                    确定
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
            </MyLayout>
        )
    }
}

export default Form.create()(GetMyCreatedTasks);
