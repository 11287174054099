import React, { Component } from 'react';
import {Card, Row, Col, Badge, Button, Tooltip,List} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import '../../../assets/style/common.css';
import poster from '../../../assets/images/1.jpg';
import SceneListVote from "../../personal/lecture/SceneListVote";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";

const { Meta } = Card;

class LectureSceneDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lectureData:null,
            videoListData:[]
        };
    }
    lectureDetail() {
        let lectureId = this.props.match.params.lid;
        api.lecture.lectureDetail({lectureId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        lectureData:data.data,
                    });
                    this.videoList(data.data.lid);
                }
            }
        )
    }

    videoList(tid) {
        api.video.videoList(
            {
                "condition": {
                    "lectureDateEnd": "",
                    "lectureDateStart": "",
                    "lectureName": "",
                    "relateLectureId": tid,
                    "teacherName": "",
                    "teacherId":"",
                    "status":null,
                    "videoKeywords": []
                },
                "pageNum": 1,
                "pageSize": 8,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        videoListData:data.data,
                    })
                }
            }
        );
    }

    componentDidMount() {
        this.lectureDetail();
    }

    render() {
        const {
            lectureData = null
        } = this.state;
        let history = this.props.history;
        let userInfo = this.props.userInfo;
        return(
            <MyLayout>
                <div className="heading">
                    <div className="heading_title">{lectureData!= null?lectureData.name:""}</div>
                    <div className="heading_content">
                        <div className="heading_img">
                            {lectureData!= null && lectureData.posterPath != null ? <img
                                width={220}
                                src={lectureData.posterPath}
                            />:"未上传"}</div>
                        <div className="heading_mes">
                            <p className="mes_title"><span>课程时间：</span>
                                {lectureData!= null?lectureData.startDate.substring(0,16)+"--"+
                                lectureData.endDate.substring(0,16):""}
                            </p>
                            <p className="mes_title"><span>主讲人：</span>
                                {lectureData!= null?lectureData.teachers:""}
                            </p>
                            <p className="mes_title"><span>举办单位：</span>
                                {lectureData!= null?
                                    <a onClick={()=>
                                        history.push('/Personal/OrgDetailsPersonal'+'/'+lectureData.org.orgId)}>
                                        {lectureData.org.orgName}
                                    </a>:""}
                            </p>
                            <p className="mes_title"><span>讲座介绍：</span>
                                {lectureData!= null?lectureData.intro:""}
                            </p>
                        </div>
                    </div>
                </div>
                <SceneListVote lectureId = {this.props.match.params.lid}/>
                {
                    (userInfo==null || userInfo.role == 0 || userInfo.role == 1)?
                        <div>
                            <Row style={{marginTop: "45px", marginBottom: "20px",display:'flex'}}>
                                <Col span={22}>
                                    <div style={{fontSize:"24px"}}>关联视频</div>
                                </Col>
                                <Col span={2} style={{top:"20px"}}>
                                    <a onClick={()=>
                                        history.push('/Personal/moreVideoList')
                                    }>查看更多视频</a>
                                </Col>
                            </Row>
                            <List
                                grid={{ gutter: 20, column: 4 }}
                                itemLayout="vertical"
                                size="large"
                                dataSource={this.state.videoListData}
                                renderItem={(item,index) => (
                                    <List.Item key={index}>
                                        <Card hoverable
                                              cover={<img alt="example" style={{height:"170px"}} src={item.posterPath} />}
                                              onClick={()=>
                                                  history.push('/Personal/VideoDetail'+'/'+item.vid)
                                              }
                                        >
                                            <Meta title={item.name} style={{height:"110px"}} description={
                                                <div className="multipleTextOverflow">
                                                    <Tooltip title= {item.intro}>
                                                        {item.intro}
                                                    </Tooltip>
                                                </div>
                                            } />
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </div>:""
                }
            </MyLayout>
        )
    }
}
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default connect(mapStateToProps,null)(LectureSceneDetail);