import React from 'react';
import {Col, Row, Table, Divider, Button, Pagination, Select} from 'antd';
import api from '../../../api/api';
import MyLayout from '../../common/MyLayout';
class NewsManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            totalNum: null,
            newsStatus: null,
        };
        this.initPageSize = 10;
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
    }

    onFilter = newsStatus => {
        this.setState({newsStatus});
        setTimeout(this.allNews, 200);
    };

    allNews = () => {
        let newsParams = {
            condition: {newsStatus: this.state.newsStatus},
            pageNum: this.pageNum,
            pageSize: this.pageSize,
        };

        api.news.pageSearchNews(newsParams, (state, data) => {
            if (state && data.status === 200) {
                this.setState({
                    data: data.data,
                    totalNum: data.totalNum,
                });
            }
        });
    };
    pageOnChange = (pageNum, pageSize) => {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(() => this.allNews(), 200);
    };
    handle = link => {
        const w = window.open('about:blank');
        w.location.href = link;
    };
    componentDidMount() {
        this.allNews();
    }
    render() {
        let history = this.props.history;
        const columns = [
            {
                title: '新闻标题',
                key: 'newsTitle',
                render: (text, record) => (
                    <a
                        onClick={() =>
                            history.push(`/news/addOrEditNews/${record.newsId}`)
                        }
                    >
                        {record.newsTitle}
                    </a>
                ),
            },
            {
                title: '新闻来源',
                key: 'newsRefrence',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            cursor: 'pointer',
                        },
                    };
                },
                render: (text, record) => (
                    <a onClick={() => this.handle(record.newsRefrence)}>
                        {record.newsRefrence}
                    </a>
                ),
            },
            {
                title: '新闻作者',
                dataIndex: 'newsAuthor',
                key: 'newsAuthor',
            },
            {
                title: '创建时间时间',
                dataIndex: 'createdTime',
                key: 'createdTime',
            },
            {
                title: '状态',
                dataIndex: 'newsStatus',
                key: 'newsStatus',
                render: (text, record) =>
                    (text = text === 0 ? '编辑中' : '已发布'),
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <a
                            onClick={() =>
                                history.push(
                                    `/news/addOrEditNews/${record.newsId}`
                                )
                            }
                        >
                            编辑
                        </a>
                    </span>
                ),
            },
        ];
        return (
            <MyLayout>
                <p className="userEditTitle">新闻列表</p>
                <Divider />
                <Row gutter={5}>
                    <Col span={6}>
                        <Button
                            type="primary"
                            onClick={() =>
                                history.push(`/news/addOrEditNews/addNews`)
                            }
                            style={{marginBottom: 16}}
                        >
                            添加新闻
                        </Button>
                        <Select
                            style={{width: 120, marginLeft: 20}}
                            defaultValue={null}
                            value={this.state.newsStatus}
                            onChange={this.onFilter}
                        >
                            <Select.Option value={null}>全部</Select.Option>
                            <Select.Option value="0">编辑中</Select.Option>
                            <Select.Option value="1">已发布</Select.Option>
                        </Select>
                    </Col>
                    <Divider dashed />
                    <Table
                        pagination={false}
                        dataSource={this.state.data}
                        columns={columns}
                        rowKey="newsId"
                    />
                    <Divider style={{background: 'transparent'}} />
                    <Pagination
                        onChange={(pageNum, pageSize) =>
                            this.pageOnChange(pageNum, pageSize)
                        }
                        pageSize={this.pageSize}
                        current={this.pageNum}
                        total={this.state.totalNum}
                    />
                </Row>
            </MyLayout>
        );
    }
}

export default NewsManagement;
