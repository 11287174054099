import React, { Component } from 'react';
import { Icon, Button,Tag, Divider, Table, InputNumber, message, Modal, Row, Col} from 'antd';
import api from '../../../api/api';
import Dict from '../../../config/Dict';
import TestPaperSelectModal from './TestPaperSelectModal';
const { confirm } = Modal;

class PaperTaskSelect extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            itemIndex:0,
            visible: false,
            data:[],

            selectedRowKeys:[],
         };
    }

    getPaperListByTaskId(){
        let mtaskId = this.props.mtaskId;
        api.taskadmin.getPaperListByTaskId({"mtaskId":mtaskId},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    batchAddToTask=(ids)=>{
        let mtaskId = this.props.mtaskId;
        api.taskadmin.batchAddDataToTask(
            {
                mtaskId,
                ids:ids,
            },
            (state,data)=>{
                if(state && data.status === 200)
                {
                    this.getPaperListByTaskId();
                }
                else
                {
                    message.error(data.msg);
                }
            }
        )
    }


    itemMoveStep(paperId,moveStep){
        let mtaskId = this.props.mtaskId;
        api.taskadmin.changeItemIndexWithStep({
                "itemId": paperId,
                "moveStep": moveStep,
                "mtaskId": mtaskId
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.getPaperListByTaskId();
                }
            }
        )
    }

    removeItemFromTask(paperId){
        let mtaskId = this.props.mtaskId;
        confirm({
            title: "提示",
            content:"你确定要删除这条项目吗?",
            onOk:()=>{
                api.taskadmin.removeItemFromTask({
                        "mtaskId": mtaskId,
                        "itemId": paperId,
                    },
                    (state,data)=>{
                        if (state) {
                            if(data.status == 200) {
                                setTimeout(()=>this.getPaperListByTaskId(), 200,);
                                message.success('删除成功!');
                            }
                            this.setState({selectedRowKeys:[]});
                        }
                    }
                )
            }
        });
    }

    componentDidMount() {
        this.getPaperListByTaskId();
    }

    batchRemoveData=()=>{
        let mtaskId = this.props.mtaskId;
        let ids = this.state.selectedRowKeys;

        Modal.confirm({
            title:'真的要删除?',
            onOk:()=>{
                api.taskadmin.batchRemovDataToTask(
                    {
                        mtaskId,
                        ids:ids,
                    },
                    (state,data)=>{
                        if(state && data.status === 200)
                        {
                            this.getPaperListByTaskId();
                        }
                        else
                        {
                            message.error(data.msg);
                        }
                    }
                )
                this.setState({selectedRowKeys:[]});
            }
        })

    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys });
    };

    render() {
        const {data,selectedRowKeys} = this.state;
        const columns = [
            {
                title: '#',
                dataIndex: 'itemIndex',
                key: 'itemIndex',
                className:"tableOverflow",
            },
            {
                title: '试卷题目',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '考试时间',
                dataIndex: 'kssj',
                key: 'kssj',
                className:"tableOverflow",
                render: (text, record) => (
                    <div>
                    <span>{record.startTime?record.startTime:'不定'}</span>
                    -
                    <span>{record.endTime?record.endTime:'不定'}</span>
                </div>
                )
            },
            {
                title: ' 试卷类型',
                dataIndex: 'examType',
                key: 'examType',
                render: (text, record) => (
                    <Tag color={text==0?"#87d068":"#2db7f5"}>
                        {
                            Dict.getValue('examType',text,'')
                        }
                    </Tag>
                ),
            },
            {
                title: '题数',
                dataIndex: 'itemNum',
                key: 'itemNum',
            },
            {
                title: '总分值',
                dataIndex: 'totalScore',
                key: 'totalScore',
            },
            {
                title: '答题人数',
                dataIndex: 'answerNum',
                key: 'answerNum',
            },
            {
                title: '创建日期',
                dataIndex: 'createTime',
                key: 'createTime',
                render: (text, record) => (
                    text.substring(0,10)
                ),
            },
            {
                title: '顺序调整',
                key: 'adjustOrder',
                render: (text, record) => (
                    <span>
                    <Button
                     onClick={
                         ()=>this.itemMoveStep(record.paperId,1)
                     }
                    >
                    <Icon type='down'/>
                    </Button>
                    <Divider type='vertical'/>
                    <Button
                     onClick={
                         ()=>this.itemMoveStep(record.paperId,-1)
                     }
                    >
                    <Icon type='up'/>
                    </Button>
                    </span>
                ),
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <a onClick={()=>this.removeItemFromTask(record.paperId)}>删除</a>
                ),
            }
        ];

        const hasSelected = selectedRowKeys.length > 0;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        return (
            <div>
                <Row>
                    <Col span={3}>
                        <TestPaperSelectModal 
                        handleAdd = {this.batchAddToTask}
                        excludes={this.state.data}
                        />
                    </Col>
                    <Col span={2}>
                        <Button type="danger" 
                        onClick={this.batchRemoveData} 
                        disabled={!hasSelected}>
                            删除
                        </Button>
                    </Col>
                    <Col span={3}>
                        <span style={{ marginLeft: 8 }}>
                            {hasSelected ? `共选中 ${selectedRowKeys.length} 条` : ''}
                        </span>
                    </Col>
                </Row>
                <Divider/>
                <Table
                    rowSelection={rowSelection}
                    rowKey='mtaskItemId'
                    pagination={false}
                    dataSource={data}
                    columns={columns}
                />
            </div>
        );
    }
}

export default PaperTaskSelect;



