import React, { Component } from 'react';
import {Row, Col, Select, Input, List, Divider, Button} from 'antd';
import api from '../../../../api/api';
import MyLayout from "../../../common/MyLayout";

const { Option } = Select;
const InputGroup = Input.Group;

class LectureList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectType:"lectureName",
            lectureName:"",
            startTime:"",
            endTime:"",
            orgIds:[],
            teahcerNames:[],

            data:[],
            pageNum:1,
            pageSize:4,
            totalNum:99,
        };
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.updateData(), 200);
    }

    updateData() {
        api.lecture.myLectureList(
            {
                "condition": {
                    "beforeEnd": true,
                    "endTime": this.state.endTime,
                    "lectureName": this.state.lectureName,
                    "orgIds":this.state.orgIds,
                    "startTime": this.state.startTime,
                    "teahcerNames": this.state.teahcerNames,
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:4,
            totalNum:9,
        });
        setTimeout(()=>this.updateData(), 200,)
    }

    componentDidMount() {
        this.updateData();
    }

    render() {
        let history = this.props.history;
        return(
            <MyLayout sider={"InfoManageSider"}>
                <div className="userEditTitle">讲座列表</div>
                <Divider />
                <Row gutter={5}>
                    <Col span={19}>
                        <InputGroup compact style={{marginLeft:"20px"}}>
                            <Select size="large"
                                    defaultValue="lectureName"
                                    onChange={(value)=>this.setState({selectType:value})}>
                                <Option value="lectureName">讲座名</Option>
                                <Option value="teahcerNames">教师名</Option>
                            </Select>
                            <span style={{ width: '40%'}} >{(() => {
                                    switch (this.state.selectType) {
                                        case "lectureName":
                                            return <Input size="large"
                                                          defaultValue={this.state.lectureName}
                                                          placeholder='请输入讲座名'
                                                          onChange={(e)=>this.setState({lectureName:e.target.value})}
                                            />;
                                        case "teahcerNames":
                                            return <Input size="large"
                                                          defaultValue={this.state.teahcerNames}
                                                          placeholder='请输入教师名'
                                                          onChange={(e)=>this.setState({teahcerNames:[e.target.value]})}
                                            />;
                                        default:
                                            return null
                                    }
                                }
                            )()}</span>
                            <div>
                                <Button type='primary'
                                        size="large"
                                        icon="search"
                                        onClick={()=>this.research()}>
                                    搜索</Button>
                            </div>
                        </InputGroup>
                    </Col>
                    <Col span={4}>
                        <Button size="large"
                                type="primary"
                                onClick={()=>history.push('/company/addOrEditLecture'+'/'+'addLecture')}>
                            录入新的讲座</Button>
                    </Col>
                </Row>
                <Divider dashed/>
                <List
                    itemLayout="vertical"
                    size="large"
                    pagination={{
                        onChange: page => {
                            this.pageOnChange(page, this.state.pageSize)
                        },
                        pageSize:this.state.pageSize,
                        current:this.state.pageNum,
                        total:this.state.totalNum
                    }}
                    dataSource={this.state.data}
                    renderItem={item => (
                        <List.Item
                            key={item.name}
                            extra={
                                <img
                                    width={150}
                                    alt="logo"
                                    src={item.posterPath}
                                />
                            }
                        >
                            <List.Item.Meta
                                title={
                                    <a  onClick={()=> history.push("/company/lectureDetail"+'/'+item.lid)}>
                                        <Row gutter={15}>
                                            <Col span={8} className="oneTextOverflow">
                                                <a >{item.name}</a>
                                            </Col>
                                            <Col span={5}>
                                                <div style={{color :"#adadad",fontSize: "14px"}}>
                                                    {item.startDate.substring(0,16)}
                                                </div>
                                            </Col>
                                        </Row>
                                    </a>
                                }
                                description={
                                    <div>
                                        主讲：
                                        {item.teachers}  ({item.orgName})
                                    </div>
                                }
                            />
                            <div className="multipleTextOverflow">
                                {item.intro}
                            </div>
                        </List.Item>
                    )}
                />
            </MyLayout>
        )
    }
}

export default LectureList;