import React, { Component } from 'react';
import {message, Form, Input, Button} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import TeacherSelectModal from "../../../components/TeacherSelectModal";
import SimpleDateSel from '../../../components/SimpleDateSel';

const { TextArea } = Input;

class AddOrEditStar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            starData:null,
            dataTime:null
        };
    }

    addOrEditStar(values){
        let id = this.achieveParams();
        api.teacher.addOrEditStar(
            {id, ...values},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.props.history.push('/admin/StarList');
                } else {
                    message.error(data.msg);
                }
            }
        )
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.addOrEditStar(values);
            }
        });
    };


    starDetail(starId) {
        api.teacher.starDetail({starId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        starData:data.data,
                    })
                }
            }
        )
    }

    achieveParams(){
        let {id} = this.props.match.params;
        if (id && id =="addStar"){
            id = "";
        }
        return id;
    }

    componentDidMount() {
        if (this.achieveParams() && this.achieveParams() !="" ){
            this.starDetail(this.achieveParams());
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            starData= null,
        } = this.state;

        const formItemLayout = {
            labelCol: {
                sm: { span: 8 },
            },
            wrapperCol: {
                sm: { span: 10},
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                sm: {
                    span: 10,
                    offset: 8,
                },
            },
        };
        return(
            <MyLayout>
                <div style={{marginTop:'60px'}}>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit} >
                        <Form.Item label='推荐讲师'>
                            {getFieldDecorator('tid', {
                                initialValue:starData?starData.teacher:null,
                                rules: [
                                    {
                                        required: true,
                                        message: '推荐的讲师是必须输入的!'
                                    },
                                ],
                            })(
                                <TeacherSelectModal />
                            )}
                        </Form.Item>
                        <Form.Item label='推荐开始日期'>
                            {getFieldDecorator('recommendDate', {
                                initialValue:starData?starData.recommendDate:null,
                                rules: [
                                    {
                                        required: true,
                                        message: '推荐开始日期是必填的!'
                                    },
                                ],
                            })(
                                <SimpleDateSel/>,
                            )}
                        </Form.Item>
                        <Form.Item label='推荐结束日期'>
                            {getFieldDecorator('recommendEndDate', {
                                initialValue:starData?starData.recommendEndDate:null,
                                rules: [
                                    {
                                        required: true,
                                        message: '推荐结束日期是必填的!'
                                    },
                                ],
                            })(
                                <SimpleDateSel/>,
                            )}
                        </Form.Item>
                        <Form.Item label='推荐理由' >
                            {getFieldDecorator('reason', {
                                initialValue:starData?starData.reason:null,
                                rules: [
                                    {
                                        required: true,
                                        message: '推荐理由必须输入的!'
                                    },
                                ],
                            })(
                                <TextArea rows={4}
                                          placeholder="推荐理由"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit" size="large" block>
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </MyLayout>
        )
    }
}
export default Form.create()(AddOrEditStar);