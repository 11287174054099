import React, { Component } from 'react';
import {Descriptions, Badge, Button, Col, Row, Modal, message, Form} from 'antd';
import MyLayout from "../../../common/MyLayout";
import api from "../../../../api/api";
import Dict from '../../../../config/Dict';
import {connect } from 'react-redux';

const { confirm } = Modal;

class TeacherDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teacherData:null,
        };
    }

    teacherDetail() {
        let teacherId = this.props.match.params.teacherId;
        api.teacher.teacherDetail({teacherId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        teacherData:data.data,
                    })
                }
            }
        )
    }

    cancellation(){
        let teacherId = this.props.match.params.teacherId;
        confirm({
            title: '你确定要注销这个讲师吗?',
            content: '注销的讲师不会被删除，注销后你可以选择重新上线这个讲师。',
            onOk:()=>{
                api.teacher.offTeacher({teacherId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            if(this.props.userInfo.role == 5){
                                this.props.history.push('/admin/PendingTchList')
                            }else{
                                this.teacherDetail();
                            }
                        }
                    }
                )
            },
        });
    }

    reOnline(){
        let tid = this.props.match.params.teacherId;
        confirm({
            title: '你确定要重新上线这个讲师吗?',
            content: '重新的讲师，将进入[审批中]的状态，管理员审批通过后，讲师将重新上线。',
            onOk:()=>{
                api.teacher.addOrEditTeacher({tid},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.teacherDetail();
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            },
        });
    }

    examineOrg(status){
        let teacherId = this.props.match.params.teacherId;
        let title = "";
        let content = "";
        if (status == 1){
            title = "你确定要[审批通过]这个讲师吗?";
            content = "讲师[审批通过]后才可以被用户所看见哦。"
        }else{
            title = "你确定要[审批驳回]这个讲师吗?";
            content = "讲师[审批驳回]后信息会返回给请求者。"
        }
        confirm({
            title: title,
            content:content,
            onOk:()=>{
                api.teacher.examineTeacher({teacherId,status},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.props.history.push('/admin/PendingTchList');
                        }
                    }
                )
            }
        });
    }

    componentDidMount() {
        this.teacherDetail();
    }

    render() {
        const {
            teacherData= null
        } = this.state;
        return(
            <MyLayout>
                <div>
                    <Descriptions title="讲师详情" bordered>
                        <Descriptions.Item key='createdTime' label="创建时间">
                            {teacherData!= null?teacherData.createdTime:""}
                        </Descriptions.Item>
                        <Descriptions.Item key='updatedTime' label="更新时间">
                            {teacherData!= null && teacherData.updatedTime? teacherData.updatedTime:"无更新"}
                        </Descriptions.Item>
                        <Descriptions.Item key='status' label="上线状态">
                            {teacherData!= null? <Badge
                                status="processing"
                                text={Dict.getValue('videoState',teacherData.status,'')} />:null
                            }
                        </Descriptions.Item>
                        <Descriptions.Item key="name" label="讲师姓名" span={2}>
                            {teacherData!= null?teacherData.name:""}
                        </Descriptions.Item>
                        <Descriptions.Item key='vid' label="讲师id" >{teacherData!= null?teacherData.tid:""}</Descriptions.Item>
                        <Descriptions.Item key='relateLId' label="所属组织" span={2}>{teacherData!= null?teacherData.org.orgName:"无"}</Descriptions.Item>
                        <Descriptions.Item key='tid' label="所属领域">{teacherData!= null?teacherData.field.name:"无"}</Descriptions.Item>
                        <Descriptions.Item  label="讲师照片" span={3}>
                            {teacherData!= null && teacherData.photoPath ? 
                            <img
                                width={220}
                                src={teacherData.photoPath}
                                alt=''
                            />:"未上传"}
                        </Descriptions.Item>
                        <Descriptions.Item  label="签名图片" span={3}>
                            {teacherData!= null && teacherData.signaturePath? 
                            <img
                                alt=''
                                width={220}
                                src={teacherData.signaturePath}
                            />:"未上传"}
                        </Descriptions.Item>
                        <Descriptions.Item key='intro' label="专业成就">
                            {teacherData!= null?teacherData.achievement:""}
                        </Descriptions.Item>
                    </Descriptions>
                    {
                        this.props.userInfo.role != 5 ?
                        <Row style={{padding:20}}>
                            {
                                teacherData!= null && teacherData.status !=0 ?
                                <Col span={4}>
                                    <Button type="primary" onClick={()=>this.cancellation()}>下线讲师</Button>
                                </Col>
                                :                  
                                <Col span={4}>
                                    <Button type="primary" onClick={()=>this.reOnline()}>重新上线</Button>
                                </Col>
                            }
                            <Col span={4}>
                                <Button type="primary"
                                        onClick={
                                            ()=>
                                            this.props.history.push('/company/AddOrEditTeacher'+'/'+this.props.match.params.teacherId)
                                        }
                                    >
                                    编辑讲师
                                    </Button>
                            </Col>
                        </Row>:
                        <Row style={{paddingTop:"25px"}}>
                            <Col span={2}>
                                <Button type="primary"
                                        onClick={()=>this.examineOrg(1)}
                                >
                                    通过审批</Button>
                            </Col>
                            <Col span={2}>
                                <Button type="primary"
                                        onClick={()=>this.examineOrg(2)}
                                >
                                    驳回审批</Button>
                            </Col>
                            <Col span={2}>
                                <Button type="primary" onClick={()=>this.cancellation()}>下线这个讲师</Button>
                            </Col>
                        </Row>
                    }
                </div>
            </MyLayout>
        )
    }
}
//export default TeacherDetail;
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default connect(mapStateToProps,null)(Form.create({ name: 'TeacherDetail' })(TeacherDetail));