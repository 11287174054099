import { Modal, Table, Button } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import api from '../../../api/api';
import majorData from '../../../config/majorData';
import Dict, { dict } from '../../../config/Dict';

class AddStudentsWithClassModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible:false,
            expandedRowKeys:[],

            //子选项功能
            selectedRowKeys:[],
            filteredInfo: null,
            studentsData:[],
        };
    }
    
    handleAdd=()=>{
        if(this.props.handleAdd)
        {
            if(this.props.handleAdd(this.state.selectedRowKeys))
            {
                this.setState({visible:false});
            }
            setTimeout(()=>{
                this.setState({selectedRowKeys:[]})
            },100)
        }
    };

    featchStudentDataByClassId(classId)
    {
        if(this.props.mtaskId)
        {
            api.taskadmin.getNotJoinStudentTaskClass(
                {classId,mtaskId:this.props.mtaskId},
                (state,data)=>{
                    if(state && data.status === 200)
                    {
                        this.setState({studentsData:data.data})
                    }
                }
            )
        }
        else
        {
            api.taskadmin.getStudentsListByClassId(
                {classId},
                (state,data)=>{
                    if(state && data.status === 200)
                    {
                        this.setState({studentsData:data.data})
                    }
                }
            )
        }
    }
    onExpandHandle=(expanded,record)=>{
        if(expanded)
        {
            //展开时候才去取数据
            let classId = record.classId;
            this.setState({
                selectedRowKeys:[],
                filteredInfo: null,
                studentsData:[],
                expandedRowKeys:[classId]
            });
            this.featchStudentDataByClassId(classId);
        }
        else
        {
            this.setState({
                selectedRowKeys:[],
                filteredInfo: null,
                studentsData:[],
                expandedRowKeys:[]
            });  
        }
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys });
    };

    handleChange = (pagination, filters, sorter) => {
        this.setState({
          filteredInfo: filters
        });
    };

    render() {

        const expandedRowRender = (record) => {
            
            let {selectedRowKeys,filteredInfo,studentsData} = this.state;
            const hasSelected = selectedRowKeys.length > 0;

            let pureStudentsData = [];
            if(this.props.excludeUsers)
            {
                for(let i = 0; i<studentsData.length;i++)
                {
                    let isExclude = false;
                    for(let j = 0; j<this.props.excludeUsers.length; j++)
                    {
                        if(this.props.excludeUsers[j].uid === studentsData[i].uid)
                        {
                            isExclude = true;
                            break;
                        }
                    }

                    if(!isExclude)
                    {
                        pureStudentsData.push(studentsData[i]);
                    }
                }
            }
            else
            {
                pureStudentsData = studentsData;
            }


            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectChange,
            };

            filteredInfo = filteredInfo || {};
            let clumsin = [
            {
                title: '学生姓名',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow",
            },
            {
                title: '城市',
                dataIndex: 'pcity',
                key: 'pcity',
                className:"tableOverflow",
                filters: Dict.getPCityFilters(pureStudentsData),
                filteredValue: filteredInfo.pcity || null,
                onFilter: (value, record) => record.pcity.includes(value),
                render: (text, record) => (
                    <span>{Dict.getAdressLabelValue(record.pcity)}</span>
                ),
            }];

            return (
                <div>
                   <div style={{ marginBottom: 16 }}>
                    <Button type="primary" 
                    onClick={this.handleAdd} 
                    disabled={!hasSelected}>
                        添加
                    </Button>
                    <span style={{ marginLeft: 8 }}>
                        {hasSelected ? `共选中 ${selectedRowKeys.length} 人` : ''}
                    </span>
                    </div>
                    <Table
                       rowSelection={rowSelection}
                       rowKey='uid'
                       columns={clumsin}
                       dataSource={pureStudentsData}
                       pagination={false}
                       onChange={this.handleChange}
                    />
                </div>
            )
        };


        const columns = [
            {
                title: '班级名称',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow",
            },
            {
                title: '班级编号',
                dataIndex: 'classId',
                key: 'classId',
            },
            {
                title: '班级所属专业',
                dataIndex: 'subjectId',
                key: 'subjectId',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 60,
                        }
                    }
                },
                render: (text, record) => (
                    <span>
                        {majorData.getValue(text,'')}
                    </span>
                ),
            }
        ];


        return (
            <>
               <Button type="primary"
                        style={{marginBottom:"18px"}}
                        onClick={()=>{
                            this.setState({
                                visible: true,
                            })
                        }}>
                    {this.props.title}
                </Button>
                <Modal
                    title="班级学生选择器"
                    width={800}
                    centered={true}
                    visible={this.state.visible}
                    onCancel={()=>{
                        this.setState({
                            visible:false
                        })
                    }}
                    footer={null}
                >
                    <Table
                        className="components-table-demo-nested"
                        dataSource={this.props.clses}
                        expandedRowRender={expandedRowRender}
                        columns={columns}
                        rowKey='classId'
                        onExpand={this.onExpandHandle}
                        expandedRowKeys={this.state.expandedRowKeys}
                        pagination={false}
                    />
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}

export default connect(mapStateToProps,null)(AddStudentsWithClassModal);





