import React from 'react';
import {Cascader} from 'antd';

import getClassification from './getClassification.js';

class NewsClassification extends React.Component {
    convertClass = () => {
        const classArray = [];
        const {value} = this.props;
        classArray[0] = (value + '').substr(0, 3);
        classArray[1] = value + '';
        return classArray;
    };
    handleChange = e => {
        const {onChange} = this.props;
        if (onChange) {
            onChange(e[1]);
        }
    };

    render() {
        return (
            <Cascader
                options={getClassification()}
                placeholder="请选择"
                onChange={this.handleChange}
                value={this.convertClass()}
            />
        );
    }
}

export default NewsClassification;
