/* eslint-disable jsx-a11y/alt-text */

import React, { Component } from 'react';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

class AccountBind extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Typography>
                <Title level={2}>账号绑定</Title>
                <Paragraph>
                    本文将会指导用户如何进行账号绑定。
                </Paragraph>
                <Paragraph>
                    成功登录后，用户点击右上角的个人中心，选中下拉菜单的第一个选项【个人信息】，点击左侧导航栏中的【账号绑定】后，可以看到当前登录账号的账号绑定情况。
                </Paragraph>
                <Paragraph>
                    用户可以分别对邮箱，手机，微信，身份证进行绑定
                </Paragraph>
                <Title level={4}>绑定邮箱</Title>
                <Paragraph>
                    用户可以在右侧【绑定邮箱】行后找到【绑定邮箱】，在弹出的窗口输入对应的信息后，点击确定即可完成邮箱的绑定。
                </Paragraph>
                <Title level={4}>绑定手机</Title>
                <Paragraph>
                    用户可以在右侧【绑定手机】行后找到【绑定手机】，在弹出的窗口输入对应的信息后，点击确定即可完成手机的绑定。
                </Paragraph>
                <Title level={4}>绑定微信</Title>
                <Paragraph>
                    用户可以在右侧【绑定微信】行后找到【绑定微信】，在弹出的窗口中使用微信扫描二维码后即可完成微信的绑定。
                </Paragraph>
                <Title level={4}>绑定身份证</Title>
                <Paragraph>
                    用户可以在右侧【绑定身份证】行后找到【绑定身份证】，在弹出的窗口输入对应的信息后，点击确定即可完成身份证的绑定。
                </Paragraph>
            </Typography>
        );
    }
}

export default AccountBind;