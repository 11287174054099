import React, { Component } from 'react';
import { Col, Row, Button, Descriptions, Badge, message, Modal} from 'antd';
import MyLayout from "../../../common/MyLayout";
import Dict from '../../../../config/Dict';
import api from "../../../../api/api";

const { confirm } = Modal;

class OrgDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgData:null,
            role:""
        };
    }

    updateOrgDetail() {
        let orgId = this.props.match.params.orgId;
        api.org.orgDetail({orgId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        orgData:data.data,
                    })
                }
            }
        )
    }

    cancellation(){
        let orgId = this.props.match.params.orgId;
        confirm({
            title: '你确定要注销这个组织(公司)吗?',
            content: '注销的组织(公司)不会被删除，注销后申请者可以选择重新上线。',
            onOk:()=>{
                api.org.offOrg({orgId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            if(this.state.role == 5){  // rol : admin
                                this.props.history.push('/admin/PendingOrgList')
                            }else{
                                this.updateOrgDetail();
                            }
                        }
                    }
                )
            },
            onCancel() {
                
            },
        });
    }

    reOnline(){
        let orgId = this.props.match.params.orgId;
        confirm({
            title: '你确定要重新上线这个组织(公司)吗?',
            content: '重新上线这个组织(公司)，组织(公司)将进入[审批中]的状态，管理员审批通过后，组织(公司)将重新上线。',
            onOk:()=>{
                api.org.editOrg(
                    {orgId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.updateOrgDetail();
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            },
            onCancel() {
                
            },
        });
    }

    examineOrg(status){
        let orgId = this.props.match.params.orgId;
        let title = "";
        let content = "";
        if (status == 1){
            title = "你确定要[审批通过]这个组织(公司)吗?";
            content = "组织(公司)[审批通过]后才可以创建讲座等。"
        }else{
            title = "你确定要[审批驳回]这个组织(公司)吗?";
            content = "组织(公司)[审批驳回]后信息会返回给请求者。"
        }
        confirm({
            title: title,
            content:content,
            onOk:()=>{
                api.org.examineOrg({orgId,status},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.props.history.push('/admin/PendingOrgList')
                        }
                    }
                )
            },
            onCancel() {
                
            },
        });
    }

    componentDidMount() {
        api.user.getMyUserInfo("", (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        role:data.data.role,
                    })
                }
            }
        );
        this.updateOrgDetail();
    }

    render() {
        const {
            orgData= null
        } = this.state;
        return(
            <MyLayout >
                <div>
                    <Descriptions title="组织详情" bordered>
                        <Descriptions.Item key='createdTime' label="信息录入时间">
                            {orgData!= null?orgData.createdTime:""}
                        </Descriptions.Item>
                        <Descriptions.Item key='updatedTime' label="信息更新时间">
                            {orgData!= null && orgData.updatedTime!= null?orgData.updatedTime:"无"}
                        </Descriptions.Item>
                        <Descriptions.Item label="审核状态" >
                            {orgData!= null? <Badge
                                status="processing"
                                text={Dict.getValue('orgState',orgData.status,'')} />:null
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="公司名称" span={2}>{orgData!= null?orgData.orgName:""}</Descriptions.Item>
                        <Descriptions.Item label="公司法人">{orgData!= null?orgData.legalPersonName:""}</Descriptions.Item>
                        <Descriptions.Item label="公司性质">
                            {Dict.getValue('nature',orgData!= null?orgData.nature:"",'')}
                        </Descriptions.Item>
                        <Descriptions.Item label="所属行业">
                            {Dict.getValue('companyClass',orgData!= null?orgData.companyClass:"",'')}
                        </Descriptions.Item>
                        <Descriptions.Item label="公司规模">
                            {Dict.getValue('personNum',orgData!= null?orgData.personNum:"",'')}
                        </Descriptions.Item>
                        <Descriptions.Item label="公司地址" span={2}>
                            {orgData!= null?orgData.detailAddress:""}
                        </Descriptions.Item>
                        <Descriptions.Item label="公司联系人" >
                            {orgData!= null?orgData.contactsNames:""}
                        </Descriptions.Item>
                        <Descriptions.Item label="联系电话" span={2}>
                            {orgData!= null?orgData.contactsPhones:""}
                        </Descriptions.Item>
                        <Descriptions.Item label="邮箱" span={2}>
                            {orgData!= null?orgData.contactsEmails:""}
                        </Descriptions.Item>
                        <Descriptions.Item  label="公司证件" span={3}>
                            {orgData!= null && orgData.certificatesFileIds != null ? <img
                                width={220}
                                src={orgData.certificatesFileIds}
                            />:"未上传"}
                        </Descriptions.Item>
                        <Descriptions.Item  label="公司logo" span={3}>
                            {orgData!= null && orgData.logoFileId!=null ? <img
                                width={220}
                                src={orgData.logoFileId}
                            />:"未上传"}
                        </Descriptions.Item>
                        <Descriptions.Item label="公司介绍">
                            {orgData!= null?orgData.introduce:""}
                        </Descriptions.Item>
                    </Descriptions>
                    {
                        this.state.role != 5 ?
                            <Row style={{padding:20}}>
                            {
                                orgData!= null && orgData.status !=0 ?<Col span={2}>
                                    <Button type="primary" onClick={()=>this.cancellation()}>注销组织</Button>
                                </Col>: <div>
                                    <Col span={2}>
                                        <Button type="primary" onClick={()=>this.reOnline()}>重新上线</Button>
                                    </Col>
                                </div>
                            }
                            <Col span={2}>
                                <Button type="primary"
                                        onClick={()=>
                                            this.props.history.push('/company/AddApplyOrg'+'/'+this.props.match.params.orgId)}>
                                    编辑组织</Button>
                            </Col>
                        </Row>:
                        <Row style={{paddingTop:"25px"}}>
                            <Col span={2}>
                                <Button type="primary"
                                        onClick={()=>this.examineOrg(1)}
                                >
                                    通过审批</Button>
                            </Col>
                            <Col span={2}>
                                <Button type="primary"
                                        onClick={()=>this.examineOrg(2)}
                                >
                                    驳回审批</Button>
                            </Col>
                            <Col>
                                <Button type="primary" onClick={()=>this.cancellation()}>注销组织</Button>
                            </Col>
                        </Row>
                    }
                </div>
            </MyLayout>
        )
    }
}
export default OrgDetail;