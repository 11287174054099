import React, { Component } from 'react';
import {Descriptions, Badge} from 'antd';
import MyLayout from "../../common/MyLayout";
import api from "../../../api/api";

class StarDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            starData:null,
        };
    }

    starDetail() {
        let starId = this.props.match.params.starId;
        api.teacher.starDetail({starId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        starData:data.data,
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.starDetail();
    }

    render() {
        const {
            starData= null
        } = this.state;
        return(
            <MyLayout>
                <div>
                    <Descriptions title="今日名师" bordered>
                        <Descriptions.Item key='createdTime' label="信息创建时间" span={2}>
                            {starData!= null?starData.createdTime:""}
                        </Descriptions.Item>
                        <Descriptions.Item key='updatedTime' label="信息更新时间" span={2}>
                            {starData!= null && starData.updateTime=="" ? starData.updateTime:"无更新"}
                        </Descriptions.Item>
                        <Descriptions.Item key="name" label="讲师姓名" span={2}>
                            {starData!= null?starData.teacher.name:""}
                        </Descriptions.Item>
                        <Descriptions.Item key='recommendDate' label="自动推荐时间" span={2}>
                            {starData!= null ? starData.recommendDate:"无"}
                        </Descriptions.Item>
                        <Descriptions.Item key='relateLId' label="所属组织" span={2}>
                            {starData!= null?starData.teacher.org.orgName:"无"}
                        </Descriptions.Item>
                        <Descriptions.Item key='tid' label="所属领域">
                            {starData!= null?starData.teacher.field.name:"无"}
                        </Descriptions.Item>
                        <Descriptions.Item  label="讲师照片" span={3}>
                            {starData!= null && starData.teacher.photoPath != null ? <img
                                width={220}
                                src={starData.teacher.photoPath}
                            />:"未上传"}
                        </Descriptions.Item>
                        <Descriptions.Item key='intro' label="专业成就" span={4}>
                            {starData!= null?starData.teacher.achievement:""}
                        </Descriptions.Item>
                        <Descriptions.Item key='reason' label="推荐理由" span={4}>
                            {starData!= null?starData.reason:""}
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </MyLayout>
        )
    }
}
export default StarDetail;