
import React, { Component } from 'react';
import { Button } from 'antd';
class ThirdLogin extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         };
    }
    render() {
        return (
            <div>
                <Button
                    type='primary'
                    onClick={
                        ()=>{
                            window.open("/developGuide.doc","_blank");
                        }
                    }
                >接口API说明书下载</Button>
            </div>
        );
    }
}

export default ThirdLogin;


