import React, { Component } from 'react';
import {message, Form, Icon, Input, Button, Col, Row,Select} from 'antd';
import api from '../../../api/api';
import ImageUpload from '../../../components/ImageUpload';
import MyLayout from "../../common/MyLayout";
import SimpleDateShowTime from "../../../components/SimpleDateShowTime";
const { Option } = Select;

class AddOrEditAdvertise extends Component {

    constructor(props) {
        super(props);
        this.state = {
            LinkData:null,
            fromDate:""
        };
    }

    addOrEditAdvertise(values){
        let linkId = this.achieveParams();

        api.admin.addOrEditAdvertise(
            {linkId, ...values},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.props.history.push('/admin/AdvertiseList');
                } else {
                    message.error(data.msg);
                }
            }
        )
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.addOrEditAdvertise(values);
            }
        });
    };


    getAdByLinkId(linkId) {
        api.admin.getAdByLinkId({linkId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        LinkData:data.data,
                    })
                }
            }
        )
    }

    achieveParams(){
        let {linkId} = this.props.match.params;
        if (linkId && linkId =="addAdvertise"){
            linkId = "";
        }
        return linkId;
    }

    componentDidMount() {
        if (this.achieveParams() && this.achieveParams() !="" ){
            this.getAdByLinkId(this.achieveParams());
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            LinkData= null,
        } = this.state;

        const formItemLayout = {
            labelCol: {
                sm: { span: 8 },
            },
            wrapperCol: {
                sm: { span: 10},
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                sm: {
                    span: 10,
                    offset: 8,
                },
            },
        };

        return(
            <MyLayout>
                <div style={{marginTop:'60px'}}>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit} >
                        <Form.Item label='广告名称' hasFeedback>
                            {getFieldDecorator('name', {
                                initialValue:LinkData?LinkData.name:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '广告名称是必须输入的!'
                                    },
                                ],
                            })(
                                <Input size="large"
                                       prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                       placeholder="广告名称"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='链接URL' hasFeedback>
                            {getFieldDecorator('link', {
                                initialValue:LinkData?LinkData.link:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '链接URL是必须输入的!'
                                    },
                                ],
                            })(
                                <Input
                                       prefix={<Icon type="link" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                       placeholder="URL（以http://或https://开头）"/>
                            )}
                        </Form.Item>
                        <Form.Item label='广告图片'>
                            {getFieldDecorator('filePath', {
                                initialValue:LinkData?LinkData.filePath:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '广告图片必须上传!'
                                    },

                                ],
                            })(
                                <ImageUpload/>,
                            )
                            }
                            <div style={{color:"#f42429"}}>注：图片要求1423*300像素，类型为jpeg/pag。
                                请注意图片尺寸及图片格式</div>
                        </Form.Item>
                        <Row>
                            <Col span={6} offset={6}>
                                <Form.Item label='投放日期'>
                                    {getFieldDecorator('fromDate', {
                                        initialValue:LinkData?LinkData.fromDate:null,
                                        rules: [
                                            {
                                                required: false,
                                                message: '投放开始日期是必填的!'
                                            },
                                        ],
                                    })(
                                        <SimpleDateShowTime placeholder={"开始日期"}
                                                            onChange={(e)=>{
                                                                this.setState({
                                                                    fromDate:e
                                                                })
                                                            }}
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item>
                                    {getFieldDecorator('endDate', {
                                        initialValue:LinkData ? LinkData.endDate:null,
                                        rules: [
                                            {
                                                required: false,
                                                message: '广告投放日期是必填的!'
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if (this.state.fromDate == "" && LinkData.fromDate) {
                                                        if (new Date(value) > new Date(LinkData.fromDate)) {
                                                            callback();
                                                            return;
                                                        }
                                                    }
                                                    else if(new Date(value) > new Date(this.state.fromDate)){
                                                        callback();
                                                        return;
                                                    }
                                                    callback('error');
                                                },
                                                message: '结束时间不能大于开始时间',
                                            }
                                        ],
                                    })(
                                        <SimpleDateShowTime placeholder={"结束日期"}/>,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit" size="large" block>
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </MyLayout>
        )
    }
}
export default Form.create()(AddOrEditAdvertise);