let RoutePath = {
    Login: '/login',
    Register: '/register',
    UserSetting: '/userSetting',
    UserInfo: '/userInfo',
    numberBinding: '/numberBinding',
    PaymentFail: '/PaymentFail',
    PaymentSuccess: '/PaymentSuccess',
    CertificateQuery: '/CertificateQuery',
    Home: '/home',
    VideoDetails: '/Personal/VideoDetail',
    VideoPlay: '/Personal/VideoPlay',
    moreVideoList: '/Personal/moreVideoList',
    LectureSceneDetail: '/Personal/LectureSceneDetail',
    SeatOrder: '/Personal/SeatOrder',
    OrderForm: '/Personal/OrderForm',
    MyBizOrderLis: '/Personal/MyBizOrderLis',
    famousTeacherList: '/famousTeacherList',
    FamousTeacherDetail: '/Personal/FamousTeacherDetail',
    moreLectureList: '/Personal/moreLectureList',
    OrgDetailsPersonal: '/Personal/OrgDetailsPersonal',
    SceneOrders: '/SceneOrders',
    VideoOrderForm: '/Personal/VideoOrderForm',
    MyVideoOrderList: '/Personal/MyVideoOrderList',
    myBuyVideoList: '/Personal/myBuyVideoList',
    myPlayVideoList: '/Personal/myPlayVideoList',
    StudyProgressDetail: '/Personal/StudyProgressDetail',
    GetMyJoinedClasses: '/Personal/student/GetMyJoinedClasses',
    MyPartinTaskList: '/Personal/student/MyPartinTaskList',
    StudentTaskDetail: '/Personal/student/StudentTaskDetail',
    CompanyHome: '/CompanyHome',
    TeacherList: '/company/TeacherList',
    AddOrEditTeacher: '/company/AddOrEditTeacher',
    TeacherDetail: '/company/TeacherDetail',
    VideoList: '/company/VideoList',
    videoDetail: '/videoDetail',
    AddOrEditVideo: '/AddOrEditVideo',
    MyOrgList: '/company/MyOrgList',
    AddApplyOrg: '/company/AddApplyOrg',
    OrgDetail: '/company/OrgDetail',
    MyGroundModelList: '/company/MyGroundModelList',
    GroundDetail: '/company/GroundDetail',
    LectureList: '/company/LectureList',
    AddOrEditLecture: '/company/AddOrEditLecture',
    LectureDetail: '/company/LectureDetail',
    MyVenueList: '/company/MyVenueList',
    AddOrEditVenue: '/company/AddOrEditVenue',
    VenueDetail: '/company/VenueDetail',
    AddOrEditScene: '/company/AddOrEditScene',
    SceneDetail: '/company/SceneDetail',
    moreVideoList: '/company/moreVideoList',
    moreLectureList: '/company/moreLectureList',
    GetOrgStaffs: '/staff/GetOrgStaffs',
    SearchClass: '/class/SearchClass',
    getClassStaffs: '/class/getClassStaffs',
    GetMyOcuppyClasses: '/taskadmin/GetMyOcuppyClasses',
    GetStudentsList: '/taskadmin/GetStudentsList',
    GetMyCreatedTasks: '/taskadmin/GetMyCreatedTasks',
    TaskDetail: '/taskadmin/TaskDetail',
    MyCreatePaperList: '/taskadmin/MyCreatePaperList',
    AddOrEditQuestion: '/taskadmin/AddOrEditQuestion',
    Admin: '/admin',
    PendingOrgList: '/admin/PendingOrgList',
    FieldList: '/admin/FieldList',
    UserManage: '/admin/UserManage',
    PendingVideoList: '/admin/PendingVideoList',
    PendingTchList: '/admin/PendingTchList',
    StarList: '/admin/StarList',
    AddOrEditStar: '/admin/AddOrEditStar',
    StarDetail: '/admin/StarDetail',
    AdminTeacherDetail: '/admin/AdminTeacherDetail',
    ExamineGround: '/admin/ExamineGround',
    PendingVenueList: '/admin/PendingVenueList',
    pendingLectureList: '/admin/pendingLectureList',
    AddOrEditAdvertise: '/admin/AddOrEditAdvertise',
    AdvertiseList: '/admin/AdvertiseList',
    VideoTypeAdmin: '/admin/videotype/videotypeadmin',
    SeriesList: '/company/series/serieslist',
    SeriesDetail: '/company/series/seriesdetail',
    SeriesExam: '/admin/series/seriesexam',
    SeriesPub: '/pub/series/seriespub',
    NoticePub: '/pub/notice/noticepub',
    NoticeList: '/pub/notice/noticelist',
    NewSginUp: '/pub/signup/newsginup',
    RealNameAuth: '/user/usersetting/realnameauth',
    StaticVideo: '/statistic/staticvideo',
    paperExamList: '/admin/paperexam/paperexamlist',
    StaticPaper: '/statistic/staticpaper',
    JudgePaper: '/taskadmin/testpaper/judgepaper',
    MyAnswerPaperList: '/personal/student/myanswerpaperlist',
    OrgAdmin: '/compay/org/orgadmin',
    NoticeUserAdmin: '/pub/notice/noticeuseradmin',
    GetQaDetail: '/qa/GetQaDetail',
    GetFollowmeTopicList: '/qa/GetFollowmeTopicList',
    DummyData: '/admin/jkdumydataabccdesdfasd',
    PageSearchSignup: '/pub/signup/pagesearchsingup',
    StaticPersonalList: '/statistic/staticpersonallist',
    FetchPersonalStatics: '/statistic/FetchPersonalStatics',
    GetOrgStaticsList: '/statistic/charts/GetOrgStaticsList',
    PlateformValidate: '/user/usersetting/plateform/plateformvalidate',
    PlateFormInfo: '/user/usersetting/plateform/plateforminfo',
    PlateformConnect: '/connect',
    HelpIndex: '/help',
    OrgList: '/orglist',
    StaticExamWhole: '/statistic/staticexamwhole',
    SystemStatics: '/statics/systotalstatics',
    NewsManagement: '/news/newsManagement',
    AddOrEditNews: '/news/addOrEditNews',
    NewsInformation: '/news/newsInformation',
    NewsDetail: '/news/newsDetail',
    CommentsAuditing: '/news/CommentsAuditing',
    BankAccount: '/user/bankaccount',
    AccountInfo: '/personal/accountinfo',
    AccountClosePay: '/admin/account/accountclosepay',
    NewsHistory: '/news/newsHistory',
    PersonalSnapshots: '/statistic/personalSnapshots',
    EditCertList: '/cert/editcert',
    StaticMonthLearn: '/statistic/staticmonthlearn',
    StaticTotalMonth: '/statistic/statictotalmonth',
    ClassSnapshots:'/statistic/classsnapshots',
    StaticRangeList:  '/statistic/staticranglelist',
    ExportSetting:'/statistic/exportsetting',
    EItemSetting:'/statistic/eitemsetting',
}

export default RoutePath
