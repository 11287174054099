import {Comment, List, Modal, Pagination} from 'antd'
import React from 'react'
import api from '../../../api/api'
import AddComment from '../newsInformation/AddComment'
import OkOrNg from '../newsInformation/OkOrNg'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import RoutePath from '../../../config/RoutePath'

class CommonComments extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      commentsData: [],
      totalNum: null,
      item: {},

      headerReply: false,
    }
    this.initPageSize = 10
    this.pageNum = 1
    this.pageSize = this.initPageSize
  }

  pageOnChange = (pageNum, pageSize) => {
    this.pageNum = pageNum
    this.pageSize = pageSize
    setTimeout(() => this.getComment(), 200)
  }

  getComment = () => {
    api.news.pageSearchNewsComment(
      {
        condition: {
          pid: this.props.commonId,
        },
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      },
      (state, data) => {
        if (state && data.status === 200) {
          let commentsData = data.data
          for (let i = 0; i < commentsData.length; i++) {
            commentsData[i].showReply = false
          }
          this.setState({
            commentsData,
            totalNum: data.totalNum,
          })
        }
      }
    )
  }

  showReply = index => {

    if (this.props.userInfo == null) {
      Modal.confirm(
        {
          title: '没有登录',
          content: '只有登录用户才能点赞评论，登录?',
          onOk: () => this.props.history.push(RoutePath.Login),
          okText: '去登录',
          onCancel: () => {},
          cancelText: '取消',
        }
      )
      return
    }

    let commentsData = this.state.commentsData
    commentsData[index].showReply = !commentsData[index].showReply
    this.setState({commentsData})
  }

  showModal = item => {
    this.setState({
      item: item,
      visible: true,
    })
  }

  handleCancel = e => {
    this.setState({
      visible: false,
    })
  }

  replyName = (name, returnName) => {
    const reply = returnName ? `${name} 回复 ${returnName}` : name
    return reply
  }

  componentDidMount() {
    this.getComment()
  }

  componentWillReceiveProps(props) {
    if (props.flushData !== this.props.flushData) {
      this.getComment()
    }
  }

  showHeaderReply() {

    if (this.props.userInfo == null) {
      Modal.confirm(
        {
          title: '没有登录',
          content: '只有登录用户才能点赞评论，登录?',
          onOk: () => this.props.history.push(RoutePath.Login),
          okText: '去登录',
          onCancel: () => {},
          cancelText: '取消',
        }
      )
      return
    }

    this.setState({headerReply: !this.state.headerReply})
  }

  render() {
    const commentsData = this.state.commentsData
    const inputData = this.props.inputData

    return (
      <>
        {inputData ? (
          <>
            <Comment
              style={{marginLeft: -15}}
              author={inputData.name}
              content={inputData.content}
              actions={[
                <OkOrNg
                  okId={inputData.commentId}
                  okNumber={inputData.oks}
                  ngNumber={inputData.ngs}
                  likeStatus={inputData.likeStatus}
                  okType="1"
                />,
                <a onClick={() => this.showHeaderReply()}>
                  {this.state.headerReply ? '收起' : '回复'}
                </a>,
              ]}
            />
            {this.state.headerReply ? (
              <div style={{marginLeft: -20, marginTop: -40}}>
                <AddComment
                  commentRows="1"
                  commonCommentId={inputData.commentId}
                  onUpdate={this.getComment}
                  commentType="1"
                ></AddComment>
              </div>
            ) : null}
          </>
        ) : null}
        <List
          className="comment-list"
          header={`${this.state.totalNum}条评论`}
          itemLayout="horizontal"
          dataSource={commentsData}
          renderItem={(item, index) => (
            <>
              <li>
                <Comment
                  author={this.replyName(
                    item.name,
                    item.returnName
                  )}
                  content={item.content}
                  actions={[
                    <OkOrNg
                      okId={item.commentId}
                      okNumber={item.oks}
                      ngNumber={item.ngs}
                      likeStatus={item.likeStatus}
                      okType="1"
                    />,
                    <a
                      style={{marginLeft: 10}}
                      onClick={() =>
                        this.showReply(index)
                      }
                    >
                      {item.showReply ? '收起' : '回复'}
                    </a>,
                    <>
                      {this.props.commentType === '0' ? (
                        <a
                          onClick={() =>
                            this.showModal(item)
                          }
                          style={{marginLeft: 20}}
                        >
                          更多回复
                        </a>
                      ) : null}
                    </>,
                  ]}
                />
                {item.showReply ? (
                  <AddComment
                    commentRows="2"
                    commonCommentId={item.commentId}
                    onUpdate={this.getComment}
                    commentType="1"
                  ></AddComment>
                ) : null}
              </li>
            </>
          )}
        />
        <Pagination
          onChange={(pageNum, pageSize) =>
            this.pageOnChange(pageNum, pageSize)
          }
          pageSize={this.pageSize}
          current={this.pageNum}
          total={this.state.totalNum}
        />
        <Modal
          title=""
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={[null, null]}
        >
          {this.state.visible ? (
            <CommonComments
              commentType="1"
              inputData={this.state.item}
              commonId={this.state.item.commentId}
              history={this.props.history}
              userInfo={this.props.userInfo}
            />
          ) : null}
        </Modal>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.user.userInfo,
  }
}

export default withRouter(connect(mapStateToProps, null)(CommonComments))
