import React,{ Component } from "react";
import HomeLayout from "../common/HomeLayout";
import {Row, Col, Tooltip, List, Card, Divider, message} from 'antd';
import api from "../../api/api";
import ComFunction from '../../api/ComFunction';
import {connect } from 'react-redux';
import { PlayCircleOutlined } from "@ant-design/icons";
import Action from "../../actions/Action";
import CON from "../../config/const";
import Sfs from "../../config/Sfs";
const { Meta } = Card;


class Home extends Component{

    constructor(props) {
        super(props);
        this.state = {
            videoListData:[],
            starListData:[],
            lectureListData:[],
            pageNum:1,
            pageSize:8,
            totalNum:8,
        };
    }

    videoList() {
        api.video.videoList(
            {
                "condition": {
                    "lectureDateEnd": "",
                    "lectureDateStart": "",
                    "lectureName": "",
                    "teacherName": "",
                    "status":null,
                    'sort':1,
                    "videoKeywords": [],
                },
                "pageNum": 1,
                "pageSize": 8,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        videoListData:data.data,
                    })
                }
            }
        )
    }

    getTodayStar(){
        api.teacher.getTodayStar("",
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        starListData:data.data,
                    })
                }
            }
        )
    }

    lectureList(){
        api.lecture.lectureList(
            {
                "condition": {
                    "beforeEnd": true,
                    "endTime": "",
                    "lectureName": "",
                    "orgIds": [],
                    "startTime": "",
                    "teahcerNames": []
                },
                "pageNum": 1,
                "pageSize": 8,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        lectureListData:data.data,
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.videoList();
        this.lectureList();
        this.getTodayStar();
    }

    render()
    {
        let history = this.props.history;
        return(
            <HomeLayout >
                <Row style={{marginTop: "45px", marginBottom: "20px",display:'flex'}}>
                    <Col span={22}>
                        <div style={{fontSize:"24px"}}>热门视频</div>
                    </Col>
                    <Col span={2} style={{top:"20px"}}>
                        <a onClick={()=>
                            history.push('/Personal/moreVideoList')
                        }>查看更多视频</a>
                    </Col>
                </Row>
                <List
                    grid={{ gutter: 20, column: 4 }}
                    itemLayout="vertical"
                    size="large"
                    dataSource={this.state.videoListData}
                    renderItem={(item,index) => (
                        <List.Item key={index}>
                            <Card hoverable
                                cover={<img alt="example" style={{height:"170px"}} src={item.posterPath} />}
                                onClick={()=>
                                    history.push('/Personal/VideoDetail'+'/'+item.vid)
                                }
                            >
                                <Meta title={item.name}
                                    style={{height:"100px"}}
                                    description={
                                        <div>
                                            <div className="twoElements">
                                                <Tooltip title= {item.intro}>
                                                    {item.intro}
                                                </Tooltip>
                                            </div>
                                            <Row>
                                                <Col span={16}>
                                                    <div className="home-PriceValue">
                                                    {
                                                        item.price==0?
                                                            <div>
                                                                限时免费
                                                            </div>:
                                                            <div>
                                                                 <span style={{fontSize:"16px", color: "#aaaaaa"}}>
                                                                     价格：
                                                                 </span>
                                                                ￥{item.price/100}
                                                            </div>
                                                    }
                                                    </div>
                                                </Col>
                                                <Col span={8} style={{color:"#b7b7b7",paddingTop:"10px"}}>
                                                    <span>
                                                    <PlayCircleOutlined />{ComFunction.getVisitedTimesDisplayText(item.visitTimes)}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </div>
                                    } />
                            </Card>
                        </List.Item>
                    )}
                />
                {Sfs.isLectureOn?<>
                 {!CON.IS_FREE?<>
                <Divider />
                <Row style={{marginTop: "45px", marginBottom: "20px",display:'flex'}}>
                    <Col span={22}>
                        <div style={{fontSize:"24px"}}>热门讲座</div>
                    </Col>
                    <Col span={2} style={{top:"20px"}}>
                        <a onClick={()=>
                            history.push('/Personal/moreLectureList')
                        }>查看更多讲座</a>
                    </Col>
                </Row>
                <List
                    grid={{ gutter: 20, column: 4 }}
                    itemLayout="vertical"
                    size="large"
                    dataSource={this.state.lectureListData}
                    renderItem={(item,index) => (
                        <List.Item
                            key={index}
                        >
                            <Card hoverable
                                  cover={<img alt="example" style={{height:"170px"}} src={item.posterPath} />}
                                  onClick={()=>
                                      history.push('/Personal/LectureSceneDetail'+'/'+item.lid)
                                  }
                                  actions={[
                                      <div>选座购票</div>
                                  ]}
                            >
                                <Meta title={item.name} style={{height:"60px"}} description={
                                    <div className="twoElements">
                                        <Tooltip title= {item.intro}>
                                            {item.intro}
                                        </Tooltip>
                                    </div>
                                } />
                            </Card>
                        </List.Item>
                    )}
                />
                </>:null}
            </>:null}
                <Divider />
                <Row style={{marginTop: "45px", marginBottom: "20px",display:'flex'}}>
                    <Col span={22}>
                        <div style={{fontSize:"24px"}}>今日名师</div>
                    </Col>
                </Row>
                <List
                    grid={{ gutter: 20, column: 4 }}
                    itemLayout="vertical"
                    size="large"
                    dataSource={this.state.starListData}
                    renderItem={(item,index) => (
                        <List.Item
                            key={index}
                        >
                            <Card hoverable
                                  cover={<img alt="讲师照"
                                              className="homeImg"
                                              src={ComFunction.getScareImgeUrl(item.teacher.photoPath,200,260)}
                                             />
                                  }
                                  onClick={()=>
                                      history.push('/Personal/FamousTeacherDetail'+'/'+item.teacher.tid)
                                  }
                            >
                                <Meta title={item.teacher.name}
                                      style={{height:"60px"}} description={
                                    <div className="twoElements">
                                        <Tooltip title= {item.teacher.motto}>
                                            {item.teacher.motto}
                                        </Tooltip>
                                    </div>
                                }/>
                            </Card>
                        </List.Item>
                    )}
                />
            </HomeLayout>
        )
    }
}

//需要触发什么行为
function mapDispatchToProps(dispatch) {
    return {
        saveUserInfoToStore: (data) => dispatch({
            type: Action.SAVE_USER_INFO, //CON.SAVE_USER_INFO
            data: data,
        }),
    }
}


export default connect(null,mapDispatchToProps)(Home);