import React, { Component } from 'react';
import api from '../../api/api';
import { setLoadingState } from '../../reducers/UserInfo';
import MyLayout from '../common/MyLayout';
import { Checkbox, Table, Divider, Row, Col, Form, Select, Button, message, Tooltip } from 'antd';
import RoutePath from '../../config/RoutePath';
import moment from 'moment';
import SimpleDateSel from '../../components/SimpleDateSel';
import ComFunction from '../../api/ComFunction';

class StaticRangeList extends Component {

    constructor(props) {
        super(props);
        this.state = {

            yearMonthEnd: moment().format("YYYY/MM/DD HH:mm:ss"),
            yearMonthStart: moment().subtract(5, 'months').format("YYYY/MM/DD HH:mm:ss"),

            data:[],
            loading:false,
        };
    }

    featchMtClassStaticDataRange()
    {
        setLoadingState(true);
        let orgId = this.props.match.params.orgId;
        api.statics.getMtClassStaticDataRange({
            orgId: orgId,
            yearMonthStart: this.state.yearMonthStart,
            yearMonthEnd: this.state.yearMonthEnd,
        },
        (state, data)=>{
            if(state && data.status==200) {

                let mdata = data.data;
                for(let i = 0; i < mdata.length; i++)
                {
                    let studentNumOk = mdata[i].studentNumOk;
                    mdata[i].avgNumVideo =ComFunction.getIntDiveder(mdata[i].numVideo,studentNumOk);
                    mdata[i].avgNumVideoDuration =ComFunction.getIntDiveder(mdata[i].numVideoDuration,studentNumOk);
                    mdata[i].avgNumAnswer =ComFunction.getIntDiveder(mdata[i].numAnswer,studentNumOk);
                    mdata[i].avgNumAnswerItems =ComFunction.getIntDiveder(mdata[i].numAnswerItems,studentNumOk);
                }
                this.setState({
                    data:mdata,
                })
            }
            else
            {
                message.error("日期超限或者其它错误");
            }
            setLoadingState(false);
        }
        )
    }

    research() {
        setTimeout(()=>this.featchMtClassStaticDataRange(), 200);
    }

    componentDidMount()
    {
        this.featchMtClassStaticDataRange();
    }

    render() {

        let columns = [
            {
                title: '#班号#',
                dataIndex: 'classId',
                key: 'classId',
            },
            {
                title: '班级名',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow",
                render:(text,record)=>(
                    <Tooltip title={text}>
                            {text}
                    </Tooltip>
                )
            },
            {
                title: '人数',
                dataIndex: 'studentNumOk',
                key: 'studentNumOk',
                sorter: (a, b) => a.studentNumOk-b.studentNumOk,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '总视频数',
                dataIndex: 'numVideo',
                key: 'numVideo',
                sorter: (a, b) => a.numVideo-b.numVideo,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '平均视频数',
                dataIndex: 'avgNumVideo',
                key: 'avgNumVideo',
                sorter: (a, b) => a.avgNumVideo-b.avgNumVideo,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '总时长(分)',
                dataIndex: 'numVideoDuration',
                key: 'numVideoDuration',
                sorter: (a, b) => a.numVideoDuration-b.numVideoDuration,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '平均时长(分)',
                dataIndex: 'avgNumVideoDuration',
                key: 'avgNumVideoDuration',
                sorter: (a, b) => a.avgNumVideoDuration-b.avgNumVideoDuration,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '总试卷数',
                dataIndex: 'numAnswer',
                key: 'numAnswer',
                sorter: (a, b) => a.numAnswer-b.numAnswer,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '平均卷数',
                dataIndex: 'avgNumAnswer',
                key: 'avgNumAnswer',
                sorter: (a, b) => a.avgNumAnswer-b.avgNumAnswer,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '总做题数',
                dataIndex: 'numAnswerItems',
                key: 'numAnswerItems',
                sorter: (a, b) => a.numAnswerItems-b.numAnswerItems,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: '平均做题数',
                dataIndex: 'avgNumAnswerItems',
                key: 'avgNumAnswerItems',
                sorter: (a, b) => a.avgNumAnswerItems-b.avgNumAnswerItems,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title:'历史数据',
                dataIndex: 'descrp',
                key: 'descrp',
                render: (text, record) => (
                  <Button
                   type="primary"
                   onClick={
                       ()=>this.props.history.push(RoutePath.ClassSnapshots + "/" + record.classId)
                   }
                  >
                    历史数据
                  </Button>
              )
          }
        ];

        return (
            <MyLayout>
            <Row>
                <Col span={24}>
                <Form layout='inline'>
                    <Form.Item label='选择开始月份'>
                        <SimpleDateSel
                        picker="month"
                        size="default"
                        value={this.state.yearMonthStart}
                        onChange={
                            (yearMonthStart)=>{
                                this.setState({yearMonthStart: moment(yearMonthStart).format("YYYY/MM/DD HH:mm:ss")});
                            }
                        }
                        />
                    </Form.Item>
                    <Form.Item label='选择结束月份'>
                        <SimpleDateSel
                        picker="month"
                        size="default"
                        value={this.state.yearMonthEnd}
                        onChange={
                            (yearMonthEnd)=>{
                                this.setState({yearMonthEnd: moment(yearMonthEnd).format("YYYY/MM/DD HH:mm:ss")});
                            }
                        }
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                        type='primary'
                        onClick={()=>this.research()}
                        >
                            搜索
                        </Button>
                    </Form.Item>
                </Form>
                </Col>
                </Row>
                <Table
                    dataSource={this.state.data}
                    columns={columns}
                    rowKey='classId'
                    pagination={false}
                />
        </MyLayout>
        );
    }
}

export default StaticRangeList;
