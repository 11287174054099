
import React, { Component } from 'react';
import MyLayout from '../../common/MyLayout';
import api from '../../../api/api';
import { Pagination,Divider, Table, Button, Form, Modal, message, Input, Popover, Ico, Row, Col, Select} from 'antd';
import VideoTypeSelect from '../../../components/VideoTypeSelect';
import Dict from '../../../config/Dict';
import { RobotFilled } from '@ant-design/icons';

class SeriesExam extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:[],
            status: 0,

            pageNum:1,
            pageSize:5,
            totalNum:9,
         };
    }


    fetachSeriesList(){
        api.series.pageSerchSeries(
            {
                "condition": {
                    "status":this.state.status,
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    componentDidMount()
    {
        this.fetachSeriesList();
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.fetachSeriesList(), 200);
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:5,
            totalNum:9,
        });
        setTimeout(()=>this.fetachSeriesList(), 200,);
    }

    examPassOrReject(param){
        api.series.examPassOrReject(
            param,
            (state,data)=>{
                this.fetachSeriesList();
            }
        )
    }

    render() {

        const columns = [
            {
                title: '系列名',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '类别',
                key: 'typeId',
                render: (text, record) => (
                    <VideoTypeSelect
                        value={record.typeId}
                        disabled={true}
                        bordered={false}
                        display = {true}
                    />
                )
            },
            {
                title: '状态',
                key: 'status',
                render: (text, record) => (
                    <div>
                    {
                        Dict.getValue('seriesStatus',record.status)
                    }
                    </div>
                )
            },
            {
                title: '创建日期',
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: '详情',
                key: 'description',
                render: (text, record) => (
                    <Popover
                    content={record.description}
                    >
                    {record.description.substring(0,10)}
                    </Popover>
                )
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button
                        type='primary'
                        onClick = {
                            ()=>
                            {
                                this.examPassOrReject(
                                    {
                                        seriesId:record.seriesId,
                                        status:2
                                    }
                                )
                            }
                        }
                        >
                        审核通过
                        </Button>
                        <Divider type='vertical'/>
                        <Button
                        type='danger'
                        onClick = {
                            ()=>
                            {
                                this.examPassOrReject(
                                    {
                                        seriesId:record.seriesId,
                                        status:3
                                    }
                                )
                            }
                        }
                        >
                        审核拒绝
                        </Button>
                    </span>
                ),
            },
        ];

        return (
            <MyLayout>
                    <div className="userEditTitle">系列审核</div>
                    <Divider/>
                        <Form layout='inline'>
                            <Form.Item label='选择状态'>
                            <Select
                              value={this.state.status}
                              onChange={(value)=>{
                                  this.setState({
                                      status:value
                                  })
                                  this.research();
                              }}
                              >
                                <Select.Option value={0}>编辑中</Select.Option>
                                <Select.Option value={1}>待审核</Select.Option>
                                <Select.Option value={2}>已通过</Select.Option>
                                <Select.Option value={3}>已拒绝</Select.Option>
                            </Select>
                            </Form.Item>
                        </Form>
                    <Divider/>
                    <Table
                    dataSource={this.state.data}
                    columns={columns}
                     />
                    <Pagination
                            onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.state.pageSize}
                            current={this.state.pageNum}
                            total={this.state.totalNum}
                        />
            </MyLayout>
        );
    }
}

export default SeriesExam;

