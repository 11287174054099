import React, { Component } from 'react';
import {Modal, Button, Input, Form, message, Row, Col} from 'antd';
import api from "../../../api/api";
import connect from "react-redux/es/connect/connect";
import Action from '../../../actions/Action';


class IdCarModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };


    handleOk = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.user.editIdNumber({...values},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.props.refreshUserInfo();
                            //this.props.changeNewPwd(data.data);// 子向父传值[changeNewPwd={(newPwd)=>this.newPwdFun(newPwd)}]
                            this.setState({
                                visible: false,
                            });
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        let userInfo = this.props.userInfo;
        return (
            <div>
                <Button type="link" onClick={this.showModal}>
                    {this.props.btnName}
                </Button>
                <Modal
                    title="绑定手机/邮箱"
                    width={620}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Form className="modal-form-style">
                        <Form.Item label='身份证号'>
                            {
                                getFieldDecorator('idNumber', {
                                    initialValue:userInfo?userInfo.idNumber:'',
                                    rules: [
                                        {
                                            required: true,
                                            validateStatus:'error',
                                            message: '身份证号是必须输入的!'
                                        },
                                        {
                                            pattern:/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
                                            message: '身份证号不满足规则!'
                                        },
                                        {
                                            validator: (rule, value, callback) => {
                                                api.user.basicinfocheck({
                                                        "checkItem": value,
                                                        "type": 3
                                                    },
                                                    (state,data)=>{
                                                        if(state && data.status == 200) {
                                                            callback();
                                                        }
                                                        else  {
                                                            callback('error');
                                                        }
                                                    });
                                            },
                                            message: '身份证号码重复或者无效！',
                                        },
                                    ],
                                })(
                                    <Input
                                        placeholder="例如:230103195607117012"
                                    />,
                                )}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        refreshUserInfo: (data) => dispatch({
            type: Action.GET_USER_INFO,
        }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'IdCarModal' })(IdCarModal));
