/* eslint-disable jsx-a11y/alt-text */

import React, { Component } from 'react';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

class BaseInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Typography>
                <Title level={2}>基本信息</Title>
                <Paragraph>
                    本文将会指导用户如何编辑个人信息。
                </Paragraph>
                <Paragraph>
                    成功登录后，用户点击右上角的个人中心，选中下拉菜单的第一个选项【个人信息】，点击左侧导航栏中的第一条【基本信息】后，可以看到当前登录账号的基本信息详情。
                </Paragraph>
                <Paragraph>
                    点击右上角的【修改基本信息】，可以在弹出的窗口中修改基本信息。
                </Paragraph>
                <Paragraph>
                    另外，如果用户有更换头像的需求，在上述操作之后点击左侧的头像，在弹出的窗口中找到想要更换的头像点击【打开】即可。
                </Paragraph>
                <Paragraph>
                    当用户确认修改完基本信息后，点击弹出窗口最下方的【确定】按钮进行保存，即可完成对用户基本信息的修改。
                </Paragraph>
            </Typography>
        );
    }
}

export default BaseInfo;