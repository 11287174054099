
import React, { Component } from 'react';
import MyLayout from '../../../common/MyLayout';
import { connect } from 'react-redux';
import api from '../../../../api/api';
import {Col, Row, Button, Form, Descriptions, Modal, Input,message } from 'antd';
import Action from '../../../../actions/Action';

class PlateFormInfo extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            visible:false,
        };
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {		
                api.user.editMyUserInfo(
                    values,
                    (state,data)=>{
                        this.setState({visible:false});
                        if(state && data.status == 200) {
                            this.props.refreshUserInfo();
                        } else {
                            message.error(data.msg);
                        }
                    }
                )	
            }
        });
    };

    resetAppSecret()
    {
        Modal.confirm({
            title:'!!!警告!!!',
            content:'重置后，原来的appSecret将不好用，需更新未新的。',
            onOk:()=>{
                api.user.resetAppSecret(
                    null,
                    (state,data)=>{
                        this.props.refreshUserInfo();
                    }
                )
            },
            onCancel:()=>{

            },
            okText:"确定",
            cancelText:"取消",
        })
    }

    render() {

        let userInfo = this.props.userInfo;
        if(!userInfo)
        {
            userInfo = {};
        }
        const { getFieldDecorator } = this.props.form;

        return (
            <MyLayout sider={"UserSettingSider"}>
                <Descriptions title="第三方信息" bordered column={1}>
                    <Descriptions.Item label="appId">
                        {userInfo.appId}
                    </Descriptions.Item>
                    <Descriptions.Item label="appSecret">
                        <Row>
                            <Col span={18}>
                            {userInfo.appSecret?userInfo.appSecret:"未设置"}
                            </Col>
                            <Col span={6}>
                                <Button 
                                type='danger'
                                onClick={()=>this.resetAppSecret()}
                                >重置</Button>
                            </Col>
                        </Row>
                    </Descriptions.Item>
                    <Descriptions.Item label="域名白名单">
                        <Row>
                            <Col span={18}>
                                {userInfo.redirectDomain?userInfo.redirectDomain:"未设置"}
                            </Col>
                            <Col span={6}>
                            <Button 
                                type='primary'
                                onClick={()=>{
                                    this.props.form.setFieldsValue({
                                        redirectDomain:userInfo.redirectDomain
                                    })
                                    this.setState({visible:true})
                                }}
                                >编辑</Button>
                            </Col>
                        </Row>
                        
                    </Descriptions.Item>
                </Descriptions>
                <div>*多个域名用逗号(,)隔开</div>
                <Modal
                 title="编辑域名信息"
                 visible={this.state.visible}
                 onCancel={()=>this.setState({visible:false})}
                 footer={null}
                >
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item>
                             {getFieldDecorator('redirectDomain', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '域名必须输入'
                                        },
                                    ],
                                })(
                                    <Input size="large"
                                        placeholder="域名逗号隔开,例如a.b.c,aa.bb.cc"
                                    />,
                                )}
                        </Form.Item>
                        <Form.Item>
                            <Button  type="primary" htmlType="submit" block>
                                确定
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

            </MyLayout>
        );
    }
}


function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        refreshUserInfo: (data) => dispatch({
            type: Action.GET_USER_INFO,
        }),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Form.create()(PlateFormInfo));


