import React, { Component } from 'react';
import MyLayout from '../../../common/MyLayout';
import {Divider, Table, Button, Form, Modal, message, Input, Popover, Icon } from 'antd';
import api from '../../../../api/api';
import ImageUpload from '../../../../components/ImageUpload';
import TextArea from 'antd/lib/input/TextArea';
import VideoTypeSelect from '../../../../components/VideoTypeSelect';
import Dict from '../../../../config/Dict';
import RoutePath from '../../../../config/RoutePath';

class SeriesList extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            series:[],
            visible:false,
        };
    }

    handleSumbit=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.series.addOrEditVideoSeries(
                    values,
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.fetchMySeriesList();
                        }
                        else
                        {
                            message.error(data.msg);
                        }
                    }
                );
                this.setState({visible:false});
            }
        });
    };

    fetchMySeriesList()
    {
        api.series.getMyVideoSeries(
            null,
            (state,data)=>{
                if(state && data.status == 200)
                {
                    this.setState({
                        series:data.data
                    })
                }
            }
        )
    }

    componentDidMount()
    {
        this.fetchMySeriesList();
    }

    handleDelete=(record)=>{
        Modal.confirm({
            title: '确认删除',
            content: '真的要删除'+record.title,
            onOk:()=>{
                api.series.deleteSeries(
                    {seriesId:record.seriesId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.fetchMySeriesList();
                        }
                        else
                        {
                            message.error(data.msg);
                        }
                    } 
                )
            },
            okText: '确认',
            cancelText: '取消',
          });
    }


    handleExam=(record)=>{
        Modal.confirm({
            title: '确认提交审核',
            content: '真的要提交审核'+record.title,
            onOk:()=>{
                api.series.sumbitToExam(
                    {seriesId:record.seriesId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.fetchMySeriesList();
                        }
                        else
                        {
                            message.error(data.msg);
                        }
                    } 
                )
            },
            okText: '确认',
            cancelText: '取消',
          });
    }

    render() {

        let history = this.props.history;
        const columns = [
            {
                title: '系列名',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '类别',
                key: 'typeId',
                render: (text, record) => (
                    <VideoTypeSelect
                        value={record.typeId}
                        disabled={true}
                        bordered={false}
                        display = {true}
                    />
                )
            },
            {
                title: '状态',
                key: 'status',
                render: (text, record) => (
                    <div>
                    {
                        Dict.getValue('seriesStatus',record.status)
                    }
                    </div>
                )
            },
            {
                title: '创建日期',
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: '详情',
                key: 'description',
                render: (text, record) => (
                    <Popover
                    content={record.description}
                    >
                    {record.description.substring(0,10)}
                    </Popover>
                )
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button
                            onClick={()=>{
                                let form = this.props.form;
                                form.setFieldsValue(record);
                                this.setState({visible:true});
                            }}
                        ><Icon type='edit'/></Button>
                        {
                            record.status == 0 || record.status == 3?
                            <>
                            <Divider type='vertical'/>
                            <Button
                            type='primary'
                            onClick={
                                ()=>{
                                    this.handleExam(record);
                                }
                            }
                            >
                            提交审核
                            </Button>
                            </>
                            :
                            null
                        }
                        <Divider type='vertical'/>
                        <Button
                        type='danger'
                        onClick={
                            ()=>{
                                this.handleDelete(record);
                            }
                        }
                        >
                        删除
                        </Button>
                        <Divider type='vertical'/>
                        <Button
                        onClick={
                            ()=>{
                                history.push(RoutePath.SeriesDetail+'/'+record.seriesId);
                            }
                        }
                        >
                        编辑章节
                        </Button>
                    </span>
                ),
            },
        ];

        const { getFieldDecorator } = this.props.form;
        getFieldDecorator('seriesId',{});

        return (
            <MyLayout sider={"InfoManageSider"}>
                <div className="userEditTitle">系列管理</div>
                <Divider/>
                <Button type='primary'
                        onClick={
                            ()=>{
                                let form = this.props.form;
                                form.setFieldsValue({
                                    seriesId:null,
                                    title:null,
                                    description:null,
                                    typeId:null,
                                    coverPath:null,
                                    });
                                this.setState({
                                visible:true
                                });
                            }
                        }
                
                >添加新系列</Button>

                <Table
                    dataSource={this.state.series}
                    columns={columns}
                />

                <Modal                
                    title='添加编辑系列'
                    visible={this.state.visible}
                    onCancel={()=>{
                                this.setState({
                                    visible:false
                                })
                            }}
                    okText="确认"
                    cancelText="取消"
                    footer={null}
                >
                <Form onSubmit={this.handleSumbit}>
                    <Form.Item label='系列名'>
                    {
                            getFieldDecorator('title', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '类别名是必须输入的!'
                                    },
                                ],
                            })(
                            <Input size="large"
                                 placeholder="类别名"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label='系列类型'>
                    {
                            getFieldDecorator('typeId', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '系列类型是必须输入的!'
                                    },
                                ],
                            })(
                                <VideoTypeSelect/>
                        )}
                    </Form.Item>
                    <Form.Item label='封面图片'>
                        {
                            getFieldDecorator('coverPath', {
                                rules: [
                                    {
                                        required: true,
                                        message: '封面图片必须上传!'
                                    },

                                ],
                            })(
                                <ImageUpload/>,
                            )
                        }
                    </Form.Item>
                    <Form.Item label='详细介绍'>
                    {
                            getFieldDecorator('description', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '详细介绍是必须输入的!'
                                    },
                                ],
                            })(
                            <TextArea size="large"
                                 placeholder="类别名"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit" size="large" block>
                            确定
                        </Button>
                    </Form.Item>
                </Form>

                </Modal>

            </MyLayout>
        );
    }
}

export default Form.create()(SeriesList);