import React, {Component} from 'react'
import {Typography} from 'antd'

const {Title, Paragraph} = Typography

class StudentAdvantage extends Component {
    render() {
        return (
            <Typography>
                <Title level={2}>学生优势</Title>
                <Paragraph>
                    学习者可以通过在家上网学习，只要有网的地方，即可利用碎片化的时间学习。视频、图片和资料可在电子产品上反复观看，学习者可针对自己学习弱项强化训练。网上通过课程学习，对学习者而言，价格相对优惠。网课能够做到停课不停学。
                </Paragraph>
                <Paragraph>
                    网络培训是比起坐在教室里上课，网课虽然少了一点互动性，但是网课的回放功能却是在教室上课时不具备的。在教室里上课，学生们只能跟着老师的节奏来，有时候老师的节奏比较快，部分学生跟不上，可能就会影响后续的听课效果。并且可以自己制定自己的学习计划，安排自己的学习时间。这样也不会影响到自己的娱乐休息时间。而且自己也有了更多的课余时间。
                </Paragraph>
            </Typography>
        )
    }
}

export default StudentAdvantage