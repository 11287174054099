import React, { Component } from 'react';
import {Modal, Button, Icon, Input, Form, message, Row, Col} from 'antd';
import api from "../../../api/api";
import DataValidate from "../../../config/DataValidate";

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            item:null, //手机或邮箱号
            isPAndM:"", //类型
            imagecode:null, //图片验证码
            imgenum:1000,
            codeYesAndNo:true
        };
    }

    refreshImage() {
        this.setState({
                imgenum:Math.ceil(Math.random()*1000000)
            }
        )
    }

    getCode() {
        if(this.state.isPAndM == '0') {
            api.user.sendPhoneKey({
                    phone:this.state.item,
                    validatekey:this.state.imagecode,
                },
                (status, data) => {
                    if (status && data.status == 200) {
                        message.info("验证码发送成功");
                    } else {
                        message.error(data.msg);
                    }
                }
            );
        }
        else if (this.state.isPAndM == '1') {
            api.user.sendvalidateemail({
                    email:this.state.item,
                    validatekey:this.state.imagecode,
                },
                (status, data) => {
                    if (status && data.status == 200) {
                        message.info("验证码发送成功");
                    } else {
                        message.error(data.msg);
                    }
                }
            );
        }
    }

    handleOk = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.user.resetpwdbyphoneoremail(
                    {...values},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.setState({
                                visible: false,
                            });
                            Modal.success({
                                content: '您的新密码是:'+data.data,
                            });
                        } else {
                            message.error('error');
                        }
                    }
                )
            }
        });
    };

    basicInfoCheck=()=> {
        const { getFieldDecorator } = this.props.form;
        return getFieldDecorator("item", {
            rules: [
                {
                    required: true,
                    validateStatus:'error',
                    message: '电话/邮箱是必须输入的!'
                },
                {
                    validator: (rule, value, callback) => {
                        if(DataValidate.isMobile(value) ) {
                            this.setState({isPAndM:"0"});
                            callback();
                            return;
                        }
                        else if (DataValidate.isEmail(value)){
                            this.setState({isPAndM:"1"});
                            callback();
                            return;
                        }
                        callback('error');
                    },
                    message: '电话/邮箱格式不对',
                },
                {
                    validator: (rule, value, callback) => {
                        let type = "";
                        if(DataValidate.isMobile(value) ) {
                            type = 1;
                        } else if (DataValidate.isEmail(value)){
                            type = 2;
                        }
                        api.user.basicinfocheck({
                                "checkItem": value,
                                "type": type
                            },
                            (state,data)=>{
                                if(state && data.status == 7000130 || data.status ==7000140) {//电话和邮箱已经注册
                                    callback();
                                    return;
                                }
                                else  { //还没注册
                                    callback('error');
                                }
                            });
                    },
                    message: '这个电话/邮箱还没有注册过！',
                },
            ],
        })(
            <Input size="large"
                   prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                   onChange={(e)=>{
                       this.setState({
                           item:e.target.value,
                       })
                   }}
                   placeholder="手机/邮箱"
            />,

        );
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const phoneCodeValue = (
            <Button style={{ height: 22, border:0,background: "#FAFAFA"}} onClick={()=>{
               this.getCode();
            }}>获取验证码</Button>
        );
        return (
            <span>
                <Button type="link" onClick={()=>this.setState({visible: true})}>
                    {this.props.btnName}
                </Button>
                <Modal
                    title="找回密码"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={()=>{
                        this.setState({
                        visible: false,
                    })}}
                >
                    <Form>
                        <Form.Item hasFeedback>
                            {this.basicInfoCheck()}
                        </Form.Item>
                        <Form.Item >
                            {getFieldDecorator("validate", {
                                rules: [
                                    {
                                        required: true,
                                        message: '验证码必须输入!'
                                    },
                                ],
                            })(
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <Input size="large"
                                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            placeholder="请输入图片验证码"
                                               onChange={(e)=>{
                                                   this.setState(
                                                       {
                                                           imagecode:e.target.value,
                                                       }
                                                   )
                                               }}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <img
                                            onClick={()=>this.refreshImage()}
                                            style={{width: 120, height: 40}}
                                            src={'/api/user/generateCodeImg?imgnum'+this.state.imgenum}/>
                                    </Col>
                                </Row>
                            )}
                        </Form.Item>
                        <Form.Item >
                            {getFieldDecorator('validateCode', {
                                rules: [
                                    {
                                        required: true,
                                        message: '验证码必须输入!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(DataValidate.isMobileCode(value)) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '验证码格式不对',
                                    },
                                ],
                            })(
                                <Input size="large"
                                       prefix={<Icon type="check" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                       placeholder="验证码" addonAfter={phoneCodeValue}
                                />,
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </span>
        );
    }
}
export default Form.create()(ResetPassword);