import React, { Component } from 'react';
import { Tooltip, Affix,Table, Divider, PageHeader, Tag, message, Col, Row, Modal, Button} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import Dict from "../../../config/Dict";
import AddOrEditPaperModal from "../testPaper/AddOrEditPaperModal";
const { confirm } = Modal;

class MyCreatePaperList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:[],
        };
    }

    getMyCreatePaperList() {
        api.exam.getMyCreatePaperList("",
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }else{
                    message.error(data.msg);
                }
            }
        )
    }

    removePaperById(paperId){
        confirm({
            title: "提示",
            content:"你确定要删除这张试卷吗?",
            onOk:()=>{
                api.exam.removePaperById({paperId},
                    (state, data)=>{
                        if(state && data.status==200) {
                            message.success("删除成功!");
                            this.getMyCreatePaperList();
                        }else {
                            message.error("请先删除试题在删除试卷!");
                        }
                    }
                )
            }
        });
    }

    onChange(ar){
        if (ar){
            this.getMyCreatePaperList();
        }
    }

    componentDidMount() {
        this.getMyCreatePaperList();
    }

    getParentTitle(parentPaperId)
    {
        if(!parentPaperId)
        {
            return "非补考"
        }

        let data = this.state.data;
        for(let i = 0; i < data.length; i++)
        {
            if(parentPaperId === data[i].paperId)
            {
                return data[i].title;
            }
        }
        return '--';
    }

    render() {
        let history = this.props.history;
        const columns = [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                render:(text, record,index) => (
                    <span>{index+1}</span>
                ),
            },
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
                className:"tableOverflow",
                render: (text, record) => (
                    <Tooltip title={text}>
                        {text}
                    </Tooltip>
                ),
            },
            {
                title: '补考状态',
                dataIndex: 'parentPaperId',
                key: 'parentPaperId',
                className:"tableOverflow",
                render: (text, record) => {
                    let txt = this.getParentTitle(record.parentPaperId);
                    return <Tooltip title={txt}>
                    {txt}
                    </Tooltip>
                },
            },
            {
                title: '题数/分值/时长/人数',
                dataIndex: 'itemNum',
                key: 'itemNum',
                className:"tableOverflow",
                render: (text, record)=>(
                    <div>{record.itemNum}/{record.totalScore}/{record.duration}/{record.answerNum}</div>
                )
            },
            {
                title: '考试时间',
                dataIndex: 'answerDate',
                key: 'answerDate',
                render: (text, record) => (
                    <div>
                        <span>{record.startTime?record.startTime:'未定'}</span>
                        -
                        <span>{record.endTime?record.endTime:'未定'}</span>
                    </div>
                )
            },
            {
                title: '状态',
                key: 'statuss',
                dataIndex: 'statuss',
                render: (text, record) => (
                    <span>
                    <Tag color={record.status==0?"#2db7f5":record.status==1?"#cc88d0":record.status==2?"#87d068":"#f50"}>
                        {
                            Dict.getValue('paperStatus', record.status, '')
                        }
                    </Tag>
                    <Tag color={record.examType==0?"#87d068":"#2db7f5"}>
                        {
                            Dict.getValue('examType',record.examType,'')
                        }
                    </Tag>
                    </span>
                ),
            },
            {
                title: '操作',
                key: 'action',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 240,
                        }
                    }
                },
                render: (text, record) => (
                    <div>
                    <AddOrEditPaperModal 
                        type="edit"
                        data={record}
                        paperList={this.state.data}
                        onChange={(ar)=>this.onChange(ar)}/>
                    <Divider type='vertical'/>
                    <Button type='primary'
                    onClick={()=>
                            history.push("/taskadmin/AddOrEditQuestion"+'/'+record.paperId)}>
                        编辑题目
                    </Button>
                    <Divider type='vertical'/>
                    <Button
                    type='danger'
                    onClick={
                        ()=>this.removePaperById(record.paperId)
                        }
                    >删除</Button>
                    </div>
                ),
            },
        ];
        return(
            <MyLayout>
                <PageHeader
                    style={{padding:"0"}}
                    title="试卷列表"
                    subTitle="我的试卷列表"
                />
                <Divider/>
                <AddOrEditPaperModal type="add"
                                    paperList={this.state.data}
                                     onChange={(ar)=>this.onChange(ar)}/>
                <Table
                    rowKey='paperId'
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
            </MyLayout>
        )
    }
}

export default MyCreatePaperList;