import React, {Component} from 'react'
import {Line, Area, Column} from '@ant-design/charts'
import '../../../assets/style/common.css'
import { Empty } from 'antd'

class LineChart extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        let data = this.props.data
        let chartTyp = this.props.chartTyp
        let chartName = this.props.chartName

        const config = {
            data,
            title: {
                visible: true,
                text: chartName,
            },
            xField: 'time',
            yField: 'value',
            seriesField: 'type',
            xAxis: {
                type: 'dateTime',
                tickCount: 3,
            },
            meta:{
                value:{alias:'数值'},
                time:{alias:'时间'}
            }
        }

        return (
            <>
            {
                data.length === 0?
                <Empty/>
                :
                <div>
                    {chartTyp === 'Line' ? (
                        <Line {...config} />
                    ) : chartTyp === 'Area' ? (
                        <Area {...config} />
                    ) : (
                        <Column {...config} />
                    )}
                </div>
            }
            </>
        )
    }
}
export default LineChart
