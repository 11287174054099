import React, {Component} from 'react'
import MyLayout from '../common/MyLayout'
import {Tabs, PageHeader, Divider, Button} from 'antd'
import ThirdLogin from './ThirdLogin'
import HelpLogin from './HelpLogin'
import BaseInfo from './BaseInfo'
import AccountBind from './AccountBind'
import SafeSetting from './SafeSetting'
import Certification from './Certification'
import SchoolAdvantage from './SchoolAdvantage'
import TeacherAdvantage from './TeacherAdvantage'
import StudentAdvantage from './StudentAdvantage'
// import ThirdQRLogin from './ThirdQRLogin';

const {TabPane} = Tabs

class HelpIndex extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <MyLayout>
                <PageHeader
                    title='使用帮助'
                />
                <Divider/>
                <Tabs tabPosition="left">
                    <TabPane tab="学校优势" key='schoolAdvantage'>
                        <SchoolAdvantage/>
                    </TabPane>
                    <TabPane tab="教师优势" key='teacherAdvantage'>
                        <TeacherAdvantage/>
                    </TabPane>
                    <TabPane tab="学生优势" key='studentAdvantage'>
                        <StudentAdvantage/>
                    </TabPane>
                    <TabPane tab="注册与登录" key='helpLogin'>
                        <HelpLogin/>
                    </TabPane>
                    {/* <TabPane tab="扫码登录" key='thirdQR'>
                        <ThirdQRLogin />
                    </TabPane> */}
                    <TabPane tab="基本信息" key='basicInfo'>
                        <BaseInfo/>
                    </TabPane>
                    <TabPane tab="账号绑定" key='accountBind'>
                        <AccountBind/>
                    </TabPane>
                    <TabPane tab="安全设置" key='safeSetting'>
                        <SafeSetting/>
                    </TabPane>
                    <TabPane tab="实名认证" key='certification'>
                        <Certification/>
                    </TabPane>
                    <TabPane tab="第三方网站登录" key='thirdLogin'>
                        <ThirdLogin/>
                    </TabPane>
                </Tabs>
            </MyLayout>
        )
    }
}

export default HelpIndex



