import React, { Component } from 'react';
import {Modal, Avatar, Input, List, Pagination, Tooltip, Tag} from 'antd';
import api from "../api/api";

/************* 讲座选择 ****************/
class LectureSelectModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lectureListData:[],
            name:"",
            visible: false,

            pageNum:1,
            pageSize:8,
            totalNum:9,
        }
    }

    lectureList(){
        api.lecture.lectureList(
            {
                "condition": {
                    "beforeEnd": true,
                    "endTime": "",
                    "lectureName": "",
                    "orgIds": [],
                    "startTime": "",
                    "teahcerNames": []
                },
                "pageNum": 1,
                "pageSize": 8,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        lectureListData:data.data,
                    })
                }
            }
        )
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.lectureList(), 200);
    }

    listClick(name,lid) {
        this.setState({
            name:name,
            visible: false,
        });
        this.props.onChange(lid)
    }

    componentDidMount() {
        this.lectureList();
    }

    render() {
        if (this.props.value && this.props.value.lid && this.props.value.name){
            this.props.onChange(this.props.value.lid);
            this.setState({
                name: this.props.value.name,
            })
        }
        return (
            <div>
                <Input size="large"
                       placeholder="讲座选择"
                       value={this.state.name}
                       onClick={()=>{
                           this.setState({
                               visible: true,
                           })}
                       }/>
                <Modal
                    title="讲座选择"
                    visible={this.state.visible}
                    onCancel={()=>{
                        this.setState({
                            visible:false
                        })
                    }}
                    footer={null}
                >
                    <List
                        size="large"
                        dataSource={this.state.lectureListData}
                        renderItem={item =>
                            <List.Item onClick={()=>this.listClick(item.name,item.lid)}>
                                <List.Item.Meta
                                    avatar={<Avatar src={item.posterPath} />}
                                    title={<a>{item.name}</a>}
                                    description={
                                        <p>
                                            <Tooltip title="所属组织" >
                                                <Tag color="cyan">{item.orgName}</Tag>
                                            </Tooltip>
                                        </p>
                                    }
                                />
                            </List.Item>}
                    />
                    <div style={{paddingTop:"25px"}}>
                        <Pagination
                            onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.state.pageSize}
                            current={this.state.pageNum}
                            total={this.state.totalNum}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default LectureSelectModal;