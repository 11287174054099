
import React, { Component } from 'react';
import { Progress,Button, Tag, Table, Pagination, Select, Col, Row, Form, Divider, PageHeader} from 'antd';
import MyLayout from '../../common/MyLayout';
import api from '../../../api/api';
import RoutePath from '../../../config/RoutePath';

class MyAnswerPaperList extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            data:[],
        };
    }

    fetchMyPaperRecord()
    {
        api.exam.getMyAnswerRecord(
            null,
            (state,data)=>{
                if(state && data.status === 200)
                {
                    this.setState({
                        data:data.data
                    })
                }
            }
        )
    }

    componentDidMount()
    {
        this.fetchMyPaperRecord();
    }

    render() {
        let history = this.props.history;
        const columns = [
            {
                title: '试卷名',
                dataIndex: 'title',
                key: 'title',
                className:"tableOverflow",
            }, 
            {
                title: '考试时间',
                dataIndex: 'kssj',
                key: 'kssj',
                className:"tableOverflow",
                render: (text, record) => (
                    <div>
                    <span>{record.startTime?record.startTime:'不定'}</span>
                    -
                    <span>{record.endTime?record.endTime:'不定'}</span>
                </div>
                )
            },
            /*** 
            {
                title: '答题状态',
                dataIndex: 'answerSumbit',
                key: 'answerSumbit',
                render: (text, record) => (
                    record.answerSumbit == 0?
                    <Tag color="#f50">未交卷</Tag>
                    :<Tag color="#108ee9">已交卷</Tag>
                )
            }, 
            */
            {
                title: '判卷',
                dataIndex: 'judge',
                key: 'judge',
                render: (text, record) => (
                    record.judge == 0?
                    <Tag color="#f50">未判卷</Tag>
                    :<Tag color="#108ee9">已判卷</Tag>
                )
            }, 
            {
                title: '完成百分比',
                dataIndex: 'percent',
                key: 'percent',
                render: (text, record) => (
                    <Progress percent={record.percent}/>
                )
            },
            {
                title: '得分',
                dataIndex: 'score',
                key: 'score',
                render: (text, record) => (
                    <span>
                        {
                            record.choiceScore+record.completionScore+record.shortScore
                        }分
                    </span>
                )  
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                render: (text, record) => (
                    <Button type='primary'
                        onClick={()=>history.push(
                            "/taskadmin/AnswerDetail/"+record.paperId)
                        }
                    >
                        继续答题
                    </Button>
                )
            }, 
        ];

        return (
            <MyLayout>
                <PageHeader
                    title='我的答题列表'
                />
                <Divider/>
                <Table
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                    rowKey='answerId'
                />
            </MyLayout>
        );
    }
}

export default MyAnswerPaperList;


