
import { Radio, Row, Tabs } from 'antd';
import Item from 'antd/lib/list/Item';
import moment from 'moment';
import React, { Component } from 'react';
import api from '../../../api/api';
import MyLayout from '../../common/MyLayout';
import AreaChart from './AreaChart';

class AdminStatics extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:[],

            endDate: moment().add('days', 1).format('YYYY/MM/DD 00:00:00'),
            startDate: moment().subtract('days', 6).format('YYYY/MM/DD 00:00:00'),
            nowSelectedDays: 1,
         };
    }

    getSysStaticsList() {
        api.statics.getSysStaticsList({
                "endDate": this.state.endDate,
                "startDate": this.state.startDate
            },
            (state, data) => {
                if (state && data.status == 200) {
                    this.setState({
                        data: data.data,
                    });
                }
            }
        )
    }

    onDateChange = e => {
        let startDate = moment().subtract('days', 6).format('YYYY/MM/DD 00:00:00');//7天前
        switch (e.target.value) {
            case 1:
                startDate = moment().subtract('days', 6).format('YYYY/MM/DD 00:00:00');//7天前
                break;
            case 2:
                startDate = moment().subtract('days', 13).format('YYYY/MM/DD 00:00:00');//14天前
                break;
            case 3:
                startDate = moment().subtract('days', 20).format('YYYY/MM/DD 00:00:00');//21天前
                break;
            case 4:
                startDate = moment().subtract('days', 29).format('YYYY/MM/DD 00:00:00');//一个月
                break;
            case 5:
                startDate = moment().subtract('days', 90).format('YYYY/MM/DD 00:00:00');//一个月
                break;
        }
        this.setState({
            nowSelectedDays: e.target.value,
            endDate: moment().add('days', 1).format('YYYY/MM/DD 00:00:00'),
            startDate: startDate
        });
        setTimeout(() => this.getSysStaticsList(), 200);
    };

    componentDidMount()
    {
        this.getSysStaticsList();
    }

    render() {

        let tabConfigs = [
            {
                name: '视频',
                data:[
                    {
                        valueKey:'numVideo',
                        valueName:"总视频数",
                        chartName:"总视频数",
                        tabName:"总视频数",
                    },
                    {
                        valueKey:'numDeleteVideo',
                        valueName:"删除视频数",
                        chartName:"删除视频数",
                        tabName:"删除视频数",
                    },
                    {
                        valueKey:'numVideoDuration',
                        valueName:"视频时长(分钟)",
                        chartName:"视频时长",
                        tabName:"视频时长",
                    },
                    {
                        valueKey:'numPersonVideoLearn',
                        valueName:"视频学习总人数",
                        chartName:"视频学习总人数",
                        tabName:"学习总人数",
                    },
                    {
                        valueKey:'numPersonVideoRenciLearn',
                        valueName:"视频学习总人次",
                        chartName:"视频学习总人次",
                        tabName:"视频学习总人次",
                    },
                    {
                        valueKey:'numDurationVideoLearn',
                        valueName:"学习总时长(分钟)",
                        chartName:"学习总时长",
                        tabName:"学习总时长",
                    },
                ]
            },
            {
                name: '试卷',
                data:[
                    {
                        valueKey:'numPaper',
                        valueName:"总试卷数",
                        chartName:"总试卷数",
                        tabName:"总试卷数",
                    },
                    {
                        valueKey:'numPersonPaperLearn',
                        valueName:"试卷学习人数",
                        chartName:"试卷学习人数",
                        tabName:"试卷学习人数",
                    },
                    {
                        valueKey:'numPersonPaperRenciLearn',
                        valueName:"试卷学习人次",
                        chartName:"试卷学习人次",
                        tabName:"试卷学习人次",
                    },
                    {
                        valueKey:'numQuestionsPaperLearn',
                        valueName:"答题道数",
                        chartName:"答题道数",
                        tabName:"答题道数",
                    },
                ]
            },
            {
                name: '组织',
                data:[
                    {
                        valueKey:'numOrg',
                        valueName:"组织数",
                        chartName:"组织数",
                        tabName:"组织数",
                    },
                    {
                        valueKey:'numLecture',
                        valueName:"讲座场次数",
                        chartName:"讲座场次数",
                        tabName:"讲座场次数",
                    },
                ]
            },
            {
                name: '用户',
                data:[
                    {
                        valueKey:'numConstomUser',
                        valueName:"普通用户数",
                        chartName:"普通用户数",
                        tabName:"普通用户数",
                    },
                    {
                        valueKey:'numCompanyUser',
                        valueName:"公司用户数",
                        chartName:"公司用户数",
                        tabName:"公司用户数",
                    },
                    {
                        valueKey:'numStaffUser',
                        valueName:"教职员工数",
                        chartName:"教职员工数",
                        tabName:"教职员工数",
                    },
                    {
                        valueKey:'numThirdpartUser',
                        valueName:"三方用户数",
                        chartName:"三方用户数",
                        tabName:"三方用户数",
                    },
                ]
            },
            {
                name: '访问',
                data:[
                    {
                        valueKey:'numIps',
                        valueName:"IP地址数",
                        chartName:"IP地址数",
                        tabName:"IP地址数",
                    },
                    {
                        valueKey:'numInterfaceVisits',
                        valueName:"接口访问数",
                        chartName:"接口访问数",
                        tabName:"接口访问数",
                    },
                ]
            },
            {
                name: '班级及任务',
                data:[
                    {
                        valueKey:'numClass',
                        valueName:"总班级数",
                        chartName:"总班级数",
                        tabName:"总班级数",
                    },
                    {
                        valueKey:'numClassMem',
                        valueName:"班级学生数",
                        chartName:"班级学生数",
                        tabName:"班级学生数",
                    },
                    {
                        valueKey:'numTask',
                        valueName:"总任务数",
                        chartName:"总任务数",
                        tabName:"总任务数",
                    },
                ]
            },
        ];
        return (
                <MyLayout title='系统统计'>
                    <Row>
                        <Radio.Group onChange={this.onDateChange} value={this.state.nowSelectedDays}>
                            <Radio value={1}>前7天</Radio>
                            <Radio value={2}>前14天</Radio>
                            <Radio value={3}>前21天</Radio>
                            <Radio value={4}>前1个月</Radio>
                            <Radio value={5}>前3个月</Radio>
                        </Radio.Group>
                    </Row>
                    <Tabs>
                        {
                            tabConfigs.map((item,index)=>
                            <Tabs.TabPane tab={item.name} key={index}>
                                <Tabs>
                                    {
                                        item.data.map((itemin,indexin)=>
                                        <Tabs.TabPane tab={itemin.tabName} key={indexin}>
                                            <AreaChart
                                                    data={this.state.data}
                                                    {...itemin}/>
                                            </Tabs.TabPane>
                                        )
                                    }
                                </Tabs>
                            </Tabs.TabPane>
                            )
                        }
                    </Tabs>
                </MyLayout>
        );
    }
}

export default AdminStatics;

