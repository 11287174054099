import React, { Component } from 'react';
import {Result, Button} from 'antd';
import MyLayout from "./common/MyLayout";

class PaymentSuccess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lectureData:null,
        };
    }

    render() {
        return(
            <div>
                <Result
                    status="success"
                    title="订单支付成功!"
                    extra={
                        <Button type="primary"
                                key="console"
                                onClick={()=>{
                                        window.location.href="about:blank";
                                        window.close();
                                        //window.open("about:blank","_self").close();
                                    }
                                }>
                            知道了
                        </Button>
                    }
                />
            </div>
        )
    }
}

export default PaymentSuccess;