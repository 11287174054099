
import React, { Component } from 'react';
import MyLayout from '../../../common/MyLayout';
import api from '../../../../api/api';
import { Tooltip, Card, Menu,Divider, Table, Button, Form, Modal, message, Input, Popover, Icon,Row,Col, InputNumber, Progress, List } from 'antd';
import VideoTypeSelect from '../../../../components/VideoTypeSelect';
import VideoMoalSearch from '../../../common/VideoModalSearch';
import { connect } from 'react-redux';
import RoutePath from '../../../../config/RoutePath';

const { Meta } = Card;

class SeriesDetail extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            data:null,
            visible:false,
            chapterLoading:true,
            selectedIndex: -1,
         };
    }

    fetchSeriesDetail()
    {
        let seriesId = this.props.match.params.seriesId;
        this.setState({chapterLoading: true});
        api.series.getVideoSeriesDetailById(
            {seriesId},
            (state,data)=>{
                if(state && data.status == 200)
                {
                    this.setState({
                        data: data.data,
                    })
                }
                this.setState({chapterLoading: false});

                if(data.data && data.data.chapters && data.data.chapters.length >0)
                {
                    this.setState({selectedIndex:0});
                }
            }
        )

    }

    handleSumbit=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.series.addOrEditVideoChaper(
                    values,
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.fetchSeriesDetail();
                        }
                        else
                        {
                            message.error(data.msg);
                        }
                    }
                );
                this.setState({visible:false});
            }
        });
    };

    componentDidMount()
    {
        this.fetchSeriesDetail();
    }

    handleDeleteChapter=(record)=>{
        Modal.confirm({
            title: '确认删除',
            content: '真的要删除'+record.name,
            onOk:()=>{
                api.series.deleteChapter(
                    {chapterId:record.chapterId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.fetchSeriesDetail();
                        }
                        else
                        {
                            message.error(data.msg);
                        }
                    } 
                )
            },
            okText: '确认',
            cancelText: '取消',
          });
    }

    handleDeleteVideo=(record)=>{
        Modal.confirm({
            title: '确认删除',
            content: '真的要删除'+record.videoName,
            onOk:()=>{
                api.series.removeVideoFromChapter(
                    {vsrId:record.vsrId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.fetchSeriesDetail();
                        }
                        else
                        {
                            message.error(data.msg);
                        }
                    } 
                )
            },
            okText: '确认',
            cancelText: '取消',
          });
    }


    handleMoveVideo(parm)
    {
        api.series.moveIndexInChapter(
            parm,
            (state,data)=>{
                if(state && data.status == 200) {
                    this.fetchSeriesDetail();
                }
                else
                {
                    message.error(data.msg);
                }
            } 
        )
    }

    render() {
        let data = this.state.data;
        let history = this.props.history;
        let userInfo = this.props.userInfo;
        let hasRight = false;
        if(userInfo != null)
        {
            if(data != null)
            {
                if(data.uid == userInfo.uid)
                {
                    hasRight = true;
                }
            }
        }


        let chapterColum = [
            {
                title: '序号',
                key: 'rankIndex',
                render: (text, record) => (
                    <span>
                    第{record.rankIndex}章
                    </span>
                ),
            },
            {
                title: '章节名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '视频数',
                dataIndex: 'numvideos',
                key: 'numvideos',
                render:(text,record)=>(
                    <div>{record.videos.length}</div>
                )
            }
        ];

        if(hasRight)
        {
            chapterColum.push( {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button
                            onClick={()=>{
                                let form = this.props.form;
                                form.setFieldsValue(record);
                                this.setState({visible:true});
                            }}
                        ><Icon type='edit'/>
                        </Button>
                        <Divider type='vertical'/>
                        <VideoMoalSearch
                            title='添加视频'
                            excludes={record.videos}
                            onClick={
                                (videoId)=>{
                                    let videos = record.videos;
                                    let max = 0;
                                    for(let i = 0; i < videos.length; i++)
                                    {
                                        if(videoId == videos[i].videoId)
                                        {
                                            message.error("你已经添加了本视频");
                                            return false;
                                        }

                                        if(videos[i].rankIndex > max)
                                        {
                                            max = videos[i].rankIndex;
                                        }

                                    }
                                    api.series.addVideoToChapter(
                                        {
                                            videoId,
                                            chapterId: record.chapterId,
                                            rankIndex:max+1,
                                        },
                                        (state,data)=>{
                                            if(state && data.status == 200)
                                            {
                                                this.fetchSeriesDetail();
                                                message.info('添加成功');
                                            }
                                            else
                                            {
                                                message.error(data.msg);
                                            }
                                        }
                                    )
                                    return false;
                                }
                            }
                        />
                        <Divider type='vertical'/>
                        <Button
                        type='danger'
                        onClick={
                            ()=>{
                                this.handleDeleteChapter(record);
                            }
                        }
                        >
                        删除
                        </Button>

                    </span>
                ),
            });
        }

        let videoColum = [
            {
                title: '序号',
                key: 'rankIndex',
                render: (text, record,index) => (
                    <span>
                    第{index+1}节
                    </span>
                ),
            },
            {
                title: '节名',
                key: 'videoName',
                render: (text, record,index) => (
                    <span>{record.videoName}</span>
                )
            },
        ];

        if(userInfo != null && !hasRight)
        {
            videoColum.push(
                {
                    title: '完成百分比',
                    key: 'percent',
                    render: (text, record,index) => (
                        <Progress percent={record.percent}/>
                    )
                }
            )
        }

        videoColum.push({

            title: '--',
            key: 'actionPlay',
            render: (text, record,index) => (
                <Button
                 type='primary'
                 onClick={
                     ()=>{
                        history.push(RoutePath.VideoPlay+'/'+record.videoId);
                     }
                 }
                >播放</Button>
            )
        })

        if(hasRight)
        {
            videoColum.push( {
                title: '操作',
                key: 'action',
                render: (text, record,index) => (
                    <span>
                        <Button
                            onClick ={ ()=>{
                                this.handleMoveVideo(
                                    {
                                        vsrId:record.vsrId,
                                        chapterId: record.chapterId,
                                        moveStep:1
                                    }
                                )
                            }
                            }
                        >
                        <Icon type='down'/>
                        </Button>
                        <Divider type='vertical'/>
                        <Button
                                onClick ={ ()=>{
                                this.handleMoveVideo(
                                    {
                                        vsrId:record.vsrId,
                                        chapterId: record.chapterId,
                                        moveStep:-1
                                    }
                                )
                            }
                            }
                        >
                        <Icon type='up'/>
                        </Button>
                        <Divider type='vertical'/>
                        <Button
                        type='danger'
                        onClick={
                            ()=>{
                                this.handleDeleteVideo(record);
                            }
                        }
                        >
                        删除
                        </Button>
                    </span>
                ),
            })
        }


        const { getFieldDecorator } = this.props.form;
        getFieldDecorator('seriesId',{});
        getFieldDecorator('chapterId',{});

        let chapters = [];
        if(data)
        {
            chapters = data.chapters;
        }

        let videoData = [];
        if(this.state.selectedIndex >= 0)
        {
            videoData = data.chapters[this.state.selectedIndex].videos;
        }

        if(!data)
        {
            data = {};
        }

        return (
            <MyLayout>
                <div className="userEditTitle">{data?data.title:''}</div>
                <Divider/>
                { 
                    hasRight?
                    <>
                    <Row>
                        <Col span={6}>
                        <img src={data.coverPath} style={{height:"170px"}}/>
                        </Col>
                        <Col span={1}>
                        </Col>
                        <Col span={8}>
                        <div>{}
                        {data.description?data.description.split('\n').map(
                            (item,index)=>
                            (
                                <p>{item}</p>
                            )
                        ):null}
                        </div>
                        </Col>
                        <Col span={1}>
                        </Col>
                        <Col span={6}>
                        <span>
                        所属类别:&nbsp;&nbsp;
                        <VideoTypeSelect
                        value={data.typeId}
                        disabled={true}
                        bordered={false}
                        display = {true}
                        />
                        </span>
                        <div>&nbsp;</div>
                        <div>发布日期:&nbsp;&nbsp;{data.createTime}</div>
                        </Col>
                    </Row>
                    <Divider/>
                    <Button 
                        type='primary'
                        onClick={
                                ()=>{
                                    let form = this.props.form;
                                    form.setFieldsValue({
                                        seriesId:data.seriesId,
                                        chapterId:null,
                                        name:null,
                                        rankIndex:null,
                                        });
                                    this.setState({
                                    visible:true
                                    });
                                }
                            }
                        >增加一章</Button>
                    <Table
                        dataSource={chapters}
                        columns={chapterColum}
                        defaultExpandAllRows={false}
                        pagination={false}
                        loading={this.state.chapterLoading}
                        expandedRowRender= {(record)=>{
                        return <Table
                                pagination={false}
                                dataSource={record.videos} 
                                showHeader={false}
                                columns={videoColum}
                                />}
                        }
                    />
                  </>:null
                }

                {hasRight?null:
                <Row>
                    <Col span={4}>
                        <Menu
                            mode='vertical-left'
                            theme='light'
                            defaultSelectedKeys={['0']}
                            style={{height:'100%'}}
                        >
                            {
                                chapters.map(
                                    (record,index)=>{
                                    return <Menu.Item key={index}
                                     onClick={()=>this.setState({selectedIndex:index})}
                                    >
                                    <Tooltip
                                        title={<span>{record.name}</span>}
                                    >
                                            <span>第{record.rankIndex}章&nbsp;{record.name}</span>
                                    </Tooltip>
                                    </Menu.Item>
                                    }
                                )
                            }
                        </Menu>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={19}>
                        <List
                            grid={{ gutter: 20, column: 3 }}
                            itemLayout="vertical"
                            dataSource={videoData}
                            renderItem={(item,index) => (
                                <List.Item key={index}>
                                    <Card hoverable
                                        cover={<img alt="example" style={{height:"170px"}} src={item.videoPosterPath} />}
                                        onClick={()=>
                                            history.push(RoutePath.VideoPlay+'/'+item.videoId)
                                        }
                                    >
                                        <Meta title={<span>第{item.rankIndex}节&nbsp;{item.videoName}</span>} style={{height:"50px"}} description={
                                                this.props.userInfo?
                                                <div className="multipleTextOverflow">
                                                    <Progress percent={item.percent} />
                                            </div>:null
                                        } />
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>}

                <Modal                
                    title='添加编辑章节'
                    visible={this.state.visible}
                    onCancel={()=>{
                                this.setState({
                                    visible:false
                                })
                            }}
                    okText="确认"
                    cancelText="取消"
                    footer={null}
                >
                <Form onSubmit={this.handleSumbit}>
                    <Form.Item label='章名'>
                    {
                            getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '章名是必须输入的!'
                                    },
                                ],
                            })(
                            <Input size="large"
                                 placeholder="章名"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label='序号'>
                    {
                        getFieldDecorator('rankIndex', {
                                rules: [
                                    {
                                        required: true,
                                        message: '序号是必须输入的!'
                                    },
                                ],
                            })(
                            <InputNumber
                                 size="large"
                                 placeholder="序号"
                                 style={{width:'100%'}}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit" size="large" block>
                            确定
                        </Button>
                    </Form.Item>
                </Form>

                </Modal>
            </MyLayout>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}

export default connect(mapStateToProps,null)(Form.create()(SeriesDetail));
