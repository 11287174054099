import React, {Component} from 'react'
import {Typography} from 'antd'

const {Title, Paragraph} = Typography

class SchoolAdvantage extends Component {
    render() {
        return (
            <Typography>
                <Title level={2}>学校/企业优势</Title>
                <Paragraph>
                    有效的企业培训,其实是提升企业综合竞争力的过程。事实上,培训的效果并不取决于受训者个人,而恰恰相反,企业组织本身作为一个有机体的状态,起着非常关键的作用。企业培训对于企业的好处是很多的，主要有以下几点：
                </Paragraph>
                    一、快出人才，多出人才，出好人才。国内教育资源的限制和教育体制的缺陷，令企业只有依靠培训来获得优秀员工。
                <Paragraph>
                </Paragraph>
                <Paragraph>
                    二、减少员工的流动率和流失率，当员工无法有效的完成自己的工作时，就会形成工作压力，并在各方面表现出来。成功的培训能通过员工技能的提高，有效减少工作压力和增加工作乐趣，减少人员流动和流失，也有助于降低劳动力和管理成本。
                </Paragraph>
                <Paragraph>
                    三、最大程度的降低成本。通过培训，改进员工工作表现，既可获得因人员素质的提高带来的实际效益，更可带来因员工主动性，积极性和创造性的激发所带来的巨大效益。
                </Paragraph>
                <Paragraph>
                    四、塑造更完美的企业文化。通过培训，员工会感激管理层为他们提供了使自己成长，发展和在工作中取得更大成就的机会，自然有效的增强企业对他们的向心力和凝聚力。
                </Paragraph>
                <Paragraph>
                    五、强化员工敬业精神。拥有正确心态和娴熟技巧的员工，自然的也具备了良好的敬业精神。而培训的一个主要的目的和效果也正是对心态的改进和技巧的强化。
                </Paragraph>
                <Paragraph>
                    六、更有利的胜过竞争对手，只要与对手相比时存在一些特定的优势，就能在激烈的市场竞争中赢得一席之地。而人员优势正是很多企业欠缺的。对一般企业而言，只要做出不多努力，就很容易实现自己的人员优势，胜过竞争对手，而培训正是获得人员优势的最好手段。 </Paragraph>
                <Paragraph>
                    七、赢得更好的企业形象和经济效益。所有上述的综合，就会产生更好的企业形象，带来全面的经济效益，从另一方面强化了培训的总体效果。
                </Paragraph>
            </Typography>
        )
    }
}

export default SchoolAdvantage