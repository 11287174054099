import React, {Component} from 'react'
import {Typography} from 'antd'

const {Title, Paragraph} = Typography

class TeacherAdvantage extends Component {
    render() {
        return (
            <Typography>
                <Title level={2}>教师优势</Title>
                <Paragraph>
                    网络教育培训现在已经是各个高校普遍使用的另一种教学方式，这种方式既方便又灵活快捷。学生可观看下载讲课录像、电子教案、课件等授课内容，弥补课堂中的不足，起到了加深理解、解惑问题、复习提高的作用；同时学生也可以在网络教学平台上与老师交流，通过交流，学生可以获得疑问的解答，得到学习的动力，教师也可以了解学生的当前学习状况，激励学生的学习，在网络教学活动中建立起一个有效的交流环境，能够使得分布在不同地方的师生方便的交流，从而能够有效的提高教学活动的质量。而且目前还有了校际间的网络平台，这样能够整合教师与教师，学校与学校之间的资源。。
                </Paragraph>
                <Paragraph>
                    网络教育培训促进了教师教学模式的改革。从教师角度来说，没有了维护课堂纪律的条件，为教师提供了更多思考，如何帮助学生提高学习有效性和针对性的空间。可以不受时间和空间的限制，随时可以学习，提升了学习效率。网络教育培训可以使得教育资源不平等问题得到解决，教育资源共享使得所有学生都能享受到一样的服务。
                </Paragraph>
            </Typography>
        )
    }
}

export default TeacherAdvantage