import React, { Component } from 'react';
import { Menu, Icon, Layout} from 'antd';
import {connect } from 'react-redux';
import {withRouter } from 'react-router';
import Action from '../../../actions/Action';
import Sfs from '../../../config/Sfs';

const { SubMenu } = Menu;
let {Sider} = Layout;

class VideoLectureOrderSider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //selected:""
        };
    }

    render() {
        let userInfo = this.props.userInfo;
        let history = this.props.history;
        let pathname = this.props.location.pathname;
        return (
            <div>
                <Sider style={{
                    height: '50vh',
                    left: 0,
                    background: '#f0f2f5',
                    paddingTop:2,
                    marginRight: 10
                }}>
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[pathname]}
                        style={{ height: '100%', borderRight: 0 }}
                    >
                    {
                        Sfs.isLectureOn?
                        <Menu.Item  key='/Personal/MyBizOrderLis'
                                    onClick={()=>history.push('/Personal/MyBizOrderLis')}>
                            <Icon type="bank" />
                            <span className="nav-text1" >我的讲座订单</span>
                        </Menu.Item>
                        :null
                    }

                        <Menu.Item  key='/Personal/MyVideoOrderList'
                                    onClick={()=>history.push('/Personal/MyVideoOrderList')}>
                            <Icon type="video-camera" />
                            <span className="nav-text2" >我的视频订单</span>
                        </Menu.Item>
                    </Menu>
                </Sider>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
        remain: state.user.remain,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetUser: (data) => dispatch(
            {
                type: Action.RESET_USER_INFO,
            }),
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(VideoLectureOrderSider));