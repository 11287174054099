import { Select, Table, Divider, Button, Form, Col, Row,message, Modal, Input,Pagination, Popover, Avatar } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import api from '../../api/api';
import ImageUpload from '../../components/ImageUpload';
import Dict, { dict } from '../../config/Dict';
import MyLayout from '../common/MyLayout';


const {Search} = Input;

class EditCertList extends Component {

    constructor(props) {
        super(props);
        this.state = {

            //搜索相关
            keyword: null,
            status:null,

            //数据
            data:[],

            //编辑相关
            visible:false,
            isAdd:false,
         };

         this.initPageSize = 10;
         this.pageNum = 1;
         this.pageSize = this.initPageSize;
         this.totalNum = 10000;
    }

    featchCertList() {
        api.cert.pageSearchCert(
            {
                "condition": {
                    keyword: this.state.keyword,
                    status:this.state.status,
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data) => {
                if (state && data.status == 200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        data: data.data,
                    })
                }
            }
        )
    }

    pageOnChange(pageNum, pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(() => this.featchCertList(), 200);
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        setTimeout(() => this.featchCertList(), 200,);
    }

    componentDidMount(){
        this.featchCertList();
    }

    handleSumbit=(e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {

                let cbk = (state,data)=>{
                    if(state && data.status == 200) {
                        this.research();
                    }
                    else
                    {
                        message.error(data.msg);
                    }
                }

                if(this.state.isAdd)
                {
                    api.cert.addCert(
                        values,
                        cbk
                    );
                }
                else
                {
                    api.cert.editCert(
                        values,
                        cbk
                    );
                }
                this.setState({visible:false});
            }
        });
    };

    render() {

        let role = 1;
        if(this.props.userInfo)
        {
            role = this.props.userInfo.role;
        }

        const columns = [
            {
                title: '证书',
                dataIndex: 'filepath',
                key: 'filepath',
                render: (text, record) => (
                        <Popover
                        title="图片放大"
                        content={<img src={text} alt=''/>}
                        >
                                    <Avatar src={text}/>
                        </Popover>
                ),
            },
            {
                title: "证书号码",
                dataIndex: "certNo",
                key: "certNo",
            },
            {
                title: "证书名",
                dataIndex: "title",
                key: "title",
            },
            {
                title: "状态",
                dataIndex: "status",
                key: "status",
                render: (text, record) => (
                    Dict.getValue('certStatus',text,'')
                )
            },
        ];
        if(role === 1)
        {
            columns.push( {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button 
                            type='primary'
                            onClick={()=>{
                                let form = this.props.form;
                                form.setFieldsValue(record);
                                this.setState({visible:true,isAdd:false});
                            }}
                        >编辑</Button>
                        <Divider type='vertical'/>
                        <Button 
                            type='primary'
                            disabled={record.status === 1}
                            onClick={()=>{
                                Modal.confirm({
                                    title: '提交审核?',
                                    content: '确定要讲该证书'+record.certNo+'提交审核',
                                    onOk:()=>{
                                        api.cert.editCert(
                                        {...record,status:1},
                                        (state, data)=>{
                                            if(state && data.status===200) {
                                               this.featchCertList();
                                            }
                                        }
                                    )
                                },
                                onCancel() {
                                },
                                })
                            }}
                        >提交审核</Button>
                        <Divider type='vertical'/>
                        <Button 
                            type='danger'
                            onClick={()=>{
                                Modal.confirm({
                                    title: '你确定要删除这个证书吗?',
                                    content: '你删除的证书'+record.certNo+'别人将再也查不到',
                                    onOk:()=>{
                                        api.cert.deleteCert(
                                        {certNo:record.certNo},
                                        (state, data)=>{
                                            if(state && data.status===200) {
                                               this.featchCertList();
                                            }
                                        }
                                    )
                                },
                                onCancel() {
                                },
                                })
                            }}
                        >删除</Button>
                    </span>
                ),
            })
        }
        else
        {
            columns.push({
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button 
                            type='primary'
                            onClick={()=>{
                                        api.cert.editCert(
                                        {...record,status:2},
                                        (state, data)=>{
                                            if(state && data.status===200) {
                                               this.featchCertList();
                                            }
                                        }
                                    )
                                }
                            }
                        >审核通过</Button>
                        <Divider type='vertical'/>
                        <Button 
                            type='danger'
                            onClick={()=>{
                                        api.cert.editCert(
                                        {...record,status:3},
                                        (state, data)=>{
                                            if(state && data.status===200) {
                                               this.featchCertList();
                                            }
                                        }
                                    )
                                }
                            }
                        >审核拒绝</Button>
                    </span>
                ),
            })
        }
        const { getFieldDecorator } = this.props.form;
        getFieldDecorator('certNo',{});
        getFieldDecorator('title',{});
        getFieldDecorator('status',{});
        getFieldDecorator('filepath',{});

        return (
            <MyLayout>
                <Row gutter={5}>
                    <Col span={6}>
                        {
                        role === 1?
                        <Button
                        type="primary"
                        style={{ marginBottom: 16 }}
                        onClick={()=>{
                            this.setState({
                                visible:true,
                                isAdd:true,
                            })
                        }}
                        >
                        添加证书
                        </Button>:null}
                    </Col>
                    <Col span={18}>
                        <Form layout='inline'>
                            <Form.Item label="状态">
                                    <Select value={this.state.status} onChange={
                                        (status)=>{
                                            this.setState({status});
                                            this.research();
                                        }
                                    }>
                                        <Select.Option value={null}>不限制</Select.Option>
                                        <Select.Option value={0}>编辑中</Select.Option>
                                        <Select.Option value={1}>待审核</Select.Option>
                                        <Select.Option value={2}>已通过</Select.Option>
                                        <Select.Option value={3}>已驳回</Select.Option>
                                    </Select>
                            </Form.Item>
                            <Form.Item>
                                <Search
                                    placeholder="请输入关键字"
                                    enterButton="搜索"
                                    onSearch={
                                        (keyword) => {
                                            this.setState({keyword});
                                            this.research();
                                        }
                                    }
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Table
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
            <Divider/>
            <Pagination
                onChange={(pageNum, pageSize) =>
                this.pageOnChange(pageNum, pageSize)
                }
                pageSize={this.pageSize}
                current={this.pageNum}
                total={this.totalNum}
            />

        <Modal
                title='添加/编辑证书'
                visible={this.state.visible}
                onCancel={()=>{
                            this.setState({
                                visible:false
                            })
                        }}
                okText="确认"
                cancelText="取消"
                footer={null}
                >
                <Form onSubmit={this.handleSumbit}>
                    <Form.Item label='证书编号'>
                    {
                            getFieldDecorator('certNo', {
                                rules: [
                                    {
                                        required: true,
                                        message: '证书编号必须输入!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(this.state.isAdd)
                                            {
                                                api.cert.isCertExsit({
                                                    certNo: value,
                                                },
                                                    (state, data) => {
                                                        if (state && data.status === 200) {//电话和邮箱不可注册
                                                            if(data.data === "OK")
                                                            {
                                                                callback('error');
                                                            }
                                                            else
                                                            {
                                                                callback();
                                                            }
                                                        }
                                                        else {//可注册
                                                            callback();
                                                        }
                                                });
                                            }
                                            else
                                            {
                                                callback();
                                            }
                                        },
                                        message: '证书编号已被占用',
                                    },
                                ],
                            })(
                            <Input 
                                 size="large"
                                 disabled={!this.state.isAdd}
                                 placeholder="证书编号"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label='证书名'>
                    {
                            getFieldDecorator('title', {
                                rules: [
                                    {
                                        required: true,
                                        message: '证书名必须输入!'
                                    },
                                ],
                            })(
                            <Input 
                                 size="large"
                                 placeholder="证书名"
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label='证书'>
                    {
                               getFieldDecorator('filepath', {
                                        rules: [
                                            {
                                                required: true,
                                                message: '必须输入!'
                                            },
                                        ],
                                    })(
                                    <ImageUpload/>,
                                )
                    }
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit" size="large" block>
                            确定
                        </Button>
                    </Form.Item>
                </Form>
                </Modal>
            </MyLayout>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    }
}

export default  connect(mapStateToProps,null)(Form.create()(EditCertList));
