import React, { Component } from 'react';
import {Modal, Table, Input, Pagination, Tooltip, Tag, Row, Col, Button, Divider} from 'antd';
import api from '../../api/api';
import ComFunction from '../../api/ComFunction';

class TaskModalSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
            keyword: "",
            visible: false,
            taskType: 0,

            pageNum:1,
            pageSize:5,
            totalNum:9,
         }
    }

    getTaskList(){ //视频列表
        api.statics.pageSearchMtask(
            {
                "condition": {
                    keyword:this.state.keyword,
                    taskType: this.props.taskType?this.props.taskType:this.state.taskType,
                    visible:this.props.visible
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.getTaskList(), 200);
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:5,
            totalNum:9,
        });
        setTimeout(()=>this.getTaskList(), 200);
    }

    render() {

        const columns = [
            {
                title: '任务名',
                dataIndex: 'taskName',
                key: 'taskName',
                className:"tableOverflow",
                render: (text, record) => (
                    <Tooltip
                    title={text}
                    >{text}</Tooltip>
                ),
            },
            {
                title: '任务类型',
                dataIndex: 'taskType',
                key: 'taskType',
                render: (text, record) => (
                    record.taskType === 0?
                    <Tag color="orange">视频</Tag>
                    :<Tag color="cyan">试题</Tag>
                ),
            },
            {
                title: '可见性',
                dataIndex: 'visible',
                key: 'visible',
                render: (text, record) => (
                    record.visible === 0?
                    <Tag color="blue">可见</Tag>
                    :<Tag color="red">不可见</Tag>
                ),
            },
            {
                title: '开始时间',
                dataIndex: 'startTime',
                key: 'startTime',
                render: (text, record) => (ComFunction.getShortTime(text))
            },
            {
                title: '操作',
                dataIndex: 'opr',
                key: 'opr',
                render: (text, record) => (
                    <Button
                     type='primary'
                     onClick={()=>{
                         if(this.props.onSelectItem)
                         {
                             this.props.onSelectItem(record);
                         }
                         this.setState({visible:false});
                     }}
                    >选择</Button>
                ),
            }
        ];

        return(
        <>
            <div
            onClick={()=>{
                this.getTaskList();
                this.setState({
                    visible: true,
                })
            }}
            >
                {this.props.title}
            </div>

            <Modal
                    title="任务选择"
                    width={800}
                    centered={true}
                    visible={this.state.visible}
                    onCancel={()=>{
                        this.setState({
                            visible:false
                        })
                    }}
                    footer={null}
                >
                    <Row gutter={15}>
                        <Col span={7} >
                            <span>
                                <Input defaultValue={this.state.keyword}
                                       placeholder='关键字'
                                       onChange={(e)=>this.setState({keyword:[e.target.value]})}
                                />
                            </span>
                        </Col>
                        <Col span={3} >
                            <div>
                                <Button type='primary'
                                        onClick={()=>this.research()}>
                                    搜索</Button>
                            </div>
                        </Col>
                    </Row>
                    <Divider dashed/>
                    <Table
                    rowKey='mtaskId'
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                    />
                    <Divider dashed/>
                    <Pagination
                        onChange={(pageNum,pageSize)=>this.pageOnChange(
                            pageNum,pageSize
                        )}
                        pageSize={this.state.pageSize}
                        current={this.state.pageNum}
                        total={this.state.totalNum}
                    />
                </Modal>
        </>);
    }
}
 
export default TaskModalSearch;
