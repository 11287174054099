import React, { Component } from 'react';
import { Button, Tag,Tooltip, Table, Divider, PageHeader, Row, Col,Input, Pagination} from 'antd';
import api from '../../api/api';
import MyLayout from "../common/MyLayout";
import AddTopicModal from '../qa/AddTopicModal';
import RoutePath from "../../config/RoutePath";
const { Search } = Input;

class GetFollowmeTopicList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            keyword:null,
            topData:[],
        };

        this.initPageSize = 8;

        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        this.totalNum = 10000;
    }

    getFollowmeTopicList() {
        api.qa.pageGetFollowmeTopicList(
            {
                "condition": {
                    "keyword":this.state.keyword
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        topData:data.data,
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.getFollowmeTopicList();
    }

    pageOnChange(pageNum,pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(()=>this.getFollowmeTopicList(), 200);
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        setTimeout(()=>this.getFollowmeTopicList(), 200,);
    }

    render() {
        let history = this.props.history;
        const columns = [
            {
                title: '问题',
                dataIndex: 'content',
                key: 'content',
                className:"tableOverflow",
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 120,
                        }
                    }
                },
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => (
                    record.status === 0?
                    <Tag color='#f50'>待我回答</Tag>
                    :<Tag color='#87d068'>待学生回答</Tag>
                ),
            },
            {
                title: '发起者',
                dataIndex: 'studentName',
                key: 'studentName',
            },
            {
                title: '问题最后更新时间',
                dataIndex: 'createdTime',
                key: 'createdTime',
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                render: (text, record) => (
                    <Button 
                    type='primary'
                    onClick={()=>history.push(RoutePath.GetQaDetail+'/'+record.topicId)}>
                    我来回答</Button>
                ),
            },
        ];
        return(
            <MyLayout>
                <Row>
                    <Col span={4}></Col>
                    <Col span={8}>
                        <Search
                            placeholder="请输入关键字"
                            enterButton="搜索"
                            size="large"
                            onSearch={
                                (keyword)=>{
                                    this.setState({keyword});
                                    this.research();
                                }
                            }
                        />
                    </Col>
                    <Col span={8}></Col>
                </Row>
                <Divider/>
                <Divider />
                <Table
                    rowKey="topicId"
                    pagination={false}
                    dataSource={this.state.topData}
                    columns={columns}
                />
                <Divider/>
                <Pagination
                        onChange={(pageNum,pageSize)=>this.pageOnChange(
                            pageNum,pageSize
                        )}
                        pageSize={this.pageSize}
                        current={this.pageNum}
                        total={this.totalNum}
                />
            </MyLayout>
        )
    }
}
export default GetFollowmeTopicList;