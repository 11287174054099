import React, { Component } from 'react';
import { Button, Popover,Col, Row, message, Modal, Table, Pagination, Divider, Input, Switch, Radio, Select, Form} from 'antd';
import api from '../../api/api';
import MyLayout from "../common/MyLayout";
import Dict from "../../config/Dict";
const { confirm } = Modal;

class UserManage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            catagory:0,
            email: "",
            phone: "",
            realNameAuth: 0,
            plateformAuth: 0,

            data:[],
            pageNum:1,
            pageSize:8,
            totalNum:9,
        };
    }

    passorreject(realNameAuth,uid)
    {
        api.admin.swichAvailability({
            realNameAuth, uid
        },
        (state,data)=>{
            if(state && data.status === 200) {
                this.research();
                message.success('设置成功!');
            }
        }
        )  
    }

    passorrejectPlateform(plateformAuth,uid)
    {
        api.admin.swichAvailability({
            plateformAuth, uid
        },
        (state,data)=>{
            if(state && data.status === 200) {
                this.research();
                message.success('设置成功!');
            }
        }
        )  
    }

    swichAvailability(status,uid){
        let title = "";
        let content = "";
        if (status === 0){
            title = "你确定要把这个用户设置成[无效]吗?";
            content = "用户设置成[无效]后该用户不可登录。"
        }else{
            title = "你确定要把这个用户设置成[有效]吗?";
            content = "用户设置成[有效]后该用户方可登录！"
        }
        confirm({
            title: title,
            content:content,
            onOk:()=>{
                api.admin.swichAvailability({
                        "status": status,
                        "uid": uid
                    },
                    (state,data)=>{
                        if(state && data.status === 200) {
                            this.research();
                            message.success('设置成功!');
                        }
                    }
                )
            },
            onCancel() {
            },
        });
    }

    resetPwd(uid){
        confirm({
            title: "你确定要重置这个用户的密码吗?",
            content:"密码重置成功后，将会发送你一个随机密码",
            onOk:()=>{
                api.admin.resetPwd({uid},
                    (state,data)=>{
                        if(state && data.status === 200) {
                            this.research();
                            this.resetSuccess(data.data);
                        }
                    }
                )
            },

        });
    }

    resetSuccess(pwd){
        Modal.info({
            title: '随机密码',
            content: (
                <div>
                    <p>您的随机密码是“{pwd}”请妥善保存并及时修改！</p>
                </div>
            ),
            onOk() {},
        });
    };


    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.getUsersInCategory(), 200);
    }

    getUsersInCategory() {
        api.admin.getUsersInCategory({
                "condition": {
                    "catagory": this.state.catagory,
                    "email": this.state.email,
                    "phone": this.state.phone,
                    realNameAuth: this.state.realNameAuth,
                    plateformAuth:this.state.plateformAuth,
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize
            },
            (state, data)=>{
                if(state && data.status===200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:4,
            totalNum:9,
        });
        setTimeout(()=>this.getUsersInCategory(), 200,)
    }

    componentDidMount() {
        this.getUsersInCategory();
    }

    render() {
        const columns = [
            {
                title: 'uid',
                dataIndex: 'uid',
                key: 'uid',
                render: (text, record) => (
                    <Popover content={record.uid}>
                        {record.uid.substring(0,10)}
                    </Popover>
                ),
            },
            {
                title: '角色',
                dataIndex: 'role',
                key: 'role',
                render: (text, record) => (
                    <span>
                        {Dict.getValue('role',text,'')}
                    </span>
                ),
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '实名认证',
                dataIndex: 'realNameAuth',
                key: 'realNameAuth',
                render: (text, record) => (
                    <Popover content={
                    <>
                    <div>{record.idNumber}</div>
                    <img alt="" width={300} src={record.idcardFilepath}/>
                    <Button type='primary'
                    onClick={()=>{
                        this.passorreject(2,record.uid);
                    }}
                    >通过认证</Button>
                    <Divider type='vertical'/>
                    <Button type='danger'
                                        onClick={()=>{
                        this.passorreject(0,record.uid);
                    }}
                    >驳回</Button>
                    </>
                    }>
                        {Dict.getValue('realNameAuth',text,'')}
                    </Popover>
                ),
            },
            {
                title: '三方认证',
                dataIndex: 'plateformAuth',
                key: 'plateformAuth',
                render: (text, record) => (
                    <Popover content={
                    <>
                    <img alt="" width={300} src={record.plateformFilepath}/>
                    <Button type='primary'
                    onClick={()=>{
                        this.passorrejectPlateform(2,record.uid);
                    }}
                    >通过认证</Button>
                    <Divider type='vertical'/>
                    <Button type='danger'
                                        onClick={()=>{
                        this.passorrejectPlateform(0,record.uid);
                    }}
                    >驳回</Button>
                    </>
                    }>
                        {Dict.getValue('plateformAuth',text,'')}
                    </Popover>
                ),
            },
            {
                title: '电话',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: 'email',
                key: 'email',
                dataIndex: 'email',
            },
            {
                title: '用户状态',
                key: 'status',
                dataIndex: 'status',
                render: (text, record) => (
                    <span>
                        {
                            <Switch checked={text===0?false:true}
                                 checkedChildren="启用"
                                 unCheckedChildren="禁用"
                                    onChange={()=>this.swichAvailability(text===0?1:0,record.uid)}
                        />}
                    </span>
                ),
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button onClick={()=>this.resetPwd(record.uid)}>重置密码</Button>
                    </span>
                ),
            },
        ];
        return(
            <MyLayout >
                <Form layout='inline'>
                    <Form.Item label="用户类型">
                            <Select
                                value={this.state.catagory}
                                onChange={(value)=>{
                                        this.setState({
                                        catagory:value});
                                        this.research();
                                }}
                            >
                                <Select.Option value={0}>个人用户</Select.Option>
                                <Select.Option value={1}>企业用户</Select.Option>
                                <Select.Option value={2}>老师用户</Select.Option>
                                <Select.Option value={3}>三方用户</Select.Option>
                                <Select.Option value={5}>admin</Select.Option>
                            </Select>
                    </Form.Item>
                    <Form.Item label='实名认证'>
                        <Select
                        value={this.state.realNameAuth}
                        onChange={(value)=>{
                                this.setState({
                                realNameAuth:value});
                                this.research();
                        }}
                    >
                        <Select.Option value={0}>实名未审核</Select.Option>
                        <Select.Option value={1}>实名待审核</Select.Option>
                        <Select.Option value={2}>实名审核通过</Select.Option>
                    </Select>
                        </Form.Item>
                    <Form.Item label='三方认证'>
                        <Select
                        value={this.state.plateformAuth}
                        onChange={(value)=>{
                                this.setState({
                                plateformAuth:value});
                                this.research();
                        }}
                    >
                        <Select.Option value={0}>三方未审核</Select.Option>
                        <Select.Option value={1}>三方待审核</Select.Option>
                        <Select.Option value={2}>三方审核通过</Select.Option>
                    </Select>
                        </Form.Item>
                        <Form.Item>
                            <Input defaultValue={this.state.email}
                                placeholder='email检索'
                                onChange={(e)=>{
                                    this.setState({email:e.target.value});
                                    this.research();
                                }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Input defaultValue={this.state.phone}
                                placeholder='电话检索'
                                onChange={(e)=>{
                                    this.setState({phone:e.target.value});
                                    this.research();
                                }}
                            />
                        </Form.Item>
                </Form>
                <Divider/>
                <Table bordered
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
                <div style={{paddingTop:"25px"}}>
                    <Pagination
                        onChange={(pageNum,pageSize)=>this.pageOnChange(
                            pageNum,pageSize
                        )}
                        pageSize={this.state.pageSize}
                        current={this.state.pageNum}
                        total={this.state.totalNum}
                    />
                </div>
            </MyLayout>
        )
    }
}

export default UserManage;