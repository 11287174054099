import React, { Component } from 'react';
import { Card, Tabs, Row, Col, Rate, Button, message, Modal, List,Tooltip} from 'antd';
import api from '../../../api/api';
import Player from 'griffith'
import { connect } from 'react-redux';

import MyLayout from "../../common/MyLayout";
import Dict from "../../../config/Dict";
import AddOrEditLecture from "../../company/lectureManage/lecture/AddOrEditLecture";
import CON from '../../../config/const';
import RoutePath from '../../../config/RoutePath';
import { PlayCircleOutlined } from '@ant-design/icons';
import ComFunction from '../../../api/ComFunction';

const { TabPane } = Tabs;
const {Meta} = Card;

class VideoPlay extends Component {

    constructor(props) {
        super(props);
        this.state = {
            videoData: null,
            teacherScore: 0,
            courseScore: 0,
            rdata:[],
        };
        this.lastRunPro = -1;
    }

   featchRelatives(videoId)
   {
        if(!videoId)
        {
            videoId = this.props.match.params.videoId;
        }
        api.video.getRelativeVideoList({ videoId },
            (state, data) => {
                if (state && data.status == 200) {
                    this.setState({
                        rdata: data.data,
                    });
                }
            }
        )
   }

    updateVideoDetail(videoId) {
        if(!videoId)
        {
            videoId = this.props.match.params.videoId;
        }
        api.video.videoDetail({ videoId },
            (state, data) => {
                if (state && data.status == 200) {

                    if (data.data.filePath == null) {
                        Modal.error({
                            title: '权限错误',
                            content: "您没有播放此视频的权限",
                            onOk: () => this.props.history.goBack()
                        });
                    }

                    this.setState({
                        videoData: data.data,
                    });
                }
            }
        )
    }

    SubmitComment() {
        let { videoId } = this.props.match.params;
        let score = this.state.teacherScore * 10 + this.state.courseScore * 10;
        api.video.comment({
            "comment": "",
            "score": score,
            "vid": videoId
        },
            (state, data) => {

                if (state && data.status == 200) {
                    //评价成功提示
                    message.success('您的评价提交成功！');
                }
            }
        )
    }

    onLoadedmetadata = (e) => {
        let data = this.state.videoData;
        let currentTime = 0;
        if (data.percent == null || data.percent == 100) {
            currentTime = 0;
        } else if (data.percent != null && data.percent < 100) {
            currentTime = data.percent / 100 * e.target.duration;
        }
        e.target.currentTime = currentTime;
    }

    onTimeupdate = (e) => {
        let { videoId } = this.props.match.params;
        let ele = e.target;
        let currentPro = parseInt(ele.currentTime / ele.duration * 100);
        if(currentPro >= 99)
        {
            let history = this.props.history;
            if(this.state.rdata && this.state.rdata.length >=2)
            {
                let dt = this.state.rdata[1];
                if(dt)
                {
                    history.push("/Personal/VideoPlay" + '/' + dt.vid)
                }
            }
        }
        if ((currentPro % 2 == 1)
            && (this.lastRunPro != currentPro)) {
            this.lastRunPro = currentPro;
            api.video.updatePlayPercent({
                videoId: videoId,
                percent: currentPro//progress[i]
            }, (state, data) => {
                if (state && data.status == 200) {
                }
            }
            );
        }
    }

    initOper(videoId)
    {
        if (this.props.userInfo == null) {
            Modal.confirm(
                {
                    title: '没有登录',
                    content: '只有登录用户才能播放，登录?',
                    onOk: () => this.props.history.push(RoutePath.Login),
                    okText: '去登录',
                    onCancel: () => this.props.history.goBack(),
                    cancelText: '回上一级',
                }
            )
        }
        else {
            this.updateVideoDetail(videoId);
            this.featchRelatives(videoId);
        }
    }

    componentDidMount() {
        this.initOper(null);
    }

    componentWillReceiveProps(newProps) {
        let videoId = newProps.match.params.videoId;
        this.initOper(videoId);
    }

    getDispFooter(item,index){

           let color = 'black';
           let txt = '排队中...';
           if(index === 0)
           {
               color='red';
               txt='播放中...';
           }

           if(index === 1)
           {
                color='blue';
                txt='接下来...';
           }

            return <Row style={{marginLeft:"5%"}}>
                <Col span={12}>
                    <span style={{color}}>{txt}</span>
                </Col>
                <Col span={4}></Col>
                <Col span={8}>
                    <span style={{color}}><PlayCircleOutlined />{ComFunction.getVisitedTimesDisplayText(item.visitTimes)}</span>
                </Col>
            </Row>
    }

    render() {

        const {
            teacherScore,
            courseScore,
            videoData = null,
            rdata,
        } = this.state;

        let history = this.props.history;

        let rightData = [];
        if(videoData)
        {
            rightData.push(videoData);
        }
        let mx = rdata.length > 2?2:rdata.length;
        for(let i =0;  i < mx;  i++)
        {
            rightData.push(rdata[i]);
        }

        return (
            <MyLayout>
                <p className="userEditTitle">{videoData != null ? videoData.name : ""}</p>
                <div>
                    <Row>
                        <Col style={{height: 570}} span={18}>
                            {videoData ?
                                <video
                                    onTimeUpdate={this.onTimeupdate}
                                    onLoadedMetadata={this.onLoadedmetadata}
                                    style={{ marginLeft: '50px',backgroundColor:'black' }}
                                    id="play"
                                    height="100%"
                                    width="100%"
                                    controls
                                    autoplay="autoplay"
                                    src={CON.VIDEO_SERVER + videoData.filePath}
                                /> : null}
                        </Col>
                        <Col span={2}>

                        </Col>
                        <Col style={{height: 570}} span={4}>
                                <List
                                    grid={{gutter: 0, column: 1}}
                                    itemLayout="vertical"
                                    size="small"
                                    dataSource={rightData}
                                    renderItem={(item, index) => (
                                    <List.Item key={index}>
                                        <Card hoverable
                                            cover={<img alt="example" style={{height: "100px",width:"100%"}} src={item.posterPath}/>}
                                            style={{height: "180px",width:"100%"}}
                                            onClick={() =>{
                                                if(index > 0)
                                                {
                                                    history.push("/Personal/VideoPlay" + '/' + item.vid)
                                                }
                                            }
                                      }
                                        >
                                            <Meta title={
                                                <Tooltip
                                                    title={item.name}
                                                    >
                                                    {item.name}
                                                </Tooltip>}
                                                style={{height: "50px",width:"100%"}}
                                                description={
                                                    this.getDispFooter(item,index)
                                                }
                                                />

                                        </Card>
                                    </List.Item>
                                )}
                                />
                        </Col>
                    </Row>
                    {/*
                    <Row>
                        <Col span={18}>
                            <div style={{backgroundColor:"#0c0906",width:"1000"}}>
                                <div style={{fontSize:"22px" ,color:"#f4f4f4",padding:"10px"}}>
                                    {videoData!= null?videoData.name:""}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    */}
                    <Row >
                        <Col span={18}>
                            <Tabs defaultActiveKey="1" style={{ padding: "20px" }}>
                                <TabPane tab="课程描述" key="1">
                                    {videoData != null ? videoData.intro : ""}
                                </TabPane>
                                <TabPane tab="课程评价" key="2">
                                    <div>
                                        <span >教师评分：</span>
                                        <Rate onChange={(e) => this.setState({ teacherScore: e })} value={teacherScore} />&emsp;
                                        {teacherScore ? <span>{Dict.getValue('rate', teacherScore - 1, '')}</span> : ''}
                                    </div>
                                    <div>
                                        <span >课程评分：</span>
                                        <Rate onChange={(e) => this.setState({ courseScore: e })} value={courseScore} />&emsp;
                                        {courseScore ? <span>{Dict.getValue('rate', courseScore - 1, '')}</span> : ''}
                                    </div>
                                    <div>
                                        <Button type="primary" style={{ marginTop: "20px" }} onClick={() => this.SubmitComment()}>提交评价</Button>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            </MyLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}

export default connect(mapStateToProps, null)(VideoPlay);