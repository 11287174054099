import React, { Component } from 'react';
import {Row, Col, Select, message, Button, Tabs, Input,Modal,Badge, Divider} from 'antd';
import api from '../../../api/api';
import QuestionModal from "./QuestionModal";
import AddOrEditChoiceModal from "./AddOrEditChoiceModal";

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

class QuestionList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:null,
            activeKey:'0',
            choicesData:""
        };
    }

    handleChange=(value,standardAnswer)=>{

        if(!standardAnswer)
        {
            message.error("你的答案为空或者没有变更，没必要提交");
            return;
        }

        api.exam.addOrEditQuestion(
            {
                "paperId": this.props.paperId,
                "questionId": value.questionId,
                "standardAnswer": typeof (standardAnswer) == "string" ? standardAnswer : standardAnswer.join("")
            },
            (state,data)=>{
                if(state && data.status === 200) {
                    Modal.success({
                        title: '提示',
                        content: '参考答案添加/修改成功!',
                    });
                    this.props.onChange(state);
                } else {
                    message.error(data.msg);
                }
            }
        )
    };

    removeQuestion(type,data){
        switch (type) {
            case "choice":
                confirm({
                    title: "提示",
                    content:"你确定要删除这个选项吗?",
                    onOk:()=>{
                        api.exam.removeChoiceById({"choiceId":data},
                            (state,data)=>{
                                if (state) {
                                    if(data.status == 200) {
                                        this.props.onChange(state);
                                        message.success('删除成功!');
                                    }else {
                                        message.error(data.msg);
                                    }
                                }
                            }
                        )
                    }
                });
                break;
            case "question":
                confirm({
                    title: "提示",
                    content:"你确定要删除这道试题吗?",
                    onOk:()=>{
                        api.exam.removeQuestionById({"questionId":data},
                            (state,data)=>{
                                if (state) {
                                    if(data.status == 200) {
                                        this.onChange(state);
                                        message.success('删除成功!');
                                    }else {
                                        message.error(data.msg);
                                    }
                                }
                            }
                        )
                    }
                });
                break;
            default:
                break;
        }
    }

    onChange(ar,oper){
        if (ar){
            if(oper == 'add')
            {
                let last = this.props.questionData.length;
                this.setState(
                {
                    activeKey:last.toString()
                })
            }
            this.props.onChange(ar);
        }
    }

    choicesSelect=(val)=>{
        const children = [];
        val.choices.map((value,index)=>(
            children.push(<Option key={value.tmark}>{value.tmark}</Option>)
        ));
        return children;
    };

    render() {
        let questionData = this.props.questionData;
        return(
            <div>
                <QuestionModal type="add"
                               paperId={this.props.paperId}
                               questionType={this.props.questionType}
                               onChange={(ar)=>this.onChange(ar,'add')}/>
                {questionData.length>0?
                    <Tabs activeKey={this.state.activeKey}
                          tabPosition="top"
                          onChange={(activeKey)=>{
                              this.setState({activeKey})
                          }}
                          >
                        {questionData.map((val,index) => (
                            <TabPane tab={<Badge
                             status={(val.choices.length == 0 && (val.examQuestion.itemType <=2 )) || !val.examQuestion.standardAnswer?'error':'success'}
                            >{`第${index+1}题(${val.examQuestion.itemScore}分)`}</Badge>} key={index}>
                                <Row>
                                    <Col span={20}>
                                        <div dangerouslySetInnerHTML={{__html:val.examQuestion.content}}></div>
                                    </Col>
                                    <Col span={2}>
                                        <QuestionModal type="edit"
                                                       data={val.examQuestion}
                                                       paperId={this.props.paperId}
                                                       questionType={this.props.questionType}
                                                       onChange={(ar)=>this.onChange(ar)}/>
                                    </Col>
                                    <Col span={2}>
                                        <Divider type='vertical'/>
                                        <Button
                                        type='danger'
                                        size='small'
                                        onClick={
                                            ()=>{
                                                this.removeQuestion("question",val.questionId);
                                            }
                                        }>
                                        删除</Button>
                                    </Col>
                                </Row>

                                {
                                    //只有选择题需要添加选项
                                    this.props.questionType===0
                                    ||this.props.questionType===1
                                    ||this.props.questionType===2?
                                    <AddOrEditChoiceModal 
                                        type="add"                
                                        onChange={(ar)=>this.onChange(ar)}
                                        questionId={val.questionId}/>
                                    :null
                                }

                                {val.choices.length > 0?
                                <div style={{
                                    border:"1px solid #000",
                                    marginBottom:"5px",
                                }}>
                                {
                                    
                                    val.choices.map((item,index) => (
                                        <Row style={{margin:"5px"}}>
                                            <Col span={1}>
                                                {item.tmark+"、"}
                                            </Col>
                                            <Col span={18}>
                                                <div dangerouslySetInnerHTML={{__html:item.content}}/>
                                            </Col>
                                            <Col span={2}>
                                                <AddOrEditChoiceModal type="edit"
                                                                      choiceData={item}
                                                                      questionId={val.questionId}
                                                                      onChange={(ar)=>this.onChange(ar)}/>
                                            </Col>
                                            <Col span={2}>
                                                <Divider type='vertical'/>
                                                <Button
                                                type='danger' 
                                                size='small'
                                                onClick={
                                                    ()=>{
                                                        this.removeQuestion("choice",item.choiceId);
                                                    }
                                                }
                                                >删除</Button>
                                            </Col>
                                        </Row>
                                    ))
                                }
                                </div>:null
                                }

                                <Row>
                                    <Col span={2}>
                                        参考答案:
                                    </Col>
                                    <Col span={22}>

                                        {
                                            //单选题，多选题，不定向选择题
                                            this.props.questionType === 0
                                            ||this.props.questionType === 1
                                            ||this.props.questionType === 2?
                                            <Select 
                                                       mode="multiple"
                                                       placeholder="标准答案"
                                                       defaultValue={val.examQuestion.standardAnswer?val.examQuestion.standardAnswer.split(""):[]}
                                                       style={{ width: 320 }}
                                                       onChange={(e)=>this.handleChange(val,e)}>
                                                {this.choicesSelect(val)}
                                          </Select>:null
                                        }

                                        {
                                            //填空题，简答题
                                            this.props.questionType === 3
                                            ||this.props.questionType ===4?
                                            <>
                                                <TextArea rows={4}
                                                          size="large"
                                                          placeholder="本题参考答案，判卷时参考"
                                                          defaultValue={val.examQuestion.standardAnswer}
                                                          onChange={(e)=>this.setState({choicesData:e.target.value})}
                                                />
                                                <Button type='primary'
                                                        style={{marginTop:"15px"}}
                                                        onClick={()=>this.handleChange(val,this.state.choicesData)}>
                                                    提交参考答案</Button>
                                            </>:null
                                        }

                                        {
                                            //判断题
                                            this.props.questionType === 5?
                                            <Select 
                                                    placeholder="标准答案"
                                                    defaultValue={val.examQuestion.standardAnswer}
                                                    style={{ width: 320 }}
                                                    onChange={(e)=>this.handleChange(val,e)}>
                                                <Option value="T">正确</Option>
                                                <Option value="F">错误</Option>
                                          </Select>:null
                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                        ))}
                    </Tabs>:
                    <div>还没有添加该类型试题!</div>
                }
            </div>
        )
    }
}
export default QuestionList;