import { Avatar, Divider, Pagination, Popover, Table, Tooltip } from 'antd';
import React, { Component } from 'react';
import api from '../../../api/api';

class BizCloseList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
          };

          this.initPageSize = 8;

          this.pageNum = 1;
          this.pageSize = this.initPageSize;
          this.totalNum = 10000;
    }

    featchBizOrderList() {
        api.biz.pageSearchBizCloses(
            {
                "condition": {
                    uid:this.props.uid,
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data) => {
                if (state && data.status == 200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        data: data.data,
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.featchBizOrderList();
    }

    pageOnChange(pageNum, pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(() => this.featchBizOrderList(), 200);
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        setTimeout(() => this.featchBizOrderList(), 200,);
    }

    render() {

        const columns = [
            {
                title: '结算单',
                dataIndex: 'payEvidence',
                key: 'payEvidence',
                render: (text, record) => {
                    if(text)
                    {
                        return <a href={text} target='_blank'><Avatar src={text}/ ></a>
                    }
                    else
                    {
                        return <div>无结算单</div>
                    }
                },
            },
            {
                title: '结算状态',
                dataIndex: 'status',
                key: 'status',
                className:"tableOverflow",
                render: (text, record) => (
                        <div>{text === 1?'已结算':'未结算'}</div>
                ),
            },
            {
                title: '账目金额(元)',
                dataIndex: 'totalNum',
                key: 'totalNum',
                render: (text, record) => (
                    <div>{record.totalNum/100}</div>
                )
            },
            {
                title: '支付金额(元)',
                dataIndex: 'payNum',
                key: 'payNum',
                render: (text, record) => (
                    <div>{record.payNum/100}</div>
                )
            },
            {
                title: '申请日期',
                dataIndex: 'sumbitDate',
                key: 'sumbitDate',
                render: (text, record) => (
                    <div>{record.sumbitDate?record.sumbitDate:'无日期'}</div>
                )
            },
            {
                title: '支付日期',
                dataIndex: 'payDate',
                key: 'payDate',
                render: (text, record) => (
                    <div>{record.payDate?record.payDate:'未支付'}</div>
                )
            },
        ];

        return (
            <>
            <Table
                        rowKey='closeId'
                        pagination={false}
                        dataSource={this.state.data}
                        columns={columns}
            />
            <Divider/>
            <Pagination
                       onChange={(pageNum,pageSize)=>this.pageOnChange(
                            pageNum,pageSize
                        )}
                        pageSize={this.pageSize}
                        current={this.pageNum}
                        total={this.totalNum}
                        />
            </>
        );
    }
}

export default BizCloseList;