import React, { Component } from 'react';
import { Col, Row, Button,Tag, Table, Modal, message, Divider, Switch, Select, Form} from 'antd';
import api from '../../../api/api';
import Dict from "../../../config/Dict";
import AdressData from '../../../config/AdressData';
import AddStudentsWithClassModal from '../../common/addstudents/AddStudentsWithClassModal';
import { setLoadingState } from '../../../reducers/UserInfo';
const { confirm } = Modal;

class GetJoinStudent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            data:[],
            clses:[],
            selectClassId:null,

            selectedRowKeys: [],
            filteredInfo: null,
            pagination:true,
        };
    }

    getJoinStudentByTaskId(){
        let mtaskId = this.props.mtaskId;
        setLoadingState(true);
        api.taskadmin.getJoinStudentByTaskId(
            {
                mtaskId: mtaskId,
                classId: this.state.selectClassId?this.state.selectClassId:"",
            },
            (state, data)=>{
                setLoadingState(false);
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    getMyOcuppyClasses() {
        api.taskadmin.getMyOcuppyClasses(null,
            (state, data)=>{
                if(state && data.status==200) {

                    let selectClassId = null;
                    if(data.data.length > 0)
                    {
                        selectClassId = data.data[0].classId;
                    }

                    this.setState({
                        clses:data.data,
                        selectClassId
                    })
                    setTimeout(()=>this.getJoinStudentByTaskId(),200);
                }
            }
        )
    }

    addStudentsToTask = (uids) => {
        let mtaskId = this.props.mtaskId;
        setLoadingState(true);
        api.taskadmin.addStudentsToTask({
                "mtaskId": mtaskId,
                "studentUids": uids
            },
            (state, data)=>{
                setLoadingState(false);
                if(state && data.status === 200) {
                    setTimeout(()=>this.getJoinStudentByTaskId(),200);
                }
            }
        );
        return true;
    };

    onChange(ar){
        if (ar){
            Modal.success({
                title: '提示',
                content: '学生添加成功!',
            });
            this.getJoinStudentByTaskId();
        }
    }

    examFreeWork(status,uid){
        let title = "";
        let content = "";
        if (status == 1){
            title = "你确定要[审批通过]这个免修申请吗?";
            content = "免修申请通过后该学生不需要学习这个任务。"
        }else{
            title = "你确定要[审批驳回]这个免修申请吗?";
            content = "免修申请驳回后,这个学生的任务是必修状态。"
        }
        confirm({
            title: title,
            content:content,
            onOk:()=>{
                api.taskadmin.examFreeWork({
                        "freeWork": status,
                        "mtaskId": this.props.mtaskId,
                        "uid": uid
                    },
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.getJoinStudentByTaskId();
                        }else{
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    }

    removeStudentFromTask(uid){
        let mtaskId = this.props.mtaskId;
        confirm({
            title: "提示",
            content:"你确定要从这个任务中移除这个学员吗?",
            onOk:()=>{
                api.taskadmin.removeStudentFromTask({
                        "mtaskId": mtaskId,
                        "uid": uid,
                    },
                    (state,data)=>{
                        this.setState({selectedRowKeys:[]});
                        if (state) {
                            if(data.status == 200) {
                                setTimeout(()=>this.getJoinStudentByTaskId(), 200,);
                                message.success('删除成功!');
                            } else{
                                message.error(data.msg);
                            }
                        }
                    }
                )
            }
        });
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({selectedRowKeys});
    };

    componentDidMount() {
        this.getMyOcuppyClasses();
        
    }

    handleChange = (pagination, filters, sorter) => {
        this.setState({
          filteredInfo: filters,
          selectedRowKeys:[]
        });
    };

    batchRemoveSelected=()=>{
        let mtaskId = this.props.mtaskId;
        confirm({
            title: "提示",
            content:"你确定要从这个任务中移除批量移除这些学员吗?",
            onOk:()=>{
                api.taskadmin.batchRemoveStudentsFromTask({
                        "mtaskId": mtaskId,
                        "ids": this.state.selectedRowKeys,
                    },
                    (state,data)=>{
                        this.setState({selectedRowKeys:[]});
                        if (state) {
                            if(data.status == 200) {
                                setTimeout(()=>this.getJoinStudentByTaskId(), 200,);
                                message.success('删除成功!');
                            } else{
                                message.error(data.msg);
                            }
                        }
                    }
                )
            }
        });
    };

    getClsDisplay(record)
    {
        let {clses} = this.state;
        for(let i =0; i <record.clsEs.length; i++)
        {
            for(let j = 0; j < clses.length; j++)
            {
                if(record.clsEs[i].classId === clses[j].classId)
                {
                    return record.clsEs[i].name;
                }
            }
        }
        return "--"
    }

    deleteAllStudentsFromTask()
    {
        let mtaskId = this.props.mtaskId;
        confirm({
            title: "提示",
            content:"你确定要从这个任务中移除所有学员吗?",
            onOk:()=>{
                api.taskadmin.deleteAllTaskUserByTaskId({
                        "mtaskId": mtaskId,
                    },
                    (state,data)=>{
                        this.setState({selectedRowKeys:[]});
                        if (state) {
                            if(data.status == 200) {
                                setTimeout(()=>this.getJoinStudentByTaskId(), 200,);
                                message.success('删除成功!');
                            } else{
                                message.error(data.msg);
                            }
                        }
                    }
                )
            }
        });
    }

    addAllClassStudentToTask()
    {
        let mtaskId = this.props.mtaskId;
        confirm({
            title: "提示",
            content:"你确定要把本班所有人都加到这个任务吗?",
            onOk:()=>{
                api.taskadmin.addAllClassStudentToTask({
                        "mtaskId": mtaskId,
                        classId: this.state.selectClassId?this.state.selectClassId:null,
                    },
                    (state,data)=>{
                        this.setState({selectedRowKeys:[]});
                        if (state) {
                            if(data.status == 200) {
                                setTimeout(()=>this.getJoinStudentByTaskId(), 200,);
                                message.success('添加成功!');
                            } else{
                                message.error(data.msg);
                            }
                        }
                    }
                )
            }
        });
    }

    addAllMyAdminClassToTask()
    {
        let mtaskId = this.props.mtaskId;
        confirm({
            title: "提示",
            content:"你确定要把我所管理的所有班的学生都加到这个任务吗?",
            onOk:()=>{
                api.taskadmin.addAllMyAdminClassToTask({
                        "mtaskId": mtaskId
                    },
                    (state,data)=>{
                        this.setState({selectedRowKeys:[]});
                        if (state) {
                            if(data.status == 200) {
                                setTimeout(()=>this.getJoinStudentByTaskId(), 200,);
                                message.success('添加成功!');
                            } else{
                                message.error(data.msg);
                            }
                        }
                    }
                )
            }
        });
    }


    deleteAllStudentsFromTaskByClassId()
    {
        let mtaskId = this.props.mtaskId;
        confirm({
            title: "提示",
            content:"你确定要把本班所有人都从这个任务中删除吗?",
            onOk:()=>{
                api.taskadmin.deleteAllStudentsFromTaskByClassId({
                        "mtaskId": mtaskId,
                        classId: this.state.selectClassId?this.state.selectClassId:null,
                    },
                    (state,data)=>{
                        this.setState({selectedRowKeys:[]});
                        if (state) {
                            if(data.status == 200) {
                                setTimeout(()=>this.getJoinStudentByTaskId(), 200,);
                                message.success('删除成功!');
                            } else{
                                message.error(data.msg);
                            }
                        }
                    }
                )
            }
        });
    }

    render() {
        let {data,selectedRowKeys, filteredInfo, filteredCls,clses} = this.state;
        const rowSelection = {
            selectedRowKeys, //指定选中项的 key 数组
            onChange: this.onSelectChange,//选中项发生变化时的回调
        };
        const hasSelected = selectedRowKeys.length > 0;

        filteredInfo = filteredInfo || {};

        let clsFilters = [];
        for(let i = 0; i < clses.length; i++)
        {
            clsFilters.push({
                value:clses[i].classId,
                text: clses[i].name,
            })
        }
        const columns = [
            {
                title: '学生名',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow",
            },
            {
                title: '城市',
                dataIndex: 'pcity',
                key: 'pcity',
                className:"tableOverflow",
                filters: Dict.getPCityFilters(data),
                filteredValue: filteredInfo.pcity || null,
                onFilter: (value, record) => record.pcity?record.pcity.includes(value):false,
                render: (text, record) => (
                    <span>{Dict.getAdressLabelValue(record.pcity)}</span>
                ),
            },
            {
                title: '班级',
                dataIndex: 'clsEs',
                key: 'clsEs',
                className:"tableOverflow",
                filters: clsFilters,
                filteredValue: filteredInfo.clsEs || null,
                onFilter: (value, record) => {
                            for(let j =0; j <record.clsEs.length; j++)
                            {
                                if(record.clsEs[j].classId === value)
                                {
                                    return true;
                                }
                            }
                            return false;
                        },
                render: (text, record) => (
                    <span>{this.getClsDisplay(record)}</span>
                ),
            },
            {
                title: '学习进度',
                dataIndex: 'percent',
                key: 'percent',
                render: (text, record) => (
                    <Tag color="geekblue">
                        {text}%
                    </Tag>
                ),
            },
            {
                title: '免修状态',
                key: 'freeWork',
                dataIndex: 'freeWork',
                render: (text, record) => (
                    <Tag color={text==0?"#2db7f5":text==1?"#87d068":"#f50"}>
                        {
                            Dict.getValue('freeWork',text,'')
                        }
                    </Tag>
                ),
            },
            {
                title: '免修审核',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <a onClick={()=>this.examFreeWork(2,record.uid)} disabled={record.freeWork!=1?true:false}>同意</a>
                        <Divider type="vertical" />
                        <a onClick={()=>this.examFreeWork(0,record.uid)} disabled={record.freeWork!=1?true:false}>驳回</a>
                    </span>
                ),
            },
            {
                title: '删除学生',
                key: 'delete',
                render: (text, record) => (
                    <a onClick={()=>this.removeStudentFromTask(record.uid)}>删除</a>
                ),
            },
        ];
        return(
            <div>
                <Form layout='inline'>
                <Form.Item>
                        <Switch
                            checkedChildren={<span>分页开启</span>}
                            unCheckedChildren={<span>分页关闭</span>}
                            checked={this.state.pagination}
                            onChange={(pagination)=>this.setState({pagination})}
                        />
                </Form.Item>
                <Form.Item>
                        <Select value={this.state.selectClassId} onChange={(selectClassId)=>{
                            this.setState({selectClassId});
                            setTimeout(()=>this.getJoinStudentByTaskId(),200);
                            }}
                            >
                            <Select.Option value={null} key={-1}>不限班级(也就是所有班级)</Select.Option>
                            {
                                this.state.clses.map((item,index)=>(
                                    <Select.Option value={item.classId} key={index}>{item.name}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item>
                            <span>
                            {"共有"+data.length+"人参与"}
                            </span>
                    </Form.Item>
                    <Form.Item>
                            {
                            this.state.selectClassId?
                            <Button 
                            type='primary'
                            onClick={()=>{
                                this.addAllClassStudentToTask();
                            }}
                            >
                            一键添加所选班级所有学生
                            </Button>
                            :
                            <Button 
                            type='danger'
                            onClick={()=>{
                                this.addAllMyAdminClassToTask();
                            }}
                            >
                            一键添加我所管理所有班级的所有学生
                            </Button>
                            }
                    </Form.Item>
                    <Form.Item>
                        <AddStudentsWithClassModal 
                            title='精准添加学生到任务'
                            clses={this.state.clses}
                            handleAdd={this.addStudentsToTask}
                            mtaskId={this.props.mtaskId}
                        />
                    </Form.Item>

                </Form>
                <Form layout='inline'>
                    <Form.Item>
                            <Button type="danger" onClick={this.batchRemoveSelected} disabled={!hasSelected}>
                                一键删除精准选中的学生
                            </Button>
                            <span>
                                {hasSelected ? `共选中 ${selectedRowKeys.length} 人` : ''}
                            </span>
                    </Form.Item>
                    <Form.Item>
                        {
                            this.state.selectClassId?
                            <Button
                            type='danger'
                            disabled={data.length === 0}
                            onClick={()=>this.deleteAllStudentsFromTaskByClassId()}
                            >一键删除所选班级学生</Button>
                            :
                            <Button
                            type='danger'
                            disabled={data.length === 0}
                            onClick={()=>this.deleteAllStudentsFromTask()}
                            >一键删除所有学生</Button>
                        }

                    </Form.Item>
                </Form>
                <Divider/>
                <Table
                    rowSelection={rowSelection}
                    rowKey='mtaskUserId'
                    pagination={this.state.pagination?{
                        pageSize:20,
                    }:false}
                    dataSource={data}
                    columns={columns}
                    onChange={this.handleChange}
                />
            </div>
        )
    }
}
export default GetJoinStudent;