import React, { Component } from 'react';
import {Form,Modal, InputNumber, Input,Button,message} from 'antd';
import api from "../../../api/api";
import DraftInput from './DraftInput';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;

/************* 添加或者编辑一道题 ****************/
class QuestionModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            data:[],
        }
    }

    handleOk = e => {
        e.preventDefault();
        //let standardAnswer="";
        let paperId = this.props.paperId;
        let itemType = this.props.questionType;
        let questionId = this.props.data?this.props.data.questionId:"";
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.exam.addOrEditQuestion(
                    {paperId, itemType,questionId, ...values},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.setState({
                                visible: false,
                            });
                            this.props.onChange(state);
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const data = this.props.data;
        const formItemLayout = {
            labelCol: {
                sm: { span: 4 },
            },
            wrapperCol: {
                sm: { span: 18},
            },
        };

        return (
            <div>
                <Button type={this.props.type === "add"?"primary":"link"}
                        style={{ marginBottom: 16 }}
                        onClick={()=>{

                            if(this.props.type === "add")
                            {
                                this.props.form.setFieldsValue({
                                    content:'',
                                    itemScore:5,
                                    standardAnswer:"",
                                });
                            }

                            this.setState({
                                visible: true,
                            })
                        }}>
                    {this.props.type === "add"?<PlusOutlined/>:<EditOutlined/>}
                    {this.props.type === "add"?"添加试题":"编辑"}
                </Button>
                <Modal
                    title={this.props.type === "add"?"添加试题":"编辑试题"}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    width={900}
                    onCancel={this.handleCancel}
                    forceRender={true}
                >
                    <Form {...formItemLayout}>
                        <Form.Item label='本题分值' hasFeedback>
                            {getFieldDecorator('itemScore', {
                                initialValue:data?data.itemScore:0,
                                rules: [
                                    {
                                        required: true,
                                        message: '本题目分值是必须输入的!'
                                    },
                                ],
                            })(
                                <InputNumber min={1} max={1000}/>
                            )}
                        </Form.Item>
                        <Form.Item label='题目内容' hasFeedback style={{height:'320px'}}>
                            {getFieldDecorator('content', {
                                initialValue:data?data.content:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '题目内容是必须输入的!'
                                    },
                                ],
                            })(
                                <DraftInput/>
                            )}
                        </Form.Item>
                        {
                            /***
                            <Form.Item label='参考答案' hasFeedback>
                                {getFieldDecorator('standardAnswer', {
                                    initialValue:data?data.standardAnswer:'',
                                })(
                                    <TextArea rows={4}
                                            disabled
                                            size="large"
                                            placeholder="题目参考答案(试题添加/编辑完成后在添加参考答案)"
                                    />
                                )}
                            </Form.Item>
                             */
                        }
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Form.create()(QuestionModal);
