import React, { Component } from 'react';
import {Table, Button, Divider, Pagination} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";

class MyVideoOrderList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:null,

            pageNum:1,
            pageSize:8,
            totalNum:9,
            tradeStatus:null,
        };
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.myBizOrderList(), 200);
    }

    myBizOrderList(){
        api.biz.pageSearchBizOrders({
                "condition": {
                    //"outTradeNo": null,//订单号
                    //"tradeNo":null,//支付宝交易号
                    //"type": 1, //订单类型(0-讲座票,1-视频费)
                    //"uid": null
                    tradeStatus:this.state.tradeStatus,
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.myBizOrderList();
    }

    render() {
        const columns = [
            {
                title: '订单号',
                key: 'outTradeNo',
                render: (text, record) => (
                    <span>
                        <a onClick={()=>this.props.history.push('/Personal/OrderForm'+'/'+record.outTradeNo)}>
                           <div style={{fontSize:"15px"}}>
                            {record.outTradeNo}
                        </div>
                        <div style={{padding:"5px",color:"#797979"}}>
                            订单日期：{record.createdTime.substring(0,16)}
                        </div>
                        </a>
                    </span>
                )
            },
            {
                title: '订单内容',
                dataIndex: 'body',
                key: 'body',
            },
            {
                title: '订单金额',
                key: 'totalAmount',
                dataIndex: 'totalAmount',
                render: (text, record) => (
                    <span>
                        ￥{text/100}
                    </span>
                )
            },
            {
                title: '支付时间',
                //key: 'paidTime',
                //dataIndex: 'paidTime',
                render: (text, record) => (
                    <span>
                        {record.paidTime && record.paidTime != null ? record.paidTime.substring(0,16):"未支付"}
                    </span>
                )
            },
            {
                title: '操作',
                //dataIndex: 'paidTime',
                //key: 'paidTime',
                render: (text, record) => (
                    <span>
                        {
                            record.paidTime && record.paidTime != null  ?
                                "订单已完成":
                                <a
                                    onClick={()=>this.props.history.push('/Personal/VideoOrderForm'+'/'+record.outTradeNo)}>
                                    立即支付
                                </a>
                        }
                    </span>
                ),
            }];
        return(
            <MyLayout >
                <p className="userEditTitle">我的订单</p>
                <Divider/>
                <div>
                    <Table
                        pagination={false}
                        dataSource={this.state.data}
                        columns={columns}
                        rowKey="outTradeNo"
                    />
                    <div style={{paddingTop:"25px"}}>
                        <Pagination
                            onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.state.pageSize}
                            current={this.state.pageNum}
                            total={this.state.totalNum}
                        />
                    </div>
                </div>
            </MyLayout>
        )
    }
}

export default MyVideoOrderList;