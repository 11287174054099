//学科一级分类

let dataSubjectFirstClass = {
    "51":"农林牧渔大类",
    "52":"资源环境与安全大类",
    "53":"能源动力与材料大类",
    "54":"土木建筑大类",
    "55":"水利大类",
    "56":"装备制造大类",
    "57":"生物与化工大类",
    "58":"轻工纺织大类",
    "59":"食品药品与粮食大类",
    "60":"交通运输大类",
    "61":"电子信息大类",
    "62":"医药卫生大类",
    "63":"财经商贸大类",
    "64":"旅游大类",
    "65":"文化艺术大类",
    "66":"新闻传播大类",
    "67":"教育与体育大类",
    "68":"公安与司法大类",
    "69":"公共管理与服务大类"
};

//学科二级分类
let dataSubjectSubClass = {
    "010101":"马克思主义哲学",
    "010102":"中国哲学",
    "010103":"外国哲学",
    "010104":"逻辑学",
    "010105":"伦理学",
    "010106":"美学",
    "010107":"宗教学",
    "010108":"科学技术哲学",
    "020101":"政治经济学",
    "020102":"经济思想史",
    "020103":"经济史",
    "020104":"西方经济学",
    "020105":"世界经济",
    "020106":"人口、资源与环境经济学",
    "020201":"国民经济学",
    "020202":"区域经济学",
    "020203":"财政学(含税收学)",
    "020204":"金融学(含保险学)",
    "020205":"产业经济学",
    "020206":"国际贸易学",
    "020207":"劳动经济学",
    "020208":"统计学",
    "020209":"数量经济学",
    "020210":"国防经济",
    "030101":"法学理论",
    "030102":"法律史",
    "030103":"宪法学与行政法学",
    "030104":"刑法学",
    "030105":"民商法学(含劳动法学、社会保障法学)",
    "030106":"诉讼法学",
    "030107":"经济法学",
    "030108":"环境与资源保护法学",
    "030109":"国际法学(含国际公法、国际私法、国际经济法",
    "030110":"军事法学",
    "030201":"政治学理论",
    "030202":"中外政治制度",
    "030203":"科学社会主义与国际共产主义运动",
    "030204":"中共党史(含党的学说与党的建设)",
    "030206":"国际政治",
    "030207":"国际关系",
    "030208":"外交学",
    "030301":"社会学",
    "030302":"人口学",
    "030303":"人类学",
    "030304":"民俗学(含中国民间文学)",
    "030401":"民族学",
    "030402":"马克思主义民族理论与政策",
    "030403":"中国少数民族经济",
    "030404":"中国少数民族史",
    "030405":"中国少数民族艺术",
    "030501":"马克思主义基本原理",
    "030502":"马克思主义发展史",
    "030503":"马克思主义中国化研究",
    "030504":"国外马克思主义研究",
    "030505":"思想政治教育",
    "040101":"教育学原理",
    "040102":"课程与教学论",
    "040103":"教育史",
    "040104":"比较教育学",
    "040105":"学前教育学",
    "040106":"高等教育学",
    "040107":"成人教育学",
    "040108":"职业技术教育学",
    "040109":"特殊教育学",
    "040110":"教育技术学(可授教育学、理学学位)",
    "040201":"基础心理学",
    "040202":"发展与教育心理学",
    "040203":"应用心理学",
    "040301":"体育人文社会学",
    "040302":"运动人体科学",
    "040303":"体育教育训练学",
    "040304":"民族传统体育学",
    "050101":"文艺学",
    "050102":"语言学及应用语言学",
    "050103":"汉语言文字学",
    "050104":"中国古典文献学",
    "050105":"中国古代文学",
    "050106":"中国现当代文学",
    "050107":"中国少数民族语言文学(分语族)",
    "050108":"比较文学与世界文学",
    "050201":"英语语言文学",
    "050202":"俄语语言文学",
    "050203":"法语语言文学",
    "050204":"德语语言文学",
    "050205":"日语语言文学",
    "050206":"印度语言文学",
    "050207":"西班牙语语言文学",
    "050208":"阿拉伯语语言文学",
    "050209":"欧洲语言文学",
    "050210":"亚非语言文学",
    "050211":"外国语言学及应用语言学",
    "050301":"新闻学",
    "050302":"传播学",
    "050401":"艺术学",
    "050402":"音乐学",
    "050403":"美术学",
    "050404":"设计艺术学",
    "050405":"戏剧戏曲学",
    "050406":"电影学",
    "050407":"广播电视艺术学",
    "050408":"舞蹈学",
    "060101":"史学理论及史学史",
    "060102":"考古学及博物馆学",
    "060103":"历史地理学",
    "060104":"历史文献学(含敦煌学、古文字学)",
    "060105":"专门史",
    "060106":"中国古代史",
    "060107":"中国近现代史",
    "060108":"世界史",
    "070101":"基础数学",
    "070102":"计算数学",
    "070103":"概率论与数理统计",
    "070104":"应用数学",
    "070105":"运筹学与控制论",
    "070201":"理论物理",
    "070202":"粒子物理与原子核物理",
    "070203":"原子与分子物理",
    "070204":"等离子体物理",
    "070205":"凝聚态物理",
    "070206":"声学",
    "070207":"光学",
    "070208":"无线电物理",
    "070301":"无机化学",
    "070302":"分析化学",
    "070303":"有机化学",
    "070304":"物理化学(含化学物理)",
    "070305":"高分子化学与物理",
    "070401":"天体物理",
    "070402":"天体测量与天体力学",
    "070501":"自然地理学",
    "070502":"人文地理学",
    "070503":"地图学与地理信息系统",
    "070601":"气象学",
    "070602":"大气物理学与大气环境",
    "070701":"物理海洋学",
    "070702":"海洋化学",
    "070703":"海洋生物学",
    "070704":"海洋地质",
    "070801":"固体地球物理学",
    "070802":"空间物理学",
    "070901":"矿物学、岩石学、矿床学",
    "070902":"地球化学",
    "070903":"古生物学与地层学(含古人类学)",
    "070904":"构造地质学",
    "070905":"第四纪地质学",
    "071001":"植物学",
    "071002":"动物学",
    "071003":"生理学",
    "071004":"水生生物学",
    "071005":"微生物学",
    "071006":"神经生物学",
    "071007":"遗传学",
    "071008":"发育生物学",
    "071009":"细胞生物学",
    "071010":"生物化学与分子生物学",
    "071011":"生物物理学",
    "071012":"生态学",
    "071101":"系统理论",
    "071102":"系统分析与集成",
    "071200":"科学技术史",
    "080101":"一般力学与力学基础",
    "080102":"固体力学",
    "080103":"流体力学",
    "080104":"工程力学",
    "080201":"机械制造及其自动化",
    "080202":"机械电子工程",
    "080203":"机械设计及理论",
    "080204":"车辆工程",
    "080300":"光学工程",
    "080401":"精密仪器及机械",
    "080402":"测试计量技术及仪器",
    "080501":"材料物理与化学",
    "080502":"材料学",
    "080503":"材料加工工程",
    "080601":"冶金物理化学",
    "080602":"钢铁冶金",
    "080603":"有色金属冶金",
    "080701":"工程热物理",
    "080702":"热能工程",
    "080703":"动力机械及工程",
    "080704":"流体机械及工程",
    "080705":"制冷及低温工程",
    "080706":"化工过程机械",
    "080801":"电机与电器",
    "080802":"电力系统及其自动化",
    "080803":"高电压与绝缘技术",
    "080804":"电力电子与电力传动",
    "080805":"电工理论与新技术",
    "080901":"物理电子学",
    "080902":"电路与系统",
    "080903":"微电子学与固体电子学",
    "080904":"电磁场与微波技术",
    "081001":"通信与信息系统",
    "081002":"信号与信息处理",
    "081101":"控制理论与控制工程",
    "081102":"检测技术与自动化装置",
    "081103":"系统工程",
    "081104":"模式识别与智能系统",
    "081105":"导航、制导与控制",
    "081201":"计算机系统结构",
    "081202":"计算机软件与理论",
    "081203":"计算机应用技术",
    "081301":"建筑历史与理论",
    "081302":"建筑设计及其理论",
    "081303":"城市规划与设计(含风景园林规划与设计)",
    "081304":"建筑技术科学",
    "081401":"岩土工程",
    "081402":"结构工程",
    "081403":"市政工程",
    "081404":"供热、供燃气、通风及空调工程",
    "081405":"防灾减灾工程及防护工程",
    "081406":"桥梁与隧道工程",
    "081501":"水文学及水资源",
    "081502":"水力学及河流动力学",
    "081503":"水工结构工程",
    "081504":"水利水电工程",
    "081505":"港口、海岸及近海工程",
    "081601":"大地测量学与测量工程",
    "081602":"摄影测量与遥感",
    "081603":"地图制图学与地理信息工程",
    "081701":"化学工程",
    "081702":"化学工艺",
    "081703":"生物化工",
    "081704":"应用化学",
    "081705":"工业催化",
    "081801":"矿产普查与勘探",
    "081802":"地球探测与信息技术",
    "081803":"地质工程",
    "081901":"采矿工程",
    "081902":"矿物加工工程",
    "081903":"安全技术及工程",
    "082001":"油气井工程",
    "082002":"油气田开发工程",
    "082003":"油气储运工程",
    "082101":"纺织工程",
    "082102":"纺织材料与纺织品设计",
    "082103":"纺织化学与染整工程",
    "082104":"服装设计与工程",
    "082201":"制浆造纸工程",
    "082202":"制糖工程",
    "082203":"发酵工程",
    "082204":"皮革化学与工程",
    "082301":"道路与铁道工程",
    "082302":"交通信息工程及控制",
    "082303":"交通运输规划与管理",
    "082304":"载运工具运用工程",
    "082401":"船舶与海洋结构物设计制造",
    "082402":"轮机工程",
    "082403":"水声工程",
    "082501":"飞行器设计",
    "082502":"航空宇航推进理论与工程",
    "082503":"航空宇航制造工程",
    "082504":"人机与环境工程",
    "082601":"武器系统与运用工程",
    "082602":"兵器发射理论与技术",
    "082603":"火炮、自动武器与弹药工程",
    "082604":"军事化学与烟火技术",
    "082701":"核能科学与工程",
    "082702":"核燃料循环与材料",
    "082703":"核技术及应用",
    "082704":"辐射防护及环境保护",
    "082801":"农业机械化工程",
    "082802":"农业水土工程",
    "082803":"农业生物环境与能源工程",
    "082804":"农业电气化与自动化",
    "082901":"森林工程",
    "082902":"木材科学与技术",
    "082903":"林产化学加工工程",
    "083001":"环境科学",
    "083002":"环境工程",
    "083100":"生物医学工程",
    "083201":"食品科学",
    "083202":"粮食、油脂及植物蛋白工程",
    "083203":"农产品加工及贮藏工程",
    "083204":"水产品加工及贮藏工程",
    "090101":"作物栽培学与耕作学",
    "090102":"作物遗传育种",
    "090201":"果树学",
    "090202":"蔬菜学",
    "090203":"茶学",
    "090301":"土壤学",
    "090302":"植物营养学",
    "090401":"植物病理学",
    "090402":"农业昆虫与害虫防治",
    "090403":"农药学(可授农学、理学学位)",
    "090501":"动物遗传育种与繁殖",
    "090502":"动物营养与饲料科学",
    "090503":"草业科学",
    "090504":"特种经济动物饲养(含：蚕、蜂等)",
    "090601":"基础兽医学",
    "090602":"预防兽医学",
    "090603":"临床兽医学",
    "090701":"林木遗传育种",
    "090702":"森林培育",
    "090703":"森林保护学",
    "090704":"森林经理学",
    "090705":"野生动植物保护与利用",
    "090706":"园林植物与观赏园艺",
    "090707":"水土保持与荒漠化防治",
    "090801":"水产养殖",
    "090802":"捕捞学",
    "090803":"渔业资源",
    "100101":"人体解剖和组织胚胎学",
    "100102":"免疫学",
    "100103":"病原生物学",
    "100104":"病理学与病理生理学",
    "100105":"法医学",
    "100106":"放射医学",
    "100107":"航空、航天与航海医学",
    "100201":"内科学",
    "100202":"儿科学",
    "100203":"老年医学",
    "100204":"神经病学",
    "100205":"精神病与精神卫生学",
    "100206":"皮肤病与性病学",
    "100207":"影像医学与核医学",
    "100208":"临床检验诊断学",
    "100209":"护理学",
    "100210":"外科学",
    "100211":"妇产科学",
    "100212":"眼科学",
    "100213":"耳鼻咽喉科学",
    "100214":"肿瘤学",
    "100215":"康复医学与理疗学",
    "100216":"运动医学",
    "100217":"麻醉学",
    "100218":"急诊医学",
    "100301":"口腔基础医学",
    "100302":"口腔临床医学",
    "100401":"流行病与卫生统计学",
    "100402":"劳动卫生与环境卫生学",
    "100403":"营养与食品卫生学",
    "100404":"儿少卫生与妇幼保健学",
    "100405":"卫生毒理学",
    "100406":"军事预防医学",
    "100501":"中医基础理论",
    "100502":"中医临床基础",
    "100503":"中医医史文献",
    "100504":"方剂学",
    "100505":"中医诊断学",
    "100506":"中医内科学",
    "100507":"中医外科学",
    "100508":"中医骨伤科学",
    "100509":"中医妇科学",
    "100510":"中医儿科学",
    "100511":"中医五官科学",
    "100512":"针灸推拿学",
    "100513":"民族医学(含：藏医学、蒙医学等)",
    "100601":"中西医结合基础",
    "100602":"中西医结合临床",
    "100701":"药物化学",
    "100702":"药剂学",
    "100703":"生药学",
    "100704":"药物分析学",
    "100705":"微生物与生化药学",
    "100706":"药理学",
    "100800":"中药学",
    "110101":"军事思想",
    "110102":"军事历史",
    "110201":"军事战略学",
    "110202":"战争动员学",
    "110301":"联合战役学",
    "110302":"军种战役学(含：第二炮兵战役学)",
    "110401":"合同战术学",
    "110402":"兵种战术学",
    "110501":"作战指挥学",
    "110502":"军事运筹学",
    "110503":"军事通信学",
    "110504":"军事情报学",
    "110505":"密码学",
    "110506":"军事教育训练学(含：军事体育学)",
    "110601":"军事组织编制学",
    "110602":"军队管理学",
    "110700":"军队政治工作学",
    "110801":"军事后勤学",
    "110802":"后方专业勤务",
    "110803":"军事装备学",
    "120100":"管理科学与工程",
    "120201":"会计学",
    "120202":"企业管理(含：财务管理、市场营销、人力资源管理)",
    "120203":"旅游管理",
    "120204":"技术经济及管理",
    "120301":"农业经济管理",
    "120302":"林业经济管理",
    "120401":"行政管理",
    "120402":"社会医学与卫生事业管理",
    "120403":"教育经济与管理",
    "120404":"社会保障",
    "120405":"土地资源管理",
    "120501":"图书馆学",
    "120502":"情报学",
    "120503":"档案学",

    "510101":"作物生产技术",
    "510102":"种子生产与经营",
    "510103":"设施农业与装备",
    "510104":"现代农业技术",
    "510105":"休闲农业",
    "510106":"生态农业技术",
    "510107":"园艺技术",
    "510108":"植物保护与检疫技",
    "510109":"茶树栽培与茶叶加",
    "510110":"中草药栽培技术",
    "510111":"烟草栽培与加工",
    "510112":"棉花加工与经营管",
    "510113":"农产品加工与质量",
    "510114":"绿色食品生产与检",
    "510115":"农资营销与服务",
    "510116":"农产品流通与管理",
    "510117":"农业装备应用技术",
    "510118":"农业经济管理",
    "510119":"农村经营管理",
    "510201":"林业技术",
    "510202":"园林技术",
    "510203":"森林资源保护",
    "510204":"经济林培育与利用",
    "510205":"野生植物资源保护",
    "510206":"野生动物资源保护",
    "510207":"森林生态旅游",
    "510208":"森林防火指挥与通",
    "510209":"自然保护区建设与",
    "510210":"木工设备应用技术",
    "510211":"木材加工技术",
    "510212":"林业调查与信息处",
    "510213":"林业信息技术与管",
    "510301":"畜牧兽医",
    "510302":"动物医学",
    "510303":"动物药学",
    "510304":"动物防疫与检疫",
    "510305":"动物医学检验技术",
    "510306":"宠物养护与驯导",
    "510307":"实验动物技术",
    "510308":"饲料与动物营养",
    "510309":"特种动物养殖",
    "510310":"畜牧工程技术",
    "510311":"蚕桑技术",
    "510312":"草业技术",
    "510313":"养蜂与蜂产品加工",
    "510314":"畜牧业经济管理",
    "510401":"水产养殖技术",
    "510402":"海洋渔业技术",
    "510403":"水族科学与技术",
    "510404":"水生动物医学",
    "510405":"渔业经济管理",

    "520101":"国土资源调查与管理",
    "520102":"地质调查与矿产普查",
    "520103":"矿产地质与勘查",
    "520104":"岩矿分析与鉴定",
    "520105":"宝玉石鉴定与加工",
    "520106":"煤田地质与勘查技术",
    "520201":"工程地质勘查",
    "520202":"水文与工程地质",
    "520203":"钻探技术",
    "520204":"矿山地质",
    "520205":"地球物理勘探技术",
    "520206":"地质灾害调查与防治",
    "520207":"环境地质工程",
    "520208":"岩土工程技术",
    "520301":"工程测量技术",
    "520302":"摄影测量与遥感技术",
    "520303":"测绘工程技术",
    "520304":"测绘地理信息技术",
    "520305":"地籍测绘与土地管理",
    "520306":"矿山测量",
    "520307":"测绘与地质工程技术",
    "520308":"导航与位置服务",
    "520309":"地图制图与数字传播技术",
    "520310":"地理国情监测技术",
    "520311":"国土测绘与规划",
    "520401":"钻井技术",
    "520402":"油气开采技术",
    "520403":"油气储运技术",
    "520404":"油气地质勘探技术",
    "520405":"油田化学应用技术",
    "520406":"石油工程技术",
    "520501":"煤矿开采技术",
    "520502":"矿井建设",
    "520503":"矿山机电技术",
    "520504":"矿井通风与安全",
    "520505":"综合机械化采煤",
    "520506":"选煤技术",
    "520507":"煤炭深加工与利用",
    "520508":"煤化分析与检验",
    "520509":"煤层气采输技术",
    "520510":"矿井运输与提升",
    "520601":"金属与非金属矿开采技术",
    "520602":"矿物加工技术",
    "520603":"矿业装备维护技术",
    "520701":"大气科学技术",
    "520702":"大气探测技术",
    "520703":"应用气象技术",
    "520704":"防雷技术",
    "520801":"环境监测与控制技术",
    "520802":"农村环境保护",
    "520803":"室内环境检测与控制技术",
    "520804":"环境工程技术",
    "520805":"环境信息技术",
    "520806":"核与辐射检测防护技术",
    "520807":"环境规划与管理",
    "520808":"环境评价与咨询服务",
    "520809":"污染修复与生态工程技术",
    "520810":"清洁生产与减排技术",
    "520811":"资源综合利用与管理技术",
    "520901":"安全健康与环保",
    "520902":"化工安全技术",
    "520903":"救援技术",
    "520904":"安全技术与管理",
    "520905":"工程安全评价与监理",
    "520906":"安全生产监测监控",
    "520907":"职业卫生技术与管理",

    "530101":"发电厂及电力系统",
    "530102":"供用电技术",
    "530103":"电力系统自动化技术",
    "530104":"高压输配电线路施工运行与维护",
    "530105":"电力系统继电保护与自动化技术",
    "530106":"水电站机电设备与自动化",
    "530107":"电网监控技术",
    "530108":"电力客户服务与管理",
    "530109":"水电站与电力网",
    "530110":"电源变换技术与应用",
    "530111":"农业电气化技术",
    "530112":"分布式发电与微电网技术",
    "530201":"电厂热能动力装置",
    "530202":"城市热能应用技术",
    "530203":"核电站动力设备运行与维护",
    "530204":"火电厂集控运行",
    "530205":"电厂化学与环保技术",
    "530206":"电厂热工自动化技术",
    "530301":"风力发电工程技术",
    "530302":"风电系统运行与维护",
    "530303":"生物质能应用技术",
    "530304":"光伏发电技术与应用",
    "530305":"工业节能技术",
    "530306":"节电技术与管理",
    "530307":"太阳能光热技术与应用",
    "530308":"农村能源与环境技术",
    "530401":"黑色冶金技术",
    "530402":"轧钢工程技术",
    "530403":"钢铁冶金设备应用技术",
    "530404":"金属材料质量检测",
    "530405":"铁矿资源综合利用",
    "530501":"有色冶金技术",
    "530502":"有色冶金设备应用技术",
    "530503":"金属压力加工",
    "530504":"金属精密成型技术",
    "530601":"材料工程技术",
    "530602":"高分子材料工程技术",
    "530603":"复合材料工程技术",
    "530604":"非金属矿物材料技术",
    "530605":"光伏材料制备技术",
    "530606":"炭素加工技术",
    "530607":"硅材料制备技术",
    "530608":"橡胶工程技术",
    "530701":"建筑材料工程技术",
    "530702":"建筑材料检测技术",
    "530703":"建筑装饰材料技术",
    "530704":"建筑材料设备应用",
    "530705":"新型建筑材料技术",
    "530706":"建筑材料生产与管理",

    "540101":"建筑设计",
    "540102":"建筑装饰工程技术",
    "540103":"古建筑工程技术",
    "540104":"建筑室内设计",
    "540105":"风景园林设计",
    "540106":"园林工程技术",
    "540107":"建筑动画与模型制作",
    "540201":"城乡规划",
    "540202":"村镇建设与管理",
    "540203":"城市信息化管理",
    "540301":"建筑工程技术",
    "540302":"地下与隧道工程技术",
    "540303":"土木工程检测技术",
    "540304":"建筑钢结构工程技术",
    "540401":"建筑设备工程技术",
    "540402":"供热通风与空调工程技术",
    "540403":"建筑电气工程技术",
    "540404":"建筑智能化工程技术",
    "540405":"工业设备安装工程技术",
    "540406":"消防工程技术",
    "540501":"建设工程管理",
    "540502":"工程造价",
    "540503":"建筑经济管理",
    "540504":"建设项目信息化管理",
    "540505":"建设工程监理",
    "540601":"市政工程技术",
    "540602":"城市燃气工程技术",
    "540603":"给排水工程技术",
    "540604":"环境卫生工程技术",
    "540701":"房地产经营与管理",
    "540702":"房地产检测与估价",
    "540703":"物业管理",

    "550101":"水文与水资源工程",
    "550102":"水文测报技术",
    "550103":"水政水资源管理",
    "550201":"水利工程",
    "550202":"水利水电工程技术",
    "550203":"水利水电工程管理",
    "550204":"水利水电建筑工程",
    "550205":"机电排灌工程技术",
    "550206":"港口航道与治河工程",
    "550207":"水务管理",
    "550301":"水电站动力设备",
    "550302":"水电站电气设备",
    "550303":"水电站运行与管理",
    "550304":"水利机电设备运行与管理",
    "550401":"水土保持技术",
    "550402":"水环境监测与治理",

    "560101":"机械设计与制造",
    "560102":"机械制造与自动化",
    "560103":"数控技术",
    "560104":"精密机械技术",
    "560105":"特种加工技术",
    "560106":"材料成型与控制技术",
    "560107":"金属材料与热处理技术",
    "560108":"铸造技术",
    "560109":"锻压技术",
    "560110":"焊接技术与自动化",
    "560111":"机械产品检测检验技术",
    "560112":"理化测试与质检技术",
    "560113":"模具设计与制造",
    "560114":"电机与电器技术",
    "560115":"电线电缆制造技术",
    "560116":"内燃机制造与维修",
    "560117":"机械装备制造技术",
    "560118":"工业设计",
    "560119":"工业工程技术",
    "560201":"自动化生产设备应用",
    "560202":"机电设备安装技术",
    "560203":"机电设备维修与管理",
    "560204":"数控设备应用与维护",
    "560205":"制冷与空调技术",
    "560206":"光电制造与应用技术",
    "560207":"新能源装备技术",
    "560301":"机电一体化技术",
    "560302":"电气自动化技术",
    "560303":"工业过程自动化技术",
    "560304":"智能控制技术",
    "560305":"工业网络技术",
    "560306":"工业自动化仪表",
    "560307":"液压与气动技术",
    "560308":"电梯工程技术",
    "560309":"工业机器人技术",
    "560401":"铁道机车车辆制造与维护",
    "560402":"铁道通信信号设备制造与维护",
    "560403":"铁道施工和养路机械制造与维护",
    "560501":"船舶工程技术",
    "560502":"船舶机械工程技术",
    "560503":"船舶电气工程技术",
    "560504":"船舶舾装工程技术",
    "560505":"船舶涂装工程技术",
    "560506":"游艇设计与制造",
    "560507":"海洋工程技术",
    "560508":"船舶通信与导航",
    "560509":"船舶动力工程技术",
    "560601":"飞行器制造技术",
    "560602":"飞行器维修技术",
    "560603":"航空发动机制造技术",
    "560604":"航空发动机装试技术",
    "560605":"航空发动机维修技术",
    "560606":"飞机机载设备制造技术",
    "560607":"飞机机载设备维修技术",
    "560608":"航空电子电气技术",
    "560609":"航空材料精密成型技术",
    "560610":"无人机应用技术",
    "560611":"导弹维修",
    "560701":"汽车制造与装配技术",
    "560702":"汽车检测与维修技术",
    "560703":"汽车电子技术",
    "560704":"汽车造型技术",
    "560705":"汽车试验技术",
    "560706":"汽车改装技术",
    "560707":"新能源汽车技术",

    "570101":"食品生物技术",
    "570102":"化工生物技术",
    "570103":"药品生物技术",
    "570104":"农业生物技术",
    "570105":"生物产品检验检疫",
    "570201":"应用化工技术",
    "570202":"石油炼制技术",
    "570203":"石油化工技术",
    "570204":"高分子合成技术",
    "570205":"精细化工技术",
    "570206":"海洋化工技术",
    "570207":"工业分析技术",
    "570208":"化工装备技术",
    "570209":"化工自动化技术",
    "570210":"涂装防护技术",
    "570211":"烟花爆竹技术与管理",
    "570212":"煤化工技术",

    "580101":"高分子材料加工技术",
    "580102":"制浆造纸技术",
    "580103":"香料香精工艺",
    "580104":"表面精饰工艺",
    "580105":"家具设计与制造",
    "580106":"化妆品技术",
    "580107":"皮革加工技术",
    "580108":"皮具制作与工艺",
    "580109":"鞋类设计与工艺",
    "580110":"乐器制造与维护",
    "580111":"陶瓷制造工艺",
    "580201":"包装工程技术",
    "580202":"包装策划与设计",
    "580203":"包装设备应用技术",
    "580204":"食品包装技术",
    "580301":"数字图文信息技术",
    "580302":"印刷设备应用技术",
    "580303":"印刷媒体设计与制作",
    "580304":"印刷媒体技术",
    "580305":"数字印刷技术",
    "580401":"现代纺织技术",
    "580402":"丝绸技术",
    "580403":"染整技术",
    "580404":"纺织机电技术",
    "580405":"纺织品检验与贸易",
    "580406":"纺织品设计",
    "580407":"家用纺织品设计",
    "580408":"纺织材料与应用",
    "580409":"针织技术与针织服装",
    "580410":"服装设计与工艺",
    "580411":"皮革服装制作与工艺",
    "580412":"服装陈列与展示设计",

    "590101":"食品加工技术",
    "590102":"酿酒技术",
    "590103":"食品质量与安全",
    "590104":"食品贮运与营销",
    "590105":"食品检测技术",
    "590106":"食品营养与卫生",
    "590107":"食品营养与检测",
    "590201":"中药生产与加工",
    "590202":"药品生产技术",
    "590203":"兽药制药技术",
    "590204":"药品质量与安全",
    "590205":"制药设备应用技术",
    "590301":"药品经营与管理",
    "590302":"药品服务与管理",
    "590303":"保健品开发与管理",
    "590304":"化妆品经营与管理",
    "590401":"粮食工程技术",
    "590501":"粮油储藏与检测技术",

    "600101":"铁道机车",
    "600102":"铁道车辆",
    "600103":"铁道供电技术",
    "600104":"铁道工程技术",
    "600105":"铁道机械化维修技术",
    "600106":"铁道信号自动控制",
    "600107":"铁道通信与信息化技术",
    "600108":"铁道交通运营管理",
    "600109":"铁路物流管理",
    "600110":"铁路桥梁与隧道工程技术",
    "600111":"高速铁道工程技术",
    "600112":"高速铁路客运乘务",
    "600113":"动车组检修技术",
    "600201":"智能交通技术运用",
    "600202":"道路桥梁工程技术",
    "600203":"道路运输与路政管理",
    "600204":"道路养护与管理",
    "600205":"公路机械化施工技术",
    "600206":"工程机械运用技术",
    "600207":"交通运营管理",
    "600208":"交通枢纽运营管理",
    "600209":"汽车运用与维修技术",
    "600210":"汽车车身维修技术",
    "600211":"汽车运用安全管理",
    "600212":"新能源汽车运用与维修",
    "600301":"航海技术",
    "600302":"国际邮轮乘务管理",
    "600303":"船舶电子电气技术",
    "600304":"船舶检验",
    "600305":"港口机械与自动控制",
    "600306":"港口电气技术",
    "600307":"港口与航道工程技术",
    "600308":"港口与航运管理",
    "600309":"港口物流管理",
    "600310":"轮机工程技术",
    "600311":"水上救捞技术",
    "600312":"水路运输与海事管理",
    "600313":"集装箱运输管理",
    "600401":"民航运输",
    "600402":"民航通信技术",
    "600403":"定翼机驾驶技术",
    "600404":"直升机驾驶技术",
    "600405":"空中乘务",
    "600406":"民航安全技术管理",
    "600407":"民航空中安全保卫",
    "600408":"机场运行",
    "600409":"飞机机电设备维修",
    "600410":"飞机电子设备维修",
    "600411":"飞机部件修理",
    "600412":"航空地面设备维修",
    "600413":"机场场务技术与管理",
    "600414":"航空油料",
    "600415":"航空物流",
    "600416":"通用航空器维修",
    "600417":"通用航空航务技术",
    "600418":"飞机结构修理",
    "600501":"管道工程技术",
    "600502":"管道运输管理",
    "600601":"城市轨道交通车辆技术",
    "600602":"城市轨道交通机电技术",
    "600603":"城市轨道交通通信信号技术",
    "600604":"城市轨道交通供配电技术",
    "600605":"城市轨道交通工程技术",
    "600606":"城市轨道交通运营管理",
    "600701":"邮政通信管理",
    "600702":"快递运营管理",

    "610101":"电子信息工程技术",
    "610102":"应用电子技术",
    "610103":"微电子技术",
    "610104":"智能产品开发",
    "610105":"智能终端技术与应用",
    "610106":"智能监控技术应用",
    "610107":"汽车智能技术",
    "610108":"电子产品质量检测",
    "610109":"电子产品营销与服务",
    "610110":"电子电路设计与工艺",
    "610111":"电子制造技术与设备",
    "610112":"电子测量技术与仪器",
    "610113":"电子工艺与管理",
    "610114":"声像工程技术",
    "610115":"移动互联应用技术",
    "610116":"光电技术应用",
    "610117":"光伏工程技术",
    "610118":"光电显示技术",
    "610119":"物联网应用技术",
    "610201":"计算机应用技术",
    "610202":"计算机网络技术",
    "610203":"计算机信息管理",
    "610204":"计算机系统与维护",
    "610205":"软件技术",
    "610206":"软件与信息服务",
    "610207":"动漫制作技术",
    "610208":"嵌入式技术与应用",
    "610209":"数字展示技术",
    "610210":"数字媒体应用技术",
    "610211":"信息安全与管理",
    "610212":"移动应用开发",
    "610213":"云计算技术与应用",
    "610214":"电子商务技术",
    "610301":"通信技术",
    "610302":"移动通信技术",
    "610303":"通信系统运行管理",
    "610304":"通信工程设计与监理",
    "610305":"电信服务与管理",
    "610306":"光通信技术",
    "610307":"物联网工程技术",

    "620101K":"临床医学",
    "620102K":"口腔医学",
    "620103K":"中医学",
    "620104K":"中医骨伤",
    "620105K":"针灸推拿",
    "620106K":"蒙医学",
    "620107K":"藏医学",
    "620108K":"维医学",
    "620109K":"傣医学",
    "620110K":"哈医学",
    "620201":"护理",
    "620202":"助产",
    "620301":"药学",
    "620302":"中药学",
    "620303":"蒙药学",
    "620304":"维药学",
    "620305":"藏药学",
    "620401":"医学检验技术",
    "620402":"医学生物技术",
    "620403":"医学影像技术",
    "620404":"医学美容技术",
    "620405":"口腔医学技术",
    "620406":"卫生检验与检疫技术",
    "620407":"眼视光技术",
    "620408":"放射治疗技术",
    "620409":"呼吸治疗技术",
    "620501":"康复治疗技术",
    "620502":"言语听觉康复技术",
    "620503":"中医康复技术",
    "620601K":"预防医学",
    "620602":"公共卫生管理",
    "620603":"卫生监督",
    "620604":"卫生信息管理",
    "620701":"人口与家庭发展服务",
    "620702":"生殖健康服务与管理",
    "620801":"健康管理",
    "620802":"医学营养",
    "620803":"中医养生保健",
    "620804":"心理咨询",
    "620805":"医疗设备应用技术",
    "620806":"精密医疗器械技术",
    "620807":"医疗器械维护与管理",
    "620808":"康复工程技术",
    "620809":"康复辅助器具技术",
    "620810":"假肢与矫形器技术",
    "620811":"老年保健与管理",

    "630101":"财政",
    "630102":"税务",
    "630103":"资产评估与管理",
    "630104":"政府采购管理",
    "630201":"金融管理",
    "630202":"国际金融",
    "630203":"证券与期货",
    "630204":"信托与租赁",
    "630205":"保险",
    "630206":"投资与理财",
    "630207":"信用管理",
    "630208":"农村金融",
    "630209":"互联网金融",
    "630301":"财务管理",
    "630302":"会计",
    "630303":"审计",
    "630304":"会计信息管理",
    "630401":"信息统计与分析",
    "630402":"统计与会计核算",
    "630501":"国际贸易实务",
    "630502":"国际经济与贸易",
    "630503":"国际商务",
    "630504":"服务外包",
    "630505":"经济信息管理",
    "630506":"报关与国际货运",
    "630507":"商务经纪与代理",
    "630508":"国际文化贸易",
    "630601":"工商企业管理",
    "630602":"商务管理",
    "630603":"商检技术",
    "630604":"连锁经营管理",
    "630605":"市场管理与服务",
    "630606":"品牌代理经营",
    "630701":"市场营销",
    "630702":"汽车营销与服务",
    "630703":"广告策划与营销",
    "630704":"茶艺与茶叶营销",
    "630801":"电子商务",
    "630802":"移动商务",
    "630803":"网络营销",
    "630901":"物流工程技术",
    "630902":"物流信息技术",
    "630903":"物流管理",
    "630904":"物流金融管理",
    "630905":"工程物流管理",
    "630906":"冷链物流技术与管理",
    "630907":"采购与供应管理",

    "640101":"旅游管理",
    "640102":"导游",
    "640103":"旅行社经营管理",
    "640104":"景区开发与管理",
    "640105":"酒店管理",
    "640106":"休闲服务与管理",
    "640201":"餐饮管理",
    "640202":"烹调工艺与营养",
    "640203":"营养配餐",
    "640204":"中西面点工艺",
    "640205":"西餐工艺",
    "640301":"会展策划与管理",

    "650101":"艺术设计",
    "650102":"视觉传播设计与制作",
    "650103":"广告设计与制作",
    "650104":"数字媒体艺术设计",
    "650105":"产品艺术设计",
    "650106":"家具艺术设计",
    "650107":"皮具艺术设计",
    "650108":"服装与服饰设计",
    "650109":"室内艺术设计",
    "650110":"展示艺术设计",
    "650111":"环境艺术设计",
    "650112":"公共艺术设计",
    "650113":"雕刻艺术设计",
    "650114":"包装艺术设计",
    "650115":"陶瓷设计与工艺",
    "650116":"刺绣设计与工艺",
    "650117":"玉器设计与工艺",
    "650118":"首饰设计与工艺",
    "650119":"工艺美术品设计",
    "650120":"动漫设计",
    "650121":"游戏设计",
    "650122":"人物形象设计",
    "650123":"美容美体艺术",
    "650124":"摄影与摄像艺术",
    "650125":"美术",
    "650201":"表演艺术",
    "650202":"戏剧影视表演",
    "650203":"歌舞表演",
    "650204":"戏曲表演",
    "650205":"曲艺表演",
    "650206":"音乐剧表演",
    "650207":"舞蹈表演",
    "650208":"国际标准舞",
    "650209":"服装表演",
    "650210":"模特与礼仪",
    "650211":"现代流行音乐",
    "650212":"作曲技术",
    "650213":"音乐制作",
    "650214":"钢琴伴奏",
    "650215":"钢琴调律",
    "650216":"舞蹈编导",
    "650217":"戏曲导演",
    "650218":"舞台艺术设计与制作",
    "650219":"音乐表演",
    "650301":"民族表演艺术",
    "650302":"民族美术",
    "650303":"民族服装与服饰",
    "650304":"民族民居装饰",
    "650305":"民族传统技艺",
    "650306":"少数民族古籍修复",
    "650307":"中国少数民族语言文化",
    "650401":"文化创意与策划",
    "650402":"文化市场经营管理",
    "650403":"公共文化服务与管理",
    "650404":"文物修复与保护",
    "650405":"考古探掘技术",
    "650406":"文物博物馆服务与管理",
    "650407":"图书档案管理",

    "660101":"图文信息处理",
    "660102":"网络新闻与传播",
    "660103":"版面编辑与校对",
    "660104":"出版商务",
    "660105":"出版与电脑编辑技术",
    "660106":"出版信息管理",
    "660107":"数字出版",
    "660108":"数字媒体设备管理",
    "660201":"新闻采编与制作",
    "660202":"播音与主持",
    "660203":"广播影视节目制作",
    "660204":"广播电视技术",
    "660205":"影视制片管理",
    "660206":"影视编导",
    "660207":"影视美术",
    "660208":"影视多媒体技术",
    "660209":"影视动画",
    "660210":"影视照明技术与艺术",
    "660211":"音像技术",
    "660212":"录音技术与艺术",
    "660213":"摄影摄像技术",
    "660214":"传播与策划",
    "660215":"媒体营销",

    "670101K":"早期教育",
    "670102K":"学前教育",
    "670103K":"小学教育",
    "670104K":"语文教育",
    "670105K":"数学教育",
    "670106K":"英语教育",
    "670107K":"物理教育",
    "670108K":"化学教育",
    "670109K":"生物教育",
    "670110K":"历史教育",
    "670111K":"地理教育",
    "670112K":"音乐教育",
    "670113K":"美术教育",
    "670114K":"体育教育",
    "670115K":"思想政治教育",
    "670116K":"舞蹈教育",
    "670117K":"艺术教育",
    "670118K":"特殊教育",
    "670119K":"科学教育",
    "670120K":"现代教育技术",
    "670121K":"心理健康教育",
    "670201":"汉语",
    "670202":"商务英语",
    "670203":"应用英语",
    "670204":"旅游英语",
    "670205":"商务日语",
    "670206":"应用日语",
    "670207":"旅游日语",
    "670208":"应用韩语",
    "670209":"应用俄语",
    "670210":"应用法语",
    "670211":"应用德语",
    "670212":"应用西班牙语",
    "670213":"应用越南语",
    "670214":"应用泰语",
    "670215":"应用阿拉伯语",
    "670216":"应用外语",
    "670301":"文秘",
    "670302":"文秘速录",
    "670401":"运动训练",
    "670402":"运动防护",
    "670403":"社会体育",
    "670404":"休闲体育",
    "670405":"高尔夫球运动与管理",
    "670406":"民族传统体育",
    "670407":"体育艺术表演",
    "670408":"体育运营与管理",
    "670409":"体育保健与康复",
    "670410":"健身指导与管理",

    "680101K":"治安管理",
    "680102K":"交通管理",
    "680103K":"信息网络安全监察",
    "680104K":"防火管理",
    "680105K":"边防检查",
    "680106K":"边境管理",
    "680107K":"特警",
    "680108K":"警察管理",
    "680109K":"公共安全管理",
    "680110K":"森林消防",
    "680111K":"部队后勤管理",
    "680112K":"部队政治工作",
    "680201K":"警察指挥与战术",
    "680202K":"边防指挥",
    "680203K":"船艇指挥",
    "680204K":"通信指挥",
    "680205K":"消防指挥",
    "680206K":"参谋业务",
    "680207K":"抢险救援",
    "680301K":"刑事科学技术",
    "680302K":"警犬技术",
    "680401K":"刑事侦查",
    "680402K":"国内安全保卫",
    "680403K":"经济犯罪侦查",
    "680404K":"禁毒",
    "680501":"司法助理",
    "680502":"法律文秘",
    "680503":"法律事务",
    "680504":"检察事务",
    "680601K":"刑事执行",
    "680602":"民事执行",
    "680603K":"行政执行",
    "680604K":"司法警务",
    "680605":"社区矫正",
    "680701K":"刑事侦查技术",
    "680702":"安全防范技术",
    "680703K":"司法信息技术",
    "680704":"司法鉴定技术",
    "680705K":"司法信息安全",
    "680706K":"罪犯心理测量与矫正技术",
    "680707K":"戒毒矫治技术",
    "680708":"职务犯罪预防与控制",

    "690101":"社会工作",
    "690102":"社会福利事业管理",
    "690103":"青少年工作与管理",
    "690104":"社区管理与服务",
    "690105":"公共关系",
    "690106":"人民武装",
    "690201":"民政管理",
    "690202":"人力资源管理",
    "690203":"劳动与社会保障",
    "690204":"网络舆情监测",
    "690205":"公共事务管理",
    "690206":"行政管理",
    "690207":"质量管理与认证",
    "690208":"知识产权管理",
    "690301":"老年服务与管理",
    "690302":"家政服务与管理",
    "690303":"婚庆服务与管理",
    "690304":"社区康复",
    "690305":"现代殡葬技术与管理"
};

export default {
    getValue:(value,def)=>{
        if(dataSubjectSubClass.hasOwnProperty(value)) {
            return dataSubjectSubClass[value];
        } else {
            return def;
        }
    }
}

export {dataSubjectSubClass};