import React, { Component, lazy } from 'react';
import { Tooltip, Affix,Table, Divider, PageHeader, Tag, message, Col, Row, Modal, Button, Form, Select, Icon} from 'antd';
import api from '../../api/api';
import MyLayout from '../common/MyLayout';
import Dict from '../../config/Dict';
import ExportJsonExcel from 'js-export-excel';
import TestPaperSearchModal from '../common/TestPaperSearchModal';

class StaticExamWhole extends Component {
    constructor(props) {
        super(props);
        this.state = {

            loading:false,

            data:[],

            clses:[],
            classId:null,
         };
    }

    feacthClassList()
    {
            //老师用户
            api.taskadmin.getMyOcuppyClasses(
                null,
                (state,data)=>{
                    if(state && data.status == 200)
                    {
                        this.setState({clses:data.data});
                    }
                    if(data.data.length > 0)
                    {
                        this.setState({classId:data.data[0].classId});
                        this.fetachExamperList(data.data[0].classId);
                    }
                }
            )
    }

    fetachExamperList(classId){
        if(!classId)
        {
            classId = this.state.classId;
        }
        if(classId)
        {
            api.class.getExamPaperListByClassId(
                {classId},
                (state, data)=>{
                    if(state && data.status==200) {
                        this.setState({
                            data:data.data,
                        })
                    }
                }
            )
        }
    }

    componentDidMount()
    {
        this.feacthClassList();
    }

    onSelectItem=(record)=>{
        api.class.addExamToClass(
            {
                classId:this.state.classId,
                paperId:record.paperId
            },
            (state,data)=>{
                if(state && data.status === 200)
                {
                    this.fetachExamperList();
                }
                else{
                    message.error(data.msg);
                }
            }
        )
    }

    removItem(record)
    {
        Modal.confirm({
            title:"警告",
            content:"真的要把本试卷从成绩单上去除吗?",
            onOk:()=>{
                api.class.removeOneExamFromClass(
                    {
                        classExamId:record.classExamId
                    },
                    (state,data)=>{
                        if(state && data.status === 200)
                        {
                            this.fetachExamperList();
                        }
                        else{
                            message.error(data.msg);
                        }
                    }
                )
            }
        })
    }

    getParentTitle(parentPaperId)
    {
        if(!parentPaperId)
        {
            return "非补考"
        }

        let data = this.state.data;
        for(let i = 0; i < data.length; i++)
        {
            if(parentPaperId === data[i].paperId)
            {
                return data[i].title;
            }
        }
        return '@@';
    }

    downloadWithData(data)
    {
        var option={};
        option.fileName = data.name;

        let oneSheetData = {
            sheetData:[],
            sheetName:data.classId,
            sheetHeader:[],
        }
        let i = 0;
        let j = 0;
        let sheetHeader = ['序号','姓名','身份证号'];
        let oneClumeWidths = [4, 4, 10];
        for(let i = 0; i < data.titles.length; i++)
        {
            sheetHeader.push(data.titles[i]);
            oneClumeWidths.push(10);
        }
        oneSheetData.sheetHeader = sheetHeader;
        oneSheetData.columnWidths = oneClumeWidths;

        let otherDatas = [];
        for(i = 0; i < data.scores.length; i++)
        {
            let onescore = data.scores[i];
            let oneLine = [];
            oneLine.push(i+1);
            oneLine.push(onescore.name);
            oneLine.push(onescore.idNumber);

            let oneOtherData = {
                sheetData:[],
                sheetName:onescore.name+"("+(i+1)+")",
               // sheetHeader:['序号','课程名称','成绩'],
                columnWidths:[4,10,4],
            };

            //基本数据
            oneOtherData.sheetData.push(["",'基本数据']);
            oneOtherData.sheetData.push(['姓名',onescore.name]);
            oneOtherData.sheetData.push(['身份证号',onescore.idNumber]);
            oneOtherData.sheetData.push(['班级',data.name]);
            //各科成绩
            oneOtherData.sheetData.push(["",'各科成绩']);
            oneOtherData.sheetData.push(['序号','课程名称','成绩']);
            for(j = 0; j < onescore.scores.length; j++)
            {
                let oneOtherDataOneLine = [j+1,data.titles[j]];
                if(onescore.scores[j] === -1)
                {
                    //oneLine.push('缺考');
                    oneLine.push(-1);
                    oneOtherDataOneLine.push(-1);
                }

                if(onescore.scores[j] >= 0)
                {
                    oneLine.push(onescore.scores[j]);
                    oneOtherDataOneLine.push(onescore.scores[j]);
                }
                oneOtherData.sheetData.push(oneOtherDataOneLine);
            }
            oneSheetData.sheetData.push(oneLine);
            otherDatas.push(oneOtherData);
        }

        option.datas = [oneSheetData].concat(otherDatas);

        var toExcel = new  ExportJsonExcel(option); //new
        toExcel.saveExcel(); //保存
    }

     downloadExcelData(){
        if(!this.state.classId)
        {
            return;
        }

        this.setState({loading:true});
        api.statics.featchExamClassStatics(
            {classId:this.state.classId},
            (state,data)=>{
                if(state && data.status === 200)
                {
                    this.downloadWithData(data.data)
                }
                else
                {
                    message.error(data.msg);
                }
                this.setState({loading:false});
            }
        )
        /***
        Modal.info({
            title:'提示',
            content:'内容开发中，敬请期待...'
        })
         */
     }

    render() {

        const columns = [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                render:(text, record,index) => (
                    <span>{index+1}</span>
                ),
            },
            {
                title: '标题',
                dataIndex: 'title',
                key: 'title',
                className:"tableOverflow",
                render: (text, record) => (
                    <Tooltip title={text}>
                        {text}
                    </Tooltip>
                ),
            },
            {
                title: '补考状态',
                dataIndex: 'parentPaperId',
                key: 'parentPaperId',
                className:"tableOverflow",
                render: (text, record) => {
                    let txt = this.getParentTitle(record.parentPaperId);
                    return <Tooltip title={txt}>
                    {txt}
                    </Tooltip>
                },
            },
            {
                title: '考试时间',
                dataIndex: 'answerDate',
                key: 'answerDate',
                render: (text, record) => (
                    <div>
                        <span>{record.startTime?record.startTime:'未定'}</span>
                        -
                        <span>{record.endTime?record.endTime:'未定'}</span>
                    </div>
                )
            },
            {
                title: '类型',
                key: 'statuss',
                dataIndex: 'statuss',
                render: (text, record) => (
                    <span>
                    <Tag color={record.examType==0?"#87d068":"#2db7f5"}>
                        {
                            Dict.getValue('examType',record.examType,'')
                        }
                    </Tag>
                    </span>
                ),
            },
            {
                title: '操作',
                key: 'action',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 140,
                        }
                    }
                },
                render: (text, record) => (
                    <div>
                    <Button
                    type='danger'
                    onClick={()=>this.removItem(record)}
                    >删除</Button>
                    </div>
                ),
            },
        ];
        return (
            <MyLayout>
                <PageHeader
                    style={{padding:"0"}}
                    title="考试综合统计"
                    subTitle="统计给定试卷的班级考试成绩"
                />
                <Divider/>
                <Row>
                    <Col span={22}>
                        <Form layout='inline'>
                        <Form.Item>
                        {
                        this.state.classId?
                        <Select defaultValue={this.state.classId} onChange={(classId)=>{
                            this.setState({classId});
                            this.fetachExamperList(classId);
                            }}>
                        {
                            this.state.clses.map((item,index)=>(
                                <Select.Option value={item.classId} key={index}>{item.name}</Select.Option>
                            ))
                        }
                        </Select>:null
                       }
                       </Form.Item>
                       <Form.Item>
                       <TestPaperSearchModal
                       hasSelectedData={this.state.data}
                    onSelectItem={this.onSelectItem}
                />
                       </Form.Item>
                        </Form>
                    </Col>
                    <Col span={2}>
                        <Button
                        icon='download' 
                        disabled={this.state.loading}
                        loading={this.state.loading}
                        onClick={()=>{
                            this.downloadExcelData();
                        }}
                        ></Button>
                    </Col>
                </Row>
                <Divider/>

                <Divider/>
                <Table
                    rowKey='paperId'
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
                <Divider/>
            </MyLayout>
        );
    }
}

export default StaticExamWhole;

