
import { Button } from 'antd';
import React, { Component } from 'react';

class PackButton extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            disabled:false,
         };
    }

    render() {
        let tProps = {...this.props};
        if(this.props.clickDelayTime > 0)
        {
            tProps.disabled |= this.state.disabled;
            tProps.loading |= this.state.disabled;
            tProps.onClick = (e)=>{
                this.setState({disabled:true});
                setTimeout(()=>{
                    this.setState({disabled:false});
                },this.props.clickDelayTime)
                if(this.props.onClick)
                {
                    this.props.onClick(e);
                }
            }
        }

        return (
            <Button
            {...tProps}
            >
                {this.props.children}
            </Button>
        );
    }
}

export default PackButton;