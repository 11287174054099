import React, { Component } from 'react';
import {Col, Row, Table, Pagination, Divider, Button} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";

class StarList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:[],
            pageNum:1,
            pageSize:8,
            totalNum:9,
        };
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.updateData(), 200);
    }

    updateData() {
        api.teacher.starList({
                "condition": "",
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.updateData();
    }

    render() {
        let history = this.props.history;
        const columns = [
            {
                title: '讲师姓名',
                key: 'name',
                render:  (text, record) => (
                    <a onClick={()=>history.push('/admin/AdminTeacherDetail'+'/'+record.teacher.tid)}>
                        {record.teacher.name}
                    </a>
                )
            },
            {
                title: '所属组织',
                key: 'org',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow:'ellipsis',
                            cursor:'pointer'
                        }
                    }
                },
                render: (text, record) => (
                    <span>{record.teacher.org.orgName}</span>
                ),
            },
            {
                title: '所属领域',
                key: 'field',
                render: (text, record) => (
                    <span>{record.teacher.field.name}</span>
                ),
            },
            {
                title: '开始时间',
                dataIndex: 'recommendDate',
                key: 'recommendDate',
            },
            {
                title: '结束时间',
                dataIndex: 'recommendEndDate',
                key: 'recommendEndDate',
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <a onClick={()=>
                            this.props.history.push('/admin/StarDetail'+'/'+record.id)}>详情</a>
                        <Divider type="vertical" />
                        <a onClick={()=>
                            this.props.history.push('/admin/AddOrEditStar'+'/'+record.id)}>编辑
                        </a>
                    </span>
                ),
            },
        ];
        return(
            <MyLayout>
                <p className="userEditTitle">推荐讲师</p>
                <Divider/>
                <Row gutter={5}>
                    <Col span={6}>
                        <Button type="primary"
                                onClick={()=>history.push('/admin/AddOrEditStar'+'/'+'addStar')}
                                style={{ marginBottom: 16 }}>
                            添加今日名师
                        </Button>
                    </Col>
                </Row>
                <Divider dashed/>
                <Table
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
                {/**
                <div style={{paddingTop:"25px"}}>
                    <Pagination
                        onChange={(pageNum,pageSize)=>this.pageOnChange(
                            pageNum,pageSize
                        )}
                        pageSize={this.state.pageSize}
                        current={this.state.pageNum}
                        total={this.state.totalNum}
                    />
                </div>
                 */}
            </MyLayout>
        )
    }
}

export default StarList;