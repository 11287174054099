import React, { Component } from 'react';
import { Cascader,Modal, Button, Icon, Input, Form, message, Row, Col, Select, Divider, Radio} from 'antd';
import api from "../../../api/api";
import DataValidate from "../../../config/DataValidate";
import connect from "react-redux/es/connect/connect";
import SimpleDateSel from "../../../components/SimpleDateSel";
import AdressData from '../../../config/AdressData';
import ImageUpload from '../../../components/ImageUpload';
import Action from '../../../actions/Action';
const { Option } = Select;

class InfoModify extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };


    resetSuccess=()=>{
        Modal.info({
            title: '信息修改',
            content: (
                <div>
                    <p>您的信息修改成功！</p>
                </div>
            ),
            onOk() {},
        });
    };

    handleOk = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let pcity = this.props.userInfo.role == 0?values.pcity[1]:null;
                api.user.editMyUserInfo(
                    {...values,pcity},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.props.refreshUserInfo();
                            this.resetSuccess();
                            this.setState({
                                visible: false,
                            });
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    };


    getAdressCasValue(user)
    {
        if(user == null)
        {
            return null;
        }
        let pcity = user.pcity;
        if(pcity == null)
        {
            return null;
        }

        for(let i = 0; i < AdressData.length; i++)
        {
            for(let j = 0; j < AdressData[i].children.length; j++)
            {
                let cd = AdressData[i].children[j];
                if(cd.value == pcity || cd.label == pcity)
                {
                    return [cd.pvalue,cd.value];
                }
            }
        }
        return null;
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { form } = this.props;
        let data = this.props.userInfo;

        return (
            <div>
                <Button type="link" onClick={this.showModal}>
                    {this.props.btnName}
                </Button>
                <Modal
                    title="修改基本信息"
                    width={820}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Form className="modal-form-style">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item label='头像'>
                                    {getFieldDecorator('profile', {
                                        initialValue:data?data.profile:'',
                                        rules: [
                                            {
                                                required: false,
                                                message: '头像是必须上传的!'
                                            },

                                        ],
                                    })(
                                        <ImageUpload/>,
                                    )
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="用户名">
                                    {getFieldDecorator('name', {
                                        initialValue:data?data.name:'',
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入一个新的用户名!'
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if(DataValidate.isChineseName(value) || DataValidate.isEnglishName(value)) {
                                                        callback();
                                                        return;
                                                    }
                                                    callback('error');
                                                },
                                                message: '用户名格式不对(只支持中汉字、英文字母)',
                                            },
                                        ],
                                    })(
                                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                               placeholder="用户名"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="性别">
                                    {getFieldDecorator('sex', {
                                        initialValue: data ? data.sex : 1,
                                        rules: [
                                            {
                                                required: true,
                                                message: '性别是必选的!'
                                            },
                                        ],
                                    })(
                                        <Radio.Group>
                                            <Radio value={1}>男</Radio>
                                            <Radio value={0}>女</Radio>
                                        </Radio.Group>
                                    )
                                    }
                                </Form.Item>
                            </Col>
                            {data&&data.role == 0 ?<>
                            <Col span={8}>
                                <Form.Item label="政治面貌">
                                    {getFieldDecorator('pnature', {
                                        initialValue:data?data.pnature:'',
                                        rules: [
                                            {
                                                required: true,
                                                message: '政治面貌必须输入!'
                                            },
                                        ],
                                    })(
                                        <Input placeholder="例如:党员、无党派等"/>,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="最高学历">
                                    {
                                        getFieldDecorator('pmaxEdu', {
                                            initialValue:data&&data.pmaxEdu?data.pmaxEdu:'其它',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '最高学历必须输入!'
                                                },
                                            ],
                                        })(
                                            <Select>
                                                <Option value={'小学'}>小学</Option>
                                                <Option value={'初中'}>初中</Option>
                                                <Option value={'高中'}>高中</Option>
                                                <Option value={'大学'}>大学</Option>
                                                <Option value={'其它'}>其它</Option>
                                            </Select>)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="所在城市">
                                    {
                                        getFieldDecorator('pcity', {
                                            initialValue:this.getAdressCasValue(data),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '所在城市必须输入'
                                                },
                                            ],
                                        })(
                                            <Cascader
                                                options={AdressData}
                                            />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item label='详细地址'>
                                    {
                                        getFieldDecorator('paddr', {
                                            initialValue:data?data.paddr:'',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '详细地址必须输入'
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="例如:大连市华北路58号"
                                            />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label='报考专业'>
                                    {
                                        getFieldDecorator('pmajor', {
                                            initialValue:data?data.pmajor:'',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '报考专业必须输入'
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="例如:汽车电子技术"
                                            />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label='奖惩情况'>
                                    {
                                        getFieldDecorator('prewordPunish', {
                                            initialValue:data?data.prewordPunish:'',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '奖励惩罚必须输入'
                                                },
                                            ],
                                        })(
                                            <Input.TextArea
                                                placeholder="例如:2009年，荣获某某比赛1等奖"
                                            />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label='工作单位'>
                                    {
                                        getFieldDecorator('pworkUnit', {
                                            initialValue:data?data.pworkUnit:'',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '工作单位'
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="例如:大连天巳集团有限公司"
                                            />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label='职位'>
                                    {
                                        getFieldDecorator('pduty', {
                                            initialValue:data?data.pduty:'',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '职位必须填写'
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="例如:软件工程师，待业等"
                                            />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item label='周末是否工作'>
                                    {
                                        getFieldDecorator('pworkWeekend', {
                                            initialValue:data?data.pworkWeekend:'',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '周末工作情况必须填写'
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder="例如:工作，不工作等"
                                            />)
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label='考生类别'>
                                    {
                                        getFieldDecorator('pcategory', {
                                            initialValue:data&&data.pcategory?data.pcategory:'其它',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: '类别是必选的!'
                                                },
                                            ],
                                        })(
                                            <Select>
                                                <Option value={'农村往届'}>农村往届</Option>
                                                <Option value={'农村应届'}>农村应届</Option>
                                                <Option value={'城市往届'}>城市往届</Option>
                                                <Option value={'城市应届'}>城市应届</Option>
                                                <Option value={'农民工'}>农民工</Option>
                                                <Option value={'退伍军人'}>退伍军人</Option>
                                                <Option value={'下岗失业人员'}>下岗失业人员</Option>
                                                <Option value={'新型职业农民'}>新型职业农民</Option>
                                                <Option value={'其它'}>其它</Option>
                                            </Select>)
                                    }
                                </Form.Item>
                            </Col>
                            <Divider dashed/>
                            {
                                form.getFieldValue("pcategory") == '退伍军人'?
                                    <>
                                <Col span={12}>
                                    <Form.Item label='退伍时间'>
                                        {
                                            getFieldDecorator('pretireTime', {
                                                initialValue:data?data.pretireTime:'',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '退伍时间必选!'
                                                    },
                                                ],
                                            })(
                                                <SimpleDateSel/>)
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="择业方式">
                                        {
                                            getFieldDecorator('pjoinJobType', {
                                                initialValue:data&&data.pjoinJobType?data.pjoinJobType:'',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '择业是必选的!'
                                                    },
                                                ],
                                            })(
                                                <Select>
                                                    <Option value={'自助择业'}>自助择业</Option>
                                                    <Option value={'地方安置'}>地方安置</Option>
                                                    <Option value={'其它'}>其它</Option>
                                                </Select>)
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label='服役期间奖惩'>
                                        {
                                            getFieldDecorator('parmRewordPunish', {
                                                initialValue:data?data.parmRewordPunish:'',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '服役期间奖惩必须输入'
                                                    },
                                                ],
                                            })(
                                                <Input.TextArea
                                                    placeholder="例如:2009年，荣获某某比赛一等功"
                                                />)
                                        }
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label='退役部队'>
                                        {
                                            getFieldDecorator('pretireArm', {
                                                initialValue:data?data.pretireArm:'',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '退役部队情况必须填写'
                                                    },
                                                ],
                                            })(
                                                <Input
                                                    placeholder="例如:陆军"
                                                />)
                                        }
                                    </Form.Item>
                                </Col>
                                    </>:null
                            }
                            </>:""}
                        </Row>
                    </Form>
                </Modal>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        refreshUserInfo: (data) => dispatch({
            type: Action.GET_USER_INFO,
        }),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Form.create({ name: 'InfoModify' })(InfoModify));
//export default Form.create()(InfoModify);
