import { GET, POST } from './utils/Request'
import Storage from './utils/Storage'

const SERVER_HOST = ''

var api = {
    connect: {
        getThirdPartCode: new GET(
            SERVER_HOST + '/api/connect/getThirdPartCode'
        ), //获取第三方code
        getCodeStatus: new GET(SERVER_HOST + '/api/connect/getCodeStatus'), //获取code状态
    },

    dummy: {
        taskdummy: new POST(SERVER_HOST + '/api/dummy/taskdummy'), //为任务dummy
    },

    signup: {
        addNewSignUp: new POST(SERVER_HOST + '/api/signup/addNewSignUp'), //添加新的报道
        pageSerchSignUp: new POST(SERVER_HOST + '/api/signup/pageSerchSignUp'),
    },

    notice: {
        getNoticeDetail: new GET(SERVER_HOST + '/api/notice/getNoticeDetail'), //获取没有读取的数字
        searchToMeNotice: new POST(
            SERVER_HOST + '/api/notice/searchToMeNotice'
        ), //分页搜索给我的通知
        getNotReadNum: new GET(SERVER_HOST + '/api/notice/getNotReadNum'), //获取没有读取的数字
        getItemName: new GET(SERVER_HOST + '/api/notice/getItemName'), //获取Item的名字
        getMyEditNoticeList: new GET(
            SERVER_HOST + '/api/notice/getMyEditNoticeList'
        ), //获取Item的名字
        addOrEditNotice: new POST(SERVER_HOST + '/api/notice/addOrEditNotice'), //添加修改通知
        deleteNoticeByNoticeId: new GET(
            SERVER_HOST + '/api/notice/deleteNoticeByNoticeId'
        ), //删除
        getNoticeUserListByNoticeId: new GET(
            SERVER_HOST + '/api/notice/getNoticeUserListByNoticeId'
        ), //根据id获取用户列表
        batchAddUserToNotice: new POST(
            SERVER_HOST + '/api/notice/batchAddUserToNotice'
        ), //批量添加用户到通知
        batchRomoveUserFromNotice: new POST(
            SERVER_HOST + '/api/notice/batchRomoveUserFromNotice'
        ), //批量移除用户
    },

    alipay: {
        alipay: new GET(SERVER_HOST + '/api/alipay/alipay'), //支付宝支付
        aliRefund: new GET(SERVER_HOST + '/api/alipay/aliRefund'), //支付宝退款
    },

    biz: {
        getBizOrderById: new GET(SERVER_HOST + '/api/biz/getBizOrderById'), //根据id(out_trade_no)获取订单
        myBizOrderList: new POST(SERVER_HOST + '/api/biz/myBizOrderList'), //我的订单列表
        checkSceneOrders: new GET(SERVER_HOST + '/api/biz/checkSceneOrders'), //获取场次售出的详细订单列表
        getAccountInfo: new GET(SERVER_HOST + '/api/biz/getAccountInfo'),
        sumbitClose: new GET(SERVER_HOST + '/api/biz/sumbitClose'),
        pageSearchBizOrders: new POST(
            SERVER_HOST + '/api/biz/pageSearchBizOrders'
        ),
        pageSearchBizCloses: new POST(
            SERVER_HOST + '/api/biz/pageSearchBizCloses'
        ),
        payClose: new POST(SERVER_HOST + '/api/biz/payClose'),
        delClose: new GET(SERVER_HOST + '/api/biz/delClose'),
    },

    anon: {
        exchangeAuthToken: new GET(SERVER_HOST + '/api/anon/exchangeAuthToken'),
        fieldList: new POST(SERVER_HOST + '/api/anon/fieldList'), // 领域列表
    },

    user: {
        basicinfocheck: new POST(SERVER_HOST + '/api/user/basicinfocheck'), //验证基本信息是否可以注册
        login: new POST(SERVER_HOST + '/api/user/login'), // 登录
        tokenLogin: new POST(SERVER_HOST + '/api/user/tokenLogin'), // token登录
        phonelogin: new POST(SERVER_HOST + '/api/user/phonelogin'), // 登录手机验证码登录
        register: new POST(SERVER_HOST + '/api/user/register'), // 用户注册
        logout: new GET(SERVER_HOST + '/api/user/logout'), // 注销
        validateImgCode: new GET(SERVER_HOST + '/api/user/validateImgCode'), //验证图片验证码是否正确
        isNeedPictureValide: new GET(
            SERVER_HOST + '/api/user/isNeedPictureValide'
        ), //是否需要图片验证，data=1需要，否则不需要
        sendPhoneKey: new GET(SERVER_HOST + '/api/user/sendPhoneKey'), //发送短信验证
        sendvalidateemail: new GET(SERVER_HOST + '/api/user/sendvalidateemail'), //发送注册邮件
        getMyUserInfo: new GET(SERVER_HOST + '/api/user/getMyUserInfo'), //获取当前用户信息
        bindphoneoremail: new POST(SERVER_HOST + '/api/user/bindphoneoremail'), // 绑定手机或者邮箱,如果原来未解绑则直接覆盖.
        resetpwdbyphoneoremail: new POST(
            SERVER_HOST + '/api/user/resetpwdbyphoneoremail'
        ), // 利用手机或者邮箱找回密码(重置密码)
        editMyPwd: new POST(SERVER_HOST + '/api/user/editMyPwd'), // 修改密码
        editMyUserInfo: new POST(SERVER_HOST + '/api/user/editMyUserInfo'), //编辑当前用户信息
        editIdNumber: new GET(SERVER_HOST + '/api/user/editIdNumber'), //修改我的身份证号
        islogin: new GET(SERVER_HOST + '/api/user/islogin'), //判断是否登录
        resetAppSecret: new GET(SERVER_HOST + '/api/user/resetAppSecret'), //判断是否登录
    },

    teacher: {
        addOrEditTeacher: new POST(
            SERVER_HOST + '/api/teacher/addOrEditTeacher'
        ), // 增加或编辑讲师
        teacherDetail: new GET(SERVER_HOST + '/api/teacher/teacherDetail'), //名师详情
        myTeacherInputList: new POST(
            SERVER_HOST + '/api/teacher/myTeacherInputList'
        ), // 我录入的讲师列表
        teacherList: new POST(SERVER_HOST + '/api/teacher/teacherList'), // 名师列表
        offTeacher: new GET(SERVER_HOST + '/api/teacher/offTeacher'), //注销讲师
        pendingTeacherList: new POST(
            SERVER_HOST + '/api/teacher/pendingTeacherList'
        ), // 待审核讲师列表
        examineTeacher: new GET(SERVER_HOST + '/api/teacher/examineTeacher'), //审批讲师{status=1通过、2驳回}
        getTodayStar: new GET(SERVER_HOST + '/api/teacher/getTodayStar'), //获取今日名师
        addOrEditStar: new POST(SERVER_HOST + '/api/teacher/addOrEditStar'), // 增加或编辑某日名师
        starList: new POST(SERVER_HOST + '/api/teacher/starList'), // 增加或编辑某日名师
        starDetail: new GET(SERVER_HOST + '/api/teacher/starDetail'), //今日名师详情
    },

    video: {
        addOrEditVideo: new POST(SERVER_HOST + '/api/video/addOrEditVideo'), // 增加或编辑视频
        myVideoInputList: new POST(SERVER_HOST + '/api/video/myVideoInputList'), // 我录入的视频列表
        videoDetail: new GET(SERVER_HOST + '/api/video/videoDetail'), //视频详情
        offVideo: new GET(SERVER_HOST + '/api/video/offVideo'), //注销视频
        delVideo: new GET(SERVER_HOST + '/api/video/delVideo'), //删除视频
        videoList: new POST(SERVER_HOST + '/api/video/videoList'), // 视频列表（所有通过审核的）
        pageMyPlayVideoList: new POST(
            SERVER_HOST + '/api/video/pageMyPlayVideoList'
        ),
        pendingVideoList: new POST(SERVER_HOST + '/api/video/pendingVideoList'), // 待审核视频列表
        examineVideo: new GET(SERVER_HOST + '/api/video/examineVideo'), //审批视频{status=1通过、2驳回}
        comment: new POST(SERVER_HOST + '/api/video/comment'), // 评价
        buyit: new GET(SERVER_HOST + '/api/video/buyit'), // 购买视频(创建/打开订单)
        myBuyVideoList: new GET(SERVER_HOST + '/api/video/myBuyVideoList'), // 获取我购买的视频列表
        myPlayVideoList: new GET(SERVER_HOST + '/api/video/myPlayVideoList'), // 获取我已经播放的视频列表
        updatePlayPercent: new GET(
            SERVER_HOST + '/api/video/updatePlayPercent'
        ), // 更新视频播放百分比
        pageSerchPlayRecord: new POST(
            SERVER_HOST + '/api/video/pageSerchPlayRecord'
        ), //播放痕迹搜索
        getRelativeVideoList: new GET(
            SERVER_HOST + '/api/video/getRelativeVideoList'
        ), // 获取临近视频
    },

    org: {
        applyOrg: new POST(SERVER_HOST + '/api/org/applyOrg'), // 申请组织
        orgDetail: new GET(SERVER_HOST + '/api/org/orgDetail'), //组织详情
        myOrgList: new POST(SERVER_HOST + '/api/org/myOrgList'), // 我的组织列表
        orgList: new POST(SERVER_HOST + '/api/org/orgList'), // 组织列表
        offOrg: new GET(SERVER_HOST + '/api/org/offOrg'), //组织详情
        editOrg: new POST(SERVER_HOST + '/api/org/editOrg'), // 编辑组织信息
        pendingOrgList: new POST(SERVER_HOST + '/api/org/pendingOrgList'), // 待审核组织列表
        examineOrg: new GET(SERVER_HOST + '/api/org/examineOrg'), //审批组织{status=1通过、2驳回}
    },

    admin: {
        addOrEditField: new POST(SERVER_HOST + '/api/admin/addOrEditField'), // 增加或编辑领域
        delField: new GET(SERVER_HOST + '/api/admin/delField'), // 删除领域
        getUsersInCategory: new POST(
            SERVER_HOST + '/api/admin/getUsersInCategory'
        ), // 按类别获取用户列表
        resetPwd: new GET(SERVER_HOST + '/api/admin/resetPwd'), // admin重置指定用户密码
        swichAvailability: new POST(
            SERVER_HOST + '/api/admin/swichAvailability'
        ), // 用户启用/禁用
        addOrEditAdvertise: new POST(
            SERVER_HOST + '/api/admin/addOrEditAdvertise'
        ), // 新增或者修改广告
        allAdvertisements: new GET(
            SERVER_HOST + '/api/admin/allAdvertisements'
        ), // 广告文件列表
        getAdByLinkId: new GET(SERVER_HOST + '/api/admin/getAdByLinkId'), // 获取广告ById
        deleteAdByLinkId: new GET(SERVER_HOST + '/api/admin/deleteAdByLinkId'), // 删除广告ById
    },

    series: {
        getAllVideoTypes: new GET(SERVER_HOST + '/api/series/getAllVideoTypes'), //获取所有类型
        addOrEditVideoType: new POST(
            SERVER_HOST + '/api/series/addOrEditVideoType'
        ), //添加修改类型
        deleteTypeByTypeId: new GET(
            SERVER_HOST + '/api/series/deleteTypeByTypeId'
        ), //删除
        getMyVideoSeries: new GET(SERVER_HOST + '/api/series/getMyVideoSeries'), //获取我的系列列表
        addOrEditVideoSeries: new POST(
            SERVER_HOST + '/api/series/addOrEditVideoSeries'
        ), //添加修改系列
        deleteSeries: new GET(SERVER_HOST + '/api/series/deleteSeries'), //删除系列
        sumbitToExam: new GET(SERVER_HOST + '/api/series/sumbitToExam'), //提交审核
        getVideoSeriesDetailById: new GET(
            SERVER_HOST + '/api/series/getVideoSeriesDetailById'
        ), //获取一个系列的详情
        addOrEditVideoChaper: new POST(
            SERVER_HOST + '/api/series/addOrEditVideoChaper'
        ), //添加修改章节
        deleteChapter: new GET(SERVER_HOST + '/api/series/deleteChapter'), //删除章节
        addVideoToChapter: new POST(
            SERVER_HOST + '/api/series/addVideoToChapter'
        ), //添加视频到一个章节
        removeVideoFromChapter: new GET(
            SERVER_HOST + '/api/series/removeVideoFromChapter'
        ), //从章节删除视频
        moveIndexInChapter: new POST(
            SERVER_HOST + '/api/series/moveIndexInChapter'
        ), //在一个章节内移动视频位置
        pageSerchSeries: new POST(SERVER_HOST + '/api/series/pageSerchSeries'), //分页搜索系列
        examPassOrReject: new GET(SERVER_HOST + '/api/series/examPassOrReject'), //审核
    },

    lecture: {
        addOrEditGround: new POST(SERVER_HOST + '/api/lecture/addOrEditGround'), // 增加或编辑场地模板
        groundDetail: new GET(SERVER_HOST + '/api/lecture/groundDetail'), // 场地模板详情
        myGroundModelList: new POST(
            SERVER_HOST + '/api/lecture/myGroundModelList'
        ), // 我的场地模板列表
        offGround: new GET(SERVER_HOST + '/api/lecture/offGround'), // 注销场地模板
        delGround: new GET(SERVER_HOST + '/api/lecture/delGround'), // 删除场地模板
        pendingGroundList: new POST(
            SERVER_HOST + '/api/lecture/pendingGroundList'
        ), // 待审核场地模板列表
        examineGround: new GET(SERVER_HOST + '/api/lecture/examineGround'), // 审批场地模板{status=1通过、2驳回}
        addOrEditLecture: new POST(
            SERVER_HOST + '/api/lecture/addOrEditLecture'
        ), // 增加或编辑讲座，本地操作不申请审批
        lectureList: new POST(SERVER_HOST + '/api/lecture/lectureList'), // 讲座搜索(列表)
        myLectureList: new POST(SERVER_HOST + '/api/lecture/myLectureList'), // 我的讲座列表
        lectureDetail: new GET(SERVER_HOST + '/api/lecture/lectureDetail'), // 讲座详情
        offLecture: new GET(SERVER_HOST + '/api/lecture/offLecture'), //注销讲座
        applyLecture: new GET(SERVER_HOST + '/api/lecture/applyLecture'), //讲座申请上线
        pendingLectureList: new POST(
            SERVER_HOST + '/api/lecture/pendingLectureList'
        ), // 待审核讲座列表
        examineLecture: new GET(SERVER_HOST + '/api/lecture/examineLecture'), //审批讲座{status=1通过、2驳回}
        delLecture: new GET(SERVER_HOST + '/api/lecture/delLecture'), //删除讲座
        myVenueList: new POST(SERVER_HOST + '/api/lecture/myVenueList'), // 我的会场列表(企业管理员共享企业内所有会场
        addOrEditVenue: new POST(SERVER_HOST + '/api/lecture/addOrEditVenue'), //增加或编辑会场
        venueDetail: new GET(SERVER_HOST + '/api/lecture/venueDetail'), //会场详情
        offVenue: new GET(SERVER_HOST + '/api/lecture/offVenue'), //注销会场
        delVenue: new GET(SERVER_HOST + '/api/lecture/delVenue'), //删除会场
        pendingVenueList: new POST(
            SERVER_HOST + '/api/lecture/pendingVenueList'
        ), //待审核会场列表
        examineVenue: new GET(SERVER_HOST + '/api/lecture/examineVenue'), //审批会场{status=1通过、2驳回
        addOrEditScene: new POST(SERVER_HOST + '/api/lecture/addOrEditScene'), //增加或编辑场次，同时配置票价
        sceneList: new GET(SERVER_HOST + '/api/lecture/sceneList'), // 场次列表
        sceneDetail: new GET(SERVER_HOST + '/api/lecture/sceneDetail'), // 场次详情
        delScene: new GET(SERVER_HOST + '/api/lecture/delScene'), // 删除场次
        lockSeats: new POST(SERVER_HOST + '/api/lecture/lockSeats'), //手选锁座并生成订单
    },

    staff: {
        getOrgStaffsByOrgId: new GET(
            SERVER_HOST + '/api/staff/getOrgStaffsByOrgId'
        ), // 获取公司所有员工
        addNewStaff: new POST(SERVER_HOST + '/api/staff/addNewStaff'), //添加员工
        resetStaffPwd: new GET(SERVER_HOST + '/api/staff/resetStaffPwd'), //修改员工密码密码
        switchStaffAvailability: new GET(
            SERVER_HOST + '/api/staff/switchStaffAvailability'
        ), //更改员工的状态
        deleteStaff: new GET(SERVER_HOST + '/api/staff/deleteStaff'), //删除员工，只有在员工还没被委任为教学人员之前才可删除
    },

    class: {
        searchClass: new POST(SERVER_HOST + '/api/class/searchClass'), //分页搜索班级列表
        addNewClass: new POST(SERVER_HOST + '/api/class/addNewClass'), //添加新的班级
        checkClassId: new GET(SERVER_HOST + '/api/class/checkClassId'), //检测ClassId是否可用
        deleteClass: new GET(SERVER_HOST + '/api/class/deleteClass'), //只有在本班级没有学生，并且没有添加任课老师的情况下可删除，否则会失败
        editClass: new POST(SERVER_HOST + '/api/class/editClass'), //编辑原来的班级
        getClassDetail: new GET(SERVER_HOST + '/api/class/getClassDetail'), //获取班级详情
        getClassStaffs: new GET(SERVER_HOST + '/api/class/getClassStaffs'), //获取班级任课老师列表
        addNewClassStaff: new POST(SERVER_HOST + '/api/class/addNewClassStaff'), //给班级添加新的任课老师
        removeClassStaff: new POST(SERVER_HOST + '/api/class/removeClassStaff'), //将此老师从本班级除名
        editClassStaff: new POST(SERVER_HOST + '/api/class/editClassStaff'), //设置任课老师的权限
        getAllClassByOrgId: new GET(
            SERVER_HOST + '/api/class/getAllClassByOrgId'
        ), //获取一个组织所有的班级列表
        getExamPaperListByClassId: new GET(
            SERVER_HOST + '/api/class/getExamPaperListByClassId'
        ),
        addExamToClass: new GET(SERVER_HOST + '/api/class/addExamToClass'),
        removeOneExamFromClass: new GET(
            SERVER_HOST + '/api/class/removeOneExamFromClass'
        ),
        getClassAchiveListById: new GET(
            SERVER_HOST + '/api/class/getClassAchiveListById'
        ),
        addOrEditClassAchive: new POST(
            SERVER_HOST + '/api/class/addOrEditClassAchive'
        ),
        deleteAchiveByClassAchiveId: new GET(
            SERVER_HOST + '/api/class/deleteAchiveByClassAchiveId'
        ),
        getClassAchiveItemListById: new GET(
            SERVER_HOST + '/api/class/getClassAchiveItemListById'
        ),
        getClassAchiveDetailById: new GET(
            SERVER_HOST + '/api/class/getClassAchiveDetailById'
        ),
        addOrEditClassAchiveItem: new POST(
            SERVER_HOST + '/api/class/addOrEditClassAchiveItem'
        ),
        deleteAchiveItemByClassAchiveItemId: new GET(
            SERVER_HOST + '/api/class/deleteAchiveItemByClassAchiveItemId'
        ),
        getPersonAchiveByUidAndClassId: new GET(
            SERVER_HOST + '/api/class/getPersonAchiveByUidAndClassId'
        ),
    },

    student: {
        getMyJoinedClasses: new GET(
            SERVER_HOST + '/api/student/getMyJoinedClasses'
        ), //获取我申请加入的班级列表
        applicateJoinClass: new GET(
            SERVER_HOST + '/api/student/applicateJoinClass'
        ), //提交申请加入班级
        quitFromClass: new GET(SERVER_HOST + '/api/student/quitFromClass'), //退出班级
        getMyPartinTaskList: new GET(
            SERVER_HOST + '/api/student/getMyPartinTaskList'
        ), //获取我参与的任务列表
        getMyLearnVideoListByTaskId: new GET(
            SERVER_HOST + '/api/student/getMyLearnVideoListByTaskId'
        ), //通过任务ID获取我学习的视频列表
        applicateFreeTask: new GET(
            SERVER_HOST + '/api/student/applicateFreeTask'
        ), //申请任务免修
        getMyLearnPaperListByTaskId: new GET(
            SERVER_HOST + '/api/student/getMyLearnPaperListByTaskId'
        ), //通过任务ID获取我的试卷列表
    },

    taskadmin: {
        getMyOcuppyClasses: new GET(
            SERVER_HOST + '/api/taskadmin/getMyOcuppyClasses'
        ), //获取我任课的班级列表[任课老师调用,包括班主任与普通任课老师]
        getStudentsListByClassId: new GET(
            SERVER_HOST + '/api/taskadmin/getStudentsListByClassId'
        ), //获取班级所有学生列表
        changeStudentStatus: new POST(
            SERVER_HOST + '/api/taskadmin/changeStudentStatus'
        ), //通过或者拒绝学生加入班级[只有班主任可调用
        getMyCreatedTasks: new GET(
            SERVER_HOST + '/api/taskadmin/getMyCreatedTasks'
        ), // 获取我创建的任务列表
        createNewTask: new POST(SERVER_HOST + '/api/taskadmin/createNewTask'), //创建一个新的任务
        editTask: new POST(SERVER_HOST + '/api/taskadmin/editTask'), //编辑一个老任务
        getTaskDetailById: new GET(
            SERVER_HOST + '/api/taskadmin/getTaskDetailById'
        ), //获取一个任务详情
        getVideoListByTaskId: new GET(
            SERVER_HOST + '/api/taskadmin/getVideoListByTaskId'
        ), //通过任务ID获取视频列表
        //addItemToTask: new POST(SERVER_HOST+'/api/taskadmin/addItemToTask'), //为一个任务添加子项目
        //editItemToTask: new POST(SERVER_HOST+'/api/taskadmin/editItemToTask'), //调整子项目的顺序
        removeItemFromTask: new GET(
            SERVER_HOST + '/api/taskadmin/removeItemFromTask'
        ), //删除任务项目
        removeTaskById: new GET(SERVER_HOST + '/api/taskadmin/removeTaskById'), //根据ID删除一个任务
        getJoinStudentByTaskId: new GET(
            SERVER_HOST + '/api/taskadmin/getJoinStudentByTaskId'
        ), //获取参加某一任务的列表
        addStudentsToTask: new POST(
            SERVER_HOST + '/api/taskadmin/addStudentsToTask'
        ), //为一个任务添加多个学生
        addAllClassStudentToTask: new GET(
            SERVER_HOST + '/api/taskadmin/addAllClassStudentToTask'
        ), //添加一个班的所有审批通过的学生到本任务
        getNotJoinStudentTaskClass: new GET(
            SERVER_HOST + '/api/taskadmin/getNotJoinStudentTaskClass'
        ), //获取一个班中未参加此任务的学生列表
        examFreeWork: new POST(SERVER_HOST + '/api/taskadmin/examFreeWork'), //审批[通过/拒绝]免修申请
        removeStudentFromTask: new GET(
            SERVER_HOST + '/api/taskadmin/removeStudentFromTask'
        ), //从一个任务中移除学员
        getPaperListByTaskId: new GET(
            SERVER_HOST + '/api/taskadmin/getPaperListByTaskId'
        ), //获取一个任务的所有试卷
        changeItemIndexWithStep: new POST(
            SERVER_HOST + '/api/taskadmin/changeItemIndexWithStep'
        ), //调整顺序
        batchRemoveStudentsFromTask: new POST(
            SERVER_HOST + '/api/taskadmin/batchRemoveStudentsFromTask'
        ), //批量
        batchAddDataToTask: new POST(
            SERVER_HOST + '/api/taskadmin/batchAddDataToTask'
        ), //给一个项目批量添加
        batchRemovDataToTask: new POST(
            SERVER_HOST + '/api/taskadmin/batchRemovDataToTask'
        ), //批量删除
        addSeriesToTask: new GET(
            SERVER_HOST + '/api/taskadmin/addSeriesToTask'
        ), //添加系列到任务
        updateTaskPercentByTaskId: new GET(
            SERVER_HOST + '/api/taskadmin/updateTaskPercentByTaskId'
        ), //手动更新进度
        deleteAllTaskUserByTaskId: new GET(
            SERVER_HOST + '/api/taskadmin/deleteAllTaskUserByTaskId'
        ), //批量删除所有学员
        addAllMyAdminClassToTask: new GET(
            SERVER_HOST + '/api/taskadmin/addAllMyAdminClassToTask'
        ), //添加我所管理的所有班级学生到任务
        deleteAllStudentsFromTaskByClassId: new GET(
            SERVER_HOST + '/api/taskadmin/deleteAllStudentsFromTaskByClassId'
        ), //从任务中删除一个班级所有的学生
    },

    exam: {
        getMyCreatePaperList: new GET(
            SERVER_HOST + '/api/exam/getMyCreatePaperList'
        ), //获取我创建的试卷列表
        addOrEditPaper: new POST(SERVER_HOST + '/api/exam/addOrEditPaper'), //添加或者编辑试卷封面
        removePaperById: new GET(SERVER_HOST + '/api/exam/removePaperById'), //删除一张试卷
        sumbitToExam: new GET(SERVER_HOST + '/api/exam/sumbitToExam'), //提交审核
        addOrEditQuestion: new POST(
            SERVER_HOST + '/api/exam/addOrEditQuestion'
        ), //添加或者编辑一道题
        addOrEditChoice: new POST(SERVER_HOST + '/api/exam/addOrEditChoice'), //添加或者编辑一个选项
        getPaperDetailByPaperId: new GET(
            SERVER_HOST + '/api/exam/getPaperDetailByPaperId'
        ), //获取一份试卷的详情
        removeChoiceById: new GET(SERVER_HOST + '/api/exam/removeChoiceById'), //删除一个选项
        removeQuestionById: new GET(
            SERVER_HOST + '/api/exam/removeQuestionById'
        ), //删除一道题目
        searchExamPaper: new POST(SERVER_HOST + '/api/exam/searchExamPaper'), //分页搜索试卷列表
        getAnswerDetailByPaperId: new GET(
            SERVER_HOST + '/api/exam/getAnswerDetailByPaperId'
        ), //获取我答此题详情
        addOrEditAnser: new POST(SERVER_HOST + '/api/exam/addOrEditAnser'), //添加或者编辑一道题的答案
        examExamPaper: new POST(SERVER_HOST + '/api/exam/examExamPaper'), //审核试卷
        sumbitAnswer: new GET(SERVER_HOST + '/api/exam/sumbitAnswer'), //交卷
        searchExamAnswer: new POST(SERVER_HOST + '/api/exam/searchExamAnswer'), //分页搜索答案
        judgeAnswer: new POST(SERVER_HOST + '/api/exam/judgeAnswer'), //判卷
        getMyAnswerRecord: new GET(SERVER_HOST + '/api/exam/getMyAnswerRecord'), //我的答卷列表
        batchImportQuestions: new POST(
            SERVER_HOST + '/api/exam/batchImportQuestions'
        ), //批量导入问题
        clearExamPaperQuestions: new GET(
            SERVER_HOST + '/api/exam/clearExamPaperQuestions'
        ), //清空试题
        copyFromOtherPaper: new POST(
            SERVER_HOST + '/api/exam/copyFromOtherPaper'
        ), //从其它试题拷贝试题到本试题
    },

    qa: {
        getMyCreatedTopicList: new GET(
            SERVER_HOST + '/api/qa/getMyCreatedTopicList'
        ), //获取我创建的问题列表
        addTopic: new POST(SERVER_HOST + '/api/qa/addTopic'), //发起一次提问
        getMyTeachers: new GET(SERVER_HOST + '/api/qa/getMyTeachers'), //获取我所有的任课老师列表
        getQaDetailByTopicId: new GET(
            SERVER_HOST + '/api/qa/getQaDetailByTopicId'
        ), //获取一个QA详情
        addFollow: new POST(SERVER_HOST + '/api/qa/addFollow'), //回答提问或者跟帖
        pageGetFollowmeTopicList: new POST(
            SERVER_HOST + '/api/qa/pageGetFollowmeTopicList'
        ), //获取向我的提问列表
    },

    statics: {
        featchExamClassStatics: new GET(
            SERVER_HOST + '/api/statics/featchExamClassStatics'
        ),
        fetchPersonalStatics: new GET(
            SERVER_HOST + '/api/statics/fetchPersonalStatics'
        ), //获取个人学习信息
        getPersonalStaticsByClassId: new GET(
            SERVER_HOST + '/api/statics/getPersonalStaticsByClassId'
        ), //根据classId求取人员单独统计
        updatePersonalStaticDataByClassId: new GET(
            SERVER_HOST + '/api/statics/updatePersonalStaticDataByClassId'
        ), //手动更新全班成绩
        getOrgStaticsList: new POST(
            SERVER_HOST + '/api/statics/getOrgStaticsList'
        ), //指定时间段搜索org统计列表
        getSysStaticsList: new POST(
            SERVER_HOST + '/api/statics/getSysStaticsList'
        ),
        fetchPersonalSnapshots: new GET(
            SERVER_HOST + '/api/statics/fetchPersonalSnapshots'
        ),
        getClassLearnStaticByCondition:new POST(
            SERVER_HOST + '/api/statics/getClassLearnStaticByCondition'
        ),
        getMtClassYearMonthTotal:new POST(
            SERVER_HOST + '/api/statics/getMtClassYearMonthTotal'
        ),
        getMtClassYearMonthHistory:new GET(
            SERVER_HOST + '/api/statics/getMtClassYearMonthHistory'
        ),
        getMtClassStaticDataRange:new POST(
            SERVER_HOST + '/api/statics/getMtClassStaticDataRange'
        ),
        pageSearchMtask:new POST(
            SERVER_HOST + '/api/statics/pageSearchMtask'
        ),//分页搜索任务
    },

    cache: {
        userInfo: new Storage('userInfoDimension'),
    },

    news: {
        addOrEditNews: new POST(SERVER_HOST + '/api/news/addOrEditNews'), //添加或者编辑新闻
        pageSearchNews: new POST(SERVER_HOST + '/api/news/pageSearchNews'), //分页搜索新闻
        getNewsDetail: new GET(SERVER_HOST + '/api/news/getNewsDetail'), //获取新闻详情
        addNewsOKNg: new POST(SERVER_HOST + '/api/news/addNewsOKNg'), //给新闻点赞或者点臭鸡蛋
        addNewsComment: new POST(SERVER_HOST + '/api/news/addNewsComment'), //给新闻添加评论
        pageSearchNewsComment: new POST(
            SERVER_HOST + '/api/news/pageSearchNewsComment'
        ), //分页搜索评论
        examComment: new GET(SERVER_HOST + '/api/news/examComment'), //审核评论
        addCommentOKNg: new POST(SERVER_HOST + '/api/news/addCommentOKNg'), //给评论点赞或者点臭鸡蛋
        addCommentComment: new POST(
            SERVER_HOST + '/api/news/addCommentComment'
        ), //给评论点赞或者点臭鸡蛋
        pageSeachNewsHistory: new POST(
            SERVER_HOST + '/api/news/pageSeachNewsHistory'
        ), //分页搜索我的浏览历史
    },

    cert:{
        addCert: new POST(SERVER_HOST + '/api/cert/addCert'), //添加证书
        editCert: new POST(SERVER_HOST + '/api/cert/editCert'), //编辑证书
        deleteCert: new GET(SERVER_HOST + '/api/cert/deleteCert'), //删除证书
        pageSearchCert: new POST(SERVER_HOST + '/api/cert/pageSearchCert'), //分页搜索证书
        isCertExsit:new GET(SERVER_HOST + '/api/cert/isCertExsit'), //证书编号是否存在
    },
}

export default api
