import React, { Component } from 'react';
import {Comment, List, Divider, Input, Form, Button, message} from 'antd';
import connect from "react-redux/es/connect/connect";
import api from '../../api/api';
import MyLayout from "../common/MyLayout";
import '../../assets/style/common.css';
import ask from  '../../assets/images/ask.png';
import answer from  '../../assets/images/answer.png';

const { TextArea } = Input;

class GetQaDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            qaDetail:null,
        };
    }

    getQaDetailByTopicId() {
        let topicId = this.props.match.params.topicId;
        api.qa.getQaDetailByTopicId({topicId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        qaDetail:data.data,
                    });
                }
            }
        )
    }

    handleSubmit = e => {
        e.preventDefault();
        let topicId = this.props.match.params.topicId;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.qa.addFollow(
                    {topicId, ...values},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.getQaDetailByTopicId();
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    };

    componentDidMount() {
        this.getQaDetailByTopicId();
    }

    render() {
        const {qaDetail = null} = this.state;
        let userInfo = this.props.userInfo;
        const { getFieldDecorator } = this.props.form;
        const tailFormItemLayout = {
            wrapperCol: {span: 5}
        };
        return(
            <MyLayout>
                <Comment
                    author={
                            qaDetail ?
                            <p>{qaDetail.authorName}
                                <span className="qaDetailFont">发送给</span>
                                {qaDetail.teacherName}</p>:""
                    }
                    avatar={ask}
                    datetime={qaDetail ? qaDetail.createdTime:""}
                    content={
                        <p>
                            {qaDetail ? qaDetail.content:""}
                        </p>
                    }
                >
                    <List className="comment-list"
                          header={`共${qaDetail && qaDetail.qaFollows ? qaDetail.qaFollows.length:""} 条问答`}
                          itemLayout="horizontal"
                          dataSource={qaDetail && qaDetail.qaFollows ? qaDetail.qaFollows:""}
                          renderItem={(item,index) => (
                              <li key={index}>
                                  <Comment
                                      author={item.userName}
                                      avatar={
                                          item.userName==qaDetail.authorName?
                                              ask:answer
                                      }
                                      content={<p>{item.content}</p>}
                                      datetime={item.createdTime}
                                  />
                              </li>
                          )}
                    />
                </Comment>
                <Divider />
                    <Form onSubmit={this.handleSubmit} >
                        <Form.Item label={userInfo && userInfo.role == 0?"继续提问":"解答"} hasFeedback>
                        {
                            getFieldDecorator('content', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '内容是必须输入的!'
                                    },
                                ],
                            })(
                                <TextArea rows={8}
                                          size="large"
                                          placeholder="内容是必须输入的"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit" size="large" block>
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
            </MyLayout>
        )
    }
}
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
//export default GetQaDetail;
export default connect(mapStateToProps,null)(Form.create()(GetQaDetail));
