
import React, { Component } from 'react';

import api from '../../../api/api';
import { message, Input, Col, Row, Modal, Button, Pagination, Table, Tag, PageHeader, Divider, Select } from 'antd';
import Dict from '../../../config/Dict';

class TestPaperSelectModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:[],
            keyword: "",
            visible:false,

            selectedRowKeys:[],
         };

         this.initPageSize = 8;
         this.pageNum = 1;
         this.pageSize = this.initPageSize;
         this.totalNum = 10000;
    }

    featchExamList(){
        api.exam.searchExamPaper(
            {
                "condition": {
                    "keyword":this.state.keyword,
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    componentDidMount()
    {
        this.featchExamList();
    }

    pageOnChange(pageNum,pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        this.setState({selectedRowKeys:[]})
        setTimeout(()=>this.featchExamList(), 200);
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        this.setState({selectedRowKeys:[]})
        setTimeout(()=>this.featchExamList(), 200,);
    }

    isInExcludes(item)
    {
        if(this.props.excludes)
        {
            let excludes = this.props.excludes;
            for(let i = 0; i < excludes.length; i++)
            {
                let exclude = excludes[i];
                if(item.paperId === exclude.paperId)
                {
                    return true;
                }
            }
        }
        else
        {
            return false;
        }
        return false;
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys });
    };
    
    handleAdd=()=>{
        if(this.props.handleAdd)
        {
            this.props.handleAdd(this.state.selectedRowKeys);
            setTimeout(()=>{
                this.setState({selectedRowKeys:[]})
            },100)
        }
    };

    render() {

        const columns = [
            {
                title: '试卷题目',
                dataIndex: 'title',
                key: 'title',
                className:"tableOverflow",
            },
            {
                title: ' 试卷类型',
                dataIndex: 'examType',
                key: 'examType',
                render: (text, record) => (
                    <Tag color={text==0?"#87d068":"#2db7f5"}>
                        {
                            Dict.getValue('examType',text,'')
                        }
                    </Tag>
                ),
            },
            {
                title: '考试时间',
                dataIndex: 'kssj',
                key: 'kssj',
                className:"tableOverflow",
                render: (text, record) => (
                    <div>
                    <span>{record.startTime?record.startTime:'不定'}</span>
                    -
                    <span>{record.endTime?record.endTime:'不定'}</span>
                </div>
                )
            },
            {
                title: '考试时长(分钟)',
                dataIndex: 'duration',
                key: 'duration',
            },
            {
                title: '创建日期',
                dataIndex: 'createTime',
                key: 'createTime',
                render: (text, record) => (
                    text.substring(0,10)
                ),
            }
        ];

        let {selectedRowKeys} = this.state;
        const hasSelected = selectedRowKeys.length > 0;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps:(record)=>{
                if(this.isInExcludes(record))
                {
                    return {disabled:true};
                }
                else
                {
                    return null;
                }
            }
        };

        return (
            <>
            <Button
                type='primary'
                onClick={()=>{
                    this.setState({visible:true});
                }}
                >
                    添加试卷
                </Button>
            <Modal
            visible={this.state.visible}
            onCancel={
                ()=>
                this.setState({visible:false})
            }
            footer={null}
            width={800}
            >
                <Row gutter={16}>
                        <Col span={7} ></Col>
                        <Col span={7} >
                            <span>
                                <Input value={this.state.keyword}
                                       placeholder='请输入关键字'
                                       onChange={(e)=>{
                                           this.setState({keyword:e.target.value})
                                       }}
                                />
                            </span>
                        </Col>
                        <Col span={3} >
                            <div>
                                <Button type='primary'
                                        onClick={()=>this.research()}>
                                    搜索</Button>
                            </div>
                        </Col>
                </Row>
                <Divider/>
                    <Button type="primary" 
                    onClick={this.handleAdd} 
                    disabled={!hasSelected}>
                        添加
                    </Button>
                    <span style={{ marginLeft: 8 }}>
                        {hasSelected ? `共选中 ${selectedRowKeys.length}` : ''}
                    </span>
                <Divider/>
                <Table
                        rowSelection={rowSelection}
                        rowKey='paperId'
                        pagination={false}
                        dataSource={this.state.data}
                        columns={columns}
                    />
                <Divider/>
                <Pagination
                        onChange={(pageNum,pageSize)=>this.pageOnChange(
                            pageNum,pageSize
                        )}
                        pageSize={this.pageSize}
                        current={this.pageNum}
                        total={this.totalNum}
                /> 
            </Modal>
            </>
        );
    }
}

export default TestPaperSelectModal;

