import {
  Card,
  Col,
  Divider,
  Icon,
  Input,
  List,
  Pagination,
  Row,
  Tooltip,
} from "antd";
import React from "react";
import api from "../../../api/api";
import ComFunction from "../../../api/ComFunction";
import RoutePath from "../../../config/RoutePath";
import MyLayout from "../../common/MyLayout";
import ClassificationSelect from "./ClassificationSelect";

const { Search } = Input;
const { Meta } = Card;

class NewsInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      keyword: "",
      totalNum: null,
      newsClassification: null,
      maxClassification: null,
      minClassification: null,
    };
    this.initPageSize = 8;
    this.pageNum = 1;
    this.pageSize = this.initPageSize;
  }

  fetchNewsList = () => {
    let newsParams = {
      condition: {
        keyword: this.state.keyword,
        newsClassification: this.state.newsClassification,
        minClassification: this.state.minClassification,
        maxClassification: this.state.maxClassification,
      },
      pageNum: this.pageNum,
      pageSize: this.pageSize,
    };

    api.news.pageSearchNews(newsParams, (state, data) => {
      if (state && data.status === 200) {
        this.setState({
          data: data.data,
          totalNum: data.totalNum,
        });
      }
    });
  };

  componentDidMount() {
    this.fetchNewsList();
  }

  pageOnChange = (pageNum, pageSize) => {
    this.pageNum = pageNum;
    this.pageSize = pageSize;
    setTimeout(() => this.fetchNewsList(), 200);
  };

  research = () => {
    this.pageNum = 1;
    this.pageSize = this.initPageSize;
    setTimeout(() => this.fetchNewsList(), 200);
  };

  render() {
    let history = this.props.history;
    return (
      <MyLayout>
        <Row>
          <Col span={6}> </Col>
          <Col span={8}>
            <Search
              placeholder="请输入关键字"
              enterButton="搜索"
              size="large"
              onSearch={(keyword) => {
                this.setState({ keyword });
                this.research();
              }}
            />
          </Col>
          <Col span={8}> </Col>
        </Row>
        <Divider />

        <ClassificationSelect
          onChange={(e) => {
            if (e !== null && e.length === 3) {
              this.setState({
                minClassification: e + "000",
                maxClassification: parseInt(e) + 1 + "000",
              });
            } else {
              this.setState({
                newsClassification: e,
                minClassification: null,
                maxClassification: null,
              });
            }
            this.research();
          }}
        />

        <Divider />
        <List
          grid={{
            gutter: 20,
            column: 4,
          }}
          itemLayout="vertical"
          size="large"
          dataSource={this.state.data}
          renderItem={(item, index) => (
            <List.Item key={index}>
              <Card
                hoverable
                cover={
                  <img
                    alt="封面图片"
                    style={{ height: "170px" }}
                    src={item.newsCoverpath}
                  />
                }
                onClick={() =>
                  history.push(RoutePath.NewsDetail + "/" + item.newsId)
                }
              >
                <Meta
                  title={item.newsTitle}
                  style={{ height: "100px" }}
                  description={[
                    <div className="multipleTextOverflow" key={"des:" + index}>
                      <Tooltip title={item.newsDesc}>{item.newsDesc}</Tooltip>
                    </div>,
                  ]}
                />
                <Row>
                  <Col span={10}>
                    <div className="home-PriceValue">{item.newsAuthor?item.newsAuthor:'佚名'}</div>
                  </Col>
                  <Col
                    span={14}
                    style={{
                      color: "#b7b7b7",
                      paddingTop: "10px",
                    }}
                  >
                    <span style={{ marginRight: 15 }}>
                      <Icon type="like" style={{ marginRight: 5 }} />
                      {item.newsOks}
                    </span>
                    <span>
                      <Icon type="dislike" style={{ marginRight: 5 }} />
                      {item.newsNgs}
                    </span>
                    <span style={{ marginLeft: 15 }}>
                      阅读数&nbsp;{" "}
                      {ComFunction.getVisitedTimesDisplayText(
                        item.newsVisitedTimes
                      )}
                    </span>
                  </Col>
                </Row>
              </Card>
            </List.Item>
          )}
        />
        <Pagination
          onChange={(pageNum, pageSize) => this.pageOnChange(pageNum, pageSize)}
          pageSize={this.pageSize}
          current={this.pageNum}
          total={this.state.totalNum}
        />
      </MyLayout>
    );
  }
}

export default NewsInformation;
