import React, { Component } from 'react';
import { Cascader, message, Form, Icon, Input, Button, Radio, Checkbox, Row, Col, Modal, Select, DatePicker, Typography } from 'antd';
import DataValidate from '../../config/DataValidate'
import api from '../../api/api';
import { connect } from 'react-redux';
import RegistProtocalModal from './RegistProtocalModal';
import PraviteProtocalModal from "./PraviteProtocalModal";
import Background from "../../assets/images/login.jpg";
import SimpleDateShowTime from '../../components/SimpleDateShowTime';
import SimpleDateSel from "../../components/SimpleDateSel";
import AdressData from '../../config/AdressData';
import Action from '../../actions/Action';

const { Option } = Select;

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imgenum: 1000,
            password: '',
            visible: false,
            imagecode: null,//动态码
            isMore: false,
        };
    }

    getParam(name) {
        var search = this.props.location.search;
        if (search == "") {
            return null;
        }

        var query = search.substring(1);
        var list = query.split('&');
        var item = [];
        for (var i = 0; i < list.length; i++) {
            item = list[i].split('=');
            if (item[0] == name) {
                return item[1];
            }
        }
        return null;
    }


    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let wxkey = this.getParam('wxkey');
                let token = this.getParam('token');
                let pcity = null;
                if (values.pcity) {
                    pcity = values.pcity[1];
                }

                api.user.register(
                    { ...values, wxkey, token, pcity },
                    (state, data) => {
                        if (state && data.status == 200) {
                            this.props.saveUserInfoToStore(data.data);
                            this.props.history.push('/');
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    };

    refreshImage() {
        this.setState({
            imgenum: Math.ceil(Math.random() * 1000000)
        })
    }

    renderPhoneValidate(name) {
        const { getFieldDecorator } = this.props.form;
        return getFieldDecorator(name, {
            rules: [
                {
                    required: true,
                    message: '验证码必须输入!'
                },
                {
                    validator: (rule, value, callback) => {
                        if (value && value.length == 5) {
                            api.user.validateImgCode(
                                { validateCode: value },
                                function (state, data) {
                                    if (state && data.status == 200) {
                                        callback()
                                    } else {
                                        callback('error');
                                    }
                                });
                        } else {
                            callback('error');
                        }
                    },
                    message: '验证码输入错误',
                },
            ],
        })(
            <Row gutter={8}>
                <Col span={12}>
                    <Input
                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="请输入验证码"
                        onChange={(e) => {
                            this.setState(
                                {
                                    imagecode: e.target.value,
                                }
                            )
                        }}
                    />
                </Col>
                <Col span={12}>
                    <img
                        onClick={() => this.refreshImage()}
                        style={{ width: 120, height: 40 }}
                        src={'/api/user/generateCodeImg?imgnum' + this.state.imgenum} />
                </Col>
            </Row>
        );
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { form } = this.props;

        const phoneCodeValue = (
            <Button style={{ height: 22, border: 0, background: "#FAFAFA" }}
                onClick={() => {
                    const { form } = this.props;
                    if (!form.getFieldError("phone")
                        && DataValidate.isMobile(form.getFieldValue("phone"))) {
                        this.setState({ visible: true })
                    } else {
                        message.error('请检查电话')
                    }
                }}>获取验证码</Button>
        );

        const layout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
        };
        let wxkey = this.getParam('wxkey');
        let token = this.getParam('token');

        return (
            <div>
                <img className="login_Background" src={Background} />
                <p className="login_title_1">德师</p>
                <p className="login_title_2">师者，所以传道受业解惑也</p>

                <Form onSubmit={this.handleSubmit} className="login-form">

                    {
                        token ?
                            <Form.Item>
                                <Typography.Title level={4} strong type='danger'>欢迎来自工匠平台的用户，请先登录或者注册</Typography.Title>
                            </Form.Item>
                            : null
                    }

                    {
                        wxkey ?
                            <Form.Item>
                                <Typography.Title level={4} strong type='danger'>该用户尚未绑定任何用户，请先登录绑定或者注册</Typography.Title>
                            </Form.Item>
                            : null
                    }
                    <Form.Item>
                        {
                            getFieldDecorator('role', {
                                initialValue: 0,
                                rules: [
                                    {
                                        required: true,
                                        message: '角色是必选的!'
                                    },
                                ],
                            })(
                                <Radio.Group onChange={(e) => {
                                    this.setState({ role: e.target.value })
                                }}>
                                    <Radio value={0}>个人用户</Radio>
                                    <Radio value={1}>企业用户</Radio>
                                    {<Radio value={3}>三方平台</Radio>}
                                </Radio.Group>)
                        }
                    </Form.Item>
                    <Form.Item hasFeedback>
                        {
                            getFieldDecorator("phone", {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus: 'error',
                                        message: '电话号码必须输入!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (DataValidate.isMobile(value)) {
                                                callback();
                                            }
                                            else {
                                                callback('error');
                                            }
                                        },
                                        message: '电话格式不对',
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            api.user.basicinfocheck({
                                                "checkItem": value,
                                                "type": 1
                                            },
                                                (state, data) => {
                                                    if (state && data.status == 7000130
                                                        || data.status == 7000140) {//电话和邮箱不可注册
                                                        callback('error');
                                                    }
                                                    else {//可注册
                                                        callback();
                                                    }
                                                });
                                        },
                                        message: '电话已经注册过了！',
                                    },
                                ],
                            })(
                                <Input size="large"
                                    prefix={<Icon type="info" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="手机号"
                                />,

                            )}
                    </Form.Item>
                    <Form.Item >
                        {
                            getFieldDecorator('phoneValidateCode', {
                                rules: [
                                    {
                                        required: true,
                                        message: '验证码必须输入!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (DataValidate.isMobileCode(value)) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '验证码格式不对',
                                    },
                                ],
                            })(
                                <Input size="large"
                                    prefix={<Icon type="check" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="验证码" addonAfter={phoneCodeValue}
                                />,
                            )}
                    </Form.Item>

                    <Form.Item hasFeedback>
                        {
                            getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus: 'error',
                                        message: '密码必须输入!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (DataValidate.isPassWord(value)) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '密码格式不对(6~16位数字加字母组合)',
                                    },
                                ],
                            })(
                                <Input size="large"
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                    onChange={(e) => {
                                        this.setState({
                                            password: e.target.value,
                                        })
                                    }}
                                    placeholder="请设置6-16位密码"
                                />,
                            )}
                    </Form.Item>

                    <Form.Item size="large" hasFeedback>
                        {
                            getFieldDecorator('password2', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus: 'error',
                                        message: '第二次密码必须输入!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (DataValidate.isPassWord(value)) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '第二次密码格式不对',
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            //email 检测
                                            if (value == this.state.password) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '两次密码输入不相同',
                                    },
                                ],
                            })(
                                <Input size="large"
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                    placeholder="请再次输入6-16位密码"
                                />,
                            )}
                    </Form.Item>
                    <Form.Item hasFeedback>
                        {
                            getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus: 'error',
                                        message: '姓名是必须输入的!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (DataValidate.isChineseName(value) || DataValidate.isEnglishName(value)) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '姓名格式不对',
                                    },
                                ],
                            })(
                                <Input size="large"
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="中文/英文姓名"
                                />,
                            )}
                    </Form.Item>
                    {
                        form.getFieldValue("role") == 0 ?
                            <>
                                <Form.Item >
                                    <Checkbox
                                        checked={this.state.isMore}
                                        onChange={
                                            (e) => {
                                                this.setState({
                                                    isMore: e.target.checked,
                                                });
                                            }
                                        }>
                                        更多报到信息
                            </Checkbox>
                                </Form.Item>
                                {
                                    this.state.isMore ?
                                        <>

                                            <Form.Item label='性别'>
                                                {
                                                    getFieldDecorator('sex', {
                                                        initialValue: 1,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '性别是必选的!'
                                                            },
                                                        ],
                                                    })(
                                                        <Radio.Group>
                                                            <Radio value={1}>男</Radio>
                                                            <Radio value={0}>女</Radio>
                                                        </Radio.Group>)
                                                }
                                            </Form.Item>
                                            <Form.Item label='身份证号'>
                                                {
                                                    getFieldDecorator('idNumber', {
                                                        validateFirst: true,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                validateStatus: 'error',
                                                                message: '身份证号是必须输入的!'
                                                            },
                                                            {
                                                                pattern: /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
                                                                message: '身份证号不满足规则!'
                                                            },
                                                            {
                                                                validator: (rule, value, callback) => {
                                                                    api.user.basicinfocheck({
                                                                        "checkItem": value,
                                                                        "type": 3
                                                                    },
                                                                        (state, data) => {
                                                                            if (state && data.status == 200) {
                                                                                callback();
                                                                            }
                                                                            else {
                                                                                callback('error');
                                                                            }
                                                                        });
                                                                },
                                                                message: '身份证号码重复或者无效！',
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            placeholder="例如:230103195607117012"
                                                        />,
                                                    )}
                                            </Form.Item>
                                            <Form.Item label='政治面貌'>
                                                {
                                                    getFieldDecorator('pnature', {
                                                        initialValue: null,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '政治面貌必须输入!'
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            placeholder="例如:党员、无党派等"
                                                        />)
                                                }
                                            </Form.Item>
                                            <Form.Item label='最高学历'>
                                                {
                                                    getFieldDecorator('pmaxEdu', {
                                                        initialValue: "其它",
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '最高学历必须输入!'
                                                            },
                                                        ],
                                                    })(
                                                        <Select>
                                                            <Option value={'小学'}>小学</Option>
                                                            <Option value={'初中'}>初中</Option>
                                                            <Option value={'高中'}>高中</Option>
                                                            <Option value={'大学'}>大学</Option>
                                                            <Option value={'其它'}>其它</Option>
                                                        </Select>)
                                                }
                                            </Form.Item>
                                            <Form.Item label='城市'>
                                                {
                                                    getFieldDecorator('pcity', {
                                                        initialValue: null,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '所在城市必须输入'
                                                            },
                                                        ],
                                                    })(
                                                        <Cascader
                                                            options={AdressData}
                                                        />)
                                                }
                                            </Form.Item>
                                            <Form.Item label='详细地址'>
                                                {
                                                    getFieldDecorator('paddr', {
                                                        initialValue: null,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '详细地址必须输入'
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            placeholder="例如:大连市华北路58号"
                                                        />)
                                                }
                                            </Form.Item>
                                            <Form.Item label='报考专业'>
                                                {
                                                    getFieldDecorator('pmajor', {
                                                        initialValue: null,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '报考专业必须输入'
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            placeholder="例如:汽车电子技术"
                                                        />)
                                                }
                                            </Form.Item>
                                            <Form.Item label='奖惩情况'>
                                                {
                                                    getFieldDecorator('prewordPunish', {
                                                        initialValue: null,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '奖励惩罚必须输入'
                                                            },
                                                        ],
                                                    })(
                                                        <Input.TextArea
                                                            placeholder="例如:2009年，荣获某某比赛1等奖"
                                                        />)
                                                }
                                            </Form.Item>
                                            <Form.Item label='工作单位'>
                                                {
                                                    getFieldDecorator('pworkUnit', {
                                                        initialValue: null,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '工作单位'
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            placeholder="例如:大连天巳集团有限公司"
                                                        />)
                                                }
                                            </Form.Item>
                                            <Form.Item label='职位'>
                                                {
                                                    getFieldDecorator('pduty', {
                                                        initialValue: null,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '职位必须填写'
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            placeholder="例如:软件工程师，待业等"
                                                        />)
                                                }
                                            </Form.Item>
                                            <Form.Item label='周末是否工作'>
                                                {
                                                    getFieldDecorator('pworkWeekend', {
                                                        initialValue: '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '周末工作情况必须填写'
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            placeholder="例如:工作，不工作等"
                                                        />)
                                                }
                                            </Form.Item>
                                            <Form.Item label='考生类别'>
                                                {
                                                    getFieldDecorator('pcategory', {
                                                        initialValue: "退伍军人",
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '类别是必选的!'
                                                            },
                                                        ],
                                                    })(
                                                        <Select>
                                                            <Option value={'农村往届'}>农村往届</Option>
                                                            <Option value={'农村应届'}>农村应届</Option>
                                                            <Option value={'城市往届'}>城市往届</Option>
                                                            <Option value={'城市应届'}>城市应届</Option>
                                                            <Option value={'农民工'}>农民工</Option>
                                                            <Option value={'退伍军人'}>退伍军人</Option>
                                                            <Option value={'下岗失业人员'}>下岗失业人员</Option>
                                                            <Option value={'新型职业农民'}>新型职业农民</Option>
                                                            <Option value={'其它'}>其它</Option>
                                                        </Select>)
                                                }
                                            </Form.Item>
                                            {
                                                form.getFieldValue("pcategory") == '退伍军人' ?
                                                    <>
                                                        <Form.Item label='退伍时间'>
                                                            {
                                                                getFieldDecorator('pretireTime', {
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: '退伍时间必选!'
                                                                        },
                                                                    ],
                                                                })(
                                                                    <SimpleDateSel />)
                                                            }
                                                        </Form.Item>
                                                        <Form.Item label="择业方式">
                                                            {
                                                                getFieldDecorator('pjoinJobType', {
                                                                    initialValue: "自主择业",
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: '择业是必选的!'
                                                                        },
                                                                    ],
                                                                })(
                                                                    <Select>
                                                                        <Option value={'自助择业'}>自助择业</Option>
                                                                        <Option value={'地方安置'}>地方安置</Option>
                                                                        <Option value={'其它'}>其它</Option>
                                                                    </Select>)
                                                            }
                                                        </Form.Item>
                                                        <Form.Item label='服役期间奖惩'>
                                                            {
                                                                getFieldDecorator('parmRewordPunish', {
                                                                    initialValue: null,
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: '服役期间奖惩必须输入'
                                                                        },
                                                                    ],
                                                                })(
                                                                    <Input.TextArea
                                                                        placeholder="例如:2009年，荣获某某比赛一等功"
                                                                    />)
                                                            }
                                                        </Form.Item>
                                                        <Form.Item label='退役部队'>
                                                            {
                                                                getFieldDecorator('pretireArm', {
                                                                    initialValue: '',
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: '退役部队情况必须填写'
                                                                        },
                                                                    ],
                                                                })(
                                                                    <Input
                                                                        placeholder="例如:陆军"
                                                                    />)
                                                            }
                                                        </Form.Item>
                                                    </> : null
                                            }
                                        </>
                                        : null
                                }
                            </>
                            : null
                    }
                    <Form.Item >
                        {
                            getFieldDecorator('agreement', {
                                valuePropName: 'checked',
                                rules: [
                                    {
                                        required: true,
                                        message: '须同意隐私条款才能注册!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (value) {
                                                callback();
                                            }
                                            else {
                                                callback('error');
                                            }
                                        },
                                        message: '须同意隐私条款才能注册!',
                                    },]
                            })(
                                <Checkbox style={{ width: "100%" }}>
                                    我接受 <PraviteProtocalModal /> 以及 <RegistProtocalModal />
                                </Checkbox>
                            )}
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit" size="large" block>
                            注册
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <div>
                            已有账号？
                            {
                                wxkey ?
                                    <a href={"#/login?wxkey=" + wxkey} >立即登录</a>
                                    :
                                    <>
                                        {
                                            token ?
                                                <a href={"#/login?token=" + token}>立即登录</a>
                                                :
                                                <a href="#/login" >立即登录</a>
                                        }
                                    </>
                            }
                        </div>
                    </Form.Item>
                </Form>
                {
                    this.state.visible ?
                        <Modal
                            title="获取手机验证码"
                            visible={this.state.visible}
                            onOk={() => {

                                let codeName = "imagecode";
                                const { form } = this.props;
                                let imagecode = form.getFieldValue(codeName);

                                if (!form.getFieldError(codeName)
                                    && imagecode) {
                                    api.user.sendPhoneKey({
                                        phone: form.getFieldValue("phone"),
                                        validatekey: imagecode,
                                    },
                                        (status, data) => {
                                            if (status && data.status == 200) {
                                                this.setState({
                                                    visible: false,
                                                });
                                                message.info("验证码发送成功");
                                            } else {
                                                message.error(data.msg);
                                            }
                                        }
                                    );
                                }
                                else {
                                    message.error("图片验证码未输入或者输入错误")
                                }

                            }}
                            onCancel={() => {
                                this.setState({
                                    visible: false
                                })
                            }}
                            okText="确认"
                            cancelText="取消"
                        >
                            <Form.Item>
                                {this.renderPhoneValidate("imagecode")}
                            </Form.Item>
                        </Modal> : null
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        saveUserInfoToStore: (data) => dispatch(
            {
                type: Action.SAVE_USER_INFO,
                data: data,
            }),
    }
}

export default connect(null, mapDispatchToProps)(Form.create()(Register));