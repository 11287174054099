import React, { Component } from 'react';
import {Card, Row, Col, List, Divider, Tooltip, Descriptions} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import '../../../assets/style/common.css';
import HomeLayout from "../../common/HomeLayout";
import CON from '../../../config/const';
import Sfs from '../../../config/Sfs';
import ComFunction from '../../../api/ComFunction';

const { Meta } = Card;

class FamousTeacherDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teacherData:null,
            videoListData:[],
            lectureListData:[],
        };
    }

    teacherDetail() {
        let teacherId = this.props.match.params.teacherId;
        api.teacher.teacherDetail({teacherId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        teacherData:data.data,
                    });
                    this.videoList(data.data.tid);
                    this.lectureList(data.data.tid);
                }
            }
        )
    }

    videoList(tid) {
        api.video.videoList(
            {
                "condition": {
                    "lectureDateEnd": "",
                    "lectureDateStart": "",
                    "lectureName": "",
                    "teacherId":tid,
                    "teacherName": "",
                    "status":null,
                    "videoKeywords": []
                },
                "pageNum": 1,
                "pageSize": 8,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        videoListData:data.data,
                    })
                }
            }
        )
    }

    lectureList(tid){
        api.lecture.lectureList(
            {
                "condition": {
                    "beforeEnd": true,
                    "endTime": "",
                    "lectureName": "",
                    "orgIds": [],
                    "startTime": "",
                    "teahcerId":tid,
                    "teahcerNames": []
                },
                "pageNum": 1,
                "pageSize": 8,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        lectureListData:data.data,
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.teacherDetail();
        //this.videoList();
        //this.lectureList();
    }

    render() {
        let {
            teacherData = null
        } = this.state;
        let history = this.props.history;
        return(
            <MyLayout>
                <div className="heading">
                    <div className="heading_title">{teacherData!= null?teacherData.name:""}</div>
                    <div className="heading_content">

                        <div className="heading_img">
                            {teacherData!= null && teacherData.photoPath != null ? <img
                                width={220}
                                src={teacherData.photoPath}
                            />:"未上传"}</div>
                        <div className="heading_mes">
                            <Descriptions bordered={true} column={1}>
                                <Descriptions.item label='讲师姓名'>
                                     {teacherData!= null?teacherData.name:""}
                                </Descriptions.item>
                                <Descriptions.item label='所属组织'>
                                   {teacherData!= null?
                                    <a onClick={()=>
                                        history.push('/Personal/OrgDetailsPersonal'+'/'+teacherData.org.orgId)}>
                                        {teacherData.org.orgName}
                                    </a>:"无"}
                                </Descriptions.item>
                                <Descriptions.item label='所属领域'>
                                {teacherData!= null?teacherData.field.name:"无"}
                                </Descriptions.item>
                                <Descriptions.item label='专业成就'>
                                         {ComFunction.getMultiLineNode(teacherData!= null?teacherData.achievement:null)}
                                </Descriptions.item>
                            </Descriptions>
                        </div>
                    </div>
                </div>
                <Divider />
                <Row style={{marginTop: "45px", marginBottom: "20px",display:'flex'}}>
                    <Col span={22}>
                        <div style={{fontSize:"24px"}}>关联视频</div>
                    </Col>
                    <Col span={2} style={{top:"20px"}}>
                        <a onClick={()=>
                            history.push('/Personal/moreVideoList')
                        }>查看更多视频</a>
                    </Col>
                </Row>
                <List
                    grid={{ gutter: 20, column: 4 }}
                    itemLayout="vertical"
                    size="large"
                    dataSource={this.state.videoListData}
                    renderItem={(item,index) => (
                        <List.Item key={index}>
                            <Card hoverable
                                  cover={<img alt="example" style={{height:"170px"}} src={item.posterPath} />}
                                  onClick={()=>
                                      history.push('/Personal/VideoDetail'+'/'+item.vid)
                                  }
                            >
                                <Meta title={item.name} style={{height:"110px"}} description={
                                    <div className="multipleTextOverflow">
                                        <Tooltip title= {item.intro}>
                                            {item.intro}
                                        </Tooltip>
                                    </div>
                                } />
                            </Card>
                        </List.Item>
                    )}
                />

                {!CON.IS_FREE && Sfs.isLectureOn ?<>
                <Row style={{marginTop: "45px", marginBottom: "20px",display:'flex'}}>
                    <Col span={22}>
                        <div style={{fontSize:"24px"}}>关联讲座</div>
                    </Col>
                    <Col span={2} style={{top:"20px"}}>
                        <a onClick={()=>
                            history.push('/Personal/moreLectureList')
                        }>查看更多讲座</a>
                    </Col>
                </Row>
                <List
                    grid={{ gutter: 20, column: 4 }}
                    itemLayout="vertical"
                    size="large"
                    dataSource={this.state.lectureListData}
                    renderItem={(item,index) => (
                        <List.Item
                            key={index}
                        >
                            <Card hoverable
                                  cover={<img alt="example" style={{height:"170px"}} src={item.posterPath} />}
                                  onClick={()=>
                                      history.push('/Personal/LectureSceneDetail'+'/'+item.lid)
                                  }
                                  actions={[
                                      <div>选座购票</div>
                                  ]}
                            >
                                <Meta title={item.name} style={{height:"110px"}} description={
                                    <div className="multipleTextOverflow">
                                        <Tooltip title= {item.intro}>
                                            {item.intro}
                                        </Tooltip>
                                    </div>
                                } />
                            </Card>
                        </List.Item>
                    )}
                />
                </>:null}
            </MyLayout>
        )
    }
}

export default FamousTeacherDetail;