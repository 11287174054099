import React, { Component } from 'react';
import { Table,Modal, Row, Col, List, Avatar, Divider, Button, Input, Tag, Tooltip, Select, Form, Popover, Pagination, message} from 'antd';
import api from '../../../../api/api';

import MyLayout from "../../../common/MyLayout";
import FieldSelectModal from "../../../../components/FieldSelectModal";
import OrgSelectModal from "../../../../components/OrgSelectModal";
import Dict from '../../../../config/Dict';
import ComFunction from '../../../../api/ComFunction';
const { Option } = Select;
const InputGroup = Input.Group;

const { confirm } = Modal;

class TeacherList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectType:"name",
            orgIds:[],
            name:"",
            fid:"",
            status:null,

            data:[],
            pageNum:1,
            pageSize:8,
            totalNum:9,
        };
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.myTeacherInputList(), 200);
    }

    myTeacherInputList(){
        api.teacher.myTeacherInputList(
            {
                "condition": {
                    "fid": this.state.fid,
                    "name": this.state.name,
                    "orgIds":this.state.orgIds,
                    "status":this.state.status
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:8,
            totalNum:9,
        });
        setTimeout(()=>this.myTeacherInputList(), 200,)
    }

    handleChange(data,type){
        switch (type) {
            case "org":
                this.setState({
                    orgIds:[data]
                });
                break;
            case "field":
                this.setState({
                    fid:data
                });
                break;
            default:
                break;
        }
    }

    cancellation(teacherId){
        confirm({
            title: '你确定要注销这个讲师吗?',
            content: '注销的讲师不会被删除，注销后你可以选择重新上线这个讲师。',
            onOk:()=>{
                api.teacher.offTeacher({teacherId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.myTeacherInputList();
                        }
                    }
                )
            },
        });
    }

    reOnline(tid){
        confirm({
            title: '你确定要重新上线这个讲师吗?',
            content: '重新的讲师，将进入[审批中]的状态，管理员审批通过后，讲师将重新上线。',
            onOk:()=>{
                api.teacher.addOrEditTeacher({tid},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.myTeacherInputList();
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            },
        });
    }

    componentDidMount() {
        this.myTeacherInputList();
    }

    render()
    {
        let history = this.props.history;

        const columns = [
            {
                title: '肖像',
                dataIndex: 'photoPath',
                key: 'photoPath',
                render:(text,record)=>{
                    return <Avatar src={ComFunction.getScareImgeUrl(record.photoPath,50,50)} />
                }
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render:(text,record)=>(
                    <span>{Dict.getValue('teacherStatus',record.status)}</span>
                )
            },
            {
                title: '所属组织',
                dataIndex: 'orgId',
                key: 'orgId',
                render:(text,record)=>{
                    return   <Tag color="cyan"
                    onClick={()=>history.push('/Personal/OrgDetailsPersonal'+'/'+record.org.orgId)}>
                    {record.org.orgName}
                    </Tag>
                }
            },
            {
                title: '所属领域',
                dataIndex: 'orgId',
                key: 'orgId',
                render:(text,record)=>{
                    return   <Tag color="purple">{record.field.name}</Tag>
                }
            },
            {
                title: '成就',
                dataIndex: 'achievement',
                key: 'achievement',
                className:"tableOverflow",
                render:(text,record)=>( 
                    <Tooltip
                    title={record.achievement}
                    placement="topLeft"
                    >
                         {record.achievement}
                    </Tooltip>
                    )
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                render:(text,record)=>( 
                    <>
                    <Button
                    type='primary'
                    onClick={
                        ()=>{
                            history.push('/company/TeacherDetail'+'/'+record.tid)
                        }
                    }
                    >
                    详情
                    </Button>
                    <Divider type='vertical'/>
                    {
                        record.status != 0?
                        <>
                            <Button 
                            type='danger' 
                            onClick={()=>this.cancellation(record.tid)}>
                            下线讲师
                            </Button>
                        </>:
                        <>
                            <Button 
                            type="primary" 
                            onClick={()=>this.reOnline(record.tid)}>
                            重新上线</Button>
                        </>           
                    }
                    </>
                    )
            },
        ];

        return(
            <MyLayout sider={"InfoManageSider"}>
                <div className="userEditTitle">我录入的名师列表</div>
                <Divider />
                <Form layout='inline'>
                    <Form.Item>
                        <Select 
                            style={{ width: 120 }}
                            defaultValue={this.state.status}
                            onChange={(v)=>{
                                this.setState({status:v});
                                this.research();
                            }}>
                            <Option value={null}>所有</Option>
                            <Option value={0}>已下线</Option>
                            <Option value={1}>已上线</Option>
                            <Option value={2}>审核驳回</Option>
                            <Option value={3}>审核中</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                            <Select
                                    defaultValue="name"
                                    onChange={(value)=>this.setState({selectType:value})}>
                                <Option value="name">讲师名</Option>
                                <Option value="org">所属组织</Option>
                                <Option value="field">所属领域</Option>
                            </Select>
                    </Form.Item>
                    <Form.Item>
                            <span>{(() => {
                                    switch (this.state.selectType) {
                                        case "name":
                                            return <Input 
                                                          defaultValue={this.state.name}
                                                          placeholder='请输入讲师名'
                                                          onChange={(e)=>this.setState({name:e.target.value})}
                                            />;
                                        case "org":
                                            return <OrgSelectModal userSetting={"myUser"} onChange={(data)=>this.handleChange(data,"org")}/>;
                                        case "field":
                                            return <FieldSelectModal onChange={(data)=>this.handleChange(data,"field")}/>;
                                        default:
                                            return null
                                    }
                                }
                            )()}</span>
                    </Form.Item>
                    <Form.Item>
                                <Button type='primary'
                                        icon="search"
                                        onClick={()=>this.research()}>
                                    搜索</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button
                                type="primary"
                                onClick={()=>history.push('/company/AddOrEditTeacher'+'/'+'addTeacher')}>
                            录入新的讲师</Button>
                    </Form.Item>
                </Form>
                <Divider dashed/>

                <Table
                    pagination={false}
                    rowKey='tid'
                    dataSource={this.state.data}
                    columns={columns}
                />
                <Divider dashed/>
                <Pagination
                    onChange={(pageNum,pageSize)=>this.pageOnChange(
                        pageNum,pageSize
                    )}
                    pageSize={this.state.pageSize}
                    current={this.state.pageNum}
                    total={this.state.totalNum}
                />
            </MyLayout>
        )
    }
}
export default TeacherList;