
import React from 'react';
import './App.css';
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import LoadingWapper from './views/LoadingWapper';

moment.locale('zh-cn');

function App() {
  return (
      <ConfigProvider locale={zh_CN}>
          <LoadingWapper/>
      </ConfigProvider>
  );
}
export default App;
