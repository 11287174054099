import React, { Component } from 'react';
import {Table, Button, Divider, Modal,Tag} from 'antd';
import api from '../../../../api/api';
import MyLayout from "../../../common/MyLayout";
import Dict from "../../../../config/Dict";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";

const { confirm } = Modal;

class CheckSceneOrders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:null,
        };
    }

    checkSceneOrders(){
        let sceneId = this.props.match.params.sceneId;
        api.biz.checkSceneOrders({sceneId},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    aliRefund(outTradeNo,tradeNo){
        confirm({
            title: '你确定要把这个订单的款项退给用户吗?',
            content: '退款的款项将退回到用户的支付账户中。',
            onOk:()=>{
                api.alipay.aliRefund({outTradeNo,tradeNo},
                    (state, data)=>{
                        if(state && data.status==200) {
                            Modal.success({
                                content: '退款成功！',
                            });
                            setTimeout(()=> this.checkSceneOrders(), 200,)
                        }
                    }
                )
            },
            onCancel() {
                
            },
        });

    }

    componentDidMount() {
        this.checkSceneOrders();
    }

    render() {
        let userInfo = this.props.userInfo;
        const columns = [
            {
                title: '订单号',
                key: 'outTradeNo',
                render: (text, record) => (
                    <span>
                        <a onClick={()=>{
                            //if(userInfo && userInfo.role === 0)
                           // {
                                //this.props.history.push('/Personal/OrderForm'+'/'+record.outTradeNo)
                           // }
                        }}>
                           <div style={{fontSize:"15px"}}>
                            {record.outTradeNo}
                        </div>
                        <div style={{padding:"5px",color:"#797979"}}>
                            订单日期：{record.createdTime.substring(0,16)}
                        </div>
                        </a>
                    </span>
                )
            },
            {
                title: '支付宝交易号',
                dataIndex: 'tradeNo',
                key: 'tradeNo',
                render: (text, record) => (
                    <div>{text?text:'--'}</div>
                ),
            },
            {
                title: '座位号',
                dataIndex: 'body',
                key: 'body',
            },
            {
                title: '订单金额',
                key: 'totalAmount',
                dataIndex: 'totalAmount',
                render: (text, record) => (
                    <span>
                        ￥{text/100}
                    </span>
                )
            },
            {
                title: '支付时间',
                key: 'paidTime',
                dataIndex: 'paidTime',
                render: (text, record) => (
                    <span>
                        {text ? text.substring(0,16):"未支付"}
                    </span>
                )
            },
            {
                title: '支付情况',
                dataIndex: 'tradeStatus',
                key: 'tradeStatus',
                render: (text, record) => (
                    <span>
                            <Tag color={text==1?"#87d068":"#f50"}>
                                    {Dict.getValue('tradeStatus',text,'--')}
                            </Tag>
                    </span>
                ),
            }];
        if(userInfo && userInfo.role == 5)
        {
            columns.push({
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button type='primary'
                                disabled={!record.tradeNo && record.tradeStatus!==1}
                                onClick={()=>this.aliRefund(record.outTradeNo,record.tradeNo)}>
                           退款
                        </Button>
                    </span>
                ),
            });
        }

        return(
            <MyLayout>
                <p className="userEditTitle">本场订单列表</p>
                <Divider/>
                <div>
                    <Table
                        pagination={false}
                        dataSource={this.state.data}
                        columns={columns}
                    />
                </div>
            </MyLayout>
        )
    }
}

//export default CheckSceneOrders;
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default withRouter(connect(mapStateToProps,null)(CheckSceneOrders));
