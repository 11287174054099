import React, {useState } from 'react';
import { AutoComplete } from 'antd';
const { Option } = AutoComplete;

const MajorAutoComplete=(props)=> {
    const [result, setResult] = useState([]);
    let dataSubjectSubClass = props.option;
    let optionArr = Object.entries(dataSubjectSubClass); //数据源Object转换成数组

    const handleSearch = value => {
        let res = [];
        if (!value) {
            res = [];
        } else {
            optionArr.map(data => (
                RegExp(value).exec(data[1]) == null?"":res.push(data)//遍历输入的值并进行匹配过滤
            ));
        }
        setResult(res);
    };

    const onChange = (value, option) => {
        props.onChange(option.key)//保存key
    };

    //value[0]为key value[1]为值
    const children = result.map(value => (
        <Option key={value[0]} value={value[1]}>
            {value[1]}
        </Option>
    ));

    //key转换成汉字并显示
    let majorData = props.option;
    let key = props.value;
    let value = majorData[key];

    return (
        <AutoComplete
            placeholder="专业检索"
            defaultValue={value}
            onSearch={handleSearch}
            onChange={(value, option)=>onChange(value, option)}
        >
            {children}
        </AutoComplete>
    );
};

export default MajorAutoComplete;