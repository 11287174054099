import React, { Component } from 'react';
import {Modal, Input, Button, Table, Pagination, Row, Col, Divider} from 'antd';
import api from '../../../api/api';
import majorData, {dataSubjectSubClass} from "../../../config/majorData";
import MajorAutoComplete from "../../../components/MajorAutoComplete";

class applicateJoinClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            name:"",
            classId:"",
            subjectId:"",

            data:[],
            pageNum:1,
            pageSize:7,
            totalNum:9,
        };
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.searchClass(), 200);
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:7,
            totalNum:9,
        });
        setTimeout(()=>this.searchClass(), 200,);
    }

    searchClass() {
        api.class.searchClass({
                "condition": {
                    "classId": this.state.classId,
                    "name": this.state.name,
                    "orgId": "",
                    "subjectId":this.state.subjectId
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    applicateJoinClass=(classId)=>{
        api.student.applicateJoinClass({
                "classId":classId
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.props.onChange(state);//组件向父类传值
                    this.setState({
                        visible: false,
                    });
                    Modal.success({
                        title: '提示',
                        content: '您的申请已提交,待管理员审核通过后,方可进入该班级!',
                    });
                }
                else if (data.status == 6000360){
                    Modal.error({
                        title: '提示',
                        content: '您已经申请过加入本班级，不用再申请了',
                    });
                }
            }
        )
    };

    onChange(data){
        if (data){
            this.setState({
                subjectId: data,
            });
        }
    }

    componentDidMount() {
        this.searchClass();
    }

    render() {
        const columns = [
            {
                title: '班级名称',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow",
                render: (text, record) => (
                    <a>{text}</a>
                ),
            },
            {
                title: '班级编号',
                dataIndex: 'classId',
                key: 'classId',
            },
            {
                title: '班级所属专业',
                dataIndex: 'subjectId',
                key: 'subjectId',
                render: (text, record) => (
                    <span>
                        {majorData.getValue(text,'')}
                    </span>
                ),
            },
            {
                title: '申请加入',
                key: 'applicate',
                dataIndex: 'applicate',
                render: (text, record) => (
                    <Button type="primary"
                            onClick={()=>this.applicateJoinClass(record.classId)}>
                        申请加入
                    </Button>
                ),
            }
        ];
        return(
            <div>
                <Button type="primary"
                        style={{ marginBottom: 16 }}
                        onClick={()=>{
                            this.setState({
                                visible: true,
                            })
                        }}>
                    申请加入班级
                </Button>
                <Modal
                    title="申请加入班级"
                    width={800}
                    centered={true}
                    visible={this.state.visible}
                    onCancel={()=>{
                        this.setState({
                            visible:false
                        })
                    }}
                    footer={null}
                >
                    <Row gutter={15}>
                        <Col span={7} >
                            <span>
                                <Input defaultValue={this.state.name}
                                       placeholder='请输入班级名称'
                                       onChange={(e)=>this.setState({name:e.target.value})}
                                />
                            </span>
                        </Col>
                        <Col span={7} >
                            <span>
                                <Input defaultValue={this.state.classId}
                                       placeholder='请输入班级编号'
                                       onChange={(e)=>this.setState({classId:e.target.value})}
                                />
                            </span>
                        </Col>
                        <Col span={7} >
                            <span>
                                <MajorAutoComplete 
                                    option={dataSubjectSubClass} 
                                    onChange={(data)=>this.onChange(data)}
                                />
                            </span>
                        </Col>
                        <Col span={3} >
                            <div>
                                <Button type='primary'
                                        onClick={()=>this.research()}>
                                    搜索</Button>
                            </div>
                        </Col>
                    </Row>
                    <Divider dashed/>
                    <Table
                        pagination={false}
                        dataSource={this.state.data}
                        columns={columns}
                    />
                    <div style={{paddingTop:"25px"}}>
                        <Pagination
                            onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.state.pageSize}
                            current={this.state.pageNum}
                            total={this.state.totalNum}
                        />
                    </div>
                </Modal>
            </div>
        )
    }
}
export default applicateJoinClass;