import React, { Component } from 'react';
import {Table, Divider, Button, Row, Col, Modal, message, Form} from 'antd';
import api from '../../../api/api';
import { withRouter } from 'react-router'
import connect from "react-redux/es/connect/connect";
import moment  from 'moment';

class SceneListVote extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:[],
        };
    }

    updateData() {
        let lectureId = this.props.lectureId;
        api.lecture.sceneList({lectureId},
            (state, data)=>{
                if(state && data.status==200) {
                    
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.updateData();
    }

    render() {
        let history = this.props.history;
        let userInfo = this.props.userInfo;

        const columns = [
            {
                title: '本场讲座名',
                dataIndex: 'name',
                key: 'name',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow:'ellipsis',
                            cursor:'pointer'
                        }
                    }
                },
                render:  (text, record) => (
                    <a onClick={()=>{this.props.userInfo != null ?
                        history.push('/Personal/SeatOrder'+'/'+record.id):
                        history.push('/login')}}>{text}</a>
                )
            },
            {
                title: '会场',
                dataIndex: 'vname',
                key: 'vname',
            },
            {
                title: '会场地址',
                dataIndex: 'vlocation',
                key: 'vlocation',
            },
            {
                title: '主讲人',
                dataIndex: 'tname',
                key: 'tname',
                render:  (text, record) => (
                    <a onClick={()=>{history.push('/Personal/FamousTeacherDetail'+'/'+record.tid)}}>{text}</a>
                )
            },
            {
                title: '开始时间',
                dataIndex: 'startTime',
                key: 'startTime',
                render:  (text, record) => (
                    <div>{text.substring(0,16)}</div>
                )
            },
            {
                title: '结束时间',
                dataIndex: 'endTime',
                key: 'endTime',
                render:  (text, record) => (
                    <div>{text.substring(0,16)}</div>
                )
            },
        ];
        
        if(userInfo==null || userInfo.role === 0)
        {
            columns.push({
                title: '操作',
                key: 'action',
                render: (text, record) => (
                    <span>
                        {
                            //moment(new Date()).isBefore(moment(record.startTime).add(1, 'days'))>0
                            new Date()>new Date(record.startTime.replace("-","/"))
                                ?
                                <div style={{color:"#f42429"}}>售票结束</div>
                                :
                                <Button type='primary'
                                        onClick={()=>{this.props.userInfo != null ?
                                            history.push('/Personal/SeatOrder'+'/'+record.id):
                                            history.push('/login')}}>
                                    选座购票
                                </Button>
                        }
                    </span>
                ),
            });
        };

        return(
            <div>
                <Divider/>
                <Row>
                    <Col span={21}>
                        <p className="userEditTitle">讲座场次</p>
                    </Col>
                </Row>
                <Table
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
            </div>
        )
    }
}
//export default withRouter(SceneListVote);
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default withRouter(connect(mapStateToProps,null)(SceneListVote));
