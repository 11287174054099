import React,{ Component } from "react";

import { Upload, Icon, message,Progress } from 'antd';
import {authtoken} from "../api/utils/Request";

function beforeUpload(file) {
    //const isJpgOrPng = file.type === 'video/mp4' || file.type === 'image/avi';
    const isJpgOrPng = file.type === 'video/mp4' ;
    if (!isJpgOrPng) {
        message.error('你只能上传.mp4类型的视频!');
    }
    //const isLt2M = file.size / 1024 / 1024 < 20;
    //if (!isLt2M) {
        //message.error('必须小于20M');
    //}
    //return isJpgOrPng && isLt2M;
    return isJpgOrPng;
}

class VideoUpload extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
			percent: 0,
        };
    }

    handleChange = info => {
		
		if(info.event)
		{
			this.setState({percent:info.event.percent});
		}
		
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {

            let fileId = info.file.response.data;
            if(this.props.onChange)
            {
                this.props.onChange(fileId);
            }
            this.setState({ loading: false });
            //getBase64(info.file.originFileObj, videoUrl =>{
            // this.setState({
            //   videoUrl,
            //   loading: false,
            // })},
            //);
        }
    };

    render() {
		let videoUrl = this.props.value;
		let percent = Math.ceil(this.state.percent);
		if(videoUrl && !this.state.loading)
		{
			percent = 100;
		}
		
        const uploadButton = (
            <div style={{width:300,height:20}}>
				{this.state.loading || this.state.percent > 0 || videoUrl?
				    <>
					<Progress percent={percent}/>
					{
						percent == 100?<div>文件上传成功!</div>:null
					}
					</>
					:
					<>
					  <Icon type='plus'/>
					  <div className="ant-upload-text">上传</div>
					</>
				}

            </div>
        );     
        return (
            <Upload
                name="upfile"
                listType="picture-card"
                //className="avatar-uploader"
                showUploadList={false}
                action="/api/authc/upload"
                beforeUpload={beforeUpload}
                onChange={this.handleChange}
                headers={{authtoken}}
            >
                {uploadButton}
            </Upload>
        );
    }
}

export default VideoUpload;