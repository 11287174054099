import React, { Component } from 'react';
import {Col, Row, Form, Table, Divider, Tag, PageHeader, Button, Modal, message} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import majorData from "../../../config/majorData";
import Dict from "../../../config/Dict";
import ApplicateJoinClass from "./applicateJoinClass";

class GetMyJoinedClasses extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:[]
        };
    }

    getMyJoinedClasses() {
        api.student.getMyJoinedClasses("",
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }


    quitFromClass(classId)
    {
        Modal.confirm({
            title:"真的要退出本班级?",
            onOk:()=>{
                api.student.quitFromClass(
                    {classId},
                    (state,data)=>{
                        if(state && data.status === 200) {
                            this.getMyJoinedClasses();
                        }
                        else{
                            message.error(data.msg);
                        }
                    }
                )
            }
        })
        
    }

    onChange(ar){
        if (ar){
            this.getMyJoinedClasses();
        }
    }

    componentDidMount() {
        this.getMyJoinedClasses();
    }

    render() {
        let history = this.props.history;
        const columns = [
            {
                title: '班级名称',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow",
                render: (text, record) => (
                    <a>{text}</a>
                ),
            },
            {
                title: '班级编号',
                dataIndex: 'classId',
                key: 'classId',
            },
            {
                title: '班级所属专业',
                dataIndex: 'subjectId',
                key: 'subjectId',
                render: (text, record) => (
                    <span>
                        {majorData.getValue(text,'')}
                    </span>
                ),
            },
            {
                title: '申请日期',
                dataIndex: 'createdTime',
                key: 'createdTime',
            },
            {
                title: '审核状态',
                key: 'status',
                dataIndex: 'status',
                render: (text, record) => (
                    <Tag color={text==0?"#2db7f5":text==1?"#87d068":"#f50"}>
                        {
                            Dict.getValue('student',text,'')
                        }
                    </Tag>
                ),
            },
            {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Button 
                            disabled={record.status == 1}
                            type='danger'
                            onClick={()=>this.quitFromClass(record.classId)}>退出班级</Button>
                    </span>
                ),
            },
        ];
        return(
            <MyLayout>
                <PageHeader
                    style={{padding:"0"}}
                    title="班级列表"
                    subTitle="我申的班级列表"
                />
                <Divider />
                <ApplicateJoinClass  onChange={(ar)=>this.onChange(ar)}/>
                <Divider dashed/>
                <Table
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
            </MyLayout>
        )
    }
}
export default Form.create()(GetMyJoinedClasses);