import React, { Component } from 'react';
import {Modal, Button, Icon, Input, Form, message} from 'antd';
import api from "../../../api/api";
import DataValidate from "../../../config/DataValidate";


class EditMyPwd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password:"",
            visible: false,
        };
    }

    resetSuccess=(pwd)=>{
        Modal.info({
            title: '修改密码',
            content: (
                <div>
                    <p>密码修改成功,请妥善保存！</p>
                </div>
            ),
            onOk() {},
        });
    };

    handleOk = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.user.editMyPwd(
                    {...values},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.setState({
                                visible: false,
                            });
                            this.resetSuccess(data.data);
                        } else {
                            message.error('error');
                        }
                    }
                )
            }
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Button type="link" onClick={()=>this.setState({visible: true})}>
                    {this.props.btnName}
                </Button>
                <Modal
                    title="密码修改"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Form>
                        <Form.Item>
                            {getFieldDecorator('pwdold', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '请输入原密码!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(DataValidate.isPassWord(value)) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '密码格式不对(6~16位数字加字母组合)',
                                    },
                                ],
                            })(
                                <Input size="large"
                                       prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                       placeholder="原密码"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('pwdnew', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '要修改的密码是必须输入的!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(DataValidate.isPassWord(value)) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '密码格式不对(6~16位数字加字母组合)',
                                    },
                                ],
                            })(
                                <Input size="large"
                                       prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                       onChange={(e)=>{
                                           this.setState({
                                               password:e.target.value,
                                           })
                                       }}
                                       placeholder="要修改的密码"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item  size="large" hasFeedback>
                            {getFieldDecorator('pwdnew2', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '第二次密码是必须输入的!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(DataValidate.isPassWord(value)) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '第二次输入的密码格式不对！',
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            //email 检测
                                            if(value == this.state.password) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '两次密码输入不相同!',
                                    },
                                ],
                            })(
                                <Input size="large"
                                       prefix={<Icon   style={{ color: 'rgba(0,0,0,.25)' }} />}
                                       type="password"
                                       placeholder="请再次输入8-16位密码"
                                />,
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}
export default Form.create()(EditMyPwd);