import React, { Component } from 'react';
import {message, Form, Icon, Select, Input, Button} from 'antd';
import api from '../../../../api/api';
import ImageUpload from '../../../../components/ImageUpload';
import MyLayout from "../../../common/MyLayout";
import FieldSelectModal from "../../../../components/FieldSelectModal";
import OrgSelectModal from "../../../../components/OrgSelectModal";

const { TextArea } = Input;

class AddOrEditTeacher extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teacherData:null,
        };
    }

    addOrEditTeacher(values){
        let tid = this.achieveParams();

        api.teacher.addOrEditTeacher(
            {tid, ...values},

            (state,data)=>{
                if(state && data.status == 200) {
                    this.props.history.push('/company/TeacherList');
                } else {
                    message.error(data.msg);
                }
            }
        )
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.addOrEditTeacher(values);
            }
        });
    };


    teacherDetail(teacherId) {
        api.teacher.teacherDetail({teacherId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        teacherData:data.data,
                    })
                }
            }
        )
    }

    achieveParams(){
        let {teacherId} = this.props.match.params;
        if (teacherId && teacherId =="addTeacher"){
            teacherId = "";
        }
        return teacherId;
    }

    componentDidMount() {
        if (this.achieveParams() && this.achieveParams() !="" ){
            this.teacherDetail(this.achieveParams());
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            teacherData= null,
        } = this.state;

        const formItemLayout = {
            labelCol: {
                sm: { span: 8 },
            },
            wrapperCol: {
                sm: { span: 10},
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                sm: {
                    span: 10,
                    offset: 8,
                },
            },
        };
        return(
            <MyLayout>
                <div style={{marginTop:'60px'}}>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit} >
                        <Form.Item label='讲师姓名' hasFeedback>
                            {getFieldDecorator('name', {
                                initialValue:teacherData?teacherData.name:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '讲师姓名是必须输入的!'
                                    },
                                ],
                            })(
                                <Input size="large"
                                       prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                       placeholder="讲师姓名"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='讲师照片'>
                            {getFieldDecorator('photoPath', {
                                initialValue:teacherData?teacherData.photoPath:'',
                                rules: [
                                    {
                                        required: false,
                                        message: '视频文件必须上传!'
                                    },

                                ],
                            })(
                                <ImageUpload/>,
                            )
                            }
                        </Form.Item>
                        <Form.Item label='所属组织'>
                            {getFieldDecorator('oid', {
                                initialValue:teacherData?teacherData.org:'',
                                rules: [
                                    {
                                        required: false,
                                        message: '所属组织是必须的!'
                                    },
                                ],
                            })(
                                <OrgSelectModal userSetting={"myUser"}/>
                            )}
                        </Form.Item>
                        <Form.Item label='所属领域'>
                            {getFieldDecorator('fid', {
                                initialValue:teacherData?teacherData.field:'',
                                rules: [
                                    {
                                        required: false,
                                        message: '所属领域是必须的!'
                                    },
                                ],
                            })(
                                <FieldSelectModal />
                            )
                            }
                        </Form.Item>
                        <Form.Item label='签名图片'>
                            {getFieldDecorator('signaturePath', {
                                initialValue:teacherData?teacherData.signaturePath:'',
                                rules: [
                                    {
                                        required: false,
                                        message: '签名图片必须上传!'
                                    },
                                ],
                            })(
                                <ImageUpload/>,
                            )
                            }
                        </Form.Item>
                        <Form.Item label='一句话格言' >
                            {getFieldDecorator('motto', {
                                initialValue:teacherData?teacherData.motto:'',
                                rules: [
                                    {
                                        required: true,
                                        message: '一句话格言必须输入的!'
                                    },
                                ],
                            })(
                                <TextArea rows={4}
                                          placeholder="一句话格言"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='专业成就' >
                            {getFieldDecorator('achievement', {
                                initialValue:teacherData?teacherData.achievement:'',
                                rules: [
                                    {
                                        required: true,
                                        message: '专业成就是必须输入的!'
                                    },
                                ],
                            })(
                                <TextArea rows={4}
                                          placeholder="特长、擅长领域取得成绩等"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit" size="large" block>
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </MyLayout>
        )
    }
}
export default Form.create()(AddOrEditTeacher);