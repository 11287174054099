import { Icon, Modal, Tooltip } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import api from '../../../api/api'
import RoutePath from '../../../config/RoutePath'

class OkOrNg extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            okNumber: props.okNumber,
            ngNumber: props.ngNumber,
            likeStatus: props.likeStatus,
        }
    }

    addNewsOKNg = okOrNg => {
        const newsId = this.props.okId
        api.news.addNewsOKNg({
            id: newsId,
            status: okOrNg,
        })
    }
    addCommentOKNg = okOrNg => {
        const commentId = this.props.okId
        api.news.addCommentOKNg({
            id: commentId,
            status: okOrNg,
        })
    }
    like = () => {

        if (this.props.userInfo == null) {
            Modal.confirm(
                {
                    title: '没有登录',
                    content: '只有登录用户才能点赞评论，登录?',
                    onOk: () => this.props.history.push(RoutePath.Login),
                    okText: '去登录',
                    onCancel: () => {},
                    cancelText: '取消',
                }
            )
            return;
        }


        if (this.state.likeStatus === 0) {
            return
        }

        let ngNumber = this.state.ngNumber - 1
        if (this.state.likeStatus === null) {
            ngNumber = this.state.ngNumber
        }

        const okType = this.props.okType
        this.setState({
            okNumber: this.state.okNumber + 1,
            ngNumber: ngNumber,
            likeStatus: 0,
        })
        okType === '0' ? this.addNewsOKNg(0) : this.addCommentOKNg(0)
    }

    dislike = () => {

        if (this.props.userInfo == null) {
            Modal.confirm(
                {
                    title: '没有登录',
                    content: '只有登录用户才能点赞评论，登录?',
                    onOk: () => this.props.history.push(RoutePath.Login),
                    okText: '去登录',
                    onCancel: () => {},
                    cancelText: '取消',
                }
            )
            return;
        }

        if (this.state.likeStatus === 1) {
            return
        }
        let okNumber = this.state.okNumber - 1
        if (this.state.likeStatus === null) {
            okNumber = this.state.okNumber
        }

        const okType = this.props.okType
        this.setState({
            okNumber: okNumber,
            ngNumber: this.state.ngNumber + 1,
            likeStatus: 1,
        })
        okType === '0' ? this.addNewsOKNg(1) : this.addCommentOKNg(1)
    }

    componentWillReceiveProps(props) {
        if (!(this.state.likeStatus === props.likeStatus)) {
            this.setState({likeStatus: props.likeStatus})
        }

        if (!(this.state.okNumber === props.okNumber)) {
            this.setState({okNumber: props.okNumber})
        }

        if (!(this.state.ngNumber === props.ngNumber)) {
            this.setState({ngNumber: props.ngNumber})
        }
    }

    render() {
        const likeStatus = this.state.likeStatus

        return (
            <>
                <span key="comment-basic-like">
                    <Tooltip title="赞">
                        <Icon
                            type="like"
                            onClick={this.like}
                            theme={likeStatus === 0 ? 'filled' : 'outlined'}
                            style={{fontSize: 15}}
                        />
                    </Tooltip>
                    <span style={{paddingLeft: 2, cursor: 'auto'}}>
                        {this.state.okNumber}
                    </span>
                </span>
                <span key="comment-basic-dislike" style={{marginLeft: 15}}>
                    <Tooltip title="踩">
                        <Icon
                            type="dislike"
                            onClick={this.dislike}
                            theme={likeStatus === 1 ? 'filled' : 'outlined'}
                            style={{fontSize: 15}}
                        />
                    </Tooltip>
                    <span style={{paddingLeft: 2, cursor: 'auto'}}>
                        {this.state.ngNumber}
                    </span>
                </span>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}

export default  withRouter(connect(mapStateToProps, null)(OkOrNg))
