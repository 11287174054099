import React, { Component } from 'react';
import { Menu, Icon, Layout} from 'antd';
import {connect } from 'react-redux';
import {withRouter } from 'react-router'
import RoutePath from '../../../config/RoutePath';
import { GatewayOutlined, SettingOutlined, CompressOutlined, BankOutlined } from '@ant-design/icons';
import Action from '../../../actions/Action';

let {Sider} = Layout;

class UserSettingSider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //selected:""
        };
    }

    render() {
        let userInfo = this.props.userInfo;
        let history = this.props.history;
        let pathname = this.props.location.pathname;
        return (
            <div>
                <Sider style={{
                    height: '50vh',
                    left: 0,
                    background: '#f0f2f5',
                    paddingTop:2,
                    marginRight: 10
                }}>
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[pathname]}
                        style={{ height: '100%', borderRight: 0 }}
                    >

                        <Menu.Item  key='/userSetting'
                                    onClick={()=>history.push('/userSetting')}>
                            <Icon type="info-circle" />
                            <span>基本信息</span>
                        </Menu.Item>
                        <Menu.Item  key='/numberBinding'
                                    onClick={()=>history.push('/numberBinding')}>
                            <Icon type="pushpin" />
                            <span>账号绑定</span>
                        </Menu.Item>
                        <Menu.Item  key='/userInfo'
                                    onClick={()=>history.push('/userInfo')}>
                            <Icon type="setting" />
                            <span>安全设置</span>
                        </Menu.Item>
                        <Menu.Item  key={RoutePath.RealNameAuth}
                                    onClick={()=>history.push(RoutePath.RealNameAuth)}>
                            <Icon type="user" />
                            <span>实名认证</span>
                        </Menu.Item>
                        {
                            userInfo&&userInfo.role === 3 ?
                            <Menu.Item  key={RoutePath.PlateformValidate}
                                        onClick={()=>history.push(RoutePath.PlateformValidate)}>
                                <GatewayOutlined />
                                <span>三方认证</span>
                            </Menu.Item>:null
                        }
                        {
                            userInfo&&userInfo.role === 3 ?
                            <Menu.Item  key={RoutePath.PlateFormInfo}
                                        onClick={()=>history.push(RoutePath.PlateFormInfo)}>
                                <CompressOutlined />
                                <span>开发设置</span>
                            </Menu.Item>:null
                        }
                        {
                            userInfo&&userInfo.role === 1 ?
                            <Menu.Item  key={RoutePath.BankAccount}
                                        onClick={()=>history.push(RoutePath.BankAccount)}>
                                <BankOutlined />
                                <span>银行账号</span>
                            </Menu.Item>:null
                        }
                    </Menu>
                </Sider>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
        remain: state.user.remain,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetUser: (data) => dispatch(
            {
                type: Action.RESET_USER_INFO,
            }),
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(UserSettingSider));