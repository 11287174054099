import { Divider, Pagination, Table, Tooltip } from 'antd';
import React, { Component } from 'react';
import api from '../../../api/api';

class BizOrderList extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            data:[],
         };

         this.initPageSize = 8;

         this.pageNum = 1;
         this.pageSize = this.initPageSize;
         this.totalNum = 10000;
    }

    featchBizOrderList() {
        api.biz.pageSearchBizOrders(
            {
                "condition": {
                    uid:this.props.uid,
                },
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data) => {
                if (state && data.status == 200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        data: data.data,
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.featchBizOrderList();
    }

    pageOnChange(pageNum, pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(() => this.featchBizOrderList(), 200);
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        setTimeout(() => this.featchBizOrderList(), 200,);
    }

    render() {

        const columns = [
            {
                title: '订单号',
                dataIndex: 'outTradeNo',
                key: 'outTradeNo',
            },
            {
                title: '订单内容',
                dataIndex: 'body',
                key: 'body',
                className:"tableOverflow",
                render: (text, record) => (
                    <Tooltip title={text}>
                        {text}
                    </Tooltip>
                ),
            },
            {
                title: '金额(元)',
                dataIndex: 'totalAmount',
                key: 'totalAmount',
                render: (text, record) => (
                    <div>{record.totalAmount/100}</div>
                )
            },
            {
                title: '创建日期',
                dataIndex: 'createdTime',
                key: 'createdTime',
            },
            {
                title: '支付日期',
                dataIndex: 'paidTime',
                key: 'paidTime',
            },
        ];
        return (
            <>
            <Table
                        rowKey='outTradeNo'
                        pagination={false}
                        dataSource={this.state.data}
                        columns={columns}
            />
            <Divider/>
            <Pagination
                       onChange={(pageNum,pageSize)=>this.pageOnChange(
                            pageNum,pageSize
                        )}
                        pageSize={this.pageSize}
                        current={this.pageNum}
                        total={this.totalNum}
                        />
            </>
        );
    }
}

export default BizOrderList;