import React, {Component} from 'react';
import {Tabs, Radio, Col, Row} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import '../../../assets/style/common.css';
import LineChart from "../../statistic/charts/LineChart";
import moment from 'moment';

const {TabPane} = Tabs;

class GetOrgStaticsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            staticsData: [],
            activeKey: 'numClass',
            type: "numClass",

            endDate: moment().add('days', 1).format('YYYY/MM/DD 00:00:00'),
            startDate: moment().subtract('days', 6).format('YYYY/MM/DD 00:00:00')
        };
    }

    getOrgStaticsList() {
        let orgId = this.props.match.params.orgId;
        api.statics.getOrgStaticsList({
                "endDate": this.state.endDate,
                "orgId": orgId,
                "startDate": this.state.startDate
            },
            (state, data) => {
                if (state && data.status == 200) {
                    this.setState({
                        staticsData: data.data,
                    });
                }
            }
        )
    }

    getSpecifyData = (totalData, keyword) => {
        let data = [];
        var labelName = {
            "numClass": "班级数量(个)",
            "numStudent": "学生数量(人)",
            "numStaff": "老师数量(人)",
            "numVideo": "上传视频数(个)",
            "numDuration": "上传视频总时长(分)",
            "totalDurationStudentVideo": "学习总时长(分)",
            "totalTimesStudentVideo": "总次数(次)",
            "totalDurationMyvideo": "学习的时长(分)",
            "totalTimesMyvideo": "视频学习次数(次)",
            "numPaper": "试卷总数(套)",
            "totalAnswerStudentPaper": "学生答题总数(道)",
            "totalAnswerMypaper": "试卷被答总数",
            "numTask": "添加的任务总数(个)",
            "numSeries": "系列总数(个)",
        };
        for (let i = 0; i < totalData.length; i++) {
            data.push(
                {
                    time: totalData[i].addTime,
                    value: totalData[i][keyword],
                    type: labelName[keyword],
                });
        }
        return data;
    };

    onChange = e => {
        let startDate = moment().subtract('days', 6).format('YYYY/MM/DD 00:00:00');//7天前

        switch (e.target.value) {
            case 1:
                startDate = moment().subtract('days', 6).format('YYYY/MM/DD 00:00:00');//7天前
                break;
            case 2:
                startDate = moment().subtract('days', 13).format('YYYY/MM/DD 00:00:00');//14天前
                break;
            case 3:
                startDate = moment().subtract('days', 20).format('YYYY/MM/DD 00:00:00');//21天前
                break;
            case 4:
                startDate = moment().subtract('days', 29).format('YYYY/MM/DD 00:00:00');//一个月
                break
        }
        this.setState({
            value: e.target.value,
            endDate: moment().add('days', 1).format('YYYY/MM/DD 00:00:00'),
            startDate: startDate
        });
        setTimeout(() => this.getOrgStaticsList(), 200);
    };

    componentDidMount() {
        this.getOrgStaticsList();
    }

    render() {
        const {staticsData, activeKey, type} = this.state;
        let data = [];

        if (staticsData && staticsData.length > 0) {
            data = this.getSpecifyData(staticsData, activeKey);
        }

        return (
            <MyLayout>
                <Row>
                    <Radio.Group onChange={this.onChange} value={this.state.value}>
                        <Radio value={1}>前7天</Radio>
                        <Radio value={2}>前14天</Radio>
                        <Radio value={3}>前21天</Radio>
                        <Radio value={4}>前1个月</Radio>
                    </Radio.Group>
                </Row>
                <Row>
                    <Radio.Group style={{margin: 16}}
                                 onChange={(e) => {
                                     this.setState({
                                         activeKey: e.target.value,
                                         type: e.target.value
                                     })
                                 }
                                 }
                    >
                        <Radio.Button value="numClass">教师/学生统计</Radio.Button>
                        <Radio.Button value="numVideo">视频统计</Radio.Button>
                        <Radio.Button value="numPaper">试卷统计</Radio.Button>
                        <Radio.Button value="numTask">任务统计</Radio.Button>
                    </Radio.Group>
                </Row>
                {type == "numClass" ?
                    <Tabs defaultActiveKey="1"
                          onChange={(activeKey) => {
                              this.setState({
                                  activeKey
                              })
                          }}
                    >
                        <TabPane tab="班级数量" key="numClass">
                            {/*折线图 chartTyp = "Line" 面积图 chartTyp = "Area"*/}
                            <LineChart chartTyp="Area"
                                       chartName="班级数量"
                                       data={data}
                            />
                        </TabPane>
                        <TabPane tab="学生数量" key="numStudent">
                            <LineChart chartTyp="Area"
                                       chartName="学生数量"
                                       data={data}
                            />
                        </TabPane>
                        <TabPane tab="老师数量" key="numStaff">
                            <LineChart chartTyp="Area"
                                       chartName="老师数量"
                                       data={data}/>
                        </TabPane>
                    </Tabs> : type === "numVideo" ?
                        <Tabs defaultActiveKey="1"
                              onChange={(activeKey) => {
                                  this.setState({
                                      activeKey
                                  })
                              }}
                        >
                            <TabPane tab="上传视频数量" key="numVideo">
                                <LineChart chartTyp="Area"
                                           chartName="上传视频数量"
                                           data={data}/>
                            </TabPane>
                            <TabPane tab="上传视频总时长" key="numDuration">
                                <LineChart chartTyp="Area"
                                           chartName="上传视频总时长"
                                           data={data}/>
                            </TabPane>
                            <TabPane tab="学生学习总时长" key="totalDurationStudentVideo">
                                <LineChart chartTyp="Area"
                                           chartName="学生学习总时长(包括学习其它公司(学校)视频)"
                                           data={data}
                                />
                            </TabPane>
                            <TabPane tab="视频被学习时长" key="totalDurationMyvideo">
                                <LineChart chartTyp="Area"
                                           chartName="本公司(学校)视频被学习总时长"
                                           data={data}
                                />
                            </TabPane>
                            <TabPane tab="学生学习总次数" key="totalTimesStudentVideo">
                                <LineChart chartTyp="Area"
                                           chartName="学生学习总次数"
                                           data={data}
                                />
                            </TabPane>
                            <TabPane tab="上传视频学习总次数" key="totalTimesMyvideo">
                                <LineChart chartTyp="Area"
                                           chartName="上传视频学习总次数"
                                           data={data}/>
                            </TabPane>
                        </Tabs> : type === "numPaper" ?
                            <Tabs defaultActiveKey="1"
                                  onChange={(activeKey) => {
                                      this.setState({
                                          activeKey
                                      })
                                  }}
                            >
                                <TabPane tab="试卷总数" key="numPaper">
                                    <LineChart chartTyp="Area"
                                               chartName="试卷总数"
                                               data={data}/>
                                </TabPane>
                                <TabPane tab="学生答题总数" key="totalAnswerStudentPaper">
                                    <LineChart chartTyp="Area"
                                               chartName="学生答题总数(包括非本公司(学校)试题)"
                                               data={data}/>
                                </TabPane>
                                <TabPane tab="本公司(学校)试卷被答总数" key="totalAnswerMypaper">
                                    <LineChart chartTyp="Area"
                                               chartName="本公司(学校)试卷被答总数"
                                               data={data}
                                    />
                                </TabPane>
                            </Tabs> :
                            <Tabs defaultActiveKey="1"
                                  onChange={(activeKey) => {
                                      this.setState({
                                          activeKey
                                      })
                                  }}
                            >
                                <TabPane tab="添加的任务总数" key="numTask">
                                    <LineChart chartTyp="Area"
                                               chartName="添加的任务总数"
                                               data={data}
                                    />
                                </TabPane>
                                <TabPane tab="系列总数" key="numSeries">
                                    <LineChart chartTyp="Area"
                                               chartName="系列总数"
                                               data={data}
                                    />
                                </TabPane>
                            </Tabs>
                }
            </MyLayout>
        )
    }
}

export default GetOrgStaticsList;