import React, { Component } from 'react';
import {Modal, Button, Input, Form, message, Row, Col, Icon} from 'antd';
import api from "../../../api/api";
import connect from "react-redux/es/connect/connect";
import DataValidate from "../../../config/DataValidate";

class ChangePwdModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    handleOk = e => {
        let uid = this.props.UID;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.staff.resetStaffPwd({uid,...values},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.setState({
                                visible: false,
                            });
                            Modal.success({
                                content: '新密码设置成功!',
                            });
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Button type="link" onClick={()=>this.setState({visible: true})}>
                    {this.props.btnName}
                </Button>
                <Modal
                    title="密码修改"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Form>
                        <p>UID:{this.props.UID}</p>
                        <Form.Item hasFeedback>
                            {getFieldDecorator('newpwd', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '要修改的密码是必须输入的!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(DataValidate.isPassWord(value)) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '密码格式不对(6~16位数字加字母组合)',
                                    },
                                ],
                            })(
                                <Input size="large"
                                       onChange={(e)=>{
                                           this.setState({
                                               password:e.target.value,
                                           })
                                       }}
                                       placeholder="要修改的密码"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item  size="large" hasFeedback>
                            {getFieldDecorator('newpwd2', {
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '再次输入密码是必须的!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(DataValidate.isPassWord(value)) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '再次输入的密码格式不对！',
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            //email 检测
                                            if(value == this.state.password) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '两次密码输入不相同!',
                                    },
                                ],
                            })(
                                <Input size="large"
                                       placeholder="请再次输入8-16位密码"
                                />,
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default connect(mapStateToProps, null)(Form.create({ name: 'ChangePwdModal' })(ChangePwdModal));
