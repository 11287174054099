
import React, { Component } from 'react';
import { InputNumber, Button,Divider, Form, message, PageHeader, Tabs, Tag, Typography, Descriptions, Modal, Badge, Row, Col} from 'antd';
import MyLayout from '../../common/MyLayout';
import api from '../../../api/api';
import Dict from '../../../config/Dict';
import JudgeForm from './JudgeForm';
const { Paragraph } = Typography;
const { TabPane } = Tabs;
class JudgePaper extends Component {

    constructor(props) {
        super(props);
        this.state = 
        { 
            paperDetail:null,
            answerDetail:null,
            activeKey:{
                0:'0',
                1:'1000',
                2:'2000',
                3:'3000',
                4:'4000',
                5:'5000',
            },
        };
    }

    getAnswerDetailByPaperId(){
        let paperId = this.props.match.params.paperId;
        let uid = this.props.match.params.uid;
        api.exam.getAnswerDetailByPaperId(
            {paperId,uid},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        answerDetail:data.data,
                    });
                }else{
                    message.error(data.msg);
                }
            }
        )
    }

    getPaperDetailByPaperId(){
        let paperId = this.props.match.params.paperId;
        api.exam.getPaperDetailByPaperId({paperId},
            (state, data)=>{
                if(state && data.status==200) {
                    this.getAnswerDetailByPaperId();
                    this.setState({
                        paperDetail:data.data,
                    });
                }else{
                    message.error(data.msg);
                }
            }
        )
    }

    componentDidMount() {
        this.getPaperDetailByPaperId();
    }

    getAnswerByQuestionId(questionId)
    {
        let answerDetail = this.state.answerDetail;
        if(answerDetail == null)
        {
            return null;
        }
        let examAnsitems = answerDetail.examAnsitems;
        for(let i = 0; i < examAnsitems.length; i++)
        {
            let ansitem = examAnsitems[i];
            if(ansitem.questionId == questionId)
            {
                return ansitem;
            }
        }

        return null;
    }

    getTypeQuestion(type)
    {
        let paperDetail = this.state.paperDetail;
        if(paperDetail == null)
        {  
            return {
                res:[],
                needJudges:0,
            };
        }

        let questions = paperDetail.questions;

        let res = [];
        let needJudges = 0;
        for (let i = 0; i < questions.length; i++) 
        {
            if(questions[i].examQuestion.itemType == type)
            {
                let dt = questions[i];
                let answer = this.getAnswerByQuestionId(dt.questionId);
                dt.answer = answer;
                if(answer && answer.judge == 0)
                {
                    needJudges++;
                }
                res.push(dt);
            }
        }
        return {
            res:res,
            needJudges:needJudges,
        };
    }

    render() {

        let {answerDetail,paperDetail} = this.state;
        let hasSumbit = answerDetail && answerDetail.examAnswer.answerSumbit == 1;
        let hasJudge = answerDetail && answerDetail.examAnswer.judge == 1;
        let totalScore = 0;
        if(answerDetail)
        {
            totalScore +=  answerDetail.examAnswer.choiceScore;
            totalScore +=  answerDetail.examAnswer.completionScore;
            totalScore +=  answerDetail.examAnswer.shortScore;
        }

        let tabq = [
            {
                questionType: 5,
                tab: '判断题',
            },
            {
                questionType: 0,
                tab: '单选题',
            },
            {
                questionType: 1,
                tab: '多选题',
            },
            {
                questionType: 2,
                tab: '不定向选择题',
            },
            {
                questionType: 3,
                tab: '填空题',
            },
            {
                questionType: 4,
                tab: '简答题',
            }
        ];

        for(let i = 0; i < tabq.length; i++)
        {
            let dt = this.getTypeQuestion(tabq[i].questionType);
            tabq[i].questions = dt.res;
            tabq[i].needJudges = dt.needJudges;
        }

        return (
            <MyLayout>
                 <PageHeader
                    title={paperDetail?paperDetail.examPaper.title:""}
                    className="site-page-header"
                    subTitle="批卷"
                    tags={<>
                    <Tag color="blue">
                        {Dict.getValue('paperStatus', 
                        paperDetail?paperDetail.examPaper.status:"", '')}
                    </Tag>
                    
                    </>
                    }
                    extra={
                    <div style={{color:'red',fontSize:'30px'}}>
                    {totalScore}分</div>
                    }
                >
                    <div className="heading_content">
                        <div className="heading_img">
                            {paperDetail&&paperDetail.examPaper?
                                <img
                                    alt=''
                                    src={paperDetail.examPaper.coverPath}
                                    width={200}
                                    height={150}
                                />:"未上传"}
                        </div>
                        <div className="heading_mes">
                            <Descriptions size="small" column={2}>
                                <Descriptions.Item label="试卷类型">
                                    <Tag color={paperDetail&&paperDetail.examPaper.examType==0?"#87d068":"#2db7f5"}>
                                        {Dict.getValue('examType',paperDetail?paperDetail.examPaper.examType:"",'')}
                                    </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label="题数/分值">
                                    {paperDetail?
                                        paperDetail.examPaper.itemNum+'/'+paperDetail.examPaper.totalScore:""}
                                </Descriptions.Item>
                                <Descriptions.Item label="答题人数">
                                    {paperDetail?paperDetail.examPaper.answerNum:""}
                                </Descriptions.Item>
                                <Descriptions.Item label="创建日期">
                                    {paperDetail?paperDetail.examPaper.createTime:""}
                                </Descriptions.Item>
                                <Descriptions.Item label="交卷">
                                    <Tag color='#2db7f5'>{hasSumbit?'已交卷':'未交卷'}</Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label="判卷">
                                    <Tag color={hasJudge?'green':'#f50'} >{hasJudge?'已判卷':'未判卷'}</Tag>
                                </Descriptions.Item>
                            </Descriptions>
                            <Paragraph>
                                {paperDetail?paperDetail.examPaper.description:""}
                            </Paragraph>
                        </div>
                    </div>
                </PageHeader>
                <Divider></Divider>
                <Tabs tabPosition="left">
                {
                    tabq.map((item,index)=>(
                        <TabPane tab={
                            <Badge
                            status={item.needJudges>0?"error":"success"}
                            >
                                {
                                    item.tab+'('+item.needJudges+'/'+item.questions.length+')'
                                }
                            </Badge>
                            
                            } key={item.questionType}>
                            {
                                item.questions.length == 0?
                                <div>该类型没有题目</div>
                                :<Tabs
                                activeKey={this.state.activeKey[item.questionType]}
                                onChange={(activeKey)=>{
                                            let orgActiveKey = this.state.activeKey;
                                            orgActiveKey[item.questionType] = activeKey;
                                            this.setState({activeKey:orgActiveKey});
                                        }}
                                >
                                    {
                                        item.questions.map(
                                            (question, index)=>
                                            (
                                                <TabPane 
                                                    tab={
                                                        <Badge
                                                        status={question.answer && question.answer.judge == 0?"error":"success"}>
                                                        {'第'+(index+1)+'题'}
                                                        </Badge>}
                                                    key={item.questionType*1000+index}>

                                                    <Descriptions bordered column={3}>
                                                        <Descriptions.Item label='该题分值' span={1}>
                                                            <div>{question.examQuestion.itemScore}分</div>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='该题得分' span={2}>
                                                            <div style={{color:'red',fontSize:'20px'}}>
                                                            {question.answer?question.answer.score:0}分
                                                            </div>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='题目' span={3}>
                                                            <div dangerouslySetInnerHTML={{__html:question.examQuestion.content}}></div>
                                                            {
                                                                question.choices.map((choice,index1)=>
                                                                (
                                                                    <Row key ={index*10000+index1}>
                                                                        <Col span={2}>
                                                                            {choice.tmark+"、"}
                                                                        </Col>
                                                                        <Col span={22}>
                                                                            <div dangerouslySetInnerHTML={{__html:choice.content}}></div>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                                )
                                                            }
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='标准答案' span={3}>
                                                            <div>{question.examQuestion.standardAnswer}</div>
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label='学生答案' span={2}>
                                                            <div style={{color:'red',fontSize:'20px'}}>
                                                            {question.answer?question.answer.ansContent:'该题没有作答'}
                                                            </div>
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                     {
                                                     question.answer 
                                                     &&(
                                                         question.examQuestion.itemType === 3 
                                                         || question.examQuestion.itemType === 4 
                                                      ) ?
                                                      <>
                                                        <Divider/>
                                                        <JudgeForm
                                                          max={question.examQuestion.itemScore}
                                                          score={question.answer.score}
                                                          ansitemId={question.answer.ansitemId}
                                                          onChange={()=>{
                                                              this.getAnswerDetailByPaperId();
                                                              let activeKey = this.state.activeKey;
                                                              let tpindex = activeKey[item.questionType];
                                                              let index = parseInt(tpindex);
                                                              index = index%1000;
                                                              if(index >= item.questions.length -1)
                                                              {
                                                                  //回到第一题
                                                                  tpindex = item.questionType*1000 - 1;
                                                              }
                                                              activeKey[item.questionType] = (1+parseInt(tpindex)).toString();
                                                              this.setState({activeKey:activeKey});

                                                          }}
                                                          />                                                       
                                                      </>
                                                      :null
                                                     }
                                                     
                                                </TabPane>
                                            )
                                        )
                                    }

                                </Tabs>
                            }
                        </TabPane>
                    ))
                }
                </Tabs>
            </MyLayout>
        );
    }
}

export default JudgePaper;

