import React,{ Component } from "react";
import MyLayout from "../../common/MyLayout";
import {Row, Col, Tooltip, List, Card,Divider} from 'antd';
import api from "../../../api/api";
const { Meta } = Card;


class myBuyVideoList extends Component{

    constructor(props) {
        super(props);
        this.state = {
            videoListData:[],
            pageNum:1,
            pageSize:8,
            totalNum:8
        };
    }

    myBuyVideoList() {
        api.video.myBuyVideoList("",
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        videoListData:data.data,
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.myBuyVideoList();
    }

    render()
    {
        let history = this.props.history;
        return(
            <MyLayout >
                <Row style={{marginBottom: "20px",display:'flex'}}>
                    <Col span={22}>
                        <div style={{fontSize:"24px"}}>已购课程</div>
                    </Col>
                    <Col span={2} style={{top:"20px"}}>
                        <a onClick={()=>
                            history.push('/Personal/moreVideoList')
                        }>查看更多视频</a>
                    </Col>
                </Row>
                <Divider />
                <List
                    grid={{ gutter: 20, column: 4 }}
                    itemLayout="vertical"
                    size="large"
                    dataSource={this.state.videoListData}
                    renderItem={(item,index) => (
                        <List.Item key={index}>
                            <Card hoverable
                                  cover={<img alt="example" style={{height:"170px"}} src={item.posterPath} />}
                                  onClick={()=>
                                      history.push('/Personal/VideoDetail'+'/'+item.vid)
                                  }
                            >
                                <Meta title={item.name}
                                      style={{height:"100px"}}
                                      description={
                                          <div>
                                              <div className="twoElements">
                                                  <Tooltip title= {item.intro}>
                                                      {item.intro}
                                                  </Tooltip>
                                              </div>
                                              <Row>
                                                  <Col span={19}>
                                                      <div className="home-PriceValue">
                                                          {
                                                              item.price==0?
                                                                  <div >
                                                                      限时免费
                                                                  </div>:
                                                                  <div >
                                                                 <span style={{fontSize:"16px", color: "#aaaaaa"}}>
                                                                     价格：
                                                                 </span>
                                                                      ￥{item.price/100}
                                                                  </div>
                                                          }
                                                      </div>
                                                  </Col>
                                                  <Col style={{color:"#b7b7b7",paddingTop:"10px"}}>
                                                    <span span={5} >
                                                        {item.visitTimes} 次浏览
                                                    </span>
                                                  </Col>
                                              </Row>
                                          </div>
                                      } />
                            </Card>
                        </List.Item>
                    )}
                />
            </MyLayout>
        )
    }
}

export default myBuyVideoList;