import { Progress, Icon, Button, Table, Pagination, Select, Col, Row, Form, Divider, Input } from 'antd';
import React, { Component } from 'react';
import MyLayout from '../common/MyLayout';
import {connect } from 'react-redux';
import api from '../../api/api';
import ExportJsonExcel from 'js-export-excel';
import VideoMoalSearch from '../common/VideoModalSearch';
import RoutePath from '../../config/RoutePath';

class StaticVideo extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            clses:[],
            classId:null,
            video:{vid:null},
            phone:null,
            idNumber:null,

            data:[],
            loading:false,
         };

         this.initPageSize = 8;
         this.pageNum = 1;
         this.pageSize = this.initPageSize;
         this.totalNum = 10000;
    }

    feacthClassList()
    {
        let orgId = this.props.match.params.orgId;
        if(orgId)
        {
            //管理员用户
            api.class.getAllClassByOrgId({orgId},
                (state,data)=>{
                    if(state && data.status == 200)
                    {
                        this.setState({clses:data.data});
                        if(data.data.length > 0)
                        {
                            this.setState({classId:data.data[0].classId});
                        }
                        this.research();
                    }
                }
            );
        }
        else
        {
            //老师用户
            api.taskadmin.getMyOcuppyClasses(
                null,
                (state,data)=>{
                    if(state && data.status == 200)
                    {
                        this.setState({clses:data.data});
                        if(data.data.length > 0)
                        {
                            this.setState({classId:data.data[0].classId});
                        }
                        this.research();
                    }
                }
            )
        }
    }

    getSearchCondition()
    {
        return {
            classId:this.state.classId,
            videoId:this.state.video.vid,
            phone:this.state.phone,
            idNumber:this.state.idNumber,
        }
    }

    fetachVideoRecordList(){
        api.video.pageSerchPlayRecord(
            {
                "condition": this.getSearchCondition(),
                "pageNum": this.pageNum,
                "pageSize": this.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.totalNum = data.totalNum;
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    downLoadWithData(data)
    {
       var option={};
       option.fileName = '播放记录'
       option.datas=[
        {
            sheetData:[],
            sheetName:'播放记录',
            sheetHeader:[
                '姓名',
                '电话',
                '身份证号',
                '视频名',
                '视频时长(分钟)',
                '完成百分比',
                '完成时间'],
            columnWidths: [4, 10, 4, 4]
        },
       ];
       for(let i = 0; i < data.length; i++)
       {
           option.datas[0].sheetData.push([
               data[i].name,
               data[i].phone,
               data[i].idNumber,
               data[i].videoName,
               data[i].duration,
               data[i].percent,
               data[i].updatedTime,
           ])
       }

       var toExcel = new ExportJsonExcel(option); //new
       toExcel.saveExcel(); //保存
    }

    downloadExcelData()
    {
        this.setState({loading:true});
        api.video.pageSerchPlayRecord(
            {
                "condition": this.getSearchCondition(),
                "pageNum": 1,
                "pageSize": 500000,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.downLoadWithData(data.data);
                }
                this.setState({loading:false});
            }
        )
    }

    componentDidMount()
    {
        this.feacthClassList();
    }

    pageOnChange(pageNum,pageSize) {
        this.pageNum = pageNum;
        this.pageSize = pageSize;
        setTimeout(()=>this.fetachVideoRecordList(), 200);
    }

    research() {
        this.pageNum = 1;
        this.pageSize = this.initPageSize;
        setTimeout(()=>this.fetachVideoRecordList(), 200,);
    }

    render() {

        const columns = [
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '电话',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: '视频名',
                dataIndex: 'videoName',
                key: 'videoName',
                className:"tableOverflow",
            }, 
            {
                title: '视频时长',
                dataIndex: 'duration',
                key: 'duration',
                render: (text, record) => (
                    <span>{text}分钟</span>
                )
            },
            {
                title: '完成百分比',
                dataIndex: 'percent',
                key: 'percent',
                render: (text, record) => (
                    <Progress percent={record.percent}/>
                )
            },
            {
                title: '完成时间',
                dataIndex: 'updatedTime',
                key: 'updatedTime',
                className:"tableOverflow",
            }, 
            {
                title:'详情',
                dataIndex: 'descrp',
                key: 'descrp',
                render: (text, record) => (
                  <Button
                   type="primary"
                   onClick={
                       ()=>this.props.history.push(RoutePath.FetchPersonalStatics + "/" + record.uid)
                   }
                  >
                    详情
                  </Button>
              )
          }
        ]

        let excludes = [];
        if(this.state.video.vid)
        {
            excludes.push(this.state.video);
        }
        return (
            <MyLayout>
                <Row>
                <Col span={23}>
                <Form layout='inline'>
                    <Form.Item>
                       {
                        this.state.classId?
                        <Select defaultValue={this.state.classId} onChange={(classId)=>{this.setState({classId})}}>
                        {
                            this.state.clses.map((item,index)=>(
                                <Select.Option value={item.classId} key={index}>{item.name}</Select.Option>
                            ))
                        }
                        </Select>:null
                       }
                    </Form.Item>
                    <Form.Item>
                       <VideoMoalSearch
                           title={this.state.video.name?
                           this.state.video.name.substring(0,8)+'...'
                           :"选择视频"}
                           excludes={excludes}
                           addText="选定"
                           onClick={
                            (videoId,video)=>{
                                this.setState({video});
                                return true;
                           }
                        }
                       />
                    </Form.Item>
                    <Form.Item>
                       <Input
                         placeholder="手机号"
                         value={this.state.phone}
                         onChange={(e)=>this.setState({phone:e.target.value})}
                       />
                    </Form.Item>
                    <Form.Item>
                       <Input
                         placeholder="身份证号"
                         value={this.state.idNumber}
                         onChange={(e)=>this.setState({idNumber:e.target.value})}
                       />
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary'
                        onClick={()=>this.research()}
                        ><Icon type='search'/></Button>
                    </Form.Item>      
                </Form>
                </Col>
                <Col span={1}>
                    <Button icon='download' 
                       disabled={this.state.loading}
                       loading={this.state.loading}
                       onClick={()=>this.downloadExcelData()}></Button>
                </Col>
                </Row>
                <Divider/>
                <Table
                  dataSource={this.state.data}
                  columns={columns}
                  rowKey='playId'
                  pagination={false}
              />
              <Divider/>
                <Pagination
                            onChange={(pageNum,pageSize)=>this.pageOnChange(
                                pageNum,pageSize
                            )}
                            pageSize={this.pageSize}
                            current={this.pageNum}
                            total={this.totalNum}
                        />
            </MyLayout>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}

export default connect(mapStateToProps,null)(Form.create()(StaticVideo));
