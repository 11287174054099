import { Button, Card, Avatar, Divider } from 'antd';

import React, { Component } from 'react';
import MyLayout from '../../../common/MyLayout';
import api from '../../../../api/api';
import Dict from '../../../../config/Dict';
import RoutePath from '../../../../config/RoutePath';

class OrgAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            orgData:{},
         };
    }


    updateOrgDetail() {
        let orgId = this.props.match.params.orgId;
        api.org.orgDetail({orgId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        orgData:data.data,
                    })
                }
            }
        )
    }


    componentDidMount() {
        this.updateOrgDetail();
    }

    render() {
        let item = this.state.orgData;
        let history = this.props.history;

        return (
            <MyLayout>
                <Card
                title={item.orgName}
                extra={<Avatar src={item.logoFileId} />}
                actions={[
                    <Button type="primary"
                            onClick={()=>history.push(RoutePath.GetOrgStaticsList+'/'+item.orgId)}>
                        综合统计
                    </Button>,
                    <Button type="primary"
                            onClick={()=>history.push(RoutePath.StaticMonthLearn +'/'+item.orgId)}>
                        按月统计
                    </Button>,
                    <Button type="primary"
                            onClick={()=>history.push(RoutePath.StaticTotalMonth +'/'+item.orgId)}>
                        月总统计
                    </Button>,
                    <Button type="primary"
                            onClick={()=>history.push(RoutePath.StaticRangeList +'/'+item.orgId)}>
                        分段统计
                    </Button>,
                    <Button type="primary"
                        onClick={()=>history.push(RoutePath.StaticPersonalList+'/'+item.orgId)}>
                        学习统计
                    </Button>,
                    <Button type="primary"
                        onClick={()=>history.push(RoutePath.StaticVideo+'/'+item.orgId)}>
                        视频统计
                    </Button>,
                    <Button type="primary"
                        onClick={()=>history.push(RoutePath.StaticPaper+'/'+item.orgId)}>
                        练习统计
                    </Button>,
                    <Button type="primary"
                            onClick={()=>history.push(RoutePath.PageSearchSignup+'/'+item.orgId)}>
                        报名列表
                    </Button>,
                    <Button type="primary"
                        onClick={()=>history.push(RoutePath.NoticePub+'/1/'+item.orgId)}>
                        学院通知
                    </Button>,
                    <Button type="primary"
                            onClick={()=>history.push(RoutePath.NoticePub+'/3/'+item.orgId)}>
                        定向通知
                    </Button>,
                    <Button type="primary"
                            onClick={()=>history.push('/staff/GetOrgStaffs'+'/'+item.orgId)}>
                        员工管理
                    </Button>,
                    <Button type="primary"
                            onClick={()=>history.push('/class/SearchClass'+'/'+item.orgId)}>
                        班级管理
                    </Button>
                ]}
                >
                            <Card.Meta
                                description={
                                    "法人："+item.legalPersonName +" "+"" +
                                    "【"+ Dict.getValue('nature',item.nature,'')+
                                    "/"+ Dict.getValue('companyClass',item.companyClass,'')+
                                    "/"+ Dict.getValue('personNum',item.personNum,'')+
                                    "】"
                                }
                            />
                            <Divider/>
                            <div className="multipleTextOverflow">
                                {item.introduce}
                            </div>
                </Card>
            </MyLayout>
        );
    }
}

export default OrgAdmin;

