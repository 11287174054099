import React, { Component } from 'react';
import { Icon, Button,Tag, Divider, Table, InputNumber, message, Modal, Row, Col} from 'antd';
import api from '../../../api/api';
import VideoListBatchSelectModal from '../../common/VideoListBatchSelectModal';
import AddVideoWithSeriesModal from './AddVideoWithSeriesModal';
const { confirm } = Modal;


class GetVideoList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            itemIndex:0,
            visible: false,
            data:[],

            selectedRowKeys:[],
        };
    }

    getVideoListByTaskId(){
        let mtaskId = this.props.mtaskId;
        api.taskadmin.getVideoListByTaskId({"mtaskId":mtaskId},
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                    })
                }
            }
        )
    }

    batchAddToTask=(ids)=>{
        let mtaskId = this.props.mtaskId;
        api.taskadmin.batchAddDataToTask(
            {
                mtaskId,
                ids:ids,
            },
            (state,data)=>{
                if(state && data.status === 200)
                {
                    this.getVideoListByTaskId();
                }
                else
                {
                    message.error(data.msg);
                }
            }
        )
    }

    batchRemoveData=()=>{
        let mtaskId = this.props.mtaskId;
        let ids = this.state.selectedRowKeys;

        Modal.confirm({
            title:'真的要删除?',
            onOk:()=>{
                api.taskadmin.batchRemovDataToTask(
                    {
                        mtaskId,
                        ids:ids,
                    },
                    (state,data)=>{
                        if(state && data.status === 200)
                        {
                            this.getVideoListByTaskId();
                        }
                        else
                        {
                            message.error(data.msg);
                        }
                    }
                )
                this.setState({selectedRowKeys:[]});
            }
        })

    }

    itemMoveStep(vid,moveStep){
        let mtaskId = this.props.mtaskId;
        api.taskadmin.changeItemIndexWithStep({
                "itemId": vid,
                "moveStep": moveStep,
                "mtaskId": mtaskId
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.getVideoListByTaskId();
                }
            }
        )
    }

    removeItemFromTask(vid){
        let mtaskId = this.props.mtaskId;
        confirm({
            title: "提示",
            content:"你确定要删除这条项目吗?",
            onOk:()=>{
                api.taskadmin.removeItemFromTask({
                        "mtaskId": mtaskId,
                        "itemId": vid,
                    },
                    (state,data)=>{
                        if (state) {
                            if(data.status == 200) {
                                setTimeout(()=>this.getVideoListByTaskId(), 200,);
                                message.success('删除成功!');
                            }
                            this.setState({selectedRowKeys:[]});
                        }
                    }
                )
            }
        });
    }

    componentDidMount() {
        this.getVideoListByTaskId();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys });
    };

    addSeriesToTask=(record)=>{
        let mtaskId = this.props.mtaskId;
        let {seriesId} = record;
        api.taskadmin.addSeriesToTask(
            {
             mtaskId,seriesId
            },
            (state,data)=>{
                if (state) {
                    if(data.status == 200) {
                        setTimeout(()=>this.getVideoListByTaskId(), 200);
                    }
                    this.setState({selectedRowKeys:[]});
                }
            }
        )
    };

    getTotalDuration()
    {
        let totalDuration = 0;
        for(let i = 0; i< this.state.data.length; i++)
        {
            totalDuration += this.state.data[i].duration;
        }
        return totalDuration;
    }
    render() {
        const {data,selectedRowKeys} = this.state;
        const columns = [
            {
                title: '#',
                dataIndex: 'itemIndex',
                key: 'itemIndex',
                className:"tableOverflow",
            },
            {
                title: '视频名',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow",
            },
            {
                title: '讲师',
                dataIndex: 'teacher',
                key: 'teacher',
                render: (text, record) => (
                    <div>
                        {record.teacher?record.teacher.name:"--"}
                    </div>
                ),
            },
            {
                title: '时长(分)',
                dataIndex: 'duration',
                key: 'duration',
            },
            {
                title: '顺序调整',
                key: 'action',
                render: (text, record) => (
                    <span>
                    <Button
                     onClick={
                         ()=>this.itemMoveStep(record.vid,1)
                     }
                    >
                    <Icon type='down'/>
                    </Button>
                    <Divider type='vertical'/>
                    <Button
                     onClick={
                         ()=>this.itemMoveStep(record.vid,-1)
                     }
                    >
                    <Icon type='up'/>
                    </Button>
                    </span>
                ),
            },
            {
                title: '操作',
                key: 'action2',
                render: (text, record) => (
                    <a onClick={()=>this.removeItemFromTask(record.vid)}>删除</a>
                ),
            }
        ];

        const hasSelected = selectedRowKeys.length > 0;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        return(
            <div>
                <Row>
                    <Col span={3}>
                        <VideoListBatchSelectModal
                        title="批量添加"
                        handleAdd={(data)=>this.batchAddToTask(data)}
                        excludes={data}
                        />
                    </Col>
                    <Col span={3}>
                        <AddVideoWithSeriesModal
                        title="系列添加"
                        handleAdd={this.addSeriesToTask}
                        />
                    </Col>
                    <Col span={2}>
                        <Button type="danger" 
                        onClick={this.batchRemoveData} 
                        disabled={!hasSelected}>
                            删除
                        </Button>
                    </Col>
                    <Col span={3}>
                        <span style={{ marginLeft: 8 }}>
                            {hasSelected ? `共选中 ${selectedRowKeys.length} 条视频` : ''}
                        </span>
                    </Col>
                    <Col span={3}>
                        <span style={{ marginLeft: 8 }}>
                            总时长{this.getTotalDuration()}分钟
                        </span>
                    </Col>
                </Row>
                <Divider/>
                <Table
                    rowSelection={rowSelection}
                    rowKey='mtaskItemId'
                    pagination={false}
                    dataSource={data}
                    columns={columns}
                />
            </div>
        )
    }
}
export default GetVideoList;