import { Steps, Col, Row, Button, Result, Divider } from 'antd';

import React, { Component } from 'react';
import MyLayout from "../../common/MyLayout";
import { connect } from 'react-redux';
import ImageUpload from '../../../components/ImageUpload';
import api from '../../../api/api';
import Action from '../../../actions/Action';

class RealNameAuth extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
           idcardFilepath:null,
           realNameAuth:0,
        };
    }


    editMyUserInfo()
    {
        api.user.editMyUserInfo(
            {idcardFilepath:this.state.idcardFilepath},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.props.refreshUserInfo();
                } else {
                }
            }
        )
    }

    render() {
        let userInfo = this.props.userInfo;
        return (
            <MyLayout sider={"UserSettingSider"}>
                {
                    userInfo?
                    <>
                        <Steps current={userInfo.realNameAuth}>
                            <Steps.Step key={0} title="材料提交"></Steps.Step>
                            <Steps.Step key={1} title="审核中"></Steps.Step>
                            <Steps.Step key={2} title="审核完成"></Steps.Step>
                        </Steps>
                        {
                            userInfo.realNameAuth == 0?
                            <Row style={{marginTop:'20px'}}>
                            <Col span={10}>

                            </Col>
                            <Col span={4}>
                                <div>
                                <ImageUpload 
                                   value={this.state.idcardFilepath?this.state.idcardFilepath:userInfo.idcardFilepath}
                                   onChange={(idcardFilepath)=>this.setState({idcardFilepath})}
                                />
                                </div>
                                <div>请上传身份证正面照</div>
                                <Divider/>
                                <Button 
                                type='primary'
                                disabled={this.state.idcardFilepath == null}
                                onClick={()=>this.editMyUserInfo()}
                                >实名认证</Button>
                            </Col>
                            </Row>
                            :null
                        }
                        {
                            userInfo.realNameAuth == 1?
                            <>
                                <Result
                                    title="实名认证审核中"
                                />
                            </>
                            :null
                        }

                        {
                            userInfo.realNameAuth == 2?
                            <>
                                <Result
                                    status = 'success'
                                    title="实名认证完成"
                                />
                            </>
                            :null
                        }
                    </>
                    :
                    null
                }
            </MyLayout>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        refreshUserInfo: (data) => dispatch({
            type: Action.GET_USER_INFO,
        }),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(RealNameAuth);
