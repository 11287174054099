import React, { Component } from 'react';
import { Menu, Icon, Layout} from 'antd';
import {connect } from 'react-redux';
import {withRouter } from 'react-router'
import PendingTchList from "../../admin/PendingTeacher/PendingTchList";
import Action from '../../../actions/Action';

const { SubMenu } = Menu;
let {Sider} = Layout;

class PlatformPendingSider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //selected:""
        };
    }

    render() {
        let userInfo = this.props.userInfo;
        let history = this.props.history;
        let pathname = this.props.location.pathname;
        return (
            <></>
        );
    }
}
function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
        remain: state.user.remain,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetUser: (data) => dispatch(
            {
                type: Action.RESET_USER_INFO,
            }),
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(PlatformPendingSider));