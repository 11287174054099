
import React, { Component } from 'react'
import MyLayout from '../../common/MyLayout';
import { message, Button, Modal, Row, Table, Pagination, Badge, Tabs,Col,Input, Divider,Tag, PageHeader } from 'antd';
import api from '../../../api/api';
import Dict from '../../../config/Dict';
import AddStudentsWithClassModal from '../../common/addstudents/AddStudentsWithClassModal';

class NoticeUserAdmin extends Component {

    constructor(props) {

        super(props);
        this.state = { 
            notice:{},
            users:[],

            selectedRowKeys: [],
            filteredInfo: null,
            clses:[],
        };
    }

    featchNotice()
    {
        let noticeId = this.props.match.params.noticeId;
        api.notice.getNoticeDetail(
            {noticeId},
            (state,data)=>{
                if(state && data.status === 200)
                {
                    this.setState({
                        notice:data.data
                    })
                }
            }
        );
    }

    featchUsers()
    {
        let noticeId = this.props.match.params.noticeId;
        api.notice.getNoticeUserListByNoticeId(
            {noticeId},
            (state,data)=>{
                if(state && data.status === 200)
                {
                    this.setState({
                        users:data.data
                    })
                }
            }
        );
    }

    featchClses()
    {
        let orgId = this.props.match.params.orgId;
        api.class.getAllClassByOrgId({orgId},
            (state,data)=>{
                if(state && data.status == 200)
                {
                    this.setState({clses:data.data});
                }
            }
        );
    }

    componentDidMount()
    {
        this.featchNotice();
        this.featchUsers();
        this.featchClses();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({selectedRowKeys});
    };

    romoveFromNotice=()=>{
        let noticeId = this.props.match.params.noticeId;
        api.notice.batchRomoveUserFromNotice(
            {
                noticeId,
                ids:this.state.selectedRowKeys,
            },
            (state,data)=>{
                if(state && data.status === 200)
                {
                    this.featchUsers();
                    this.setState({selectedRowKeys:[]});
                }
                else
                {
                    message.error(data.msg);
                }
            }
        )
    }

    batchAddToNotice=(uids)=>{
        let noticeId = this.props.match.params.noticeId;
        api.notice.batchAddUserToNotice(
            {
                noticeId,
                ids:uids,
            },
            (state,data)=>{
                if(state && data.status === 200)
                {
                    this.featchUsers();
                }
                else
                {
                    message.error(data.msg);
                }
            }
        )
    }

    handleChange = (pagination, filters, sorter) => {
        this.setState({
          filteredInfo: filters
        });
    };

    render() {

        let {users, selectedRowKeys, filteredInfo} = this.state;
        const rowSelection = {
            selectedRowKeys, //指定选中项的 key 数组
            onChange: this.onSelectChange,//选中项发生变化时的回调
        };

        filteredInfo = filteredInfo || {};
        const columns = [
            {
                title: '学生姓名',
                dataIndex: 'name',
                key: 'name',
                className:"tableOverflow",
            },
            {
                title: '城市',
                dataIndex: 'pcity',
                key: 'pcity',
                className:"tableOverflow",
                filters: Dict.getPCityFilters(users),
                filteredValue: filteredInfo.pcity || null,
                onFilter: (value, record) => record.pcity.includes(value),
                render: (text, record) => (
                    <span>{Dict.getAdressLabelValue(record.pcity)}</span>
                ),
            },
            {
                title: '添加日期',
                dataIndex: 'addTime',
                key: 'addTime',
            },
        ];

        const hasSelected = selectedRowKeys.length > 0;
        return (
            <MyLayout>
                <PageHeader
                title={this.state.notice.title}
                subTitle={this.state.notice.content}
                extra={[
                    <AddStudentsWithClassModal 
                        title='添加新的接收者'
                        clses={this.state.clses}
                        handleAdd={this.batchAddToNotice}
                        excludeUsers={this.state.users}
                    />
                ]}
                />
                <Divider/>
                <div style={{ marginBottom: 16 }}>
                    <Button type="danger" onClick={this.romoveFromNotice} disabled={!hasSelected}>
                        从通知列表中移除
                    </Button>
                    <span style={{ marginLeft: 8 }}>
                        列表中共有{this.state.users.length}人,
                        {hasSelected ? `共选中 ${selectedRowKeys.length} 人` : ''}
                    </span>
                </div>
                <Table rowSelection={rowSelection}
                       rowKey='noticeLinkId'
                       columns={columns}
                       dataSource={this.state.users}
                       pagination={false}
                       onChange={this.handleChange}/>


            </MyLayout>
        );
    }
}

export default NoticeUserAdmin;





