import React, { Component } from 'react';
import {Col, Row, Modal, Table, Pagination, Divider, Input, Button, Form, Select} from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import Dict from "../../../config/Dict";
const { confirm } = Modal;

class PendingOrgList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            legalPersonName: "",
            orgName: "",
            status:null,

            data:[],
            pageNum:1,
            pageSize:8,
            totalNum:9,
        };
    }

    examineOrg(status,orgId){
        let title = "";
        let content = "";
        if (status == 1){
            title = "你确定要[审批通过]这个组织(公司)吗?";
            content = "组织(公司)[审批通过]后才可以创建讲座等。"
        }else{
            title = "你确定要[审批驳回]这个组织(公司)吗?";
            content = "组织(公司)[审批驳回]后信息会返回给请求者。"
        }
        confirm({
            title: title,
            content:content,
            onOk:()=>{
                api.org.examineOrg({orgId,status},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.research();
                        }
                    }
                )
            },
            onCancel() {
            },
        });
    }

    pageOnChange(pageNum,pageSize) {
        this.setState({
            pageNum,
            pageSize,
            totalNum:this.state.totalNum
        });
        setTimeout(()=>this.updateData(), 200);
    }

    updateData() {
        api.org.pendingOrgList({
                "condition": {
                    "legalPersonName": this.state.legalPersonName,
                    "orgName": this.state.orgName,
                    "status":this.state.status,
                },
                "pageNum": this.state.pageNum,
                "pageSize": this.state.pageSize,
            },
            (state, data)=>{
                if(state && data.status==200) {
                    this.setState({
                        data:data.data,
                        pageNum: this.state.pageNum,
                        pageSize: this.state.pageSize,
                        totalNum:data.totalNum
                    })
                }
            }
        )
    }

    research() {
        this.setState({
            pageNum:1,
            pageSize:4,
            totalNum:9,
        });
        setTimeout(()=>this.updateData(), 200,)
    }

    componentDidMount() {
        this.updateData();
    }

    render() {
        let history = this.props.history;
        const columns = [
            {
                title: '公司名称',
                dataIndex: 'orgName',
                key: 'orgName',
                onCell: () => {
                    return {
                        style: {
                            maxWidth: 150,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow:'ellipsis',
                            cursor:'pointer'
                        }
                    }
                },
                render:  (text, record) => (
                    <a className="oneTextOverflow"
                       onClick={()=>history.push('/company/OrgDetail'+'/'+record.orgId)}>{text}
                   </a>
                )
            },
            {
                title: '公司法人',
                dataIndex: 'legalPersonName',
                key: 'legalPersonName',
            },
            {
                title: '公司地址',
                dataIndex: 'detailAddress',
                key: 'detailAddress',
            },
            {
                title: '所属行业',
                key: 'companyClass',
                dataIndex: 'companyClass',
                render: (text, record) => (
                    <span>
                        {Dict.getValue('companyClass',text,'')}
                    </span>
                ),
            },
            {
                title: '审核状态',
                key: 'status',
                dataIndex: 'status',
                render: (text, record) => (
                    <span>
                        {
                            Dict.getValue('orgState',text,'')
                        }
                    </span>
                ),
            },
            {
                title: '审核事件',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <a onClick={()=>this.examineOrg(1,record.orgId)}>同意</a>
                        <Divider type="vertical" />
                        <a onClick={()=>this.examineOrg(2,record.orgId)}>驳回</a>
                    </span>
                ),
            },
        ];
        return(
            <MyLayout sider={"PlatformPendingSider"}>
                <p className="userEditTitle">组织审核</p>
                <Form layout='inline'>
                    <Form.Item>
                        <Input 
                               defaultValue={this.state.legalPersonName}
                               placeholder='请输入法人名'
                               onChange={(e)=>this.setState({legalPersonName:e.target.value})}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Input
                               defaultValue={this.state.orgName}
                               placeholder='请输入公司名'
                               onChange={(e)=>{
                                   this.setState({orgName:e.target.value})}}
                        />
                    </Form.Item>
                    <Form.Item>
                    <Select value={this.state.status} onChange={
                        (status)=>{
                            this.setState({status});
                            this.research();
                        }
                    }>
                        <Select.Option value={null}>不限制</Select.Option>
                        <Select.Option value={0}>已注销</Select.Option>
                        <Select.Option value={1}>上线中</Select.Option>
                        <Select.Option value={2}>已驳回</Select.Option>
                        <Select.Option value={3}>待审核</Select.Option>
                    </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary'
                                onClick={()=>this.research()}>
                            搜索</Button>
                    </Form.Item>
                </Form>
                <Divider/>
                <Table
                    pagination={false}
                    dataSource={this.state.data}
                    columns={columns}
                />
                <div style={{paddingTop:"25px"}}>
                    <Pagination
                        onChange={(pageNum,pageSize)=>this.pageOnChange(
                            pageNum,pageSize
                        )}
                        pageSize={this.state.pageSize}
                        current={this.state.pageNum}
                        total={this.state.totalNum}
                    />
                </div>
            </MyLayout>
        )
    }
}

export default PendingOrgList;