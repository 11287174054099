import React, { Component } from 'react';
import { withRouter } from 'react-router'
import { Layout,PageHeader, BackTop,Button  } from 'antd';
import {connect } from 'react-redux';

import NavbarSelect from './NavbarSelect';
import SiderSelect from './SiderSelect';
import CommonFooter from './CommonFooter';

let {Footer, Content} = Layout;

class MyLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {

         };
    }

    render() {
        let history = this.props.history;
        let userInfo = this.props.userInfo;
        return (
            <>
                <Layout>
                    <NavbarSelect/>
                    <Layout style={{
                        paddingLeft:40,
                        paddingRight:40,
                        paddingTop:5,
                    }}>
                        {
                        <SiderSelect siders={this.props.sider}/>
                    }
                        <Layout style={{ padding: '0 18px',backgroundColor:'white' }}>
                             {
                                this.props.title?
                                    <>
                                    <PageHeader
                                        title={this.props.title}
                                    />
                                    </>
                                    :null
                             }
                            <Content
                                style={{
                                    background: '#fff',
                                    padding: "20px 66px 20px 66px",
                                    minHeight: 280,
                                }}
                            >
                                {this.props.children}
                            </Content>
                        </Layout>
                    </Layout>
                    <CommonFooter/>
                </Layout>
                {
                    userInfo && userInfo.role == 0?
                        <BackTop visibilityHeight={-1}>
                            <div className="homeQa" onClick={()=>history.push('/qa/GetMyCreatedTopicList')}/>
                        </BackTop>:""
                }
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default withRouter(connect(mapStateToProps,null)(MyLayout));