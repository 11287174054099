import React, { Component } from 'react';
import {Form,Modal, InputNumber, Input,Radio, Row, Col, Button, Divider, message, Select, Icon} from 'antd';
import api from "../../../api/api";
import ImageUpload from "../../../components/ImageUpload";
import SimpleDateShowTime from '../../../components/SimpleDateShowTime';
const { TextArea } = Input;

/************* 添加或者编辑试卷封面 ****************/
class AddOrEditPaperModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            data:[],
        }
    }

    handleOk = e => {
        e.preventDefault();
        let paperId = this.props.data?this.props.data.paperId:"";
        this.props.form.validateFields((err, values) => {
            if (!err) {
                api.exam.addOrEditPaper(
                    {paperId,...values},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.setState({
                                visible: false,
                            });
                            this.props.onChange(state);
                        } else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const {data} = this.props;
        const formItemLayout = {
            labelCol: {
                sm: { span: 6 },
            },
            wrapperCol: {
                sm: { span: 18},
            },
        };

        let paperList = this.props.paperList;

        let dispPaperList = [];
        dispPaperList.push({
            paperId:null,
            title:'非补考'
        });
        paperList.map((item)=>{
            //排除补考试卷
            if(!item.parentPaperId)
            {
                //排除自己
                if(!(data && data.paperId === item.paperId))
                {
                    dispPaperList.push({
                        paperId:item.paperId,
                        title:item.title
                    });
                }
            }
        })

        return (
            <>
                <Button type={this.props.type==="add"?'primary':'default'}
                        onClick={()=>{
                            this.setState({
                                visible: true,
                            })
                            let form = this.props.form;
                            if(this.props.type === "add")
                            {
                                form.setFieldsValue({
                                    paperId:null,
                                    parentPaperId:null,
                                    title:null,
                                    examType:0,
                                    coverPath:null,
                                    totalScore:100,
                                    duration:0,
                                    startTime:null,
                                    endTime:null,
                                    description:null,
                                    });
                            }
                            else
                            {
                                form.setFieldsValue(data);
                            }
                        }}>
                    {
                        this.props.type==="add"?"创建试卷":<Icon type='edit'/>
                    }
                </Button>
                <Modal
                    title={this.props.type==="add"?"创建试卷":"编辑试卷"}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    width={620}
                    onCancel={this.handleCancel}
                >
                    <Form {...formItemLayout}>
                        <Form.Item label='试卷标题' hasFeedback>
                            {getFieldDecorator('title', {
                                initialValue:data?data.title:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '试卷标题是必须输入的!'
                                    },
                                ],
                            })(
                                <Input size="large"
                                       placeholder="试卷标题"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='补考' hasFeedback>
                            {getFieldDecorator('parentPaperId', {
                                initialValue:data?data.parentPaperId:'',
                                rules: [],
                            })(
                                <Select>
                                    {
                                        dispPaperList.map((item,index)=>
                                        <Select.Option value={item.paperId} key={index}>
                                            {item.title}
                                        </Select.Option>
                                        )
                                    }
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label='试卷类型'>
                            {getFieldDecorator('examType', {
                                initialValue:data?data.examType:0,
                                rules: [
                                    {
                                        required: true,
                                        message: '试卷类型是必须选择的!'
                                    },
                                ],
                            })(
                                <Radio.Group>
                                    <Radio value={0}>练习题</Radio>
                                    <Radio value={1}>考试试卷</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <Form.Item label='封面图片'>
                            {getFieldDecorator('coverPath', {
                                initialValue:data?data.coverPath:'',
                                rules: [
                                    {
                                        required: false,
                                        message: '海报图片必须上传!'
                                    },

                                ],
                            })(
                                <ImageUpload/>,
                            )
                            }
                        </Form.Item>
                        <Form.Item label='总分值' hasFeedback>
                            {getFieldDecorator('totalScore', {
                                initialValue:data?data.totalScore:100,
                                rules: [
                                    {
                                        required: true,
                                        message: '总分值是必须选择的!'
                                    },
                                ],
                            })(
                                <InputNumber min={1} max={1000}/>
                            )}
                        </Form.Item>
                        <Form.Item label='考试时长(分钟)' hasFeedback>
                            {getFieldDecorator('duration', {
                                initialValue:data?data.duration:0,
                                rules: [
                                    {
                                        required: true,
                                        message: '时长必须给!'
                                    },
                                ],
                            })(
                                <InputNumber min={0} max={1000}/>
                            )}
                        </Form.Item>
                        <Form.Item label='开始时间'>
                                {getFieldDecorator('startTime', {
                                    initialValue:data?data.startTime:100,
                                    rules: [
                                    ],
                                })(
                                    <SimpleDateShowTime placeholder={"开始日期"} selectAllDays={true}/>,
                                )}
                            </Form.Item>
                            <Form.Item label='结束时间'>
                                {getFieldDecorator('endTime', {
                                    initialValue:data?data.endTime:100,
                                    rules: [
                                    ],
                                })(
                                    <SimpleDateShowTime placeholder={"结束日期"} selectAllDays={true}/>,
                                )}
                            </Form.Item>
                        <Form.Item label='试卷描述' >
                            {getFieldDecorator('description', {
                                initialValue:data?data.description:"",
                                rules: [
                                    {
                                        required: true,
                                        message: '一试卷描述必须输入的!'
                                    },
                                ],
                            })(
                                <TextArea rows={4}
                                          placeholder="试卷描述"
                                />,
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}

export default Form.create()(AddOrEditPaperModal);