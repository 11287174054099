function URLBuild(url) {
    this.url = url;
    this.params = {};
    var u = url.split("?");
    this.host = u[0];
    if (typeof (u[1]) == "string") {
        for (var index = 1; index < u.length; index++) {
            var ut = u[index].split("&");
            for (var i in ut) {
                var j = ut[i].split("=");
                this.params[j[0]] = decodeURIComponent(j[1]);
            }
        }

    }

}

URLBuild.prototype.build = function () {
    var url = this.host;
    var sb = [];
    var maps = this.params;
    for (var key in maps) {
        sb.push(key);
        sb.push("=");
        sb.push(encodeURIComponent(maps[key]));
        sb.push("&");
    }
    sb[sb.length - 1] = null;
    url = url.trim();
    var length = url.length;
    var index = url.indexOf("?");
    if (index > -1) {//url说明有问号
        if ((length - 1) == index) {//url最后一个符号为？，如：http://wwww.baidu.com?
            url += sb.join("");
        } else {//情况为：http://wwww.baidu.com?aa=11
            url += "&" + sb.join("");
        }
    } else {//url后面没有问号，如：http://wwww.baidu.com
        url += "?" + sb.join("");
    }
    return url;

};
URLBuild.prototype.remove = function (key) {
    delete  this.params[key];
    return this;
};
URLBuild.prototype.toString = function () {
    return this.build();
};

/**
 * 向url链接追加参数(单个)
 * @param name String
 * @param value String
 * @return
 */
URLBuild.prototype.add = function (name, value) {
    this.params[name] = value;
    return this;
};
/**
 * 向url链接追加参数(多个)
 * @param json
 * @return
 */
URLBuild.prototype.addAll = function (json) {
    for (var key in json)
        this.params[key] = json[key];
    return this;
};
module.exports = URLBuild;