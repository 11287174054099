import React, {Component} from 'react'
import {Form, Radio, Row,Checkbox, Col} from 'antd'
import api from '../../../api/api'
import MyLayout from '../../common/MyLayout'
import '../../../assets/style/common.css'
import LineChart from '../../statistic/charts/LineChart'
import { setLoadingState } from '../../../reducers/UserInfo'
import ComFunction from '../../../api/ComFunction'
class ClassSnapshots extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            activeKey: "numVideo",
            avg:false,
            acc:true,
            staticsData: [],
            classData:{},
         };
    }

    fetchClassSnapshots() {
        let classId = this.props.match.params.classId;
        setLoadingState(true);
        api.statics.getMtClassYearMonthHistory(
            {
                classId,
            },
            (state, data) => {
                if (state && data.status === 200) {
                    this.setState({
                        classData: data.data,
                        staticsData: data.data.historyData,
                    })
                }
                setLoadingState(false);
            }
        )
    }

    getSpecifyData = (totalData, keyword) => {
        let data = [];

        if(this.state.acc)
        {
            for (let i = 0; i < totalData.length; i++) {
                if(totalData[i][keyword] > 0)
                {
                    if(this.state.avg)
                    {
                        data.push({
                            time: totalData[i].updateTime,
                            value: ComFunction.getIntDiveder(totalData[i][keyword],this.state.classData.studentNumOk),
                        })
                    }
                    else
                    {
                        data.push({
                            time: totalData[i].updateTime,
                            value: totalData[i][keyword],
                        })
                    }
                }
            }
        }
        else
        {
            let trigger = false;
            for (let i = 1; i < totalData.length; i++) {
                     let diff = totalData[i][keyword] - totalData[i-1][keyword];
                     if(trigger || diff > 0)
                     {
                         trigger = true;
                        if(this.state.avg)
                        {
                            data.push({
                                time: totalData[i].updateTime,
                                value: ComFunction.getIntDiveder(
                                    diff,
                                    this.state.classData.studentNumOk),
                            })
                        }
                        else
                        {
                            data.push({
                                time: totalData[i].updateTime,
                                value:diff,
                            })
                        }
                }
            }
        }

        return data;
    }

    componentDidMount() {
        this.fetchClassSnapshots();
    }

    render() {

        const {staticsData, activeKey,classData} = this.state;
        let data = []

        if (staticsData && staticsData.length > 0) {
            data = this.getSpecifyData(staticsData, activeKey)
        }

        var clumDatas = [
            {
                key:'numVideo',
                value: '学习视频数(个)'
            },
            {
                key:'numVideoDuration',
                value: '学习视频时长(分)'
            },
            {
                key:'numAnswer',
                value: '试卷数量(套)'
            },
            {
                key:'numAnswerItems',
                value: '答题数量(道)',
            }
        ];
        let activeValue = '';
        let prefix = '累计';
        if(!this.state.acc)
        {
            prefix = '单月';
        }
        for(let i = 0; i <clumDatas.length; i++)
        {
            if(clumDatas[i].key === activeKey)
            {
                activeValue = prefix+ clumDatas[i].value;
                break;
            }
        }

        return (
            <MyLayout title={"["+classData.name+"(班号:"+classData.classId+")(人数:"+classData.studentNumOk+")"+']历史数据'}>
            <Row>
                <Col span={18}>
                <Form layout='inline'>
                    <Form.Item>
                            <Radio.Group
                            defaultValue={this.state.activeKey}
                            onChange={e => {
                                this.setState({
                                    activeKey: e.target.value,
                                })
                            }}
                        >
                        {
                            clumDatas.map((item,index)=>(
                                <Radio.Button key={'x'+index} value={item.key}>
                                        {prefix + item.value}
                                </Radio.Button>
                            ))
                        }
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                            <Checkbox
                            onChange={(e)=>{
                                this.setState({avg:e.target.checked});
                            }}
                            checked={this.state.avg}>
                            平均值?</Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <Checkbox
                            onChange={(e)=>{
                                this.setState({acc:e.target.checked});
                            }}
                            checked={this.state.acc}>
                            累计值?</Checkbox>
                        </Form.Item>
                </Form>
                </Col>
            </Row>
                <LineChart
                    chartTyp="Column"
                    chartName={activeValue}
                    data={data}
                />
        </MyLayout>
        );
    }
}

export default ClassSnapshots;


