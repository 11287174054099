
import md5 from "md5";

function isObject(val) {
    return val != null && typeof val === 'object' && Array.isArray(val) === false;
};

function sessionStorageHelper() {
}

sessionStorageHelper.get = function (storage, key) {
    var json = storage.getItem(key);
    var res = null;
    try {
        res = JSON.parse(json);
    } catch (e) {

    }
    return res;
}
sessionStorageHelper.set = function (storage, key, value) {
    var data = value;
    if (isObject(value))
    {
        data = JSON.stringify(value);
    }
    storage.setItem(key, data);
}

function Storage(key, p) {
    this.init(key, p);
}

Storage.prototype = {

    init: function (key) {
        this.key = md5('moralmaster${key}');
        this.sessionValue = null;
        this.localStorageValue = null;
    },

    getSession: function () {
        if (this.sessionValue == null)
            this.sessionValue = sessionStorageHelper.get(sessionStorage, this.key);
        return this.sessionValue;
    },
    setSession: function (value) {
        this.sessionValue = value;
        sessionStorageHelper.set(sessionStorage, this.key, value);
    },

    getLocal: function () {
        if (this.localStorageValue == null)
            this.localStorageValue = sessionStorageHelper.get(localStorage, this.key);
        return this.localStorageValue;
    },
    setLocal: function (value) {
        this.localStorageValue = value;
        return sessionStorageHelper.set(localStorage, this.key, value);
    },
    invalidate: function () {
        localStorage.removeItem(this.key);
        sessionStorage.removeItem(this.key);
        this.localStorageValue = null;
        this.sessionValue = null;
    }
}

export  default Storage;
