
import React, { Component } from 'react';
import { InputNumber, Button,Divider, Form, message, PageHeader, Tabs, Tag, Typography, Descriptions, Modal, Badge} from 'antd';
import api from '../../../api/api';

class JudgeForm extends Component {

    constructor(props) {
        super(props);
        this.state = 
        { 
          score:props.score,
        };
    }

    sumbitScore(spscore)
    {
        let ansitemId = this.props.ansitemId;
        let score = this.state.score;
        if(typeof(spscore) != 'undefined')
        {
            score = spscore;
            this.setState({score});
        }
        api.exam.judgeAnswer(
            {ansitemId,score},
            (state,data)=>{
                if(state && data.status == 200)
                {
                    if(this.props.onChange)
                    {
                        this.props.onChange();
                    }
                }
            }
        )
    }

    render() {
        return (
            <Form layout='inline'>
                <Form.Item label='打分'>
                    <InputNumber
                    value={this.state.score}
                    step={1}
                    max={this.props.max}
                    min={0}
                    onChange={(score)=>this.setState({score})}
                    ></InputNumber>
                </Form.Item>
                <Form.Item>
                    <Button 
                    onClick={
                        ()=>
                        this.sumbitScore()
                    }
                    type='primary'>提交分数</Button>
                    <Divider type='vertical'/>
                    <Button 
                    type='ghost'
                    onClick={
                        ()=>
                        this.sumbitScore(this.props.max)
                    }>满分{this.props.max}分</Button>
                    <Divider type='vertical'/>
                    <Button 
                    onClick={
                        ()=>
                        this.sumbitScore(0)
                    }
                    type='danger'>0分</Button>
                </Form.Item>
            </Form>
        );
    }
}

export default Form.create()(JudgeForm);
