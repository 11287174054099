import React, { Component } from 'react';
import {Statistic,Card, Divider,PageHeader,Row, Col,Descriptions} from 'antd';
import api from '../../api/api';
import MyLayout from "../common/MyLayout";
import '../../assets/style/common.css';
import LearningRecords from "../statistic/LearningRecords";
import emptyImg from '../../assets/images/emptyImg.png';
import {FileTextOutlined,SafetyCertificateOutlined,VideoCameraAddOutlined,FieldTimeOutlined} from '@ant-design/icons';

class FetchPersonalStatics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data:null,
        };
    }
    fetchPersonalStatics() {
        let uid = this.props.match.params.uid;
        api.statics.fetchPersonalStatics({uid},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        data:data.data,
                    });
                }
            }
        )
    }

    componentDidMount() {
        this.fetchPersonalStatics();
    }

    render() {
        const {
            data = null
        } = this.state;
        return(
            <MyLayout>
                {data?
                    <div>
                        <PageHeader
                            title={data.name}
                            className="site-page-header"
                            subTitle="学习信息统计"
                        >
                            <div className="heading_content">
                                <div className="head_portrait">
                                    <img alt=''
                                         width={80}
                                         src={data.profile?data.profile:emptyImg}
                                    />
                                </div>
                                <div className="heading_mes">
                                    <Descriptions size="small" column={3}>
                                        <Descriptions.Item label="用户名">
                                            {data.name}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="联系方式">
                                            {data.phone}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="本条数据更新时间">
                                            {data.updateTime}
                                        </Descriptions.Item>
                                        <Descriptions.Item>
                                            <Statistic title="参与任务数" value={data.numTask+"个"}/>
                                        </Descriptions.Item>
                                        <Descriptions.Item>
                                            <Statistic title="加入班级数" value={data.numClass+"个"}/>
                                        </Descriptions.Item>
                                        <Descriptions.Item>
                                            <Statistic title="任务完成总百分比"
                                                       value={data.percentTask ? data.percentTask+"%" : "0"+"%"}/>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </div>
                            </div>
                        </PageHeader>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Card>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Statistic
                                                title="累计学习视频数"
                                                value={data?data.numVideo:"0"}
                                                valueStyle={{ color: '#3f8600' }}
                                                prefix={<VideoCameraAddOutlined  />}
                                                suffix="个"
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic
                                                title="累计学习视频时长"
                                                value={data.numVideoDuration?data.numVideoDuration:"0"}
                                                valueStyle={{ color: '#3f8600' }}
                                                prefix={<FieldTimeOutlined />}
                                                suffix="分钟"
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Statistic
                                                title="第一次参加学习时间"
                                                value={data.dateFirstwatch?data.dateFirstwatch:"--"}
                                                valueStyle={{ color: '#3f8600' }}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic
                                                title="最后一次参加学习时间"
                                                value={data.dateLastwatch?data.dateLastwatch:"--"}
                                                valueStyle={{ color: '#3f8600' }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Statistic
                                                title="累计试卷数量"
                                                value={data.numAnswer}
                                                valueStyle={{ color: '#2db7f5' }}
                                                prefix={<FileTextOutlined  />}
                                                suffix="套"
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic
                                                title="累计答题数量"
                                                value={data.numAnswerItems}
                                                valueStyle={{ color: '#2db7f5' }}
                                                prefix={<SafetyCertificateOutlined  />}
                                                suffix="道"
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Statistic
                                                title="第一次答卷时间"
                                                value={data.dateFirstlearn?data.dateFirstlearn:"--"}
                                                valueStyle={{ color: '#2db7f5' }}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic
                                                title="最后一次答卷时间"
                                                value={data.dateLastlearn?data.dateLastlearn:"--"}
                                                valueStyle={{ color: '#2db7f5' }}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </div>: ""
                }
                <LearningRecords uid={this.props.match.params.uid}/>
            </MyLayout>
        )
    }
}

export default FetchPersonalStatics;