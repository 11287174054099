import React, { Component } from 'react';
import {message, Form, Icon, Input, Button, InputNumber} from 'antd';
import api from '../../../../api/api';
import ImageUpload from '../../../../components/ImageUpload';
import VideoUpload from '../../../../components/VideoUpload';
import TeacherSelectModal from "../../../../components/TeacherSelectModal";
import LectureSelectModal from "../../../../components/LectureSelectModal";
import MyLayout from "../../../common/MyLayout";
import CON from '../../../../config/const';

const { TextArea } = Input;

class AddOrEditVideo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            videoData:null,
            price:0, // 价格
            duration:20,
        };
    }

    addOrEditVideo(values){
        let vid = this.achieveParams();
        let price = this.state.price*100;
        api.video.addOrEditVideo(

            {vid, price, ...values},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.props.history.push('/company/VideoList');
                } else {
                    message.error(data.msg);
                }
            }
        )
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.addOrEditVideo(values);
            }
        });
    };

    videoDetail(videoId) {
        api.video.videoDetail({videoId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        videoData:data.data,
                    })
                }
            }
        )
    }

    achieveParams(){
        let {videoId} = this.props.match.params;
        if (videoId && videoId =="editVideo"){
            videoId = "";
        }
        return videoId;
    }

    componentDidMount() {
        if (this.achieveParams() && this.achieveParams() !="" ){
            this.videoDetail(this.achieveParams());
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            videoData= null
        } = this.state;

        const formItemLayout = {
            labelCol: {
                sm: { span: 8 },
            },
            wrapperCol: {
                sm: { span: 10},
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                sm: {
                    span: 10,
                    offset: 8,
                },
            },
        };
        return(
            <MyLayout>
                <div style={{marginTop:'60px'}}>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit} >
                        <Form.Item label='视频名' hasFeedback>
                            {getFieldDecorator('name', {
                                initialValue:videoData?videoData.name:'',
                                rules: [
                                    {
                                        required: true,
                                        validateStatus:'error',
                                        message: '视频名是必须输入的!'
                                    },
                                ],
                            })(
                                <Input size="large"
                                       prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                       placeholder="视频文件名"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='文件地址'>
                            {getFieldDecorator('filePath', {
                                initialValue:videoData?videoData.filePath:'',
                                rules: [
                                    {
                                        required: false,
                                        message: '视频文件必须上传!'
                                    },

                                ],
                            })(
                                <VideoUpload/>,
                            )
                            }
                        </Form.Item>
                        <Form.Item label='海报图片地址'>
                            {getFieldDecorator('posterPath', {
                                initialValue:videoData?videoData.posterPath:'',
                                rules: [
                                    {
                                        required: false,
                                        message: '海报图片地址必须上传!'
                                    },

                                ],
                            })(
                                <ImageUpload/>,
                            )
                            }
                        </Form.Item>
                        <Form.Item label='预告短片文件路径'>
                            {getFieldDecorator('trailerPath', {
                                initialValue:videoData?videoData.trailerPath:'',
                                rules: [
                                    {
                                        required: false,
                                        message: '视频文件必须上传!'
                                    },

                                ],
                            })(
                                <ImageUpload/>,
                            )
                            }
                        </Form.Item>
                        <Form.Item label='视频介绍' >
                            {getFieldDecorator('intro', {
                                initialValue:videoData?videoData.intro:'',
                                rules: [
                                    {
                                        required: true,
                                        message: '视频介绍是必须输入的!'
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if(value.length > 5) {
                                                callback();
                                                return;
                                            }
                                            callback('error');
                                        },
                                        message: '视频介绍必须大于5个字。',
                                    },
                                ],
                            })(
                                <TextArea rows={4}
                                          placeholder="视频介绍"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='所属讲师'>
                            {getFieldDecorator('tid', {
                                initialValue:videoData?videoData.teacher:''
                            })(
                                <TeacherSelectModal />,
                            )}
                        </Form.Item>
                        <Form.Item label='所属讲座'>
                            {getFieldDecorator('relateLId',{
                                initialValue:videoData?videoData.lecture:''
                            })(
                                <LectureSelectModal/>,
                            )}
                        </Form.Item>
                        <Form.Item label='视频关键字'>
                            {getFieldDecorator('keywords', {
                                initialValue:videoData?videoData.keywords:'',
                                rules: [
                                    {
                                        required: true,
                                        message: '视频关键字是必须输入的!'
                                    }
                                ],
                            })(
                                <Input size="large"
                                       placeholder="视频关键字"
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label='视频时长(分钟)'>
                            {getFieldDecorator('duration', {
                                initialValue:videoData?videoData.duration:'',
                                rules: [
                                    {
                                        required: true,
                                        message: '视频时长是必须输入(1,9999)!'
                                    }
                                ],
                            })(
                                <InputNumber style={{width:"200px"}} min={1} max={9999} step={1}
                                       placeholder="视频时长"
                                       size="large"
                                       onChange={(value)=>this.setState({duration:value})}/>,
                            )}
                        </Form.Item>
                        {!CON.IS_FREE?
                        <Form.Item label='视频价格(元)'>
                            {getFieldDecorator('videoPrice', {
                                initialValue:videoData?videoData.price/100:'',
                                rules: [
                                    {
                                        required: true,
                                        message: '视频价格是必须输入的（如果免费请输入0）!'
                                    }
                                ],
                            })(
                                <InputNumber style={{width:"200px"}} min={0} max={9999} step={0.1}
                                       placeholder="视频价格"
                                       size="large"
                                       onChange={(value)=>this.setState({price:value})}/>,
                            )}
                            <div style={{color:"#f42429"}}>视频价格区间为0-9999（RMB）.如果是免费视频，请将价格设置为0.</div>
                        </Form.Item>:null}
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit" size="large" block>
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </MyLayout>
        )
    }
}
export default Form.create()(AddOrEditVideo);