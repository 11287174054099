import React, {Component} from 'react'
import {Radio, Row} from 'antd'
import api from '../../../api/api'
import MyLayout from '../../common/MyLayout'
import '../../../assets/style/common.css'
import LineChart from '../../statistic/charts/LineChart'

class PersonalSnapshots extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeKey: "numVideo",
            staticsData: [],
        }
    }

    fetchPersonalSnapshots() {
        let uid = this.props.match.params.uid
        api.statics.fetchPersonalSnapshots(
            {
                uid,
            },
            (state, data) => {
                if (state && data.status === 200) {
                    this.setState({
                        staticsData: data.data,
                    })
                }
            }
        )
    }

    getSpecifyData = (totalData, keyword) => {
        let data = []
        for (let i = 0; i < totalData.length; i++) {
            if(totalData[i][keyword] != 0)
            {
                data.push({
                    time: totalData[i].updateTime,
                    value: totalData[i][keyword],
                })
            }
        }
        return data;
    }

    componentDidMount() {
        this.fetchPersonalSnapshots()
    }

    render() {
        const {staticsData, activeKey} = this.state;
        let data = []

        if (staticsData && staticsData.length > 0) {
            data = this.getSpecifyData(staticsData, activeKey)
        }

        var clumDatas = [
            {
                key:'numVideo',
                value: '累计学习视频数(个)'
            },
            {
                key:'numVideoDuration',
                value: '累计学习视频时长(分)'
            },
            {
                key:'numAnswer',
                value: '累计试卷数量(个)'
            },
            {
                key:'numAnswerItems',
                value: '累计答题数量(个)',
            }
        ];
        let activeValue = '';
        for(let i = 0; i <clumDatas.length; i++)
        {
            if(clumDatas[i].key === activeKey)
            {
                activeValue = clumDatas[i].value;
                break;
            }
        }

        return (
            <MyLayout>
                <Row>
                    <Radio.Group
                        style={{margin: 16}}
                        defaultValue={this.state.activeKey}
                        onChange={e => {
                            this.setState({
                                activeKey: e.target.value,
                            })
                        }}
                    >
                    {
                        clumDatas.map((item,index)=>(
                            <Radio.Button key={'x'+index} value={item.key}>
                                    {item.value}
                            </Radio.Button>
                        ))
                    }
                    </Radio.Group>
                </Row>
                    <LineChart
                        chartTyp="Column"
                        chartName={activeValue}
                        data={data}
                    />
            </MyLayout>
        )
    }
}

export default PersonalSnapshots
