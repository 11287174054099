import React, { Component } from 'react';
import {Button, Modal, Row, Col, message, Form} from 'antd';
import MyLayout from "../../../common/MyLayout";
import api from "../../../../api/api";
import '../../../../assets/style/common.css';

import empty from  '../../../../assets/images/empty.png';
import occupy from '../../../../assets/images/occupy.png';
import select from '../../../../assets/images/select.png';
import screen from '../../../../assets/images/screen.png';
import poster from '../../../../assets/images/1.jpg';
import classNames from "classnames";
import connect from "react-redux/es/connect/connect";

const { confirm } = Modal;

class SceneDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sceneData:null,

            seatsData:[],
            rowAndColData:null
        };
    }

    // 0: "这个位置没有座位"，
    // 1: "可选座位"，
    // 2: "已售座位",
    // 3: "可选座位被选后"
    getRowAndCol(modelId,tickets){
        api.lecture.groundDetail(
            {modelId},
            (state,data)=>{
                if(state && data.status == 200) {
                    let arrayTwo = [];
                    for(let i = 1; i <= data.data.rows; i++) {
                        let arr = [];
                        let flag = false;
                        for(let j = 1; j <= data.data.cols; j++) {
                            tickets.forEach(val => {
                                if(val.rowNo == ""+i && val.colNo == ""+j) {
                                    flag = true;
                                    return;
                                }
                            });
                            flag?arr.push(1):arr.push(0);
                        }
                        arrayTwo[i-1] = arr;
                    }
                    this.setState({
                        seatsData:arrayTwo,
                    });
                }
            }
        );
    }
    /*
       createRowAndCol(modelId,tickets){ //push的1,代表可选
           var arrayTwo = Object.values(tickets.reduce((res, item) => {
               res[item.rowNo] ? res[item.rowNo].push(1) : res[item.rowNo] = [1];
               return res;
           }, {}));
           this.setState({
               seatsData:arrayTwo,
           });
    }*/

    getSum(arr){
        return arr.reduce(function(prev, curr, index, arr){
            return prev + curr;
        });
    }

    renderSeat(){
        if (this.state.seatsData.length <= 0) {
            return ;
        }
        let columns = [];
        for(let i=0,len=this.state.seatsData.length; i<len;i++) {
            columns.push(
                <div className="row">
                    {this.state.seatsData[i].map((val,index)=>{
                        return <span key={index}
                                     onClick={e=>this.click(i,index)}
                                     className={classNames('seat',{'empty':val===1,'occupy':val===2,'select':val===3})}>
                    </span>
                    })}
                </div>);
        }
        return columns;
    };

    renderNumber(){
        if (this.state.seatsData.length <= 0) {
            return ;
        }
        let spans = [];
        let row = 0;
        //检验roomInfo下每个数组的合并值
        for(let i=0,len=this.state.seatsData.length; i<len; i++) {
            if(this.getSum(this.state.seatsData[i])>0) {
                spans.push(<span >{++row}</span>);
            } else {
                spans.push(<span></span>);
            }
        }
        return spans;
    }

    sceneDetail() {
        
        let sceneId = this.props.match.params.sceneId;
        api.lecture.sceneDetail({sceneId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        sceneData:data.data,
                    });
                    //this.createRowAndCol(data.data.venue.modelId,data.data.tickets);
                    this.getRowAndCol(data.data.venue.modelId,data.data.tickets);
                }
            }
        )
    }

    delScene(sceneId){
        confirm({
            title: '你确定要删除这个场次吗?',
            content: '你删除的场次将不再显示在场次表中。',
            onOk:()=>{
                api.lecture.delScene(
                    {sceneId},
                    (state,data)=>{
                        if(state && data.status == 200) {
                            this.props.history.push('/company/lectureDetail'+'/'+ this.props.match.params.lid);
                        }
                        else {
                            message.error(data.msg);
                        }
                    }
                )
            }
        });
    }

    componentDidMount() {
        this.sceneDetail();
    }

    render() {
        let history = this.props.history;
        const {
            sceneData= null
        } = this.state;
        
        return(
            <MyLayout>
                <div className="App">
                    <div className="container">
                        <div className="seat-container">
                            <div className="seat-header">
                                <ul>
                                    <li>
                                        <img src={empty} alt="可选座位"/><span>可选座位</span>
                                    </li>
                                    <li>
                                        <img src={occupy} alt="已售座位"/><span>已售座位</span>
                                    </li>
                                    <li>
                                        <img src={select} alt="已选座位"/><span>已选座位</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="seat-main">
                                <div className="screen">
                                    <img src={screen} alt="屏幕"/>
                                    <h4>屏幕中央</h4>
                                </div>
                                <div className="seats">
                                    <span className="line"></span>
                                    { this.renderSeat() }
                                    <div className="seat-number">
                                        { this.renderNumber() }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="msg-container">
                            <div className="info-header">
                                {sceneData!= null && sceneData.teacher.photoPath != null ? <img
                                    width={120}
                                    src={sceneData.teacher.photoPath}
                                />:"未上传"}
                                <div className="header-msg">
                                    <h3>{sceneData!= null ? sceneData.name:""}</h3>
                                    <p><span>开始时间：</span>
                                        {sceneData!= null ? sceneData.startTime.substring(0,16):""}
                                    </p>
                                    <p><span>结束时间：</span>
                                        {sceneData!= null ? sceneData.endTime.substring(0,16):""}
                                    </p>
                                    <p><span>主讲人：</span>
                                        {sceneData!= null ? sceneData.teacher.name:""}
                                    </p>
                                    <p><span>嘉宾：</span>
                                        {sceneData!= null ? sceneData.guests:""}
                                    </p>
                                </div>
                            </div>
                            <div className="info-main">
                                <p><span>会场名：</span>{sceneData!= null ? sceneData.venue.name:""}</p>
                                <p><span>地址：</span>{sceneData!= null ? sceneData.venue.location:""}</p>
                                <p><span>票价(元)：</span>
                                    {sceneData!= null && sceneData.tickets[0]?
                                        "￥"+Number(sceneData.tickets[0].price/100).toFixed(2)+"/张":""
                                    }
                                </p>
                            </div>
                            {
                                this.props.userInfo && this.props.userInfo.role != 5 ?
                                <Row style={{padding: 20}}>
                                    <Col span={8}>
                                        <Button type="primary"
                                                onClick={() => history.push('/company/AddOrEditScene' + '/' +
                                                    this.props.match.params.lid + '/' +
                                                    this.props.match.params.sceneId)
                                                }>编辑场次
                                        </Button>
                                    </Col>
                                    <Col span={8}>
                                        <Button type="primary"
                                                onClick={() => this.delScene(this.props.match.params.sceneId)}>删除场次</Button>
                                    </Col>
                                </Row>:""
                            }
                        </div>
                    </div>
                </div>
            </MyLayout>
        )
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}
export default connect(mapStateToProps,null)(SceneDetail);