import React, { Component } from 'react';
import { Row, Button, Divider, Form, message, PageHeader, Tabs, Tag, Typography, Descriptions, Modal, Badge, Col } from 'antd';
import api from '../../../api/api';
import MyLayout from "../../common/MyLayout";
import Dict from "../../../config/Dict";
import AddOrEditAnser from "./AddOrEditAnser";
import '../../../assets/style/common.css';
import { connect } from 'react-redux';
import RoutePath from '../../../config/RoutePath';

const { TabPane } = Tabs;
const { Paragraph } = Typography;

class AnswerDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {

            detailData: null,
            answerDetail: null,

            judgeSelect:[],//判断题
            singleSelect: [],//单选
            doubleSelect: [],//多选
            uncertainSelect: [],//不定项选
            fillVacancy: [],//填空
            shortAnswer: [],//简答

            remainSeconds: -1,
        };

        this.timer = null;
    }

    getAnswerDetailByPaperId() {
        let paperId = this.props.match.params.paperId;
        api.exam.getAnswerDetailByPaperId(
            { paperId },
            (state, data) => {
                if (state && data.status === 200) {
                    let remainSeconds = -1;
                    if(data.data)
                    {
                        remainSeconds = data.data.remainSeconds
                    }

                    this.setState({
                        answerDetail: data.data,
                        remainSeconds: remainSeconds,
                    });
                } else {
                    message.error(data.msg);
                }
            }
        )
    }

    getPaperDetailByPaperId() {
        let paperId = this.props.match.params.paperId;
        api.exam.getPaperDetailByPaperId({ paperId },
            (state, data) => {
                if (state && data.status === 200) {
                    this.getAnswerDetailByPaperId();
                    this.setState({
                        detailData: data.data,
                        judgeSelect:[],
                        singleSelect: [],
                        doubleSelect: [],
                        uncertainSelect: [],
                        fillVacancy: [],
                        shortAnswer: [],
                    });
                    this.questionDivide(data.data);
                } else {
                    message.error(data.msg);
                }
            }
        )
    }
    questionDivide(data) {
        const { 
            judgeSelect,
            singleSelect, 
            doubleSelect, 
            uncertainSelect, 
            fillVacancy, 
            shortAnswer 
        } = this.state;

        for (let i = 0; i < data.questions.length; i++) {
            switch (data.questions[i].examQuestion.itemType) {
                case 0:
                    singleSelect.push(data.questions[i]);
                    break;
                case 1:
                    doubleSelect.push(data.questions[i]);
                    break;
                case 2:
                    uncertainSelect.push(data.questions[i]);
                    break;
                case 3:
                    fillVacancy.push(data.questions[i]);
                    break;
                case 4:
                    shortAnswer.push(data.questions[i]);
                    break;
                case 5:
                    judgeSelect.push(data.questions[i]);
                    break;
                default:
                    break;
            }
        }
        this.setState({
            judgeSelect: judgeSelect,
            singleSelect: singleSelect,
            doubleSelect: doubleSelect,
            uncertainSelect: uncertainSelect,
            fillVacancy: fillVacancy,
            shortAnswer: shortAnswer,

        })
    }

    componentWillUnmount()
    {
        if(this.timer)
        {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    didMountGoOn()
    {
        if (this.props.userInfo && this.props.userInfo.role === 0) {
            this.getPaperDetailByPaperId();
            if(this.timer)
            {
                clearInterval(this.timer);
                this.timer = null;
            }

            this.timer = setInterval(()=>{
                let remainSeconds = this.state.remainSeconds;
                if(remainSeconds > 0)
                {
                    remainSeconds--;
                    this.setState({remainSeconds});
                }
            },1000)
        }
        else {
            Modal.confirm(
                {
                    title: '权限错误',
                    content: '只有登录用户并且是学员用户才可以答题，登录或者重新登录?',
                    onOk: () => this.props.history.push(RoutePath.Login),
                    okText: '去登录',
                    onCancel: () => this.props.history.goBack(),
                    cancelText: '回上一级',
                }
            )
        }
    }
    componentDidMount() {

        Modal.confirm({
            title: '!!!重要提醒!!!',
            content:<span style={{color:'red'}}>一旦开始答题，就开始计时，即使退出，计时继续，不可暂停，您准备好了吗?</span>,
            onOk: () => this.didMountGoOn(),
            okText: '开始答题',
            onCancel: () => this.props.history.goBack(),
            cancelText: '再等会儿',
        });
    }

    sumbitAnswer(answerId) {
        Modal.confirm({
            title: '提醒',
            content: '真的要交卷吗？交卷操作将不可撤销。',
            onOk: () => api.exam.sumbitAnswer(
                { answerId },
                (state, data) => {
                    if (state && data.status === 200) {
                        this.getPaperDetailByPaperId();
                    }
                    else {
                        message.error(data.msg);
                    }
                }
            ),

        })

    }

    getNumOfAnwer(questions) {
        let nums = 0;
        let { answerDetail } = this.state;
        if (answerDetail == null) {
            return 0;
        }

        let examAnsitems = answerDetail.examAnsitems;
        for (let i = 0; i < questions.length; i++) {
            for (let j = 0; j < examAnsitems.length; j++) {
                if (questions[i].questionId === examAnsitems[j].questionId) {
                    nums++;
                    break;
                }
            }
        }

        return nums;
    }

    render() {
        const { detailData, answerDetail } = this.state;
        let hasSumbit = answerDetail && answerDetail.examAnswer.answerSumbit === 1;


        let tabsData = [
            {
                title: '判断题',
                questionData: this.state.judgeSelect,
                questionType: 5,
                numOfAnswer: this.getNumOfAnwer(this.state.judgeSelect),
            }, 
            {
                title: '单选题',
                questionData: this.state.singleSelect,
                questionType: 0,
                numOfAnswer: this.getNumOfAnwer(this.state.singleSelect),
            },
            {
                title: '多选题',
                questionData: this.state.doubleSelect,
                questionType: 1,
                numOfAnswer: this.getNumOfAnwer(this.state.doubleSelect),
            },
            {
                title: '不定向选择',
                questionData: this.state.uncertainSelect,
                questionType: 2,
                numOfAnswer: this.getNumOfAnwer(this.state.uncertainSelect),
            },
            {
                title: '填空题',
                questionData: this.state.fillVacancy,
                questionType: 3,
                numOfAnswer: this.getNumOfAnwer(this.state.fillVacancy),
            },
            {
                title: '简答题',
                questionData: this.state.shortAnswer,
                questionType: 4,
                numOfAnswer: this.getNumOfAnwer(this.state.shortAnswer),
            }
        ];

        let totalNeedAnswer = 0;
        tabsData.forEach((item) => {
            totalNeedAnswer += item.numOfAnswer;
        });
        if (answerDetail && detailData && totalNeedAnswer === detailData.questions.length) {
           // Modal.success({
           //     title: '恭喜!',
           //     content: '您所有的题均已答完',
           // })
        }

        let remainSeconds = this.state.remainSeconds;
        let remainTxt = '';
        if(remainSeconds < 0)
        {
            remainTxt = '不限制时长'
        }
        else if(remainSeconds === 0)
        {
            remainTxt = '截止时间到!'
        }
        else
        {
            remainTxt = Math.floor(remainSeconds/60) + '分'  + remainSeconds%60 + '秒';
        }

       let examType =  detailData?detailData.examPaper.examType:1;
        return (
            <MyLayout>
                <PageHeader
                    title={detailData ? detailData.examPaper.title : ""}
                    className="site-page-header"
                    subTitle="在线答题(每做完一道题点提交答案自动交卷)"
                    tags={<>
                        <Tag color="blue">
                            {Dict.getValue('paperStatus',
                                detailData ? detailData.examPaper.status : "", '')}
                        </Tag>

                    </>
                    }
                    extra={<>
                    {  false?
                        <Button
                            disabled={hasSumbit}
                            onClick={() => this.sumbitAnswer(answerDetail.answerId)}
                            type='danger'>{hasSumbit ? '已交卷' : '我要交卷'}</Button>:null}
                    </>}
                >
                    <Row style={{fontSize:24}}>
                            <Col span={12}>
                                    <div>开始答题时间:<span style={{color:'red'}}>{answerDetail?answerDetail.examAnswer.createTime:'--'}</span></div>
                            </Col>
                            <Col span={12}>
                                    <div>倒计时:<span style={{color:'red'}}>{remainTxt}</span></div>
                            </Col>
                    </Row>
                    <div className="heading_content">
                        <div className="heading_img">
                            {detailData && detailData.examPaper ?
                                <img
                                    alt=''
                                    src={detailData.examPaper.coverPath}
                                    width={200}
                                    height={150}
                                /> : "未上传"}
                        </div>
                        <div className="heading_mes">
                            <Descriptions size="small" column={2}>
                                <Descriptions.Item label="试卷类型">
                                    <Tag color={detailData && detailData.examPaper.examType == 0 ? "#87d068" : "#2db7f5"}>
                                        {Dict.getValue('examType', detailData ? detailData.examPaper.examType : "", '')}
                                    </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label="题数/分值">
                                    {detailData ?
                                        detailData.examPaper.itemNum + '/' + detailData.examPaper.totalScore : ""}
                                </Descriptions.Item>
                                <Descriptions.Item label="答题人数">
                                    {detailData ? detailData.examPaper.answerNum : ""}
                                </Descriptions.Item>
                                <Descriptions.Item label='考试时间'>
                                        <div>
                                        <span>{detailData && detailData.examPaper.startTime?detailData.examPaper.startTime:'不限定'}</span>
                                        -
                                        <span>{detailData && detailData.examPaper.endTime?detailData.examPaper.endTime:'不限定'}</span>
                                    </div>
                                </Descriptions.Item>
                                <Descriptions.Item label='考试时长'>
                                        <span>{detailData && detailData.examPaper.duration?detailData.examPaper.duration+'分钟':'不限定'}</span>
                                </Descriptions.Item>
                                <Descriptions.Item label="创建日期">
                                    {detailData ? detailData.examPaper.createTime : ""}
                                </Descriptions.Item>
                            </Descriptions>
                            <Paragraph>
                                {detailData ? detailData.examPaper.description : ""}
                            </Paragraph>
                        </div>
                    </div>
                </PageHeader>
                <Divider />
                <Tabs tabPosition="left">
                    {
                        tabsData.map((item, index) => (
                            <TabPane tab={<Badge className="site-badge-count"
                                offset={[10, 0]}
                                status={item.numOfAnswer < item.questionData.length ? 'error' : 'success'}>

                                {item.title + "(" + item.numOfAnswer + "/" + item.questionData.length + ")"}</Badge>
                            }
                                key={index}>
                                <AddOrEditAnser questionData={item.questionData}
                                    questionType={item.questionType}
                                    examType={examType}
                                    answerDetail={answerDetail}
                                    onChange={() => this.getAnswerDetailByPaperId()}
                                />
                            </TabPane>
                        )
                        )
                    }
                </Tabs>
            </MyLayout>
        )
    }
}


function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    };
}

export default connect(mapStateToProps, null)(Form.create()(AnswerDetail));
