
import React, { Component } from 'react';
import MyLayout from '../common/MyLayout';
import api from '../../api/api';
import { Checkbox, Table, Divider, Row, Col, Form, Select, Button } from 'antd';
import ExportJsonExcel from 'js-export-excel';
import { setLoadingState } from '../../reducers/UserInfo';
import RoutePath from '../../config/RoutePath';

class StaticPersonalList extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            clses:[],
            classId:null,

            data:[],
            loading:false,
            checkOptions:[],
            updateLoading: false,
         };
    }

    feacthClassList()
    {
        let orgId = this.props.match.params.orgId;
        if(orgId)
        {
            //管理员用户
            api.class.getAllClassByOrgId({orgId},
                (state,data)=>{
                    if(state && data.status == 200)
                    {
                        this.setState({clses:data.data});
                        if(data.data.length > 0)
                        {
                            this.setState({classId:data.data[0].classId});
                            this.research();
                        }
                    }
                }
            );
        }
        else
        {
            //老师用户
            api.taskadmin.getMyOcuppyClasses(
                null,
                (state,data)=>{
                    if(state && data.status == 200)
                    {
                        this.setState({clses:data.data});
                        if(data.data.length > 0)
                        {
                            this.setState({classId:data.data[0].classId});
                            this.research();
                        }
                    }
                }
            )
        }
    }

    featchPersonalList()
    {
        setLoadingState(true);
        api.statics.getPersonalStaticsByClassId({
            classId:this.state.classId
        },
        (state, data)=>{
            if(state && data.status==200) {
                this.setState({
                    data:data.data,
                })
            }
            setLoadingState(false);
        }
        )
    }

    research() {
        setTimeout(()=>this.featchPersonalList(), 200,);
    }

    componentDidMount()
    {
        this.feacthClassList();
    }



    getItemSheetData(orgData,index)
    {
        let itemData = [index+1, orgData.name, orgData.phone,orgData.idNumber];
        let checkOpts = this.state.checkOptions;
        for(let i = 0; i < checkOpts.length; i++)
        {
            if(checkOpts[i] === 'video')
            {
                 //sheetHeader.push("视频数","总时长","初学时间","最近学习时间");
                 itemData.push(
                     orgData.numVideo, 
                     orgData.numVideoDuration?orgData.numVideoDuration:0,
                     orgData.dateFirstwatch?orgData.dateFirstwatch:"--",
                     orgData.dateLastwatch?orgData.dateLastwatch:"--");
            }
            if(checkOpts[i] === 'exam')
            {
                 //sheetHeader.push("答题份数","答题道数","初答时间","最近答题时间");
                 itemData.push(
                     orgData.numAnswer, 
                     orgData.numAnswerItems?orgData.numAnswerItems:0,
                     orgData.dateFirstlearn?orgData.dateFirstlearn:"--",
                     orgData.dateLastlearn?orgData.dateLastlearn:"--");
            }
            if(checkOpts[i] === 'task')
            {
                 //sheetHeader.push("加入班级数量","加入任务数量","任务总体完毕百分比");
                 //let avgpercent = orgData.percentTask && orgData.numTask > 0?Math.floor(orgData.percentTask/orgData.numTask):0;
                 let avgpercent = orgData.percentTask ?orgData.percentTask:0;
                 itemData.push(
                     orgData.numClass, 
                     orgData.numTask,
                     avgpercent
                );
            }
         }
         itemData.push(orgData.updateTime)
         return itemData;
    }

    downLoadWithData(data)
    {
       var option={};
       option.fileName = '学习汇总统计'
       option.datas=[
        {
            sheetData:[],
            sheetName:'学习汇总统计',
        },
       ];

       let sheetHeader = ['#编号#', '姓名','电话','身份证号'];
       let checkOpts = this.state.checkOptions;
       for(let i = 0; i < checkOpts.length; i++)
       {
           if(checkOpts[i] === 'video')
           {
                sheetHeader.push("视频数","总时长(分钟)","初学时间","最近学习时间");
           }
           if(checkOpts[i] === 'exam')
           {
                sheetHeader.push("答题份数","答题道数","初答时间","最近答题时间");
           }
           if(checkOpts[i] === 'task')
           {
                sheetHeader.push("加入班级数量","加入任务数量","任务总体完毕百分比");
           }
        }
        sheetHeader.push("统计时间");

       for(let i = 0; i < data.length; i++)
       {
           option.datas[0].sheetData.push(this.getItemSheetData(data[i],i));
       }
       option.datas[0].sheetHeader = sheetHeader;

       var toExcel = new ExportJsonExcel(option); //new
       toExcel.saveExcel(); //保存
    }

    render() {

        const options = [
            { label: '视频', value: 'video' },
            { label: '作业', value: 'exam' },
            { label: '任务', value: 'task' },
        ];

        let columns = [
            {
                title: '#编号#',
                dataIndex: 'index',
                key: 'index',
                render: (text, record,index) => (
                    <span>{index+1}</span>
                )
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '电话',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: '身份证号',
                dataIndex: 'idNumber',
                key: 'idNumber',
                //className:"tableOverflow",
            }, 
        ];

        let checkOpts = this.state.checkOptions;
        for(let i = 0; i < checkOpts.length; i++)
        {
            if(checkOpts[i] === 'video')
            {
                columns = columns.concat(
                    [
                        {
                            title: '视频数',
                            dataIndex: 'numVideo',
                            key: 'numVideo',
                            render: (text, record) => (
                                <span>{text?text:0}</span>
                            )
                        },
                        {
                            title: '总时长(分)',
                            dataIndex: 'numVideoDuration',
                            key: 'numVideoDuration',
                            render: (text, record) => (
                                <span>{text?text:0}</span>
                            )
                        },
                        {
                            title: '初学时间',
                            dataIndex: 'dateFirstwatch',
                            key: 'dateFirstwatch',
                            render: (text, record) => (
                                <span>{text?text.substring(0,10):"--"}</span>
                            )
                        },
                        {
                            title: '最近学习时间',
                            dataIndex: 'dateLastwatch',
                            key: 'dateLastwatch',
                            render: (text, record) => (
                                <span>{text?text.substring(0,10):"--"}</span>
                            )
                        },
                    ]
                )
            }

            if(checkOpts[i] === 'exam')
            {
                columns = columns.concat([
                    {
                        title: '答题份数',
                        dataIndex: 'numAnswer',
                        key: 'numAnswer',
                        render: (text, record) => (
                            <span>{text?text:0}</span>
                        )
                    },
                    {
                        title: '答题道数',
                        dataIndex: 'numAnswerItems',
                        key: 'numAnswerItems',
                        render: (text, record) => (
                            <span>{text?text:0}</span>
                        )
                    },
                    {
                        title: '初答时间',
                        dataIndex: 'dateFirstlearn',
                        key: 'dateFirstlearn',
                        render: (text, record) => (
                            <span>{text?text.substring(0,10):"--"}</span>
                        )
                    },
                    {
                        title: '最近答题时间',
                        dataIndex: 'dateLastlearn',
                        key: 'dateLastlearn',
                        render: (text, record) => (
                            <span>{text?text.substring(0,10):"--"}</span>
                        )
                    },
                ]);
            }

            if(checkOpts[i] === 'task')
            {
                columns = columns.concat([
                    {
                        title: '加入班级数量',
                        dataIndex: 'numClass',
                        key: 'numClass',
                        render: (text, record) => (
                            <span>{text?text:0}</span>
                        )
                    },
                    {
                        title: '加入任务数量',
                        dataIndex: 'numTask',
                        key: 'numTask',
                        render: (text, record) => (
                            <span>{text?text:0}</span>
                        )
                    },
                    {
                        title: '任务总体完毕百分比',
                        dataIndex: 'percentTask',
                        key: 'percentTask',
                        //render: (text, record) => (
                        //    <span>{text && record.numTask > 0?Math.floor(text/record.numTask):0}</span>
                        //)
                        render: (text, record) => (
                           <span>{text ?text:0}</span>
                        )
                    },

                ]);
            }
        }

        columns = columns.concat([
        {
                title: '统计时间',
                dataIndex: 'updateTime',
                key: 'updateTime',
                render: (text, record) => (
                    <span>{text?text.substring(0,10):"--"}</span>
                )
        },
        {
              title:'详情',
              dataIndex: 'descrp',
              key: 'descrp',
              render: (text, record) => (
                <Button
                 type="primary"
                 onClick={
                     ()=>this.props.history.push(RoutePath.FetchPersonalStatics + "/" + record.uid)
                 }
                >
                  详情
                </Button>
            )
        }
    ]);

        return (
            <MyLayout>
             <Row>
                <Col span={23}>
                <Form layout='inline'>
                    <Form.Item>
                       {
                        this.state.classId?
                        <Select 
                        defaultValue={this.state.classId} 
                        onChange={
                            (classId)=>
                            {
                                this.setState({classId});
                                this.research();
                            }
                        }>
                        
                        {
                            this.state.clses.map((item,index)=>(
                                <Select.Option value={item.classId} key={index}>{item.name}</Select.Option>
                            ))
                        }
                        </Select>:null
                       }
                    </Form.Item>    
                    <Form.Item>
                        <Checkbox.Group 
                            options={options}
                            onChange={(checkOptions)=>this.setState({checkOptions})}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                        type='primary'
                        onClick={
                            ()=>{
                                if(this.state.classId)
                                {
                                    this.setState({updateLoading:true});
                                    api.statics.updatePersonalStaticDataByClassId(
                                        {classId:this.state.classId},
                                        (state,data)=>{
                                            this.setState({updateLoading:false});
                                            this.featchPersonalList();
                                        }
                                    )
                                }
                            }
                        }
                        loading={this.state.updateLoading}
                        >
                            更新本班数据
                        </Button>
                    </Form.Item>
                </Form>
                </Col>
                <Col span={1}>
                <Button icon='download' 
                       onClick={()=>this.downLoadWithData(this.state.data)}></Button>
                </Col>
                </Row>
                <Divider/>
                <Table
                  dataSource={this.state.data}
                  columns={columns}
                  rowKey='uid'
                  pagination={false}
              />
            </MyLayout>
        );
    }
}

export default StaticPersonalList;

