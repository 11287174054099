import React, { Component } from 'react';
import {Descriptions, Badge, Modal} from 'antd';
import MyLayout from "../../common/MyLayout";
import api from "../../../api/api";
import Dict from '../../../config/Dict';

class AdminTeacherDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teacherData:null,
        };
    }

    teacherDetail() {
        let teacherId = this.props.match.params.teacherId;
        api.teacher.teacherDetail({teacherId},
            (state,data)=>{
                if(state && data.status == 200) {
                    this.setState({
                        teacherData:data.data,
                    })
                }
            }
        )
    }

    componentDidMount() {
        this.teacherDetail();
    }

    render() {
        const {
            teacherData= null
        } = this.state;
        return(
            <MyLayout>
                <div>
                    <Descriptions title="讲师详情" bordered>
                        <Descriptions.Item key='createdTime' label="创建时间">
                            {teacherData!= null?teacherData.createdTime:""}
                        </Descriptions.Item>
                        <Descriptions.Item key='updatedTime' label="更新时间">
                            {teacherData!= null && teacherData.updateTime=="" ? teacherData.updateTime:"无更新"}
                        </Descriptions.Item>
                        <Descriptions.Item key='status' label="上线状态">
                            {teacherData!= null? <Badge
                                status="processing"
                                text={Dict.getValue('videoState',teacherData.status,'')} />:null
                            }
                        </Descriptions.Item>
                        <Descriptions.Item key="name" label="讲师姓名" span={2}>
                            {teacherData!= null?teacherData.name:""}
                        </Descriptions.Item>
                        <Descriptions.Item key='vid' label="讲师id" >{teacherData!= null?teacherData.tid:""}</Descriptions.Item>
                        <Descriptions.Item key='relateLId' label="所属组织" span={2}>{teacherData!= null?teacherData.org.orgName:"无"}</Descriptions.Item>
                        <Descriptions.Item key='tid' label="所属领域">{teacherData!= null?teacherData.field.name:"无"}</Descriptions.Item>
                        <Descriptions.Item  label="讲师照片" span={3}>
                            {teacherData!= null && teacherData.photoPath != null ? <img
                                width={220}
                                src={teacherData.photoPath}
                            />:"未上传"}
                        </Descriptions.Item>
                        <Descriptions.Item  label="签名图片" span={3}>
                            {teacherData!= null && teacherData.signaturePath != null ? <img
                                width={220}
                                src={teacherData.signaturePath}
                            />:"未上传"}
                        </Descriptions.Item>
                        <Descriptions.Item key='intro' label="专业成就">
                            {teacherData!= null?teacherData.achievement:""}
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </MyLayout>
        )
    }
}
export default AdminTeacherDetail;